import { Popover } from "@jugl-web/ui-components/cross-platform";
import { Tooltip } from "@jugl-web/ui-components/web";
import { assignRefs, cx } from "@jugl-web/utils";
import { FC } from "react";
import { ReactComponent as ManageColumnsIcon } from "../../assets/manage-columns.svg";
import { TABLE_DIMENSIONS } from "../../consts";
import { ManageColumnsPopoverContent } from "../ManageColumnsPopoverContent";
import { TaskTableLayout } from "../TaskTableLayout";

interface ManageColumnsButtonCellProps {
  side: "left" | "right";
}

export const ManageColumnsButtonCell: FC<ManageColumnsButtonCellProps> = ({
  side,
}) => (
  <Tooltip
    renderTrigger={({ props, ref }) => (
      <Popover
        placement={side === "left" ? "bottom-start" : "bottom-end"}
        renderTrigger={({ Trigger, triggerRef, isOpen }) => (
          <Trigger
            as={TaskTableLayout.Cell}
            ref={assignRefs([ref, triggerRef])}
            width={TABLE_DIMENSIONS.TABLE_OUTERMOST_COLUMN_WIDTH}
            isHoverable
            className={cx("justify-center px-0", isOpen && "bg-grey-100")}
            onClick={() => {}}
            {...props}
          >
            <ManageColumnsIcon />
          </Trigger>
        )}
        className="rounded-xl px-4 py-6"
        style={{ boxShadow: "0px 5px 16px 0px rgba(0, 0, 0, 0.16)" }}
      >
        <ManageColumnsPopoverContent
          shouldScrollToAddedColumn={side === "right"}
        />
      </Popover>
    )}
  >
    Manage columns
  </Tooltip>
);
