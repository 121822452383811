import { getJwtToken } from "@web-src/features/auth/utils";
import store from "@web-src/store";
import { logout } from "@web-src/features/auth/authSlice";
import { getAxiosInstance } from "@jugl-web/rest-api";
import { environment } from "@web-src/environments/environment";

const api = getAxiosInstance(
  () => getJwtToken()?.accessToken,
  environment.apiUrl
);

api.interceptors.response.use(undefined, (error) => {
  if (error?.response?.status === 401) {
    store.dispatch(logout());
  }
  throw error;
});

export default api;
