import { SubscriptionInfoModel } from "@jugl-web/rest-api";
import { SubscriptionPlanColor } from "../types/SubscriptionPlanColor";
import { SUBSCRIPTION_PLAN_COLORS } from "./consts";

export const getSubscriptionInfoPlanColorInfo = (
  subscriptionInfo: SubscriptionInfoModel
) => {
  let color: SubscriptionPlanColor = "green";
  if (subscriptionInfo.planInfo?.type === "basic") {
    color = "pink";
  }
  return SUBSCRIPTION_PLAN_COLORS[color];
};
