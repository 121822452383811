// #region Auth
export const MOBILE_DEBUG_ACCESS_TOKEN_KEY = "JUGL__MOBILE_DEBUG_ACCESS_TOKEN";
export const MOBILE_DEBUG_ENTITY_ID_KEY = "JUGL__MOBILE_DEBUG_ENTITY_ID";
export const MOBILE_DEBUG_LANGUAGE_KEY = "JUGL__MOBILE_DEBUG_LANGUAGE";
export const MOBILE_DEBUG_SETUP_AUTH_KEY = "JUGL__MOBILE_DEBUG_SETUP_AUTH";
export const MOBILE_DEBUG_APP_URL_KEY = "JUGL__MOBILE_DEBUG_APP_URL";
// #endregion

// #region Misc keys
export const TIMEZONE_MISMATCH_ALERT_SHOWN =
  "JUGL__TIMEZONE_MISMATCH_ALERT_SHOWN";
export const TIMEZONE_INFO_POPUP_SHOWN = "JUGL__TIMEZONE_INFO_POPUP_SHOWN";
export const SIDEBAR_EXPANDED_KEY = "JUGL__SIDEBAR_EXPANDED";
export const PAYMENTS_YEARLY_BENEFITS_SHOWN =
  "JUGL__PAYMENTS_YEARLY_BENEFITS_SHOWN";
export const SESSION_ID = "JUGL__SESSION_ID";
export const WEB_DOWNLOAD_MOBILE_CTA_CLOSED =
  "JUGL__WEB_DOWNLOAD_MOBILE_CTA_CLOSED";
export const ENTITY_LINK_INVITATION = "JUGL__ENTITY_LINK_INVITATION";
export const LINK_TO_OPEN_AFTER_LOGIN_KEY = "JUGL__LINK_TO_OPEN_AFTER_LOGIN";
// #endregion

// #region Tasks/templates keys
export const TASK_VIEW_ID_BY_ENTITY_ID_KEY = "JUGL__TASK_VIEW_ID_BY_ENTITY_ID";
export const TASK_LIST_MODE_KEY = "JUGL__TASK_LIST_MODE";
export const TASK_LIST_LAYOUT_KEY = "JUGL__TASK_LIST_LAYOUT";
export const TASK_CALENDAR_VIEW_KEY = "JUGL__TASK_CALENDAR_VIEW";
export const TASK_CALENDAR_DATE_KEY = "JUGL__TASK_CALENDAR_DATE";
export const TASK_CALENDAR_WEEK_MANAGE_COLUMN_TAB_KEY =
  "JUGL__TASK_CALENDAR_WEEK_MANAGE_COLUMN_TAB";
export const TASK_FILTERING_BY_ENTITY_ID_KEY =
  "JUGL__TASK_FILTERING_BY_ENTITY_ID";
export const TASK_SORTING_KEY = "JUGL__TASKS_SORTING";
export const TASK_SEARCH_QUERY_BY_ENTITY_ID_KEY =
  "JUGL__TASK_SEARCH_QUERY_BY_ENTITY_ID";
export const TASK_PREFERENCES_BY_TASK_ID_KEY =
  "JUGL__TASK_PREFERENCES_BY_TASK_ID";
export const TEMPLATE_FOLDERS_SEARCH_QUERY_KEY =
  "JUGL__TEMPLATE_FOLDERS_SEARCH_QUERY";
export const TEMPLATE_FOLDERS_FILTER_KEY = "JUGL__TEMPLATE_FOLDER_FILTERS";
export const IS_PRIVATE_TASK_HINT_SHOWN_KEY =
  "JUGL__IS_PRIVATE_TASK_HINT_SHOWN";
export const TASK_CUSTOM_FIELD_COLUMNS_BY_ENTITY_ID_KEY =
  "JUGL__TASK_CUSTOM_FIELD_COLUMNS_BY_ENTITY_ID";
export const TASK_EXPANDED_TABLES_BY_ENTITY_ID_KEY =
  "TASK_EXPANDED_TABLES_BY_ENTITY_ID";
export const TASK_COLLAPSED_CALENDAR_REPORTEE_PANELS_BY_ENTITY_ID_KEY =
  "TASK_COLLAPSED_CALENDAR_REPORTEE_PANELS_BY_ENTITY_ID";
// #endregion

// #region User settings keys
export const AVATAR_VERSION_STORAGE_KEY = "JUGL__SETTINGS_AVATAR_VERSION";

// #endregion
// #region Spotlights/hints keys
export const TASK_TEMPLATES_SPOTLIGHT_TOUR_KEY =
  "JUGL__TASK_TEMPLATES_SPOTLIGHT_TOUR";
export const EDIT_TASK_FROM_TEMPLATE_SPOTLIGHT_KEY =
  "JUGL__EDIT_TASK_FROM_TEMPLATE_SPOTLIGHT";
export const MANAGE_TASK_FIELDS_HINT_DISMISSED_KEY =
  "JUGL__MANAGE_TASK_FIELDS_HINT_DISMISSED";
// #endregion

// #region Utils
export const getStorageItem = <TItem>(
  key: string,
  storage: Storage = localStorage
): TItem | undefined => {
  const rawItem = storage.getItem(key);

  if (!rawItem) {
    return undefined;
  }

  try {
    return JSON.parse(rawItem) as TItem;
  } catch {
    return undefined;
  }
};

export const getStorageItemWithFallback = <TItem>(
  key: string,
  fallback: TItem,
  storage: Storage = localStorage
): TItem => getStorageItem<TItem>(key, storage) ?? fallback;

export const saveItemToStorage = <TItem>(
  key: string,
  item: TItem,
  storage: Storage = localStorage
) => {
  try {
    storage.setItem(key, JSON.stringify(item));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(`Failed to save item to storage: ${error}`);
  }
};
// #endregion
