import { cx, useTranslations } from "@jugl-web/utils";
import React, { ChangeEvent } from "react";
import { Select } from "../../cross-platform/Select";
import { Text } from "../../cross-platform/Text";
import { Pagination } from "../Pagination";

export interface TableProps<TData> {
  data: TData[];
  columns: { id: string; title: string }[];
  headerFormatter?: (
    id: string,
    title: string,
    columnIdx: number
  ) => string | React.ReactNode;
  rowFormatter?: (row: TData, itemIdx: number) => string | React.ReactNode;
  dataFormatter?: (row: TData, itemIdx: number) => string | React.ReactNode;
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  pageRows: number;
  onPageRowsChange: (rows: number) => void;
}

export const Table = <T extends object>({
  columns,
  data,
  headerFormatter,
  dataFormatter,
  currentPage,
  onPageChange,
  pageRows,
  onPageRowsChange,
  totalPages,
  rowFormatter,
}: TableProps<T>) => {
  const handlePageRowsChange = (e: ChangeEvent<HTMLSelectElement>) => {
    onPageRowsChange(parseInt(e.target.value, 10));
  };
  const { t } = useTranslations();
  return (
    <>
      <table className="w-full min-w-fit border-collapse">
        <thead className="after:table-row after:h-[20px] after:bg-transparent after:content-['']">
          <tr className="bg-white">
            {columns.map((item, index) => (
              <th
                key={item.id}
                className={cx(
                  "font-500 text-grey-800 py-4 pr-4 text-left text-xs uppercase",
                  {
                    "rounded-l-lg pl-6": index === 0,
                    "rounded-r-lg pr-6": columns.length - 1 === index,
                  }
                )}
              >
                {headerFormatter?.(item.id, item.title, index) ?? item.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="border-separate border-spacing-4">
          {data.map((row, index) => (
            <>
              {/* eslint-disable-next-line react/no-array-index-key */}
              <div className="mt-[2px]" key={index} />
              <tr
                className={cx(
                  "bg-white text-sm [&>th:first-child]:pl-6 [&>th:last-child]:pr-6 [&>th]:py-4 [&>th]:pr-4 [&>th]:text-left [&>th]:font-[400] [&>th]:text-gray-800"
                )}
              >
                <>
                  {!dataFormatter && rowFormatter && rowFormatter(row, index)}
                  {!rowFormatter &&
                    (row as Array<T>).map((item, itemIdx) => (
                      <>
                        {/* eslint-disable-next-line react/no-array-index-key */}
                        {dataFormatter?.(row, itemIdx) ?? (
                          <th>{item as unknown as React.ReactElement}</th>
                        )}
                      </>
                    ))}
                </>
              </tr>
            </>
          ))}
        </tbody>
      </table>
      {data?.length === 0 && (
        <div className="flex w-full justify-center uppercase">
          <Text variant="text2Regular">
            {t({
              id: "table-component.no-results-with-ellipsis",
              defaultMessage: "No results...",
            })}
          </Text>
        </div>
      )}
      <div className="my-4 flex w-full items-center justify-between">
        <div>
          <Text variant="text2Regular" className="text-grey">
            {t({
              id: "table-component.rows-per-page",
              defaultMessage: "Rows per page",
            })}
            :
          </Text>
          <Select
            className="bg-transparent"
            value={pageRows}
            onChange={handlePageRowsChange}
          >
            <option>20</option>
            <option>50</option>
          </Select>
        </div>
        <div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        </div>
      </div>
    </>
  );
};
