import { forwardRef } from "react";
import { Text, LoadingAnimation } from "@jugl-web/ui-components/cross-platform";
import { cx, useTranslations } from "@jugl-web/utils";

interface MoreTasksLoadingProps {
  className?: string;
}

export const MoreTasksLoading = forwardRef<
  HTMLDivElement,
  MoreTasksLoadingProps
>(({ className }, ref) => {
  const { t } = useTranslations();

  return (
    <div ref={ref} className={cx("flex flex-col gap-4 text-center", className)}>
      <LoadingAnimation size={{ custom: 52 }} />
      <Text
        variant="body3"
        className="text-grey font-secondary inline-block whitespace-nowrap"
      >
        {t({
          id: "tasks-page.loading-tasks",
          defaultMessage: "Loading Tasks...",
        })}
      </Text>
    </div>
  );
});
