import { useRestApiProvider } from "@jugl-web/rest-api";
import {
  PreviewTaskTemplate,
  previewTasksTemplatesAdapter,
} from "@jugl-web/rest-api/tasks-templates";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useCallback, useMemo } from "react";

export enum TemplateFilter {
  AllTemplates = "allTemplates",
  MyTemplates = "myTemplates",
  CreatedByJugl = "createdByJugl",
  CreatedByOthers = "createdByOthers",
}

export interface UseTasksTemplatesProps {
  entityId: string | undefined;
  meId: string;
  searchQuery: string;
  filter: TemplateFilter;
  folderId?: string;
}

const matchesFolder = (folderId?: string) => (template: PreviewTaskTemplate) =>
  folderId ? template.folder_id === folderId : true;

const matchesSearchQuery =
  (searchQuery: UseTasksTemplatesProps["searchQuery"]) =>
  (template: PreviewTaskTemplate) =>
    template.name.toLowerCase().includes(searchQuery.toLowerCase());

const matchesFilter =
  (filter: TemplateFilter, meId: string) => (template: PreviewTaskTemplate) => {
    switch (filter) {
      case TemplateFilter.MyTemplates:
        return template.created_by === meId;
      case TemplateFilter.CreatedByJugl:
        return template.created_by === null;
      case TemplateFilter.CreatedByOthers:
        return template.created_by !== null && template.created_by !== meId;
      default:
        return true;
    }
  };

export const useTasksTemplates = ({
  entityId,
  meId,
  folderId,
  searchQuery,
  filter,
}: UseTasksTemplatesProps) => {
  const { tasksTemplatesApi } = useRestApiProvider();

  const { data, isLoading, isError, refetch } =
    tasksTemplatesApi.useGetTemplatesQuery(entityId ? { entityId } : skipToken);

  const adapterSelectors = useMemo(
    () => previewTasksTemplatesAdapter.getSelectors(),
    []
  );

  const allTemplates = useMemo(
    () =>
      adapterSelectors.selectAll(
        data ?? previewTasksTemplatesAdapter.getInitialState()
      ),
    [adapterSelectors, data]
  );

  const filteredTemplates = useMemo(
    () =>
      allTemplates.filter(
        (template) =>
          matchesFolder(folderId)(template) &&
          matchesSearchQuery(searchQuery)(template) &&
          matchesFilter(filter, meId)(template)
      ),
    [allTemplates, filter, folderId, meId, searchQuery]
  );

  const selectTemplatesByFolderId = useCallback(
    (fId: string) => filteredTemplates.filter(matchesFolder(fId)),
    [filteredTemplates]
  );

  return {
    templates: filteredTemplates,
    isLoading,
    isError,
    refetch,
    selectTemplatesByFolderId,
  };
};
