import Intercom, { hide, show, shutdown } from "@intercom/messenger-js-sdk";
import { HookOutOfContextError } from "@jugl-web/utils";
import { environment } from "@web-src/environments/environment";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { useMe } from "@web-src/features/app/hooks/useMe";
import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

interface IntercomContextValue {
  isVisible: boolean;
  toggle: () => void;
}

const IntercomContext = createContext<IntercomContextValue | null>(null);

interface IntercomProviderProps {
  children: ReactNode;
}

export const IntercomProvider: FC<IntercomProviderProps> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);

  const { id: meId, profile: meProfile } = useMe();
  const { entity } = useEntity();

  const toggle = useCallback(() => {
    if (isVisible) {
      hide();
    } else {
      show();
    }
  }, [isVisible]);

  useEffect(() => {
    Intercom({
      app_id: environment.intercomAppId,
      hide_default_launcher: true,
    });

    // Need to use the global instance because the `onShow` and `onHide` methods from SDK
    // make the `show` and `hide` SDK methods not to work
    window.Intercom?.("onShow", () => setIsVisible(true));
    window.Intercom?.("onHide", () => setIsVisible(false));

    return () => {
      shutdown();
    };
  }, []);

  useEffect(() => {
    // Same as above, need to use the global instance as it's not working with the SDK methods
    window.Intercom?.("update", {
      user_id: meId,
      name: meProfile?.displayName,
      email: meProfile?._user.emails[0],
      phone: meProfile?._user.mobiles[0],
      company: {
        company_id: entity?.id,
        name: entity?.name,
      },
    });
  }, [entity, meId, meProfile]);

  const contextValue = useMemo<IntercomContextValue>(
    () => ({
      isVisible,
      toggle,
    }),
    [isVisible, toggle]
  );

  return (
    <IntercomContext.Provider value={contextValue}>
      {children}
    </IntercomContext.Provider>
  );
};

export const useIntercom = () => {
  const context = useContext(IntercomContext);

  if (!context) {
    throw new HookOutOfContextError("useIntercom", "IntercomContext");
  }

  return context;
};
