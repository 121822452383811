import React from "react";
import { cx } from "@jugl-web/utils";
import { Text } from "@jugl-web/ui-components/cross-platform";
import { ListItem } from "../ListItem";
import { ReactComponent as GreyPinIcon } from "./assets/grey-pin.svg";

type EventListItemProps = {
  eventTime?: React.ReactNode;
  icon?: React.ReactNode;
  onClick: () => void;
  address?: string;
  text?: string;
  children?: React.ReactNode;
  containerClassNames?: string;
  detailsList?: React.ReactNode;
};
export const EventListItem = ({
  children,
  text,
  eventTime,
  icon: Icon,
  onClick,
  address,
  containerClassNames,
  detailsList,
}: EventListItemProps) => (
  <div
    className={cx(
      "flex w-full cursor-pointer flex-col justify-between gap-2 px-8 py-4",
      containerClassNames
    )}
    onClick={onClick}
  >
    {children}
    <div className="flex w-full flex-row justify-between">
      <div className="flex flex-row items-center gap-2">
        {Icon}
        {text && (
          <Text variant="body2" className="text-dark.A100 font-medium">
            {text}
          </Text>
        )}
      </div>
      {eventTime}
    </div>

    {address && (
      <div className="pl-7">
        <ListItem text={address} icon={<GreyPinIcon />} />
      </div>
    )}
    {detailsList}
  </div>
);
