import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { cx } from "@jugl-web/utils";
import { FC } from "react";
import { ColorPickerPopover } from "../../../web/ColorPickerPopover/ColorPickerPopover";
import { PlainButton } from "../../PlainButton";
import { Popover } from "../../Popover";
import { ReactComponent as PaintBucketIcon } from "./icons/paint-bucket.svg";

export interface EditableTaskTemplateFolderTileProps {
  color: string;
  emoji: string;
  className?: string;
  onColorChange: (color: string) => void;
  onEmojiChange: (emoji: string) => void;
}

export const EditableTaskTemplateFolderTile: FC<
  EditableTaskTemplateFolderTileProps
> = ({ color, emoji, className, onColorChange, onEmojiChange }) => (
  <div className={cx("relative inline-flex", className)}>
    <ColorPickerPopover
      title="Background color"
      color={color}
      placement="right-start"
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          ref={triggerRef}
          as={PlainButton}
          className="group relative flex h-[120px] w-[120px] items-center justify-center rounded-2xl outline-none transition hover:brightness-95"
          style={{ backgroundColor: color }}
        >
          <PaintBucketIcon className="absolute right-1 top-1 opacity-0 transition-opacity group-hover:opacity-100" />
        </Trigger>
      )}
      onColorChange={onColorChange}
    />
    <Popover
      placement="right-start"
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          ref={triggerRef}
          as={PlainButton}
          className={cx(
            "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full border border-dashed border-transparent p-1 outline-none transition",
            "hover:border-white hover:shadow-[0_5px_8px_0_rgba(0,0,0,0.4)]"
          )}
        >
          <div className="flex h-12 w-12 items-center justify-center rounded-full bg-white">
            <span className="select-none text-[32px]">{emoji}</span>
          </div>
        </Trigger>
      )}
    >
      {({ onClose }) => (
        <Picker
          theme="light"
          // TODO: Use `useLanguage` after mobile creation task branch is merged
          // locale={dateLocale?.code}
          data={data}
          skinTonePosition="none"
          previewPosition="none"
          onEmojiSelect={(e: { native: string }) => {
            onEmojiChange(e.native);
            onClose();
          }}
        />
      )}
    </Popover>
  </div>
);
