import { FC } from "react";
import { CustomFieldProps } from "../../types";
import { CustomDateField } from "../CustomDateField";
import { CustomDropdownField } from "../CustomDropdownField";
import { CustomNumberField } from "../CustomNumberField";
import { CustomTextField } from "../CustomTextField";

export const CustomField: FC<CustomFieldProps> = (props) => {
  const { field } = props;

  switch (field.type) {
    case "text":
      return <CustomTextField {...props} />;
    case "number":
      return <CustomNumberField {...props} />;
    case "date":
      return <CustomDateField {...props} />;
    case "dropdown":
      return <CustomDropdownField {...props} />;
    default:
      throw new Error(`Unknown field type: ${field.type}`);
  }
};
