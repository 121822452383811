import { useRestApiProvider } from "@jugl-web/rest-api";
import {
  Alert,
  EmptyListContent,
  InteractiveContainer,
  ListLoading,
  Menu,
  TableGrid,
} from "@jugl-web/ui-components/cross-platform";
import { Switch } from "@jugl-web/ui-components/cross-platform/Switch";
import { useToast, useTranslations } from "@jugl-web/utils";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import React, { useCallback, useMemo, useState } from "react";
import format from "date-fns/format";
import { ReportsModel } from "@jugl-web/rest-api/reports/models/reportsModel";
import Lottie from "react-lottie";
import Highlighter from "react-highlight-words";
import { environment } from "@web-src/environments/environment";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import EmptyAnimation from "./assets/empty.json";
import { ReactComponent as RecipientIcon } from "./assets/recipient.svg";
import { ReactComponent as ThreeDotsIcon } from "./assets/three-dots.svg";
import { ReactComponent as EditIcon } from "./assets/edit.svg";
import { ReactComponent as DeleteIcon } from "./assets/delete.svg";
import { ReactComponent as PeopleIcon } from "./assets/people.svg";
import { ReactComponent as TravelLogIcon } from "./assets/travel-log.svg";
import { ReactComponent as TasksIcon } from "./assets/tasks.svg";

export const ScheduledReportsTable: React.FC<{
  searchQuery: string;
}> = ({ searchQuery }) => {
  const { t } = useTranslations();
  const { reportsApi } = useRestApiProvider();
  const { entityId } = useEntitySelectedProvider();
  const [deleteAlertIsOpen, setDeleteAlertIsOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState<ReportsModel>();
  const { toast } = useToast({ variant: "web" });

  const { data, isLoading, isFetching, isError, refetch } =
    reportsApi.useGetReportsQuery(
      {
        entityId,
      },
      { refetchOnMountOrArgChange: true }
    );

  const { navigateToPage } = useNavigation();

  const [updateReport] = reportsApi.useUpdateReportMutation();
  const [deleteReport, { isLoading: isDeleteLoading }] =
    reportsApi.useDeleteReportMutation();

  const filteredReports = useMemo(
    () =>
      data?.filter(
        (report) =>
          report.name.toLocaleLowerCase().includes(searchQuery.toLowerCase()) &&
          Object.keys(environment.supersetChartIdToType)
            .map((item) => parseInt(item, 10))
            .includes(report.chartId)
      ) || [],
    [data, searchQuery]
  );

  const getReportBase = useCallback(
    (chartId: number) => {
      switch (environment.supersetChartIdToType[chartId]) {
        case "tasks":
          return {
            label: t({
              id: "scheduled-reports-page.tasks",
              defaultMessage: "Tasks",
            }),
            icon: <TasksIcon />,
          };
        case "people":
          return {
            label: t({
              id: "scheduled-reports-page.people",
              defaultMessage: "People",
            }),
            icon: <PeopleIcon />,
          };
        case "travelLog":
          return {
            label: t({
              id: "scheduled-reports-page.travel-log",
              defaultMessage: "Travel log",
            }),
            icon: <TravelLogIcon />,
          };
        default:
          throw new Error("Unsupported report type");
      }
    },
    [t]
  );

  const handleStatusChange = async (report: ReportsModel) => {
    const response = await updateReport({
      entityId,
      reportId: report.id,
      data: {
        active: !report.active,
      },
    });
    if (response && "data" in response) {
      toast(
        t({
          id: "scheduled-reports-page.scheduled-report-updated",
          defaultMessage: "Scheduled auto report was updated",
        })
      );
    }
  };

  const handleDeleteReport = async () => {
    if (!selectedReport) return;
    const response = await deleteReport({
      entityId,
      reportId: selectedReport.id,
    });
    if (response && "data" in response) {
      setDeleteAlertIsOpen(false);
      setSelectedReport(undefined);
      toast(
        t({
          id: "scheduled-reports-page.scheduled-report-deleted",
          defaultMessage: "Scheduled auto report was deleted",
        })
      );
    }
  };

  if (data?.length === 0) {
    return (
      <EmptyListContent
        type="custom"
        customTitle={t({
          id: "scheduled-reports-page.scheduled-auto-reports",
          defaultMessage: "Scheduled Auto Reports",
        })}
        customSubtitle={t({
          id: "scheduled-reports-page.scheduled-reports-info",
          defaultMessage:
            "Quick and easy way to track Workspace activity, just set time and who will receive this reports 😍",
        })}
        customImg={
          <Lottie
            options={{
              animationData: EmptyAnimation,
            }}
            height={250}
            width={250}
          />
        }
        customButton={{
          text: t({
            id: "scheduled-reports-page.schedule-report",
            defaultMessage: "Schedule Report",
          }),
          onClick: () => navigateToPage("reportsScheduledForm"),
        }}
      />
    );
  }

  return (
    <>
      <TableGrid
        className="overflow-auto whitespace-nowrap"
        cellClassName="flex items-center text-[#4F4F4F] text-sm"
        headerCellClassName="flex items-center"
        emptyContent={(() => {
          if (isLoading || isFetching) {
            return <ListLoading />;
          }
          if (isError) {
            return <EmptyListContent type="error" onRefetch={refetch} />;
          }
          return <EmptyListContent type="noResults" />;
        })()}
        data={filteredReports}
        columns={[
          {
            title: t({
              id: "scheduled-reports-page.report-name",
              defaultMessage: "Report name",
            }),
            grow: true,
            content: ({ name }) => (
              <span className="text-base font-medium">
                <Highlighter
                  highlightClassName="text-primary font-semibold bg-transparent"
                  autoEscape
                  searchWords={[searchQuery]}
                  textToHighlight={name}
                />
              </span>
            ),
          },
          {
            title: t({
              id: "scheduled-reports-page.report-base-on",
              defaultMessage: "Report based on",
            }),
            grow: true,
            content: ({ chartId }) => {
              const { icon, label } = getReportBase(chartId);
              return (
                <div className="flex items-center gap-1">
                  {icon}
                  <span>{label}</span>
                </div>
              );
            },
          },
          {
            title: t({
              id: "scheduled-reports-page.repeat-on",
              defaultMessage: "Repeat on",
            }),
            grow: true,
            content: ({ humanizedCrontab }) => <span>{humanizedCrontab}</span>,
          },
          {
            title: t({
              id: "scheduled-reports-page.send-to",
              defaultMessage: "Send to",
            }),
            grow: true,
            content: ({ recipients }) => (
              <div className="flex items-center gap-1">
                <RecipientIcon />
                <span>{recipients.length}</span>
              </div>
            ),
          },
          {
            title: t({
              id: "scheduled-reports-page.last-run",
              defaultMessage: "Last run",
            }),
            grow: true,
            content: ({ lastRun }) => (
              <span>
                {lastRun
                  ? format(new Date(lastRun), "MMMM dd, yyyy")
                  : t({
                      id: "common.never",
                      defaultMessage: "Never",
                    })}
              </span>
            ),
          },
          {
            title: t({
              id: "scheduled-reports-page.active",
              defaultMessage: "Active",
            }),
            content: (item) => (
              <div className="flex items-center gap-10">
                <Switch
                  isChecked={item.active}
                  onChange={() => handleStatusChange(item)}
                />
                <Menu
                  placement="bottom-start"
                  onSelect={(el, event, close) => close()}
                  sections={[
                    [
                      {
                        id: "edit",
                        icon: <EditIcon />,
                        label: t({
                          id: "scheduled-reports-page.edit-report-schedule",
                          defaultMessage: "Edit Report Schedule",
                        }),
                        onSelect: () =>
                          navigateToPage("reportsScheduledFormEdit", {
                            reportId: item.id,
                          }),
                      },
                      {
                        id: "delete",
                        icon: <DeleteIcon />,
                        label: t({
                          id: "scheduled-reports-page.delete-schedule",
                          defaultMessage: "Delete this Schedule",
                        }),
                        labelClassName: "text-gradients-danger",
                        onSelect: () => {
                          setSelectedReport(item);
                          setDeleteAlertIsOpen(true);
                        },
                      },
                    ],
                  ]}
                  renderTrigger={({ Trigger, triggerRef }) => (
                    <Trigger
                      ref={triggerRef}
                      className="flex h-12 w-12 items-center justify-center rounded-[10px] transition-colors hover:bg-[#EEF2F5]"
                      as={InteractiveContainer}
                    >
                      <ThreeDotsIcon />
                    </Trigger>
                  )}
                />
              </div>
            ),
          },
        ]}
      />
      <Alert
        isOpen={deleteAlertIsOpen}
        onRequestClose={() => setDeleteAlertIsOpen(false)}
        buttons={[
          {
            role: "close",
            text: t({ id: "common.close", defaultMessage: "Close" }),
            onClick: () => {
              setSelectedReport(undefined);
              setDeleteAlertIsOpen(false);
            },
          },
          {
            text: t({ id: "common.delete", defaultMessage: "Delete" }),
            color: "tertiary",
            isDisabled: isDeleteLoading,
            onClick: handleDeleteReport,
          },
        ]}
        title={t({
          id: "scheduled-reports-page.delete-auto-report",
          defaultMessage: "Delete Auto Report",
        })}
        content={t(
          {
            id: "scheduled-reports-page.delete-report-confirmation",
            defaultMessage:
              "Do you really want to delete Task template {reportName}?",
          },
          {
            reportName: (
              <span className="text-semibold text-primary-800">
                {selectedReport?.name}
              </span>
            ),
          }
        )}
      />
    </>
  );
};
