export enum DeviceType {
  web = "web",
  device = "device",
}

export type AuthData = {
  accessToken: string;
  refreshToken: string;
  data: {
    agt: string;
    aud: string;
    dvc: { mac: string; platform: string };
    exp: number;
    iat: number;
    iss: string;
    jid: string;
    jti: string;
    nbf: number;
    sub: string;
    typ: string;
  };
};

export type DvcDetails = {
  model: string;
  make: string;
  os: string;
  browser: string;
};
