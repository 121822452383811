import React, { useState } from "react";
import { useTranslations, cx } from "@jugl-web/utils";
import { SidebarDrawer } from "@jugl-web/ui-components/web";
import {
  Button,
  Text,
  Pill,
  Popover,
  InteractiveContainer,
} from "@jugl-web/ui-components/cross-platform";
import { ChatMessagePayload } from "@web-src/features/chats/types";
import { v4 as uuidv4 } from "uuid";
import useActiveChat from "@web-src/features/chats/hooks/useActiveChat";
import { format } from "date-fns";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { DatePicker } from "@jugl-web/ui-components/cross-platform/DatePicker";
import { ReactComponent as AddIcon } from "./icons/add.svg";
import { ReactComponent as CloseIcon } from "./icons/close.svg";
import { ReactComponent as CalendarIcon } from "./icons/calendar2.svg";

export const ChatAddConferenceSidebar: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  setConference: (conference: ChatMessagePayload["conference"]) => void;
}> = ({ isOpen, onClose, setConference }) => {
  const { t } = useTranslations();
  const [enabledNameInput, setEnabledNameInput] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [scheduleDate, setScheduleDate] = useState<string>();
  const { dateLocale } = useLanguage();

  const { chat } = useActiveChat();
  const handleSetConference = async (instant?: boolean) => {
    if (!chat) {
      return;
    }
    const instantConference = {
      channel: uuidv4(),
      name,
    };
    const date = scheduleDate && new Date(scheduleDate);
    const scheludedConference = {
      ...instantConference,
      date: date && format(date, "MMM d, yyyy", { locale: dateLocale }),
      time: date && format(date, "pp", { locale: dateLocale }),
    };
    setConference(instant ? instantConference : scheludedConference);
    handleClose();
  };
  const handleClose = () => {
    setEnabledNameInput(false);
    setName("");
    setScheduleDate(undefined);
    onClose();
  };

  return (
    <SidebarDrawer
      className="width-[480px]"
      title={t({
        id: "chats-page.add-conference-sidebar-title",
        defaultMessage: "Start Meeting",
      })}
      onClose={handleClose}
      isOpen={isOpen}
    >
      <SidebarDrawer.Content>
        <div className="px-8 pb-12 pt-8">
          <Button
            color="primary"
            fullWidth
            uppercase
            onClick={() => handleSetConference(true)}
          >
            {t({
              id: "chats-page.add-conference-sidebar-instant-button",
              defaultMessage: "Start instant meeting",
            })}
          </Button>
        </div>
        <div className="bg-dark-100 h-3 w-full" />
        <div className="px-8 py-6">
          <Text variant="body2" className="text-grey">
            {t({
              id: "chats-page.add-conference-sidebar-or-schedule-a-meeting",
              defaultMessage: "Or Schedule a Meeting",
            })}
          </Text>
          <div className="bg-grey-100 relative mt-6 h-[72px] w-full cursor-pointer rounded-xl px-4 py-5">
            <div
              className={cx(
                "absolute left-4 right-4 top-1/2 flex -translate-y-1/2 flex-row items-center justify-between",
                {
                  hidden: enabledNameInput,
                }
              )}
              onClick={() => setEnabledNameInput(true)}
            >
              <div>
                <Text variant="body2" className="text-onyx flex items-center">
                  {t({
                    id: "chats-page.add-conference-sidebar-meeting-name",
                    defaultMessage: "Meeting name",
                  })}
                </Text>
              </div>
              <Pill
                size="md"
                className="hover:brightness-90"
                label={
                  <div className="flex items-center gap-1">
                    {t({ id: "common.add", defaultMessage: "Add" })}
                    <AddIcon />
                  </div>
                }
              />
            </div>
            <div
              className={cx(
                "absolute left-4 right-4 top-1/2 flex -translate-y-1/2 flex-row items-center justify-between",
                {
                  hidden: !enabledNameInput,
                }
              )}
            >
              <input
                type="text"
                className="m-0 inline-block h-[32px] w-full flex-1 rounded-lg border-none bg-transparent p-0 text-base outline-none"
                placeholder="Type name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {name && <CloseIcon onClick={() => setName("")} />}
            </div>
          </div>
          <div className="bg-grey-100 relative mt-6 min-h-[72px] w-full cursor-pointer rounded-xl px-4 py-5">
            <Popover
              placement="top"
              renderTrigger={({ Trigger, triggerRef }) => (
                <Trigger ref={triggerRef} as={InteractiveContainer}>
                  <div
                    className={cx("flex flex-row items-center justify-between")}
                  >
                    <div>
                      <Text
                        variant="body2"
                        className="text-onyx flex items-center"
                      >
                        {t({
                          id: "chats-page.add-conference-sidebar-date-and-time",
                          defaultMessage: "Date & Time",
                        })}
                      </Text>
                    </div>
                    {!scheduleDate && (
                      <Pill
                        size="md"
                        className="hover:brightness-90"
                        label={
                          <div className="flex items-center gap-1">
                            {t({ id: "common.add", defaultMessage: "Add" })}
                            <AddIcon />
                          </div>
                        }
                      />
                    )}

                    {scheduleDate && (
                      <CloseIcon
                        onClick={(e) => {
                          setScheduleDate(undefined);
                          e.stopPropagation();
                        }}
                      />
                    )}
                  </div>
                </Trigger>
              )}
            >
              {({ onClose: onDatePickerClose }) => (
                <DatePicker
                  initialDate={
                    scheduleDate ? new Date(scheduleDate) : new Date()
                  }
                  minDate={new Date()}
                  onSubmit={(date) => {
                    setScheduleDate(date?.toISOString());
                  }}
                  showTimePicker
                  onClose={onDatePickerClose}
                  className="w-[343px] p-4"
                />
              )}
            </Popover>

            {scheduleDate && (
              <Popover
                placement="top"
                renderTrigger={({ Trigger, triggerRef }) => (
                  <Trigger ref={triggerRef} as={InteractiveContainer}>
                    <div className="bg-grey-200 mt-3 flex cursor-pointer flex-row items-center gap-1 rounded-xl px-2 py-3">
                      <CalendarIcon />
                      <Text variant="body2" className="text-dark">
                        {format(new Date(scheduleDate), "MMM d, yyyy, p", {
                          locale: dateLocale,
                        })}
                      </Text>
                    </div>
                  </Trigger>
                )}
              >
                {({ onClose: onDatePickerClose }) => (
                  <DatePicker
                    initialDate={
                      scheduleDate ? new Date(scheduleDate) : new Date()
                    }
                    minDate={new Date()}
                    onSubmit={(date) => {
                      setScheduleDate(date?.toISOString());
                    }}
                    showTimePicker
                    onClose={onDatePickerClose}
                    className="w-[343px] p-4"
                  />
                )}
              </Popover>
            )}
          </div>
        </div>
        <div className="mt-4 w-full px-8">
          {scheduleDate && (
            <Button
              color="primary"
              fullWidth
              uppercase
              onClick={() => handleSetConference()}
            >
              {t({
                id: "chats-page.add-conference-sidebar-schedule-meeting",
                defaultMessage: "Schedule Meeting",
              })}
            </Button>
          )}
        </div>
      </SidebarDrawer.Content>
    </SidebarDrawer>
  );
};
