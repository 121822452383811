import { RtkEmptySplitApi } from "../../types";
import { AuthApiTags } from "./tags";
import { LoginMethod } from "./types";

export const addAuthApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [AuthApiTags.loginMethods],
  });

  return apiWithTags.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
      guestRegister: builder.mutation<
        { id: string },
        { data: { email: string } | { mobile: string } }
      >({
        query: ({ data }) => ({
          url: "/api/guest/register",
          method: "POST",
          data,
        }),
      }),
      guestRegisterVerify: builder.mutation<
        { token: string },
        { data: { id: string; otp: string } }
      >({
        query: ({ data }) => ({
          url: "/api/guest/verify",
          method: "POST",
          data,
          rawResponse: true,
        }),
        transformResponse: (data) => ({
          token: data.headers["x-auth-joiint"],
        }),
      }),
      addLoginMethod: builder.mutation<void, Omit<LoginMethod, "id">>({
        query: ({ type, value }) => ({
          method: "POST",
          url: `/api/auth/user_account/${type}`,
          data: {
            [type]: value,
          },
        }),
      }),
      verifyLoginMethod: builder.mutation<
        void,
        Omit<LoginMethod, "id"> & { otp: string }
      >({
        query: ({ type, value, otp }) => ({
          method: "POST",
          url: `/api/auth/user_account/${type}`,
          data: { otp, [type]: value },
        }),
        invalidatesTags: [AuthApiTags.loginMethods],
      }),

      removeLoginMethod: builder.mutation<void, LoginMethod>({
        query: ({ type, id }) => ({
          method: "DELETE",
          url: `/api/auth/user_account/${type}/${id}`,
        }),
        invalidatesTags: [AuthApiTags.loginMethods],
      }),
      listLoginMethods: builder.query<LoginMethod[], void>({
        query: () => ({
          method: "GET",
          url: `/api/auth/user_account/mobiles-emails/list`,
        }),
        providesTags: [AuthApiTags.loginMethods],
      }),
    }),
  });
};

export type AuthApi = ReturnType<typeof addAuthApi>;
