import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import React, { useMemo, useState } from "react";
import { UserEntityPreferences } from "@web-src/modules/settings/pages/SettingsPage/types";
import useConversations from "@web-src/features/chats/hooks/useConversations";
import { useNotifications } from "@web-src/modules/notifications/providers/NotificationsProvider";
import { ChatType } from "@web-src/features/chats/types";
import { MutedChatsDetails } from "./components/MutedChatsDetails";
import { ReactComponent as ChevronRightIcon } from "./assets/chevron-right.svg";

export const MutedChatsSection: React.FC<{
  userEntityPreferences: UserEntityPreferences;
}> = ({ userEntityPreferences }) => {
  const { t } = useTranslations();
  const [isMutedChatsDetailsOpened, setIsMutedChatsDetailsOpened] =
    useState(false);
  const { getChatNotificationsPreferences } = useNotifications();
  const { conversations } = useConversations();

  const infoStr = useMemo(() => {
    const mutedChatsCount = conversations.filter(
      (item) =>
        item.data.type === ChatType.chat &&
        item.data.id &&
        getChatNotificationsPreferences(item.data.id) !== "none"
    ).length;
    const mutedGroupsCount = conversations.filter(
      (item) =>
        item.data.type === ChatType.muc &&
        item.data.id &&
        getChatNotificationsPreferences(item.data.id) !== "none"
    ).length;
    return `${mutedChatsCount} private | ${mutedGroupsCount} group${
      mutedChatsCount === 1 ? "" : "s"
    }`;
  }, [conversations, getChatNotificationsPreferences]);

  return (
    <>
      <div className="flex flex-col gap-6 bg-white py-6">
        <div className="flex flex-col">
          <InteractiveContainer
            className="mx-6 flex items-center justify-between"
            onClick={() => setIsMutedChatsDetailsOpened(true)}
          >
            <div className="flex flex-col gap-1">
              <span className="text-dark">
                {t({
                  id: "settings-page.muted-chats",
                  defaultMessage: "Muted Chats",
                })}
              </span>
              <span className="text-xs text-[#828282]">{infoStr}</span>
            </div>
            <ChevronRightIcon />
          </InteractiveContainer>
        </div>
      </div>
      <MutedChatsDetails
        isOpen={isMutedChatsDetailsOpened}
        onRequestClose={() => setIsMutedChatsDetailsOpened(false)}
        userEntityPreferences={userEntityPreferences}
      />
    </>
  );
};
