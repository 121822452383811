import { useRestApiProvider } from "@jugl-web/rest-api";
import {
  EmptyListContent,
  InteractiveContainer,
  ListLoading,
  TableGrid,
} from "@jugl-web/ui-components/cross-platform";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { FC, useMemo } from "react";
import { format } from "date-fns";
import { priceDisplay } from "@jugl-web/utils";
import { ReactComponent as DownloadIcon } from "./assets/download-icon.svg";

export const InvoicesTable: FC = () => {
  const { entitiesApi } = useRestApiProvider();
  const { entityId } = useEntitySelectedProvider();
  const {
    data: invoicesList,
    isLoading: invoicesListIsLoading,
    isError: invoicesListIsError,
    refetch,
  } = entitiesApi.useGetInvoicesListQuery({
    entityId,
  });
  const invoiceList = useMemo(
    () =>
      invoicesList?.invoice_list.filter((item) => item.status !== "draft") ||
      [],
    [invoicesList]
  );
  return (
    <TableGrid
      data={invoiceList}
      className="min-h-[200px]"
      inline
      columns={[
        {
          title: "Payment date",
          content: (invoice) => (
            <div className="whitespace-nowrap">
              {format(new Date(invoice.period_start * 1000), "MMM dd, yyyy")}
            </div>
          ),
        },
        {
          title: "Number",
          content: (invoice) => (
            <div className="whitespace-nowrap">{invoice.number}</div>
          ),
        },
        {
          title: "Amount",
          content: (invoice) => (
            <div className="whitespace-nowrap">
              {priceDisplay(invoice.amount_paid, invoice.currency)}
            </div>
          ),
        },
        {
          title: "",
          grow: true,
          content: (invoice) => (
            <div className="flex flex-1 justify-end">
              <InteractiveContainer
                onClick={() => window.open(invoice.invoice_pdf, "_blank")}
              >
                <DownloadIcon />
              </InteractiveContainer>
            </div>
          ),
        },
      ]}
      emptyContent={(() => {
        if (invoicesListIsLoading) {
          return <ListLoading />;
        }
        if (invoicesListIsError) {
          return <EmptyListContent type="error" onRefetch={refetch} />;
        }
        return (
          <div className="px-8 py-6 text-[#5A6367]">
            💸 After making payment, all invoices will be listed here
          </div>
        );
      })()}
    />
  );
};
