import { cx } from "@jugl-web/utils";
import {
  ForwardRefExoticComponent,
  HTMLAttributes,
  PropsWithChildren,
  RefAttributes,
  forwardRef,
} from "react";
import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";

export const TasksColumnLayout = forwardRef<
  HTMLDivElement,
  PropsWithChildren<HTMLAttributes<HTMLDivElement>>
>((props, ref) => {
  const { children, className, ...otherProps } = props;

  return (
    <div
      ref={ref}
      className={cx(
        "relative flex max-h-full min-h-0 w-[340px] shrink-0 flex-col self-start overflow-hidden rounded-xl bg-[#EEF2F5] pb-[34px] shadow-[0_0_2px_0_rgba(0,0,0,0.16)] transition-colors",
        className
      )}
      {...otherProps}
    >
      {children}
    </div>
  );
}) as ForwardRefExoticComponent<
  PropsWithChildren<
    HTMLAttributes<HTMLDivElement> & RefAttributes<HTMLDivElement>
  >
> & {
  Header: React.FC<PropsWithChildren<{ onClick?: () => void }>>;
  EmptyMessage: React.FC<PropsWithChildren>;
  List: React.FC<PropsWithChildren<{ isDragging?: boolean }>>;
};

TasksColumnLayout.Header = ({ children, onClick }) => (
  <InteractiveContainer
    className={cx(
      "text-dark-800 py-3 px-[14px] text-sm font-medium leading-3 transition-colors",
      onClick && "hover:bg-[#E1E9EE]"
    )}
    onClick={onClick}
    isDisabled={!onClick}
  >
    {children}
  </InteractiveContainer>
);

TasksColumnLayout.EmptyMessage = ({ children }) => (
  <div className="text-dark-700 m-0 px-4 pt-3 text-sm font-normal leading-3">
    {children}
  </div>
);

TasksColumnLayout.List = ({ children, isDragging }) => (
  <div
    className={cx(
      "jugl__custom-scrollbar mx-2 flex flex-grow flex-col gap-6 p-2",
      isDragging ? "overflow-hidden" : "overflow-y-auto"
    )}
  >
    {children}
  </div>
);
