import { TaskViewsProvider } from "@jugl-web/domain-resources/tasks";
import { TaskFilteringProvider } from "@jugl-web/domain-resources/tasks/components/TaskFilteringProvider";
import { TaskSortingProvider } from "@jugl-web/domain-resources/tasks/components/TaskSortingProvider";
import { HeaderBreadcrumbsItem } from "@jugl-web/ui-components/web/HeaderBreadcrumbs";
import { useTranslations } from "@jugl-web/utils";
import { TaskListModeButton } from "@web-src/components/TaskListModeButton";
import { TasksPageLayout } from "@web-src/components/TasksPageLayout";
import { TasksContent } from "@web-src/features/tasks/TasksContent";
import { TasksControlBar } from "@web-src/features/tasks/TasksControlBar";
import {
  TasksPageContextProvider,
  useTasksPageContext,
} from "@web-src/features/tasks/TasksPageContext";
import { useMarkModuleAsRead } from "@web-src/hooks/useMarkModuleAsRead";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useMemo } from "react";

const InnerTasks = () => {
  const { taskListMode, setTaskListMode } = useTasksPageContext();

  const { entityId } = useEntitySelectedProvider();

  const { generateUrl } = useNavigation();
  const { t } = useTranslations();

  const breadcrumbs = useMemo<HeaderBreadcrumbsItem[]>(
    () => [
      {
        title: t({
          id: "tasks-page.tasks",
          defaultMessage: "Tasks",
        }),
        href: generateUrl("tasksTasks"),
      },
    ],
    [generateUrl, t]
  );

  useMarkModuleAsRead(entityId, "task");

  return (
    <TaskFilteringProvider entityId={entityId}>
      <TaskSortingProvider>
        <TaskViewsProvider entityId={entityId}>
          <TasksPageLayout
            breadcrumbs={breadcrumbs}
            topBarRightContent={
              <TaskListModeButton
                isTeamTasks={taskListMode === "team"}
                onChange={() =>
                  setTaskListMode(taskListMode === "team" ? "personal" : "team")
                }
              />
            }
          >
            <TasksControlBar />
            <TasksContent />
          </TasksPageLayout>
        </TaskViewsProvider>
      </TaskSortingProvider>
    </TaskFilteringProvider>
  );
};

export const Tasks = () => (
  <TasksPageContextProvider>
    <InnerTasks />
  </TasksPageContextProvider>
);
