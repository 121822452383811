import { TaskChecklistHandle } from "@jugl-web/domain-resources/tasks";
import {
  TaskFormStateProvider,
  useTaskFormState,
} from "@jugl-web/domain-resources/tasks/hooks/useTaskFormState";
import { Alert } from "@jugl-web/ui-components/cross-platform";
import { FC, useRef, useState } from "react";
import { useTranslations } from "@jugl-web/utils";
import { TaskFormDialog } from "../TaskFormDialog";
import { ManageTaskTemplateDialogContent } from "./components/ManageTaskTemplateDialogContent";
import { ManageTaskTemplateDialogProps } from "./types";

export const ManageTaskTemplateDialog: FC<ManageTaskTemplateDialogProps> = ({
  isOpen,
  editingTemplateId,
  onClose,
  ...props
}) => {
  const [isLeaveConfirmationDialogOpen, setIsLeaveConfirmationDialogOpen] =
    useState(false);
  const { t } = useTranslations();

  const { formContext, isDirty } = useTaskFormState();

  const titleTextareaRef = useRef<HTMLTextAreaElement | null>(null);
  const taskChecklistRef = useRef<TaskChecklistHandle | null>(null);

  const handleSafeClose = () => {
    if (isDirty()) {
      setIsLeaveConfirmationDialogOpen(true);
      return;
    }

    onClose();
  };

  return (
    <>
      <TaskFormDialog
        isOpen={isOpen}
        header={{
          type: "title",
          title: editingTemplateId
            ? t({
                id: "tasks-page.edit-task-template",
                defaultMessage: "Edit task template",
              })
            : t({
                id: "tasks-page.new-task-template",
                defaultMessage: "New task template",
              }),
        }}
        initialFocus={titleTextareaRef}
        onClose={handleSafeClose}
      >
        <TaskFormStateProvider context={formContext}>
          <ManageTaskTemplateDialogContent
            key={editingTemplateId}
            isOpen={isOpen}
            editingTemplateId={editingTemplateId}
            titleTextareaRef={titleTextareaRef}
            taskChecklistRef={taskChecklistRef}
            onClose={onClose}
            onSafeClose={handleSafeClose}
            {...props}
          />
        </TaskFormStateProvider>
      </TaskFormDialog>
      <Alert
        isOpen={isLeaveConfirmationDialogOpen}
        title={t({
          id: "tasks-page.discard-changes-warning-title",
          defaultMessage: "Discard changes?",
        })}
        content={t({
          id: "tasks-page.discard-template-info",
          defaultMessage:
            "If you discard, the template information won't be saved",
        })}
        buttons={[
          {
            text: t({ id: "common.cancel", defaultMessage: "Cancel" }),
            role: "close",
          },
          {
            text: t({ id: "common.discard", defaultMessage: "Discard" }),
            color: "primary",
            onClick: (_, actions) => {
              onClose();
              actions.closeAlert();
            },
          },
        ]}
        onRequestClose={() => setIsLeaveConfirmationDialogOpen(false)}
      />
    </>
  );
};
