import React, { memo } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { UserProfile, useRestApiProvider } from "@jugl-web/rest-api";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslations } from "@jugl-web/utils";
import {
  Button,
  LoadingAnimation,
  TextField,
} from "@jugl-web/ui-components/cross-platform";
import { useProfileUpdateToast } from "../../hooks/useProfileUpdateToast";

type Inputs = Pick<UserProfile["general"], "first_name" | "last_name">;

const schema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  gender: yup.string(),
  birth: yup.date(),
});

const PersonalInfoFormContainer = memo(() => {
  const { usersApi } = useRestApiProvider();
  const { data } = usersApi.useGetUserProfileQuery({});
  return <PersonalInfoFormComponent {...{ data }} />;
});

const PersonalInfoFormComponent: React.FC<{
  data: UserProfile | undefined;
}> = ({ data }) => (
  <div className="w-1/2 p-10">
    {!data ? <LoadingAnimation /> : <PersonalInfoForm {...{ data }} />}
  </div>
);

const PersonalInfoForm: React.FC<{ data: UserProfile }> = ({ data }) => {
  const { usersApi } = useRestApiProvider();
  const [updateProfile, { isLoading }] =
    usersApi.useUpdateUserProfileMutation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: data.general.first_name,
      last_name: data.general.last_name,
    },
  });
  const { showProfileUpdatedSnackbar } = useProfileUpdateToast();

  const onSubmit: SubmitHandler<Inputs> = async (params) => {
    const { first_name: firstName, last_name: lastName } = { ...params };
    const res = await updateProfile({
      user_id: data?.general.id,
      data: {
        type: "general",
        first_name: firstName,
        last_name: lastName,
      },
    });
    if ("data" in res) {
      reset({ first_name: firstName, last_name: lastName });
      showProfileUpdatedSnackbar();
    }
  };
  const { t } = useTranslations();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex w-full gap-[70px]">
        <div className="flex w-1/2 flex-col gap-12">
          <TextField
            isRequired
            label={t({
              id: "form-controls.first-name.label",
              defaultMessage: "First Name",
            })}
            isFullWidth
            {...register("first_name")}
          />
          <Button
            isDisabled={!isValid || !isDirty || isLoading}
            fullWidth
            variant="contained"
            type="submit"
          >
            {t({
              id: "common.save",
              defaultMessage: "Save",
            })}
          </Button>
        </div>
        <div className="w-1/2">
          <TextField
            isRequired
            label={t({
              id: "form-controls.last-name.label",
              defaultMessage: "Last Name",
            })}
            isFullWidth
            {...register("last_name")}
          />
        </div>
      </div>
    </form>
  );
};

export default PersonalInfoFormContainer;
