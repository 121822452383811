import React, {
  useContext,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import { cx, getUniqueId, useTranslations } from "@jugl-web/utils";
import { Fab } from "@jugl-web/ui-components/cross-platform/Fab";
import { Button } from "@jugl-web/ui-components/cross-platform";
import { useMatch } from "react-router-dom";
import {
  PhoenixSocketContext,
  PhxResponse,
} from "../../providers/PheonixSocket";
import { ReactComponent as RobotIcon } from "./icons/robot.svg";
import { ReactComponent as CrossIcon } from "./icons/cross.svg";
import { ChatBotMessageBubble } from "./components/ChatBotMessageBubble.tsx";
import { ChatBotRestartConfirmationDialog } from "./components/ChatBotRestartConfirmationDialog";
import { formatTimeLabel } from "./utils";
import {
  ChannelResponse,
  ChatBotMessage,
  ChatBotReceivedMessage,
} from "./types";

export const ChatBot: React.FC = () => {
  const tasksPageMatch = useMatch(`/:entityId/tasks`);
  const workspaceTasksPageMatch = useMatch(
    `/:entityId/chats/:activeChatId/tasks`
  );
  const isVisible = !!(tasksPageMatch || workspaceTasksPageMatch);
  const { t } = useTranslations();
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);
  const [
    isRestartChatBotConfirmationDialogOpen,
    setIsRestartChatBotConfirmationDialogOpen,
  ] = useState(false);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const { channel } = useContext(PhoenixSocketContext);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState<string>();
  const [messages, setMessages] = useState<ChatBotMessage[]>([]);
  const [choices, setChoices] = useState<string[]>([]);
  const [isConversationFinished, setIsConversationFinished] = useState(false);

  const init = useCallback(() => {
    if (channel) {
      channel.push("bot:task:init", {});
      setIsInitialized(true);
    }
  }, [channel]);

  const restart = useCallback(() => {
    setChoices([]);
    setMessages([]);
    setError(undefined);
    setIsTyping(false);
    setIsConversationFinished(false);
    init();
  }, [init]);

  const sendMessage = useCallback(
    (message: ChatBotReceivedMessage, currentChoices?: string[]) => {
      if (channel) {
        let mergedChoices: string[] = [];
        if (currentChoices) {
          mergedChoices = [...choices, ...currentChoices];
          setChoices(mergedChoices);
        }
        channel.push("bot:task", {
          next: message.next,
          next_params: message.next_params ? mergedChoices : undefined,
        });
        if (message.next_params) setChoices([]);
        if (message.next !== "finish") {
          setMessages((prev) => [
            ...prev.filter(
              (oldMessage) =>
                oldMessage.chatBotReceivedMessage?.action !== "user_select"
            ),
            {
              isSelf: true,
              chatBotSentMessage: {
                id: getUniqueId(),
                message: message?.message || "",
                timeLabel: formatTimeLabel(new Date()),
                icon: message?.icon || "",
              },
            },
          ]);
        }
      }
    },
    [channel, choices]
  );

  useEffect(() => {
    if (!channel) return undefined;
    const botTaskListener = (e: PhxResponse<ChannelResponse>) => {
      if (e.response && e.response.type === "in-progress") {
        setIsTyping(true);
      } else if (e.response && Array.isArray(e.response.data)) {
        setIsTyping(false);
        const transformedMessages = e.response.data.map(
          (chatBotReceivedMessage: ChatBotReceivedMessage) => ({
            isSelf: false,
            chatBotReceivedMessage: {
              ...chatBotReceivedMessage,
              time_label: formatTimeLabel(new Date()),
              id: getUniqueId(),
              icon:
                chatBotReceivedMessage.action === "report"
                  ? messages[messages.length - 1].chatBotSentMessage?.icon
                  : chatBotReceivedMessage.icon,
            },
          })
        );
        setMessages((prev) => [...prev, ...transformedMessages]);
        if (transformedMessages[0].chatBotReceivedMessage?.next === "finish") {
          sendMessage(transformedMessages[0].chatBotReceivedMessage);
        }
        if (
          transformedMessages[0].chatBotReceivedMessage?.message ===
          "You're Welcome!"
        )
          setIsConversationFinished(true);
      }
      if (e.response.error) {
        setError(e.response.error);
      }
    };
    const subscriptionRef = channel.on("phx_bot_task", botTaskListener);
    return () => {
      channel.off("phx_bot_task", subscriptionRef);
    };
  }, [channel, messages, sendMessage]);

  const handleFabClick = () => {
    setIsWidgetOpen(!isWidgetOpen);
    if (!isInitialized) {
      init();
    }
  };

  const handleCrossClick = () => {
    setIsWidgetOpen(!isWidgetOpen);
  };

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (isWidgetOpen && isVisible) {
      scrollToBottom();
    }
  }, [isWidgetOpen, messages, isTyping, isVisible]);

  if (!isVisible) {
    return null;
  }
  return (
    <>
      <Fab
        icon={<RobotIcon className="h-10 w-10" />}
        size="lg"
        variant="primary"
        onClick={handleFabClick}
        className="absolute bottom-16 right-16 z-10"
      />
      <div
        className={cx(
          isWidgetOpen
            ? "scale-100 scroll-smooth opacity-100"
            : "pointer-events-none scale-95 opacity-0",
          "fixed bottom-12 right-8 z-10 h-[500px] w-[400px] origin-bottom transform-gpu rounded-3xl bg-gray-100 shadow-lg transition-all duration-500",
          "flex flex-col overflow-x-hidden scroll-smooth"
        )}
      >
        <header className="z-10 flex h-[72px] w-[400px] shrink-0 items-center justify-between rounded-t-3xl bg-white">
          <div className="bg-primary pointer-default ml-6 h-9 w-9 rounded-full">
            <RobotIcon className="ml-1.5 mt-px h-8 w-6" />
          </div>
          <span className="mr-20 text-lg font-semibold">
            {t({
              id: "task-page.chatbot-title",
              defaultMessage: "Jugl AI",
            })}
          </span>
          <Button
            variant="text"
            className="text-primary cursor-pointer border-none bg-transparent text-base"
            onClick={() => setIsRestartChatBotConfirmationDialogOpen(true)}
            isDisabled={isTyping}
          >
            {t({
              id: "common.restart",
              defaultMessage: "Restart",
            })}
          </Button>
          <button
            type="button"
            className="cursor-pointer rounded border-none bg-transparent"
            onClick={handleCrossClick}
          >
            <CrossIcon className="mt-1 mr-4 h-10 w-10" />
          </button>
        </header>
        <div className="relative flex grow flex-col overflow-y-auto">
          {error && <div className="bg-primary-100 rounded-xl">{error}</div>}
          <div
            ref={messagesContainerRef}
            className={cx(
              "flex w-full flex-col gap-4 overflow-y-auto py-5 pl-14 pr-7",
              isConversationFinished && "mb-20"
            )}
          >
            {messages.map((message, idx) => {
              let isLastInGroup = false;
              const nextMessage = messages[idx + 1];
              if (
                message.isSelf !== nextMessage?.isSelf ||
                nextMessage?.chatBotReceivedMessage?.action === "user_select"
              ) {
                isLastInGroup = true;
              }
              return (
                <ChatBotMessageBubble
                  message={{
                    ...message,
                  }}
                  key={
                    message.chatBotReceivedMessage?.id ||
                    message.chatBotSentMessage?.id
                  }
                  onSendMessage={sendMessage}
                  isLastInGroup={isLastInGroup}
                />
              );
            })}
            {isTyping && <ChatBotMessageBubble isTyping />}
          </div>
          {isConversationFinished && (
            <div className="absolute bottom-0 left-0 flex h-20 w-full items-center justify-center bg-white px-4">
              <Button
                uppercase
                onClick={() => setIsRestartChatBotConfirmationDialogOpen(true)}
                fullWidth
              >
                {t({
                  id: "tasks-page.restart-bot",
                  defaultMessage: "Restart Bot",
                })}
              </Button>
            </div>
          )}
        </div>
      </div>
      <ChatBotRestartConfirmationDialog
        isOpen={isRestartChatBotConfirmationDialogOpen}
        onClose={() => setIsRestartChatBotConfirmationDialogOpen(false)}
        onRestart={restart}
      />
    </>
  );
};
