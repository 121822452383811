import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuthState } from "@web-src/features/auth/authSlice";
import PropTypes from "prop-types";
import { PageRoute } from "@web-src/features/app/types";
import Loader from "@web-src/components/Loader";

function PublicRoute({ component }) {
  const { isAuthenticated } = useSelector(selectAuthState);

  switch (isAuthenticated) {
    case true:
      return (
        <Navigate
          to={{
            pathname: `/${PageRoute.orgSelect}`,
          }}
        />
      );
    case false:
      return component;
    default:
      return <Loader className="h-screen w-screen" />;
  }
}

PublicRoute.propTypes = {
  component: PropTypes.node,
};

export default PublicRoute;
