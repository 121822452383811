import { ReactNode } from "react";
import { AppVariant, useTranslations } from "@jugl-web/utils";
import { Button, ButtonProps } from "../Button";
import { Dialog } from "../Dialog";
import { PlainButton } from "../PlainButton";
import { ReactComponent as CloseIcon } from "./icons/close.svg";
import { MobileDrawer } from "../../mobile";

export interface ConfirmationPopupButtonProps
  extends Pick<ButtonProps, "variant" | "color" | "isDisabled" | "onClick"> {
  label: string;
}

export interface ConfirmationPopupProps {
  variant: AppVariant;
  isOpen: boolean;
  title: string;
  message: ReactNode;
  buttons: ConfirmationPopupButtonProps[];
  icon?: ReactNode;
  hasCancelButton?: boolean;
  onRequestClose: () => void;
}

export const ConfirmationPopup = ({
  variant,
  isOpen,
  title,
  message,
  buttons,
  icon,
  hasCancelButton,
  onRequestClose,
}: ConfirmationPopupProps) => {
  const { t } = useTranslations();

  if (variant === "mobile") {
    return (
      <MobileDrawer
        isOpen={isOpen}
        header={{ title }}
        hasBackdrop
        size="auto"
        onClose={onRequestClose}
      >
        <div className="px-4 pb-8">
          {icon && <div className="mb-4">{icon}</div>}
          <p className="mt-0 mb-12 text-sm text-[#363636]">{message}</p>
          <div className="flex gap-4">
            {hasCancelButton && (
              <Button
                variant="contained"
                color="grey"
                fullWidth
                onClick={onRequestClose}
              >
                Cancel
              </Button>
            )}
            {buttons.map(({ label, ...buttonProps }, index) => (
              <Button key={+index} fullWidth {...buttonProps}>
                {label}
              </Button>
            ))}
          </div>
        </div>
      </MobileDrawer>
    );
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onRequestClose}
      className="px-28 pt-14 pb-10 text-center"
    >
      <PlainButton onClick={onRequestClose} className="absolute right-4 top-4">
        <CloseIcon />
      </PlainButton>
      {icon && <div className="mb-4">{icon}</div>}
      <h3 className="text-dark m-0 mb-4 text-lg font-medium leading-[22px]">
        {title}
      </h3>
      <p className="text-grey leading-2 mt-0 mb-[58px] text-[15px]">
        {message}
      </p>
      <div className="flex items-center justify-center gap-[30px]">
        {hasCancelButton && (
          <Button
            variant="outlined"
            className="w-64"
            uppercase
            onClick={onRequestClose}
          >
            {t({
              id: "common.cancel",
              defaultMessage: "Cancel",
            })}
          </Button>
        )}
        {buttons.map(({ label, ...buttonProps }, index) => (
          <Button key={+index} className="w-64" uppercase {...buttonProps}>
            {label}
          </Button>
        ))}
      </div>
    </Dialog>
  );
};
