import { cx } from "@jugl-web/utils";
import { ReactNode } from "react";
import { Drawer, DrawerProps } from "../../cross-platform/Drawer";
import { DrawerHeader } from "../DrawerHeader";

export interface SidebarDrawerProps
  extends Pick<
    DrawerProps,
    "isOpen" | "onClose" | "hasBackdrop" | "initialFocus"
  > {
  children: ReactNode;
  title: ReactNode;
  className?: string;
  onGoBack?: () => void;
  inline?: boolean;
}

export const SidebarDrawer = ({
  children,
  isOpen,
  title,
  hasBackdrop,
  initialFocus,
  className,
  onClose,
  onGoBack,
  inline,
}: SidebarDrawerProps) => {
  if (inline) {
    if (!isOpen) {
      return null;
    }
    return (
      <div className="flex h-[100vh] flex-col">
        <DrawerHeader
          type="title"
          title={title}
          onClose={onClose}
          onGoBack={onGoBack}
        />
        {children}
      </div>
    );
  }
  return (
    <Drawer
      isOpen={isOpen}
      size="md"
      placement="right"
      hasBackdrop={hasBackdrop}
      onClose={onClose}
      initialFocus={initialFocus}
      className={cx("flex flex-col", className)}
    >
      <DrawerHeader
        type="title"
        title={title}
        onClose={onClose}
        onGoBack={onGoBack}
      />
      {children}
    </Drawer>
  );
};

export interface SidebarDrawerContentProps {
  children: ReactNode;
  className?: string;
}

SidebarDrawer.Content = ({
  children,
  className,
}: SidebarDrawerContentProps) => (
  <div className={cx("grow overflow-y-auto", className)}>{children}</div>
);

export interface SidebarDrawerActionsProps {
  children: ReactNode;
  className?: string;
}

SidebarDrawer.Actions = ({
  children,
  className,
}: SidebarDrawerContentProps) => (
  <div className={cx("px-8 pb-12 pt-4", className)}>{children}</div>
);
