import { TemplateFilter } from "@jugl-web/domain-resources/tasks/hooks/useTasksTemplates";
import { Menu } from "@jugl-web/ui-components/cross-platform";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { ControlBarButton } from "@jugl-web/ui-components/web/ControlBarButton";
import { ExpandableSearchBar } from "@jugl-web/ui-components/web/ExpandableSearchBar";
import { cx, useTranslations } from "@jugl-web/utils";
import { TasksPageLayout } from "@web-src/components/TasksPageLayout";
import { useTaskTemplatesPageContext } from "@web-src/features/tasks/TaskTemplatesPageContext";
import { FC, useMemo } from "react";
import { ManageTaskTemplateDialog } from "../ManageTaskTemplateDialog";
import { ReactComponent as AddBlankIcon } from "./assets/add-blank.svg";
import { ReactComponent as FilterIcon } from "./assets/filter.svg";

interface TaskTemplatesControlBarProps {
  entityId: string;
  meId: string;
  folderId: string;
}

export const TaskTemplatesControlBar: FC<TaskTemplatesControlBarProps> = ({
  entityId,
  meId,
  folderId,
}) => {
  const {
    searchQuery,
    filter,
    setSearchQuery,
    setFilter,
    isNewTemplateDialogOpen,
    setIsNewTemplateDialogOpen,
  } = useTaskTemplatesPageContext();

  const { t } = useTranslations();

  const filterLabelById = useMemo<Record<TemplateFilter, string>>(
    () => ({
      [TemplateFilter.AllTemplates]: t({
        id: "task-templates-page.all-templates-filter",
        defaultMessage: "All templates",
      }),
      [TemplateFilter.MyTemplates]: t({
        id: "task-templates-page.my-templates-filter",
        defaultMessage: "My templates",
      }),
      [TemplateFilter.CreatedByJugl]: t({
        id: "task-templates-page.created-by-jugl-filter",
        defaultMessage: "Created by Jugl",
      }),
      [TemplateFilter.CreatedByOthers]: t({
        id: "task-templates-page.created-by-others-filter",
        defaultMessage: "Created by others",
      }),
    }),
    [t]
  );

  const isFilterApplied = filter !== TemplateFilter.AllTemplates;

  return (
    <>
      <TasksPageLayout.ControlBar>
        <div className="flex w-full items-center justify-between gap-4">
          <div className="flex items-center gap-4">
            <ExpandableSearchBar
              color="white"
              size="lg"
              isAlwaysExpanded
              onSearch={setSearchQuery}
              defaultValue={searchQuery}
            />
            <Menu
              className="w-[250px] py-2"
              placement="bottom-start"
              sections={[
                [
                  {
                    id: TemplateFilter.AllTemplates,
                    label: filterLabelById[TemplateFilter.AllTemplates],
                    toggle: "radio",
                  },
                  {
                    id: TemplateFilter.MyTemplates,
                    label: filterLabelById[TemplateFilter.MyTemplates],
                    toggle: "radio",
                  },
                  // Will be reverted once the Jugl templates (#1171) are implemented
                  // {
                  //   id: TemplateFilter.CreatedByJugl,
                  //   label: filterLabelById[TemplateFilter.CreatedByJugl],
                  //   toggle: "radio",
                  // },
                  {
                    id: TemplateFilter.CreatedByOthers,
                    label: filterLabelById[TemplateFilter.CreatedByOthers],
                    toggle: "radio",
                  },
                ],
              ]}
              renderTrigger={({ Trigger, triggerRef, isOpen }) => (
                <Trigger
                  ref={triggerRef}
                  as={ControlBarButton}
                  label={filterLabelById[filter]}
                  startSlot={
                    <span className="relative flex items-center justify-center">
                      <FilterIcon className="fill-grey" />
                      <div
                        className={cx(
                          "bg-gradients-success absolute right-0 top-0 box-content h-2 w-2 -translate-y-1/3 translate-x-1/3 rounded-full border-2 border-solid border-white transition-opacity",
                          isFilterApplied ? "opacity-100" : "opacity-0"
                        )}
                      />
                    </span>
                  }
                  isActive={isOpen}
                />
              )}
              onSelect={(item, _, close) => {
                setFilter(item.id as TemplateFilter);
                close();
              }}
              selectedId={filter}
            />
          </div>
          <Button
            className="h-10 px-10"
            iconEnd={<AddBlankIcon />}
            onClick={() => setIsNewTemplateDialogOpen(true)}
          >
            {t({
              id: "tasks-page.new-template",
              defaultMessage: "New template",
            })}
          </Button>
        </div>
      </TasksPageLayout.ControlBar>
      <ManageTaskTemplateDialog
        entityId={entityId}
        meId={meId}
        folderId={folderId}
        isOpen={isNewTemplateDialogOpen}
        onClose={() => setIsNewTemplateDialogOpen(false)}
      />
    </>
  );
};
