import {
  SupportedNotificationsModule,
  useRestApiProvider,
} from "@jugl-web/rest-api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useCallback } from "react";

interface UseModuleNotificationsUnreadIndicatorOptions {
  entityId: string | undefined;
  module: SupportedNotificationsModule;
}

export const useModuleNotificationsUnreadIndicator = ({
  entityId,
  module,
}: UseModuleNotificationsUnreadIndicatorOptions) => {
  const { moduleNotificationsApi } = useRestApiProvider();

  const { data } =
    moduleNotificationsApi.useGetModuleNotificationsUnreadIndicatorQuery(
      entityId ? { entityId, module } : skipToken
    );

  const [markModuleNotificationsUnreadIndicatorAsRead] =
    moduleNotificationsApi.useMarkModuleNotificationsUnreadIndicatorAsReadMutation();

  const isUnread = !!data?.unread;

  const markAsRead = useCallback(() => {
    if (!entityId) {
      return;
    }

    markModuleNotificationsUnreadIndicatorAsRead({ entityId, module });
  }, [entityId, markModuleNotificationsUnreadIndicatorAsRead, module]);

  return {
    isUnread,
    markAsRead,
  };
};
