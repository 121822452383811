import { PageNavParams } from "@web-src/modules/navigation/types";
import { ModulePagesConfig } from "@web-src/modules/navigation/types/ModulePagesConfigs";
import ConferencePage from "./ConferencePage";

export type ConferencePagesNavigationParams = {
  conferenceConference: PageNavParams<{ channelId: string }>;
};

export const conferencePagesNavigationConfig: ModulePagesConfig<ConferencePagesNavigationParams> =
  {
    conferenceConference: {
      element: <ConferencePage />,
      path: "conference/:channelId",
      isPrivate: true,
    },
  };
