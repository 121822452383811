import React, { useRef, useEffect, useState } from "react";
import {
  useTranslations,
  convertSnakeCaseToReadableString,
} from "@jugl-web/utils";
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import Overlay from "ol/Overlay";
import { fromLonLat } from "ol/proj";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Feature from "ol/Feature";
import TileJSON from "ol/source/TileJSON";
import Point from "ol/geom/Point";
import LineString from "ol/geom/LineString";
import { Icon, Style, Stroke } from "ol/style";
import { environment } from "@web-src/environments/environment";
import BaseLayer from "ol/layer/Base";
import { createEmpty, extend } from "ol/extent";
import {
  Event,
  EventsLocations,
  LocationEvents,
} from "@jugl-web/rest-api/location/types";
import { parseISO } from "date-fns";
import { utcToZonedTime, format } from "date-fns-tz";
import { grey } from "@jugl-web/ui-components/cross-platform/Colors/colors";
import gpsConnectedImage from "./assets/gps-connected.png";
import clockOutImage from "./assets/clock-out.png";
import clockInImage from "./assets/clock-in.png";
import gpsLostImage from "./assets/gps-lost.png";
import pausedImage from "./assets/paused.png";
import resumedImage from "./assets/resumed.png";

import visitedLocationImage from "./assets/visited-location.png";

const startPointIconStyle = new Style({
  image: new Icon({
    anchor: [0.5, 1],
    src: clockInImage,
    scale: 0.25,
  }),
  zIndex: 999,
});

const endPointIconStyle = new Style({
  image: new Icon({
    anchor: [0.5, 0],
    src: clockOutImage,
    scale: 0.25,
  }),
  zIndex: 999,
});
const gpsLostIconStyle = new Style({
  image: new Icon({
    anchor: [0.5, 0],
    src: gpsLostImage,
    scale: 0.25,
  }),
  zIndex: 999,
});
const gpsConnectedIconStyle = new Style({
  image: new Icon({
    anchor: [0.5, 0],
    src: gpsConnectedImage,
    scale: 0.25,
  }),
  zIndex: 999,
});
const visitedLocationIconStyle = new Style({
  image: new Icon({
    anchor: [0.5, 0.5],
    src: visitedLocationImage,
    scale: 0.25,
  }),
  zIndex: 999,
});
const resumedIconStyle = new Style({
  image: new Icon({
    anchor: [0.5, 0.5],
    src: resumedImage,
    scale: 0.8,
  }),
  zIndex: 999,
});
const pausedIconStyle = new Style({
  image: new Icon({
    anchor: [0.5, 0.5],
    src: pausedImage,
    scale: 0.8,
  }),
  zIndex: 999,
});

export const LocationMap: React.FC<{
  events?: Event[];
  locationToFocus?: { lat: number; lon: number };
  timezone: string;
}> = ({ events, locationToFocus, timezone }) => {
  const mapElement = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<Map>();
  const tooltipContainer = useRef<HTMLDivElement>(null);
  const hideTimeout = useRef<ReturnType<typeof setTimeout>>();
  const { t } = useTranslations();
  useEffect(() => {
    if (!mapElement.current) {
      return;
    }
    const newMap: Map = new Map({
      target: mapElement.current,
      layers: [
        new TileLayer({
          source: new TileJSON({
            url: `https://api.maptiler.com/maps/streets-v2/tiles.json?key=${environment.mapTilerKey}`,
            tileSize: 512,
            crossOrigin: "anonymous",
          }),
        }),
      ],
      view: new View({
        center: fromLonLat([0, 0]),
        zoom: 3,
      }),
    });
    newMap.setTarget(mapElement.current);
    setMap(newMap);
  }, []);

  const tooltip = new Overlay({
    element: tooltipContainer.current || undefined,
  });
  map?.addOverlay(tooltip);

  useEffect(() => {
    const filteredEvents =
      events &&
      events.filter(
        (event) => event.loc?.length > 0 && event.type !== LocationEvents.idle
      );
    const idleLocations =
      events &&
      events
        .filter((event) => event.type === LocationEvents.idle)
        .reduce((acc: EventsLocations[], event) => {
          const locPts = (event.loc && [event.loc[event.loc.length - 1]]) || [];
          return [...acc, ...locPts];
        }, []);
    const points =
      events &&
      events.filter(
        (event) =>
          (event.type === LocationEvents.clock_in ||
            event.type === LocationEvents.clock_out ||
            event.type === LocationEvents.online ||
            event.type === LocationEvents.offline ||
            event.type === LocationEvents.location_enabled ||
            event.type === LocationEvents.location_disabled ||
            event.type === LocationEvents.paused ||
            event.type === LocationEvents.resumed) &&
          event.loc?.length
      );
    const drivingLocations =
      events && events.filter((event) => event.type === LocationEvents.driving);
    if (mapElement.current && filteredEvents && filteredEvents.length > 0) {
      const lineSources =
        drivingLocations &&
        drivingLocations.map((event) => {
          const lineFeature =
            new Feature({
              geometry: new LineString(
                event.loc.map((el) =>
                  fromLonLat([el?.loc_pts?.lon, el?.loc_pts?.lat])
                )
              ),
            }) || [];
          lineFeature.setStyle(
            new Style({
              stroke: new Stroke({
                color: grey.A100,
                width: 4,
              }),
              zIndex: 100,
            })
          );
          return new VectorSource({
            features: [lineFeature],
          });
        });
      const visitedLocations =
        idleLocations?.reduce(
          (
            acc: {
              acc: number;
              lat: number;
              lon: number;
              speed: number;
              time: string;
            }[],
            idleEvent
          ) => {
            const locPts = [{ time: idleEvent?.time, ...idleEvent?.loc_pts }];
            return [...acc, ...locPts];
          },
          []
        ) || [];
      const visitedLocationFeatures = visitedLocations.map((loc) => {
        const visitedLocationCoordinate = fromLonLat([loc.lon, loc.lat]);
        const visitedLocation = new Feature({
          geometry: new Point(visitedLocationCoordinate),
          event: LocationEvents.idle,
          time: loc.time,
        });
        visitedLocation.setStyle(visitedLocationIconStyle);
        return visitedLocation;
      });
      const pointsFeature =
        points?.map((locationEvent) => {
          const cordinates = fromLonLat([
            locationEvent?.loc && locationEvent?.loc[0]?.loc_pts?.lon,
            locationEvent?.loc && locationEvent?.loc[0]?.loc_pts?.lat,
          ]);
          const point = new Feature({
            geometry: new Point(cordinates),
            event: locationEvent.type,
            time: locationEvent.start_time,
          });
          if (locationEvent.type === LocationEvents.clock_in) {
            point.setStyle(startPointIconStyle);
          }

          if (locationEvent.type === LocationEvents.online) {
            point.setStyle(gpsConnectedIconStyle);
          }
          if (locationEvent.type === LocationEvents.offline) {
            point.setStyle(gpsLostIconStyle);
          }
          if (
            locationEvent.type === LocationEvents.clock_out ||
            locationEvent.type === LocationEvents.offline
          ) {
            point.setStyle(endPointIconStyle);
          }
          if (locationEvent.type === LocationEvents.location_enabled) {
            point.setStyle(gpsConnectedIconStyle);
          }
          if (locationEvent.type === LocationEvents.location_disabled) {
            point.setStyle(gpsLostIconStyle);
          }
          if (locationEvent.type === LocationEvents.paused) {
            point.setStyle(pausedIconStyle);
          }
          if (locationEvent.type === LocationEvents.resumed) {
            point.setStyle(resumedIconStyle);
          }
          return point;
        }) || [];
      const pointsVectorSource = new VectorSource({
        features: pointsFeature,
      });

      const vectorLayer2 = new VectorLayer({
        source: pointsVectorSource,
      });
      const lineLayers =
        (lineSources &&
          lineSources.map(
            (vectorSource) =>
              new VectorLayer({
                source: vectorSource,
              })
          )) ||
        [];
      const visitedLocationLayers = new VectorLayer({
        source: new VectorSource({
          features: visitedLocationFeatures,
        }),
      });
      const layers = map?.getLayers().getArray();
      const newLayersArray = [map?.getLayers().item(0)].filter(
        (layer): layer is BaseLayer => layer !== undefined
      );
      map?.setLayers(newLayersArray);
      layers?.forEach((layer) => {
        if (layer !== map?.getLayers().item(0)) {
          map?.removeLayer(layer);
        }
      });

      map
        ?.getLayers()
        .extend([vectorLayer2, ...lineLayers, visitedLocationLayers]);
      map?.getView().setZoom(13);
      map
        ?.getView()
        .setCenter(
          fromLonLat([
            (locationToFocus && locationToFocus.lon) ||
              filteredEvents[0]?.loc[0]?.loc_pts?.lon,
            (locationToFocus && locationToFocus.lat) ||
              filteredEvents[0]?.loc[0]?.loc_pts?.lat,
          ])
        );
      const combinedExtent = createEmpty();
      const vectorLayers = [vectorLayer2, ...lineLayers, visitedLocationLayers];
      const view = map?.getView();
      const padding = [50, 50, 50, 50];
      if (!locationToFocus) {
        vectorLayers?.forEach((layer) => {
          const source = layer.getSource();
          const layerExtent = source?.getExtent();
          if (layerExtent) {
            extend(combinedExtent, layerExtent);
          }
        });
        view?.fit(combinedExtent, { padding, maxZoom: 13 });
      }
    }
  }, [events, events?.length, locationToFocus, map]);
  if (map) {
    map.on("pointermove", (event) => {
      const feature = map.forEachFeatureAtPixel(
        event.pixel,
        (featureEvent) => featureEvent
      );
      if (feature) {
        const values = feature.getProperties();
        const tooltipElement = tooltip.getElement();
        const eventsTranslations: { [key: string]: string } = {
          clock_in: t({
            id: "location-page.clock-in",
            defaultMessage: "Clock In",
          }),
          location_enabled: t({
            id: "location-page.location-enabled",
            defaultMessage: "Location Enabled",
          }),
          online: t({ id: "location-page.online", defaultMessage: "Online" }),
          idle: t({ id: "location-page.idle", defaultMessage: "Idle" }),
          offline: t({
            id: "location-page.offline",
            defaultMessage: "Offline",
          }),
          location_disabled: t({
            id: "location-page.location-disabled",
            defaultMessage: "Location Disabled",
          }),
          clock_out: t({
            id: "location-page.clock-out",
            defaultMessage: "Clock Out",
          }),
          paused: t({ id: "location-page.paused", defaultMessage: "Paused" }),
          resumed: t({
            id: "location-page.resumed",
            defaultMessage: "resumed",
          }),
        };
        if (tooltipElement && values?.event) {
          const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
          const parsedTime = values.time.endsWith("Z")
            ? parseISO(values.time)
            : parseISO(`${values.time}Z`);
          const zonedTime = utcToZonedTime(parsedTime, timezone || timeZone);
          tooltip.setPosition(event.coordinate);
          const featureTime = format(zonedTime, "h:mm a", {
            timeZone: timezone || timeZone,
          });
          tooltipElement.innerHTML = `${convertSnakeCaseToReadableString(
            eventsTranslations[values.event] || values.event
          )}<br/>${featureTime}`;
          clearTimeout(hideTimeout.current);
          hideTimeout.current = setTimeout(() => {
            tooltip.setPosition(undefined);
          }, 1000);
        }
      }
    });
  }
  return (
    <div>
      <div
        className="h-[calc(100vh_-_60px)] w-[calc(100vw_-_571px)]"
        ref={mapElement}
      />
      <div
        ref={tooltipContainer}
        className="bg-dark/70 z-20 whitespace-nowrap rounded-md p-4 text-white"
      />
    </div>
  );
};
