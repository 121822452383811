import { cx, useTranslations } from "@jugl-web/utils";
import {
  NewTaskPopover,
  NewTaskPopoverProps,
} from "@web-src/features/tasks/TasksControlBar/components/NewTaskPopover";
import { FC } from "react";

export interface NewTaskColumnButtonProps
  extends Pick<
    NewTaskPopoverProps,
    "entityId" | "initialState" | "taskPropertiesPanelConfigOverrides"
  > {
  isElevated: boolean;
  className?: string;
}

export const NewTaskColumnButton: FC<NewTaskColumnButtonProps> = ({
  isElevated,
  className,
  ...newTaskPopoverProps
}) => {
  const { t } = useTranslations();

  return (
    <NewTaskPopover
      renderTrigger={({ Trigger, triggerRef, isOpen }) => (
        <div
          className={cx(
            "sticky -bottom-6 shrink-0 opacity-0 transition-opacity group-hover:opacity-100",
            isElevated
              ? "bg-white px-3 pt-4 pb-6 shadow-[0px_-4px_6px_0px_rgba(0,0,0,0.06)]"
              : "bg-transparent",
            isOpen && "opacity-100",
            className
          )}
        >
          <Trigger
            ref={triggerRef}
            type="button"
            className={cx(
              "h-[45px] w-full cursor-pointer rounded-lg border border-dashed text-center transition",
              "bg-grey-200 border-grey-400 hover:bg-dark-100 hover:border-grey-500",
              isOpen && "bg-dark-100 border-grey-500"
            )}
          >
            <span className="text-dark font-secondary text-sm font-normal">
              {t({
                id: "tasks-page.add-new-task",
                defaultMessage: "Add new task",
              })}
            </span>
          </Trigger>
        </div>
      )}
      placement="bottom"
      {...newTaskPopoverProps}
    />
  );
};
