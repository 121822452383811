import { useRestApiProvider } from "@jugl-web/rest-api";
import {
  InternalTaskFilters,
  PreviewTask,
  TaskDefaultStatus,
  previewTasksAdapter,
} from "@jugl-web/rest-api/tasks";
import isPast from "date-fns/isPast";
import { useMemo } from "react";
import { TaskSorting } from "../components/TaskSortingProvider";
import { TaskListMode } from "../types";
import { INITIAL_FILTERS_STATE } from "./useTaskFiltersState";
import { useTaskSelectors } from "./useTaskSelectors";

// #region Filtering
const matchesSearchQuery =
  (searchQuery: UseTasksOptions["searchQuery"]) => (task: PreviewTask) => {
    if (!searchQuery) {
      return true;
    }

    return task.name.toLowerCase().includes(searchQuery.toLowerCase());
  };

const matchesAssignees =
  (assignees: InternalTaskFilters["assignees"]) => (task: PreviewTask) => {
    if (!assignees || assignees.length === 0) {
      return true;
    }

    if (assignees.includes(null) && task.assignees.length === 0) {
      return true;
    }

    return task.assignees.some((assigneeId) => assignees.includes(assigneeId));
  };

const matchesCustomers =
  (customers: InternalTaskFilters["customers"]) => (task: PreviewTask) => {
    if (!customers || customers.length === 0) {
      return true;
    }

    if (customers.includes(null) && task.cust_id === null) {
      return true;
    }

    return customers.includes(task.cust_id);
  };

const matchesCustomFields =
  (customFields: InternalTaskFilters["customFields"]) =>
  (task: PreviewTask) => {
    if (!customFields || Object.keys(customFields).length === 0) {
      return true;
    }

    return Object.entries(customFields).some(
      ([customFieldId, customFieldValues]) => {
        if (customFieldValues.length === 0) {
          return true;
        }

        const taskCustomFieldValue = task.custom_fields?.[customFieldId];

        if (!taskCustomFieldValue) {
          return customFieldValues.includes(null);
        }

        return customFieldValues.includes(taskCustomFieldValue);
      }
    );
  };

const matchesIsOverdue =
  (isOverdue: InternalTaskFilters["isOverdue"]) => (task: PreviewTask) => {
    if (!isOverdue) {
      return true;
    }

    if (task.due_at === null) {
      return false;
    }

    return (
      task.status !== TaskDefaultStatus.completed &&
      isPast(new Date(task.due_at))
    );
  };

const matchesLabels =
  (labels: InternalTaskFilters["labels"]) => (task: PreviewTask) => {
    if (!labels || labels.length === 0) {
      return true;
    }

    return labels.includes(task.label_id);
  };

const matchesPriorities =
  (priorities: InternalTaskFilters["priorities"]) => (task: PreviewTask) => {
    if (!priorities || priorities.length === 0) {
      return true;
    }

    return priorities.includes(task.priority);
  };

const matchesStatuses =
  (statuses: InternalTaskFilters["statuses"], forceShowCompleted: boolean) =>
  (task: PreviewTask) => {
    if (!forceShowCompleted && task.status === TaskDefaultStatus.completed) {
      return statuses.includes(TaskDefaultStatus.completed);
    }

    if (!statuses || statuses.length === 0) {
      return true;
    }

    return statuses.includes(task.status);
  };
// #endregion

// #region Sorting
const sortByLastUpdatedComparer = (taskA: PreviewTask, taskB: PreviewTask) =>
  taskA.updated_at > taskB.updated_at ? -1 : 1;

const getSortByDueDateComparer =
  (order: "asc" | "desc") => (taskA: PreviewTask, taskB: PreviewTask) => {
    const dateA = taskA.due_at ? new Date(taskA.due_at).valueOf() : Infinity;
    const dateB = taskB.due_at ? new Date(taskB.due_at).valueOf() : Infinity;

    if (dateA === Infinity && dateB === Infinity) {
      return 0;
    }

    return order === "asc" ? dateA - dateB : dateB - dateA;
  };

const sortTasks = (tasks: PreviewTask[], sorting: TaskSorting) => {
  switch (sorting) {
    case TaskSorting.lastUpdated:
      return [...tasks].sort(sortByLastUpdatedComparer);
    case TaskSorting.dueDateAsc:
      return [...tasks].sort(getSortByDueDateComparer("asc"));
    case TaskSorting.dueDateDesc:
      return [...tasks].sort(getSortByDueDateComparer("desc"));
    default:
      throw new Error("Invalid sorting type");
  }
};
// #endregion

export interface UseTasksOptions {
  entityId: string;
  mode: TaskListMode;
  searchQuery?: string;
  filters?: InternalTaskFilters;
  forceShowCompleted?: boolean;
  sorting?: TaskSorting;
}

export const useTasks = ({
  entityId,
  mode,
  searchQuery,
  filters = INITIAL_FILTERS_STATE,
  forceShowCompleted = false,
  sorting = TaskSorting.lastUpdated,
}: UseTasksOptions) => {
  const { tasksApi } = useRestApiProvider();

  const {
    createSelectTasksByDueDateInterval,
    createSelectOverdueTasks,
    createSelectNoDueDateTasks,
    createSelectTasksByLabelId,
    createSelectTasksByStatus,
    createSelectTasksByReporteeId,
    createSelectTasksByAssigneeId,
    createSelectTasksByCustomerId,
    createSelectTasksByPriority,
    createSelectTasksByCustomDropdownFieldValue,
  } = useTaskSelectors({ entityId });

  const tasksQuery = tasksApi.useGetTasksQuery(
    { entityId },
    { skip: mode !== "personal" }
  );

  const teamTasksQuery = tasksApi.useGetTeamTasksQuery(
    { entityId },
    { skip: mode !== "team", refetchOnMountOrArgChange: true }
  );

  const { data, isLoading, isError, refetch } =
    mode === "personal" ? tasksQuery : teamTasksQuery;

  const adapterSelectors = useMemo(
    () => previewTasksAdapter.getSelectors(),
    []
  );

  const allSortedTasks = useMemo(
    () =>
      sortTasks(
        adapterSelectors.selectAll(
          data ?? previewTasksAdapter.getInitialState()
        ),
        sorting
      ),
    [adapterSelectors, data, sorting]
  );

  const filteredTasks = useMemo(() => {
    const filterFns = [
      matchesSearchQuery(searchQuery),
      matchesAssignees(filters.assignees),
      matchesCustomers(filters.customers),
      matchesCustomFields(filters.customFields),
      matchesIsOverdue(filters.isOverdue),
      matchesLabels(filters.labels),
      matchesPriorities(filters.priorities),
      matchesStatuses(filters.statuses, forceShowCompleted),
    ];

    return allSortedTasks.reduce<PreviewTask[]>((acc, task) => {
      const matchesFilters = filterFns.every((fn) => fn(task));

      if (!matchesFilters) {
        return acc;
      }

      return [...acc, task];
    }, []);
  }, [allSortedTasks, filters, searchQuery, forceShowCompleted]);

  const selectTasksByDueDateInterval = useMemo(
    () => createSelectTasksByDueDateInterval(filteredTasks),
    [createSelectTasksByDueDateInterval, filteredTasks]
  );

  const selectOverdueTasks = useMemo(
    () => createSelectOverdueTasks(filteredTasks),
    [createSelectOverdueTasks, filteredTasks]
  );

  const selectNoDueDateTasks = useMemo(
    () => createSelectNoDueDateTasks(filteredTasks),
    [createSelectNoDueDateTasks, filteredTasks]
  );

  const selectTasksByLabelId = useMemo(
    () => createSelectTasksByLabelId(filteredTasks),
    [createSelectTasksByLabelId, filteredTasks]
  );

  const selectTasksByStatus = useMemo(
    () => createSelectTasksByStatus(filteredTasks),
    [createSelectTasksByStatus, filteredTasks]
  );

  const selectTasksByReporteeId = useMemo(
    () => createSelectTasksByReporteeId(filteredTasks),
    [createSelectTasksByReporteeId, filteredTasks]
  );

  const selectTasksByAssigneeId = useMemo(
    () => createSelectTasksByAssigneeId(filteredTasks),
    [createSelectTasksByAssigneeId, filteredTasks]
  );

  const selectTasksByCustomerId = useMemo(
    () => createSelectTasksByCustomerId(filteredTasks),
    [createSelectTasksByCustomerId, filteredTasks]
  );

  const selectTasksByPriority = useMemo(
    () => createSelectTasksByPriority(filteredTasks),
    [createSelectTasksByPriority, filteredTasks]
  );

  const selectTasksByCustomDropdownFieldValue = useMemo(
    () => createSelectTasksByCustomDropdownFieldValue(filteredTasks),
    [createSelectTasksByCustomDropdownFieldValue, filteredTasks]
  );

  return {
    tasks: filteredTasks,
    isLoading,
    isError,
    refetch,
    selectTasksByDueDateInterval,
    selectOverdueTasks,
    selectNoDueDateTasks,
    selectTasksByLabelId,
    selectTasksByStatus,
    selectTasksByPriority,
    selectTasksByReporteeId,
    selectTasksByAssigneeId,
    selectTasksByCustomerId,
    selectTasksByCustomDropdownFieldValue,
  };
};
