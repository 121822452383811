import React from "react";
import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import { ReactComponent as ChevronRightIcon } from "./assets/chevron-right.svg";

const Tile: React.FC<{
  icon: React.ReactNode;
  title: string;
  subTitle?: string;
  onClick: () => void;
}> = ({ icon, title, subTitle, onClick }) => (
  <InteractiveContainer
    className="flex h-[108px] items-center justify-between rounded-[10px] bg-white px-[27px]"
    onClick={onClick}
  >
    <div className="flex items-center gap-[15px]">
      {icon}
      <span className="text-lg font-medium">{title}</span>
    </div>
    {subTitle ? (
      <span className="text-primary ml-[50px] text-[15px]"> {subTitle}</span>
    ) : (
      <ChevronRightIcon className="ml-[50px]" />
    )}
  </InteractiveContainer>
);

export default Tile;
