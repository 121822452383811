import React, { memo } from "react";
import { SvgIcon } from "@mui/material";
import { ChatMessage } from "@web-src/features/chats/types";
import { ReactComponent as DoubleCheckIcon } from "./images/double-check.svg";
import { ReactComponent as CheckIcon } from "./images/check.svg";
import { ReactComponent as EyeOutlineIcon } from "./images/eye-outline.svg";
import { ReactComponent as SandClockIcon } from "./images/sand-clock.svg";
import { ReactComponent as ErrorExclamationIcon } from "./images/error-exclamation.svg";

const ChatMessageStatusIcon: React.FC<{ message: ChatMessage }> = memo(
  ({ message }) => {
    let icon: React.FC = CheckIcon;
    if (message._pending) {
      icon = SandClockIcon;
    } else if (message._error) {
      icon = ErrorExclamationIcon;
    } else {
      if (message.delivered) {
        icon = DoubleCheckIcon;
      }
      if (message.read) {
        icon = EyeOutlineIcon;
      }
    }
    return (
      <SvgIcon
        component={icon}
        sx={{ width: "16px", height: "16px", ml: "4px" }}
        viewBox="0 0 16 16"
      />
    );
  }
);

export default memo(ChatMessageStatusIcon);
