import { cx, useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import Lottie from "react-lottie";
import animationData from "./assets/empty-state-animation.json";

interface TaskViewEmptyStateProps {
  subtitle?: string;
  className?: string;
}
export const TaskViewEmptyState: FC<TaskViewEmptyStateProps> = ({
  subtitle,
  className,
}) => {
  const { t } = useTranslations();

  return (
    <div className={cx("flex flex-col items-center", className)}>
      <Lottie options={{ animationData }} height={160} width={160} />
      <span className="text-dark mb-3 text-xl font-medium leading-4">
        {t({
          id: "feedback.no-results",
          defaultMessage: "No results",
        })}
      </span>
      <span className="text-dark text-sm leading-3">
        {subtitle ||
          t({
            id: "feedback.try-something-else",
            defaultMessage: "Please try something else",
          })}
      </span>
    </div>
  );
};
