import {
  DOMConversionOutput,
  TextNode,
  DOMConversionMap,
  $createTextNode,
  SerializedTextNode,
} from "lexical";

export class ExtendedTextNode extends TextNode {
  static getType(): string {
    return "extended-text";
  }

  static clone(node: ExtendedTextNode): ExtendedTextNode {
    return new ExtendedTextNode(node.__text, node.__key);
  }

  static importDOM(): DOMConversionMap | null {
    const importers = TextNode.importDOM();
    return {
      ...importers,
      mark: () => ({
        conversion: convertMark,
        priority: 1,
      }),
    };
  }

  static importJSON(serializedNode: SerializedTextNode): TextNode {
    return super.importJSON(serializedNode);
  }

  exportJSON(): SerializedTextNode {
    return super.exportJSON();
  }
}

const convertMark = (element: HTMLElement): DOMConversionOutput | null => {
  const text = element.textContent;
  if (!text) {
    return null;
  }
  return {
    node: $createTextNode(text).setFormat("highlight"),
  };
};
