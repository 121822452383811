import { cx } from "@jugl-web/utils";
import { FC } from "react";

interface ChecklistItemNumberProps {
  index: number;
  isHighlighted?: boolean;
}

export const ChecklistItemNumber: FC<ChecklistItemNumberProps> = ({
  index,
  isHighlighted = false,
}) => (
  <span
    className={cx(
      "font-secondary text-sm font-semibold",
      isHighlighted ? "text-primary-600" : "text-dark-400"
    )}
  >
    #{index + 1}
  </span>
);
