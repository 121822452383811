export const getAvatarUrl = (imageId: number) =>
  `https://i.pravatar.cc/150?img=${imageId}`;

export const FAKE_MEMBERS = [
  {
    firstName: "John",
    lastName: "Smith",
    imageUrl: getAvatarUrl(1),
  },
  {
    firstName: "Sarah",
    lastName: "Johnson",
    imageUrl: getAvatarUrl(2),
  },
  {
    firstName: "William",
    lastName: "Brown",
    imageUrl: getAvatarUrl(3),
  },
  {
    firstName: "Emily",
    lastName: "Thompson",
    imageUrl: getAvatarUrl(4),
  },
];

export const generateAvatarMembers = (amount: number) =>
  Array.from({ length: amount }, (_, index) => FAKE_MEMBERS[index % 4]);

export * from "./TemplateWithClosureTrigger";
