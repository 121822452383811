import { ShortLinkDataDto } from "./shortLinkDataDto";
import { ShortLinkDataModel } from "./shortLinkDataModel";

export const transformShortLinkDataDtoToModel = (
  dto: ShortLinkDataDto
): ShortLinkDataModel => {
  if (dto.module === "entity_link") {
    return {
      module: dto.module,
      entityId: dto.module_res.entity.id,
      ...dto.module_res,
    };
  }
  if (dto.module === "drive") {
    return {
      entityId: dto.module_res.entity_id,
      module: dto.module,
      id: dto.module_res.id,
      type: dto.module_res.type,
      folderId: dto.module_res.parent_dir_id,
      name: dto.module_res.name,
      folderName: dto.module_res.parent_dir_name,
    };
  }
  if (dto.module === "task") {
    return {
      id: dto.module_res.id,
      entityId: dto.module_res.entity_id,
      module: dto.module,
      name: dto.module_res.name,
    };
  }
  throw new Error("Invalid module type");
};
