import { ReportsDto, ReportsModel } from "../models/reportsModel";

export const transformReportsDtoToReportsModel = (
  reportsDto: ReportsDto
): ReportsModel => ({
  active: reportsDto.active,
  created_on: reportsDto.created_on,
  crontab: reportsDto.crontab,
  humanizedCrontab: reportsDto.crontab_humanized,
  description: reportsDto.description,
  id: reportsDto.id.toString(),
  name: reportsDto.name,
  timezone: reportsDto.timezone,
  recipients: (() => {
    try {
      return JSON.parse(
        reportsDto.recipients[0].recipient_config_json
      ).target.split(", ");
    } catch {
      throw new Error();
    }
  })(),
  lastRun: reportsDto.last_eval_dttm,
  chartId: reportsDto.chart_id,
  template: reportsDto.template,
});
