import { useDroppable } from "@dnd-kit/core";
import { TaskColumnDroppableData } from "@jugl-web/domain-resources/tasks";
import {
  TaskCalendarCard,
  useTaskCardAdapter,
} from "@jugl-web/domain-resources/tasks/components/TaskCard";
import { useTaskFiltering } from "@jugl-web/domain-resources/tasks/components/TaskFilteringProvider";
import { PreviewTask } from "@jugl-web/rest-api/tasks";
import { assignRefs, useTranslations } from "@jugl-web/utils";
import { useIsScrollable } from "@jugl-web/utils/hooks/useIsScrollable";
import { NewTaskPopoverProps } from "@web-src/features/tasks/TasksControlBar/components/NewTaskPopover";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { isBefore, startOfDay } from "date-fns";
import { FC } from "react";
import { CalendarViewLayout } from "../CalendarViewLayout";
import { NewTaskColumnButton } from "../NewTaskColumnButton";

interface CalendarWeekColumnListProps {
  date: Date;
  tasks: PreviewTask[];
  droppableConfig: {
    id: string;
    data: TaskColumnDroppableData;
  };
  newTaskConfig: Pick<
    NewTaskPopoverProps,
    "initialState" | "taskPropertiesPanelConfigOverrides"
  >;
}

export const CalendarWeekColumnList: FC<CalendarWeekColumnListProps> = ({
  date,
  tasks,
  droppableConfig,
  newTaskConfig,
}) => {
  const { entityId } = useEntitySelectedProvider();
  const { searchQuery, hasActiveFilter } = useTaskFiltering();

  const { getTaskCardProps } = useTaskCardAdapter({ entityId });

  const { ref: listRef, isScrollable: isColumnScrollable } = useIsScrollable();

  const { isOver, setNodeRef, active } = useDroppable(droppableConfig);

  const { navigateToPage } = useNavigation();
  const { t } = useTranslations();

  const handleTaskClick = (task: PreviewTask) => {
    navigateToPage("tasksDetails", { taskId: task.id });
  };

  const isInPastColumn = isBefore(date, startOfDay(new Date()));

  const shouldShowNoResultsMessage =
    tasks.length === 0 && (hasActiveFilter || searchQuery.length > 0);

  return (
    <CalendarViewLayout.ColumnList
      ref={(el) => {
        setNodeRef(el);
        assignRefs(listRef)(el);
      }}
      isDragging={!!active}
      isOver={isOver}
      canDrop={!isInPastColumn}
    >
      {shouldShowNoResultsMessage ? (
        <CalendarViewLayout.EmptyStateMessage>
          {t({
            id: "common.no-results",
            defaultMessage: "No results",
          })}
        </CalendarViewLayout.EmptyStateMessage>
      ) : (
        <>
          {tasks.map((task) => (
            <TaskCalendarCard
              key={task.id}
              className="shrink-0"
              highlightedText={searchQuery}
              onClick={() => handleTaskClick(task)}
              columnId={droppableConfig.id}
              draggable
              {...getTaskCardProps(task)}
            />
          ))}
        </>
      )}
      {!isInPastColumn && (
        <NewTaskColumnButton
          entityId={entityId}
          isElevated={isColumnScrollable}
          className="-mx-3 px-3"
          {...newTaskConfig}
        />
      )}
    </CalendarViewLayout.ColumnList>
  );
};
