import React, { useMemo, useState } from "react";
import { useTranslations } from "@jugl-web/utils";
import Lottie from "react-lottie";
import { ChatBotMessage, ChatBotReceivedMessage } from "../../types";
import { ReactComponent as RobotIcon } from "../../icons/robot.svg";
import { ReactComponent as HandleIncomingIcon } from "../../icons/handle-incoming.svg";
import { ReactComponent as HandleOutgoingIcon } from "../../icons/handle-outgoing.svg";
import { ReactComponent as ChevronRightIcon } from "../../icons/chevron-right.svg";
import { ReactComponent as DownloadIcon } from "../../icons/download.svg";
import { ReactComponent as ImageIcon } from "../../icons/image.svg";
import { ReactComponent as EyeIcon } from "../../icons/eye.svg";
import typingAnimation from "../../animations/typing-animation.json";
import { getIcon, getNextChoices, downloadReport } from "../../utils";
import { ReportDialog } from "../ReportDialog/ReportDialog";

export const ChatBotMessageBubble: React.FC<{
  message?: ChatBotMessage;
  isLastInGroup?: boolean;
  isTyping?: boolean;
  onSendMessage?: (message: ChatBotReceivedMessage, choice?: string[]) => void;
}> = ({ message, isTyping, onSendMessage, isLastInGroup }) => {
  const { t } = useTranslations();
  const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);
  const [messageForReport, setMessageForReport] = useState<
    ChatBotReceivedMessage | undefined
  >(undefined);
  const elementToRender = useMemo(() => {
    const displayAvatar = isLastInGroup || isTyping;
    const isOption = message?.chatBotReceivedMessage?.action === "user_select";
    const isDownload = message?.chatBotReceivedMessage?.action === "report";
    if (isOption) {
      return (
        <button
          type="button"
          className="flex w-full cursor-pointer items-center justify-between rounded-lg border-none bg-white p-5"
          onClick={() =>
            message.chatBotReceivedMessage &&
            onSendMessage?.(
              message.chatBotReceivedMessage,
              message.chatBotReceivedMessage.action_value
                ? getNextChoices(message.chatBotReceivedMessage)
                : undefined
            )
          }
        >
          <div className="flex items-center gap-3">
            <div className="text-grey-400">
              {getIcon(message.chatBotReceivedMessage?.icon)}
            </div>
            <span className="text-primary text-base font-medium">
              {message.chatBotReceivedMessage?.message}
            </span>
          </div>
          <ChevronRightIcon />
        </button>
      );
    }
    if (isDownload) {
      return (
        <>
          <button
            type="button"
            className="bg-primary flex w-full cursor-pointer items-center justify-between rounded-lg border-none p-5 text-white"
            onClick={() =>
              downloadReport(
                `data:${message.chatBotReceivedMessage?.filetype};base64, ${message.chatBotReceivedMessage?.base64}`,
                message.chatBotReceivedMessage?.file_name || ""
              )
            }
          >
            <div className="flex items-center gap-3">
              {getIcon(message.chatBotReceivedMessage?.icon)}
              <span className="text-base font-medium">
                {t({
                  id: "tasks-page.download-file",
                  defaultMessage: "Tap to download file",
                })}
              </span>
            </div>
            <DownloadIcon />
          </button>
          {message.chatBotReceivedMessage?.icon === "graph" && (
            <button
              type="button"
              className="text-primary flex w-full cursor-pointer items-center justify-between rounded-lg border-none bg-white p-5"
              onClick={() => {
                setMessageForReport(message.chatBotReceivedMessage);
                setIsReportDialogOpen(true);
              }}
            >
              <span className="text-base font-medium">
                {t({
                  id: "tasks-page.see-report",
                  defaultMessage: "Tap to see Report",
                })}
              </span>
              <ImageIcon />
            </button>
          )}
        </>
      );
    }
    if (message?.isSelf) {
      return (
        <div className="relative ml-auto flex flex-col rounded-lg rounded-br-none bg-[#4E93CA] text-white">
          <div className="flex items-center gap-2 py-2 pr-7 pl-3">
            {getIcon(message.chatBotSentMessage?.icon)}
            <span>{message?.chatBotSentMessage?.message}</span>
          </div>
          <span className="mr-2 mb-1 flex items-center gap-1 self-end text-sm">
            {message?.chatBotSentMessage?.timeLabel}
            <EyeIcon />
          </span>
          <HandleOutgoingIcon className="absolute bottom-0 -right-[10px]" />
        </div>
      );
    }
    return (
      <div className="relative flex items-end">
        {displayAvatar && (
          <div className="bg-primary absolute bottom-0 -left-9 flex rounded-full">
            <RobotIcon className="m-1 h-[18px] w-[18px]" />
          </div>
        )}
        <div className="relative flex flex-col rounded-lg rounded-bl-none bg-white">
          {isTyping ? (
            <div className="p-2">
              <Lottie
                options={{
                  animationData: typingAnimation,
                }}
                height={20}
                width={40}
              />
            </div>
          ) : (
            <>
              <span className="py-2 pr-7 pl-3">
                {message?.chatBotReceivedMessage?.message}
              </span>
              <span className="mr-2 mb-1 self-end text-sm text-gray-500">
                {message?.chatBotReceivedMessage?.time_label}
              </span>
            </>
          )}
          {displayAvatar && (
            <HandleIncomingIcon className="absolute bottom-0 -left-[10px]" />
          )}
        </div>
      </div>
    );
  }, [message, isTyping, isLastInGroup, onSendMessage, t]);

  return (
    <>
      {elementToRender}
      <ReportDialog
        isOpen={isReportDialogOpen}
        message={messageForReport}
        onClose={() => setIsReportDialogOpen(false)}
      />
    </>
  );
};
