import {
  PaginatedRequestParams,
  PaginatedResponse,
  RtkBaseQuery,
  RtkEmptySplitApi,
} from "../../types";
import { fetchAllPages } from "../../utils";
import { EntitySpaceType, EntitySpaceModel } from "./models";
import { EntitySpaceDto } from "./models/EntitySpaceModel/EntitySpaceDto";
import { EntitySpacesApiTags } from "./tags";

export const addEntitySpacesApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [EntitySpacesApiTags.spacesList],
  });

  const entityPropsApi = apiWithTags.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
      entitySpacesList: build.query<
        PaginatedResponse<EntitySpaceModel>,
        {
          entityId: string;
          params: PaginatedRequestParams<{
            type: EntitySpaceType;
            user_id?: string;
          }>;
        }
      >({
        query: ({ entityId, params }) => ({
          url: `/api/auth/entity/${entityId}/spaces`,
          params,
        }),
        providesTags: [EntitySpacesApiTags.spacesList],
      }),
      _DEPRECATED_allEntitySpacesList: build.query<
        EntitySpaceModel[],
        {
          entityId: string;
          params: { type: EntitySpaceType; user_id?: string };
        }
      >({
        queryFn: async ({ entityId, params }, e1, e2, baseQuery) => ({
          data: await fetchAllPages<EntitySpaceModel>(
            `/api/auth/entity/${entityId}/spaces`,
            params,
            baseQuery as unknown as RtkBaseQuery
          ),
        }),
        providesTags: [EntitySpacesApiTags.spacesList],
      }),
      createEntitySpace: build.mutation<
        EntitySpaceModel,
        {
          data: Partial<Pick<EntitySpaceDto, "info" | "type">>;
          entityId: string;
        }
      >({
        query: ({ data, entityId }) => ({
          url: `/api/auth/entity/${entityId}/spaces`,
          method: "POST",
          data,
        }),
        invalidatesTags: [EntitySpacesApiTags.spacesList],
      }),
      removeEntitySpace: build.mutation<
        void,
        { entityId: string; spaceId: string }
      >({
        query: ({ entityId, spaceId }) => ({
          url: `/api/auth/entity/${entityId}/spaces/${spaceId}`,
          method: "DELETE",
          data: { entity_id: entityId, workspace_id: spaceId },
        }),
        invalidatesTags: [EntitySpacesApiTags.spacesList],
      }),
      updateEntitySpace: build.mutation<
        EntitySpaceModel,
        {
          entityId: string;
          spaceId: string;
          data: Partial<Pick<EntitySpaceDto, "info" | "type">>;
        }
      >({
        query: ({ entityId, spaceId, data }) => ({
          url: `/api/auth/entity/${entityId}/spaces/${spaceId}`,
          method: "PUT",
          data,
        }),
        invalidatesTags: [EntitySpacesApiTags.spacesList],
      }),
    }),
  });

  return entityPropsApi;
};

export type EntitySpacesApi = ReturnType<typeof addEntitySpacesApi>;
