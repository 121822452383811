import { useTaskFiltering } from "@jugl-web/domain-resources/tasks/components/TaskFilteringProvider";
import { useTaskFields } from "@jugl-web/domain-resources/tasks/hooks/useTaskFields";
import { InternalTaskFilters } from "@jugl-web/rest-api/tasks";
import { assert, useTranslations } from "@jugl-web/utils";
import { FC, useMemo } from "react";
import { AssigneeFilterPill } from "../../../TaskFilterSections/components/AssigneeFilterPill";
import { CustomFieldFilterPill } from "../../../TaskFilterSections/components/CustomFieldFilterPill";
import { CustomerFilterPill } from "../../../TaskFilterSections/components/CustomerFilterPill";
import { FilterPill } from "../../../TaskFilterSections/components/FilterPill";
import { LabelFilterPill } from "../../../TaskFilterSections/components/LabelFilterPill";
import { OverdueFilterPill } from "../../../TaskFilterSections/components/OverdueFilterPill";
import { PriorityFilterPill } from "../../../TaskFilterSections/components/PriorityFilterPill";
import { StatusFilterPill } from "../../../TaskFilterSections/components/StatusFilterPill";

type TaskFilterItem =
  | { type: "isOverdue" }
  | { type: "label"; labelId: InternalTaskFilters["labels"][number] }
  | { type: "priority"; priority: InternalTaskFilters["priorities"][number] }
  | { type: "status"; statusId: InternalTaskFilters["statuses"][number] }
  | { type: "assignee"; assigneeId: InternalTaskFilters["assignees"][number] }
  | { type: "customer"; customerId: InternalTaskFilters["customers"][number] }
  | {
      type: "customField";
      field: {
        id: string;
        value: InternalTaskFilters["customFields"][string][number];
      };
    };

interface CollectiveFilterPillsProps {
  entityId: string;
  onMorePillClick: () => void;
}

const MAX_VISIBLE_ACTIVE_FILTER_ITEMS = 10;

export const CollectiveFilterPills: FC<CollectiveFilterPillsProps> = ({
  entityId,
  onMorePillClick,
}) => {
  const { filtering, filters, updateStandardFilters } = useTaskFiltering();
  const { getCustomFieldById } = useTaskFields({ entityId });

  assert(
    filtering.type === "standard",
    "CollectiveFilterPills should be used only with standard filtering"
  );

  const { t } = useTranslations();

  const { visibleActiveFilterItems, moreActiveFilterItemsCount } =
    useMemo(() => {
      const activeFilterItems: TaskFilterItem[] = [];

      if (filters.isOverdue) {
        activeFilterItems.push({ type: "isOverdue" });
      }

      filters.labels.forEach((labelId) => {
        activeFilterItems.push({ type: "label", labelId });
      });

      filters.priorities.forEach((priority) => {
        activeFilterItems.push({ type: "priority", priority });
      });

      filters.statuses.forEach((statusId) => {
        activeFilterItems.push({ type: "status", statusId });
      });

      filters.assignees.forEach((assigneeId) => {
        activeFilterItems.push({ type: "assignee", assigneeId });
      });

      filters.customers.forEach((customerId) => {
        activeFilterItems.push({ type: "customer", customerId });
      });

      Object.entries(filters.customFields).forEach(([fieldId, values]) => {
        values.forEach((value) => {
          activeFilterItems.push({
            type: "customField",
            field: { id: fieldId, value },
          });
        });
      });

      return {
        visibleActiveFilterItems: activeFilterItems.slice(
          0,
          MAX_VISIBLE_ACTIVE_FILTER_ITEMS
        ),
        moreActiveFilterItemsCount: Math.max(
          activeFilterItems.length - MAX_VISIBLE_ACTIVE_FILTER_ITEMS,
          0
        ),
      };
    }, [filters]);

  return (
    <div className="flex flex-wrap gap-x-2 gap-y-2.5">
      {visibleActiveFilterItems.map((filterItem) => {
        if (filterItem.type === "isOverdue") {
          return (
            <OverdueFilterPill
              key={filterItem.type}
              isChecked
              onRemove={() => updateStandardFilters("isOverdue", null)}
            />
          );
        }

        if (filterItem.type === "label") {
          return (
            <LabelFilterPill
              key={filterItem.type + filterItem.labelId}
              entityId={entityId}
              labelId={filterItem.labelId}
              onRemove={() =>
                updateStandardFilters("labels", (previousLabels) =>
                  previousLabels.filter((l) => l !== filterItem.labelId)
                )
              }
            />
          );
        }

        if (filterItem.type === "priority") {
          return (
            <PriorityFilterPill
              key={filterItem.type + filterItem.priority}
              priority={filterItem.priority}
              onRemove={() =>
                updateStandardFilters("priorities", (previousPriorities) =>
                  previousPriorities.filter((p) => p !== filterItem.priority)
                )
              }
            />
          );
        }

        if (filterItem.type === "status") {
          return (
            <StatusFilterPill
              key={filterItem.type + filterItem.statusId}
              entityId={entityId}
              statusId={filterItem.statusId}
              onRemove={() =>
                updateStandardFilters("statuses", (previousStatuses) =>
                  previousStatuses.filter((s) => s !== filterItem.statusId)
                )
              }
            />
          );
        }

        if (filterItem.type === "assignee") {
          return (
            <AssigneeFilterPill
              key={filterItem.type + filterItem.assigneeId}
              entityId={entityId}
              assigneeId={filterItem.assigneeId}
              onRemove={() =>
                updateStandardFilters("assignees", (previousAssignees) =>
                  previousAssignees.filter((a) => a !== filterItem.assigneeId)
                )
              }
            />
          );
        }

        if (filterItem.type === "customer") {
          return (
            <CustomerFilterPill
              key={filterItem.type + filterItem.customerId}
              entityId={entityId}
              customerId={filterItem.customerId}
              onRemove={() =>
                updateStandardFilters("customers", (previousCustomers) =>
                  previousCustomers.filter((c) => c !== filterItem.customerId)
                )
              }
            />
          );
        }

        if (filterItem.type === "customField") {
          const matchingCustomField = getCustomFieldById(filterItem.field.id);

          if (!matchingCustomField) {
            return null;
          }

          return (
            <CustomFieldFilterPill
              key={
                filterItem.type + filterItem.field.id + filterItem.field.value
              }
              customField={matchingCustomField}
              value={filterItem.field.value}
              onRemove={() =>
                updateStandardFilters(
                  "customFields",
                  (previousCustomFields) => {
                    const fieldValues =
                      previousCustomFields[filterItem.field.id];

                    if (!fieldValues) {
                      return previousCustomFields;
                    }

                    const updatedFieldValues = fieldValues.filter(
                      (v) => v !== filterItem.field.value
                    );

                    return {
                      ...previousCustomFields,
                      [filterItem.field.id]: updatedFieldValues,
                    };
                  }
                )
              }
            />
          );
        }

        throw new Error("Unknown filter item type");
      })}
      {moreActiveFilterItemsCount > 0 && (
        <FilterPill
          isActive
          label={t(
            {
              id: "tasks-page.more-filters-count",
              defaultMessage: "+{count} more",
            },
            { count: moreActiveFilterItemsCount }
          )}
          className="hover:bg-[#D1ECFF]"
          onClick={onMorePillClick}
        />
      )}
    </div>
  );
};
