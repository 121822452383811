import { cx } from "@jugl-web/utils";
import { FC, ReactNode } from "react";

export type InfoItemProps = {
  title: string;
  description: string | ReactNode;
  icon: ReactNode;
  action?: { text?: string; onClick: () => void; color?: "primary" | "gray" };
  rightContent?: ReactNode;
};

export const InfoItem: FC<InfoItemProps> = ({
  title,
  description,
  icon,
  action,
  rightContent,
}) => (
  <div
    onClick={action?.onClick}
    className={cx(
      "flex min-w-[600px] max-w-[800px] items-center gap-[18px] rounded-xl bg-[#F9F9F9] p-6",
      {
        "cursor-pointer transition-all hover:shadow-[0px_4px_8px_0px_rgba(0,0,0,0.08)]":
          !!action,
      }
    )}
  >
    <div>{icon}</div>
    <div className="flex flex-1 flex-col gap-1.5">
      <div className="font-secondary font-medium">{title}</div>
      <div
        className={cx({
          "text-sm text-[#828282]": typeof description === "string",
        })}
      >
        {description}
      </div>
    </div>
    {!!action?.text && (
      <div
        className={cx(
          "bg-primary-500 rounded-lg py-3 px-6 text-sm font-semibold text-white duration-500",
          {
            "bg-primary-500 text-white":
              action.color === "primary" || !action.color,
            "text-dark-600 bg-[#F2F2F4]": action.color === "gray",
          }
        )}
      >
        {action?.text}
      </div>
    )}
    {!!rightContent && <div>{rightContent}</div>}
  </div>
);
