import { cx, useTranslations } from "@jugl-web/utils";
import { ReactComponent as SearchIcon } from "./icons/search.svg";
import { BaseInputProps } from "./types";
import { ReactComponent as ClearIcon } from "./icons/clear.svg";

export const BlankSearchInput = ({
  className,
  alwaysShowClear,
  containerClassName,
  onClear,
  ...inputProps
}: BaseInputProps) => {
  const { t } = useTranslations();

  const isClearButtonVisible = (onClear && inputProps.value) || alwaysShowClear;

  return (
    <div className={cx("relative", containerClassName)}>
      <SearchIcon className="pointer-events-none absolute left-4 top-1/2 -translate-y-1/2" />
      <input
        type="text"
        placeholder={`${t({
          id: "common.search",
          defaultMessage: "Search",
        })}...`}
        className={cx(
          "jugl__focusable-outline m-0 inline-block h-10 w-full flex-1 rounded-lg border-none p-0 pl-11",
          isClearButtonVisible ? "pr-12" : "pr-4",
          className
        )}
        {...inputProps}
      />
      {isClearButtonVisible && (
        <ClearIcon
          onClick={onClear}
          className="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer"
        />
      )}
    </div>
  );
};
