import { useTaskViewsContext } from "@jugl-web/domain-resources/tasks";
import { useTaskFiltering } from "@jugl-web/domain-resources/tasks/components/TaskFilteringProvider";
import {
  TaskSorting,
  useTaskSorting,
} from "@jugl-web/domain-resources/tasks/components/TaskSortingProvider";
import {
  Button,
  Menu,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { BetaTag } from "@jugl-web/ui-components/cross-platform/BetaTag";
import { SpotlightTooltip } from "@jugl-web/ui-components/cross-platform/SpotlightTooltip";
import { Tooltip } from "@jugl-web/ui-components/web";
import { ControlBarButton } from "@jugl-web/ui-components/web/ControlBarButton";
import { ExpandableSearchBar } from "@jugl-web/ui-components/web/ExpandableSearchBar";
import {
  assignRefs,
  cx,
  useSearchInput,
  useTranslations,
} from "@jugl-web/utils";
import { TasksPageLayout } from "@web-src/components/TasksPageLayout";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { useTasksPageContext } from "@web-src/features/tasks/TasksPageContext";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useFCM } from "@web-src/modules/notifications/providers/FCMProvider";
import { useOnboarding } from "@web-src/modules/preferences/providers";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { NewTaskDialog } from "../NewTaskDialog/NewTaskDialog";
import { TaskCalendarViewsPopover } from "../TaskCalendarViewsPopover";
import { TaskFiltersDialog } from "../TaskFiltersDialog";
import { TaskFiltersPopover } from "../TaskFiltersPopover";
import { TaskKanbanViewsPopover } from "../TaskKanbanViewsPopover";
import { TaskLayoutsPopover } from "../TaskLayoutsPopover";
import { ReactComponent as AddIcon } from "./assets/add.svg";
import { ReactComponent as CalendarIcon } from "./assets/calendar.svg";
import { ReactComponent as FileIcon } from "./assets/file.svg";
import { ReactComponent as FilterIcon } from "./assets/filter.svg";
import { ReactComponent as KanbanIcon } from "./assets/kanban.svg";
import { ReactComponent as ResetFiltersIcon } from "./assets/reset-filters.svg";
import { ReactComponent as SortIcon } from "./assets/sort.svg";
import { ReactComponent as TableIcon } from "./assets/table.svg";
import { NewTaskPopover } from "./components/NewTaskPopover";

export const TasksControlBar = () => {
  const [isTasksFiltersDialogOpen, setIsTasksFiltersDialogOpen] =
    useState(false);

  const { taskListMode, taskListLayout, taskCalendarView } =
    useTasksPageContext();

  const { selectedView } = useTaskViewsContext();

  const {
    hasActiveFilter,
    activeFilterSet,
    searchQuery,
    resetFiltering,
    changeSearchQuery,
  } = useTaskFiltering();

  const { sortingOptions, sortingButtonLabel, changeSorting } =
    useTaskSorting();

  const {
    isOnboardingActive,
    openedSpotlight,
    openSpotlight,
    completeOnboardingStep,
  } = useOnboarding();

  const { entity } = useEntitySelectedProvider();
  const { logEvent } = useFCM();

  const meId = useSelector(selectUserId);
  const hasRequiredParams = entity && meId;

  const { t } = useTranslations();

  const { inputProps } = useSearchInput({ defaultValue: searchQuery });

  const taskListLayoutIcon = useMemo(() => {
    switch (taskListLayout) {
      case "kanban":
        return <KanbanIcon className="fill-grey" />;
      case "table":
        return <TableIcon className="fill-grey" />;
      case "calendar":
        return <CalendarIcon />;
      default:
        return <KanbanIcon className="fill-grey" />;
    }
  }, [taskListLayout]);

  const calendarViewLabel = useMemo(() => {
    switch (taskCalendarView) {
      case "week":
        return t({
          id: "tasks-page.calendar-view-week",
          defaultMessage: "Week View",
        });
      case "month":
        return t({
          id: "tasks-page.calendar-view-month",
          defaultMessage: "Month View",
        });
      case "year":
        return t({
          id: "tasks-page.calendar-view-year",
          defaultMessage: "Year View",
        });
      default:
        return t({
          id: "tasks-page.calendar-view-week",
          defaultMessage: "Week View",
        });
    }
  }, [t, taskCalendarView]);

  return (
    <TasksPageLayout.ControlBar>
      <div className="flex items-center gap-4">
        {taskListLayout === "calendar" && taskListMode === "personal" && (
          <TaskCalendarViewsPopover
            renderTrigger={({ Trigger, triggerRef, isOpen }) => (
              <Trigger
                ref={triggerRef}
                as={ControlBarButton}
                label={calendarViewLabel}
                isActive={isOpen}
                className="hover:bg-grey-100 bg-white px-2.5"
              />
            )}
          />
        )}
        {taskListMode === "personal" && taskListLayout !== "calendar" && (
          <TaskKanbanViewsPopover
            renderTrigger={({ Trigger, triggerRef, isOpen }) => (
              <Trigger
                ref={triggerRef}
                as={ControlBarButton}
                label={selectedView.title}
                isActive={isOpen}
                className="hover:bg-grey-100 bg-white px-2.5"
              />
            )}
          />
        )}
        <div className="flex items-center gap-1">
          <Tooltip
            renderTrigger={({ ref, props }) => (
              <TaskLayoutsPopover
                renderTrigger={({ Trigger, triggerRef, isOpen }) => (
                  <Trigger
                    ref={assignRefs([triggerRef, ref])}
                    as={ControlBarButton}
                    startSlot={taskListLayoutIcon}
                    endSlot={
                      taskListLayout === "table" ? <BetaTag /> : undefined
                    }
                    isActive={isOpen}
                    {...props}
                  />
                )}
              />
            )}
          >
            {taskListLayout === "table" &&
              t({
                id: "tasks-page.table-view",
                defaultMessage: "Table View",
              })}
            {taskListLayout === "kanban" &&
              t({
                id: "tasks-page.kanban-view",
                defaultMessage: "Board View",
              })}
            {taskListLayout === "calendar" &&
              t({
                id: "tasks-page.calendar-view",
                defaultMessage: "Calendar View",
              })}
          </Tooltip>
        </div>
        <TaskFiltersPopover
          entityId={entity.id}
          renderTrigger={({ Trigger, triggerRef, isOpen }) => (
            <Trigger
              ref={triggerRef}
              as={ControlBarButton}
              className="max-w-[250px] truncate"
              label={
                activeFilterSet
                  ? t(
                      {
                        id: "tasks-page.filter-set-with-name",
                        defaultMessage: "Filter set: {name}",
                      },
                      { name: activeFilterSet.name }
                    )
                  : t({ id: "tasks-page.filters", defaultMessage: "Filters" })
              }
              startSlot={
                <span className="relative flex items-center justify-center gap-2">
                  <FilterIcon className="fill-grey" />
                  {hasActiveFilter && (
                    <div
                      className={cx(
                        "bg-gradients-success absolute -right-0.5 -top-0.5 h-2.5 w-2.5 rounded-full border-2 border-solid border-white"
                      )}
                    />
                  )}
                </span>
              }
              endSlot={
                hasActiveFilter ? (
                  <PlainButton
                    className="hover:bg-grey-300 rounded-full bg-[#EEF2F5] transition-colors"
                    onClick={(event) => {
                      event.stopPropagation();
                      resetFiltering();
                    }}
                  >
                    <ResetFiltersIcon />
                  </PlainButton>
                ) : undefined
              }
              isActive={isOpen || hasActiveFilter}
            />
          )}
          onOpenFiltersDialog={() => setIsTasksFiltersDialogOpen(true)}
        />
        <Menu
          placement="bottom-start"
          onSelect={(item, event, close) => {
            event.stopPropagation();
            close();
            changeSorting(item.value || TaskSorting.lastUpdated);
          }}
          sections={[sortingOptions]}
          renderTrigger={({ Trigger, triggerRef, isOpen }) => (
            <Trigger
              ref={triggerRef}
              as={ControlBarButton}
              label={sortingButtonLabel}
              startSlot={<SortIcon />}
              isActive={isOpen}
            />
          )}
        />
        <ExpandableSearchBar
          color="white"
          size="lg"
          onSearch={changeSearchQuery}
          defaultValue={searchQuery}
          {...inputProps}
        />
      </div>
      <div className="flex items-center gap-6">
        <Link to="templates" relative="path" className="no-underline">
          <ControlBarButton
            label={t({
              id: "tasks-page.templates",
              defaultMessage: "Templates",
            })}
            startSlot={<FileIcon />}
          />
        </Link>
        <NewTaskPopover
          entityId={entity.id}
          hasBackdrop
          placement="bottom-end"
          renderTrigger={({ Trigger, triggerRef }) => (
            <SpotlightTooltip
              isOpen={openedSpotlight === "task"}
              renderTrigger={({ props, ref }) => (
                <Trigger
                  as={Button}
                  ref={assignRefs([triggerRef, ref])}
                  className="h-[40px] px-8"
                  iconEnd={<AddIcon />}
                  onClick={() => {
                    logEvent("action_task_create");
                    openSpotlight(undefined);
                  }}
                  {...props}
                >
                  {t({ id: "tasks-page.new-task", defaultMessage: "New task" })}
                </Trigger>
              )}
              placement="bottom"
              tip={t({
                id: "tasks-page.create-task-onboarding-hint",
                defaultMessage: "Tap here to create your first task ✨",
              })}
              showButton={false}
              onDismiss={() => openSpotlight(undefined)}
            />
          )}
          onBeforeCreateTask={() => logEvent("action_task_create_send")}
          onAfterCreateTask={() => {
            if (isOnboardingActive) {
              completeOnboardingStep("task");
            }
          }}
        />
      </div>
      {hasRequiredParams && (
        <>
          <TaskFiltersDialog
            entityId={entity.id}
            isOpen={isTasksFiltersDialogOpen}
            onClose={() => setIsTasksFiltersDialogOpen(false)}
          />
          <NewTaskDialog entityId={entity.id} meId={meId} />
        </>
      )}
    </TasksPageLayout.ControlBar>
  );
};
