import { Alert } from "@jugl-web/ui-components/cross-platform";
import { FC, useMemo } from "react";
import { SubscriptionPlanModuleId } from "@jugl-web/rest-api/entities/models/common-types/SubscriptionPlanModuleId";
import Lottie from "react-lottie";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import clientsLimitationAnimation from "./assets/clients-limitation-animation.json";
import reportsLimitationAnimation from "./assets/reports-limitation-animation.json";
import conferenceLimitationAnimation from "./assets/conference-limitation-animation.json";

export type ModuleNotAvailableAlertProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  module: SubscriptionPlanModuleId;
  entityId: string;
  isAdmin: boolean;
};

export const ModuleNotAvailableAlert: FC<ModuleNotAvailableAlertProps> = ({
  isOpen,
  onRequestClose,
  module,
  entityId,
  isAdmin,
}) => {
  const { navigateToPage } = useNavigation();
  const { title, animation, description } = useMemo<{
    title: string;
    description: string;
    animation?: unknown;
  }>(() => {
    switch (module) {
      case SubscriptionPlanModuleId.clients:
        return {
          title: "Jugl Client Management",
          animation: clientsLimitationAnimation,
          description:
            "Convenient Customer base, for easy management and communication with Clients.",
        };
      case SubscriptionPlanModuleId.reports:
        return {
          title: "Jugl Reports",
          animation: reportsLimitationAnimation,
          description:
            "Different types of Reports based on Tasks, People, Clock in and other",
        };
      case SubscriptionPlanModuleId.conference:
        return {
          title: "Jugl Team conference",
          animation: conferenceLimitationAnimation,
          description:
            "Collaborate with the whole team using Jugl Conference. Share the screen, send reactions and other",
        };
      default:
        return {
          title: "Pro Feature",
          description: "This feature is not available in your current plan",
        };
    }
  }, [module]);
  return (
    <Alert
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      header="Upgrade the Plan"
      title={title}
      img={
        animation ? (
          <Lottie options={{ animationData: animation }} width={250} />
        ) : null
      }
      content={
        <div className="flex flex-col gap-2 text-center">
          <div>{description}</div>
          <div className="text-primary-800 font-medium">
            Available to Pro or Enterprise plans
          </div>
          {!isAdmin && (
            <div className="text-dark-600 text-sm">
              To update the Plan, please reach out to Admin of the Workspace
            </div>
          )}
        </div>
      }
      buttons={[
        isAdmin
          ? {
              text: "Upgrade",
              onClick: () => {
                onRequestClose();
                navigateToPage("workspaceSelectSubscription", undefined, {
                  entityId,
                });
              },
            }
          : { text: "Okay", role: "close", color: "primary" },
      ]}
    />
  );
};
