import { HookOutOfContextError } from "@jugl-web/utils";
import { createContext, FC, ReactNode, useContext, useMemo } from "react";
import { Subject } from "rxjs";

interface TeamsPageContextValue {
  refetchTrigger$: Subject<void>;
}

export const TeamsPageContext = createContext<TeamsPageContextValue | null>(
  null
);

interface TeamsPageContextProviderProps {
  children: ReactNode;
}

export const TeamsPageProvider: FC<TeamsPageContextProviderProps> = ({
  children,
}) => {
  const refetchTrigger$ = useMemo(() => new Subject<void>(), []);

  const contextValue = useMemo<TeamsPageContextValue>(
    () => ({
      refetchTrigger$,
    }),
    [refetchTrigger$]
  );

  return (
    <TeamsPageContext.Provider value={contextValue}>
      {children}
    </TeamsPageContext.Provider>
  );
};

export const useTeamsPageContext = () => {
  const context = useContext(TeamsPageContext);

  if (!context) {
    throw new HookOutOfContextError("useTeamsPageContext", "TeamsPageContext");
  }

  return context;
};
