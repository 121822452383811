import { EntityModel, useRestApiProvider } from "@jugl-web/rest-api";
import { assert, useToast, useTranslations } from "@jugl-web/utils";
import { get } from "lodash";
import { FC } from "react";
import { DangerEntityActionConfirmationDialog } from "@web-src/modules/entities/components/DangerEntityActionConfirmationDialog";

interface LeaveEntityConfirmationDialogProps {
  isOpen: boolean;
  entity: EntityModel | null;
  onLastEntityAdminError: () => void;
  onClose: () => void;
}

const LAST_ADMIN_CANNOT_LEAVE_ERROR_MESSAGE = "last_admin_cannot_leave";

export const LeaveEntityConfirmationDialog: FC<
  LeaveEntityConfirmationDialogProps
> = ({ isOpen, entity, onLastEntityAdminError, onClose }) => {
  const { t } = useTranslations();
  const { toast } = useToast({ variant: "web" });

  const { entitiesApi } = useRestApiProvider();
  const [leaveEntity, { isLoading }] = entitiesApi.useLeaveEntityMutation();

  const handleConfirmLeave = async () => {
    assert(!!entity, "Entity must be defined");

    const response = await leaveEntity({
      entityId: entity.id,
      entityRelId: entity.entityRelId,
    });

    if (!("data" in response)) {
      const errorMessage = get(response, "error.data.errors", "" as string);

      if (errorMessage === LAST_ADMIN_CANNOT_LEAVE_ERROR_MESSAGE) {
        onLastEntityAdminError();
      } else {
        toast(
          t({
            id: "feedback.error-occurred-while-leaving-workspace",
            defaultMessage: "An error occurred while leaving the workspace",
          }),
          { variant: "error" }
        );
      }

      return;
    }

    toast(
      <span>
        {t(
          {
            id: "feedback.removed-from-workspace",
            defaultMessage:
              "Workspace {entityName} was removed from your account",
          },
          { entityName: <b>{entity.name}</b> }
        )}
      </span>,
      { variant: "success" }
    );

    onClose();
  };

  return (
    <DangerEntityActionConfirmationDialog
      isOpen={isOpen}
      entity={entity}
      title={t({
        id: "entities-page.leave-workspace-confirmation-title",
        defaultMessage: "Leave workspace",
      })}
      message={t(
        {
          id: "entities-page.leave-workspace-confirmation-description",
          defaultMessage:
            "You are about to leave the {entityName} workspace. Please note that by doing so, you will lose access to all information within this workspace. This action cannot be undone.",
        },
        {
          entityName: (
            <span className="text-primary-800 font-semibold">
              {entity?.name}
            </span>
          ),
        }
      )}
      requiredActionMessage={t(
        {
          id: "entities-page.leave-workspace-confirmation-input-description",
          defaultMessage:
            "To confirm this action, please type the <b>name of the workspace</b> you wish to leave",
        },
        { b: (text: (string | JSX.Element)[]) => <b>{text}</b> }
      )}
      submitButton={{
        label: t({
          id: "entities-page.leave-workspace",
          defaultMessage: "Leave workspace",
        }),
        isDisabled: isLoading,
        onClick: handleConfirmLeave,
      }}
      onClose={onClose}
    />
  );
};
