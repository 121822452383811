import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from "react";
import { HookOutOfContextError } from "@jugl-web/utils";
import {
  EntityInvitationModel,
  EntityModel,
  useRestApiProvider,
} from "@jugl-web/rest-api";

type EntitySelectPageContextValue = {
  entitiesState: {
    isLoading: boolean;
    isError: boolean;
    isFetching: boolean;
    entities?: EntityModel[] | undefined;
  };
  entityInvitesState: {
    isLoading: boolean;
    isError: boolean;
    isFetching: boolean;
    entityInvites?: EntityInvitationModel[] | undefined;
  };
  refetchEntities: () => void;
  refetchEntityInvites: () => void;
};

const EntitySelectPageContext =
  createContext<EntitySelectPageContextValue | null>(null);

export const EntitySelectPageProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { entitiesApi } = useRestApiProvider();
  const {
    data: entities,
    isLoading: isEntitiesLoading,
    isFetching: isEntitiesFetching,
    isError: isEntitiesError,
    refetch: refetchEntities,
  } = entitiesApi.use_DEPRECATED_getAllOrgEntitiesListQuery();

  const {
    data: entityInvites,
    isLoading: isEntityInvitesLoading,
    isFetching: isEntityInvitesFetching,
    isError: isEntityInvitesError,
    refetch: refetchEntityInvites,
  } = entitiesApi.useGetEntityInvitesQuery();

  const value: EntitySelectPageContextValue = useMemo(
    () => ({
      entitiesState: {
        isLoading: isEntitiesLoading,
        isFetching: isEntitiesFetching,
        isError: isEntitiesError,
        entities,
      },
      entityInvitesState: {
        isLoading: isEntityInvitesLoading,
        isFetching: isEntityInvitesFetching,
        isError: isEntityInvitesError,
        entityInvites,
      },
      refetchEntities,
      refetchEntityInvites,
    }),
    [
      isEntitiesLoading,
      isEntitiesFetching,
      isEntitiesError,
      entities,
      isEntityInvitesLoading,
      isEntityInvitesFetching,
      isEntityInvitesError,
      entityInvites,
      refetchEntities,
      refetchEntityInvites,
    ]
  );

  return (
    <EntitySelectPageContext.Provider value={value}>
      {children}
    </EntitySelectPageContext.Provider>
  );
};

export const useEntitySelectPageProvider = () => {
  const context = useContext(EntitySelectPageContext);

  if (!context) {
    throw new HookOutOfContextError(
      "useEntitySelectPageProvider",
      "EntitySelectPageContext"
    );
  }

  return context;
};
