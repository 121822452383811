import { useEffect, useState } from "react";

export type OnResizeCallback = (entry: ResizeObserverEntry) => void;

interface ResizeObserverOptions {
  onResize: OnResizeCallback;
}

export const useResizeObserver = <TElement extends HTMLElement>({
  onResize,
}: ResizeObserverOptions) => {
  const [node, setNode] = useState<TElement | null>(null);

  useEffect(() => {
    if (!node) {
      return;
    }

    const instance = new ResizeObserver(([entry]) => onResize(entry));

    instance.observe(node);

    // eslint-disable-next-line consistent-return
    return () => {
      instance.disconnect();
    };
  }, [node, onResize]);

  return {
    ref: setNode,
  };
};
