import { FC, useState } from "react";

interface TemplateWithClosureTriggerProps {
  children: (closureProps: {
    isOpen: boolean;
    onClose: () => void;
  }) => JSX.Element;
  isInitiallyOpen?: boolean;
  onOpen?: () => void;
}

export const TemplateWithClosureTrigger: FC<
  TemplateWithClosureTriggerProps
> = ({ children, isInitiallyOpen = false, onOpen }) => {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);

  const handleOpen = () => {
    onOpen?.();
    setIsOpen(true);
  };

  const handleClose = () => setIsOpen(false);

  return (
    <div>
      <button type="button" onClick={handleOpen}>
        Open
      </button>
      {children({ isOpen, onClose: handleClose })}
    </div>
  );
};
