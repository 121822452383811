import { HookOutOfContextError } from "@jugl-web/utils";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";

type WorkspaceSubscriptionPageContextValue = {
  seatsUpdateState: number;
  setSeatsUpdateState: (additionalSeats: number) => void;
};

const WorkspaceSubscriptionPageContext =
  createContext<WorkspaceSubscriptionPageContextValue | null>(null);

export const WorkspaceSubscriptionPageProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [seatsUpdateState, setSeatsUpdateState] = useState<number>(0);

  const value: WorkspaceSubscriptionPageContextValue = useMemo(
    () => ({
      seatsUpdateState,
      setSeatsUpdateState,
    }),
    [seatsUpdateState]
  );

  return (
    <WorkspaceSubscriptionPageContext.Provider value={value}>
      {children}
    </WorkspaceSubscriptionPageContext.Provider>
  );
};

export const useWorkspaceSubscriptionPageProvider = () => {
  const context = useContext(WorkspaceSubscriptionPageContext);

  if (!context) {
    throw new HookOutOfContextError(
      "useWorkspaceSubscriptionPageProvider",
      "WorkspaceSubscriptionPageContext"
    );
  }

  return context;
};
