import { cx } from "@jugl-web/utils";
import { AvatarMember } from "../CollectiveAvatar";

const AvatarImage = ({
  member,
  size,
  className,
}: {
  member: AvatarMember;
  size: number;
  className: string;
}) => {
  const hasImage = !!member.imageUrl;
  const initials = member.firstName
    .charAt(0)
    .concat(member?.lastName?.charAt(0));

  if (!hasImage) {
    return (
      <span
        className={cx(
          className,
          "flex items-center justify-center font-semibold uppercase text-[#333333]"
        )}
        style={{
          background: "linear-gradient(180deg, #CECED3 0%, #ECEFF0 69.27%)",
        }}
      >
        {initials}
      </span>
    );
  }

  return (
    <img
      src={member.imageUrl as string}
      alt={`${member.firstName} ${member.lastName}`}
      className={cx(className, "object-cover")}
      width={size}
      height={size}
      loading="lazy"
    />
  );
};

export type AvatarStackSize = "xs" | "sm" | "md";

export interface AvatarStackProps {
  members: AvatarMember[];
  maxCount: number;
  size: AvatarStackSize;
  shouldShowNameForSingleMember?: boolean;
  singleMemberLabelClassName?: string;
  className?: string;
}

const avatarStackSizeToImageSize: Record<AvatarStackSize, number> = {
  xs: 20,
  sm: 24,
  md: 28,
};

const sizeToTextClasses: Record<AvatarStackSize, string> = {
  xs: "text-[8px]",
  sm: "text-[10px]",
  md: "text-xs",
};

export const AvatarStack = ({
  members,
  maxCount,
  size,
  shouldShowNameForSingleMember,
  singleMemberLabelClassName,
  className,
}: AvatarStackProps) => {
  if (maxCount <= 0) {
    throw new Error("maxCount should be greater than 0");
  }

  const remainingMembers = members.length - maxCount;
  const shouldApplyOverlay = remainingMembers > 0;
  const hasSingleMember = members.length === 1;

  return (
    <div className={cx("inline-flex items-center overflow-hidden", className)}>
      {members.slice(0, maxCount).map((member, index, limitedMembers) => {
        const isFirst = index === 0;
        const isLast = index === limitedMembers.length - 1;

        return (
          <div
            key={+index}
            className={cx(
              "relative overflow-hidden rounded-full border border-solid border-white",
              !isFirst && {
                "-ml-2": size === "md",
                "-ml-[9px]": size === "sm",
                "-ml-1": size === "xs",
              }
            )}
            style={{
              width: avatarStackSizeToImageSize[size],
              height: avatarStackSizeToImageSize[size],
            }}
          >
            {isLast && shouldApplyOverlay && (
              <div className="absolute inset-0 flex items-center justify-center bg-black/40">
                <span
                  className={cx(
                    "font-semibold leading-[12px] text-white",
                    sizeToTextClasses[size]
                  )}
                >
                  +{remainingMembers}
                </span>
              </div>
            )}
            <AvatarImage
              member={member}
              size={avatarStackSizeToImageSize[size]}
              className={cx("h-full w-full", sizeToTextClasses[size])}
            />
          </div>
        );
      })}
      {shouldShowNameForSingleMember && hasSingleMember && (
        <span
          className={cx(
            "text-dark-800 ml-3 text-sm tracking-[0.14px]",
            singleMemberLabelClassName
          )}
        >
          {members[0].firstName} {members[0].lastName}
        </span>
      )}
    </div>
  );
};
