import { UserGeneralProfile } from "@jugl-web/domain-resources/users/components/UserGeneralProfile";
import {
  Avatar,
  InteractiveContainer,
} from "@jugl-web/ui-components/cross-platform";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { FC, SyntheticEvent, useCallback } from "react";
import { UserRole } from "@jugl-web/rest-api";
import { useCallInvitation } from "@web-src/modules/conference/pages/ConferencePage/hooks/useCallInvitation";
import useNavigateToChat from "@web-src/features/chats/hooks/useNavigateToChat";
import Highlighter from "react-highlight-words";
import { cx } from "@jugl-web/utils";
import { ReactComponent as CallsIcon } from "./assets/calls-icon.svg";
import { ReactComponent as ChatsIcon } from "./assets/chats-icon.svg";
import { UserManagersPopover } from "./components/UserManagersPopover/UserManagersPopover";

export const PeopleUserItem: FC<{
  entityId: string;
  userId: string;
  highlightString?: string;
  className?: string;
  onClick?: (userId: string) => void;
}> = ({ entityId, userId, highlightString, className, onClick }) => {
  const { me } = useMe();
  const { call } = useCallInvitation();
  const navigateToChat = useNavigateToChat();

  const handleCallClick = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      call({
        callType: "audio",
        userId,
      });
    },
    [call, userId]
  );

  const handleChatClick = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      navigateToChat(userId);
    },
    [navigateToChat, userId]
  );

  return (
    <UserGeneralProfile entityId={entityId} userId={userId}>
      {(profile) => (
        <InteractiveContainer
          className={cx(
            "user-item flex items-center border border-t-0 border-l-0 border-r-0 border-solid border-gray-200 px-12 py-7 hover:bg-[#F9FAFB]",
            className
          )}
          onClick={() => onClick?.(userId)}
        >
          {profile.profile ? (
            <>
              <Avatar
                size="xl"
                imageUrl={profile.profile.avatar}
                username={profile.profile.displayName}
                className="mr-5"
              />
              <div className="w-[300px] truncate text-lg font-semibold">
                <Highlighter
                  autoEscape
                  highlightClassName="text-primary font-semibold bg-transparent"
                  searchWords={[highlightString || ""]}
                  textToHighlight={profile.profile.displayName}
                />
              </div>
              <div className="text-dark-700 text-sm">
                {profile.profile.role === UserRole.admin ? "Admin" : "Member"} |{" "}
                {profile.profile.department?.name || "No Department"}
              </div>
              <div className="flex flex-1 justify-end gap-6">
                <UserManagersPopover userId={userId} />
                {me?.id !== profile.profile.id ? (
                  <>
                    <InteractiveContainer
                      onClick={handleCallClick}
                      className="flex h-12 w-12 items-center justify-center rounded-lg hover:bg-[#EEF2F5]"
                    >
                      <CallsIcon />
                    </InteractiveContainer>
                    <InteractiveContainer
                      onClick={handleChatClick}
                      className="flex h-12 w-12 items-center justify-center rounded-lg hover:bg-[#EEF2F5]"
                    >
                      <ChatsIcon />
                    </InteractiveContainer>
                  </>
                ) : null}
              </div>
            </>
          ) : (
            <>
              <div
                className="mr-5 h-14 w-14 animate-pulse rounded-full"
                style={{
                  background:
                    "linear-gradient(90.14deg, #D9DCDD 0.09%, #ECEFF0 99.85%), #DBDEDF",
                }}
              />
              <div
                className="h-4 w-[200px] animate-pulse rounded"
                style={{
                  background:
                    "linear-gradient(90.14deg, #D9DCDD 0.09%, #ECEFF0 99.85%), #DBDEDF",
                }}
              />
            </>
          )}
        </InteractiveContainer>
      )}
    </UserGeneralProfile>
  );
};
