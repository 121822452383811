import React, { useMemo, useState } from "react";
import Button from "@mui/material/Button";
import useActiveChat from "@web-src/features/chats/hooks/useActiveChat";
import { useTranslations } from "@jugl-web/utils";
import {
  ChatItem,
  ChatMessage,
  ChatMessageType,
  ChatType,
} from "@web-src/features/chats/types";
import useSendMessage from "@web-src/features/chats/hooks/useSendMessage";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { ChatListPicker } from "@web-src/components/ChatListPicker";
import { ChatSideBar } from "@web-src/modules/chats/components/ChatSideBar";
import useConversations from "../../hooks/useConversations";
import { getChatMessageBody } from "../../utils";

const ForwardSideBar: React.FC<{
  messages: ChatMessage[];
  onRequestClose: () => void;
}> = ({ messages, onRequestClose }) => {
  const { conversations } = useConversations();
  const { chat } = useActiveChat();
  const [selectedChats, setSelectedChats] = useState<ChatItem[]>([]);
  const sendMessage = useSendMessage();
  const [search, setSearch] = useState("");

  const sortedMessages = useMemo(
    () =>
      messages.sort(
        (a, b) =>
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
      ),
    [messages]
  );

  const handleForwardClick = async () => {
    if (!chat) {
      return;
    }
    try {
      selectedChats.forEach((forwardChat) => {
        sortedMessages.forEach((messageItem) => {
          if (!forwardChat.id) {
            return;
          }
          sendMessage?.({
            to: forwardChat.id,
            type:
              forwardChat.type === ChatType.muc
                ? ChatMessageType.muc
                : ChatMessageType.chat,
            body: getChatMessageBody(messageItem),
            extraPayload: {
              attachments: messageItem.payload?.attachments,
              forwarded: true,
            },
          });
        });
      });
      onRequestClose();
    } catch (error) {
      // TODO: handle error
    }
  };

  const visibleChats = useMemo(
    () =>
      conversations.filter(
        (item) =>
          item.id !== chat?.id &&
          item.data.title?.toLowerCase().includes(search.toLocaleLowerCase())
      ),
    [conversations, chat, search]
  );

  const chatsToDisplay = useMemo(
    () => visibleChats.map((el) => el.data),
    [visibleChats]
  );

  const { t } = useTranslations();

  return (
    <ChatSideBar
      title={t(
        {
          id: "chats-page.forward-messages",
          defaultMessage: "Forward {messagesCount} Messages to...",
        },
        {
          messagesCount: messages.length,
        }
      )}
      onRequestClose={onRequestClose}
      footer={
        <Button
          color="primary"
          variant="contained"
          size="large"
          fullWidth
          disabled={!messages.length || !selectedChats.length}
          onClick={handleForwardClick}
        >
          {t({
            id: "common.forward",
            defaultMessage: "Forward",
          })}
        </Button>
      }
    >
      <div className="px-4">
        <SearchInput
          variant="blank"
          className="my-2"
          value={search}
          onChange={(e) => setSearch(e.currentTarget.value)}
        />
      </div>
      <ChatListPicker
        chats={chatsToDisplay}
        value={selectedChats}
        onSelect={setSelectedChats}
      />
    </ChatSideBar>
  );
};

export default ForwardSideBar;
