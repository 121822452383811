import {
  BaseTask,
  InternalTaskCustomField,
  TaskDefaultStatus,
} from "@jugl-web/rest-api/tasks";
import { isoToLocalDate } from "@jugl-web/utils/date-time";
import { Locale } from "date-fns";
import add from "date-fns/add";
import differenceInDays from "date-fns/differenceInDays";
import endOfDay from "date-fns/endOfDay";
import format from "date-fns/format";
import isAfter from "date-fns/isAfter";
import isToday from "date-fns/isToday";
import isTomorrow from "date-fns/isTomorrow";
import truncate from "lodash/truncate";
import { TEMPLATE_FOLDER_PRESETS } from "./consts";

const MAX_TASK_NAME_LENGTH = 100;

export const getDueDateBasedOnDays = (dueDateInDays: number) =>
  endOfDay(add(new Date(), { days: dueDateInDays - 1 }));

export const getDueDateInDays = (dueDate: Date) =>
  differenceInDays(dueDate, new Date()) + 1;

interface GetDueDateLabelOptions {
  dueDate: Date;
  locale?: Locale;
  isConcise?: boolean;
}

export interface TransformedActivity {
  custom_fields?: {
    name: string;
    value: string;
    isRemoved?: boolean;
  };
}
export const getDueDateLabel = ({
  dueDate,
  locale,
  isConcise,
}: GetDueDateLabelOptions) => {
  if (isToday(dueDate)) {
    return "Today";
  }

  if (isTomorrow(dueDate)) {
    return "Tomorrow";
  }

  return format(dueDate, `${isConcise ? "MMM" : "MMMM"} d, uuuu`, { locale });
};

export const shortenTaskTitle = (
  title: string,
  maxLength: number = MAX_TASK_NAME_LENGTH
) =>
  truncate(title, {
    length: maxLength,
    omission: "…",
  });

export const isTaskOverdue = <TTask extends BaseTask>(task: TTask) =>
  !!(
    task.due_at &&
    isAfter(new Date(), isoToLocalDate(task.due_at)) &&
    task.status !== TaskDefaultStatus.completed
  );

export const formatCustomFieldValue = (
  customField: InternalTaskCustomField,
  rawValue: string
) => {
  if (customField.type === "date") {
    return getDueDateLabel({
      dueDate: new Date(rawValue),
      isConcise: true,
    });
  }

  if (customField.type === "dropdown") {
    return (
      customField.values?.find((value) => value.id === rawValue)?.value ||
      "Unknown"
    );
  }

  return rawValue;
};

export const getRandomFolderPreset = () =>
  TEMPLATE_FOLDER_PRESETS[
    Math.floor(Math.random() * TEMPLATE_FOLDER_PRESETS.length)
  ];
