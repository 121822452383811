import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from "react";

import {
  isElementScrolledTop,
  isElementScrolledBottom,
} from "@web-src/utils/helper";
import { cx } from "@jugl-web/utils";

const InfiniteScroll: React.FC<
  PropsWithChildren<{
    children: ReactNode;
    onReachEnd?: () => void;
    onReachStart?: () => void;
    disabled?: boolean;
    className?: string;
  }>
> = ({ children, onReachEnd, onReachStart, disabled, className }) => {
  const $eventsContent = useRef<HTMLDivElement>(null);

  const checkPosition = useCallback(() => {
    if (disabled) {
      return;
    }
    if (!$eventsContent.current) {
      return;
    }
    const $element = $eventsContent.current;
    if (isElementScrolledTop($element, 100)) {
      onReachStart?.();
    }
    if (isElementScrolledBottom($element, 100)) {
      onReachEnd?.();
    }
  }, [onReachEnd, onReachStart, disabled]);

  const handleContentScroll = () => {
    checkPosition();
  };

  useEffect(() => {
    if (disabled) {
      return;
    }
    checkPosition();
  }, [checkPosition, disabled]);

  useEffect(() => {
    if (disabled) {
      return;
    }
    const $el = $eventsContent?.current;
    if ($el && $el.scrollHeight <= $el.clientHeight) {
      onReachEnd?.();
    }
  }, [children, onReachEnd, disabled]);

  return (
    <div
      className={cx("overflow-y-auto", className)}
      onScroll={handleContentScroll}
      ref={$eventsContent}
    >
      {children}
    </div>
  );
};

export default InfiniteScroll;
