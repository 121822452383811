import React, { useEffect, useRef, useState } from "react";
import { useTranslations } from "@jugl-web/utils";
import {
  Drawer,
  LoadingSpinner,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import {
  getStorageItemWithFallback,
  saveItemToStorage,
  TIMEZONE_INFO_POPUP_SHOWN,
} from "@jugl-web/utils/storage";
import { useTimeZone } from "@web-src/modules/settings/providers/TimeZoneProvider";
import { ReactComponent as CloseIcon } from "./assets/close.svg";
import { ReactComponent as InfoIcon } from "./assets/info.svg";
import { InfoDialog } from "./components/InfoDialog/InfoDialog";
import { RegionDialogPicker } from "./components/RegionDialogPicker/RegionDialogPicker";
import { TimeZoneDialogPicker } from "./components/TimeZoneDialogPicker/TimeZoneDialogPicker";

export const TimeZoneSettingsDialog: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const { t } = useTranslations();
  const [isInfoPopupOpen, setIsInfoPopupOpen] = useState(
    getStorageItemWithFallback(TIMEZONE_INFO_POPUP_SHOWN, true)
  );
  const isTimezoneMismatchChecked = useRef(false);

  const {
    region,
    isLoading,
    timeZone,
    isTimezoneMismatch,
    isTimezoneMismatchAlertOpen,
    openTimezoneMismatchAlert,
    changeRegion,
    changeZone,
  } = useTimeZone();

  useEffect(() => {
    if (!isOpen && isTimezoneMismatchChecked.current) {
      isTimezoneMismatchChecked.current = false;
    }
    if (!isOpen || isTimezoneMismatchChecked.current) {
      return;
    }
    isTimezoneMismatchChecked.current = true;
    if (isOpen && isTimezoneMismatch) {
      openTimezoneMismatchAlert();
    }
  }, [isOpen, isTimezoneMismatch, openTimezoneMismatchAlert]);

  return (
    <>
      <Drawer
        isOpen={isOpen && !isInfoPopupOpen && !isTimezoneMismatchAlertOpen}
        size="md"
        placement="right"
        hasBackdrop
        onClose={onClose}
        className="flex flex-col"
      >
        <header className="flex items-center justify-between px-8 py-[18px] shadow-md">
          <div className="flex items-center gap-2.5">
            <PlainButton onClick={() => setIsInfoPopupOpen(true)}>
              <InfoIcon />
            </PlainButton>
            <h3 className="text-dark m-0 text-lg font-semibold leading-4">
              {t({
                id: "settings-page.time-zone",
                defaultMessage: "Time Zone",
              })}
            </h3>
          </div>
          <PlainButton onClick={onClose}>
            <CloseIcon />
          </PlainButton>
        </header>
        <div className="p-10">
          {isLoading || !region || !timeZone ? (
            <div className="mt-7 flex w-full justify-center">
              <LoadingSpinner />
            </div>
          ) : (
            <div className="bg-grey-100 flex flex-col gap-4 rounded-2xl p-4">
              <p className="text-dark m-0">
                {t({
                  id: "settings-page.select-time-zone",
                  defaultMessage: "Select Time Zone",
                })}
              </p>
              <RegionDialogPicker region={region} setRegion={changeRegion} />
              <TimeZoneDialogPicker
                timeZone={timeZone}
                setTimeZone={changeZone}
                region={region}
              />
            </div>
          )}
        </div>
      </Drawer>
      <InfoDialog
        isOpen={isOpen && isInfoPopupOpen && !isTimezoneMismatchAlertOpen}
        onClose={() => {
          setIsInfoPopupOpen(false);
          saveItemToStorage(TIMEZONE_INFO_POPUP_SHOWN, false);
        }}
      />
    </>
  );
};
