import { emptySplitApi } from "@web-src/features/api/createApi";
import { UserEntityPreferences } from "./types";

const apiWithTags = emptySplitApi.enhanceEndpoints({
  addTagTypes: [],
});

export const settingsApi = apiWithTags.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getPreferences: build.query<
      UserEntityPreferences,
      {
        entityId: string;
      }
    >({
      query: ({ entityId }) => ({
        url: `/api/auth/entity/${entityId}/preferences`,
      }),
    }),
    updatePreferences: build.mutation<
      void,
      {
        entityId: string;
        data: UserEntityPreferences;
      }
    >({
      query: ({ entityId, data }) => ({
        url: `/api/auth/entity/${entityId}/preferences`,
        method: "POST",
        data: {
          preferences: data,
        },
      }),
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          settingsApi.util.updateQueryData(
            "getPreferences",
            { entityId: args.entityId },
            (preferencesDraft) => ({
              notification_pref: {
                ...preferencesDraft.notification_pref,
                ...args.data.notification_pref,
              },
            })
          )
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
  }),
});

export const {
  useGetPreferencesQuery,
  useLazyGetPreferencesQuery,
  useUpdatePreferencesMutation,
} = settingsApi;
