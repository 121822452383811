/* eslint-disable react/destructuring-assignment */
import { FC } from "react";
import { BlankSearchInput } from "./BlankSearchInput";
import { FilledSearchInput } from "./FilledSearchInput";
import { BaseInputProps } from "./types";

export type SearchInputProps = BaseInputProps &
  (
    | { variant: "blank" }
    | { variant: "filled"; color: "primary" | "grey" | "white" }
  );

export const SearchInput: FC<SearchInputProps> = (props) => {
  if (props.variant === "blank") {
    const { onClear, alwaysShowClear, containerClassName, ...inputProps } =
      props;

    return (
      <BlankSearchInput
        alwaysShowClear={alwaysShowClear}
        containerClassName={containerClassName}
        onClear={onClear}
        {...inputProps}
      />
    );
  }

  if (props.variant === "filled") {
    const {
      color,
      alwaysShowClear,
      containerClassName,
      onClear,
      ...inputProps
    } = props;

    return (
      <FilledSearchInput
        color={color}
        alwaysShowClear={alwaysShowClear}
        containerClassName={containerClassName}
        onClear={onClear}
        {...inputProps}
      />
    );
  }

  throw new Error("Search input variant has to be specified");
};
