import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { useTranslations } from "@jugl-web/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { getUserProfileDisplayName } from "../../utils";

const UserProfileName: React.FC<{
  userId?: string | null;
  suffix?: string;
}> = ({ userId, suffix }) => {
  const meId = useSelector(selectUserId);
  const { usersApi } = useRestApiProvider();
  const { data } = usersApi.useGetUserProfileQuery(
    userId ? { params: { user_id: userId } } : skipToken
  );
  const { t } = useTranslations();

  const name = useMemo(() => {
    if (meId === userId) {
      return `${t({
        id: "common.you",
        defaultMessage: "You",
      })}${suffix || ""}`;
    }
    if (!data) {
      return null;
    }
    return `${getUserProfileDisplayName(data)}${suffix || ""}`;
  }, [data, meId, suffix, userId, t]);
  return <>{name}</>;
};

export default UserProfileName;
