import { useRestApiProvider } from "@jugl-web/rest-api";
import { TaskAttachment } from "@jugl-web/rest-api/tasks";
import { useAppVariant, useToast, useTranslations } from "@jugl-web/utils";
import { ChangeEvent, forwardRef, useImperativeHandle, useRef } from "react";
import { useTusUpload } from "../../../files/hooks/useTusUpload";
import { FilesModule } from "../../../files/types";

export type ControlledProps = {
  mode: "controlled";
  onFileAdded: (file: File[]) => void;
  onAttachmentRename: (attachment: TaskAttachment, name: string) => void;
  onAttachmentRemove: (attachmentId: string) => void;
};

export type UncontrolledProps = {
  mode: "uncontrolled";
  taskId: string;
};

type TaskAttachmentUploadProps = {
  entityId: string;
} & (ControlledProps | UncontrolledProps);

export interface TaskAttachmentsHandle {
  openFilePicker: () => void;
}

const MAX_SIZE_IN_BYTES = 100 * 1024 * 1024; // 100MB

export const TaskAttachmentsUpload = forwardRef<
  TaskAttachmentsHandle,
  TaskAttachmentUploadProps
>(({ entityId, ...modeSpecificProps }, ref) => {
  const { tasksApi } = useRestApiProvider();

  const { uploadFile } = useTusUpload({
    module: FilesModule.drive,
    entityId,
  });

  const { variant } = useAppVariant();
  const { toast, closeToast } = useToast({ variant });
  const { t } = useTranslations();

  const fileInputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    openFilePicker: () => {
      fileInputRef.current?.click();
    },
  }));

  const [invalidateTask] = tasksApi.useInvalidateTaskMutation();

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const showFileTooLargeErrorToast = (fileName: string) => {
    toast(
      t(
        {
          id: "feedback.file-too-large-megabytes",
          defaultMessage:
            "{fileName} is too large. The maximum file size is {maxFileSize}MB",
        },
        {
          fileName,
          maxFileSize: MAX_SIZE_IN_BYTES / (1024 * 1024),
        }
      ),
      { variant: "error" }
    );
  };

  const validateInputFiles = (files: FileList | null) => {
    if (!files) {
      return [];
    }

    return Array.from(files).filter((file) => {
      const isTooLarge = file.size > MAX_SIZE_IN_BYTES;

      if (isTooLarge) {
        showFileTooLargeErrorToast(file.name);
      }

      return !isTooLarge;
    });
  };
  const handleFileInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (modeSpecificProps.mode === "uncontrolled") {
      handleFileInputChange(event);
    }
    if (modeSpecificProps.mode === "controlled") {
      const validFiles = validateInputFiles(event.target.files);
      modeSpecificProps.onFileAdded?.(validFiles);
    }
    clearFileInput();
  };

  const handleFileInputChange = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { files } = event.currentTarget;
    if (modeSpecificProps.mode !== "uncontrolled" || !files) {
      return;
    }

    const validFiles = validateInputFiles(files);

    if (validFiles.length === 0) {
      return;
    }

    const uploadingToastId = toast(
      t(
        {
          id: "tasks-details-page.uploading-attachments",
          defaultMessage:
            "Uploading {files, plural, one {# attachment} other {# attachments}}...",
        },
        {
          files: validFiles.length,
        }
      ),
      { variant: "info", persist: true }
    );

    const response = await Promise.allSettled(
      validFiles.map((file) =>
        uploadFile({
          file,
          extraMetaData: {
            type: "file",
            sub_module: "task",
            task_id: modeSpecificProps.taskId,
          },
        }).catch((error) => {
          toast(
            t(
              {
                id: "feedback.file-upload-failed",
                defaultMessage: "Couldn't upload file {fileName}",
              },
              { fileName: file.name }
            ),
            { variant: "error" }
          );
          throw error;
        })
      )
    );
    const fullFilledResponses = response.filter(
      (result) => result.status === "fulfilled"
    );
    if (fullFilledResponses.length) {
      toast(
        t(
          {
            id: "tasks-details-page.uploading-attachments-success",
            defaultMessage:
              "{files, plural, one {# attachment} other {# attachments}} was added to the Task",
          },
          {
            files: fullFilledResponses.length,
          }
        )
      );
    }

    invalidateTask({ entityId, taskId: modeSpecificProps.taskId });
    closeToast(uploadingToastId);

    clearFileInput();
  };

  return (
    <>
      <input
        ref={fileInputRef}
        type="file"
        multiple
        accept="*/*"
        className="invisible absolute -left-[10000px] opacity-0"
        onChange={handleFileInput}
      />
    </>
  );
});
