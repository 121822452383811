import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import { cx, humanizePastDate, useTranslations } from "@jugl-web/utils";
import { EntityParticipantRole } from "@jugl-web/rest-api";
import React, { useMemo } from "react";
import { isoToLocalDate } from "@web-src/utils/helper";
import { DigestType } from "@web-src/features/chats/types";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import {
  NavigationPage,
  useNavigation,
} from "@web-src/modules/navigation/hooks/useNavigation";
import { ReactComponent as CallIcon } from "./icons/call.svg";
import { ReactComponent as ChatIcon } from "./icons/chat.svg";
import { ReactComponent as DriveIcon } from "./icons/drive.svg";
import { ReactComponent as PeopleIcon } from "./icons/people.svg";
import { ReactComponent as TaskIcon } from "./icons/task.svg";
import { ReactComponent as CustomerIcon } from "./icons/customer.svg";

const typeToBackgroundClasses: Record<DigestType, string> = {
  people: "bg-[#FEF6E2]",
  call: "bg-[#FFE4E4]",
  drive: "bg-primary-50",
  chat: "bg-[#EFF7DF]",
  task: "bg-[#F2E4FB]",
  customer: "bg-[#E5E3FA]",
};

const typeToIcon: Record<DigestType, JSX.Element> = {
  people: <PeopleIcon />,
  call: <CallIcon />,
  drive: <DriveIcon />,
  chat: <ChatIcon />,
  task: <TaskIcon />,
  customer: <CustomerIcon />,
};

const typeToNavigationPage = {
  people: "peoplePeople",
  drive: "driveDrive",
  chat: "chatsChats",
  task: "tasksTasks",
  customer: "customersCustomers",
} satisfies Record<Exclude<DigestType, "call">, NavigationPage>;

export const DigestBubble: React.FC<{
  title: string;
  date: string;
  type: DigestType;
}> = ({ title, date, type }) => {
  const { t } = useTranslations();
  const { dateLocale } = useLanguage();
  const timeStr = useMemo(
    () => humanizePastDate(isoToLocalDate(date), t, dateLocale),
    [date, t, dateLocale]
  );
  const { navigateToPage } = useNavigation();
  const { entity } = useEntity();

  const handleClick = () => {
    if (type === "call") {
      return;
    }

    const targetPage = typeToNavigationPage[type];
    navigateToPage(targetPage);
  };

  return (
    <InteractiveContainer
      className="h-max w-full max-w-[800px] overflow-hidden rounded-xl bg-white p-4 drop-shadow-[0_1px_4px_rgba(0,0,0,0.05)]"
      onClick={handleClick}
      isDisabled={
        type === "customer" && entity?.role !== EntityParticipantRole.admin
      }
    >
      <div className="flex items-center gap-4">
        <div
          className={cx(
            "flex h-8 w-8 shrink-0 items-center justify-center rounded-md",
            typeToBackgroundClasses[type]
          )}
        >
          {typeToIcon[type]}
        </div>
        <span
          className="overflow-hidden break-words text-sm text-black"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </div>
      <div className="text-grey ml-12 mt-2 text-xs">{timeStr}</div>
    </InteractiveContainer>
  );
};
