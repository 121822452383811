import { Customer } from "@jugl-web/rest-api";
import {
  ListBoxItem,
  ListBoxProps,
} from "@jugl-web/ui-components/cross-platform/ListBox";
import { assert, useTranslations } from "@jugl-web/utils";
import { useCallback, useMemo, useState } from "react";
import { useCustomers } from "../../../customers/hooks/useCustomers";
import { CustomerListBoxItem } from "../../components/CustomerListBoxItem";
import { WithoutUserListBoxItem } from "../../components/WithoutUserListBoxItem";
import { WITHOUT_CUSTOMER_ITEM_ID } from "./consts";

type SupportedOverrides = Partial<
  Omit<ListBoxProps<Customer | null>, "onSelect">
>;

interface UseCustomerListBoxOptions {
  entityId: string;
  addWithoutCustomerItem?: boolean;
  skipLoading?: boolean;
}

export const useCustomerListBox = ({
  entityId,
  addWithoutCustomerItem = false,
  skipLoading = false,
}: UseCustomerListBoxOptions) => {
  const [searchQuery, setSearchQuery] = useState("");

  const { customers, isLoading, isFetching, wasSearchingInLastResponse } =
    useCustomers({
      entityId,
      searchQuery,
      skipLoading,
    });

  const { t } = useTranslations();

  const customersAsListItems = useMemo(() => {
    const initialItems: ListBoxItem<Customer | null>[] =
      addWithoutCustomerItem && !wasSearchingInLastResponse
        ? [{ id: WITHOUT_CUSTOMER_ITEM_ID, value: null }]
        : [];

    return [...initialItems, ...customers.map((c) => ({ id: c.id, value: c }))];
  }, [addWithoutCustomerItem, customers, wasSearchingInLastResponse]);

  const getListBoxProps = useCallback(
    (overrides: SupportedOverrides) =>
      ({
        items: customersAsListItems,
        selectionBehavior: { mode: "single", canToggle: true },
        hasSearch: true,
        shouldFilterOnSearch: false,
        loading: isFetching
          ? isLoading
            ? "skeleton"
            : "bottom-spinner"
          : undefined,
        defaultSelectedIds: [],
        maxVisibleItems: 6,
        itemSize: "lg",
        spaceBetweenItems: "normal",
        renderCustomItem: (item, _, props) => {
          if (item.id === WITHOUT_CUSTOMER_ITEM_ID) {
            return (
              <WithoutUserListBoxItem
                {...props}
                label={t({
                  id: "tasks-page.without-customer",
                  defaultMessage: "Without customer",
                })}
              />
            );
          }

          assert(!!item.value, "Item value cannot be null at this point");

          return <CustomerListBoxItem customer={item.value} {...props} />;
        },
        onSearch: setSearchQuery,
        ...overrides,
      } satisfies ListBoxProps<Customer | null>),
    [customersAsListItems, isFetching, isLoading, t]
  );

  return {
    getListBoxProps,
    clearSearchQuery: () => setSearchQuery(""),
  };
};
