import React, { PropsWithChildren, useMemo } from "react";
import SideBar from "@web-src/components/SideBar";
import useEntity from "@web-src/features/app/hooks/useEntity";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import { useMessageInfoQuery } from "@web-src/features/chats/chatsApi";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { dateTimeString } from "@web-src/utils/datetime";
import { Avatar } from "@jugl-web/ui-components/cross-platform";
import ChatMessageBubble from "@web-src/features/chats/components/ChatMessageBubble";
import { ChatMessage } from "@web-src/features/chats/types";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import useEntityParticipants from "@web-src/features/app/hooks/useEntityParticipants";
import { getEntityUserDisplayName } from "@web-src/features/entities/utils";
import { useTranslations } from "@jugl-web/utils";
import sortBy from "lodash/sortBy";
import { User } from "@jugl-web/rest-api";
import { ReactComponent as DoubleCheckIcon } from "./icons/double-check.svg";
import { ReactComponent as CheckIcon } from "./icons/check.svg";
import { ReactComponent as EyeOutlineIcon } from "./icons/eye-outline.svg";
import { ChatMessageProvider } from "../../../chats/providers/ChatMessageProvider";

const Section: React.FC<
  PropsWithChildren<{ title: string; icon: React.FC }>
> = ({ title, icon, children }) => (
  <Stack gap="10px">
    <Stack direction="row" alignItems="center">
      <SvgIcon component={icon} />
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "18px",
          textTransform: "uppercase",
        }}
      >
        {title}
      </Typography>
    </Stack>
    {children}
  </Stack>
);

const ParticipantItem: React.FC<{
  user: User;
  at: string;
}> = ({ user, at }) => {
  const name = useMemo(() => getEntityUserDisplayName(user), [user]);
  // TODO: name, avatar
  return (
    <Stack direction="row" alignItems="center">
      <Avatar
        username={name}
        imageUrl={user?.profile?.img}
        className="h-10 w-10"
      />
      <Stack sx={{ marginLeft: "12px" }}>
        <Typography variant="bodyBold">{name}</Typography>
        <Typography variant="footnote">{at}</Typography>
      </Stack>
    </Stack>
  );
};

export const ChatMessageSideBar: React.FC<{
  message: ChatMessage;
  isOpen: boolean;
  workspaceId?: string;
  onRequestClose: () => void;
}> = ({ isOpen, onRequestClose, message, workspaceId }) => {
  const { entity } = useEntity();
  const { participantsMap } = useEntityParticipants(entity?.id);
  const { data: messageInfo } = useMessageInfoQuery(
    message?.msg_id ? { msgId: message.msg_id } : skipToken,
    { refetchOnMountOrArgChange: true }
  );
  const { me } = useMe();
  const { t } = useTranslations();
  const usersList = useMemo(() => {
    if (!workspaceId || !participantsMap || !messageInfo?.data) {
      return null;
    }
    return {
      delivered: messageInfo?.data?.delivered
        ?.filter(
          (item) => item.user_id !== me?.id && !!participantsMap[item.user_id]
        )
        .map((item) => ({
          participant: participantsMap[item.user_id],
          at: item.action_at ? dateTimeString(item.action_at) : "",
        })),
      read: messageInfo?.data?.read
        ?.filter(
          (item) => item.user_id !== me?.id && !!participantsMap[item.user_id]
        )
        .map((item) => ({
          participant: participantsMap[item.user_id],
          at: item.action_at ? dateTimeString(item.action_at) : "",
        })),
    };
  }, [participantsMap, messageInfo, me, workspaceId]);
  const timestampString = useMemo(
    () => (message ? dateTimeString(message.timestamp) : ""),
    [message]
  );
  const alphabeticallySortedNames = useMemo(
    () => ({
      delivered: sortBy(usersList?.delivered, [
        (item) => item.participant.profile?.first_name,
        (item) => item.participant.profile?.last_name,
      ]),
      read: sortBy(usersList?.read, [
        (item) => item.participant.profile?.first_name,
        (item) => item.participant.profile?.last_name,
      ]),
    }),
    [usersList]
  );
  const deliveredString = useMemo(() => {
    if (
      !!workspaceId ||
      !messageInfo ||
      !messageInfo.data.delivered?.length ||
      !messageInfo.data.delivered[0].action_at
    ) {
      return null;
    }
    return dateTimeString(messageInfo.data.delivered[0].action_at);
  }, [workspaceId, messageInfo]);
  const readString = useMemo(() => {
    if (
      !!workspaceId ||
      !messageInfo ||
      !messageInfo.data.read?.length ||
      !messageInfo.data.read[0].action_at
    ) {
      return null;
    }
    return dateTimeString(messageInfo.data.read[0].action_at);
  }, [workspaceId, messageInfo]);
  return (
    <SideBar
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      title={t({
        id: "chats-page.message-info",
        defaultMessage: "Message Info",
      })}
    >
      <Stack sx={{ px: 2 }} gap="10px">
        <div className="max-w-full pt-5">
          <ChatMessageProvider message={message}>
            {message && <ChatMessageBubble message={message} preview />}
          </ChatMessageProvider>
        </div>
        <Stack
          gap="16px"
          sx={{
            marginTop: "50px",
            paddingBottom: "50px",
          }}
          divider={<Box sx={{ height: "1px", bgcolor: "grey.200" }} />}
        >
          <Section
            title={t({
              id: "chats-page.message-sent",
              defaultMessage: "Message Sent",
            })}
            icon={CheckIcon}
          >
            <Typography variant="footnote">{timestampString}</Typography>
          </Section>
          {deliveredString && (
            <Section
              title={t({
                id: "chats-page.message-delivered",
                defaultMessage: "Message Delivered",
              })}
              icon={DoubleCheckIcon}
            >
              <Typography variant="footnote">{deliveredString}</Typography>
            </Section>
          )}
          {readString && (
            <Section
              title={t({
                id: "chats-page.message-read",
                defaultMessage: "Message Read",
              })}
              icon={EyeOutlineIcon}
            >
              <Typography variant="footnote">{readString}</Typography>
            </Section>
          )}
          {usersList?.delivered?.length && (
            <Section
              title={t({
                id: "chats-page.message-delivered-to",
                defaultMessage: "Delivered to",
              })}
              icon={DoubleCheckIcon}
            >
              <Stack gap="16px">
                {alphabeticallySortedNames.delivered.map((item) => (
                  <ParticipantItem user={item.participant} at={item.at} />
                ))}
              </Stack>
            </Section>
          )}
          {usersList?.read?.length && (
            <Section
              title={t({
                id: "chats-page.message-read-by",
                defaultMessage: "Read by",
              })}
              icon={EyeOutlineIcon}
            >
              <Stack gap="16px">
                {alphabeticallySortedNames.read.map((item) => (
                  <ParticipantItem user={item.participant} at={item.at} />
                ))}
              </Stack>
            </Section>
          )}
        </Stack>
      </Stack>
    </SideBar>
  );
};
