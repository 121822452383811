import React from "react";
import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import format from "date-fns/format";
import { ReactComponent as CalendarIcon } from "@web-src/images/icons/calendar.svg";

export const DateButton: React.FC<{
  date?: Date;
}> = ({ date }) => (
  <InteractiveContainer className="flex w-full items-center justify-between border-0 border-b border-solid border-black/20 p-2.5 text-base leading-4 outline-none">
    <span className={date ? "text-[#363636]" : "text-dark-500"}>
      {date ? format(date, "dd/MM/yyyy") : "DD/MM/YYYY"}
    </span>
    <CalendarIcon />
  </InteractiveContainer>
);
