import { cx } from "@jugl-web/utils";
import { CSSProperties, forwardRef, ReactNode } from "react";
import { Drawer, DrawerProps } from "../../cross-platform/Drawer";
import {
  BreadcrumbsDrawerHeaderProps,
  DrawerHeader,
  DrawerHeaderProps,
  TitleDrawerHeaderProps,
} from "../DrawerHeader";

export interface BottomCenteredDrawerProps
  extends Pick<
    DrawerProps,
    "isOpen" | "onClose" | "onTransitionEnd" | "hasBackdrop" | "initialFocus"
  > {
  children: ReactNode;
  header: (TitleDrawerHeaderProps | BreadcrumbsDrawerHeaderProps) &
    Pick<DrawerHeaderProps, "navigationSlots">;
  className?: string;
}

export const BottomCenteredDrawer = ({
  children,
  isOpen,
  header,
  hasBackdrop,
  initialFocus,
  className,
  onClose,
  onTransitionEnd,
}: BottomCenteredDrawerProps) => (
  <Drawer
    isOpen={isOpen}
    size="auto"
    placement="bottom"
    hasBackdrop={hasBackdrop}
    onClose={onClose}
    onTransitionEnd={onTransitionEnd}
    initialFocus={initialFocus}
    className={cx(
      "left-1/2 flex h-[90vh] w-[960px] -translate-x-1/2 flex-col overflow-x-hidden rounded-t-2xl",
      className
    )}
  >
    <DrawerHeader {...header} onClose={onClose} />
    {children}
  </Drawer>
);

export interface BottomCenteredDrawerContentProps {
  children: ReactNode;
  className?: string;
}

BottomCenteredDrawer.Content = forwardRef<
  HTMLDivElement,
  BottomCenteredDrawerContentProps
>(({ children, className }, ref) => (
  <div ref={ref} className={cx("grow overflow-y-auto", className)}>
    {children}
  </div>
));

export interface BottomCenteredDrawerActionsProps {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}

BottomCenteredDrawer.Actions = ({
  children,
  className,
  style,
}: BottomCenteredDrawerActionsProps) => (
  <div
    className={cx("px-8 pb-10 pt-6", className)}
    style={{
      boxShadow: "0px 6px 24px 0px rgba(18, 22, 34, 0.12)",
      ...style,
    }}
  >
    {children}
  </div>
);
