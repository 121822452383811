import React, { useEffect, useState } from "react";

const DEFAULT_TIMEOUT = 120;

export const CountDownTimer: React.FC<{
  onCountDownEnd: () => void;
  timeout?: number;
}> = ({ onCountDownEnd, timeout = DEFAULT_TIMEOUT }) => {
  const [remainingSeconds, setRemainingSeconds] = useState(timeout);

  const formattedTimer = `${Math.floor(remainingSeconds / 60)}:${
    remainingSeconds % 60 < 10
      ? `0${remainingSeconds % 60}`
      : remainingSeconds % 60
  }`;

  useEffect(() => {
    const timerInterval = setInterval(
      () =>
        setRemainingSeconds((previousSeconds) =>
          previousSeconds > 0 ? previousSeconds - 1 : previousSeconds
        ),
      1000
    );

    return () => {
      clearInterval(timerInterval);
    };
  }, []);

  useEffect(() => {
    if (remainingSeconds === 0) {
      onCountDownEnd();
      setRemainingSeconds(remainingSeconds);
    }
  }, [onCountDownEnd, remainingSeconds]);

  return (
    <span className="text-primary cursor-pointer text-sm font-semibold">
      {formattedTimer}
    </span>
  );
};
