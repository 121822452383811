import React, { PropsWithChildren, useMemo } from "react";
import { useTranslations } from "@jugl-web/utils";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { TabsLayout } from "@web-src/modules/common/components/TabsLayout";
import { ReactComponent as JuglLogo } from "./assets/jugl-logo.svg";
import { NoSubscriptionHeaderMenu } from "../NoSubscriptionHeaderMenu";

type TabId = "settings" | "subscription" | "members" | "roles";

type TabItem = {
  id: TabId;
  title: string;
  hidden?: boolean;
  href?: string;
  onClick?: () => void;
};

export type SettingsTabsLayoutProps = PropsWithChildren<{
  selectedTab: TabId;
  rightContent?: React.ReactNode;
  isSubscriptionExpired?: boolean;
}>;

export const SettingsTabsLayout: React.FC<
  PropsWithChildren<SettingsTabsLayoutProps>
> = ({ children, selectedTab, rightContent, isSubscriptionExpired }) => {
  const { t } = useTranslations();
  const { navigateToPage } = useNavigation();
  const { subscriptionInfo } = useEntitySelectedProvider();

  const tabs: TabItem[] = useMemo(() => {
    const subscriptionTabItem: TabItem = {
      id: "subscription",
      title: t({
        id: "workspace-settings-page.subscription",
        defaultMessage: "Subscription",
      }),
      onClick: () => navigateToPage("workspaceSubscription"),
    };
    const membersTabItem: TabItem = {
      id: "members",
      title: t({
        id: "workspace-settings-page.members",
        defaultMessage: "Members",
      }),
      onClick: () => navigateToPage("workspaceCPanel"),
    };
    if (isSubscriptionExpired) {
      return [subscriptionTabItem, membersTabItem];
    }
    const result: TabItem[] = [
      {
        id: "settings",
        title: t({
          id: "workspace-settings-page.settings",
          defaultMessage: "Settings",
        }),
        onClick: () => navigateToPage("workspaceSettings"),
      },
      membersTabItem,
      {
        ...subscriptionTabItem,
        hidden: subscriptionInfo.status === "free" || isSubscriptionExpired,
      },
      {
        id: "roles",
        title: t({
          id: "workspace-settings-page.roles-and-permissions",
          defaultMessage: "Roles and Permissions",
        }),
        onClick: () => navigateToPage("entitySelect"),
        hidden: true,
      },
    ];
    return result.filter((tab) => !tab.hidden);
  }, [t, isSubscriptionExpired, subscriptionInfo.status, navigateToPage]);

  return (
    <TabsLayout
      tabs={tabs}
      selectedTab={selectedTab}
      rightContent={rightContent}
      breadcrumbProps={
        isSubscriptionExpired
          ? {
              icon: <JuglLogo />,
              rightContent: <NoSubscriptionHeaderMenu />,
              items: [
                {
                  title: t({
                    id: "workspace-settings-page.workspace-subscription",
                    defaultMessage: "Workspace Subscription",
                  }),
                  onClick: () => navigateToPage("workspaceSubscription"),
                },
              ],
            }
          : {
              items: [
                {
                  title: t({
                    id: "workspace-settings-page.workspace-settings",
                    defaultMessage: "Workspace Settings",
                  }),
                },
              ],
            }
      }
    >
      {children}
    </TabsLayout>
  );
};
