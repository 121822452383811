import { useFormattedDate } from "@jugl-web/utils/hooks/useFormattedDate";
import { eachDayOfInterval, endOfWeek, startOfWeek } from "date-fns";
import { useMemo } from "react";
import { useTasksPageContext } from "../../TasksPageContext";

export const useWeekdays = () => {
  const { taskCalendarDate } = useTasksPageContext();

  const { localeAwareFormat } = useFormattedDate();

  return useMemo(
    () =>
      eachDayOfInterval({
        start: startOfWeek(taskCalendarDate, { weekStartsOn: 1 }),
        end: endOfWeek(taskCalendarDate, { weekStartsOn: 1 }),
      }).map((date) => ({
        date,
        label: localeAwareFormat(date, "iii"),
      })),
    [localeAwareFormat, taskCalendarDate]
  );
};
