import parseISO from "date-fns/parseISO";
import { ApiUTCDateTime } from "./types";

/**
 * Converts an API UTC date string to a local Date object.
 *
 * @param {ApiUTCDateTime} utcDate - The API UTC date string to be converted.
 * @returns {Date} Returns a local Date object representing the converted date.
 */
export const apiUTCToLocalDateTime = (utcDate: ApiUTCDateTime) =>
  parseISO(`${utcDate}Z`);
