import { FILTER_SET_NAME_CHAR_LIMIT } from "@jugl-web/domain-resources/tasks/consts";
import {
  InteractiveContainer,
  TextField,
  TextFieldProps,
} from "@jugl-web/ui-components/cross-platform";
import {
  Button,
  ButtonProps,
} from "@jugl-web/ui-components/cross-platform/Button";
import { cx, useTranslations } from "@jugl-web/utils";
import { FC, useEffect, useRef } from "react";
import Lottie from "react-lottie";
import { ReactComponent as ChevronRightIcon } from "./assets/chevron-right.svg";
import filterSetAnimation from "./assets/filter-set-animation.json";

export const FilterSetForm = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <div className={cx("flex flex-col items-center", className)}>{children}</div>
);

FilterSetForm.Header = (({ type, className }) => {
  const { t } = useTranslations();

  const hasEditType = type === "edit";

  return (
    <div className={cx("flex flex-col text-center", className)}>
      <Lottie
        options={{ animationData: filterSetAnimation }}
        height={135}
        width={160}
      />
      <span className="text-dark font-secondary mt-4 mb-2 text-[32px] font-semibold leading-[48px] tracking-[0.32px]">
        {hasEditType
          ? t({
              id: "tasks-page.edit-filter-set",
              defaultMessage: "Edit filter set",
            })
          : t({
              id: "tasks-page.new-filter-set",
              defaultMessage: "New filter set",
            })}
      </span>
      {!hasEditType && (
        <span className="text-dark-600 leading-5 tracking-[0.32px]">
          {t({
            id: "tasks-page.save-applied-filters",
            defaultMessage:
              "Save applied filters as filter set for daily usage ✨",
          })}
        </span>
      )}
    </div>
  );
}) as FC<{ type: "create" | "edit"; className?: string }>;

FilterSetForm.Input = (({ className, autoFocus = true, ...props }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { t } = useTranslations();

  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus();
    }
  }, [autoFocus]);

  return (
    <TextField
      ref={inputRef}
      isRequired
      maxLength={FILTER_SET_NAME_CHAR_LIMIT}
      label={t({
        id: "form-controls.filter-set-name.label",
        defaultMessage: "Filter set name",
      })}
      placeholder={t({
        id: "form-controls.filter-set-name.placeholder",
        defaultMessage: "Enter filter set name",
      })}
      isFullWidth
      className={cx("w-[400px]", className)}
      {...props}
    />
  );
}) as FC<TextFieldProps>;

FilterSetForm.MoveToFiltersButton = (({
  activeFiltersCount,
  className,
  onClick,
}) => {
  const { t } = useTranslations();

  return (
    <InteractiveContainer
      className={cx(
        "flex h-[60px] w-[400px] items-center justify-between rounded-xl bg-[#FAFAFA] px-4 transition-colors hover:bg-[#F7F7F8]",
        className
      )}
      onClick={onClick}
    >
      <span className="text-dark-800 tracking-[0.16px]">
        {t({ id: "tasks.page-filters", defaultMessage: "Filters" })}
      </span>
      <div className="flex items-center gap-2">
        <span className="text-grey-700 text-sm tracking-[0.14px]">
          {t(
            {
              id: "tasks-page.filters-applied-count",
              defaultMessage:
                "{count} {count, plural, one {filter} other {filters}} applied",
            },
            { count: activeFiltersCount }
          )}
        </span>
        <ChevronRightIcon />
      </div>
    </InteractiveContainer>
  );
}) as FC<{
  activeFiltersCount: number;
  className?: string;
  onClick: () => void;
}>;

FilterSetForm.SubmitButton = (({ className, ...props }) => (
  <Button
    variant="contained"
    color="primary"
    className={cx("h-10 w-[300px]", className)}
    {...props}
  />
)) as FC<Omit<ButtonProps, "ref">>;
