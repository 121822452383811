import { useTranslations } from "@jugl-web/utils";
import { useFormattedDate } from "@jugl-web/utils/hooks/useFormattedDate";
import { useTasksPageContext } from "@web-src/features/tasks/TasksPageContext";
import { add, endOfWeek, isThisWeek, startOfWeek, sub } from "date-fns";

export const useCalendarNavigation = () => {
  const { taskCalendarDate, taskCalendarView, setTaskCalendarDate } =
    useTasksPageContext();

  const { t } = useTranslations();
  const { localeAwareFormat } = useFormattedDate();

  const label = (() => {
    switch (taskCalendarView) {
      case "day": {
        return localeAwareFormat(taskCalendarDate, "iii d MMM");
      }
      case "week": {
        if (isThisWeek(taskCalendarDate)) {
          return t({
            id: "tasks-page.this-week",
            defaultMessage: "This Week",
          });
        }

        const startDate = startOfWeek(taskCalendarDate, { weekStartsOn: 1 });
        const endDate = endOfWeek(taskCalendarDate, { weekStartsOn: 1 });

        return `${localeAwareFormat(startDate, "d MMM")} - ${localeAwareFormat(
          endDate,
          "d MMM"
        )}`;
      }
      case "month": {
        return localeAwareFormat(taskCalendarDate, "MMM y");
      }
      case "year": {
        return localeAwareFormat(taskCalendarDate, "y");
      }
      default:
        throw new Error(`Invalid view: ${taskCalendarView}`);
    }
  })();

  const moveInTime = (direction: "forward" | "backward") => {
    const offsetFn = direction === "forward" ? add : sub;

    switch (taskCalendarView) {
      case "day":
        setTaskCalendarDate(offsetFn(taskCalendarDate, { days: 1 }));
        break;
      case "week":
        setTaskCalendarDate(offsetFn(taskCalendarDate, { weeks: 1 }));
        break;
      case "month":
        setTaskCalendarDate(offsetFn(taskCalendarDate, { months: 1 }));
        break;
      case "year":
        setTaskCalendarDate(offsetFn(taskCalendarDate, { years: 1 }));
        break;
      default:
        throw new Error(`Invalid view: ${taskCalendarView}`);
    }
  };

  return { label, moveInTime };
};
