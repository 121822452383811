import { priceDisplay } from "@jugl-web/utils";
import { FC } from "react";

export type SummaryItemProps = {
  currency: string;
  promoText?: string;
  hideAmount?: boolean;
  data: {
    title: string;
    subtitle?: string;
    summary: string[];
    amount: number;
  };
};

export const SummaryItem: FC<SummaryItemProps> = ({
  currency,
  promoText,
  hideAmount,
  data: { title, subtitle, summary, amount },
}) => (
  <div className="bg-grey-100 relative overflow-hidden rounded-lg p-4">
    {promoText && (
      <div className="font-secondary absolute top-0 right-0 rounded-bl-lg bg-[#E95CA8] p-2 text-sm font-semibold text-white">
        {promoText}
      </div>
    )}
    <div className="flex items-center justify-between leading-[150%]">
      <div className="font-medium">{title}</div>
      {hideAmount ? <div /> : <div>{priceDisplay(amount, currency)}</div>}
    </div>
    {subtitle && (
      <div className="mb-3 text-sm font-medium text-[#828282]">{subtitle}</div>
    )}
    {!!summary && summary.length > 0 && (
      <div className="flex flex-col gap-[3px]">
        {summary.map((item, idx) => (
          <div className="text-grey-700 text-sm" key={+idx}>
            {item}
          </div>
        ))}
      </div>
    )}
  </div>
);
