import React, { useMemo } from "react";
import { useTranslations } from "@jugl-web/utils";
import { ChatMessagePayloadAttachmentType } from "../../types";
import { ReactComponent as ChatImage } from "./icons/chat-image.svg";
import { ReactComponent as ChatVideo } from "./icons/chat-video.svg";
import { ReactComponent as ChatAudio } from "./icons/chat-audio.svg";
import { ReactComponent as ChatGif } from "./icons/chat-gif.svg";
import { ReactComponent as ChatDocument } from "./icons/chat-document.svg";
import { ReactComponent as ChatVoice } from "./icons/chat-voice.svg";
import { ReactComponent as ChatAttachment } from "./icons/chat-attachment.svg";
import { ReactComponent as ChatPdf } from "./icons/chat-pdf.svg";

const ChatListAttachment: React.FC<{
  attachmentType: ChatMessagePayloadAttachmentType;
  attachmentMime: string;
  attachmentName?: string;
}> = ({ attachmentType, attachmentMime, attachmentName }) => {
  const { t } = useTranslations();
  const { title, icon } = useMemo(() => {
    switch (attachmentType) {
      case ChatMessagePayloadAttachmentType.image:
        return {
          title: t({
            id: "chats-page.image-attachment",
            defaultMessage: "Image",
          }),
          icon: <ChatImage />,
        };
      case ChatMessagePayloadAttachmentType.audio:
        return {
          title: t({
            id: "chats-page.audio-attachment",
            defaultMessage: "Audio",
          }),
          icon: <ChatAudio />,
        };
      case ChatMessagePayloadAttachmentType.video:
        return {
          title: t({
            id: "chats-page.video-attachment",
            defaultMessage: "Video",
          }),
          icon: <ChatVideo />,
        };
      case ChatMessagePayloadAttachmentType.doc:
        return {
          title: t({
            id: "chats-page.document-attachment",
            defaultMessage: "Document",
          }),
          icon: <ChatDocument />,
        };
      case ChatMessagePayloadAttachmentType.otherFile:
        return attachmentMime.search("pdf") === -1
          ? {
              title: t({
                id: "chats-page.attachment-attachment",
                defaultMessage: "Attachment",
              }),
              icon: <ChatAttachment />,
            }
          : {
              title: t({
                id: "chats-page.pdf-attachment",
                defaultMessage: "Pdf",
              }),
              icon: <ChatPdf />,
            };
      case ChatMessagePayloadAttachmentType.voice:
        return {
          title: t({
            id: "chats-page.voice-attachment",
            defaultMessage: "Voice",
          }),
          icon: <ChatVoice />,
        };
      case ChatMessagePayloadAttachmentType.gif:
        return {
          title: t({
            id: "chats-page.gif-attachment",
            defaultMessage: "Gif",
          }),
          icon: <ChatGif />,
        };
      default:
        return {
          title: undefined,
          icon: undefined,
        };
    }
  }, [attachmentMime, attachmentType, t]);
  return (
    <span className="inline-flex items-center align-middle">
      {icon}
      <span className="ml-1.5">{attachmentName || title}</span>
    </span>
  );
};

export default ChatListAttachment;
