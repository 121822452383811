import { useTaskFiltersStateContext } from "@jugl-web/domain-resources/tasks/hooks/useTaskFiltersState";
import {
  ExpectedTaskCustomDropdownFieldValue,
  InternalTaskCustomField,
} from "@jugl-web/rest-api/tasks";
import { ListBoxItem } from "@jugl-web/ui-components/cross-platform";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { assert, useTranslations } from "@jugl-web/utils";
import { FC, useMemo } from "react";
import { FilterSectionProps } from "../../types";
import { CustomFieldFilterPill } from "../CustomFieldFilterPill";
import { FilterSectionLayout } from "../FilterSectionLayout";

interface CustomFieldFilterSectionProps extends FilterSectionProps {
  customField: InternalTaskCustomField;
}

export const WITHOUT_VALUE_ITEM_ID = "__none__";

export const CustomFieldFilterSection: FC<CustomFieldFilterSectionProps> = ({
  customField,
}) => {
  assert(
    customField.type === "dropdown",
    "Only dropdown custom fields are supported"
  );

  const { filtersState, updateCustomFieldFilter } =
    useTaskFiltersStateContext();

  const { t } = useTranslations();

  const dropdownValuesAsListItems = useMemo<
    ListBoxItem<ExpectedTaskCustomDropdownFieldValue | null>[]
  >(
    () => [
      { id: WITHOUT_VALUE_ITEM_ID, value: null },
      ...(customField.values || []).map((value) => ({ id: value.id, value })),
    ],
    [customField.values]
  );

  const filterValues = filtersState.customFields[customField.id] || [];

  return (
    <FilterSectionLayout>
      <FilterSectionLayout.Header
        title={t(
          {
            id: "tasks-page.by-custom-dropdown-field-filter",
            defaultMessage: "By {fieldName}",
          },
          { fieldName: customField.name }
        )}
        endSlot={
          <ResourcePickerPopover
            placement="bottom-end"
            title={t(
              {
                id: "tasks-page.select-custom-dropdown-field",
                defaultMessage: "Select {fieldName}",
              },
              { fieldName: customField.name }
            )}
            items={dropdownValuesAsListItems}
            selectionBehavior={{ mode: "multiple" }}
            hasSearch
            defaultSelectedIds={filterValues.map((id) =>
              id === null ? WITHOUT_VALUE_ITEM_ID : id
            )}
            maxVisibleItems={5}
            itemSize="md"
            spaceBetweenItems="compact"
            renderLabel={(item) => {
              if (item.id === WITHOUT_VALUE_ITEM_ID) {
                return t({
                  id: "tasks-page.without-custom-field-value",
                  defaultMessage: "Without value",
                });
              }

              assert(!!item.value, "Item value cannot be null at this point");

              return item.value.value;
            }}
            className="w-[315px]"
            renderTrigger={({ Trigger, triggerRef }) => (
              <Trigger ref={triggerRef} as={FilterSectionLayout.AddButton} />
            )}
            onSubmit={(ids) =>
              updateCustomFieldFilter(
                customField,
                ids.map((id) => (id === WITHOUT_VALUE_ITEM_ID ? null : id))
              )
            }
          />
        }
      />
      {filterValues.length > 0 && (
        <FilterSectionLayout.PillsContainer>
          {filterValues.map((value) => (
            <CustomFieldFilterPill
              key={value}
              customField={customField}
              value={value}
              onRemove={() =>
                updateCustomFieldFilter(
                  customField,
                  filterValues.filter((v) => v !== value)
                )
              }
            />
          ))}
        </FilterSectionLayout.PillsContainer>
      )}
    </FilterSectionLayout>
  );
};
