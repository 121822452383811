import { InternalTaskFilters } from "@jugl-web/rest-api/tasks";

export const INITIAL_FILTERS_STATE: InternalTaskFilters = {
  assignees: [],
  customers: [],
  customFields: {},
  isOverdue: null,
  isRecurring: null,
  labels: [],
  priorities: [],
  statuses: [],
};
