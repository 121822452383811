import { Customer, useRestApiProvider } from "@jugl-web/rest-api";
import { SubscriptionPlanModuleId } from "@jugl-web/rest-api/entities/models/common-types/SubscriptionPlanModuleId";
import { Avatar } from "@jugl-web/ui-components/cross-platform/Avatar";
import { ListBoxProps } from "@jugl-web/ui-components/cross-platform/ListBox";
import {
  TaskPropertyButton,
  TaskPropertyButtonProps,
} from "@jugl-web/ui-components/cross-platform/tasks/TaskPropertyButton";
import { ResourcePickerDrawer } from "@jugl-web/ui-components/mobile/ResourcePickerDrawer";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { cx, useAppVariant, useTranslations } from "@jugl-web/utils";
import { FC, useMemo, useState } from "react";
import { useCustomers } from "../../../../../customers/hooks/useCustomers";
import { useCustomerListBox } from "../../../../../users/hooks/useCustomerListBox";
import { ReactComponent as CustomerIcon } from "../../assets/customer.svg";
import { FieldComponentProps } from "../../types";

interface CustomerFieldProps extends FieldComponentProps<"customer"> {
  entityId: string;
}

export const CustomerField: FC<CustomerFieldProps> = ({
  entityId,
  customerId,
  isReadonly,
  onChange,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { isMobile } = useAppVariant();
  const { t } = useTranslations();

  const { entitiesApi } = useRestApiProvider();

  const { data: subscriptionInfo } = entitiesApi.useGetSubscriptionInfoQuery({
    entityId,
  });

  // TODO: Add some util hook to check if given module is available
  const isClientModuleAvailable = useMemo(() => {
    if (!subscriptionInfo) {
      return false;
    }

    if (!subscriptionInfo.availableModules) {
      return true;
    }

    return subscriptionInfo.availableModules.some(
      (module) =>
        module.id === SubscriptionPlanModuleId.clients && module.available
    );
  }, [subscriptionInfo]);

  const isFieldVisible =
    isClientModuleAvailable && (!!customerId || !isReadonly);

  const { getCustomerDetailsById } = useCustomers({
    entityId,
    skipLoading: !isFieldVisible,
  });

  const { getListBoxProps, clearSearchQuery } = useCustomerListBox({
    entityId,
    skipLoading: !isFieldVisible,
  });

  const selectedCustomerDetails = useMemo(
    () => (customerId ? getCustomerDetailsById(customerId) : undefined),
    [customerId, getCustomerDetailsById]
  );

  const hasSelectedCustomer = !!selectedCustomerDetails;

  const commonButtonProps: TaskPropertyButtonProps = {
    isDisabled: isReadonly,
    startIcon: hasSelectedCustomer ? (
      <Avatar
        size="xs"
        imageUrl={selectedCustomerDetails.avatarUrl}
        username={selectedCustomerDetails.fullName}
      />
    ) : (
      <CustomerIcon />
    ),
    className: cx(
      "max-w-[250px]",
      hasSelectedCustomer && "bg-primary-50 text-primary-900"
    ),
    children: hasSelectedCustomer
      ? t(
          {
            id: "tasks-page.customer-with-name",
            defaultMessage: "Customer: {name}",
          },
          {
            name: selectedCustomerDetails.fullName,
          }
        )
      : t({
          id: "tasks-page.add-customer",
          defaultMessage: "Add customer",
        }),
  };

  const commonResourcePickerProps = {
    title: t({
      id: "tasks-page.select-customer",
      defaultMessage: "Select customer",
    }),
    ...getListBoxProps({
      defaultSelectedIds: customerId ? [customerId] : undefined,
    }),
  } satisfies ListBoxProps<Customer | null> & { title: string };

  if (!isFieldVisible) {
    return null;
  }

  if (isMobile) {
    return (
      <>
        <TaskPropertyButton
          onClick={() => setIsDialogOpen(true)}
          {...commonButtonProps}
          data-id="customer-field"
        />
        <ResourcePickerDrawer
          isOpen={isDialogOpen}
          onSubmit={(_, [item]) => {
            if (!item) {
              onChange?.(null);
            } else if (item.value) {
              onChange?.(item.value);
            }
          }}
          onClose={() => {
            setIsDialogOpen(false);
            clearSearchQuery();
          }}
          {...commonResourcePickerProps}
        />
      </>
    );
  }

  return (
    <ResourcePickerPopover
      placement="bottom-start"
      className="w-[375px]"
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          ref={triggerRef}
          as={TaskPropertyButton}
          {...commonButtonProps}
          data-id="customer-field"
        />
      )}
      onSelect={({ item, isSelected, onClose }) => {
        onChange?.(isSelected ? item.value : null);
        onClose();
      }}
      onUnmount={clearSearchQuery}
      {...commonResourcePickerProps}
    />
  );
};
