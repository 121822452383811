import { cx, useTranslations } from "@jugl-web/utils";
import React, { useMemo } from "react";
import { ChatItem, ChatType } from "@web-src/features/chats/types";
import {
  Avatar,
  InteractiveContainer,
  Pill,
  Popover,
} from "@jugl-web/ui-components/cross-platform";
import { useNotifications } from "@web-src/modules/notifications/providers/NotificationsProvider";
import { ReactComponent as OptionsVerticalIcon } from "./assets/options-vertical.svg";
import { ReactComponent as VolumeIcon } from "./assets/volume.svg";
import { ReactComponent as MentionIcon } from "./assets/mention.svg";
import { ReactComponent as MuteIcon } from "./assets/mute.svg";

export const MutedChatsList: React.FC<{
  chats: ChatItem[];
  header: string;
}> = ({ chats, header }) => {
  const { t } = useTranslations();

  const { getChatNotificationsPreferences, setChatNotificationsPreferences } =
    useNotifications();
  const mutedChatsItems = useMemo(
    () =>
      chats.map((chat) => ({
        chat,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        mute: getChatNotificationsPreferences(chat.id!),
      })),
    [chats, getChatNotificationsPreferences]
  );

  return (
    <>
      <div className="flex flex-col gap-1 bg-white">
        <div className="flex items-center justify-between pb-6 pl-6">
          <span className="text-[#828282]">{header}</span>
        </div>
        {mutedChatsItems.map(({ chat, mute }, idx) => (
          <div key={chat.id}>
            <div
              className={cx(
                "flex w-full items-center justify-between px-6",
                idx === 0 ? "pb-2" : "py-2"
              )}
            >
              <div className="flex w-full items-center gap-3 truncate">
                <Avatar
                  size="lg"
                  username={chat.title || ""}
                  imageUrl={chat.img}
                />
                <div className="flex w-full flex-col gap-1 truncate">
                  <span className="text-dark w-full truncate font-medium">
                    {chat.title}
                  </span>
                  <span className="text-xs text-[#828282]">
                    {mute === "all"
                      ? t({
                          id: "settings-page.muted",
                          defaultMessage: "Muted",
                        })
                      : t({
                          id: "settings-page.just-mentions",
                          defaultMessage: "Just @mentions",
                        })}
                  </span>
                </div>
              </div>
              <Popover
                placement="bottom"
                renderTrigger={({ Trigger, triggerRef }) => (
                  <Trigger
                    ref={triggerRef}
                    as={Pill}
                    size="md"
                    startIcon={<OptionsVerticalIcon />}
                    label=""
                    className="ml-4 cursor-pointer gap-0 bg-white p-0"
                  />
                )}
              >
                {({ onClose }) => (
                  <div className="flex flex-col">
                    {mute !== "none" && (
                      <InteractiveContainer
                        className="hover:bg-grey-100 flex items-center gap-2 p-4"
                        onClick={() => {
                          onClose();
                          if (chat.id) {
                            setChatNotificationsPreferences(
                              chat.id,
                              chat.type,
                              "none"
                            );
                          }
                        }}
                      >
                        <VolumeIcon />
                        <span className="text-sm text-[#4F4F4F]">
                          {t({
                            id: "settings-page.unmute",
                            defaultMessage: "Unmute",
                          })}
                        </span>
                      </InteractiveContainer>
                    )}
                    {mute !== "all_except_mentions" &&
                      chat.type === ChatType.muc && (
                        <InteractiveContainer
                          className="hover:bg-grey-100 flex items-center gap-2 p-4"
                          onClick={() => {
                            onClose();
                            if (chat.id) {
                              setChatNotificationsPreferences(
                                chat.id,
                                chat.type,
                                "all_except_mentions"
                              );
                            }
                          }}
                        >
                          <MentionIcon />
                          <span className="text-sm text-[#4F4F4F]">
                            {t({
                              id: "settings-page.notify",
                              defaultMessage: "Notify just @mentions",
                            })}
                          </span>
                        </InteractiveContainer>
                      )}
                    {mute !== "all" && (
                      <InteractiveContainer
                        className="hover:bg-grey-100 flex items-center gap-2 p-4"
                        onClick={() => {
                          onClose();
                          if (chat.id) {
                            setChatNotificationsPreferences(
                              chat.id,
                              chat.type,
                              "all"
                            );
                          }
                        }}
                      >
                        <MuteIcon />
                        <span className="text-sm text-[#4F4F4F]">
                          {t({
                            id: "settings-page.mute",
                            defaultMessage: "Mute",
                          })}
                        </span>
                      </InteractiveContainer>
                    )}
                  </div>
                )}
              </Popover>
            </div>
            {idx !== chats.length - 1 && (
              <div className="bg-grey-200 h-px w-full" />
            )}
          </div>
        ))}
      </div>
    </>
  );
};
