import { PlainButton } from "@jugl-web/ui-components/cross-platform";
import { cx } from "@jugl-web/utils";
import { FC } from "react";
import { ReactComponent as BellIcon } from "./assets/bell.svg";

export interface NotificationsButtonProps {
  hasUnreadIndicator: boolean;
  className?: string;
  onClick: () => void;
}

export const NotificationsButton: FC<NotificationsButtonProps> = ({
  hasUnreadIndicator,
  className,
  onClick,
}) => (
  <PlainButton
    className={cx(
      "jugl__focusable-outline h-10 w-10 rounded-lg transition-colors hover:bg-[#EEF2F5]",
      className
    )}
    onClick={onClick}
  >
    <span className="relative inline-block">
      <BellIcon />
      <div
        className={cx(
          "bg-gradients-danger absolute -right-0.5 -top-0.5 box-content h-2 w-2 rounded-full border-[1.6px] border-solid border-white transition",
          hasUnreadIndicator ? "scale-100 opacity-100" : "scale-0 opacity-0"
        )}
      />
    </span>
  </PlainButton>
);
