import React, { useState } from "react";
import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { NotificationCategorySettings } from "@web-src/modules/settings/pages/SettingsPage/types";
import { Switch } from "@jugl-web/ui-components/cross-platform/Switch";
import { ReactComponent as ChevronRightIcon } from "./assets/chevron-right.svg";
import { NotificationsSoundSidebar } from "./components/NotificationsSoundSidebar/NotificationsSoundSidebar";
import { getSoundName } from "./utils/getSoundName";

export const NotificationsPreferencesSection: React.FC<{
  isNotificationSoundActive: boolean;
  notificationSoundId: number;
  title?: string;
  onChange: (preferences: NotificationCategorySettings) => void;
}> = ({ title, isNotificationSoundActive, notificationSoundId, onChange }) => {
  const { t } = useTranslations();
  const [isNotificationsSoundSidebarOpen, setIsNotificationsSoundSidebarOpen] =
    useState(false);

  const onNotificationChange = (notification: {
    isNotificationSoundActive?: boolean;
    notificationSoundId?: number;
  }) =>
    onChange({
      sound:
        notification.isNotificationSoundActive ?? isNotificationSoundActive,
      sound_web: `sound-${
        notification.notificationSoundId ?? notificationSoundId
      }`,
    });

  return (
    <>
      <div className="flex flex-col gap-6 bg-white p-6">
        {title && <span className="text-[#828282]">{title}</span>}
        <InteractiveContainer
          onClick={() =>
            onNotificationChange({
              isNotificationSoundActive: !isNotificationSoundActive,
            })
          }
          className="flex items-center justify-between"
        >
          <div className="flex flex-col gap-1">
            <span className="text-dark">
              {t({
                id: "settings-page.sound-effects",
                defaultMessage: "Sound Effects",
              })}
            </span>
            <span className="text-xs text-[#828282]">
              {isNotificationSoundActive
                ? t({
                    id: "common.on",
                    defaultMessage: "On",
                  })
                : t({
                    id: "common.off",
                    defaultMessage: "Off",
                  })}
            </span>
          </div>
          <Switch isChecked={isNotificationSoundActive} />
        </InteractiveContainer>
        {isNotificationSoundActive && (
          <InteractiveContainer
            className="flex items-center justify-between"
            onClick={() => setIsNotificationsSoundSidebarOpen(true)}
          >
            <div className="flex flex-col gap-1">
              <span className="text-dark">
                {t({
                  id: "settings-page.sound",
                  defaultMessage: "Sound",
                })}
              </span>
              <span className="text-xs text-[#828282]">
                {t(getSoundName(notificationSoundId), {
                  notificationSoundId,
                })}
              </span>
            </div>
            <ChevronRightIcon />
          </InteractiveContainer>
        )}
      </div>
      <NotificationsSoundSidebar
        isOpen={isNotificationsSoundSidebarOpen}
        onRequestClose={() => setIsNotificationsSoundSidebarOpen(false)}
        notificationSoundId={notificationSoundId}
        onNotificationSoundIdChange={(soundId) =>
          onNotificationChange({
            notificationSoundId: soundId,
          })
        }
      />
    </>
  );
};
