import { cx, useSearchInput, useTranslations } from "@jugl-web/utils";
import {
  ForwardedRef,
  Fragment,
  ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useInView } from "react-intersection-observer";
import Lottie from "react-lottie";
import { SearchInput } from "../SearchInput";
import { ReactComponent as AddIcon } from "./assets/add.svg";
import animationData from "./assets/loading-animation.json";
import { Divider } from "./components/Divider";
import {
  ListBoxItemComponent,
  ListBoxItemComponentProps,
} from "./components/ListBoxItemComponent";

export interface ListBoxItem<TValue> {
  id: string;
  value: TValue;
}

export interface UnsafeListBoxItem<TValue> {
  id: string;
  value?: TValue;
}

export interface ListBoxHandle<TValue> {
  getSelectedIds: () => string[];
  getSelectedItems: () => UnsafeListBoxItem<TValue>[];
}

export type ListBoxSelectionBehavior =
  | { mode: "single"; canToggle: boolean }
  | { mode: "multiple" };

export type ListBoxLoading = "skeleton" | "bottom-spinner";

export type ListBoxItemSize = "sm" | "md" | "lg" | "xl";
export type ListBoxSpaceBetweenItems = "normal" | "compact";

export interface ListBoxProps<TValue> {
  items: ListBoxItem<TValue>[];
  selectionBehavior: ListBoxSelectionBehavior;
  loading?: ListBoxLoading;
  isCompact?: boolean;
  hasSearch?: boolean;
  hasSearchAutoFocus?: boolean;
  isHeightFixed?: boolean;
  shouldFilterOnSearch?: boolean;
  defaultSelectedIds?: string[];
  maxVisibleItems?: number;
  itemSize?: ListBoxItemSize;
  spaceBetweenItems?: ListBoxSpaceBetweenItems;
  addButton?: {
    label: string;
    startIcon?: ReactNode;
    onClick: () => void;
  };
  className?: string;
  renderLabel?: (item: ListBoxItem<TValue>, index: number) => ReactNode;
  renderStartIcon?: (item: ListBoxItem<TValue>, index: number) => ReactNode;
  renderEndIcon?: (item: ListBoxItem<TValue>, index: number) => ReactNode;
  renderSecondaryText?: (item: ListBoxItem<TValue>, index: number) => ReactNode;
  renderCustomItem?: (
    item: ListBoxItem<TValue>,
    index: number,
    props: ListBoxItemComponentProps
  ) => ReactNode;
  onSelectionChange?: (
    ids: string[],
    items: UnsafeListBoxItem<TValue>[]
  ) => void;
  onSelect?: (item: ListBoxItem<TValue>, isSelected: boolean) => void;
  onSearch?: (searchQuery: string) => void;
  onReachEnd?: () => void;
}

const itemSizeToHeight: Record<ListBoxItemSize, number> = {
  sm: 36,
  md: 40,
  lg: 44,
  xl: 47,
};

const spaceBetweenItemsToGap: Record<ListBoxSpaceBetweenItems, number> = {
  normal: 16,
  compact: 6,
};

const ListBoxInner = <TValue,>(
  {
    items,
    selectionBehavior,
    loading,
    isCompact = false,
    hasSearch = false,
    hasSearchAutoFocus = true,
    shouldFilterOnSearch = true,
    defaultSelectedIds = [],
    maxVisibleItems,
    itemSize,
    spaceBetweenItems,
    addButton,
    isHeightFixed,
    className,
    renderLabel,
    renderStartIcon,
    renderEndIcon,
    renderSecondaryText,
    renderCustomItem,
    onSelectionChange,
    onSelect,
    onSearch,
    onReachEnd,
  }: ListBoxProps<TValue>,
  ref: ForwardedRef<ListBoxHandle<TValue>>
) => {
  const [selectedItemsToValues, setSelectedItemsToValues] = useState<
    Record<string, TValue | undefined>
  >(() => Object.fromEntries(defaultSelectedIds.map((id) => [id, undefined])));

  const listRef = useRef<HTMLDivElement | null>(null);

  const { t } = useTranslations();

  const { ref: inViewRef } = useInView({
    root: listRef.current,
    onChange: (inView) => inView && onReachEnd?.(),
  });

  const { hasSearchQuery, searchQuery, reset, inputProps } = useSearchInput({
    onSearch,
  });

  const itemById = useMemo(
    () => new Map(items.map((item) => [item.id, item])),
    [items]
  );

  const transformIdsToItems = (ids: string[]): UnsafeListBoxItem<TValue>[] =>
    ids.map((id) => {
      // First, try to get the item directly from the items passed as a prop
      const item = itemById.get(id);

      if (item) {
        return item;
      }

      // If the item is not present anymore, try to get it from the selectedItemsToValues map,
      // in case it was selected and cached before
      const cachedItem = selectedItemsToValues[id];

      if (cachedItem) {
        return { id, value: cachedItem };
      }

      // If the item is still not found, return unsafe item with no value
      return { id };
    });

  useImperativeHandle(ref, () => ({
    getSelectedIds: () => Object.keys(selectedItemsToValues),
    getSelectedItems: () =>
      transformIdsToItems(Object.keys(selectedItemsToValues)),
  }));

  const visibleItems = useMemo(() => {
    if (!shouldFilterOnSearch || !hasSearchQuery) {
      return items;
    }

    return items.filter((item, index) => {
      const label = renderLabel?.(item, index);

      if (typeof label === "string") {
        return label.toLowerCase().includes(searchQuery.toLowerCase());
      }

      return true;
    });
  }, [shouldFilterOnSearch, hasSearchQuery, items, renderLabel, searchQuery]);

  const isLoading = !!loading;
  const isEmpty = visibleItems.length === 0;

  const handleSelect = (selectedItem: ListBoxItem<TValue>) => {
    let updatedSelectedItemsToValues: Record<string, TValue | undefined>;

    if (selectionBehavior.mode === "multiple") {
      if (selectedItem.id in selectedItemsToValues) {
        updatedSelectedItemsToValues = { ...selectedItemsToValues };
        delete updatedSelectedItemsToValues[selectedItem.id];
      } else {
        updatedSelectedItemsToValues = {
          ...selectedItemsToValues,
          [selectedItem.id]: selectedItem.value,
        };
      }
    } else if (selectionBehavior.canToggle) {
      updatedSelectedItemsToValues =
        selectedItem.id in selectedItemsToValues
          ? {}
          : { [selectedItem.id]: selectedItem.value };
    } else {
      updatedSelectedItemsToValues = { [selectedItem.id]: selectedItem.value };
    }

    setSelectedItemsToValues(updatedSelectedItemsToValues);

    onSelect?.(selectedItem, selectedItem.id in updatedSelectedItemsToValues);

    if (onSelectionChange) {
      const selectedIds = Object.keys(updatedSelectedItemsToValues);
      onSelectionChange(selectedIds, transformIdsToItems(selectedIds));
    }
  };

  const itemsGap = spaceBetweenItems
    ? spaceBetweenItemsToGap[spaceBetweenItems]
    : isCompact
    ? spaceBetweenItemsToGap.compact
    : spaceBetweenItemsToGap.normal;

  const itemHeight = itemSize
    ? itemSizeToHeight[itemSize]
    : itemSizeToHeight.md;

  const listHeight = maxVisibleItems
    ? maxVisibleItems * (itemHeight + itemsGap) - itemsGap
    : undefined;

  const listHeightStyles = isHeightFixed
    ? { height: listHeight }
    : { maxHeight: listHeight };

  // Sync selected items with these passed as a prop
  useEffect(() => {
    setSelectedItemsToValues((prevSelectedItemsToValues) => {
      const updatedSelectedItemsToValues: Record<string, TValue | undefined> =
        {};

      Object.keys(prevSelectedItemsToValues).forEach((id) => {
        const item = itemById.get(id);

        if (item) {
          updatedSelectedItemsToValues[id] = item.value;
        }
      });

      return { ...prevSelectedItemsToValues, ...updatedSelectedItemsToValues };
    });
  }, [itemById]);

  return (
    <div className={className}>
      {hasSearch && (
        <>
          <SearchInput
            variant="filled"
            color="grey"
            onClear={reset}
            autoFocus={hasSearchAutoFocus}
            {...inputProps}
          />
          <Divider className={cx(isCompact ? "mt-3 mb-2" : "my-4")} />
        </>
      )}
      <div
        ref={listRef}
        className="jugl__custom-scrollbar -mr-2 flex flex-col overflow-y-auto pr-2"
        style={{ gap: itemsGap, ...listHeightStyles }}
      >
        {addButton && (
          <button
            type="button"
            className="bg-grey-100 hover:bg-grey-200 flex shrink-0 cursor-pointer items-center justify-between rounded-lg border-none px-3 transition-colors"
            style={{ height: itemHeight }}
            onClick={addButton.onClick}
          >
            <div className="flex items-center gap-2.5">
              {addButton.startIcon}
              <span className="text-dark font-[Roboto] text-sm">
                {addButton.label}
              </span>
            </div>
            <AddIcon />
          </button>
        )}
        {isEmpty && !isLoading && (
          <div className="mt-2 text-center text-sm leading-[21px] text-[#4F4F4F]">
            {t({
              id: "list-box-component.no-results",
              defaultMessage: "No results 😔",
            })}
          </div>
        )}
        {loading === "skeleton" ? (
          <div
            className="flex animate-pulse flex-col"
            style={{ gap: itemsGap }}
          >
            {Array.from({ length: maxVisibleItems || 8 }).map(
              (_, index, array) => (
                <div
                  key={+index}
                  className="bg-grey-200 w-full rounded-lg"
                  style={{
                    height: itemHeight,
                    opacity: (1 / array.length) * (array.length - index),
                  }}
                />
              )
            )}
          </div>
        ) : (
          <>
            {visibleItems.map((item, index) => {
              const isSelected = item.id in selectedItemsToValues;

              const isNotInteractive =
                isSelected &&
                selectionBehavior.mode === "single" &&
                !selectionBehavior.canToggle;

              const itemProps: ListBoxItemComponentProps = {
                label: renderLabel?.(item, index),
                isSelected,
                isInteractive: !isNotInteractive,
                height: itemHeight,
                startSlot: renderStartIcon?.(item, index),
                endSlot: renderEndIcon?.(item, index),
                secondaryText: renderSecondaryText?.(item, index),
                highlightedText: searchQuery,
                onClick: () => handleSelect(item),
              };

              if (renderCustomItem) {
                return (
                  <Fragment key={item.id}>
                    {renderCustomItem(item, index, itemProps)}
                  </Fragment>
                );
              }

              return <ListBoxItemComponent key={item.id} {...itemProps} />;
            })}
          </>
        )}
      </div>
      <div ref={inViewRef}>
        {loading === "bottom-spinner" && (
          <Lottie
            style={{ marginTop: itemsGap }}
            options={{ animationData }}
            height={32}
            width={32}
          />
        )}
      </div>
    </div>
  );
};

export const ListBox = forwardRef(ListBoxInner) as <TValue>(
  props: ListBoxProps<TValue> & { ref?: ForwardedRef<ListBoxHandle<TValue>> }
) => ReturnType<typeof ListBoxInner>;
