import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { LocationEventsSummary } from "@jugl-web/rest-api/location/types";
import {
  ChecklistSummary,
  TaskChecklistSummaryItem,
} from "@jugl-web/rest-api/tasks";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { Text } from "@jugl-web/ui-components/cross-platform/Text";
import { Tooltip } from "@jugl-web/ui-components/web/";
import { useTranslations } from "@jugl-web/utils";
import { locationApi } from "@web-src/features/api/createApi";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { PageRoute } from "@web-src/features/app/types";
import cx from "classnames";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { addZ } from "@web-src/utils/addZ";
import { ReactComponent as AddCheckIcon } from "./assets/add-check.svg";
import { ReactComponent as ArrowRightIcon } from "./assets/arrow-right-2.svg";
import { ReactComponent as CloseIcon } from "./assets/close.svg";
import { ReactComponent as LocationPinIcon } from "./assets/location-pin.svg";
import { ReactComponent as TasksIcon } from "./assets/tasks.svg";
import { ReactComponent as TimerTotalIcon } from "./assets/timer-total.svg";

const DaySummaryCell: React.FC<{
  userData: LocationEventsSummary | undefined;
  checklistSummary: ChecklistSummary | undefined;
  date: string;
  userTimezone?: string;
  onOpenTaskListSidebar: (
    date: string,
    userId: string,
    tasks: { completed_on: string; task_id: string }[],
    checklistsSummaryData: TaskChecklistSummaryItem[]
  ) => void;
  dayDuration: string;
}> = ({
  userData,
  checklistSummary,
  date,
  userTimezone,
  onOpenTaskListSidebar,
  dayDuration,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const dayEvents = userData?.events
    .filter((element) =>
      format(
        utcToZonedTime(addZ(element.start_time), userTimezone || timeZone),
        "yyyy-MM-dd"
      ).includes(date)
    )
    .sort((a, b) => {
      const aStartTime = utcToZonedTime(
        addZ(a.start_time),
        userTimezone || timeZone
      );
      const bStartTime = utcToZonedTime(
        addZ(b.start_time),
        userTimezone || timeZone
      );
      return aStartTime.getTime() - bStartTime.getTime();
    });
  const taskCount = userData?.tasks.filter((element) =>
    format(
      utcToZonedTime(addZ(element?.completed_on), userTimezone || timeZone),
      "yyyy-MM-dd"
    ).includes(date)
  ).length;
  const checklists = checklistSummary?.checklists?.filter((element) =>
    format(
      utcToZonedTime(addZ(element?.completed_at), userTimezone || timeZone),
      "yyyy-MM-dd"
    ).includes(date)
  );
  const checklistsCount = checklists?.length || 0;
  const { entity } = useEntity();
  const { t } = useTranslations();
  const isZero =
    (!dayEvents && !taskCount) ||
    (dayEvents && dayEvents?.length === 0 && !taskCount);
  const [getLocationData, { data: lastLocationData }] =
    locationApi.useLazyGeocodeLookupQuery();

  useEffect(() => {
    if (!userData?.events || userData?.events?.length === 0 || !isPopoverOpen) {
      return;
    }
    const lastEvent = userData?.events.at(-1);
    const locPts = [
      {
        lat: (lastEvent && lastEvent.loc[0]?.loc_pts.lat) || 0,
        lng: (lastEvent && lastEvent.loc[0]?.loc_pts.lon) || 0,
      },
    ];
    getLocationData({ cordinates: locPts });
  }, [getLocationData, isPopoverOpen, userData?.events]);

  return (
    <Popover
      className={cx(
        "border-grey-200 relative flex h-full w-full flex-col items-center justify-center gap-6 border-[2px] border-b-0 border-t-0 border-r-0 border-solid px-8 py-4",
        {
          "hover:bg-secondary-50": !isZero,
        }
      )}
      key={date}
    >
      {!isZero && (
        <Tooltip
          renderTrigger={({ ref, props }) => (
            <PopoverButton
              ref={ref}
              className="absolute top-0 bottom-0 right-0 left-0 z-10 cursor-pointer border-0 bg-transparent"
              {...props}
            />
          )}
        >
          {t({
            id: "location-page.tap-to-see-more",
            defaultMessage: "Tap to see more",
          })}
        </Tooltip>
      )}
      <div className="flex flex-row items-center">
        <Text variant="body1" className="text-dark">
          {dayDuration}
        </Text>
      </div>
      <div className="flex flex-row items-center">
        <Text variant="body2" className="text-grey whitespace-nowrap">
          {!taskCount
            ? "-"
            : `${taskCount} ${t({
                id: "common.tasks",
                defaultMessage: "Tasks",
              })}`}
        </Text>
      </div>
      <PopoverPanel className="absolute z-40 h-[303px] w-[448px] rounded-md bg-white drop-shadow-lg">
        {({ open, close }) => {
          if (open) {
            setIsPopoverOpen(true);
          }
          if (!open) {
            setIsPopoverOpen(false);
          }
          return (
            <div className="relative">
              <CloseIcon
                className="fill-grey-400 absolute top-4 right-4 cursor-pointer"
                onClick={() => close()}
              />
              <div className="flex items-center gap-2 pt-6 pl-4">
                <TimerTotalIcon />
                <Text variant="body1">
                  {t({
                    id: "location-page.daily-total",
                    defaultMessage: "Daily total",
                  })}
                  :
                </Text>
                <Text variant="body1" className="font-weight-700">
                  {dayDuration}
                </Text>
              </div>
              <div className="w-full p-6">
                <hr className="bg-dark-100 h-px border-0" />
              </div>
              <div className="flex flex-col px-6">
                <div className="flex flex-row items-center justify-between">
                  <div className="flex flex-col gap-2">
                    <span className="flex items-center gap-2">
                      <TasksIcon />
                      <Text variant="body1">
                        {t({
                          id: "day-summary-cell.task-done",
                          defaultMessage: "Tasks Done",
                        })}
                        : <b>{taskCount || 0}</b>
                      </Text>
                    </span>
                    <span className="flex items-center gap-2">
                      <AddCheckIcon />
                      <Text variant="body1">
                        {t({
                          id: "day-summary-cell.checklists-done",
                          defaultMessage: "Checklists Done",
                        })}
                        : <b>{checklistsCount || 0}</b>
                      </Text>
                    </span>
                  </div>
                  <span>
                    <Button
                      size="small"
                      onClick={() =>
                        onOpenTaskListSidebar(
                          date,
                          userData?.user_id || "",
                          userData?.tasks || [],
                          checklists || []
                        )
                      }
                      isDisabled={!taskCount && !checklistsCount}
                    >
                      {t({
                        id: "location-page.see-all-tasks",
                        defaultMessage: "See All Tasks",
                      })}{" "}
                      <ArrowRightIcon />
                    </Button>
                  </span>
                </div>
                <div className="w-full p-2 py-6">
                  <hr className="bg-dark-100 h-px border-0" />
                </div>
                <div className="flex flex-row items-center justify-between">
                  <Text
                    variant="body1"
                    className="flex w-[238px] items-center gap-2 overflow-hidden text-ellipsis whitespace-nowrap"
                  >
                    <LocationPinIcon className="mr-1" />{" "}
                    {(lastLocationData &&
                      lastLocationData[0]?.formatted_address) ||
                      t({
                        id: "common.location",
                        defaultMessage: "location",
                      })}
                  </Text>
                  <Link
                    to={{
                      pathname: `/${entity?.id}/${PageRoute.location}/${userData?.user_id}/${date}`,
                    }}
                    className="appearance-none no-underline outline-none"
                  >
                    <Button size="small">
                      {t({
                        id: "location-page.see-timeline",
                        defaultMessage: "See Timeline",
                      })}
                      <ArrowRightIcon />
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          );
        }}
      </PopoverPanel>
    </Popover>
  );
};

export default DaySummaryCell;
