import Chat from "@web-src/features/chats/components/Chat";
import ChatList from "@web-src/features/chats/components/ChatList";
import React from "react";

const ChatsPage: React.FC = () => (
  <div className="flex h-[100%] w-full min-w-0 flex-row">
    <ChatList />
    <Chat />
  </div>
);

export default ChatsPage;
