import { useCallback } from "react";
import { useToast, useTranslations } from "@jugl-web/utils";

export const useProfileUpdateToast = () => {
  const { toast } = useToast({ variant: "web" });
  const { t } = useTranslations();

  const showProfileUpdatedSnackbar = useCallback(
    () =>
      toast(
        t({
          id: "feedback.profile-updated-successfully",
          defaultMessage: "Profile updated successfully",
        })
      ),
    [toast, t]
  );

  return {
    showProfileUpdatedSnackbar,
  };
};
