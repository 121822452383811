import { UserEntityStatus, useRestApiProvider } from "@jugl-web/rest-api";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export type HeadlessUserItem = {
  id: string;
  managers?: string[];
  entityRelId?: string | null;
  space?: { id: string; title: string };
};

const DEFAULT_PAGE_SIZE = 20;

export type HeadlessUsersListHookParams = {
  entityId: string;
  workspaceId?: string;

  page?: number;
  pageSize?: number;

  searchQuery?: string;
  departments?: string;

  excludeUsers?: string;
  excludeWorkspaces?: string;
  includeWithoutProfile?: boolean;
  onlyReportees?: boolean;

  preferCache?: boolean;
  sortBy?: "name";
  skipInitialLoading?: boolean;
};

export const useHeadlessUsersList = ({
  entityId,
  workspaceId,
  searchQuery,
  page: paramsPage,
  pageSize = DEFAULT_PAGE_SIZE,
  excludeUsers,
  excludeWorkspaces,
  departments,
  preferCache = true,
  sortBy,
  includeWithoutProfile,
  onlyReportees,
  skipInitialLoading = false,
}: HeadlessUsersListHookParams) => {
  const { usersApi, workspacesApi } = useRestApiProvider();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [fetchUsers] = usersApi.useLazyGetUsersQuery();
  const [fetchWorkspaceParticipants] =
    workspacesApi.useLazyGetWorkspaceParticipantsQuery();

  const [mainUsersListState, setMainUsersListState] = useState<{
    list: HeadlessUserItem[];
    currentPage: number;
    pageCount: number;
    isSearch?: boolean;
  }>();

  const $currentRequest = useRef<{ abort: () => void } | null>(null);

  const loadUsers = useCallback(
    async (page?: number) => {
      setIsLoading(true);
      setIsError(false);
      // there can be only on request at a time
      $currentRequest.current?.abort();
      try {
        if (workspaceId) {
          const request = fetchWorkspaceParticipants(
            {
              entityId,
              workspaceId,
              params: {
                page: paramsPage || page,
                page_size: pageSize,
                search: searchQuery ? `%${searchQuery}%` : undefined,
              },
            },
            preferCache
          );
          $currentRequest.current = request;

          const response = await request;

          if (response.status === "fulfilled") {
            const responseData = response.data;
            setMainUsersListState((prev) => ({
              list: [
                ...(page ? prev?.list || [] : []),
                ...responseData.data.map((item) => ({
                  id: item.user_id,
                  space: item.spaces?.[0]
                    ? {
                        id: item.spaces[0].id,
                        title: item.spaces[0].info.title,
                      }
                    : undefined,
                })),
              ],
              currentPage: responseData.page_number,
              pageCount: responseData.total_pages,
              isSearch: !!searchQuery,
            }));
          } else if (
            response.status === "rejected" &&
            (response.error as { name: string })?.name !== "AbortError"
          ) {
            throw new Error();
          }
          return null;
        }

        const request = fetchUsers(
          {
            entityId,
            params: {
              page: paramsPage || page,
              page_size: pageSize,
              status: UserEntityStatus.active,
              exclude: excludeUsers,
              search: searchQuery ? `%${searchQuery}%` : undefined,
              spaces: departments || undefined,
              exclude_workspace_id: excludeWorkspaces || undefined,
              sort_by: sortBy,
              only_valid_profile: includeWithoutProfile ? undefined : true,
              only_reportees: onlyReportees ? true : undefined,
            },
          },
          preferCache
        );

        $currentRequest.current = request;

        const response = await request;

        if (response.status === "fulfilled") {
          const responseData = response.data;
          setMainUsersListState((prev) => ({
            list: [
              ...(page ? prev?.list || [] : []),
              ...responseData.data.map((item) => ({
                id: item.id,
                username: item.username || "unknown",
                entityRelId: item.entity_rel_id,
                space: item.spaces?.[0]
                  ? {
                      id: item.spaces[0].id,
                      title: item.spaces[0].info.title,
                    }
                  : undefined,
                managers: item.managers,
              })),
            ],
            currentPage: responseData.page_number,
            pageCount: responseData.total_pages,
            isSearch: !!searchQuery,
          }));
        } else if (
          response.status === "rejected" &&
          (response.error as { name: string })?.name !== "AbortError"
        ) {
          throw new Error();
        }
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }

      return null;
    },
    [
      workspaceId,
      fetchUsers,
      entityId,
      paramsPage,
      pageSize,
      excludeUsers,
      searchQuery,
      departments,
      excludeWorkspaces,
      sortBy,
      includeWithoutProfile,
      onlyReportees,
      preferCache,
      fetchWorkspaceParticipants,
    ]
  );

  useEffect(() => {
    if (skipInitialLoading) {
      return;
    }
    loadUsers();
  }, [loadUsers, skipInitialLoading]);

  const users: HeadlessUserItem[] = useMemo(
    () => mainUsersListState?.list || [],
    [mainUsersListState]
  );

  const loadMore = useCallback(() => {
    if (
      !mainUsersListState ||
      mainUsersListState.currentPage >= mainUsersListState.pageCount ||
      paramsPage !== undefined
    ) {
      return;
    }
    loadUsers(mainUsersListState.currentPage + 1);
  }, [loadUsers, mainUsersListState, paramsPage]);

  const retry = useCallback(() => {
    if (
      !mainUsersListState ||
      mainUsersListState.currentPage >= mainUsersListState.pageCount ||
      paramsPage !== undefined
    ) {
      loadUsers();
      return;
    }
    loadUsers(mainUsersListState.currentPage + 1);
  }, [loadUsers, mainUsersListState, paramsPage]);

  return {
    loadMore,
    refetch: loadUsers,
    retry,
    isLoading,
    isError,
    users,
    listState: mainUsersListState,
    reachedEnd:
      mainUsersListState &&
      mainUsersListState.currentPage >= mainUsersListState.pageCount,
  };
};
