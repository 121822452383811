import React from "react";
import { cx } from "@jugl-web/utils";

export const MainContainer: React.FC<{
  usersCount: number;
  isScreenSharing: boolean;
  isScreenSharingFullScreen: boolean;
  children: React.ReactNode;
}> = ({ usersCount, isScreenSharing, children, isScreenSharingFullScreen }) => (
  <div
    className={cx(
      "relative z-20",
      !isScreenSharing && {
        "max-hsm:py-[4vh] px-[10%] pt-[10vh] pb-[164px]":
          usersCount === 2 || usersCount === 3,
        "max-hsm:py-[2vh] px-[10%] pt-[4vh] pb-12  2xl:px-[8%]":
          usersCount === 1 ||
          usersCount === 4 ||
          usersCount === 5 ||
          usersCount === 6,
        "max-hsm:py-[2vh] px-[10%] pt-[4vh] pb-12 2xl:px-[4%]": usersCount > 6,
      },
      {
        "max-hsm:py-4 max-hsm:gap-2 max-hsm:scale-95 grid gap-8 px-[4%] pb-12 pt-12":
          isScreenSharing,
        "h-[100vh] grid-cols-[100%_auto] p-0":
          isScreenSharing && isScreenSharingFullScreen,
        "grid-cols-[80%_auto]": isScreenSharing && !isScreenSharingFullScreen,
      }
    )}
  >
    {children}
  </div>
);
