import { useTaskPriorities } from "@jugl-web/domain-resources/tasks/hooks/useTaskPriorities";
import { InternalTaskFilters } from "@jugl-web/rest-api/tasks";
import { FC } from "react";
import { FilterPill } from "../FilterPill";

interface PriorityFilterPillProps {
  priority: InternalTaskFilters["priorities"][number];
  onRemove: () => void;
}

export const PriorityFilterPill: FC<PriorityFilterPillProps> = ({
  priority,
  onRemove,
}) => {
  const { getPriorityDetailsById } = useTaskPriorities();

  const matchingPriorityDetails = getPriorityDetailsById(priority);

  return (
    <FilterPill
      label={matchingPriorityDetails.shortLabel}
      startIcon={<matchingPriorityDetails.Icon />}
      onRemove={onRemove}
    />
  );
};
