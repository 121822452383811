import React, { useContext, useMemo } from "react";
import { CallsContext } from "../../providers";
import { Conference } from "./components/Conference/Conference";
import IndividualCallScreen from "./components/IndividualCallScreen";

const ActiveCallWrapper: React.FC = () => {
  const { activeCallProps, callStage } = useContext(CallsContext);

  const isCall = useMemo(
    () => activeCallProps?.type === "call",
    [activeCallProps]
  );
  if (isCall || callStage !== "off") {
    return <IndividualCallScreen />;
  }
  return <Conference />;
};

export default ActiveCallWrapper;
