import React, { memo } from "react";

const ChatDateHeader: React.FC<{ date?: string }> = ({ date }) =>
  date ? (
    <div className="flex w-full justify-center p-2">
      <div className="text-dark-1000 flex h-[22px] items-center justify-center rounded-[11px] bg-gray-200 px-2 py-1 text-xs font-[500]">
        {date}
      </div>
    </div>
  ) : (
    <div className="h-[1px]" />
  );

export default memo(ChatDateHeader);
