import React, { useCallback, useMemo, useRef, ChangeEvent } from "react";
import { Text } from "@jugl-web/ui-components/cross-platform/Text";
import {
  endOfISOWeek,
  addWeeks,
  subWeeks,
  format,
  startOfISOWeek,
} from "date-fns";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { ReactComponent as CalendarIcon2 } from "./assets/calendar2.svg";
import { ReactComponent as ArrowLeft } from "./assets/arrow-left.svg";
import { ReactComponent as ArrowRight } from "./assets/arrow-right.svg";

const DatePicker: React.FC<{
  currentDate: Date;
  setCurrentDate: React.Dispatch<React.SetStateAction<Date>>;
}> = ({ currentDate, setCurrentDate }) => {
  const { dateLocale } = useLanguage();
  const datepicker = useRef<HTMLInputElement>(null);
  const handlePrevWeek = useCallback(() => {
    setCurrentDate((prevDate: Date) => subWeeks(prevDate, 1));
  }, [setCurrentDate]);
  const handleNextWeek = useCallback(() => {
    setCurrentDate((prevDate: Date) => addWeeks(prevDate, 1));
  }, [setCurrentDate]);
  const weekText = useMemo(
    () =>
      `${format(currentDate, "MMMM d - ", { locale: dateLocale })} ${format(
        endOfISOWeek(currentDate),
        "d, y",
        {
          locale: dateLocale,
        }
      )}`,
    [currentDate, dateLocale]
  );

  const handlePickerToggle = () => {
    datepicker?.current?.showPicker();
  };
  const handlePickerChange = (e: ChangeEvent<HTMLInputElement>) => {
    const date = startOfISOWeek(new Date(e.target.value));
    setCurrentDate(date);
  };
  const today = format(new Date(), "yyyy-MM-dd");
  return (
    <div className="flex flex-row items-center justify-center gap-4">
      <div className="relative cursor-pointer">
        <input
          className="invisible absolute top-0 bottom-0 left-0 right-0 z-20 appearance-none"
          type="date"
          onChange={handlePickerChange}
          value={currentDate?.toString()}
          ref={datepicker}
          max={today}
        />
        <CalendarIcon2 onClick={handlePickerToggle} />
      </div>
      <ArrowLeft onClick={handlePrevWeek} className="cursor-pointer" />
      <span onClick={handlePickerToggle} className="cursor-pointer">
        <Text variant="body1">{weekText}</Text>
      </span>
      <ArrowRight onClick={handleNextWeek} className="cursor-pointer" />
    </div>
  );
};

export default DatePicker;
