import { skipToken } from "@reduxjs/toolkit/dist/query";
import useEntity from "@web-src/features/app/hooks/useEntity";
import {
  useGetPreferencesQuery,
  useLazyGetPreferencesQuery,
  useUpdatePreferencesMutation,
} from "@web-src/modules/settings/pages/SettingsPage/settingsApi";
import { UserEntityPreferences } from "@web-src/modules/settings/pages/SettingsPage/types";
import { useCallback } from "react";
import deepmerge from "deepmerge";
import { AxiosResponse } from "axios";
import { SerializedError } from "@reduxjs/toolkit";

type UserEntityPreferencesHookValue = {
  isLoading: boolean;
  isError: boolean;
  preferences?: UserEntityPreferences;
  // TODO: move return type to some utils
  updatePreferences: (newPreferences: UserEntityPreferences) => Promise<
    | {
        data: void;
      }
    | {
        error:
          | AxiosResponse<unknown>
          | {
              __NETWORK_ERROR: boolean;
            }
          | SerializedError;
      }
  >;
};

export const useUserEntityPreferences = (): UserEntityPreferencesHookValue => {
  const { entity } = useEntity();

  const {
    data: preferences,
    isError,
    isLoading,
  } = useGetPreferencesQuery(entity?.id ? { entityId: entity.id } : skipToken);

  const [getUserEntityPreferences] = useLazyGetPreferencesQuery();
  const [updateUserEntityPreferences] = useUpdatePreferencesMutation();

  const updatePreferences: UserEntityPreferencesHookValue["updatePreferences"] =
    useCallback(
      async (newPreferences) => {
        if (!entity?.id) {
          throw new Error("No entity selected");
        }
        const mostRecentEntityPreferences = await getUserEntityPreferences(
          {
            entityId: entity.id,
          },
          false
        );
        if (
          "error" in mostRecentEntityPreferences &&
          !!mostRecentEntityPreferences.error
        ) {
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw mostRecentEntityPreferences.error;
        }

        const result = await updateUserEntityPreferences({
          entityId: entity.id,
          data: deepmerge(
            mostRecentEntityPreferences.data || {},
            newPreferences
          ),
        });
        if ("error" in result && !!result.error) {
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw result.error;
        }
        return result;
      },
      [entity?.id, getUserEntityPreferences, updateUserEntityPreferences]
    );

  return { preferences, updatePreferences, isError, isLoading };
};
