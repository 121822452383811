import { ListBoxItem } from "@jugl-web/ui-components/cross-platform";
import { PopoverProps } from "@jugl-web/ui-components/cross-platform/Popover";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { useTranslations } from "@jugl-web/utils";
import {
  TaskListLayout,
  useTasksPageContext,
} from "@web-src/features/tasks/TasksPageContext";
import { FC, useMemo } from "react";
import { ReactComponent as BetaIcon } from "./assets/beta.svg";
import { ReactComponent as CalendarIcon } from "./assets/calendar.svg";
import { ReactComponent as KanbanIcon } from "./assets/kanban.svg";
import { ReactComponent as TableIcon } from "./assets/table.svg";

export const TaskLayoutsPopover: FC<Pick<PopoverProps, "renderTrigger">> = ({
  renderTrigger,
}) => {
  const { taskListLayout, setTaskListLayout } = useTasksPageContext();

  const { t } = useTranslations();

  const layoutsAsListItems = useMemo<
    ListBoxItem<{
      layout: TaskListLayout;
      label: string;
      startIcon: JSX.Element;
      endSlot?: JSX.Element;
    }>[]
  >(
    () => [
      {
        id: "kanban",
        value: {
          layout: "kanban",
          label: t({
            id: "tasks-page.kanban",
            defaultMessage: "Board",
          }),
          startIcon: <KanbanIcon className="fill-grey" />,
        },
      },
      {
        id: "table",
        value: {
          layout: "table",
          label: t({
            id: "tasks-page.table",
            defaultMessage: "Table",
          }),
          startIcon: <TableIcon className="fill-grey" />,
          endSlot: <BetaIcon />,
        },
      },
      {
        id: "calendar",
        value: {
          layout: "calendar",
          label: t({
            id: "tasks-page.calendar",
            defaultMessage: "Calendar",
          }),
          startIcon: <CalendarIcon />,
        },
      },
    ],
    [t]
  );

  return (
    <ResourcePickerPopover
      placement="bottom"
      items={layoutsAsListItems}
      selectionBehavior={{ mode: "single", canToggle: false }}
      defaultSelectedIds={[taskListLayout]}
      itemSize="lg"
      spaceBetweenItems="compact"
      renderTrigger={renderTrigger}
      renderStartIcon={(item) => item.value.startIcon}
      renderLabel={(item) => (
        <div className="flex items-center gap-2">
          <span>{item.value.label}</span>
          {item.value.endSlot}
        </div>
      )}
      className="w-[200px]"
      onSelect={({ item, onClose }) => {
        setTaskListLayout(item.value.layout);
        onClose();
      }}
    />
  );
};
