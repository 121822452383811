import { Tooltip } from "@jugl-web/ui-components/web";
import { cx, useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import { ReactComponent as PersonalViewIcon } from "./assets/personal-view.svg";
import { ReactComponent as TeamViewIcon } from "./assets/team-view.svg";

export interface TaskListModeButtonProps {
  isTeamTasks: boolean;
  className?: string;
  onChange: (isTeamTasks: boolean) => void;
}

export const TaskListModeButton: FC<TaskListModeButtonProps> = ({
  isTeamTasks,
  className,
  onChange,
}) => {
  const { t } = useTranslations();

  return (
    <Tooltip
      renderTrigger={({ props, ref }) => (
        <button
          ref={ref}
          type="button"
          className={cx(
            "jugl__focusable-outline flex h-8 cursor-pointer items-center gap-1 rounded-3xl border-none px-2.5 transition-colors",
            isTeamTasks
              ? "bg-[#2D9CDB] hover:bg-[#2A97D5]"
              : "bg-secondary hover:bg-[#07B261]",
            className
          )}
          onClick={() => onChange(!isTeamTasks)}
          {...props}
        >
          {isTeamTasks ? <TeamViewIcon /> : <PersonalViewIcon />}
          <span className="text-sm text-white">
            {isTeamTasks
              ? t({
                  id: "tasks-page.team-tasks",
                  defaultMessage: "Team Tasks",
                })
              : t({
                  id: "tasks-page.my-tasks",
                  defaultMessage: "My Tasks",
                })}
          </span>
        </button>
      )}
    >
      {isTeamTasks
        ? t({
            id: "tasks-page.switch-to-my-tasks",
            defaultMessage: "Switch to My Tasks",
          })
        : t({
            id: "tasks-page.switch-to-team-tasks",
            defaultMessage: "Switch to Team Tasks",
          })}
    </Tooltip>
  );
};
