import { User } from "./User";
import { UserDto } from "./UserDto";

export const transformUserDtoToUser = (userDto: UserDto): User => ({
  emails: userDto.emails || [],
  entity_id: userDto.entity_id || "",
  id: userDto.user?.id || "",
  entity_rel_id: userDto.entity_rel_id,
  mobiles: userDto.mobiles || [],
  profile: userDto.user,
  role: userDto.role,
  spaces: userDto.dept ? [userDto.dept] : [],
  status: userDto.entity_user_status,
  updated_at: userDto.updated_at,
  username: userDto.entity_rel_username,
  timezone: userDto.timezone,
  rate: userDto.rate_per_hr,
  display_name:
    [userDto.user?.first_name, userDto.user?.last_name]
      .filter((item) => !!item)
      .join(" ") || userDto.username,
  managers: userDto.managers || [],
});
