import React, { useState, useContext, useMemo } from "react";
import { PlainButton, Text } from "@jugl-web/ui-components/cross-platform";
import { useTranslations, cx } from "@jugl-web/utils";
import { ReactComponent as ParticipantsIcon } from "./assets/participants.svg";
import { ParticipantsSidebar } from "../ParticipantsSidebar/ParticipantsSidebar";
import { CallsContext } from "../../../../../../providers";

export const Info: React.FC<{
  formattedDuration: string;
  conferenceName: string;
  isPresentingScreen?: boolean;
}> = ({ formattedDuration, conferenceName, isPresentingScreen }) => {
  const { activeCall } = useContext(CallsContext);
  const { t } = useTranslations();
  const [isParticipantsSidebarOpen, setIsParticipantsSidebarOpen] =
    useState<boolean>(false);
  const usersCount = useMemo(
    () =>
      (activeCall?.remoteUsers?.length && activeCall.remoteUsers.length + 1) ||
      1,
    [activeCall?.remoteUsers]
  );
  return (
    <span className="flex flex-col justify-start">
      <ParticipantsSidebar
        toggleSelf={() =>
          setIsParticipantsSidebarOpen(!isParticipantsSidebarOpen)
        }
        isOpen={isParticipantsSidebarOpen}
        onClose={() => setIsParticipantsSidebarOpen(false)}
      />
      <Text
        variant="body3"
        className={cx("text-dark-200", { hidden: !isPresentingScreen })}
      >
        {t({
          id: "calls-conference.you-presenting-screen",
          defaultMessage: "You Presenting Screen",
        })}
      </Text>
      <Text variant="body2" className="font-bold text-white">
        {conferenceName}
      </Text>
      <span className="flex flex-row items-center gap-2">
        <ParticipantsIcon
          className="cursor-pointer"
          onClick={() => setIsParticipantsSidebarOpen(true)}
        />
        <PlainButton onClick={() => setIsParticipantsSidebarOpen(true)}>
          <Text variant="body3" className="text-dark-200 cursor-pointer">
            {usersCount}{" "}
            {usersCount === 1
              ? t({
                  id: "audio-call-component-participant",
                  defaultMessage: "Participant",
                })
              : t({
                  id: "audio-call-component-participants",
                  defaultMessage: "Participants",
                })}
          </Text>
        </PlainButton>
        <Text variant="body3" className="text-primary-200">
          {formattedDuration}
        </Text>
      </span>
    </span>
  );
};
