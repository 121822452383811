import { HookOutOfContextError } from "@jugl-web/utils";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { UserRole, useRestApiProvider } from "@jugl-web/rest-api";
import { GlobalUserPreferences } from "@jugl-web/rest-api/users/models/common-types/GlobalUserPreferences";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { useEntityProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useLocation } from "react-router-dom";
import { entityPagesNavigationConfig } from "@web-src/modules/entities/pages/navigation-config";
import {
  GetStartedDialog,
  GetStartedDialogHandle,
} from "../components/GetStartedDialog";
import { OnboardingBeginningDialog } from "../components/OnboardingBeginningDialog/OnboardingBeginningDialog";
import { GetStartedDialogStep } from "../types";

interface OnboardingProviderProps {
  children: React.ReactNode;
}

type OnboardingStatusSteps = Omit<
  GlobalUserPreferences,
  "welcome" | "onBoardingFinish"
>;

const OnboardingContext = createContext<{
  onboardingStatus?: OnboardingStatusSteps;
  isOnboardingFinished: boolean;
  isOnboardingActive: boolean;
  openedSpotlight?: GetStartedDialogStep;
  openSpotlight: (type?: GetStartedDialogStep) => void;
  openDialog: (type: GetStartedDialogStep) => void;
  completeOnboardingStep: (type: GetStartedDialogStep) => void;
} | null>(null);

export const OnboardingProvider = ({ children }: OnboardingProviderProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isOnboardingBeginningDialogOpen, setIsOnboardingBeginningDialogOpen] =
    useState(false);
  const location = useLocation();
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const dialogRef = useRef<GetStartedDialogHandle>(null);
  const { entity, subscriptionInfo, isVerifyBillingAlertVisible } =
    useEntityProvider();
  const { me } = useMe();
  const isAdmin = me?.role === UserRole.admin;
  const hasActiveSubscription =
    !!subscriptionInfo && subscriptionInfo.planIsActive;
  const [openedSpotlight, setOpenedSpotlight] =
    useState<GetStartedDialogStep>();

  const { usersApi } = useRestApiProvider();
  const [updateGlobalPreferences] =
    usersApi.useUpdateGlobalPreferencesMutation();
  const { data, error, refetch } = usersApi.useGetGlobalPreferencesQuery(
    undefined,
    { skip: !entity?.id }
  );

  const isDuringEmailVerification = location.pathname.includes(
    entityPagesNavigationConfig.entityVerifyEmail.path
  );

  useEffect(() => {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    if ((error as any)?.status === 404) {
      updateGlobalPreferences({
        data: {
          onboarding_steps: {},
        },
      }).then(() => {
        refetch();
      });
    }
  }, [error, updateGlobalPreferences, refetch]);

  useEffect(() => {
    // Opens a welcome modal if the user is in an entity with a subscription,
    // has not started or completed the onboarding process,
    // and email verification is not performed.
    if (
      data &&
      entity?.id &&
      hasActiveSubscription &&
      !data.welcome &&
      !data.onBoardingFinish &&
      !isDuringEmailVerification &&
      !isVerifyBillingAlertVisible
    ) {
      setIsOnboardingBeginningDialogOpen(true);
    }
  }, [
    data,
    entity,
    hasActiveSubscription,
    isVerifyBillingAlertVisible,
    isDuringEmailVerification,
  ]);

  const isOnboardingActive = false;
  // TODO: turned off temporarily
  // useMemo(() => {
  //   if (data?.welcome && !data.onBoardingFinish) {
  //     return true;
  //   }
  //   return false;
  // }, [data]);

  const isOnboardingFinished = true;
  // TODO: turned off temporarily
  // useMemo(() => !!data?.onBoardingFinish, [data]);

  const getOnboardingStep = useCallback(
    (key: GetStartedDialogStep) => {
      const onboardingStepsMap: Record<GetStartedDialogStep, number> = {
        people: 0,
        profile: 1,
        task: 2,
        drive: 3,
        chat: 4,
      };

      const step = onboardingStepsMap[key];
      return isAdmin ? step : step - 1;
    },
    [isAdmin]
  );

  const openSpotlight = useCallback((type?: GetStartedDialogStep) => {
    setOpenedSpotlight(type);
  }, []);

  const completeOnboardingStep = useCallback(
    async (key: GetStartedDialogStep) => {
      if (!data || data.onBoardingFinish || data[key]) return;
      const status: Partial<GlobalUserPreferences> = {};
      status[key] = true;
      const response = await updateGlobalPreferences({
        data: {
          onboarding_steps: {
            ...data,
            ...status,
          },
        },
      });
      if (response && "data" in response) {
        setIsDialogOpen(true);
        dialogRef.current?.markStepAsCompleted(getOnboardingStep(key));
      }
    },
    [data, updateGlobalPreferences, getOnboardingStep]
  );

  const setStepIndex = useCallback((stepIndex: number) => {
    setActiveStepIndex(stepIndex);
  }, []);

  const openDialog = useCallback(
    (key: GetStartedDialogStep) => {
      setActiveStepIndex(getOnboardingStep(key));
      setIsDialogOpen(true);
    },
    [getOnboardingStep]
  );

  const closeOnboardingBeginningDialog = useCallback(async () => {
    setIsOnboardingBeginningDialogOpen(false);
    const response = await updateGlobalPreferences({
      data: {
        onboarding_steps: {
          ...data,
          welcome: true,
        },
      },
    });
    if (response && "data" in response) {
      setActiveStepIndex(0);
      setIsDialogOpen(true);
    }
  }, [updateGlobalPreferences, data]);

  const finishOnboarding = useCallback(() => {
    updateGlobalPreferences({
      data: {
        onboarding_steps: {
          ...data,
          onBoardingFinish: true,
        },
      },
    });
  }, [updateGlobalPreferences, data]);

  const contextValue = useMemo(
    () => ({
      onboardingStatus: data,
      isOnboardingActive,
      isOnboardingFinished,
      openedSpotlight: isDialogOpen ? undefined : openedSpotlight,
      openSpotlight,
      openDialog,
      completeOnboardingStep,
    }),
    [
      data,
      isOnboardingFinished,
      isOnboardingActive,
      completeOnboardingStep,
      openDialog,
      openedSpotlight,
      openSpotlight,
      isDialogOpen,
    ]
  );

  return (
    <OnboardingContext.Provider value={contextValue}>
      <OnboardingBeginningDialog
        isOpen={isOnboardingBeginningDialogOpen}
        onRequestClose={closeOnboardingBeginningDialog}
      />
      <GetStartedDialog
        isOpen={isDialogOpen}
        ref={dialogRef}
        isOnboardingFinished={isOnboardingFinished}
        activeStepIndex={activeStepIndex}
        openSpotlight={openSpotlight}
        onFinishOnboarding={finishOnboarding}
        setStepIndex={setStepIndex}
        onRequestClose={() => setIsDialogOpen(false)}
      />
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboarding = () => {
  const context = useContext(OnboardingContext);

  if (!context) {
    throw new HookOutOfContextError("useOnboarding", "OnboardingContext");
  }

  return context;
};
