import { EntityModel } from "@jugl-web/rest-api";
import { DeleteEntityConfirmationDialog } from "@web-src/modules/entities/components/DeleteEntityConfirmationDialog";
import { LastEntityAdminWarningDialog } from "@web-src/modules/entities/components/LastEntityAdminWarningDialog";
import { LeaveEntityConfirmationDialog } from "@web-src/modules/entities/components/LeaveEntityConfirmationDialog";
import { forwardRef, useCallback, useImperativeHandle, useState } from "react";

interface EntityRelatedDialogState {
  isOpen: boolean;
  entity: EntityModel | null;
}

const DEFAULT_ENTITY_RELATED_DIALOG_STATE: EntityRelatedDialogState = {
  isOpen: false,
  entity: null,
};

export interface LeaveEntityConfirmationDialogsFlowHandle {
  triggerLeaveEntityFlow: (entity: EntityModel) => void;
  triggerDeleteEntityFlow: (entity: EntityModel) => void;
}

export const LeaveEntityConfirmationDialogsFlow =
  forwardRef<LeaveEntityConfirmationDialogsFlowHandle>((_, ref) => {
    const [
      leaveEntityConfirmationDialogState,
      setLeaveEntityConfirmationDialogState,
    ] = useState(DEFAULT_ENTITY_RELATED_DIALOG_STATE);

    const [
      deleteEntityConfirmationDialogState,
      setDeleteEntityConfirmationDialogState,
    ] = useState(DEFAULT_ENTITY_RELATED_DIALOG_STATE);

    const [
      lastEntityAdminWarningDialogState,
      setLastEntityAdminWarningDialogState,
    ] = useState(DEFAULT_ENTITY_RELATED_DIALOG_STATE);

    const triggerLeaveEntityFlow = useCallback((entity: EntityModel) => {
      setLeaveEntityConfirmationDialogState({ isOpen: true, entity });
    }, []);

    const triggerDeleteEntityFlow = useCallback((entity: EntityModel) => {
      setDeleteEntityConfirmationDialogState({ isOpen: true, entity });
    }, []);

    useImperativeHandle(ref, () => ({
      triggerLeaveEntityFlow,
      triggerDeleteEntityFlow,
    }));

    return (
      <>
        <LeaveEntityConfirmationDialog
          isOpen={leaveEntityConfirmationDialogState.isOpen}
          entity={leaveEntityConfirmationDialogState.entity}
          onLastEntityAdminError={() => {
            setLeaveEntityConfirmationDialogState((prevState) => ({
              ...prevState,
              isOpen: false,
            }));

            setLastEntityAdminWarningDialogState({
              isOpen: true,
              entity: leaveEntityConfirmationDialogState.entity,
            });
          }}
          onClose={() =>
            setLeaveEntityConfirmationDialogState((prevState) => ({
              ...prevState,
              isOpen: false,
            }))
          }
        />
        <LastEntityAdminWarningDialog
          isOpen={lastEntityAdminWarningDialogState.isOpen}
          entity={lastEntityAdminWarningDialogState.entity}
          onDeleteEntityRequest={() => {
            setLastEntityAdminWarningDialogState((prevState) => ({
              ...prevState,
              isOpen: false,
            }));

            setDeleteEntityConfirmationDialogState({
              isOpen: true,
              entity: lastEntityAdminWarningDialogState.entity,
            });
          }}
          onClose={() =>
            setLastEntityAdminWarningDialogState((prevState) => ({
              ...prevState,
              isOpen: false,
            }))
          }
        />
        <DeleteEntityConfirmationDialog
          isOpen={deleteEntityConfirmationDialogState.isOpen}
          entity={deleteEntityConfirmationDialogState.entity}
          onClose={() =>
            setDeleteEntityConfirmationDialogState((prevState) => ({
              ...prevState,
              isOpen: false,
            }))
          }
        />
      </>
    );
  });
