import { useRestApiProvider } from "@jugl-web/rest-api";
import { TaskChecklistSummaryItem } from "@jugl-web/rest-api/tasks";
import { User } from "@jugl-web/rest-api/users";
import { Fab } from "@jugl-web/ui-components/cross-platform/Fab";
import { Text } from "@jugl-web/ui-components/cross-platform/Text";
import { useTranslations } from "@jugl-web/utils";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { Pagination } from "@jugl-web/ui-components/web/Pagination";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  Avatar,
  EmptyListContent,
  InteractiveContainer,
} from "@jugl-web/ui-components/cross-platform";
import { locationApi, tasksApi } from "@web-src/features/api/createApi";
import useEntity from "@web-src/features/app/hooks/useEntity";
import PeopleInfoSidebar from "@web-src/features/people/components/PeopleInfoSidebar";
import useDebounce from "@web-src/hooks/useDebounce";
import cx from "classnames";
import { addDays, addWeeks, format, isToday, subDays } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { workdayDurationCalculator } from "@web-src/utils/workdayDurationCalculator";
import FilterSidebar from "../FilterSidebar";
import DaySummaryCell from "./components/DaySummaryCell";
import TaskListSidebar from "./components/TaskListSidebar";
import { ReactComponent as AddCheckIcon } from "./assets/add-check.svg";
import { ReactComponent as FilterIcon } from "./assets/filter.svg";
import { ReactComponent as SearchIcon } from "./assets/search.svg";
import { ReactComponent as TimerTotalIcon } from "./assets/timer-total.svg";
import { formatSecondsToDuration, toUTCTimeStamp } from "./utils";

const List: React.FC<{
  currentDate: Date;
  setSearch: (value: string) => void;
  searchQuery: string | undefined;
}> = ({ currentDate, setSearch, searchQuery }) => {
  const { entity } = useEntity();
  const { t } = useTranslations();
  const { dateLocale } = useLanguage();

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [isCompletedTaskListOpen, setIsCompletedTaskListOpen] =
    useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState<string>();
  const [isSortedAsc, setIsSortedAsc] = useState<boolean>(true);
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
  const [taskSidebarData, setTaskSidebarData] =
    useState<{ completed_on: string; task_id: string }[]>();
  const [checklistSidebarData, setChecklistSidebarData] =
    useState<TaskChecklistSummaryItem[]>();
  const debouncedSearchQuery = useDebounce(searchQuery);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [profileInfoUserId, setProfileInfoUserId] = useState<
    string | undefined
  >();

  const [loadUserSummary, { data: usersSummary }] =
    locationApi.useLazyUserSummaryQuery();
  const [loadChecklistSummary, { data: checklistSummary }] =
    tasksApi.useLazyGetChecklistSummaryQuery();
  const { usersApi } = useRestApiProvider();
  const { data: users } = usersApi.use_DEPRECATED_getAllUsersQuery(
    entity
      ? {
          entityId: entity?.id,
        }
      : skipToken
  );
  const getUserSummary = useCallback(
    (userId: string) =>
      usersSummary?.data?.find((element) => element.user_id === userId),
    [usersSummary?.data]
  );
  const getUserChecklistSummary = useCallback(
    (userId: string) =>
      checklistSummary?.data?.find((element) => element.user_id === userId),
    [checklistSummary]
  );
  const filteredUsers = useMemo(() => {
    let usersNewState = users && [...users];
    if (debouncedSearchQuery) {
      usersNewState = usersNewState?.filter((user: User) =>
        JSON.stringify(user).toLocaleLowerCase().includes(debouncedSearchQuery)
      );
    }
    if (selectedDepartments.length) {
      let filteredByDepartment: User[] = [];
      selectedDepartments.forEach((department) => {
        const filtered = usersNewState?.filter(
          (user) => user.spaces && user.spaces[0]?.id === department
        );
        if (filtered?.length) {
          filteredByDepartment = filteredByDepartment.concat(filtered);
        }
      });
      usersNewState = filteredByDepartment;
    }
    usersNewState =
      usersNewState &&
      usersNewState?.sort((a: User, b: User) => {
        const usernameA =
          a?.profile?.first_name?.toLowerCase() || a?.username?.toLowerCase();
        const usernameB =
          b?.profile?.first_name?.toLowerCase() || b?.username?.toLowerCase();
        if (usernameA && usernameB && usernameA < usernameB) {
          return isSortedAsc ? -1 : 1;
        }
        if (usernameA && usernameB && usernameA > usernameB) {
          return isSortedAsc ? 1 : -1;
        }
        return 0;
      });
    return usersNewState;
  }, [isSortedAsc, users, debouncedSearchQuery, selectedDepartments]);
  const pageCount = () => {
    const usersNumber = filteredUsers?.length || 0;
    const counter = (usersNumber > 0 && usersNumber / pageSize) || 0;
    return counter < 1 ? 1 : Math.ceil(counter);
  };

  const username = useCallback((user: User) => {
    if (user.profile) {
      return `${user?.profile?.first_name || ""} ${
        user?.profile?.last_name || ""
      }`;
    }
    return user.username;
  }, []);
  const dates = useMemo(() => {
    const arr = [];
    for (let i = 0; i < 7; i += 1) {
      if (i === 0) {
        arr.push(currentDate);
      } else {
        arr.push(addDays(currentDate, i));
      }
    }
    return arr;
  }, [currentDate]);
  const formattedWeekDays = useMemo(
    () => dates.map((date) => format(date, "yyyy-MM-dd")),
    [dates]
  );
  const handleWeeklyTotalDuration = useCallback((seconds: number) => {
    if (seconds > 0) {
      return formatSecondsToDuration(seconds);
    }
    return "0:00:00";
  }, []);
  const openTaskListSidebar = useCallback(
    (
      date: string,
      userId: string | undefined,
      tasks: { completed_on: string; task_id: string }[],
      checklistsSummaryData: TaskChecklistSummaryItem[]
    ) => {
      if (!userId) return;
      setTaskSidebarData(tasks);
      setSelectedDay(date);
      setChecklistSidebarData(checklistsSummaryData);
      setIsCompletedTaskListOpen(true);
    },
    []
  );

  const handleSearchChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };
  const handleTotalTaskCount = useCallback(
    (userId: string) => {
      const userSummary = getUserSummary(userId);
      if (userSummary?.events[0]) {
        return userSummary.tasks.length;
      }
      return 0;
    },
    [getUserSummary]
  );

  const onSubmit = (params: { [key: string]: boolean }) => {
    setIsSortedAsc(params["sort-asc"] || false);
    const selectedDept: string[] = [];
    Object.keys(params).forEach((key) => {
      if (key.includes("dept") && params[key]) {
        selectedDept.push(key.replace("dept-", ""));
      }
    });
    setSelectedDepartments(selectedDept);
    setIsFilterOpen(false);
  };
  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };
  const handlePageSizeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setPage(1);
    setPageSize(Number(event.target.value));
  };

  const paginatedData = useMemo(
    () => filteredUsers?.slice((page - 1) * pageSize, page * pageSize),
    [filteredUsers, pageSize, page]
  );

  const weekDates = useMemo(() => {
    const startOfDayDates = [];
    const endOfDayDates = [];
    for (let i = 0; i < 7; i += 1) {
      const formattedDate = format(addDays(currentDate, i), "yyyy-MM-dd");
      startOfDayDates.push(toUTCTimeStamp(formattedDate));
      endOfDayDates.push(toUTCTimeStamp(formattedDate, true));
    }
    return {
      startOfDayDates: startOfDayDates.join(","),
      endOfDayDates: endOfDayDates.join(","),
    };
  }, [currentDate]);
  useEffect(() => {
    const userIds = paginatedData?.map((user) => user.id).join(",");
    if (entity?.id) {
      loadUserSummary({
        entity_id: entity?.id,
        page: 1,
        page_size: pageSize,
        user_id: userIds || "",
        from: `${format(subDays(currentDate, 1), "yyyy-MM-dd")}T00:00:00`,
        to: `${format(
          addWeeks(addDays(currentDate, 1), 1),
          "yyyy-MM-dd"
        )}T23:59:59`,
      });
      loadChecklistSummary({
        entityId: entity?.id,
        params: {
          user_id: userIds || "",
          from: `${format(subDays(currentDate, 1), "yyyy-MM-dd")}T00:00:00`,
          to: `${format(
            addWeeks(addDays(currentDate, 1), 1),
            "yyyy-MM-dd"
          )}T23:59:59`,
        },
      });
    }
  }, [
    currentDate,
    entity?.id,
    loadUserSummary,
    loadChecklistSummary,
    pageSize,
    paginatedData,
    weekDates.endOfDayDates,
    weekDates.startOfDayDates,
  ]);

  const handleToggleUserProfileSidebar = useCallback(
    (userId: string | undefined) => {
      setProfileInfoUserId(userId);
    },
    []
  );
  const columnDate = (date: Date) =>
    window.innerWidth > 1441
      ? format(date, "MMM, eee", { locale: dateLocale })
      : format(date, "eee", { locale: dateLocale });

  const makeListRow = (user: User) => {
    const userSummary = getUserSummary(user.id);
    const userChecklistSummary = getUserChecklistSummary(user.id);
    const calculatedWorkdays = formattedWeekDays.map((element) =>
      workdayDurationCalculator(
        userSummary?.events || [],
        element,
        user.timezone
      )
    );
    const workDaysSummary = calculatedWorkdays.reduce((acc, curr) => {
      if (curr === 0) {
        return acc;
      }
      return acc + curr;
    }, 0);
    return (
      <div
        className="border-grey-200 grid w-full grid-cols-[20%_repeat(8,_10%)_auto] border-[2px] border-t-0 border-r-0 border-l-0 border-solid bg-white pb-0 pl-8 pr-9"
        key={user.id}
      >
        <div className="flex flex-row items-center gap-2 border-[2px] py-4">
          <InteractiveContainer
            onClick={() => handleToggleUserProfileSidebar(user.id)}
          >
            <Avatar
              imageUrl={user.profile?.img}
              username={username(user)}
              size="xl"
            />
          </InteractiveContainer>
          <div className="flex w-5/6 flex-col gap-1 overflow-hidden">
            <Text variant="body1">{username(user)}</Text>
            <span
              style={{ fontVariant: "all-small-caps" }}
              className="w-5/6 overflow-hidden text-ellipsis whitespace-nowrap"
            >
              <Text
                variant="messageTextDefault"
                className="text-grey font-semibold uppercase"
                title={
                  (user.spaces && user.spaces[0]?.info?.title) ||
                  "No Department"
                }
              >
                {(user.spaces && user.spaces[0]?.info?.title) ||
                  t({
                    id: "common.no-department",
                    defaultMessage: "No department",
                  })}
              </Text>
            </span>
          </div>
        </div>
        {formattedWeekDays.map((element, index) => (
          <DaySummaryCell
            checklistSummary={userChecklistSummary}
            key={`${user.id}${element}`}
            userTimezone={user.timezone || ""}
            date={element}
            userData={userSummary || undefined}
            onOpenTaskListSidebar={openTaskListSidebar}
            dayDuration={
              calculatedWorkdays[index]
                ? formatSecondsToDuration(calculatedWorkdays[index])
                : "-"
            }
          />
        ))}
        <div className="border-grey-200 relative flex w-full flex-col items-start justify-center gap-6 border-[2px] border-t-0 border-r-0 border-b-0 border-solid py-4 px-8">
          <div className="flex flex-row items-center gap-2">
            <TimerTotalIcon />{" "}
            <Text variant="body1" className="text-dark">
              {handleWeeklyTotalDuration(workDaysSummary)}
            </Text>
          </div>
          <div className="flex flex-row items-center gap-2">
            <AddCheckIcon />{" "}
            <Text variant="body1" className="text-dark">
              {handleTotalTaskCount(user.id)}
            </Text>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      {profileInfoUserId && (
        <PeopleInfoSidebar
          userId={profileInfoUserId}
          isOpen
          onClose={() => handleToggleUserProfileSidebar(undefined)}
        />
      )}
      <TaskListSidebar
        isOpen={isCompletedTaskListOpen}
        date={selectedDay}
        onClose={() => {
          setIsCompletedTaskListOpen(false);
          setTaskSidebarData(undefined);
        }}
        tasksList={taskSidebarData}
        checkLists={checklistSidebarData}
      />
      <FilterSidebar
        isOpen={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        onSubmit={onSubmit}
      />
      <div
        className="border-grey-200 sticky top-[60px] z-20 grid w-full grid-cols-[20%_repeat(8,_10%)_auto] border-t-2 border-b-0 border-l-0 border-r-0 border-solid bg-white py-4 pl-8 pr-9"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex flex-row items-center gap-2">
          <div className="relative h-[40px] w-[calc(90%_-_64px)]">
            <SearchIcon className="absolute left-4 top-2.5" />
            <input
              value={searchQuery}
              placeholder={t({
                id: "common.search-with-ellipsis",
                defaultMessage: "Search...",
              })}
              className="bg-primary-500/5 h-full w-full appearance-none rounded-md border-none pl-11"
              onChange={handleSearchChange}
            />
          </div>
          <Fab
            icon={<FilterIcon />}
            variant="default"
            size="xs"
            className="rounded-md"
            onClick={() => setIsFilterOpen(!isFilterOpen)}
          />
        </div>
        {dates.map((element) => (
          <div
            className="pointer-events-none relative z-20 flex w-full items-center justify-center"
            key={element.toString()}
          >
            <div className="bg-grey-200 absolute left-0 h-full w-[2px]" />
            <div
              className={cx(
                "bg-grey-200 mr-3 flex h-[40px] w-[40px] items-center justify-center rounded-full",
                {
                  "bg-secondary-100 text-secondary-900": isToday(element),
                }
              )}
            >
              <Text
                variant="body2"
                className={cx({
                  "text-secondary-900 font-semibold": isToday(element),
                })}
              >
                {format(element, "d")}
              </Text>
            </div>
            <Text variant="body2">{columnDate(element)}</Text>
          </div>
        ))}
        <div
          className="relative flex items-center justify-start px-8 uppercase"
          style={{ fontVariant: "all-small-caps" }}
        >
          <div className="bg-grey-200 absolute left-0 h-full w-[2px]" />
          <Text variant="body2">
            {t({
              id: "location-page.weekly-total",
              defaultMessage: "weekly total",
            })}
          </Text>
        </div>
      </div>
      <div className="mt-16">
        {paginatedData?.map((user) => (user ? makeListRow(user) : null))}
      </div>
      <div
        className={cx("h-[40vh] w-full", {
          hidden: filteredUsers?.length !== 0,
        })}
      >
        <EmptyListContent type="noResults" />
      </div>
      <div
        className={cx("flex flex-row justify-between px-8 py-6", {
          hidden: filteredUsers?.length === 0,
        })}
      >
        <div className="flex flex-row items-center gap-2">
          <Text variant="body3">
            {t({
              id: "location-page.rows-per-page",
              defaultMessage: "Rows per page",
            })}
          </Text>
          <select
            onChange={handlePageSizeChange}
            className="border-grey-400 w-8 appearance-none border-0 border-b-2 bg-transparent text-center"
          >
            <option value={20}>20</option>
            <option value={50}>50</option>
          </select>
        </div>
        <Pagination
          currentPage={page}
          totalPages={pageCount()}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default List;
