import { FC } from "react";
import { useTranslations } from "@jugl-web/utils";
import { PlainButton, Text } from "@jugl-web/ui-components/cross-platform";
import { ReactComponent as ManageFieldsIcon } from "./icons/manage-fields.svg";

export interface ManageTaskFieldsTopBarButtonProps {
  onClick: () => void;
}

export const ManageTaskFieldsTopBarButton: FC<
  ManageTaskFieldsTopBarButtonProps
> = ({ onClick }) => {
  const { t } = useTranslations();
  return (
    <PlainButton
      onClick={onClick}
      className="jugl__focusable-outline flex h-[40px] items-center gap-2 rounded-lg p-2 transition-colors hover:bg-[#EEF2F5]"
    >
      <ManageFieldsIcon />
      <Text variant="body3" className="text-dark-800">
        {t({
          id: "tasks-page.manage-fields",
          defaultMessage: "Manage fields",
        })}
      </Text>
    </PlainButton>
  );
};
