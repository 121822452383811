import { Avatar } from "@jugl-web/ui-components/cross-platform/Avatar";
import {
  ListBoxItemComponent,
  ListBoxItemComponentProps,
} from "@jugl-web/ui-components/cross-platform/ListBox";
import { FC } from "react";
import { useUserGeneralProfile } from "../../hooks/useUserGeneralProfile";

interface UserListBoxItemProps extends ListBoxItemComponentProps {
  entityId: string;
  userId: string;
}

export const UserListBoxItem: FC<UserListBoxItemProps> = ({
  entityId,
  userId,
  ...listBoxItemProps
}) => {
  const { safeProfile } = useUserGeneralProfile({ entityId, userId });

  return (
    <ListBoxItemComponent
      {...listBoxItemProps}
      label={safeProfile.displayName}
      startSlot={
        <Avatar
          className="border border-solid border-white"
          size="md"
          imageUrl={safeProfile.avatar}
          username={safeProfile.displayName}
        />
      }
    />
  );
};
