import { InternalTaskCustomField } from "@jugl-web/rest-api/tasks";
import { ConfirmationPopup } from "@jugl-web/ui-components/cross-platform";
import { assert, useAppVariant, useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import { useTaskFields } from "../../hooks/useTaskFields";
import { ReactComponent as TrashBinLargeIcon } from "./icons/trash-bin-large.svg";

export interface DeleteCustomFieldConfirmationDialogProps {
  entityId: string;
  isOpen: boolean;
  field: InternalTaskCustomField | null;
  onClose: () => void;
}

export const DeleteCustomFieldConfirmationDialog: FC<
  DeleteCustomFieldConfirmationDialogProps
> = ({ entityId, isOpen, field, onClose }) => {
  const { variant } = useAppVariant();
  const { t } = useTranslations();

  const { deleteCustomField, isDeletingCustomFields } = useTaskFields({
    entityId,
  });

  return (
    <ConfirmationPopup
      variant={variant}
      isOpen={isOpen}
      icon={<TrashBinLargeIcon />}
      title={t({
        id: "tasks-page.delete-custom-field",
        defaultMessage: "Delete custom field",
      })}
      message={
        <>
          {t(
            {
              id: "tasks-page.confirm-delete-custom-field",
              defaultMessage:
                "Are you sure you want to delete custom field {fieldName}?",
            },
            {
              fieldName: (
                <span className="text-primary-800 font-semibold">
                  {field?.name}
                </span>
              ),
            }
          )}
          <br />
          {t({
            id: "tasks-page.remove-custom-field-warning",
            defaultMessage:
              "It will be removed from all tasks associated with it.",
          })}
        </>
      }
      hasCancelButton
      buttons={[
        {
          label: t({ id: "common.delete", defaultMessage: "Delete" }),
          color: "primary",
          isDisabled: isDeletingCustomFields,
          onClick: () => {
            assert(!!field);
            onClose();
            deleteCustomField(field.id);
          },
        },
      ]}
      onRequestClose={onClose}
    />
  );
};
