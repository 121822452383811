import { assert, cx, useTranslations } from "@jugl-web/utils";
import { ReactNode, useMemo, useRef, useState } from "react";
import { Button } from "../../cross-platform/Button";
import {
  ListBox,
  ListBoxHandle,
  ListBoxItem,
  ListBoxProps,
  UnsafeListBoxItem,
} from "../../cross-platform/ListBox";
import { Popover, PopoverProps } from "../../cross-platform/Popover";
import { ResourcePickerPopoverHeader } from "./ResourcePickerPopoverHeader";

interface EnhancedListBoxProps<TValue>
  extends Omit<ListBoxProps<TValue>, "onSelect" | "className"> {
  onSelect?: (props: {
    item: ListBoxItem<TValue>;
    isSelected: boolean;
    onClose: () => void;
  }) => void;
}

export interface ResourcePickerPopoverProps<TValue>
  extends EnhancedListBoxProps<TValue>,
    Pick<PopoverProps, "renderTrigger" | "placement" | "onUnmount"> {
  title?: ReactNode;
  subtitle?: ReactNode;
  className?: string;
  customButtonLabel?: string;
  onSubmit?: (ids: string[], items: UnsafeListBoxItem<TValue>[]) => void;
}

export const ResourcePickerPopover = <TValue,>({
  title,
  subtitle,
  placement,
  customButtonLabel,
  className,
  renderTrigger,
  onSelect,
  onSelectionChange,
  onSubmit,
  onUnmount,
  ...listBoxProps
}: ResourcePickerPopoverProps<TValue>) => {
  const [selectedItemsCount, setSelectedItemsCount] = useState(0);
  const listBoxRef = useRef<ListBoxHandle<TValue> | null>(null);

  const { t } = useTranslations();

  const hasHeader = !!title;
  const hasSubmitButton = !!onSubmit;

  const shouldShowSelectedItemsCounter =
    listBoxProps.selectionBehavior.mode === "multiple" &&
    selectedItemsCount > 0;

  const buttonLabel = useMemo(() => {
    if (customButtonLabel) {
      return customButtonLabel;
    }

    if (shouldShowSelectedItemsCounter) {
      return t(
        {
          id: "common.select-with-count",
          defaultMessage: "Select {count}",
        },
        {
          count: selectedItemsCount,
        }
      );
    }

    return t({
      id: "common.select",
      defaultMessage: "Select",
    });
  }, [
    customButtonLabel,
    shouldShowSelectedItemsCounter,
    selectedItemsCount,
    t,
  ]);

  const handlePopoverMount = () => {
    if (listBoxRef.current) {
      setSelectedItemsCount(listBoxRef.current.getSelectedIds().length);
    }
  };

  const handlePopoverUnmount = () => {
    setSelectedItemsCount(0);
    onUnmount?.();
  };

  return (
    <Popover
      renderTrigger={renderTrigger}
      placement={placement}
      className={cx("w-[315px]", className)}
      onMount={handlePopoverMount}
      onUnmount={handlePopoverUnmount}
    >
      {({ onClose }) => (
        <>
          {hasHeader && (
            <ResourcePickerPopoverHeader
              title={title}
              subtitle={subtitle}
              onClose={onClose}
            />
          )}
          <div className="px-3 py-3">
            <ListBox
              ref={listBoxRef}
              isCompact
              onSelectionChange={(ids, items) => {
                onSelectionChange?.(ids, items);
                setSelectedItemsCount(ids.length);
              }}
              onSelect={
                onSelect
                  ? (item, isSelected) =>
                      onSelect({ item, isSelected, onClose })
                  : undefined
              }
              {...listBoxProps}
            />
            {hasSubmitButton && (
              <div className="px-3 pt-4 pb-1">
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  className="h-8"
                  onClick={() => {
                    assert(!!listBoxRef.current, "ListBox ref is not set");

                    onSubmit(
                      listBoxRef.current.getSelectedIds(),
                      listBoxRef.current.getSelectedItems()
                    );

                    onClose();
                  }}
                >
                  {buttonLabel}
                </Button>
              </div>
            )}
          </div>
        </>
      )}
    </Popover>
  );
};
