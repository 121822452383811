import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { cx, useTranslations } from "@jugl-web/utils";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { eachMinuteOfInterval, format, parse } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { ReactComponent as ArrowIcon } from "./assets/arrow.svg";

export enum ScheduledReportsWeekDay {
  sun = "sun",
  mon = "mon",
  tue = "tue",
  wed = "wed",
  thu = "thu",
  fri = "fri",
  sat = "sat",
}

export const CrontabPicker: React.FC<{
  value: string;
  onChange: (value: string) => void;
}> = ({ value, onChange }) => {
  const cronParts = value.split(" ");
  const isDaily = cronParts[4] === "*";
  const periodValue: "Day" | "Week" = isDaily ? "Day" : "Week";
  const weekDayValue: ScheduledReportsWeekDay = isDaily
    ? ScheduledReportsWeekDay.sun
    : Object.values(ScheduledReportsWeekDay)[parseInt(cronParts[4], 10)];
  const hourValue = format(
    new Date().setHours(parseInt(cronParts[1], 10), parseInt(cronParts[0], 10)),
    "h:mm a"
  );

  const [period, setPeriod] = useState(periodValue);
  const [weekDay, setWeekDay] = useState(weekDayValue);
  const [hour, setHour] = useState(hourValue);
  const { dateLocale } = useLanguage();
  const { t } = useTranslations();

  useEffect(() => {
    const parsedTime = parse(hour, "h:mm a", new Date());
    onChange(
      `${format(parsedTime, "m")} ${format(parsedTime, "H")} * * ${
        period === "Day"
          ? "*"
          : Object.values(ScheduledReportsWeekDay).indexOf(weekDay)
      }`
    );
  }, [period, weekDay, hour, onChange]);

  const weekDayItems = useMemo(
    () => Object.values(ScheduledReportsWeekDay).map((day) => day),
    []
  );

  const hourItems = useMemo(() => {
    const startOfTheDay = new Date();
    startOfTheDay.setHours(0, 0, 0, 0);

    const halfHourIntervals = [];

    const allMinutesOfDay = eachMinuteOfInterval({
      start: startOfTheDay,
      end: new Date(startOfTheDay.getTime() + 24 * 60 * 60 * 1000),
    });

    for (let i = 0; i < allMinutesOfDay.length - 30; i += 30) {
      const currentTime = allMinutesOfDay[i];
      const formattedTime = format(currentTime, "h:mm a", {
        locale: dateLocale,
      });
      halfHourIntervals.push(formattedTime);
    }

    return halfHourIntervals.map((hourInterval) => ({
      id: hourInterval,
      value: hourInterval,
    }));
  }, [dateLocale]);

  return (
    <div className="flex flex-col gap-2">
      <span className="text-sm text-[#828282]">
        {t({
          id: "scheduled-reports-page.repeat-every",
          defaultMessage: "Repeat every",
        })}
        :
      </span>
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-14">
          <ResourcePickerPopover
            maxVisibleItems={5}
            title=""
            renderTrigger={({ Trigger, triggerRef, isOpen }) => (
              <Trigger
                ref={triggerRef}
                as={InteractiveContainer}
                className="bg-grey-100 flex w-max min-w-[180px] items-center justify-between gap-4 rounded-lg p-4 outline-none"
              >
                <span className="text-sm text-[#1A2023]">{period}</span>
                <ArrowIcon
                  className={cx(isOpen && "rotate-180 transition-transform")}
                />
              </Trigger>
            )}
            selectionBehavior={{ canToggle: false, mode: "single" }}
            placement="bottom"
            items={[
              { id: "Day", value: "Day" },
              { id: "Week", value: "Week" },
            ]}
            renderLabel={({ value: label }) => label}
            defaultSelectedIds={[period]}
            onSelect={({ item, onClose }) => {
              setPeriod(item.value as "Day" | "Week");
              onClose();
            }}
          />
          {period === "Week" && (
            <div className="flex items-center gap-5">
              {weekDayItems.map((day) => (
                <InteractiveContainer
                  key={day}
                  className={cx(
                    "flex h-10 w-10 items-center justify-center rounded-full text-sm font-medium transition-colors",
                    weekDay === day
                      ? "bg-primary-800 text-white"
                      : "bg-grey-100 text-[#4F4F4F]"
                  )}
                  onClick={() => {
                    setWeekDay(day);
                  }}
                >
                  {day.charAt(0).toUpperCase()}
                </InteractiveContainer>
              ))}
            </div>
          )}
        </div>
        <ResourcePickerPopover
          maxVisibleItems={5}
          title=""
          renderTrigger={({ Trigger, triggerRef, isOpen }) => (
            <Trigger
              ref={triggerRef}
              as={InteractiveContainer}
              className="bg-grey-100 flex w-max min-w-[180px] items-center justify-between gap-4 rounded-lg p-4 outline-none"
            >
              <span className="text-sm text-[#1A2023]">{hour}</span>
              <ArrowIcon
                className={cx(isOpen && "rotate-180 transition-transform")}
              />
            </Trigger>
          )}
          selectionBehavior={{ canToggle: false, mode: "single" }}
          placement="bottom"
          items={hourItems}
          renderLabel={({ value: label }) => label}
          defaultSelectedIds={[hour]}
          onSelect={({ item, onClose }) => {
            setHour(item.value);
            onClose();
          }}
        />
      </div>
    </div>
  );
};
