import { cx } from "@jugl-web/utils";
import { FC, ReactNode } from "react";
import Highlighter from "react-highlight-words";
import { InteractiveContainer } from "../../InteractiveContainer";
import { Menu, MenuConfig } from "../../Menu";
import { PlainButton } from "../../PlainButton";
import { ReactComponent as MenuIcon } from "./icons/menu.svg";

export interface TaskTemplateFolderTileProps {
  color: string;
  icon: ReactNode;
  subtitle: string;
  highlightedText?: string;
  menuConfig?: MenuConfig;
  onClick: () => void;
}

export const TaskTemplateFolderTile: FC<TaskTemplateFolderTileProps> = ({
  color,
  icon,
  subtitle,
  highlightedText,
  menuConfig,
  onClick,
}) => (
  <div className="flex w-[200px] justify-center">
    <InteractiveContainer
      className="flex max-w-max flex-col items-center justify-center gap-2.5 transition hover:brightness-95"
      onClick={onClick}
    >
      <div
        className="group relative flex h-[120px] w-[120px] items-center justify-center rounded-2xl"
        style={{ backgroundColor: color }}
      >
        {menuConfig && (
          <Menu
            placement="right-start"
            renderTrigger={({ Trigger, triggerRef, isOpen }) => (
              <Trigger
                ref={triggerRef}
                as={PlainButton}
                className={cx(
                  "absolute right-2 top-2 rounded-full p-[3px] opacity-0 transition hover:bg-white/20 group-hover:opacity-100",
                  isOpen && "bg-white/20 opacity-100"
                )}
                onClick={(event) => event.stopPropagation()}
              >
                <MenuIcon />
              </Trigger>
            )}
            {...menuConfig}
          />
        )}
        <div className="flex h-12 w-12 items-center justify-center rounded-full bg-white">
          {icon}
        </div>
      </div>
      <span className="text-dark leading-4">
        {highlightedText ? (
          <Highlighter
            autoEscape
            highlightClassName="text-primary bg-primary-50"
            highlightTag="span"
            textToHighlight={subtitle}
            searchWords={[highlightedText]}
          />
        ) : (
          subtitle
        )}
      </span>
    </InteractiveContainer>
  </div>
);
