import { PageNavParams } from "@web-src/modules/navigation/types";
import { ModulePagesConfig } from "@web-src/modules/navigation/types/ModulePagesConfigs";
import LocationPage from "./LocationPage";

export type LocationPagesNavigationParams = {
  locationLocation: PageNavParams<{ userId: string; date: string }>;
};

export const locationPagesNavigationConfig: ModulePagesConfig<LocationPagesNavigationParams> =
  {
    locationLocation: {
      element: <LocationPage />,
      path: "location/:userId/:date",
      isPrivate: true,
      isAdminRequired: true,
    },
  };
