import { OptionsObject, SnackbarMessage, useSnackbar } from "notistack";
import { useCallback } from "react";
import { AppVariant } from "../types";
import { useAppVariant } from "./useAppVariant";

interface UseToastOptions {
  variant?: AppVariant;
}

export const useToast = ({
  variant: overriddenVariant,
}: UseToastOptions = {}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { variant: appVariant } = useAppVariant();
  const variant = overriddenVariant || appVariant;

  const toast = useCallback(
    (message: SnackbarMessage, options?: OptionsObject) =>
      enqueueSnackbar(message, {
        variant: "success",
        hideIconVariant: true,
        anchorOrigin: {
          horizontal: "center",
          vertical: variant === "mobile" ? "bottom" : "top",
        },
        className: "justify-center",
        autoHideDuration: 3000,
        ...options,
      }),
    [enqueueSnackbar, variant]
  );

  return { toast, closeToast: closeSnackbar };
};
