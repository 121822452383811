import { Dialog } from "@jugl-web/ui-components/cross-platform/Dialog";
import { Popover } from "@jugl-web/ui-components/cross-platform/Popover";
import {
  TaskPropertyButton,
  TaskPropertyButtonProps,
} from "@jugl-web/ui-components/cross-platform/tasks/TaskPropertyButton";
import {
  TimeSpentPicker,
  TimeSpentPickerProps,
} from "@jugl-web/ui-components/cross-platform/tasks/TimeSpentPicker";
import { convertMillisecondsToTimeComponents } from "@jugl-web/ui-components/cross-platform/tasks/TimeSpentPicker/utils";
import { cx, useAppVariant, useTranslations } from "@jugl-web/utils";
import { FC, useMemo, useState } from "react";
import { ReactComponent as TimeSpentIcon } from "../../assets/time-spent.svg";
import { FieldComponentProps } from "../../types";

export const TimeSpentField: FC<FieldComponentProps<"timeSpent">> = ({
  duration,
  isReadonly,
  onChange,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { isMobile } = useAppVariant();

  const { t } = useTranslations();

  const isFieldVisible = !!duration || !isReadonly;
  const hasDuration = !!duration;

  const label = useMemo(() => {
    if (!hasDuration) {
      return t({
        id: "task-properties-panel-component.time-spent-on-task",
        defaultMessage: "Time spent on task",
      });
    }

    const { hours, minutes, seconds } =
      convertMillisecondsToTimeComponents(duration);

    const timeComponentsLabel = [
      {
        label: t({
          id: "common.hour-short",
          defaultMessage: "h",
        }),
        value: hours,
      },
      {
        label: t({
          id: "common.minute-short",
          defaultMessage: "min",
        }),
        value: minutes,
      },
      {
        label: t({
          id: "common.second-short-letter",
          defaultMessage: "s",
        }),
        value: seconds,
      },
    ]
      .filter(({ value }) => value > 0)
      .slice(0, 2)
      .map((timeComponent) => `${timeComponent.value}${timeComponent.label}`)
      .join(" ");

    return t(
      {
        id: "task-properties-panel-component.time-spent-on-task-with-value",
        defaultMessage: "Time spent on task: {label}",
      },
      { label: timeComponentsLabel }
    );
  }, [duration, hasDuration, t]);

  const commonButtonProps: TaskPropertyButtonProps = {
    isDisabled: isReadonly,
    startIcon: <TimeSpentIcon className={cx(hasDuration && "text-primary")} />,
    className: cx(hasDuration && "bg-primary-50 text-primary-900"),
    children: label,
  };

  const commonTimeSpentPickerProps: Omit<TimeSpentPickerProps, "onClose"> = {
    initialTimeMs: duration || undefined,
    onApply: (d) => onChange?.(d),
  };

  if (!isFieldVisible) {
    return null;
  }

  if (isMobile) {
    return (
      <>
        <TaskPropertyButton
          onClick={() => setIsDialogOpen(true)}
          {...commonButtonProps}
        />
        <Dialog
          size="full-width"
          isOpen={isDialogOpen}
          className="px-4 pt-8 pb-10"
          onClose={() => setIsDialogOpen(false)}
        >
          <TimeSpentPicker
            onClose={() => setIsDialogOpen(false)}
            {...commonTimeSpentPickerProps}
          />
        </Dialog>
      </>
    );
  }

  return (
    <Popover
      placement="bottom-start"
      className="p-6"
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          ref={triggerRef}
          as={TaskPropertyButton}
          {...commonButtonProps}
        />
      )}
    >
      {({ onClose }) => (
        <TimeSpentPicker onClose={onClose} {...commonTimeSpentPickerProps} />
      )}
    </Popover>
  );
};
