import { assignRefs, cx } from "@jugl-web/utils";
import { forwardRef, HTMLAttributes, ReactNode } from "react";
import { Tooltip } from "../../Tooltip";

export interface TaskPropertyButtonProps
  extends Omit<HTMLAttributes<HTMLButtonElement>, "disabled"> {
  children?: ReactNode;
  hint?: ReactNode;
  isDisabled?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
}

export const TaskPropertyButton = forwardRef<
  HTMLButtonElement,
  TaskPropertyButtonProps
>(
  (
    { children, hint, isDisabled, startIcon, endIcon, className, ...props },
    ref
  ) => (
    <Tooltip
      className="z-tooltip max-w-[224px] p-3"
      isDisabled={!hint}
      renderTrigger={({ ref: triggerRef, props: triggerProps }) => (
        <button
          ref={assignRefs([ref, triggerRef])}
          type="button"
          className={cx(
            "focus:outline-primary-300 bg-grey-200 text-dark-600 flex h-8 shrink-0 items-center gap-2 rounded border-none px-2 text-xs transition focus:outline focus:outline-1",
            "cursor-pointer hover:brightness-95 disabled:cursor-default disabled:hover:brightness-100",
            className
          )}
          {...props}
          // This has to be applied after the `props` spread as the HeadlessUI's Popover
          // injects its own `disabled` prop to the trigger
          disabled={isDisabled}
          {...triggerProps}
        >
          {startIcon && (
            <span className="shrink-0 leading-[0]">{startIcon}</span>
          )}
          {children && <span className="truncate">{children}</span>}
          {endIcon && <span className="shrink-0 leading-[0]">{endIcon}</span>}
        </button>
      )}
    >
      {hint}
    </Tooltip>
  )
);
