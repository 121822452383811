import {
  Fab,
  MultiSectionLayout,
} from "@jugl-web/ui-components/cross-platform";
import { HeaderBreadcrumbs } from "@jugl-web/ui-components/web";
import { useIntercom } from "@web-src/components/IntercomProvider";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { FC, useMemo } from "react";
import { useTranslations } from "@jugl-web/utils";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { SelectSubscriptionForm } from "../../components/SelectSubscriptionForm";
import { ReactComponent as HelpIcon } from "./assets/help.svg";
import { ReactComponent as JuglLogo } from "./assets/jugl-logo.svg";
import { NoSubscriptionHeaderMenu } from "../../components/NoSubscriptionHeaderMenu";

export const SelectSubscriptionPage: FC = () => {
  const { t } = useTranslations();
  const { subscriptionInfo } = useEntitySelectedProvider();
  const { navigateToPage } = useNavigation();
  const { toggle: toggleIntercomWidget } = useIntercom();
  const isSubscriptionExpired = subscriptionInfo?.planIsActive === false;
  const headerBreadcrumbsItems = useMemo(() => {
    if (isSubscriptionExpired) {
      return [
        {
          title: t({
            id: "workspace-settings-page.workspace-subscription",
            defaultMessage: "Workspace Subscription",
          }),
          onClick: () => navigateToPage("workspaceSubscription"),
        },
        {
          title: t({
            id: "workspace-settings-page.update-subscription",
            defaultMessage: "Update Subscription",
          }),
        },
      ];
    }
    return [
      {
        title: "Workspace Settings",
        onClick: () => navigateToPage("workspaceSettings"),
      },
      {
        title: "Subscription",
        onClick: () => navigateToPage("workspaceSubscription"),
      },
      { title: "Update Subscription" },
    ];
  }, [isSubscriptionExpired, navigateToPage, t]);
  return (
    <MultiSectionLayout
      header={
        <HeaderBreadcrumbs
          icon={isSubscriptionExpired ? <JuglLogo /> : undefined}
          items={headerBreadcrumbsItems}
          rightContent={
            isSubscriptionExpired ? <NoSubscriptionHeaderMenu /> : undefined
          }
        />
      }
      className="relative bg-[#F9F9F9]"
    >
      <SelectSubscriptionForm />
      <Fab
        icon={<HelpIcon />}
        variant="primary"
        size="sm"
        className="absolute right-10 bottom-10"
        onClick={toggleIntercomWidget}
      />
    </MultiSectionLayout>
  );
};
