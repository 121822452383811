import {
  Alert,
  Button,
  PhoneInput,
  TextField,
} from "@jugl-web/ui-components/cross-platform";
import { cx, useTranslations } from "@jugl-web/utils";
import React, { useMemo, useState } from "react";
import { isValidEmail } from "@jugl-web/utils/validators";
import Lottie from "react-lottie";
import LoginMethodPicker from "./components/LoginMethodPicker";
import PhoneMethodRadio from "./components/PhoneMethodRadio";
import { AuthStep, LoginMethod, PhoneMethod } from "../../types";
import { useLoginPage } from "../../providers/LoginPageProvider";
import WhatsappAnimation from "./assets/whatsapp.json";

const LoginForm: React.FC = () => {
  const {
    loginMethod,
    phoneMethod,
    email,
    phone,
    setAuthStep,
    setPhoneMethod,
    setEmail,
    setPhone,
    requestOtpVerification,
  } = useLoginPage();
  const [isEmailError, setIsEmailError] = useState(false);
  const [isPhoneError, setIsPhoneError] = useState(false);
  const [whatsappAlertIsOpen, setWhatsappAlertIsOpen] = useState(false);
  const { t } = useTranslations();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleLogin = async () => {
    try {
      await requestOtpVerification();
      setAuthStep(AuthStep.otp);
    } catch (error) {
      //
    }
  };

  const isError = useMemo(() => {
    if (loginMethod === LoginMethod.phone) {
      return !phone.isValid;
    }
    return !isValidEmail(email);
  }, [phone, email, loginMethod]);

  const phoneInputValue =
    phone.code && phone.iso
      ? { phone: phone.phone, code: phone.code, iso: phone.iso }
      : undefined;

  const subTitle = useMemo(() => {
    if (loginMethod === LoginMethod.email) {
      return t(
        {
          id: "login-page.enter-email-to-send-otp",
          defaultMessage:
            "Enter your <emailText>email</emailText>, we'll send you a code for Log In",
        },
        {
          emailText: (text: (string | JSX.Element)[]) => (
            <span className="text-primary-800">{text}</span>
          ),
        }
      );
    }
    return t(
      {
        id: "login-page.enter-phone-to-send-otp",
        defaultMessage:
          "Enter your <phoneText>phone number</phoneText>, we'll send you a code for Log In",
      },
      {
        phoneText: (text: (string | JSX.Element)[]) => (
          <span className="text-primary-800">{text}</span>
        ),
      }
    );
  }, [loginMethod, t]);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsFormSubmitted(true);
    if (isError) {
      return;
    }
    if (phoneMethod === PhoneMethod.whatsapp) {
      setWhatsappAlertIsOpen(true);
    } else {
      handleLogin();
    }
  };

  return (
    <>
      <form
        className="flex h-full grow flex-col gap-14"
        onSubmit={handleFormSubmit}
      >
        <div className="flex flex-col gap-5 text-center">
          <span className="text-dark font-secondary text-4xl font-medium">
            {t({
              id: "common.welcome",
              defaultMessage: "Welcome",
            })}
            !
          </span>
          <span className="text-grey-800 text-sm">{subTitle}</span>
        </div>
        {loginMethod === LoginMethod.email ? (
          <TextField
            placeholder={t({
              id: "login-page.enter-email",
              defaultMessage: "Enter Email",
            })}
            isFullWidth
            variant="underline"
            value={email}
            errorMessage={
              isFormSubmitted && isEmailError
                ? t({
                    id: "login-page.invalid-email",
                    defaultMessage: "Invalid email",
                  })
                : undefined
            }
            onChange={(e) => {
              setIsEmailError(!isValidEmail(e.target.value));
              setEmail(e.target.value);
            }}
          />
        ) : (
          <div className="flex flex-col gap-5">
            <div className="flex flex-col">
              <div
                className={cx(
                  "w-full rounded-md border border-solid py-1",
                  isFormSubmitted && isPhoneError
                    ? "border-gradients-danger"
                    : "border-gray-300"
                )}
              >
                <PhoneInput
                  value={phoneInputValue}
                  placeholder={t({
                    id: "form-controls.mobile-number.placeholder",
                    defaultMessage: "Mobile Number",
                  })}
                  onChange={(phoneNumber) => {
                    setIsPhoneError(!phoneNumber.isValid);
                    setPhone(phoneNumber);
                  }}
                />
              </div>
              {isFormSubmitted && isPhoneError && (
                <span className="text-gradients-danger mt-1.5 text-sm leading-[140%] tracking-[0.14px]">
                  {t({
                    id: "login-page.invalid-phone-number",
                    defaultMessage: "Invalid phone number",
                  })}
                </span>
              )}
            </div>
            <PhoneMethodRadio
              checked={phoneMethod === PhoneMethod.sms}
              onChange={() => setPhoneMethod(PhoneMethod.sms)}
              title={t({
                id: "login-page.receive-otp-sms",
                defaultMessage: "Receive OTP by SMS",
              })}
            />
            <PhoneMethodRadio
              checked={phoneMethod === PhoneMethod.whatsapp}
              onChange={() => setPhoneMethod(PhoneMethod.whatsapp)}
              title={t({
                id: "login-page.receive-otp-whatsapp",
                defaultMessage: "Receive OTP by WhatsApp",
              })}
            />
          </div>
        )}
        <Button fullWidth type="submit">
          {t({
            id: "common.continue",
            defaultMessage: "Continue",
          })}
        </Button>
        <div className="mt-auto">
          <LoginMethodPicker />
        </div>
      </form>
      <Alert
        isOpen={whatsappAlertIsOpen}
        onRequestClose={() => setWhatsappAlertIsOpen(false)}
        header=" "
        img={
          <Lottie
            options={{
              animationData: WhatsappAnimation,
            }}
            height={150}
            width={150}
          />
        }
        title={t({
          id: "login-page.confirm-access",
          defaultMessage: "Confirm Access",
        })}
        content={t({
          id: "login-page.jugl-requires-whatsapp-permission",
          defaultMessage:
            "Jugl requires Your permission to send you OTP by WhatsApp",
        })}
        buttons={[
          {
            text: t({
              id: "common.confirm",
              defaultMessage: "Confirm",
            }),
            onClick: () => {
              setWhatsappAlertIsOpen(false);
              handleLogin();
            },
          },
        ]}
      />
    </>
  );
};

export default LoginForm;
