import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { cx } from "@jugl-web/utils";
import getBlobDuration from "get-blob-duration";
import { ReactComponent as PlayIcon } from "./assets/play.svg";
import { ReactComponent as PauseIcon } from "./assets/pause.svg";

export const CommonAudioPlayer: React.FC<{
  children: React.ReactNode;
  url?: string;
}> = ({ children, url }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isReady, setIsReady] = React.useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const playAnimationRef = useRef<number>();

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current?.pause();
      setIsPlaying(false);
    } else {
      audioRef.current?.play();
      setIsPlaying(true);
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(time % 60);
    const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${formatMinutes}:${formatSeconds}`;
  };

  const handleProgressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (audioRef.current) {
      audioRef.current.currentTime = e.currentTarget.valueAsNumber;
    }
  };

  const setAudioDuration = async () => {
    if (typeof url === "string") {
      const audioDuration = await getBlobDuration(url);
      setDuration(audioDuration);
    }
  };

  const updateCurrentTimeAnimation = useCallback(() => {
    if (!audioRef.current) {
      playAnimationRef.current = requestAnimationFrame(
        updateCurrentTimeAnimation
      );
      return;
    }
    const progressTime = audioRef.current.currentTime;
    setCurrentTime(progressTime);

    playAnimationRef.current = requestAnimationFrame(
      updateCurrentTimeAnimation
    );
  }, []);

  useEffect(() => {
    if (isPlaying) {
      playAnimationRef.current = requestAnimationFrame(
        updateCurrentTimeAnimation
      );
    } else if (playAnimationRef.current) {
      cancelAnimationFrame(playAnimationRef.current);
    }
  }, [isPlaying, updateCurrentTimeAnimation]);

  return (
    <div className="flex items-center gap-2">
      {url && (
        <audio
          hidden
          onEnded={() => setIsPlaying(false)}
          ref={audioRef}
          onCanPlay={async () => {
            await setAudioDuration();
            setIsReady(true);
          }}
        >
          <source src={url} />
        </audio>
      )}
      {isReady ? (
        <>
          <InteractiveContainer
            className="bg-primary flex h-12 w-12 shrink-0 items-center justify-center rounded-full"
            onClick={togglePlayPause}
            isDisabled={!isReady}
          >
            {isPlaying ? <PauseIcon /> : <PlayIcon />}
          </InteractiveContainer>
          <div className="flex grow flex-col gap-2 overflow-hidden">
            {children}
            <div className="flex h-5 items-center gap-2">
              <span className="text-grey shrink-0 text-xs font-medium">
                {formatTime(isPlaying ? currentTime : duration)}
              </span>
              <input
                className={cx("jugl__range-slider", !isPlaying && "hidden")}
                type="range"
                min={0}
                step="0.0001"
                max={duration}
                value={currentTime}
                onChange={handleProgressChange}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="w-full">
          <div className="flex h-full animate-pulse items-center gap-4">
            <div className="h-10 w-10 rounded-full bg-gray-300" />
            <div className="flex grow flex-col gap-2">
              <div className="h-3 w-3/4 rounded-md bg-gray-300 " />
              <div className="h-3 w-1/2 rounded-md bg-gray-300 " />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
