import React, { useContext, useMemo, useEffect } from "react";
import { useTranslations, cx } from "@jugl-web/utils";
import {
  PlainButton,
  Text,
  Button,
} from "@jugl-web/ui-components/cross-platform";
import { useMe } from "@web-src/features/app/hooks/useMe";
import UserProfileName from "@web-src/features/users/components/UserProfileName";
import { isPast } from "date-fns";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { ReactComponent as LogoIcon } from "./assets/logo.svg";
import { ReactComponent as CloseIcon } from "./assets/close.svg";
import ControlPanel from "../../../ControlPanel";
import { CallsContext } from "../../../../../../providers";
import { CallAvatar } from "../../../CallAvatar";
import { useSpeakingState } from "../../hooks/useIsSpeaking";
import { parseUserId } from "../../../../../../utils";
import { ScheludedConference } from "./components/ScheludedConference";

export const EnterScreen: React.FC = () => {
  const { navigateToPage } = useNavigation();
  const { t } = useTranslations();
  const { activeCall, leaveCall, joinCall, activeCallProps } =
    useContext(CallsContext);
  const isSpeaking = useSpeakingState(activeCall?.localAudioTrack);
  const { me } = useMe();
  const handleJoinConference = async () => {
    const channelId = activeCallProps?.channel || "";
    await leaveCall?.();
    await activeCall?.joinConferenceFromStaging();
    joinCall?.({
      channel: channelId,
      conferenceMessage: activeCallProps?.conferenceMessage,
    });
  };
  const isConferenceScheluded = useMemo(() => {
    if (!activeCallProps?.conferenceMessage?.date) return false;
    const date = new Date(
      `${activeCallProps?.conferenceMessage?.date} ${activeCallProps?.conferenceMessage?.time}`
    );
    const isPastDate = isPast(date);

    if (!isPastDate) {
      return true;
    }
    return false;
  }, [
    activeCallProps?.conferenceMessage?.date,
    activeCallProps?.conferenceMessage?.time,
  ]);
  useEffect(() => {
    if (activeCall?.localVideoTrack && activeCall.stage === "staging") {
      activeCall?.localVideoTrack.play("local-staging-video", { fit: "cover" });
    }
    return () => activeCall?.localVideoTrack?.stop();
  }, [
    activeCall?.localVideoTrack,
    activeCall?.videoEnabled,
    activeCall?.stage,
  ]);

  const handleClose = () => {
    window.close();
    navigateToPage("chatsChats");
    leaveCall?.();
  };
  const isEmpty = activeCall?.remoteUsers?.length === 0;
  const oneUser = activeCall?.remoteUsers?.length === 1;
  const firstUserId = parseUserId(activeCall?.remoteUsers?.[0]?.uid as string);
  const usersNumber = useMemo(() => {
    const users = activeCall?.remoteUsers;
    if (users && users.length >= 1) {
      return users.length - 1;
    }
    return users?.length || 0;
  }, [activeCall?.remoteUsers]);
  return (
    <div className="fixed top-0 left-0 z-10 h-full w-full bg-white">
      <div className="bg-grey-200 flex h-[80px] w-full flex-row items-center justify-between px-14">
        <span className="flex items-center gap-3">
          <LogoIcon />
          <Text variant="body3" className="text-dark-700">
            {t({
              id: "call-enter-screen.conference",
              defaultMessage: "Conference",
            })}
          </Text>
        </span>
        <PlainButton
          onClick={handleClose}
          className="bg-dark/20 hover:bg-dark/30 h-12 w-12 cursor-pointer rounded-full"
        >
          <CloseIcon />
        </PlainButton>
      </div>
      {isConferenceScheluded && <ScheludedConference onClose={handleClose} />}
      <div
        className={cx(
          "hxs:scale-[0.7] hsm:scale-[0.8] flex h-full w-full flex-row items-center justify-center gap-[100px] 2xl:mt-[-100px]",
          {
            hidden: isConferenceScheluded,
          }
        )}
      >
        <div className="flex flex-col gap-8">
          <div className="relative flex h-[356px] w-[720px] items-center justify-center overflow-hidden rounded-lg bg-black/50">
            {!activeCall?.videoEnabled && (
              <CallAvatar
                isSpeaking={(isSpeaking && activeCall?.audioEnabled) || false}
                size={100}
                userId={me?.id || ""}
              />
            )}
            {activeCall?.videoEnabled && (
              <div
                className="absolute h-full w-full"
                id="local-staging-video"
              />
            )}
          </div>
          <div className="flex flex-row justify-center gap-12">
            {activeCall && (
              <ControlPanel
                toggleVideo={activeCall.toggleVideo}
                toggleMic={activeCall.toggleAudio}
                toggleScreenShare={() => {}}
                toggleMinimize={() => {}}
                endCall={() => {}}
                isVideoOn={activeCall?.videoEnabled || false}
                isMicOn={activeCall?.audioEnabled || false}
                isScreenShared={false}
                isMinimized={false}
                optionsToHide={[
                  "toggleMinimize",
                  "toggleScreenShare",
                  "endCall",
                ]}
                isOnBg="bg-primary"
                buttonTextVariant="dark"
                primaryButtons
              />
            )}
          </div>
        </div>

        <div className="flex h-full flex-col items-center justify-center sm:mt-[-100px] ">
          <Text variant="h3" className="text-dark">
            {activeCallProps?.conferenceMessage?.name ||
              t({
                id: "chats-page.join-video-meeting",
                defaultMessage: "Join video meeting",
              })}
          </Text>
          <Text variant="body2" className="text-grey-700">
            {isEmpty &&
              t({
                id: "call-enter-screen.empty-group-call",
                defaultMessage: "No one else is here",
              })}
            {!isEmpty &&
              oneUser &&
              t(
                {
                  id: "call-enter-screen.user-already-here",
                  defaultMessage: "{user} is already here",
                },
                {
                  user: (
                    <span className="text-primary font-semibold">
                      <UserProfileName userId={firstUserId} />
                    </span>
                  ),
                  number: usersNumber,
                }
              )}
            {!isEmpty &&
              !oneUser &&
              t(
                {
                  id: "call-enter-screen.users-already-here",
                  defaultMessage: "{user} and +{number} is already here",
                },
                {
                  user: (
                    <span className="text-primary font-semibold">
                      <UserProfileName userId={firstUserId} />
                    </span>
                  ),
                  number: usersNumber,
                }
              )}
          </Text>
          <Button
            className="mt-[72px] w-[343px] uppercase"
            onClick={handleJoinConference}
          >
            {t({ id: "call-enter-screen.join", defaultMessage: "Join" })}
          </Button>
        </div>
      </div>
    </div>
  );
};
