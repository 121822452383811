import { cx } from "@jugl-web/utils";
import {
  FC,
  forwardRef,
  ForwardRefExoticComponent,
  HTMLAttributes,
  ReactNode,
  RefAttributes,
} from "react";

export const CalendarViewLayout = () => {};

interface ColumnProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

CalendarViewLayout.Column = forwardRef<HTMLDivElement, ColumnProps>(
  ({ children, className, ...props }, ref) => (
    <div
      ref={ref}
      className={cx("flex h-full flex-1 flex-col gap-1", className)}
      {...props}
    >
      {children}
    </div>
  )
) as ForwardRefExoticComponent<ColumnProps & RefAttributes<HTMLDivElement>> & {
  Header: FC<ColumnHeaderProps>;
  List: ForwardRefExoticComponent<
    ColumnListProps & RefAttributes<HTMLDivElement>
  >;
};

interface ColumnHeaderProps {
  children: ReactNode;
  className?: string;
}

CalendarViewLayout.ColumnHeader = (({ children, className }) => (
  <div
    className={cx(
      "flex h-[50px] w-full shrink-0 items-center justify-center bg-white",
      className
    )}
  >
    {children}
  </div>
)) as FC<ColumnHeaderProps>;

interface ColumnListProps {
  children: ReactNode;
  isDragging: boolean;
  isOver: boolean;
  canDrop: boolean;
  className?: string;
}

CalendarViewLayout.ColumnList = forwardRef<HTMLDivElement, ColumnListProps>(
  ({ children, isDragging, isOver, canDrop, className }, ref) => (
    <div
      ref={ref}
      className={cx(
        "jugl__custom-scrollbar relative flex h-full max-h-full flex-col gap-6 py-6 px-3 transition-colors",
        isDragging ? "overflow-hidden" : "overflow-y-auto",
        isOver ? (canDrop ? "bg-[#C3D2F8]" : "bg-tertiary-100") : "bg-white",
        className
      )}
    >
      {children}
    </div>
  )
);

interface EmptyStateMessageProps {
  children: ReactNode;
  className?: string;
}

CalendarViewLayout.EmptyStateMessage = (({ children, className }) => (
  <div className={cx("text-center", className)}>
    <span className="font-secondary text-grey leading-2 text-xs">
      {children}
    </span>
  </div>
)) as FC<EmptyStateMessageProps>;
