import { useTaskFiltersStateContext } from "@jugl-web/domain-resources/tasks/hooks/useTaskFiltersState";
import { useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import { FilterSectionLayout } from "../FilterSectionLayout";
import { OverdueFilterPill } from "../OverdueFilterPill";

export const TypeFilterSection: FC = () => {
  const { filtersState, updateFiltersState } = useTaskFiltersStateContext();

  const { t } = useTranslations();

  return (
    <FilterSectionLayout>
      <FilterSectionLayout.Header
        title={t({
          id: "tasks-page.by-task-type-filter",
          defaultMessage: "By Task type",
        })}
      />
      <FilterSectionLayout.PillsContainer>
        <OverdueFilterPill
          isChecked={!!filtersState.isOverdue}
          onClick={() =>
            updateFiltersState("isOverdue", (previousIsOverdue) =>
              previousIsOverdue ? null : true
            )
          }
        />
      </FilterSectionLayout.PillsContainer>
    </FilterSectionLayout>
  );
};
