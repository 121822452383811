import { useTranslations } from "@jugl-web/utils";
import { FC } from "react";

export const NoInfoCell: FC = () => {
  const { t } = useTranslations();
  return (
    <span className="text-grey-700 text-sm">
      {t({
        id: "cpanel-page.not-registered",
        defaultMessage: "No info",
      })}
    </span>
  );
};
