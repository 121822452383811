import { Button } from "@jugl-web/ui-components/cross-platform";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { SidebarDrawer } from "@jugl-web/ui-components/web";
import { useTranslations } from "@jugl-web/utils";
import { ChatListPicker } from "@web-src/components/ChatListPicker";
import useConversations from "@web-src/features/chats/hooks/useConversations";
import { ChatItem, ChatType } from "@web-src/features/chats/types";
import { NotificationCategory } from "@web-src/modules/settings/pages/SettingsPage/types";
import { useNotifications } from "@web-src/modules/notifications/providers/NotificationsProvider";
import { useUserEntityPreferences } from "@web-src/modules/preferences/hooks/useUserEntityPreferences";
import React, { useCallback, useEffect, useMemo, useState } from "react";

export const AddMutedChatsSidebar: React.FC<{
  isOpen: boolean;
  onRequestClose: () => void;
}> = ({ isOpen, onRequestClose }) => {
  const { t } = useTranslations();
  const [selectedChats, setSelectedChats] = useState<ChatItem[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { conversations } = useConversations();
  const { getChatNotificationsPreferences } = useNotifications();
  const { updatePreferences } = useUserEntityPreferences();

  const visibleChats = useMemo(() => {
    let result = conversations
      .filter(
        (item) =>
          item.id &&
          getChatNotificationsPreferences(item.id) === "none" &&
          !item.data.isSelf
      )
      .map((item) => item.data);
    if (searchQuery) {
      result = result.filter((item) =>
        item.title?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    return result;
  }, [conversations, searchQuery, getChatNotificationsPreferences]);

  const [saveInProgress, setSaveInProgress] = useState(false);

  const handleSaveClick = useCallback(async () => {
    try {
      setSaveInProgress(true);
      const objectToSave: NotificationCategory = {};
      selectedChats.forEach((item) => {
        let categoryKey: "chats" | "groups" = "chats";
        if (item.type === ChatType.muc) {
          categoryKey = "groups";
        }
        if (!objectToSave[categoryKey]?.except) {
          objectToSave[categoryKey] = {
            except: { [item.id || ""]: { mute: "all" } },
          };
        } else {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          objectToSave[categoryKey]!.except![item.id || ""] = { mute: "all" };
        }
      }, []);
      await updatePreferences({ notification_pref: objectToSave });
      onRequestClose();
    } finally {
      setSaveInProgress(false);
    }
  }, [selectedChats, updatePreferences, onRequestClose]);

  useEffect(() => {
    setSelectedChats([]);
  }, [isOpen]);

  return (
    <SidebarDrawer
      hasBackdrop={false}
      isOpen={isOpen}
      onClose={onRequestClose}
      onGoBack={onRequestClose}
      title={t({
        id: "settings-page.select-chats-to-mute",
        defaultMessage: "Select Chats to Mute",
      })}
    >
      <SidebarDrawer.Content>
        <div className="flex min-h-full flex-col">
          <div className="p-8">
            <SearchInput
              color="primary"
              variant="filled"
              onChange={(e) => setSearchQuery(e.currentTarget.value)}
              value={searchQuery}
            />
          </div>
          {visibleChats.length ? (
            <ChatListPicker
              chats={visibleChats}
              value={selectedChats}
              onSelect={setSelectedChats}
            />
          ) : (
            <div className="flex flex-1 items-center justify-center text-center text-sm text-gray-500">
              {t({
                id: "settings-page.no-results",
                defaultMessage: "No results",
              })}
            </div>
          )}
        </div>
      </SidebarDrawer.Content>
      <SidebarDrawer.Actions>
        <Button
          fullWidth
          isDisabled={!selectedChats?.length || saveInProgress}
          onClick={handleSaveClick}
        >
          {selectedChats?.length
            ? t(
                {
                  id: "settings-page.mute-chats-count",
                  defaultMessage:
                    "Mute {count} {count, plural, one {chat} other {chats}}",
                },
                {
                  count: selectedChats.length,
                }
              )
            : t({
                id: "settings-page.mute",
                defaultMessage: "Mute",
              })}
        </Button>
      </SidebarDrawer.Actions>
    </SidebarDrawer>
  );
};
