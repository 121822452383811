import { FilePreviewType } from "./types";

export const inferTypeFromMimeType = (mimeType: string): FilePreviewType => {
  if (mimeType.startsWith("image")) {
    return "image";
  }
  if (mimeType.startsWith("video")) {
    return "video";
  }

  if (mimeType.startsWith("audio")) {
    return "audio";
  }

  return "other";
};
