import {
  InteractiveContainer,
  InteractiveContainerProps,
  Menu,
  MenuConfig,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { cx } from "@jugl-web/utils";
import { CSSProperties, FC, HTMLAttributes, ReactNode } from "react";
import { ReactComponent as MenuIcon } from "../../assets/menu.svg";

type GradientColor = "primary" | "tertiary";

interface TaskTemplateCardContainerProps
  extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  gradientColor: GradientColor;
  avatarSlot: ReactNode;
  menuConfig?: MenuConfig;
  className?: string;
}

const gradientColorToBgStyles: Record<
  GradientColor,
  CSSProperties["background"]
> = {
  primary: "linear-gradient(91deg, #E3F2FD 14.43%, #EDE3FD 100%)",
  tertiary: "linear-gradient(91deg, #FDE3F3 14.43%, #FDE3E3 100%)",
};

export const TaskTemplateCardContainer: FC<TaskTemplateCardContainerProps> = ({
  children,
  gradientColor,
  avatarSlot,
  menuConfig,
  className,
  onClick,
  ...props
}) => (
  <InteractiveContainer
    className={cx(
      "group h-[221px] w-[342px] overflow-hidden rounded-2xl bg-white p-0.5",
      "shadow-[0_1px_4px_0_rgba(0,0,0,0.12)] transition-shadow hover:shadow-[0_2px_12px_0_rgba(0,0,0,0.18)]",
      className
    )}
    onClick={onClick as InteractiveContainerProps["onClick"]}
    {...props}
  >
    <div
      style={{ background: gradientColorToBgStyles[gradientColor] }}
      className="relative flex h-[38px] items-center justify-end rounded-t-2xl px-2"
    >
      <div className="absolute left-[14px] bottom-0 box-content h-10 w-10 translate-y-1/2 overflow-hidden rounded-full border-4 border-solid border-white bg-white">
        {avatarSlot}
      </div>
      {menuConfig && (
        <Menu
          placement="bottom-start"
          renderTrigger={({ Trigger, triggerRef, isOpen }) => (
            <Trigger
              ref={triggerRef}
              as={PlainButton}
              className={cx(
                "h-7 w-7 rounded-lg transition",
                isOpen
                  ? "bg-white/50 opacity-100"
                  : "opacity-0 hover:bg-white/50 group-hover:opacity-100"
              )}
              onClick={(event) => event.stopPropagation()}
            >
              <MenuIcon />
            </Trigger>
          )}
          {...menuConfig}
        />
      )}
    </div>
    <div className="px-[14px] pt-[38px] pb-[26px]">{children}</div>
  </InteractiveContainer>
);
