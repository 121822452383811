import { UserLoginActivity } from "@jugl-web/rest-api";
import { useTranslations } from "@jugl-web/utils";
import React from "react";
import { AuthModeIcon } from "../../../AuthModeIcon";

export const CpanelLastActivityElement: React.FC<{
  activity: UserLoginActivity;
}> = ({ activity: { auth_mode: authMode, time, device } }) => {
  const { t } = useTranslations();
  return (
    <div className="bg-grey-200 flex w-full flex-col gap-2 rounded-xl p-8">
      <span className="text-grey text-xs">{time}</span>
      <div className="flex items-center gap-1">
        <span className="text-grey">
          {t({
            id: "cpanel-page.authentication-method",
            defaultMessage: "Authentication method",
          })}
          :
        </span>{" "}
        <span className="inline-flex items-center gap-1">
          <AuthModeIcon authMode={authMode} /> {authMode}
        </span>
      </div>
      <div>
        <span className="text-grey">
          {t({
            id: "cpanel-page.device",
            defaultMessage: "Device",
          })}
          :
        </span>{" "}
        <span>{device}</span>
      </div>
    </div>
  );
};
