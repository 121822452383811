import { ChatMessagePayloadAttachment } from "@web-src/features/chats/types";
import React, { useRef, useState } from "react";
import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import { cx } from "@jugl-web/utils";
import { getChatMessageAttachmentUrls } from "@web-src/modules/chats/utils/getChatMessageAttachmentUrls";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { ReactComponent as VideoIcon } from "./assets/video.svg";

export const VideoAttachmentItem: React.FC<{
  attachment: ChatMessagePayloadAttachment;
  onClick?: () => void;
}> = ({ attachment, onClick }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoDuration, setVideoDuration] = useState<number>();

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      setVideoDuration(videoRef.current.duration);
    }
  };

  const formatTime = (seconds?: number) => {
    if (!seconds) return "";
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  };
  const { entity } = useEntity();
  if (!entity?.id) {
    throw new Error("Selected entity is required");
  }

  const { preview, stream } = getChatMessageAttachmentUrls(
    entity?.id,
    attachment
  );

  return (
    <div className="relative">
      <InteractiveContainer
        className={cx(onClick && "hover:brightness-75")}
        onClick={onClick}
        isDisabled={!onClick}
      >
        <video
          ref={videoRef}
          src={stream}
          onLoadedMetadata={handleLoadedMetadata}
          poster={preview}
          className="bg-grey object-cove relative aspect-square w-full bg-gradient-to-b from-[#CECED3] to-[#ECEFF0]"
        />
        <div className="absolute top-0 left-0 h-full w-full bg-gradient-to-b from-transparent via-transparent to-[#1A2023_130%]" />
      </InteractiveContainer>
      <div className="absolute left-1 bottom-1 flex items-center gap-1">
        <VideoIcon />
        <span className="text-[13px] font-medium text-white">
          {formatTime(videoDuration)}
        </span>
      </div>
    </div>
  );
};
