import React from "react";
import { SideImageLayout } from "@jugl-web/ui-components/web/layouts";
import { useTranslations } from "@jugl-web/utils";
import { useUserAuth } from "@web-src/modules/auth/providers/UserAuthProvider";
import Lottie from "react-lottie";
import { Button } from "@jugl-web/ui-components/cross-platform";
import ProfileNotCreatedAnimation from "./assets/profile-not-created.json";

const ProfileInfoSection: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => {
  const { logout } = useUserAuth();
  const { t } = useTranslations();

  return (
    <SideImageLayout onBackClick={() => logout(true)}>
      <div className="flex flex-col gap-6 text-center">
        <Lottie
          options={{
            animationData: ProfileNotCreatedAnimation,
          }}
          height={200}
          width={200}
        />
        <span className="text-dark text-[32px] font-semibold">
          {t({
            id: "profile-form-page.profile-not-created",
            defaultMessage: "Profile not created",
          })}
        </span>
        <span>
          {t(
            {
              id: "profile-form-page.please-create-profile-to-proceed",
              defaultMessage:
                "Please <createProfileText>Create Profile</createProfileText> to proceed",
            },
            {
              createProfileText: (text: (string | JSX.Element)[]) => (
                <span className="text-primary-800 font-semibold">{text}</span>
              ),
            }
          )}
        </span>
        <Button className="mt-10" onClick={onClick}>
          {t({
            id: "profile-form-page.create-profile",
            defaultMessage: "Create Profile",
          })}
        </Button>
      </div>
    </SideImageLayout>
  );
};

export default ProfileInfoSection;
