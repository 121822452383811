import orderBy from "lodash/orderBy";
import { RtkEmptySplitApi } from "../../types";
import { FileType, Directory, DirectoryList, PermissionsMode } from "./types";
import { DriveApiTags } from "./tags";

export const addDriveApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [DriveApiTags.driveDirectoryContents],
  });
  const driveAPI = apiWithTags.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
      downloadFileChunk: build.mutation<
        string,
        {
          entityId: string;
          id: string;
          range: number[];
        }
      >({
        query: ({ entityId, ...params }) => ({
          url: `/api/auth/drive/entity/${entityId}/download`,
          method: "GET",
          params,
        }),
      }),
      downloadGuestFileChunk: build.mutation<
        string,
        {
          entityId: string;
          id: string;
          range: number[];
        }
      >({
        query: ({ entityId, ...params }) => ({
          url: `/api/auth/drive/entity/${entityId}/guest/download`,
          method: "GET",
          params,
        }),
      }),
      downloadAttachmentChunk: build.mutation<
        string,
        { params: { range: [number, number]; path: string } }
      >({
        query: ({ params }) => ({
          url: `/api/auth/attachment/download`,
          params,
        }),
      }),
      getDirectoryContents: build.query<
        DirectoryList,
        {
          entityId: string;
          parent_id?: string;
        }
      >({
        query: ({ entityId, ...params }) => ({
          url: `/api/auth/drive/entity/${entityId}/list`,
          params,
          silentError: true,
        }),
        transformResponse: (response: DirectoryList) => {
          response.data = orderBy(
            orderBy(
              response.data.filter((item) => item.status === "valid"),
              (item) => item.name?.toLocaleLowerCase()
            ),
            (item) => (item.type === FileType.dir ? -1 : 1)
          );
          return response;
        },
        providesTags: (e1, e2, { parent_id: parentDirectory }) => [
          DriveApiTags.driveDirectoryContents,
          {
            type: DriveApiTags.driveDirectoryContents,
            id: parentDirectory,
          },
        ],
      }),
      createDirectory: build.mutation<
        {
          data: Directory;
        },
        {
          entity_id: string;
          data: {
            name: string;
            parent_dir_id?: string;
            workspace_id?: string;
            mode: keyof typeof PermissionsMode;
            users?: string[];
          };
        }
      >({
        query: ({ data, entity_id }) => ({
          url: `/api/auth/drive/entity/${entity_id}/directory`,
          method: "POST",
          data,
          silentError: true,
        }),
        invalidatesTags: (
          e1,
          e2,
          { data: { parent_dir_id: parentDirectory } }
        ) => [
          {
            type: DriveApiTags.driveDirectoryContents,
            id: parentDirectory,
          },
          {
            type: DriveApiTags.driveDirectoryContents,
          },
        ],
      }),
      editDirectory: build.mutation<
        {
          entity_id: string;
          status: "valid" | "invalid";
          type: "dir";
          updated_at: string;
          workspade_id: string;
          id: string;
          name: string;
        },
        {
          entity_id: string;
          id: string;
          mode: keyof typeof PermissionsMode;
          name: string;
          users: string[];
          parent_dir_id: string;
          workspace_id?: string;
        }
      >({
        query: ({ entity_id, parent_dir_id, workspace_id, ...data }) => ({
          url: `/api/auth/drive/entity/${entity_id}/edit`,
          method: "PUT",
          data,
        }),
        invalidatesTags: [DriveApiTags.driveDirectoryContents],
      }),
      renameFile: build.mutation<
        {
          entity_id: string;
          id: string;
          name: string;
          status: "valid" | "invalid";
          type: FileType;
          updated_at: string;
          workspade_id?: string;
        },
        {
          entity_id: string;
          id: string;
          name: string;
          parent_dir_id?: string;
          workspace_id?: string;
        }
      >({
        query: ({ entity_id, parent_dir_id, workspace_id, ...data }) => ({
          url: `/api/auth/drive/entity/${entity_id}/rename`,
          method: "PUT",
          data,
        }),
        invalidatesTags: (e1, e2, { parent_dir_id: parentDirectory }) => [
          {
            type: DriveApiTags.driveDirectoryContents,
            id: parentDirectory,
          },
        ],
      }),
      deleteContent: build.mutation<
        void,
        {
          entity_id: string;
          id: string;
        }
      >({
        query: ({ entity_id, id }) => ({
          url: `/api/auth/drive/entity/${entity_id}/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: () => [DriveApiTags.driveDirectoryContents],
      }),
      moveFile: build.mutation<
        {
          entity_id: string;
          id: string;
          name: string;
          status: "invalid" | "valid";
          type: FileType;
          updated_at: string;
          workspace_id: string | null;
        },
        {
          id: string;
          entityId: string;
          parent_id: string;
          name: string;
          workspace_id?: string;
        }
      >({
        query: ({ entityId, ...data }) => ({
          url: `/api/auth/drive/entity/${entityId}/move`,
          method: "PUT",
          data,
        }),
        invalidatesTags: [DriveApiTags.driveDirectoryContents],
      }),
    }),
  });
  return driveAPI;
};

export type DriveApi = ReturnType<typeof addDriveApi>;
