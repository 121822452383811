import React, { useMemo, useState } from "react";
import SideBar from "@web-src/components/SideBar";
import { ChatMessage } from "@web-src/features/chats/types";
import UserProfileName from "@web-src/features/users/components/UserProfileName";
import { useTranslations } from "@jugl-web/utils";
import ReactionTile from "./ReactionTile";
import { getMessageReactionsSummary } from "../ChatMessageBubble/utils";
import ChatMessageBubble from "../ChatMessageBubble";
import { ChatMessageProvider } from "../../providers/ChatMessageProvider";

const MessageReactionsSideBar: React.FC<{
  message: ChatMessage;
  isOpen: boolean;
  onRequestClose: () => void;
}> = ({ isOpen, onRequestClose, message }) => {
  const [selectedReaction, setSelectedReaction] = useState<string>();
  const sortedReactions = useMemo(
    () => getMessageReactionsSummary(message),
    [message]
  );
  const { t } = useTranslations();

  const visibleReactions = useMemo(
    () =>
      message.reactions?.filter(
        (item) => !selectedReaction || selectedReaction === item.reaction
      ),
    [message, selectedReaction]
  );

  return (
    <SideBar
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      title={t({
        id: "chats-page.message-reactions",
        defaultMessage: "Message reactions",
      })}
    >
      <div className="w-full">
        <div className="flex w-full max-w-full justify-center px-[16px] pt-5">
          <ChatMessageProvider message={message}>
            {message && <ChatMessageBubble message={message} preview />}
          </ChatMessageProvider>
        </div>
      </div>
      <div className="flex w-full gap-2 overflow-x-auto p-5">
        <ReactionTile
          isSelected={selectedReaction === undefined}
          count={message.reactions?.length}
          onClick={setSelectedReaction}
        />
        {sortedReactions.map((item) => (
          <ReactionTile
            isSelected={selectedReaction === item.reaction}
            title={item.reaction}
            count={item.count}
            onClick={setSelectedReaction}
          />
        ))}
      </div>
      <div className="mb-3 h-[4px] w-full bg-gray-100" />
      <div>
        {visibleReactions?.map((item) => (
          <div className="flex justify-between px-[32px] py-[8px]">
            <div className="text-[14px]">
              <UserProfileName userId={item.user_id} />
            </div>
            <div>{item.reaction}</div>
          </div>
        ))}
      </div>
    </SideBar>
  );
};

export default MessageReactionsSideBar;
