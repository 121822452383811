import { useCallback, useContext } from "react";
import {
  ChatMessage,
  ChatType,
  PheonixPushAction,
} from "@web-src/features/chats/types";
import { PhoenixSocketContext } from "@web-src/features/chats/providers/PheonixSocket";
import { useDispatch } from "react-redux";
import { useUpdateChatMessages } from "@web-src/modules/chats/hooks/useUpdateChatMessages";
import { setMessageReadSent } from "../chatsSlice";

interface ReadReceiptPayload {
  type: "read";
  msg_receipt_id: string;
  chat_id?: string;
  chat_type?: string;
  msg_id: string;
  msg_timestamp: string;
}

const useSendMessageReadReceipt = () => {
  const { channel } = useContext(PhoenixSocketContext);
  const dispatch = useDispatch();
  const { addOrUpdateMessage } = useUpdateChatMessages();

  const sendMessageReceipt = useCallback(
    ({
      receiptId,
      msgId,
      chatId,
      bulk = false,
      chatType,
      msgTimestamp,
    }: {
      receiptId: string;
      msgId: string;
      msgTimestamp: string;
      chatId: string;
      bulk?: boolean;
      chatType: ChatType;
    }) =>
      new Promise((resolve, reject) => {
        if (!channel) {
          reject(new Error("No channel"));
          return;
        }
        const payloadToSend: ReadReceiptPayload = {
          msg_receipt_id: receiptId,
          type: "read",
          chat_type: chatType,
          chat_id: chatId,
          msg_id: msgId,
          msg_timestamp: msgTimestamp,
        };
        const message = channel?.push(
          bulk ? PheonixPushAction.bulk_receipts : PheonixPushAction.receipts,
          payloadToSend
        );
        message.receive("ok", (e) => {
          const messageUpdatedAttributes: Partial<ChatMessage> = {
            is_read_by_self: true,
          };
          addOrUpdateMessage({
            chatId,
            item: {
              id: msgId,
              data: messageUpdatedAttributes,
            },
            merge: true,
            skipIfNotInitialized: true,
          });
          resolve(e);
        });
        message.receive("error", (error) => {
          dispatch(setMessageReadSent({ id: receiptId, read: false }));
          reject(error);
        });
        message.receive("timeout", (error) => {
          dispatch(setMessageReadSent({ id: receiptId, read: false }));
          reject(error);
        });
      }),
    [addOrUpdateMessage, channel, dispatch]
  );
  return sendMessageReceipt;
};

export default useSendMessageReadReceipt;
