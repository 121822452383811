import { customFieldTypeToIconDetailsMap } from "@jugl-web/domain-resources/tasks";
import { useTaskFields } from "@jugl-web/domain-resources/tasks/hooks/useTaskFields";
import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform/InteractiveContainer";
import { NoResults } from "@jugl-web/ui-components/cross-platform/ListBox";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { Switch } from "@jugl-web/ui-components/cross-platform/Switch";
import { useSearchInput, useTranslations } from "@jugl-web/utils";
import { TASKS_PAGE_LAYOUT_CONTENT_CONTAINER_ID } from "@web-src/components/TasksPageLayout";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { FC, useMemo } from "react";
import { useTaskTableColumns } from "../../hooks/useTaskTableColumns";

interface ManageColumnsPopoverContentProps {
  shouldScrollToAddedColumn: boolean;
}

export const ManageColumnsPopoverContent: FC<
  ManageColumnsPopoverContentProps
> = ({ shouldScrollToAddedColumn }) => {
  const { entityId } = useEntitySelectedProvider();
  const { customFieldColumnsMap, changeCustomFieldColumnsMap } =
    useTaskTableColumns();

  const { customFields } = useTaskFields({ entityId });
  const { searchQuery, inputProps, reset } = useSearchInput();

  const { t } = useTranslations();

  const filteredCustomFields = useMemo(
    () =>
      customFields.filter((customField) =>
        customField.name.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    [customFields, searchQuery]
  );

  const hasNoCustomFields = customFields.length === 0;

  const changeColumnVisibility = (
    customFieldId: string,
    isVisible: boolean
  ) => {
    changeCustomFieldColumnsMap({
      ...customFieldColumnsMap,
      [customFieldId]: isVisible,
    });

    if (isVisible && shouldScrollToAddedColumn) {
      const containerElement = document.getElementById(
        TASKS_PAGE_LAYOUT_CONTENT_CONTAINER_ID
      );

      if (containerElement) {
        window.setTimeout(() => {
          containerElement.scrollTo({ left: containerElement.scrollWidth });
        });
      }
    }
  };

  if (hasNoCustomFields) {
    return (
      <span className="font-secondary text-base leading-4 text-[#4F4F4F]">
        {t({
          id: "tasks-page.no-items-to-add",
          defaultMessage: "No items to add 👌",
        })}
      </span>
    );
  }

  return (
    <div className="flex w-[327px] flex-col gap-6">
      <SearchInput
        autoFocus
        variant="filled"
        color="grey"
        onClear={reset}
        {...inputProps}
      />
      <div className="jugl__custom-scrollbar -mr-2 flex max-h-[300px] flex-col gap-2 overflow-y-auto pr-2">
        {filteredCustomFields.length > 0 ? (
          <>
            {filteredCustomFields.map((customField) => {
              const { icon: Icon, color: iconColor } =
                customFieldTypeToIconDetailsMap[customField.type];
              const isColumnVisible = customFieldColumnsMap[customField.id];

              return (
                <InteractiveContainer
                  key={customField.id}
                  className="flex items-center justify-between gap-2 px-3 py-1.5"
                  onClick={() =>
                    changeColumnVisibility(customField.id, !isColumnVisible)
                  }
                >
                  <div className="flex items-center gap-3 overflow-hidden">
                    <div
                      className="flex h-8 w-8 shrink-0 items-center justify-center rounded-md"
                      style={{ backgroundColor: iconColor }}
                    >
                      <Icon className="h-[14px] w-[14px] text-[#828282]" />
                    </div>
                    <span className="text-dark truncate text-sm leading-3">
                      {customField.name}
                    </span>
                  </div>
                  <Switch isChecked={isColumnVisible} className="shrink-0" />
                </InteractiveContainer>
              );
            })}
          </>
        ) : (
          <NoResults />
        )}
      </div>
    </div>
  );
};
