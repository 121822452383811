import { cx } from "@jugl-web/utils";
import { ReactNode, useMemo } from "react";
import { Drawer } from "../../cross-platform/Drawer";
import { FullScreenHeader } from "./FullScreenHeader";
import { SwipeableHeader } from "./SwipeableHeader";
import { MobileDrawerProps } from "./types";

export const MobileDrawer = ({
  children,
  isOpen,
  header,
  hasBackdrop,
  size = "auto",
  animateHeight = false,
  placement = "bottom",
  initialFocus,
  className,
  onClose,
  onTransitionEnd,
}: MobileDrawerProps) => {
  const maybeHeader = useMemo<JSX.Element | null>(() => {
    if (!header) {
      return null;
    }

    return size === "full-screen" ? (
      <FullScreenHeader
        title={header.title}
        isDetached={header.isDetached}
        onClose={onClose}
      />
    ) : (
      <SwipeableHeader {...header} onClose={onClose} />
    );
  }, [header, onClose, size]);

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      onTransitionEnd={onTransitionEnd}
      placement={placement}
      hasBackdrop={hasBackdrop}
      size={size}
      animateHeight={animateHeight}
      initialFocus={initialFocus}
      className={cx(
        "flex max-h-full flex-col",
        size !== "full-screen" && "max-h-[95%] rounded-t-2xl",
        className
      )}
    >
      {maybeHeader}
      {children}
    </Drawer>
  );
};

export interface MobileDrawerContentProps {
  children: ReactNode;
  className?: string;
}

MobileDrawer.Content = ({ children, className }: MobileDrawerContentProps) => (
  <div className={cx("mt-1 grow overflow-y-auto px-4 pb-8", className)}>
    {children}
  </div>
);

export interface MobileDrawerActionsProps {
  children: ReactNode;
  isHorizontal?: boolean;
  isDetached?: boolean;
  className?: string;
}

MobileDrawer.Actions = ({
  children,
  isHorizontal = false,
  isDetached = false,
  className,
}: MobileDrawerActionsProps) => (
  <div
    className={cx(
      "flex shrink-0 px-6 pb-4 pt-2",
      isHorizontal ? "items-center gap-2.5" : "flex-col gap-4",
      className
    )}
    style={{
      boxShadow: isDetached
        ? "1px -6px 10px 0px rgba(18, 22, 34, 0.04)"
        : undefined,
    }}
  >
    {children}
  </div>
);
