import { useCallback, useEffect, useRef, useState } from "react";
import { createRecordingFile } from "../utils/createRecordingFile";

export enum AudioRecorderError {
  noPermissions = "noPermissions",
}

export interface AudioRecording {
  file: File;
  url: string;
}

interface UseAudioRecorderOptions {
  maxTimeInSeconds: number;
  autoStart: boolean;
  volumeLevelListener?: (level: number) => void;
  generateFileName?: () => string;
  onFinished?: (recording: AudioRecording) => void;
}

export const useAudioRecorder = (
  {
    maxTimeInSeconds,
    autoStart,
    volumeLevelListener,
    onFinished,
  }: UseAudioRecorderOptions = { autoStart: true, maxTimeInSeconds: 300 }
) => {
  const [lastRecording, setLastRecording] = useState<AudioRecording | null>(
    null
  );
  const [timeInSeconds, setTimeInSeconds] = useState<number>(0);
  const [isRecording, setIsRecording] = useState(false);
  const [isProcessingAudio, setIsProcessingAudio] = useState(false);
  const [error, setError] = useState<AudioRecorderError>();

  const getMediaStream = useCallback(async () => {
    try {
      return await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
    } catch (mediaStreamError) {
      // TODO: better error handling?
      setError(AudioRecorderError.noPermissions);
      return null;
    }
  }, []);

  const currentTimer = useRef<ReturnType<typeof setInterval> | null>(null);
  const mediaStream = useRef<MediaStream | null>(null);
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const audioChunks = useRef<Blob[]>([]);
  const $isRecording = useRef<boolean>(false);
  const mimeType = useRef<string>();

  const audioProcessCallback = useRef<() => void>();
  const scriptProcessorRef = useRef<ScriptProcessorNode>();

  const stop = useCallback(() => {
    $isRecording.current = false;
    mediaStream.current?.getAudioTracks()?.forEach((item) => item.stop());
    mediaRecorder.current?.stop();
    setIsRecording(false);
    if (currentTimer.current) {
      clearInterval(currentTimer.current);
    }

    mediaStream.current = null;
    mediaRecorder.current = null;
    currentTimer.current = null;
    if (scriptProcessorRef.current && audioProcessCallback.current) {
      scriptProcessorRef.current.addEventListener(
        "audioprocess",
        audioProcessCallback.current
      );
      scriptProcessorRef.current = undefined;
      audioProcessCallback.current = undefined;
    }
    setTimeInSeconds(0);
    setIsProcessingAudio(true);
  }, []);

  const start = useCallback(async () => {
    if (mediaStream.current) {
      throw new Error("Recording already started");
    }
    mediaStream.current = await getMediaStream();
    if (!mediaStream.current) {
      return;
    }
    $isRecording.current = true;
    mediaRecorder.current = new MediaRecorder(mediaStream.current);
    audioChunks.current = [];
    mediaRecorder.current.addEventListener("dataavailable", (event) => {
      if (event.data && event.data.size > 0) {
        audioChunks.current.push(event.data);
      }

      if (!$isRecording.current) {
        const file = createRecordingFile(audioChunks.current);
        const url = window.URL.createObjectURL(file);
        const recording: AudioRecording = { file, url };
        setLastRecording(recording);
        setIsProcessingAudio(false);
        onFinished?.(recording);
      }
    });
    currentTimer.current = setInterval(() => {
      setTimeInSeconds((prev) => prev + 1);
    }, 1000);
    mediaRecorder.current.start();
    setIsRecording(true);

    const audioContext = new AudioContext();

    const analyser = audioContext.createAnalyser();
    const microphone = audioContext.createMediaStreamSource(
      mediaStream.current
    );
    // TODO: replace with AudioWorkletNode
    const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);
    scriptProcessorRef.current = scriptProcessor;

    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 1024;

    microphone.connect(analyser);
    analyser.connect(scriptProcessor);
    scriptProcessor.connect(audioContext.destination);
    audioProcessCallback.current = () => {
      if (!mimeType.current) {
        mimeType.current = mediaRecorder.current?.mimeType;
      }
      const array = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(array);
      const arraySum = array.reduce((a, value) => a + value, 0);
      const average = arraySum / array.length;
      volumeLevelListener?.(Math.round(average));
    };
    scriptProcessor.addEventListener(
      "audioprocess",
      audioProcessCallback.current
    );
  }, [getMediaStream, onFinished, volumeLevelListener]);

  useEffect(() => {
    if (timeInSeconds > maxTimeInSeconds) {
      stop();
    }
  }, [timeInSeconds, maxTimeInSeconds, stop]);

  useEffect(() => {
    if (autoStart && !mediaStream.current) {
      start();
    }
  }, [autoStart, start]);

  useEffect(() => () => stop(), [stop]);

  return {
    start,
    stop,
    overrideRecording: setLastRecording,
    isRecording,
    isProcessingAudio,
    timeInSeconds,
    error,
    lastRecording,
  };
};
