import {
  getDueDateLabel,
  isTaskOverdue,
} from "@jugl-web/domain-resources/tasks";
import { Popover } from "@jugl-web/ui-components/cross-platform";
import { DatePicker } from "@jugl-web/ui-components/cross-platform/DatePicker";
import { cx, isoToLocalDate } from "@jugl-web/utils";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { FC } from "react";
import { TaskTableCellComponentProps } from "../../types";
import { TaskTableLayout } from "../TaskTableLayout";

export const DueDateCell: FC<TaskTableCellComponentProps> = ({
  task,
  permissions,
  cellProps,
  onUpdate,
}) => {
  const { dateLocale } = useLanguage();

  const isOverdue = isTaskOverdue(task);

  const adaptedDueDate = task.due_at
    ? new Date(isoToLocalDate(task.due_at))
    : null;

  return (
    <Popover
      placement="bottom"
      renderTrigger={({ Trigger, triggerRef, isOpen, onClose }) => (
        <Trigger
          ref={triggerRef}
          as={TaskTableLayout.Cell}
          isFocused={isOpen}
          isHoverable
          isDisabled={!permissions.canEditDueDate}
          className="justify-between gap-2"
          {...cellProps}
        >
          {adaptedDueDate ? (
            <TaskTableLayout.CellText
              className={cx(isOverdue && "text-tertiary-800")}
            >
              {getDueDateLabel({
                dueDate: adaptedDueDate,
                locale: dateLocale,
              })}
            </TaskTableLayout.CellText>
          ) : (
            <TaskTableLayout.AppearingAddValueCellText
              isStatic={isOpen}
              isHidden={!permissions.canEditDueDate}
            />
          )}
          {adaptedDueDate && permissions.canEditDueDate && (
            <TaskTableLayout.ClearCellValueButton
              isAppearing={!isOpen}
              onClick={(event) => {
                event.stopPropagation();
                onUpdate({ due_at: null });
                onClose();
              }}
            />
          )}
        </Trigger>
      )}
      className="w-[375px] px-4 py-6"
    >
      {({ onClose }) => (
        <DatePicker
          initialDate={adaptedDueDate || undefined}
          minDate={new Date()}
          dateTransformation="endOfDay"
          onDaySelect={(dueDate) => {
            onUpdate({ due_at: dueDate.toISOString() });
            onClose();
          }}
        />
      )}
    </Popover>
  );
};
