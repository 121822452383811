import { User } from "@jugl-web/rest-api";

export const getEntityUserDisplayName = (item: User): string => {
  if (item.profile && (item.profile.first_name || item.profile.last_name)) {
    const strings: string[] = [];
    if (item.profile.first_name) {
      strings.push(item.profile.first_name);
    }
    if (item.profile.last_name) {
      strings.push(item.profile.last_name);
    }
    return strings.join(" ");
  }
  return item.username || item.mobiles[0] || item.emails[0] || "";
};
