import { useCallback, useRef, useState } from "react";
import mime from "mime";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { Buffer } from "buffer";

// TODO: add error handling
export const useDownloadAttachment = () => {
  const { driveApi } = useRestApiProvider();
  const [downloadChunk] = driveApi.useDownloadAttachmentChunkMutation();

  const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false);
  const downloadCancelled = useRef<boolean>(false);

  const cancelDownload = useCallback(() => {
    if (!downloadInProgress) {
      return;
    }
    downloadCancelled.current = true;
  }, [downloadInProgress]);

  const download = useCallback(
    async ({
      path,
      fileSize,
      mimeType,
      fileName,
      onProgress,
    }: {
      path: string;
      fileName: string;
      fileSize: number;
      mimeType: string;
      onProgress?: (bytesSent: number, totalBytes: number) => void;
    }) => {
      setDownloadInProgress(true);

      const chunkByteSize = 1 * 1024 * 1024;

      const numChunks = Math.ceil(fileSize / chunkByteSize);
      let file = new File([], fileName, { type: mimeType });

      const chunks: { start: number; end: number; chunk: string }[] = [];
      for (let i = 0; i < numChunks; i += 1) {
        if (downloadCancelled.current) {
          downloadCancelled.current = false;
          setDownloadInProgress(false);
          // TODO: better error
          throw new Error("Cancelled");
        }

        const start = i * chunkByteSize;
        const end = Math.min(fileSize, start + chunkByteSize) - 1;
        // eslint-disable-next-line no-await-in-loop
        const response = await downloadChunk({
          params: {
            path,
            range: [start, end],
          },
        });
        onProgress?.(end, fileSize);
        if ("data" in response) {
          chunks.push({ start, end, chunk: response.data });
        }
      }
      setDownloadInProgress(false);
      if (downloadCancelled.current) {
        downloadCancelled.current = false;
        // TODO: better error
        throw new Error("Cancelled");
      }

      chunks.forEach(({ chunk, start }) => {
        const chunkData = Buffer.from(chunk, "base64");
        const chunkBlob = new Blob([chunkData], {
          type: mimeType,
        });
        const fileBlob = new Blob([file.slice(0, start), chunkBlob], {
          type: mimeType,
        });
        file = new File(
          [fileBlob],
          `${fileName}.${mime.getExtension(mimeType)}`,
          {
            type: mimeType,
          }
        );
      });
      return file;
    },
    [downloadCancelled, downloadChunk]
  );

  return { download, cancelDownload, downloadInProgress };
};
