import { useRestApiProvider } from "@jugl-web/rest-api";
import { useCallback } from "react";
import { useSelectTaskById } from "./useSelectTaskById";

interface UseTaskUnreadIndicatorOptions {
  entityId: string;
}

export const useTaskUnreadIndicator = ({
  entityId,
}: UseTaskUnreadIndicatorOptions) => {
  const selectTaskById = useSelectTaskById({ entityId });

  const { moduleNotificationsApi, tasksApi } = useRestApiProvider();

  const [markObjectLevelIndicatorAsRead] =
    moduleNotificationsApi.useMarkObjectLevelIndicatorAsReadMutation();

  const [readTaskMutation] = tasksApi.useReadTaskMutation();

  const readTask = useCallback(
    (taskId: string) => {
      if (!entityId) {
        return;
      }

      // Send request to mark the task as read
      markObjectLevelIndicatorAsRead({
        entityId,
        module: "task",
        objectId: taskId,
      });

      // Optimistically mark the task as read
      readTaskMutation({ entityId, taskId });
    },
    [entityId, markObjectLevelIndicatorAsRead, readTaskMutation]
  );

  const isTaskUnread = useCallback(
    (taskId: string) => {
      const task = selectTaskById(taskId);

      // If we don't know about the task, we assume it's unread
      return task ? task.unread : true;
    },
    [selectTaskById]
  );

  return {
    readTask,
    isTaskUnread,
  };
};
