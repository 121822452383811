import {
  HookOutOfContextError,
  getUniqueId,
  trimHTML,
  useTabIsActive,
} from "@jugl-web/utils";
import { useSnackbar } from "notistack";
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { usePreferencesProvider } from "@jugl-web/domain-resources/preferences";
import useEntity from "@web-src/features/app/hooks/useEntity";
import {
  ChatNotificationsMuteOptions,
  NotificationCategory,
  SoundSupportedModule,
} from "@web-src/modules/settings/pages/SettingsPage/types";
import { useSelector } from "react-redux";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { ChatType } from "@web-src/features/chats/types";
import { extractNumberFromSoundType } from "@web-src/modules/settings/pages/SettingsPage/components/NotificationsSettingsSidebar/utils/extractNumberFromSoundType";
import { useUserEntityPreferences } from "@web-src/modules/preferences/hooks/useUserEntityPreferences";
import { FCMProvider } from "../FCMProvider";

export type InAppNotification = {
  entityId: string;
  key?: string;
  title: string;
  module?: keyof NotificationCategory;
  senderId?: string;
  body?: string;
  clearBody?: string;
  onClick?: () => void;
};

export type TriggerNotificationParams = {
  body?: string;
  tag?: string;
  module?: SoundSupportedModule;
  onClick?: () => void;
};

export interface NotificationsContextValue {
  triggerInAppNotification: (notification: InAppNotification) => void;

  getChatNotificationsPreferences: (
    chatId: string
  ) => ChatNotificationsMuteOptions;
  setChatNotificationsPreferences: (
    chatId: string,
    chatType: ChatType,
    option: ChatNotificationsMuteOptions
  ) => void;

  notificationPreferences: NotificationCategory | undefined;
}

export const NotificationsContext =
  createContext<NotificationsContextValue | null>(null);

const MAIN_TAB_STORAGE_KEY = "JUGL:NOTIFICATIONS_MAIN_TAB";

const isMainTab = (tabKey?: string) =>
  tabKey && localStorage.getItem(MAIN_TAB_STORAGE_KEY) === tabKey;

export const NotificationsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [tabKey, setTabKey] = useState<string>();
  const tabIsActive = useTabIsActive();
  useEffect(() => {
    if (!tabIsActive) {
      return;
    }
    const key = getUniqueId();
    localStorage.setItem(MAIN_TAB_STORAGE_KEY, key);
    setTabKey(key);
  }, [tabIsActive]);

  const [canPlaySound, setCanPlaySound] = useState<boolean>(false);
  useEffect(() => {
    const mouseOverCallback = () => {
      setCanPlaySound(true);
      document.body.removeEventListener("click", mouseOverCallback);
    };
    document.body.addEventListener("click", mouseOverCallback);
  }, []);

  const { enqueueSnackbar } = useSnackbar();
  const meId = useSelector(selectUserId);
  const [shownNotifications, setShownNotifications] = useState<{
    [key: string]: boolean;
  }>({});
  const location = useLocation();
  const { preferences: appPreferences } = usePreferencesProvider();

  const { entity } = useEntity();
  const { preferences: userPreferences, updatePreferences } =
    useUserEntityPreferences();
  const notificationPreferences = useMemo(
    () => userPreferences?.notification_pref,
    [userPreferences]
  );

  const triggerPushNotification = useCallback(
    (title: string, params?: TriggerNotificationParams) => {
      if (document.visibilityState === "visible") {
        return;
      }
      // TODO: permissions
      // eslint-disable-next-line no-new
      const notification = new Notification(title, {
        body: params?.body,
        tag: params?.tag,
      });
      notification.onclick = () => {
        window.focus();
        params?.onClick?.();
      };
    },
    []
  );

  const playNotificationSoundForModule = useCallback(
    (type?: SoundSupportedModule) => {
      if (
        (type && userPreferences?.notification_pref?.[type]?.sound === false) ||
        !canPlaySound
      ) {
        return;
      }
      const notificationSoundId = type
        ? extractNumberFromSoundType(
            userPreferences?.notification_pref?.[type]?.sound_web
          ) ?? 0
        : 0;
      new Audio(
        `/notification-sounds/notification${notificationSoundId || ""}.mp3`
      ).play();
    },
    [canPlaySound, userPreferences]
  );

  const getChatNotificationsPreferences = useCallback<
    NotificationsContextValue["getChatNotificationsPreferences"]
  >(
    (chatId) =>
      notificationPreferences?.chats?.except?.[chatId]?.mute ||
      notificationPreferences?.groups?.except?.[chatId]?.mute ||
      "none",
    [notificationPreferences]
  );

  const isNotificationInSameModule = useCallback(
    (notification: InAppNotification) => {
      if (notification.module === "chats" || notification.module === "groups") {
        return location.pathname.includes("/chats");
      }
      if (notification.module === "drive") {
        return location.pathname.includes("/drive");
      }
      if (notification.module === "task") {
        // TODO: add entityId to notification
        return (
          location.pathname.includes("/tasks") &&
          !location.pathname.includes("/reports/tasks")
        );
      }
      return false;
    },
    [location.pathname]
  );

  const isChatNotificationMuted = useCallback(
    (chatId: string, body: string) => {
      const chatNotificationPreferences =
        getChatNotificationsPreferences(chatId);
      if (chatNotificationPreferences === "all") {
        return true;
      }
      if (chatNotificationPreferences === "all_except_mentions") {
        return !meId || !body.includes(meId);
      }
      return false;
    },
    [getChatNotificationsPreferences, meId]
  );

  const triggerInAppNotification = useCallback(
    (notification: InAppNotification) => {
      if (notification.key) {
        if (shownNotifications[notification.key]) {
          return;
        }
        setShownNotifications({
          ...shownNotifications,
          [notification.key]: true,
        });
      }
      // TODO: revisit
      if (
        notification.module &&
        userPreferences?.notification_pref?.[notification.module]?.noti ===
          false
      ) {
        return;
      }
      if (
        (notification.module === "chats" || notification.module === "groups") &&
        notification.senderId &&
        isChatNotificationMuted(
          notification.senderId,
          notification.clearBody || ""
        )
      ) {
        return;
      }
      if (!appPreferences.inAppNotificationsEnabled) {
        return;
      }
      const isInSameModule = isNotificationInSameModule(notification);
      if ((document.hidden || !isInSameModule) && isMainTab(tabKey)) {
        playNotificationSoundForModule?.(notification.module);
      }
      if (document.hidden && isMainTab(tabKey)) {
        triggerPushNotification?.(notification.title, {
          body: trimHTML(notification.body || ""),
          onClick: notification.onClick,
        });
      }
      if (!isInSameModule) {
        enqueueSnackbar({
          key: "1",
          variant: "inAppNotification",
          notification,
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
          autoHideDuration: 5000,
          transitionDuration: 300,
        });
      }
    },
    [
      userPreferences?.notification_pref,
      isChatNotificationMuted,
      appPreferences.inAppNotificationsEnabled,
      isNotificationInSameModule,
      shownNotifications,
      playNotificationSoundForModule,
      triggerPushNotification,
      enqueueSnackbar,
      tabKey,
    ]
  );

  // TODO: move out to user preferences module
  const setChatNotificationsPreferences = useCallback<
    NotificationsContextValue["setChatNotificationsPreferences"]
  >(
    (chatId, chatType, option) => {
      if (!entity?.id) {
        return;
      }
      updatePreferences({
        notification_pref:
          chatType === ChatType.muc
            ? { groups: { except: { [chatId]: { mute: option } } } }
            : { chats: { except: { [chatId]: { mute: option } } } },
      });
    },
    [entity, updatePreferences]
  );

  const contextValue = useMemo<NotificationsContextValue>(
    () => ({
      triggerInAppNotification,
      getChatNotificationsPreferences,
      setChatNotificationsPreferences,
      notificationPreferences,
    }),
    [
      triggerInAppNotification,
      getChatNotificationsPreferences,
      setChatNotificationsPreferences,
      notificationPreferences,
    ]
  );

  return (
    <NotificationsContext.Provider value={contextValue}>
      <FCMProvider>{children}</FCMProvider>
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationsContext);

  if (!context) {
    throw new HookOutOfContextError(
      "useInAppNotifications",
      "NotificationsContext"
    );
  }

  return context;
};
