export const copyTime = (from: Date, to: Date) => {
  const date = new Date(to);

  date.setHours(
    from.getHours(),
    from.getMinutes(),
    from.getSeconds(),
    from.getMilliseconds()
  );

  return date;
};
