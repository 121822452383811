import { Menu } from "@jugl-web/ui-components/cross-platform";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { ControlBarButton } from "@jugl-web/ui-components/web/ControlBarButton";
import { ExpandableSearchBar } from "@jugl-web/ui-components/web/ExpandableSearchBar";
import { cx, useSearchInput, useTranslations } from "@jugl-web/utils";
import { TasksPageLayout } from "@web-src/components/TasksPageLayout";
import {
  TemplateFolderFilter,
  useTaskTemplateFoldersPageContext,
} from "@web-src/features/tasks/TaskTemplateFoldersPageContext";
import { FC, useMemo } from "react";
import { ManageTaskTemplateFolderDialog } from "../ManageTaskTemplateFolderDialog";
import { ReactComponent as AddIcon } from "./assets/add.svg";
import { ReactComponent as FilterIcon } from "./assets/filter.svg";

interface TaskTemplateFoldersControlBarProps {
  entityId: string;
  isAdmin: boolean;
}

export const TaskTemplateFoldersControlBar: FC<
  TaskTemplateFoldersControlBarProps
> = ({ entityId, isAdmin }) => {
  const {
    searchQuery,
    filter,
    changeSearchQuery,
    changeFilter,
    isNewFolderDialogOpen,
    setIsNewFolderDialogOpen,
  } = useTaskTemplateFoldersPageContext();

  const { inputProps } = useSearchInput({ defaultValue: searchQuery });

  const { t } = useTranslations();

  const filterLabelById = useMemo<Record<TemplateFolderFilter, string>>(
    () => ({
      [TemplateFolderFilter.AllFolders]: t({
        id: "task-template-folders-page.all-folders-filter",
        defaultMessage: "All folders",
      }),
      [TemplateFolderFilter.MyFolders]: t({
        id: "task-template-folders-page.my-folders-filter",
        defaultMessage: "My folders",
      }),
      [TemplateFolderFilter.CreatedByJugl]: t({
        id: "task-template-folders-page.created-by-jugl-filter",
        defaultMessage: "Created by Jugl",
      }),
      [TemplateFolderFilter.CreatedByOthers]: t({
        id: "task-template-folders-page.created-by-others-filter",
        defaultMessage: "Created by others",
      }),
    }),
    [t]
  );

  const isFilterApplied = filter !== TemplateFolderFilter.AllFolders;

  return (
    <>
      <TasksPageLayout.ControlBar>
        <div className="flex w-full items-center justify-between gap-4">
          <div className="flex items-center gap-4">
            <ExpandableSearchBar
              color="white"
              size="lg"
              isAlwaysExpanded
              defaultValue={searchQuery}
              onSearch={changeSearchQuery}
              {...inputProps}
            />
            <Menu
              className="w-[250px] py-2"
              placement="bottom-start"
              sections={[
                [
                  {
                    id: TemplateFolderFilter.AllFolders,
                    label: filterLabelById[TemplateFolderFilter.AllFolders],
                    toggle: "radio",
                  },
                  {
                    id: TemplateFolderFilter.MyFolders,
                    label: filterLabelById[TemplateFolderFilter.MyFolders],
                    toggle: "radio",
                  },
                  // Will be reverted once the Jugl templates (#1171) are implemented
                  // {
                  //   id: TemplateFolderFilter.CreatedByJugl,
                  //   label: filterLabelById[TemplateFolderFilter.CreatedByJugl],
                  //   toggle: "radio",
                  // },
                  {
                    id: TemplateFolderFilter.CreatedByOthers,
                    label:
                      filterLabelById[TemplateFolderFilter.CreatedByOthers],
                    toggle: "radio",
                  },
                ],
              ]}
              renderTrigger={({ Trigger, triggerRef, isOpen }) => (
                <Trigger
                  ref={triggerRef}
                  as={ControlBarButton}
                  label={filterLabelById[filter]}
                  startSlot={
                    <span className="relative flex items-center justify-center">
                      <FilterIcon className="fill-grey" />
                      <div
                        className={cx(
                          "bg-gradients-success absolute right-0 top-0 box-content h-2 w-2 -translate-y-1/3 translate-x-1/3 rounded-full border-2 border-solid border-white transition-opacity",
                          isFilterApplied ? "opacity-100" : "opacity-0"
                        )}
                      />
                    </span>
                  }
                  isActive={isOpen}
                />
              )}
              onSelect={(item, _, close) => {
                changeFilter(item.id as TemplateFolderFilter);
                close();
              }}
              selectedId={filter}
            />
          </div>
          {isAdmin && (
            <Button
              className="h-10 px-10"
              iconEnd={<AddIcon />}
              onClick={() => setIsNewFolderDialogOpen(true)}
            >
              {t({
                id: "tasks-page.new-template-folder",
                defaultMessage: "New template folder",
              })}
            </Button>
          )}
        </div>
      </TasksPageLayout.ControlBar>
      {entityId && (
        <ManageTaskTemplateFolderDialog
          entityId={entityId}
          isOpen={isNewFolderDialogOpen}
          onClose={() => setIsNewFolderDialogOpen(false)}
        />
      )}
    </>
  );
};
