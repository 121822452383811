import { useTranslations, useToast } from "@jugl-web/utils";
import React, { useState } from "react";
import {
  Menu,
  Text,
  PlainButton,
  LoadingSpinner,
  Button,
} from "@jugl-web/ui-components/cross-platform";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { LoginMethod } from "@jugl-web/rest-api/auth/types";
import { ReactComponent as BlueArrowIcon } from "./assets/blue-arrow.svg";
import { ReactComponent as PlusIcon } from "./assets/plus.svg";
import { ReactComponent as EmailIcon } from "./assets/mail.svg";
import { ReactComponent as PhoneIcon } from "./assets/phone.svg";
import { ReactComponent as CrossIcon } from "./assets/cross.svg";
import { LoginMethodVerificationDialog } from "./components/LoginMethodVerificationDialog";
import { LoginMethodType } from "./types";
import errorImage from "./assets/loading-error.png";
import { RemoveLoginMethodConfirmationDialog } from "./components/RemoveLoginMethodConfirmationDialog";

const LoginMethodItem = ({
  label,
  hideDeleteIcon,
  onDelete,
}: {
  label: string;
  hideDeleteIcon?: boolean;
  onDelete: () => void;
}) => (
  <div className="bg-grey-200 flex gap-2 rounded-xl py-3 px-4">
    <Text variant="body2">{label}</Text>
    {!hideDeleteIcon && (
      <PlainButton
        onClick={onDelete}
        className="rounded-full transition-colors hover:bg-white"
      >
        <CrossIcon />
      </PlainButton>
    )}
  </div>
);

export const LoginMethodList: React.FC = () => {
  const [
    removeLoginMethodConfirmationDialogState,
    setRemoveLoginMethodConfirmationDialogState,
  ] = useState<{
    isOpen: boolean;
    loginMethod: LoginMethod | null;
  }>({
    isOpen: false,
    loginMethod: null,
  });
  const [addNewLoginMethodDialogState, setAddNewLoginMethodDialogState] =
    useState<{
      isOpen: boolean;
      type: LoginMethodType | null;
    }>({
      isOpen: false,
      type: null,
    });

  const { t } = useTranslations();
  const { authApi } = useRestApiProvider();
  const { toast } = useToast({ variant: "web" });
  const {
    data: loginMethodList,
    isError: isLoginMethodLoadError,
    isLoading,
    refetch: refetchData,
  } = authApi.useListLoginMethodsQuery();
  const [removeLoginMethod] = authApi.useRemoveLoginMethodMutation();

  const handleRemoveLoginMethod = async () => {
    const { loginMethod } = removeLoginMethodConfirmationDialogState;
    if (!loginMethod) return;
    const response = await removeLoginMethod(loginMethod);

    if ("data" in response) {
      setRemoveLoginMethodConfirmationDialogState((prevState) => ({
        ...prevState,
        isOpen: false,
      }));
      toast(
        loginMethod.type === "email"
          ? t({
              id: "settings-page.email-was-deleted",
              defaultMessage: "Email was deleted",
            })
          : t({
              id: "settings-page.phone-was-deleted",
              defaultMessage: "Phone number was deleted",
            })
      );
    }
  };

  const onRetry = () => {
    refetchData();
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isLoginMethodLoadError) {
    return (
      <div className="flex flex-col gap-6 rounded-xl bg-[#F7F8F9] p-6">
        <div className="flex w-full justify-between">
          <span className="flex items-center gap-4">
            <BlueArrowIcon />
            <Text variant="body1" className="font-secondary font-medium">
              {t({
                id: "settings-page.login-methods",
                defaultMessage: "Login Methods",
              })}
            </Text>
          </span>
        </div>
        <div className="flex w-full items-center justify-center">
          <img
            src={errorImage}
            alt="error indicator"
            className="h-[245px] w-[245px]"
          />
          <div className="flex flex-col items-center justify-center">
            <span className="font-secondary text-dark text-[24px] leading-[36px]">
              {t({
                id: "settings-page.failed-to-load-data",
                defaultMessage: "Failed to load the data",
              })}
            </span>
            <Text variant="body2" className="text-grey mt-3">
              {t({
                id: "settings-page.check-your-internet-connection",
                defaultMessage: "Check your Internet connection and try again",
              })}
            </Text>
            <Button
              color="tertiary"
              className="mt-10 h-[40px] w-[200px]"
              onClick={onRetry}
            >
              {t({
                id: "common.retry",
                defaultMessage: "Retry",
              })}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6 rounded-xl bg-[#F7F8F9] p-6">
      <div className="flex w-full justify-between">
        <span className="flex items-center gap-4">
          <BlueArrowIcon />
          <Text variant="body1" className="font-secondary font-medium">
            {t({
              id: "settings-page.login-methods",
              defaultMessage: "Login Methods",
            })}
          </Text>
        </span>
        <Menu
          placement="bottom-start"
          renderTrigger={({ Trigger, triggerRef }) => (
            <Trigger
              as={PlainButton}
              ref={triggerRef}
              className="h-12 w-12 rounded-lg p-3 transition-colors hover:bg-[#EEF2F5]"
            >
              <PlusIcon />
            </Trigger>
          )}
          onSelect={(el, event, close) => close()}
          className="py-2"
          sections={[
            [
              {
                id: "add-email",
                label: t({
                  id: "common.email",
                  defaultMessage: "Email",
                }),
                icon: <EmailIcon />,
                onSelect: (_, close) => {
                  setAddNewLoginMethodDialogState({
                    isOpen: true,
                    type: "email",
                  });
                  close();
                },
              },
              {
                id: "add-phone",
                label: t({
                  id: "settings-page.phone-number",
                  defaultMessage: "Phone Number",
                }),
                icon: <PhoneIcon />,
                onSelect: (_, close) => {
                  setAddNewLoginMethodDialogState({
                    isOpen: true,
                    type: "mobile",
                  });
                  close();
                },
              },
            ],
          ]}
        />
      </div>
      <div className="flex flex-wrap items-center gap-6">
        {loginMethodList?.map((method) => (
          <LoginMethodItem
            key={method.id}
            label={method.value}
            hideDeleteIcon={loginMethodList.length === 1}
            onDelete={() => {
              setRemoveLoginMethodConfirmationDialogState({
                isOpen: true,
                loginMethod: method,
              });
            }}
          />
        ))}
      </div>
      <LoginMethodVerificationDialog
        isOpen={addNewLoginMethodDialogState.isOpen}
        type={addNewLoginMethodDialogState.type}
        onClose={() =>
          setAddNewLoginMethodDialogState((prevState) => ({
            ...prevState,
            isOpen: false,
          }))
        }
      />
      <RemoveLoginMethodConfirmationDialog
        {...removeLoginMethodConfirmationDialogState}
        onClose={() =>
          setRemoveLoginMethodConfirmationDialogState((prevState) => ({
            ...prevState,
            isOpen: false,
          }))
        }
        onRemove={handleRemoveLoginMethod}
      />
    </div>
  );
};
