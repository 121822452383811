import { TaskAttachment } from "@jugl-web/rest-api/tasks";
import {
  InteractiveContainer,
  Menu,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { getFileSizeLabel } from "@jugl-web/utils/utils/files";
import format from "date-fns/format";
import { FC, useState } from "react";
import Highlighter from "react-highlight-words";
import { ReactComponent as MoreIcon } from "../../assets/more.svg";
import { ReactComponent as PlayIcon } from "../../assets/play.svg";
import { getFileImagePath, getFileType } from "../../utils";
import { RemoveAttachmentDialog } from "../RemoveAttachmentDialog";
import { RenameTaskAttachmentDialog } from "../RenameAttachmentDialog";

export interface TaskAttachmentItemProps {
  attachment: TaskAttachment;
  isEditable: boolean;
  highlightedText?: string;
  onPreview: () => void;
  onRename?: (fileName: string) => void;
  onRemove?: () => void;
  onDownload?: () => void;
}

export const TaskAttachmentItem: FC<TaskAttachmentItemProps> = ({
  attachment,
  isEditable,
  highlightedText,
  onPreview,
  onRename,
  onRemove,
  onDownload,
}) => {
  const [hasImageLoadingError, setHasImageLoadingError] = useState(false);
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
  const [isRemoveConfirmationDialogOpen, setIsRemoveConfirmationDialogOpen] =
    useState(false);

  const { t } = useTranslations();
  const { dateLocale } = useLanguage();

  return (
    <>
      <InteractiveContainer
        title={attachment.name}
        onClick={onPreview}
        className="bg-grey-100 flex w-[168px] shrink-0 flex-col gap-3 rounded-xl p-4"
      >
        <div className="relative">
          {isEditable && (
            <Menu
              placement="bottom"
              sections={[
                [
                  {
                    id: "rename",
                    label: t({
                      id: "common.rename-file",
                      defaultMessage: "Rename file",
                    }),
                    isHidden: !onRename,
                    onSelect: (event, close) => {
                      event.stopPropagation();
                      close();
                      setIsRenameDialogOpen(true);
                    },
                  },
                  {
                    id: "delete",
                    label: t({
                      id: "common.delete-file",
                      defaultMessage: "Delete file",
                    }),
                    isHidden: !onRemove,
                    onSelect: (event, close) => {
                      event.stopPropagation();
                      close();
                      setIsRemoveConfirmationDialogOpen(true);
                    },
                  },
                  {
                    id: "download",
                    label: t({
                      id: "common.download-file",
                      defaultMessage: "Download file",
                    }),
                    isHidden: !onDownload,
                    onSelect: (event, close) => {
                      event.stopPropagation();
                      close();
                      onDownload?.();
                    },
                  },
                ],
              ]}
              className="min-w-[unset]"
              renderTrigger={({ Trigger, triggerRef }) => (
                <Trigger
                  as={PlainButton}
                  ref={triggerRef}
                  onClick={(event) => event.stopPropagation()}
                  className="absolute right-0 top-0 z-10 flex h-6 w-6 translate-x-1/4 -translate-y-1/4 items-center justify-center rounded-full bg-white"
                >
                  <MoreIcon />
                </Trigger>
              )}
            />
          )}
          <div className="relative h-16 overflow-hidden rounded-[6px]">
            {attachment.preview_available && !hasImageLoadingError ? (
              <>
                <img
                  src={attachment.preview_url}
                  alt={attachment.name}
                  className="h-full w-full object-cover"
                  onError={() => setHasImageLoadingError(true)}
                />
                {getFileType(attachment.name) === "video" && (
                  <PlayIcon className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
                )}
              </>
            ) : (
              <img
                src={getFileImagePath(attachment.name)}
                alt={attachment.name}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <span className="text-dark truncate text-[15px] font-medium leading-[18px]">
            {highlightedText ? (
              <Highlighter
                autoEscape
                highlightClassName="font-semibold text-primary bg-transparent"
                searchWords={[highlightedText || ""]}
                textToHighlight={attachment.name}
              />
            ) : (
              attachment.name
            )}
          </span>
          <span className="text-grey text-[13px] font-semibold leading-[12px]">
            {format(new Date(attachment.updated_at), "MMM d, yyyy", {
              locale: dateLocale,
            })}{" "}
            • {getFileSizeLabel(attachment.size)}
          </span>
        </div>
      </InteractiveContainer>
      {onRemove && (
        <RemoveAttachmentDialog
          isOpen={isRemoveConfirmationDialogOpen}
          attachment={attachment}
          onRemove={onRemove}
          onClose={() => setIsRemoveConfirmationDialogOpen(false)}
        />
      )}
      {onRename && (
        <RenameTaskAttachmentDialog
          isOpen={isRenameDialogOpen}
          attachment={attachment}
          onSubmit={onRename}
          onClose={() => setIsRenameDialogOpen(false)}
        />
      )}
    </>
  );
};
