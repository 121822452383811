import { TaskAttachmentItemProps } from "./components/TaskAttachmentItem";

export type FileType = {
  id: string;
  file: File;
};

export const adaptFilesToTaskAttachmentsComponentItems = (
  files: FileType[]
): TaskAttachmentItemProps["attachment"][] =>
  files.map((item) => {
    const url = URL.createObjectURL(item.file);
    const isPreviewAvailable =
      item.file.type.startsWith("image") || item.file.type.startsWith("video");
    return {
      id: item.id,
      file_path: url,
      stream_url: url,
      name: item.file.name,
      mimeType: item.file.type,
      content_type: item.file.type,
      preview_available: isPreviewAvailable,
      size: item.file.size,
      status: "valid",
      preview_url: url,
      created_at: new Date(),
      updated_at: new Date().toISOString(),
    };
  });
