import { forwardRef, HTMLProps } from "react";
import { TaskTemplateCardField } from "../../types";

const DEFAULT_FIELD_TEXT_COLOR = "#40494D";
const DEFAULT_FIELD_BG_COLOR = "#F9F9F9";

export const TaskTemplateCardFieldComponent = forwardRef<
  HTMLDivElement,
  HTMLProps<HTMLDivElement> & TaskTemplateCardField
>(({ id, text, textColor, backgroundColor, ...props }, ref) => (
  <div
    key={id}
    ref={ref}
    className="leading-2 max-w-[50%] truncate rounded-md py-0.5 px-1.5 text-xs"
    style={{
      backgroundColor: backgroundColor || DEFAULT_FIELD_BG_COLOR,
      color: textColor || DEFAULT_FIELD_TEXT_COLOR,
    }}
    {...props}
  >
    {text}
  </div>
));
