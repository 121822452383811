import React, {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  GroupedVirtuoso,
  GroupedVirtuosoProps,
  Virtuoso,
  VirtuosoHandle,
  VirtuosoProps,
} from "react-virtuoso";
import { usePrevious } from "../hooks/usePrevious";
import { PaginationItem } from "./types";

const reverseStartIndex = 1000000;

type VirtuosoContext = {
  isLoading?: boolean;
  EmptyContent?: React.ReactElement;
  HeaderContent?: React.ReactElement;
  FooterContent?: React.ReactElement;
  items: PaginationItem[];
};

type PaginationVirtuosoProps<TItem> = VirtuosoProps<
  PaginationItem<TItem>,
  VirtuosoContext
>;
type GroupedPaginationVirtuosoProps = GroupedVirtuosoProps<
  PaginationItem,
  VirtuosoContext
>;

const Header: React.FC<{
  context?: VirtuosoContext;
}> = ({ context }) => {
  if (!context?.HeaderContent) {
    return null;
  }
  return context.HeaderContent;
};

const Footer: React.FC<{
  context?: VirtuosoContext;
}> = ({ context }) => {
  if (!context?.FooterContent) {
    return null;
  }
  return context.FooterContent;
};

const EmptyPlaceholder: React.FC<{
  context?: VirtuosoContext;
}> = ({ context }) => <>{context?.EmptyContent}</>;

export type PaginationComponentHandle = {
  scrollToTop: () => void;
  scrollToBottom: () => void;
  getVirtuosoRef: () => VirtuosoHandle | null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PaginationComponentParams<TItem> = {
  renderer?: PaginationVirtuosoProps<TItem>["itemContent"];
  items: PaginationItem<TItem>[];
  endReached?: () => void;
  startReached?: () => void;
  isLoading?: boolean;
  EmptyContent?: React.ReactElement;
  HeaderContent?: React.ReactElement;
  FooterContent?: React.ReactElement;
  reverse?: boolean;
  id?: string;
  groupedRenderer?: GroupedPaginationVirtuosoProps["itemContent"];
  grouped?: {
    groupContent: GroupedPaginationVirtuosoProps["groupContent"];
    groupCounts: GroupedPaginationVirtuosoProps["groupCounts"];
  };
  initialFirstItem?: number;
  extraVirtuosoParams?: PaginationVirtuosoProps<TItem>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function PaginationComponent<TItem = any>(
  {
    endReached,
    renderer,
    items = [],
    startReached,
    isLoading,
    EmptyContent,
    HeaderContent,
    FooterContent,
    reverse,
    id,
    grouped,
    groupedRenderer,
    initialFirstItem,
    extraVirtuosoParams,
  }: PaginationComponentParams<TItem>,
  ref: ForwardedRef<PaginationComponentHandle>
) {
  const $virtuoso = useRef<VirtuosoHandle>(null);
  const previouseId = usePrevious(id);
  const [, setReRenderHandle] = useState<boolean>();

  const [initialBottomItemId, setInitialBottomItemId] = useState<string>();
  useEffect(() => {
    if (
      (initialBottomItemId && (!previouseId || previouseId === id)) ||
      !items.length
    ) {
      return;
    }
    setInitialBottomItemId(items[items.length - 1].id);
  }, [items, initialBottomItemId, previouseId, id]);

  const appendedItemsCount = useMemo(() => {
    if (
      !initialBottomItemId ||
      !items.length ||
      items[items.length - 1].id === initialBottomItemId
    ) {
      return 0;
    }
    const lastItemIndex = items.findIndex(
      (item) => item.id === initialBottomItemId
    );
    if (lastItemIndex === -1) {
      return 0;
    }
    return items.length - lastItemIndex - 1;
  }, [initialBottomItemId, items]);

  const initialValue = useRef<number>();
  const initialTopMostItemIndex = useMemo(() => {
    if (!items.length) {
      return 0;
    }
    if (
      initialValue.current !== undefined &&
      (!previouseId || previouseId === id)
    ) {
      return initialValue.current;
    }
    initialValue.current = items.length - 1 - (initialFirstItem || 0);
    return initialValue.current;
  }, [id, items.length, previouseId, initialFirstItem, initialValue]);

  const firstItemIndex = useMemo(
    () =>
      // TODO: document
      reverseStartIndex +
        initialTopMostItemIndex -
        items.length +
        1 +
        appendedItemsCount || 0,
    [appendedItemsCount, initialTopMostItemIndex, items.length]
  );
  useImperativeHandle(ref, () => ({
    scrollToTop: () => $virtuoso.current?.scrollTo({ top: 0 }),
    scrollToBottom: (smooth?: boolean) =>
      $virtuoso.current?.scrollTo({
        top: reverse ? reverseStartIndex : items?.length,
        behavior: smooth ? "smooth" : undefined,
      }),
    getVirtuosoRef: () => $virtuoso.current,
  }));

  useEffect(() => {
    if (id !== previouseId) {
      setReRenderHandle((prev) => !prev);
    }
  }, [id, previouseId, reverse]);

  const commonParams = useMemo<PaginationVirtuosoProps<TItem>>(() => {
    const params: PaginationVirtuosoProps<TItem> = {
      endReached: reverse ? startReached : endReached,
      startReached: !reverse ? startReached : endReached,
      style: {
        height: "100%",
        width: "100%",
        position: "relative",
      } as CSSProperties,
      context: {
        isLoading,
        EmptyContent,
        HeaderContent,
        FooterContent,
        items,
      },
      components: { EmptyPlaceholder, Header, Footer },
      alignToBottom: !!reverse,
      ...extraVirtuosoParams,
    };
    if (reverse) {
      params.firstItemIndex = firstItemIndex;
      params.initialTopMostItemIndex = initialTopMostItemIndex;
    }
    return params;
  }, [
    reverse,
    startReached,
    endReached,
    isLoading,
    EmptyContent,
    HeaderContent,
    FooterContent,
    items,
    firstItemIndex,
    initialTopMostItemIndex,
    extraVirtuosoParams,
  ]);
  const groupedItemContent: GroupedPaginationVirtuosoProps["itemContent"] =
    useCallback(
      // TODO: check type
      (
        index: number,
        groupIndex: number,
        data: PaginationItem<unknown>,
        context: VirtuosoContext
      ) =>
        groupedRenderer?.(
          reverse ? index - firstItemIndex : index,
          groupIndex,
          data,
          context
        ),
      [firstItemIndex, groupedRenderer, reverse]
    );
  if (id !== previouseId) {
    return null;
  }
  if (!items.length) {
    return EmptyContent || HeaderContent || FooterContent ? (
      <div className="flex h-full flex-col">
        {HeaderContent}
        <div className="flex-1">{EmptyContent}</div>
        {FooterContent}
      </div>
    ) : null;
  }
  if (grouped) {
    return (
      <GroupedVirtuoso
        {...commonParams}
        {...{
          ...grouped,
          itemContent: groupedItemContent,
        }}
        ref={$virtuoso}
      />
    );
  }
  return (
    <Virtuoso
      {...commonParams}
      ref={$virtuoso}
      data={items}
      itemContent={
        renderer
          ? (index, data, context) =>
              renderer(reverse ? index - firstItemIndex : index, data, context)
          : undefined
      }
    />
  );
}

export default forwardRef(PaginationComponent) as <TItem>(
  props: PaginationComponentParams<TItem> & {
    ref?: ForwardedRef<PaginationComponentHandle>;
  }
) => ReturnType<typeof PaginationComponent>;
