import { cx, useResizeObserver } from "@jugl-web/utils";
import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

export interface CollapseProps {
  children: ReactNode;
  isOpen: boolean;
  unmountOnExit?: boolean;
  className?: string;
  onTransitionEnd?: () => void;
}

export const Collapse: FC<CollapseProps> = ({
  children,
  isOpen,
  unmountOnExit = true,
  className,
  onTransitionEnd,
}) => {
  const [height, setHeight] = useState(0);
  const [isMounted, setIsMounted] = useState(isOpen);
  const [isAnimationDisabled, setIsAnimationDisabled] = useState(false);

  const isInitiallyRenderedRef = useRef(false);

  const { ref } = useResizeObserver({
    onResize: useCallback((entry) => {
      setHeight(entry.borderBoxSize[0].blockSize);
    }, []),
  });

  const handleTransitionEnd = () => {
    onTransitionEnd?.();

    // Disable further animations once the collapse is fully open
    // Ensure the nested collapses behave more naturally
    if (isOpen) {
      setIsAnimationDisabled(true);
    }

    // Unmount children when collapse is closed
    if (!isOpen) {
      setIsMounted(false);
    }
  };

  // Re-enable animations when the open state changes
  useLayoutEffect(() => {
    setIsAnimationDisabled(false);
  }, [isOpen]);

  // Ensure the children is mounted when it is open
  useEffect(() => {
    if (isOpen) {
      setIsMounted(true);
    }
  }, [isOpen]);

  useEffect(() => {
    // Prevent initial render from triggering the animation
    // Adding a short delay before setting the flag resolves unwanted initial transition
    setTimeout(() => {
      isInitiallyRenderedRef.current = true;
    }, 10);
  }, []);

  return (
    <div
      className={cx(
        "overflow-hidden",
        !isAnimationDisabled &&
          isInitiallyRenderedRef.current &&
          "transition-all duration-200"
      )}
      style={{ height: isOpen ? height : 0 }}
      onTransitionEnd={handleTransitionEnd}
    >
      <div ref={ref} className={className}>
        {(!unmountOnExit ? true : isMounted) && children}
      </div>
    </div>
  );
};
