import { SidebarDrawer } from "@jugl-web/ui-components/web";
import { useToast, useTranslations } from "@jugl-web/utils";
import React, { useMemo, useRef, useState } from "react";
import { UserCPanelModel, UserFile } from "@jugl-web/rest-api";
import { usersApi } from "@web-src/features/api/createApi";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  Alert,
  Button,
  LoadingSpinner,
} from "@jugl-web/ui-components/cross-platform";
import {
  downloadBlobAsFile,
  useDownloadFile,
} from "@jugl-web/domain-resources/drive";
import { useUploadDocuments } from "./hooks/useUploadDocuments";
import { ReactComponent as PlusIcon } from "./icons/plus.svg";
import { UploadingFile } from "./components/UploadingFile";
import { FileItem } from "./components/FileItem";
import { FileRenameDialog } from "./components/FileRenameDialog";
import { useCPanelPageContext } from "../../CPanelPageProvider";

export const UserDocumentsSidebar: React.FC<{
  isOpen: boolean;
  user?: UserCPanelModel;
  onRequestClose: () => void;
}> = ({ isOpen, user, onRequestClose }) => {
  const { t } = useTranslations();
  const { entity } = useEntity();
  const { updateUserDocCountDelta } = useCPanelPageContext();
  const [isFileRenameDialogOpen, setIsFileRenameDialogOpen] = useState(false);
  const [isFileDeleteAlertOpen, setIsFileDeleteAlertOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<UserFile>();
  const { uploadingFiles, uploadFiles, deleteUploadingFiles, retryUpload } =
    useUploadDocuments();
  const { downloadFile } = useDownloadFile();
  const [deleteDocument, { isLoading: isLoadingDeleteFile }] =
    usersApi.useDeleteDocumentMutation();
  const [renameDocument, { isLoading: isLoadingRenameDocument }] =
    usersApi.useRenameDocumentMutation();
  const inputRef = useRef<HTMLInputElement>(null);
  const { toast } = useToast({ variant: "web" });

  const handleDeleteFile = async () => {
    if (!entity?.id || !user?.id || !selectedFile || isLoadingDeleteFile)
      return;
    const resp = await deleteDocument({
      entityId: entity.id,
      id: selectedFile.id,
      userId: user.id,
    });
    if (resp && "data" in resp) {
      updateUserDocCountDelta(user.id, -1);
      setSelectedFile(undefined);
      setIsFileDeleteAlertOpen(false);
      toast(
        t(
          {
            id: "cpanel-page.file-was-deleted",
            defaultMessage: "{file} File was deleted",
          },
          {
            file: 1,
          }
        )
      );
    }
  };

  const handleFileInputChange = async () => {
    if (!inputRef.current?.files || !user?.id) {
      return;
    }
    const files = Array.from(inputRef.current.files);
    try {
      await uploadFiles(files, user.id, {
        type: "file",
        user_id: user.id,
        sub_module: "cpanel",
      });
      updateUserDocCountDelta(user.id, 1);
    } catch (error) {
      // do nothing
    } finally {
      inputRef.current.value = "";
    }
  };

  const handleRenameFile = async (name: string) => {
    if (!entity?.id || !selectedFile || !user?.id || isLoadingRenameDocument)
      return;
    const resp = await renameDocument({
      entityId: entity.id,
      name,
      id: selectedFile.id,
      userId: user.id,
    });
    if (resp && "data" in resp) {
      setSelectedFile(undefined);
      setIsFileRenameDialogOpen(false);
      toast(
        t({
          id: "cpanel-page.file-successfully-renamed",
          defaultMessage: "File successfully renamed",
        })
      );
    }
  };

  const handleDownloadFile = async (file: UserFile) => {
    if (!entity?.id) return;
    const resp = await downloadFile({
      entityId: entity.id,
      id: file.id,
      fileName: file.name,
      fileSize: file.size,
      mimeType: file.content_type,
    });
    if (resp && "data" in resp) {
      downloadBlobAsFile(resp.data, file.name);
    } else {
      throw new Error();
    }
  };

  const { data, isLoading, error, refetch } = usersApi.useGetUserDocumentsQuery(
    entity?.id && user?.id && isOpen
      ? {
          entityId: entity.id,
          userId: user.id,
        }
      : skipToken
  );

  const currentUploadingFiles = useMemo(
    () =>
      uploadingFiles?.filter(
        (item) => item.userId === user?.id && !item.isCompleted
      ),
    [uploadingFiles, user?.id]
  );

  const $uploadingFiles =
    currentUploadingFiles.length !== 0 && user?.id ? (
      <div className="flex flex-col gap-4 bg-white p-8">
        {currentUploadingFiles.map((item) => (
          <UploadingFile
            item={item}
            key={item.id}
            onDelete={deleteUploadingFiles}
            onRetry={() => {
              if (!user?.id) {
                return;
              }
              retryUpload(
                item.id,
                {
                  type: "file",
                  user_id: user.id,
                  sub_module: "cpanel",
                },
                user?.id
              );
            }}
          />
        ))}
      </div>
    ) : null;

  return (
    <>
      <SidebarDrawer
        isOpen={isOpen}
        onClose={
          isFileRenameDialogOpen || isFileDeleteAlertOpen
            ? () => null
            : onRequestClose
        }
        hasBackdrop
        title={`${user?.display_name} Documents`}
      >
        <SidebarDrawer.Content className="bg-grey-200">
          <div className="flex h-full flex-col gap-2">
            {isLoading ? (
              <div className="flex grow items-center justify-center">
                <LoadingSpinner />
              </div>
            ) : error ? (
              <div className="text-tertiary-400 flex grow flex-col items-center justify-center gap-4 text-lg">
                {t({
                  id: "cpanel-page.something-went-wrong",
                  defaultMessage: "Something went wrong!",
                })}
                <Button onClick={refetch}>
                  {t({
                    id: "cpanel-page.click-to-try-again",
                    defaultMessage: "Click to try again",
                  })}
                </Button>
              </div>
            ) : (
              <>
                <div className="flex w-full items-center justify-between bg-white p-8">
                  <span>
                    {t({
                      id: "cpanel-page.documents",
                      defaultMessage: "Documents",
                    })}
                  </span>
                  <button
                    className="bg-grey-200 flex h-8 w-[65px] cursor-pointer items-center justify-center gap-2 rounded-lg border-none outline-none"
                    type="button"
                    onClick={() => inputRef.current?.click()}
                  >
                    <span className="text-dark text-sm">
                      {t({
                        id: "common.add",
                        defaultMessage: "Add",
                      })}
                    </span>
                    <PlusIcon />
                  </button>
                </div>
                {$uploadingFiles}
                {data && data.length > 0 && (
                  <div className="bg-white p-8">
                    {data.map((document) => (
                      <FileItem
                        item={document}
                        onDelete={(file: UserFile) => {
                          setIsFileDeleteAlertOpen(true);
                          setSelectedFile(file);
                        }}
                        onRename={(file: UserFile) => {
                          setIsFileRenameDialogOpen(true);
                          setSelectedFile(file);
                        }}
                        onDownload={handleDownloadFile}
                      />
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        </SidebarDrawer.Content>
      </SidebarDrawer>
      <input
        ref={inputRef}
        type="file"
        multiple
        onChange={handleFileInputChange}
        hidden
      />
      <FileRenameDialog
        isOpen={isFileRenameDialogOpen}
        onRequestClose={() => setIsFileRenameDialogOpen(false)}
        file={selectedFile}
        isLoading={isLoadingRenameDocument}
        onRename={handleRenameFile}
      />
      <Alert
        isOpen={isFileDeleteAlertOpen}
        onRequestClose={() => setIsFileDeleteAlertOpen(false)}
        title={t({
          id: "cpanel-page.delete-file",
          defaultMessage: "Delete file",
        })}
        content={t(
          {
            id: "cpanel-page.delete-file-confirmation-message",
            defaultMessage: "Are you sure you want to delete file {fileName}?",
          },
          {
            fileName: (
              <span className="text-primary font-semibold">
                {selectedFile?.name}
              </span>
            ),
          }
        )}
        buttons={[
          {
            text: t({
              id: "common.cancel",
              defaultMessage: "Cancel",
            }),
            role: "close",
            onClick: () => setIsFileDeleteAlertOpen(false),
          },
          {
            text: t({
              id: "common.delete",
              defaultMessage: "Delete",
            }),
            color: "tertiary",
            isDisabled: isLoadingDeleteFile,
            onClick: handleDeleteFile,
          },
        ]}
      />
    </>
  );
};
