import { DraggableChecklistItem } from "./types";

export const produceChecklistItem = (
  text: string,
  isCompleted: boolean
): DraggableChecklistItem => ({
  id: Math.random().toString().substring(2, 6),
  text,
  isCompleted,
});
