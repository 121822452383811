import {
  createContext,
  FC,
  useContext,
  useMemo,
  useState,
  useRef,
  useCallback,
} from "react";
import Compressor from "compressorjs";
import { useEffectOnce } from "react-use";
import {
  HookOutOfContextError,
  useTranslations,
  useAppVariant,
  useToast,
} from "@jugl-web/utils";

export interface FileSelectConfig {
  maxFileSize: number;
  multiple?: boolean;
  acceptTypes?: string;
  compressionConfig?: {
    imageQuality: number;
    imageMaxResolution: number;
    targetSizeInKb: number;
  };
}
export interface FileSelectContextValue {
  selectFile: () => Promise<File[] | null | undefined>;
  setConfig: (config: FileSelectConfig) => void;
}

const FileSelectContext = createContext<FileSelectContextValue | null>(null);

interface FileSelectProviderProps {
  children: JSX.Element;
}
const defaultConfig = {
  multiple: false,
  maxFileSize: 75 * 1024 * 1024,
  acceptTypes: "*/*",
};
export const FileSelectProvider: FC<FileSelectProviderProps> = ({
  children,
}) => {
  const { t } = useTranslations();
  const { toast } = useToast({ variant: useAppVariant().variant });
  const [config, setConfig] = useState<FileSelectConfig>(defaultConfig);
  const $fileInputRef = useRef<HTMLInputElement>(null);
  const compressFiles = useCallback(
    async (files: FileList | null | void) => {
      if (!files) {
        return null;
      }
      if (!config.compressionConfig) {
        return Array.from(files);
      }
      const { compressionConfig } = config;

      const filesArray = Array.from(files);
      const compressedFilesPromises = filesArray.map((file: File) => {
        const isImage = file.type.includes("image");
        const imageTargetSize = compressionConfig.targetSizeInKb || 2048 * 1024;
        const isLessThanTargetSize = file.size < imageTargetSize;
        if (!isImage || (isImage && isLessThanTargetSize)) {
          return Promise.resolve(file);
        }
        return new Promise<File>((resolve, reject) => {
          Reflect.construct(Compressor, [
            file,
            {
              convertSize: imageTargetSize,
              quality: compressionConfig.imageQuality,
              maxWidth: compressionConfig.imageMaxResolution,
              maxHeight: compressionConfig.imageMaxResolution,
              success: (compressedFile: File | Blob) => {
                let blobAsFile = null;
                if (compressedFile instanceof Blob) {
                  blobAsFile = new File([compressedFile], file.name, {
                    type: compressedFile.type,
                  });
                }
                resolve(blobAsFile ?? (compressedFile as File));
              },
              error: (error: Error) => {
                reject(error);
              },
            },
          ]);
        });
      });

      return Promise.all(compressedFilesPromises);
    },
    [config]
  );
  const getFiles = useCallback(
    () =>
      new Promise<FileList | null>((resolve, reject) => {
        const fileInput = $fileInputRef.current;
        if (!fileInput) {
          reject(new Error("File input not found"));
          return;
        }
        fileInput.click();
        fileInput.onchange = async (event) => {
          const target = event.target as HTMLInputElement;
          if (!target.files) {
            resolve(null);
          }
          if (target.files) {
            Array.from(target.files).forEach((file) => {
              if (file.size > config.maxFileSize) {
                const maxFileSizeInMb = (
                  config.maxFileSize /
                  (1024 * 1024)
                ).toFixed(2);
                toast(
                  t(
                    {
                      id: "feedback.too-large-file-megabytes",
                      defaultMessage:
                        "The file is too large. The maximum size is {fileSize}MB",
                    },
                    {
                      fileSize: maxFileSizeInMb,
                    }
                  ),
                  { variant: "error" }
                );
                reject(new Error("File too large"));
              }
            });
            resolve(target.files);
          }
        };
      }),
    [config.maxFileSize, t, toast]
  );
  const selectFile = useCallback(async () => {
    if ($fileInputRef.current) {
      $fileInputRef.current.value = "";
    }
    return getFiles()
      .then(compressFiles)
      .catch(() => null);
  }, [compressFiles, getFiles]);
  const contextValue = useMemo(
    () => ({
      selectFile,
      setConfig,
    }),
    [selectFile]
  );
  return (
    <FileSelectContext.Provider value={contextValue}>
      {children}
      <input
        ref={$fileInputRef}
        type="file"
        multiple={config.multiple}
        accept={config.acceptTypes}
        className="invisible absolute -left-[10000px] opacity-0"
      />
    </FileSelectContext.Provider>
  );
};

export const useFileSelect = (config: FileSelectConfig) => {
  const context = useContext(FileSelectContext);

  if (!context) {
    throw new HookOutOfContextError("useFileSelect", "FileSelectContext");
  }

  useEffectOnce(() => {
    context.setConfig({ ...defaultConfig, ...config });
  });
  return context;
};
