import { FC } from "react";
import { TABLE_DIMENSIONS } from "../../consts";

export const CellLeftMargin: FC = () => (
  <div
    className="absolute z-10 w-[3px] bg-[#D6D6D6]"
    style={{
      left: `-${TABLE_DIMENSIONS.TABLE_CELL_GAP}px`,
      top: `-${TABLE_DIMENSIONS.TABLE_CELL_GAP / 2}px`,
      height: `calc(100% + ${TABLE_DIMENSIONS.TABLE_CELL_GAP}px)`,
    }}
  />
);
