import { cx } from "@jugl-web/utils";
import { InputHTMLAttributes, forwardRef } from "react";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  variant?: "underline" | "outlined";
  isError?: boolean;
  maxLength?: number;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type = "text",
      variant = "underline",
      className,
      isError,
      maxLength,
      ...props
    },
    ref
  ) => {
    const isOverMaxLength =
      maxLength && props.value && String(props.value).length > maxLength;
    const shouldShowCounter =
      maxLength && props.value && String(props.value).length > maxLength - 3;
    return (
      <>
        <input
          ref={ref}
          type={type}
          className={cx(
            "border-0 border-solid border-black/20 p-2.5 outline-none",
            "text-base font-medium leading-4 text-[#363636] transition-colors",
            "placeholder:text-dark-500 placeholder:font-normal",
            "focus:border-primary-300",
            variant === "underline" ? "border-b" : "rounded-lg border",
            (isError || isOverMaxLength) &&
              "border-gradients-danger focus:border-gradients-danger",
            className
          )}
          {...props}
        />
        {(shouldShowCounter && (
          <div
            className={cx("text-gradients-danger mt-2 text-xs", {
              "text-gradients-danger": isOverMaxLength,
              "text-grey-A100": !isOverMaxLength,
            })}
          >{`${String(props.value).length || 0}/${maxLength}`}</div>
        )) ||
          ""}
      </>
    );
  }
);
