import React, { useContext, useEffect, useState } from "react";
import { useMe } from "@web-src/features/app/hooks/useMe";
import useDebounce from "@web-src/hooks/useDebounce";
import { CallsContext } from "../../../../providers";
import UserCallWindow from "../UserCallWindow";

const LocalUserCallWindow: React.FC<{
  isCall?: boolean;
  duration?: string;
  screenSharingId?: string;
  gridAvatarSize?: number;
}> = ({ isCall, duration, screenSharingId, gridAvatarSize }) => {
  const { me } = useMe();
  const { activeCall } = useContext(CallsContext);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const debouncedIsSpeaking = useDebounce(isSpeaking, 1000);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newVolumeLevel = activeCall?.localAudioTrack?.getVolumeLevel() || 0;
      const newTalkingState = newVolumeLevel > 0.45;
      if (isSpeaking !== newTalkingState) setIsSpeaking(newTalkingState);
    }, 250);

    return () => clearInterval(intervalId);
  }, [activeCall, isSpeaking]);
  return (
    <UserCallWindow
      isSpeaking={(debouncedIsSpeaking && activeCall?.audioEnabled) || false}
      userId={me?.id || ""}
      isVideoMuted={!activeCall?.videoEnabled}
      isAudioMuted={!activeCall?.audioEnabled}
      playerId="localplayer"
      isCall={isCall}
      duration={duration}
      screenSharingId={screenSharingId}
      gridAvatarSize={gridAvatarSize}
    />
  );
};

export default LocalUserCallWindow;
