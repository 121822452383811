import { PlainButton } from "@jugl-web/ui-components/cross-platform/PlainButton";
import { Tooltip } from "@jugl-web/ui-components/cross-platform/Tooltip";
import { FC, ReactNode } from "react";

interface DrawerHeaderNavButtonProps {
  children: ReactNode;
  tip?: string;
  onClick?: () => void;
}

export const DrawerHeaderNavButton: FC<DrawerHeaderNavButtonProps> = ({
  children,
  tip,
  onClick,
}) => (
  <Tooltip
    isDisabled={!tip}
    className="px-3"
    renderTrigger={({ ref, props }) => (
      <PlainButton
        ref={ref}
        className="hover:bg-grey-200 rounded-lg transition-colors"
        onClick={onClick}
        {...props}
      >
        {children}
      </PlainButton>
    )}
  >
    {tip}
  </Tooltip>
);
