import { useCallback, useEffect, useRef, useState } from "react";
import { secondsToTime } from "@web-src/utils/datetime";
import { logger } from "@web-src/utils/logger";
import mime from "mime";

export enum AudioRecorderError {
  noPermissions = "noPermissions",
}

export const useAudioRecorder = (
  {
    maxTimeInSeconds,
    autoStart,
    volumeLevelListener,
  }: {
    maxTimeInSeconds: number;
    autoStart: boolean;
    volumeLevelListener?: (level: number) => void;
  } = { autoStart: true, maxTimeInSeconds: 300 }
) => {
  const [lastRecordedFile, setLastRecordedFile] = useState<{
    file: File;
    url: string;
  }>();
  const [timeInSeconds, setTimeInSeconds] = useState<number>(0);
  const [error, setError] = useState<AudioRecorderError>();
  const getMediaStream = useCallback(async () => {
    try {
      return await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
    } catch (mediaStreamError) {
      logger.error(mediaStreamError);
      // TODO: better error handling?
      setError(AudioRecorderError.noPermissions);
      return null;
    }
  }, []);

  const currentTimer = useRef<ReturnType<typeof setInterval> | null>(null);
  const mediaStream = useRef<MediaStream | null>(null);
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const audioChunks = useRef<Blob[]>([]);
  const isRecording = useRef<boolean>(false);
  const mimeType = useRef<string>();

  const audioProcessCallback = useRef<() => void>();
  const scriptProcessorRef = useRef<ScriptProcessorNode>();

  const stop = useCallback(() => {
    isRecording.current = false;
    mediaStream.current?.getAudioTracks()?.forEach((item) => item.stop());
    mediaRecorder.current?.stop();
    if (currentTimer.current) {
      clearInterval(currentTimer.current);
    }

    mediaStream.current = null;
    mediaRecorder.current = null;
    currentTimer.current = null;
    if (scriptProcessorRef.current && audioProcessCallback.current) {
      scriptProcessorRef.current.addEventListener(
        "audioprocess",
        audioProcessCallback.current
      );
      scriptProcessorRef.current = undefined;
      audioProcessCallback.current = undefined;
    }

    setTimeInSeconds(0);
  }, []);

  const start = useCallback(async () => {
    if (mediaStream.current) {
      throw new Error("Recording already started");
    }
    mediaStream.current = await getMediaStream();
    if (!mediaStream.current) {
      return;
    }
    isRecording.current = true;
    mediaRecorder.current = new MediaRecorder(mediaStream.current);
    mediaRecorder.current.addEventListener("dataavailable", (event) => {
      if (event.data && event.data.size > 0) {
        audioChunks.current.push(event.data);
      }
      if (!isRecording.current) {
        const fileMimeType = mimeType.current || "audio/webm";
        const file = new File(
          audioChunks.current,
          `recording.${mime.getExtension(fileMimeType)}`,
          {
            type: fileMimeType,
          }
        );
        const url = window.URL.createObjectURL(file);
        setLastRecordedFile({
          file,
          url,
        });
      }
    });
    currentTimer.current = setInterval(() => {
      setTimeInSeconds((prev) => prev + 1);
    }, 1000);
    mediaRecorder.current.start();

    const audioContext = new AudioContext();

    const analyser = audioContext.createAnalyser();
    const microphone = audioContext.createMediaStreamSource(
      mediaStream.current
    );
    // TODO: replace with AudioWorkletNode
    const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);
    scriptProcessorRef.current = scriptProcessor;

    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 1024;

    microphone.connect(analyser);
    analyser.connect(scriptProcessor);
    scriptProcessor.connect(audioContext.destination);
    audioProcessCallback.current = () => {
      if (!mimeType.current) {
        mimeType.current = mediaRecorder.current?.mimeType;
      }
      const array = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(array);
      const arraySum = array.reduce((a, value) => a + value, 0);
      const average = arraySum / array.length;
      volumeLevelListener?.(Math.round(average));
    };
    scriptProcessor.addEventListener(
      "audioprocess",
      audioProcessCallback.current
    );
  }, [getMediaStream, volumeLevelListener]);

  useEffect(() => {
    if (timeInSeconds > maxTimeInSeconds) {
      stop();
    }
  }, [timeInSeconds, maxTimeInSeconds, stop]);

  useEffect(() => {
    if (autoStart && !mediaStream.current) {
      start();
    }
  }, [autoStart, start]);

  return {
    start,
    timeInSeconds,
    timeStr: secondsToTime(timeInSeconds),
    stop,
    error,
    lastRecordedFile,
  };
};
