import { FC } from "react";
import { Alert, Avatar, Text } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";

export const AcceptInvitationAlert: FC<{
  isOpen: boolean;
  inviterAvatarUrl: string | null;
  inviterName: string;
  entityAvatar: string | null;
  entityName: string;
  isAcceptingLinkInvitation: boolean;
  onClose: () => void;
  onAccept: () => void;
}> = ({
  isOpen,
  inviterAvatarUrl,
  inviterName,
  entityAvatar,
  entityName,
  isAcceptingLinkInvitation,
  onClose,
  onAccept,
}) => {
  const { t } = useTranslations();
  return (
    <Alert
      isCloseButtonVisible
      isOpen={isOpen}
      onRequestClose={onClose}
      img={
        <div className="relative grid h-[150px] w-[150px]">
          <Avatar imageUrl={entityAvatar} username={entityName} size="5xl" />
          <div className="absolute bottom-0 right-0 flex h-[60px] w-[60px] items-center justify-center rounded-full bg-white">
            <Avatar
              imageUrl={inviterAvatarUrl}
              username={inviterName}
              className="h-[60px] w-[60px] border-[6px] border-solid border-white"
            />
          </div>
        </div>
      }
      header={t({
        id: "common.confirm-action",
        defaultMessage: "Confirm action",
      })}
      title={t({
        id: "entity-select-page.join-workspace",
        defaultMessage: "Join Workspace",
      })}
      content={
        <Text variant="body2" className="text-dark-800">
          {t(
            {
              id: "entity-select-page.do-you-really-want-to-join",
              defaultMessage:
                "Do you really want to join the Workspace <highlightedText>{entityName}</highlightedText>?",
            },
            {
              highlightedText: (text: (string | JSX.Element)[]) => (
                <span className="text-primary-800 font-semibold">{text}</span>
              ),
              entityName,
            }
          )}
        </Text>
      }
      buttons={[
        {
          text: t({ id: "common.join", defaultMessage: "Join" }),
          color: "primary",
          onClick: onAccept,
          isDisabled: isAcceptingLinkInvitation,
        },
      ]}
    />
  );
};
