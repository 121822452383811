import { useTranslations, useUniqueDOMId } from "@jugl-web/utils";
import { withLeadingZero } from "@jugl-web/utils/utils/withLeadingZero";
import { ChangeEvent, FC, useState } from "react";
import { Button } from "../../Button";
import {
  TimeComponents,
  convertMillisecondsToTimeComponents,
  convertTimeComponentsToMilliseconds,
} from "./utils";

export interface TimeSpentPickerProps {
  initialTimeMs?: number;
  onApply: (timeMs: number) => void;
  onClose: () => void;
}

const MIN_HOURS = 0;
const MAX_HOURS = 999;

const MIN_MINUTES = 0;
const MAX_MINUTES = 59;

const isHoursValid = (value: number) =>
  value >= MIN_HOURS && value <= MAX_HOURS;

const isMinutesValid = (value: number) =>
  value >= MIN_MINUTES && value <= MAX_MINUTES;

export const TimeSpentPicker: FC<TimeSpentPickerProps> = ({
  initialTimeMs = 0,
  onApply,
  onClose,
}) => {
  const [internalState, setInternalState] = useState<TimeComponents>(() =>
    convertMillisecondsToTimeComponents(initialTimeMs)
  );

  const hoursLabelId = useUniqueDOMId();
  const minutesLabelId = useUniqueDOMId();
  const { t } = useTranslations();

  const handleHoursChange = (event: ChangeEvent<HTMLInputElement>) => {
    const hours = parseInt(event.target.value, 10);

    if (!isHoursValid(hours)) {
      return;
    }

    setInternalState((previousInternalState) => ({
      ...previousInternalState,
      hours,
    }));
  };

  const handleMinutesChange = (event: ChangeEvent<HTMLInputElement>) => {
    const minutes = parseInt(event.target.value, 10);

    if (!isMinutesValid(minutes)) {
      return;
    }

    setInternalState((previousInternalState) => ({
      ...previousInternalState,
      minutes,
    }));
  };

  return (
    <div>
      <h4 className="text-primary mt-0 mb-[61px] text-center text-sm font-medium leading-[14px]">
        {t({
          id: "tasks-page.time-spent-on-task",
          defaultMessage: "Time spent on Task",
        })}
      </h4>
      <div className="mb-[77px] flex items-center justify-center gap-4">
        <label htmlFor={hoursLabelId} className="text-grey text-xs uppercase">
          {t({ id: "common.hrs", defaultMessage: "hrs" })}
        </label>
        <input
          id={hoursLabelId}
          type="number"
          min={MIN_HOURS}
          max={MAX_HOURS}
          value={withLeadingZero(internalState.hours)}
          onChange={handleHoursChange}
          className="border-grey-200 jugl__focusable-outline min-w-0 rounded-md border border-solid px-4 py-2 text-center text-2xl font-medium [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
        />
        <span className="text-dark-900 -translate-y-1 text-[54px]">:</span>
        <input
          id={minutesLabelId}
          type="number"
          min={MIN_MINUTES}
          max={MAX_MINUTES}
          value={withLeadingZero(internalState.minutes)}
          onChange={handleMinutesChange}
          className="border-grey-200 jugl__focusable-outline min-w-0 rounded-md border border-solid px-4 py-2 text-center text-2xl font-medium [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor={minutesLabelId} className="text-grey text-xs uppercase">
          {t({ id: "common.minute-short", defaultMessage: "min" })}
        </label>
      </div>
      <div className="flex items-center gap-2.5">
        <Button
          variant="outlined"
          color="primary"
          uppercase
          fullWidth
          onClick={onClose}
        >
          {t({ id: "common.cancel", defaultMessage: "Cancel" })}
        </Button>
        <Button
          variant="contained"
          color="primary"
          uppercase
          fullWidth
          onClick={() => {
            onApply(convertTimeComponentsToMilliseconds(internalState));
            onClose();
          }}
        >
          {t({ id: "common.apply", defaultMessage: "Apply" })}
        </Button>
      </div>
    </div>
  );
};
