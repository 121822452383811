import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  getJwtToken,
  removeJwtToken,
  setJwtToken,
} from "@web-src/features/auth/utils";
import { reset } from "@web-src/features/chats/chatsSlice";
import { RootState } from "@web-src/store";
import { AuthData } from "./types";

type AuthState = {
  isAuthenticated: boolean;
  authData?: AuthData;
};

export const proceedLogin = createAsyncThunk<
  AuthData | null,
  { token: string; refreshToken: string }
>("auth/proceedLogin", async ({ token, refreshToken }) =>
  setJwtToken(token, refreshToken)
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { dispatch }) => {
    dispatch(reset());
    removeJwtToken();
  }
);

const getInitialState = (): AuthState => {
  const authData = getJwtToken();
  return {
    isAuthenticated: !!authData,
    authData: authData || undefined,
  };
};

const authSlice = createSlice({
  name: "auth",
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(proceedLogin.fulfilled, (state, { payload }) => {
      state.authData = payload || undefined;
      state.isAuthenticated = true;
    });
    builder.addCase(logout.fulfilled, () => getInitialState());
  },
});

export const selectAuthState = ({ auth }: RootState) => auth;
export const selectAuthDataState = ({ auth: { authData } }: RootState) =>
  authData;

export const selectUserId = createSelector(
  [(state: RootState) => state.auth.authData],
  (authData) => authData?.data?.jid
);

export const selectIsAuthenticated = createSelector(
  [(state: RootState) => state.auth],
  (auth) => auth?.isAuthenticated
);

export default authSlice;
