import { environment } from "@web-src/environments/environment";

export const entityIdStorageKey = "jugl:entityId";

export const getAppId = () => environment.firebaseTokenId;

export const isRunningLocally = () =>
  Boolean(
    window.location.hostname === "localhost" ||
      window.location.hostname === "[::1]" ||
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      ) ||
      window.location.hostname === ""
  );
