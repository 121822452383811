import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import { FirebaseApp, FirebaseError, initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  Messaging,
  onMessage,
} from "firebase/messaging";
import { getAnalytics, logEvent as firebaseLogEvent } from "firebase/analytics";
import { Subject } from "rxjs";
import { logger } from "@web-src/utils/logger";
import { environment } from "@web-src/environments/environment";
import { useNotifications } from "@web-src/modules/notifications/providers/NotificationsProvider";
import { HookOutOfContextError } from "@jugl-web/utils";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { captureMessage } from "@sentry/react";
import { FirebaseNotification, SupportedFirebaseEvent } from "./types";
import { useProcessFCMNotification } from "./hooks/useProcessFCMNotification";

export const rawFCMNotifications$ = new Subject<unknown>();

export const FCMContext = createContext<{
  app: FirebaseApp | undefined;
  messaging: Messaging | undefined;
  notifications$: Subject<FirebaseNotification>;
  broadcastChannel: BroadcastChannel;
  token: string | undefined;
  logEvent: (supportedEvent: SupportedFirebaseEvent) => void;
} | null>(null);

export const FCMProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [app, setApp] = useState<FirebaseApp | undefined>();
  const [messaging, setMessaging] = useState<Messaging | undefined>();
  const notifications$ = useMemo(() => new Subject<FirebaseNotification>(), []);
  const [token, setToken] = useState<string>();
  const processFCMNotification = useProcessFCMNotification();
  const { triggerInAppNotification } = useNotifications();
  const { entity } = useEntity();
  const broadcastChannel = useMemo(
    () => new BroadcastChannel("sw-notifications"),
    []
  );

  useEffect(() => {
    if (app || messaging) {
      return;
    }

    const fbApp = initializeApp(environment.firebaseConfig);
    getAnalytics(fbApp);
    setApp(fbApp);
    const fbMessaging = getMessaging(fbApp);
    setMessaging(fbMessaging);
    getToken(fbMessaging, {
      vapidKey: environment.firebaseVapidKey,
    })
      .then((e) => {
        logger.info("[Firebase token]", e);
        setToken(e);
      })
      .catch((error: FirebaseError) => {
        // NOTE: 20 is unsupported registration
        if (
          error?.code === "messaging/permission-blocked" ||
          parseInt(error?.code, 10) === 20
        ) {
          // do nothing
          return;
        }
        captureMessage(`FCM token error:  ${error.code}`);
      });
  }, [messaging, app]);

  const logEvent = useCallback(
    (supportedEvent: SupportedFirebaseEvent) => {
      firebaseLogEvent(getAnalytics(), supportedEvent, {
        entity_id: entity?.id,
        entity_name: entity?.name,
      });
    },
    [entity]
  );

  useEffect(() => {
    if (!token || !messaging) {
      return undefined;
    }
    const notificationHandler = (e: FirebaseNotification) => {
      rawFCMNotifications$.next(e);
      const processedNotification = processFCMNotification(e);
      if (!processedNotification) return;
      triggerInAppNotification(processedNotification);
      notifications$.next(e);
    };
    onMessage(messaging, (e) => {
      logger.info("[foregroundMessage]", e);
      notificationHandler(e);
    });
    // TODO: Better type
    const broadcastChannelHandler = (e: MessageEvent) => {
      logger.info("[backgroundMessage]", e?.data?.notification);
      notificationHandler(e?.data?.notification);
    };
    broadcastChannel?.addEventListener("message", broadcastChannelHandler);
    return () => {
      broadcastChannel?.removeEventListener("message", broadcastChannelHandler);
    };
  }, [
    broadcastChannel,
    messaging,
    notifications$,
    token,
    app,
    triggerInAppNotification,
    processFCMNotification,
  ]);

  const value = useMemo(
    () => ({
      app,
      messaging,
      notifications$,
      broadcastChannel,
      token,
      logEvent,
    }),
    [app, messaging, notifications$, broadcastChannel, token, logEvent]
  );
  return <FCMContext.Provider value={value}>{children}</FCMContext.Provider>;
};

export const useFCM = () => {
  const context = useContext(FCMContext);

  if (!context) {
    throw new HookOutOfContextError("useFCM", "FCMContext");
  }

  return context;
};
