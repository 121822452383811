import React, { useCallback, useState } from "react";
import { SideImageLayout } from "@jugl-web/ui-components/web/layouts";
import { useUserAuth } from "@web-src/modules/auth/providers/UserAuthProvider";
import { useTranslations } from "@jugl-web/utils";
import { DiscardChangesDialog } from "@web-src/modules/common/components";
import ProfileCreationForm from "./components/ProfileCreationForm";
import ProfileInfoSection from "./components/ProfileInfoSection";

const ProfileForm: React.FC = () => {
  const [formOpened, setFormOpened] = useState(false);
  const [discardChangesDialogIsOpen, setDiscardChangesDialogIsOpen] =
    useState(false);
  const { logout } = useUserAuth();
  const { t } = useTranslations();

  const handleBackClick = useCallback(() => {
    if (formOpened) {
      setDiscardChangesDialogIsOpen(true);
      return;
    }
    logout(false);
  }, [logout, formOpened]);

  return (
    <>
      <SideImageLayout onBackClick={handleBackClick}>
        {!formOpened ? (
          <ProfileInfoSection onClick={() => setFormOpened(true)} />
        ) : (
          <ProfileCreationForm />
        )}
      </SideImageLayout>
      <DiscardChangesDialog
        isOpen={discardChangesDialogIsOpen}
        content={t({
          id: "profile-form-page.discard-account-will-not-be-created",
          defaultMessage: "If you discard, account will not be created",
        })}
        onClose={() => setDiscardChangesDialogIsOpen(false)}
        onSubmit={() => {
          setDiscardChangesDialogIsOpen(false);
          setFormOpened(false);
        }}
      />
    </>
  );
};

export default ProfileForm;
