import { cx, onArrowDown, onArrowUp } from "@jugl-web/utils";
import { withLeadingZero } from "@jugl-web/utils/utils/withLeadingZero";
import { DPUserConfig } from "@rehookify/datepicker";
import { FC, KeyboardEvent } from "react";
import { PlainButton } from "../../../PlainButton";
import { ReactComponent as ColonIcon } from "../../assets/colon.svg";
import { ReactComponent as ChevronDownIcon } from "../../assets/grey-chevron-down.svg";
import { ReactComponent as ChevronUpIcon } from "../../assets/grey-chevron-up.svg";
import { useTimePicker } from "../../hooks/useTimePicker";

interface TimePickerProps {
  minDate?: Date;
  maxDate?: Date;
  className?: string;
  onDatesChange: DPUserConfig["onDatesChange"];
}

export const TimePicker: FC<TimePickerProps> = ({
  minDate,
  maxDate,
  className,
  onDatesChange,
}) => {
  const {
    hoursIn12HourFormat,
    minutes,
    meridiem,
    bumpHours,
    dropHours,
    changeHours,
    bumpMinutes,
    dropMinutes,
    changeMinutes,
    toggleMeridiem,
  } = useTimePicker({ onDatesChange, minDate, maxDate });

  const handleHoursInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const arrowUpHandler = onArrowUp((e) => {
      e.preventDefault();
      bumpHours();
    });

    const arrowDownHandler = onArrowDown((e) => {
      e.preventDefault();
      dropHours();
    });

    arrowUpHandler(event);
    arrowDownHandler(event);
  };

  const handleMinutesInputKeyDown = (
    event: KeyboardEvent<HTMLInputElement>
  ) => {
    const arrowUpHandler = onArrowUp((e) => {
      e.preventDefault();
      bumpMinutes();
    });

    const arrowDownHandler = onArrowDown((e) => {
      e.preventDefault();
      dropMinutes();
    });

    arrowUpHandler(event);
    arrowDownHandler(event);
  };

  return (
    <div
      className={cx(
        "bg-grey-100 flex items-center justify-center gap-4 rounded-lg px-4 py-2",
        className
      )}
    >
      <div className="flex flex-col items-center gap-2">
        <PlainButton onClick={bumpHours}>
          <ChevronUpIcon />
        </PlainButton>
        <input
          type="number"
          value={withLeadingZero(hoursIn12HourFormat)}
          className="jugl__focusable-outline text-dark bg-dark-100 hover:bg-dark-200 focus:bg-dark-200 h-11 w-full min-w-0 rounded-md border-0 px-4 text-center text-2xl transition-colors [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          onChange={(event) => changeHours(Number(event.target.value))}
          onKeyDown={handleHoursInputKeyDown}
        />
        <PlainButton onClick={dropHours}>
          <ChevronDownIcon />
        </PlainButton>
      </div>
      <ColonIcon className="shrink-0" />
      <div className="flex flex-col items-center gap-2">
        <PlainButton onClick={bumpMinutes}>
          <ChevronUpIcon />
        </PlainButton>
        <input
          type="number"
          className="jugl__focusable-outline text-dark bg-dark-100 hover:bg-dark-200 focus:bg-dark-200 h-11 w-full min-w-0 rounded-md border-0 px-4 text-center text-2xl transition-colors [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          value={withLeadingZero(minutes)}
          onChange={(event) => changeMinutes(Number(event.target.value))}
          onKeyDown={handleMinutesInputKeyDown}
        />
        <PlainButton onClick={dropMinutes}>
          <ChevronDownIcon />
        </PlainButton>
      </div>
      <button
        type="button"
        className="bg-dark-100 text-dark hover:bg-dark-200 focus:bg-dark-200 h-11 cursor-pointer rounded-md border-0 px-4 text-base font-medium transition-colors"
        onClick={toggleMeridiem}
      >
        {meridiem}
      </button>
    </div>
  );
};
