import { FormField } from "@jugl-web/rest-api";

export const sortFormFields = (data: FormField[]) =>
  [...data].sort((a, b) => {
    const sectionComparison = b.section.localeCompare(a.section);

    if (sectionComparison === 0) {
      return a.pos - b.pos;
    }

    return sectionComparison;
  }) || [];
