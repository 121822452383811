export const extractNumberFromSoundType = (soundType?: string) => {
  if (!soundType) return null;

  const match = soundType.match(/\d+/);

  if (match) {
    const soundId = parseInt(match[0], 10);
    if (soundId >= 0 && soundId <= 12) {
      return soundId;
    }
    return null;
  }

  return null;
};
