import React, { useEffect, useState } from "react";
import { HeaderBreadcrumbs } from "@jugl-web/ui-components/web/HeaderBreadcrumbs";
import startOfToday from "date-fns/startOfToday";
import startOfISOWeek from "date-fns/startOfISOWeek";
import startOfDay from "date-fns/startOfDay";
import format from "date-fns/format";
import parse from "date-fns/parse";
import { useParams, useLocation } from "react-router-dom";
import { useTranslations } from "@jugl-web/utils";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { ReactComponent as CalendarIcon } from "./assets/calendar1.svg";
import DatePicker from "./components/DatePicker";
import List from "./components/List";

const SchedulePage: React.FC = () => {
  const { navigateToPage } = useNavigation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { t } = useTranslations();
  const { date } = useParams();
  const routeDate = date && startOfDay(parse(date, "yyyy-MM-dd", new Date()));
  const today = startOfToday();
  const [searchQuery, setSearch] = useState<string>(
    queryParams.get("search") || ""
  );
  const startOfWeek = startOfISOWeek(routeDate || today);
  const [currentDate, setCurrentDate] = useState<Date>(startOfWeek);

  useEffect(() => {
    navigateToPage(
      "scheduleDate",
      {
        date: format(currentDate, "yyyy-MM-dd"),
      },
      { queryParams: { search: searchQuery || undefined } }
    );
  }, [currentDate, navigateToPage, searchQuery]);

  return (
    <div className="bg-grey-200 min-h-full w-full min-w-[992px]">
      <div className="sticky top-0 z-20 w-full">
        <HeaderBreadcrumbs
          icon={<CalendarIcon />}
          items={[
            {
              title: t({
                id: "common.schedule",
                defaultMessage: "Schedule",
              }),
            },
          ]}
          rightContent={
            <DatePicker
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
            />
          }
        />
      </div>
      <List
        currentDate={currentDate}
        searchQuery={searchQuery}
        setSearch={setSearch}
      />
    </div>
  );
};

export default SchedulePage;
