import React, { useState, memo } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  UserProfile,
  UserProfileServices,
  useRestApiProvider,
} from "@jugl-web/rest-api";
import { ReactComponent as BinIcon } from "@web-src/images/icons/delete.svg";
import format from "date-fns/format";
import { isoToLocalDate } from "@web-src/utils/helper";
import { AvatarSelect } from "@jugl-web/ui-components/web/AvatarSelect";
import { useTranslations } from "@jugl-web/utils";
import { DatePicker } from "@jugl-web/ui-components/cross-platform/DatePicker";
import {
  InteractiveContainer,
  Popover,
  Button,
  Divider,
  LoadingAnimation,
  FormControlLabel,
  TextField,
  ConfirmationPopup,
} from "@jugl-web/ui-components/cross-platform";
import { Accordion } from "@jugl-web/ui-components/cross-platform/Accordion";
import { ReactComponent as ServicesIcon } from "./assets/services.svg";
import { useProfileUpdateToast } from "../../hooks/useProfileUpdateToast";
import { DateButton } from "../DateButton/DateButton";

type Inputs = {
  name_service: string;
  service_since: Date | undefined;
  until: Date | undefined;
  reference: string;
};

const schema = yup.object().shape({
  name_service: yup.string().required(),
  service_since: yup.date().required(),
  until: yup.date().required(),
  reference: yup.string().required(),
});

const EditServiceFormContainer = memo(() => {
  const { usersApi } = useRestApiProvider();
  const { data } = usersApi.useGetUserProfileQuery({});
  return <EditServiceFormComponent {...{ data }} />;
});

const EditServiceFormComponent: React.FC<{
  data: UserProfile | undefined;
}> = ({ data }) => {
  const { t } = useTranslations();
  return (
    <div className="w-1/2">
      {!data ? (
        <LoadingAnimation />
      ) : data.services.length === 0 ? (
        <div className="pt-10">
          <span className="pl-5 font-medium">
            {t({
              id: "serive-form-page.add-service",
              defaultMessage: "Add Service",
            })}
          </span>
          <EditServiceForm type="add" />
        </div>
      ) : (
        <EditServiceList {...{ data }} />
      )}
    </div>
  );
};

const EditServiceList: React.FC<{ data: UserProfile }> = ({ data }) => {
  const [displayForm, setDisplayForm] = useState(false);
  const { t } = useTranslations();
  return (
    <div className="pt-5">
      <span className="pl-5 font-medium">
        {t({
          id: "serive-form-page.add-service",
          defaultMessage: "Add Service",
        })}
      </span>
      {data.services.map((el, idx) => (
        <>
          <EditServiceListItem key={el.id} data={el} />
          {data.services.length - 1 > idx && <Divider />}
        </>
      ))}
      {!displayForm && (
        <Button
          className="ml-5 mt-5 mb-10 w-1/2"
          variant="outlined"
          onClick={() => setDisplayForm(true)}
        >
          {t({
            id: "form-controls.add-new-service.button",
            defaultMessage: "Add New Service",
          })}
        </Button>
      )}
      {displayForm && (
        <>
          <span className="ml-5 font-medium">
            {t({
              id: "serive-form-page.add-new-service",
              defaultMessage: "Add New Service",
            })}
          </span>
          <EditServiceForm type="add" onSuccess={() => setDisplayForm(false)} />
        </>
      )}
    </div>
  );
};

const EditServiceListItem: React.FC<{ data: UserProfileServices }> = ({
  data,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const { usersApi } = useRestApiProvider();
  const [deleteService, { isLoading }] =
    usersApi.useDeleteUserProfileSectionMutation();
  const { showProfileUpdatedSnackbar } = useProfileUpdateToast();
  const { t } = useTranslations();

  const handleDeleteService = async () => {
    const res = await deleteService({
      type: "service",
      item_id: data.id,
    });
    if ("data" in res) {
      showProfileUpdatedSnackbar();
      setOpenModal(false);
    }
  };

  return (
    <>
      <Accordion
        variant="web"
        title={
          <div className="flex w-full items-center gap-5 px-5 py-3">
            {data.img ? (
              <img
                src={data.img}
                className="h-9 w-9 rounded-full"
                alt="Service"
              />
            ) : (
              <div className="flex h-9 w-9 items-center justify-center rounded-full bg-[rgba(238,90,138,0.1)]">
                <ServicesIcon />
              </div>
            )}
            <div className="flex grow flex-col gap-1">
              <span>{data.service_type}</span>
              <span className="text-grey text-sm">
                {`${format(
                  isoToLocalDate(data.since),
                  "dd/MM/yyyy"
                )} - ${format(isoToLocalDate(data.until), "dd/MM/yyyy")}`}
              </span>
            </div>
            <Button
              color="tertiary"
              className="text-sm font-medium uppercase"
              isDisabled={isLoading}
              onClick={(e) => {
                e.stopPropagation();
                setOpenModal(true);
              }}
              variant="text"
              iconStart={<BinIcon />}
            >
              {t({
                id: "common.delete",
                defaultMessage: "Delete",
              })}
            </Button>
          </div>
        }
        className="flex-row-reverse px-4"
      >
        {({ onClose }) => (
          <EditServiceForm {...{ data }} type="update" onSuccess={onClose} />
        )}
      </Accordion>
      {openModal && (
        <ConfirmationPopup
          isOpen={openModal}
          onRequestClose={() => setOpenModal(false)}
          hasCancelButton
          message={t({
            id: "service-form-page.service-information-removal-confirmation",
            defaultMessage: "Do you really want to remove Service information?",
          })}
          variant="web"
          title={t({
            id: "delete-modal.delete-confirmation-title",
            defaultMessage: "Delete Confirmation",
          })}
          buttons={[
            {
              label: t({
                id: "common.delete",
                defaultMessage: "Delete",
              }),
              color: "tertiary",
              onClick: handleDeleteService,
              isDisabled: isLoading,
            },
          ]}
        />
      )}
    </>
  );
};

const EditServiceForm: React.FC<{
  data?: UserProfileServices;
  type: "add" | "update";
  onSuccess?: () => void;
}> = ({ data, type, onSuccess }) => {
  const { usersApi } = useRestApiProvider();
  const [addService, { isLoading }] =
    usersApi.useAddUserProfileSectionMutation();
  const [selectedAvatar, setSelectedAvatar] = useState<File>();
  const [updateService, { isLoading: isLoadingUpdate }] =
    usersApi.useUpdateUserProfileSectionMutation();
  const { showProfileUpdatedSnackbar } = useProfileUpdateToast();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { isValid, isDirty },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    values: {
      name_service: data?.service_type || "",
      service_since: data?.since ? new Date(data.since) : undefined,
      until: data?.until ? new Date(data.until) : undefined,
      reference: data?.ref || "",
    },
  });
  const untilValue = watch("until");
  const sinceValue = watch("service_since");
  const { t } = useTranslations();

  const onSubmit: SubmitHandler<Inputs> = async (params) => {
    const {
      name_service: NameService,
      service_since: ServiceSince,
      until,
      reference,
    } = { ...params };
    const dataForm = new FormData();
    dataForm.set("type", "service");
    dataForm.set("s_type", NameService);
    dataForm.set("ref", reference);
    dataForm.set("since", ServiceSince?.toISOString() || "");
    dataForm.set("until", until?.toISOString() || "");
    let res;
    if (selectedAvatar) dataForm.set("display_pic_file", selectedAvatar);
    if (type === "add") {
      res = await addService({ data: dataForm });
    } else {
      res = await updateService({
        item_id: data?.id,
        data: dataForm,
      });
    }
    if ("data" in res) {
      showProfileUpdatedSnackbar();
      onSuccess?.();
    }
  };

  return (
    <div className="px-10 pb-10 pt-5">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-5">
          <AvatarSelect
            onSave={setSelectedAvatar}
            value={selectedAvatar}
            defaultSrc={data?.img || undefined}
            placeholder={<ServicesIcon className="h-10 w-10" />}
          />
        </div>
        <div className="flex w-full gap-[70px]">
          <div className="flex w-1/2 flex-col gap-12">
            <TextField
              isRequired
              label={t({
                id: "form-controls.service-name.label",
                defaultMessage: "Name of Service",
              })}
              isFullWidth
              {...register("name_service")}
              placeholder={t({
                id: "form-controls.service-name.placeholder",
                defaultMessage: "Name of Service",
              })}
            />
            <Controller
              name="until"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Popover
                  className="w-[340px]"
                  placement="bottom-start"
                  renderTrigger={({ Trigger, triggerRef }) => (
                    <Trigger ref={triggerRef} as={InteractiveContainer}>
                      <FormControlLabel isRequired className="ml-2.5">
                        {t({
                          id: "form-controls.service-until-date.label",
                          defaultMessage: "Until (Date)",
                        })}
                      </FormControlLabel>
                      <DateButton date={value} />
                    </Trigger>
                  )}
                >
                  {({ onClose }) => (
                    <DatePicker
                      minDate={sinceValue}
                      onSubmit={onChange}
                      initialDate={value}
                      onClose={onClose}
                      className="p-4"
                      dateTransformation="endOfDay"
                    />
                  )}
                </Popover>
              )}
            />
            <Button
              isDisabled={!isValid || isLoading || isLoadingUpdate || !isDirty}
              fullWidth
              variant="contained"
              type="submit"
            >
              {t({
                id: "common.save",
                defaultMessage: "Save",
              })}
            </Button>
          </div>
          <div className="flex w-1/2 flex-col gap-12">
            <Controller
              name="service_since"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Popover
                  className="w-[340px]"
                  placement="bottom-start"
                  renderTrigger={({ Trigger, triggerRef }) => (
                    <Trigger ref={triggerRef} as={InteractiveContainer}>
                      <FormControlLabel isRequired className="ml-2.5">
                        {t({
                          id: "form-controls.service-since-date.label",
                          defaultMessage: "Service Since (Date)",
                        })}
                      </FormControlLabel>
                      <DateButton date={value} />
                    </Trigger>
                  )}
                >
                  {({ onClose }) => (
                    <DatePicker
                      onSubmit={(date) => {
                        onChange(date);
                        if (untilValue && date && date > untilValue) {
                          setValue("until", date, { shouldValidate: true });
                        }
                      }}
                      initialDate={value}
                      onClose={onClose}
                      className="p-4"
                      dateTransformation="endOfDay"
                    />
                  )}
                </Popover>
              )}
            />
            <TextField
              isRequired
              label={t({
                id: "form-controls.reference-link.label",
                defaultMessage: "Paste Reference Link",
              })}
              isFullWidth
              {...register("reference")}
              placeholder={t({
                id: "form-controls.reference-link.placeholder",
                defaultMessage: "Paste Reference Link",
              })}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditServiceFormContainer;
