import React, { useMemo } from "react";
import ImageViewer from "@web-src/components/ImageViewer";
import format from "date-fns/format";
import { isoToLocalDate, isImageType } from "@web-src/utils/helper";
import { DirectoryListItem } from "@jugl-web/rest-api/drive/types";

type DisplayImageViewerProps = {
  data: DirectoryListItem[] | null;
  isOpen: boolean;
  onClose: () => void;
  currentImageId: string | undefined;
};

export const DisplayImageViewer: React.FC<DisplayImageViewerProps> = ({
  data,
  isOpen,
  onClose,
  currentImageId,
}) => {
  const images = useMemo(
    () => data?.filter((element) => isImageType(element.mime_type)),
    [data]
  );

  const imagesElements = useMemo(
    () =>
      images?.map((element) => ({
        url: element.preview_url,
        id: element.id,
        name: element.name,
        date: format(isoToLocalDate(element.updated_at), "MMM dd, hh:mm a"),
      })),
    [images]
  );

  return data ? (
    <ImageViewer
      images={imagesElements}
      isOpen={isOpen}
      onClose={onClose}
      currentImageId={currentImageId}
    />
  ) : null;
};
