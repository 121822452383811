import { assert } from "@jugl-web/utils";
import { FC, useState } from "react";
import { CustomFieldProps } from "../../types";
import { getCustomFieldButtonId } from "../../utils";
import { EditCustomTextOrNumberFieldDialog } from "../../../EditCustomTextOrNumberFieldDialog";
import { TaskCustomFieldPropertyButton } from "../TaskCustomFieldPropertyButton";

export const CustomNumberField: FC<CustomFieldProps> = ({
  field,
  rawValue,
  isReadonly,
  onChange,
  onFinishEditing,
}) => {
  assert(field.type === "number", "Field should be of type number");

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <TaskCustomFieldPropertyButton
        isDisabled={isReadonly}
        id={getCustomFieldButtonId(field.id)}
        field={field}
        rawValue={rawValue}
        onClick={() => setIsDialogOpen(true)}
      />
      <EditCustomTextOrNumberFieldDialog
        isOpen={isDialogOpen}
        field={field}
        rawValue={rawValue}
        onSubmit={onChange}
        onClose={() => setIsDialogOpen(false)}
        onLeaveAnimationEnd={onFinishEditing}
      />
    </>
  );
};
