import { Customer } from "@jugl-web/rest-api/customer";
import { Avatar } from "@jugl-web/ui-components/cross-platform/Avatar";
import {
  ListBoxItemComponent,
  ListBoxItemComponentProps,
} from "@jugl-web/ui-components/cross-platform/ListBox";
import { FC } from "react";
import { getCustomerDetails } from "../../../customers/utils";

interface CustomerListBoxItemProps extends ListBoxItemComponentProps {
  customer: Customer;
}

export const CustomerListBoxItem: FC<CustomerListBoxItemProps> = ({
  customer,
  ...props
}) => {
  const details = getCustomerDetails(customer);

  return (
    <ListBoxItemComponent
      {...props}
      label={details.fullName}
      startSlot={
        <Avatar
          className="border border-solid border-white"
          size="md"
          imageUrl={details.avatarUrl}
          username={details.fullName}
        />
      }
      secondaryText={details.companyName}
    />
  );
};
