import { FC } from "react";
import { ReactComponent as CheckActiveIcon } from "../../assets/check-active.svg";
import { ReactComponent as CheckInactiveIcon } from "../../assets/check-inactive.svg";
import { TaskTableCellComponentProps } from "../../types";
import { TaskTableLayout } from "../TaskTableLayout";

export const ChecklistCell: FC<TaskTableCellComponentProps> = ({
  task,
  cellProps,
}) => {
  const hasChecklistItems = task.chklist_total > 0;
  const Icon = hasChecklistItems ? CheckActiveIcon : CheckInactiveIcon;

  return (
    <TaskTableLayout.Cell {...cellProps}>
      <div className="flex items-center gap-2">
        <Icon className="shrink-0" />
        <TaskTableLayout.CellText>
          {task.chklist_completed}/{task.chklist_total}
        </TaskTableLayout.CellText>
      </div>
    </TaskTableLayout.Cell>
  );
};
