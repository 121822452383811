import Geocode from "react-geocode";
import {
  PaginatedResponse,
  PaginatedRequestParams,
  RtkEmptySplitApi,
} from "../../types";
import {
  Event,
  LocationEventsSummary,
  LocationSummary,
  GeocodeResult,
} from "./types";

export enum LocationApiTags {
  locationsSummaryList = "LocationsSummaryList",
  locationsDetailsList = "LocationsDetailsList",
  geocodeLookup = "GeocodeLookup",
  usersSummaryAll = "UsersSummaryAll",
}

export const addLocationApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [
      LocationApiTags.locationsSummaryList,
      LocationApiTags.locationsDetailsList,
      LocationApiTags.geocodeLookup,
      LocationApiTags.usersSummaryAll,
    ],
  });
  const locationApi = apiWithTags.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
      summaryList: build.query<
        PaginatedResponse<LocationSummary>,
        PaginatedRequestParams<{
          entityId: string;
          user_id: string;
          summary?: "y";
          from_day?: string;
          to_day?: string;
        }>
      >({
        query: ({ entityId, ...params }) => ({
          url: `/api/auth/entity/${entityId}/location_tracking/v2/events`,
          params,
        }),
        providesTags: [LocationApiTags.locationsSummaryList],
      }),
      userSummary: build.query<
        PaginatedResponse<LocationEventsSummary>,
        PaginatedRequestParams<{
          entity_id: string;
          user_id: string;
          from: string;
          to: string;
        }>
      >({
        query: ({ entity_id, ...params }) => ({
          url: `/api/auth/entity/${entity_id}/location_tracking/v3/events_summary`,
          params: {
            ...params,
          },
        }),
        providesTags: [LocationApiTags.locationsSummaryList],
      }),
      detailedList: build.query<
        PaginatedResponse<Event>,
        PaginatedRequestParams<{
          entityId: string;
          user_id: string;
          from_time: string;
          to_time?: string;
        }>
      >({
        query: ({ entityId, ...params }) => ({
          url: `/api/auth/entity/${entityId}/location_tracking/v2/events`,
          params,
        }),
        providesTags: [LocationApiTags.locationsSummaryList],
      }),
      geocodeLookup: build.query<
        GeocodeResult[],
        { cordinates: { lat: number; lng: number }[] }
      >({
        queryFn: async ({ cordinates }) => {
          const mergedLocations: GeocodeResult[] = [];
          await Promise.all(
            cordinates.map(async (cords) => {
              if (!cords || !cords.lat || !cords.lng) return;
              const response = await Geocode.fromLatLng(
                cords.lat.toString(),
                cords.lng.toString()
              );
              if (response.results) {
                mergedLocations.push({
                  ...response.results[0],
                  cordinates: cords,
                });
              }
            })
          );
          return {
            data: mergedLocations,
          };
        },
        providesTags: [LocationApiTags.geocodeLookup],
      }),
    }),
  });

  return locationApi;
};
