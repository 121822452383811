import { isAppInDevMode } from "@web-src/modules/common/utils/isAppInDevMode";

enum LogLevel {
  debug = "debug",
  info = "info",
  warn = "warn",
  error = "error",
}

const loggerFunc = (
  level: LogLevel = LogLevel.info,
  ...msg: Parameters<typeof console.log>[0]
) => {
  if (!isAppInDevMode()) {
    return;
  }
  // TODO: sentry for error
  // eslint-disable-next-line no-console
  console[level](...msg);
};

export const logger = {
  debug: loggerFunc.bind(null, LogLevel.debug),
  info: loggerFunc.bind(null, LogLevel.info),
  warn: loggerFunc.bind(null, LogLevel.warn),
  error: loggerFunc.bind(null, LogLevel.error),
};
