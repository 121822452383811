import uniqBy from "lodash/uniqBy";
import { useMemo } from "react";
import { User, useRestApiProvider } from "@jugl-web/rest-api";
import { useEntityProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { skipToken } from "@reduxjs/toolkit/dist/query";

const useEntity = () => {
  const {
    entity: selectedEntity,
    isInitialized,
    entitiesList,
    isEntitiesFetching,
  } = useEntityProvider();

  const activeEntityId = selectedEntity?.id;

  const { usersApi } = useRestApiProvider();
  const { data: participants, isLoading: participantsIsLoading } =
    usersApi.use_DEPRECATED_getAllUsersQuery(
      activeEntityId ? { entityId: activeEntityId } : skipToken
    );
  const participantsMap = useMemo(() => {
    const result: { [key: string]: User } = {};
    participants?.forEach((item) => {
      result[item.id] = item;
    });
    return result;
  }, [participants]);

  return {
    participants: participants ? uniqBy(participants, "id") : [],
    participantsMap,
    participantsIsLoading,
    initialized: isInitialized,
    list: entitiesList,
    // TODO: deprecated, to remove
    active: selectedEntity,
    entity: selectedEntity,
    isEntitiesFetching,
  };
};

export default useEntity;
