import { environment } from "@web-src/environments/environment";

export const getTemplateIdByChartId = (chartId: number) => {
  switch (environment.supersetChartIdToType[chartId]) {
    case "tasks":
      return 1;
    case "people":
      return 2;
    case "travelLog":
      return 3;
    default:
      throw new Error("Unsupported report type");
  }
};
