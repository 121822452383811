import { FC, useEffect } from "react";

export interface ComponentLifecycleListenerProps {
  onMount?: () => void;
  onUnmount?: () => void;
}

export const ComponentLifecycleListener: FC<
  ComponentLifecycleListenerProps
> = ({ onMount, onUnmount }) => {
  // We want to run this function only once, when the component is mounted.
  // At this point, we don't really care whether the function reference changes or not
  useEffect(() => {
    onMount?.();

    return () => {
      onUnmount?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
