import { FC } from "react";
import Lottie from "react-lottie";
import { cx } from "@jugl-web/utils";
import { Portal } from "@headlessui/react";
import loaderAnimationData from "./assets/loader.json";

export const PageLoaderFull: FC<{
  isActive?: boolean;
  isTransparent?: boolean;
}> = ({ isActive = true, isTransparent }) => {
  if (!isActive) {
    return null;
  }
  return (
    <Portal>
      <div
        className={cx(
          "fixed left-0 right-0 top-0 bottom-0 z-[10000] flex items-center justify-center"
        )}
        style={{
          background: isTransparent ? "rgba(16, 17, 42, 0.38)" : "#fff",
          backdropFilter: isTransparent ? "blur(1.5px)" : undefined,
        }}
      >
        <Lottie
          options={{ animationData: loaderAnimationData }}
          height={68}
          width={68}
        />
      </div>
    </Portal>
  );
};
