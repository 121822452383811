import React, { useMemo } from "react";
import { ChatMessage } from "../../types";
import { getMessageReactionsSummary } from "./utils";
import ChatMessageReactionPopover from "./ChatMessageReactionPopover";

const ChatMessageReactions: React.FC<{
  message: ChatMessage;
  onClick?: () => void;
}> = ({ message, onClick }) => {
  const sortedReactions = useMemo(
    () => getMessageReactionsSummary(message),
    [message]
  );

  const visibleReactions = useMemo(
    () => sortedReactions.slice(0, 5),
    [sortedReactions]
  );

  return (
    <>
      <div className="flex h-[22px] cursor-pointer items-center gap-[4px] rounded-[11px] bg-white px-[4px] py-[2px] shadow">
        {visibleReactions.map((item, idx) => (
          <ChatMessageReactionPopover
            key={item.reaction}
            reactionItem={item}
            moreReactions={idx === 4 && sortedReactions.length > 5}
            reactionsLength={sortedReactions.length}
            onSidebarOpen={onClick}
          />
        ))}
        <span className="mr-[4px] text-[12px] font-[500] text-gray-400">
          {message.reactions?.length}
        </span>
      </div>
    </>
  );
};

export default ChatMessageReactions;
