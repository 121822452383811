import { environment } from "@web-src/environments/environment";
import { emptySplitApi } from "@web-src/features/api/createApi";
import { RawResponse } from "@web-src/features/api/types";
import { DeviceType } from "./types";
import { getDvcDetails } from "./utils";

export type AuthResponseProfile = {
  id: string;
  jid: string;
  mobile: string;
  profile: {
    nickname: string;
  } | null;
  username?: string;
};

const apiWithTags = emptySplitApi.enhanceEndpoints({
  addTagTypes: [],
});

const authApi = apiWithTags.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    userLogout: build.mutation<
      {
        result: "success";
      },
      {
        app_id: string;
        device_type: DeviceType;
      }
    >({
      query: (data) => ({
        url: "/api/auth/user/logout",
        method: "POST",
        data,
      }),
    }),
    requestOtp: build.mutation<
      AuthResponseProfile,
      {
        country: string;
        country_isd_code: string;
        mobile?: string;
        email?: string;
        otp_via?: string;
        grc_token?: string | null;
      }
    >({
      query: (data) => ({
        url: "/api/web/request-otp",
        method: "POST",
        data: { ...data, type: "org" },
      }),
    }),
    verifyOtp: build.mutation<
      {
        token: string;
        refreshToken: string;
        profile: AuthResponseProfile;
      },
      {
        id: string;
        otp: string;
      }
    >({
      query: (data) => ({
        url: "/api/web/verify-otp",
        method: "POST",
        data: {
          ...data,
          type: "org",
          dvc: getDvcDetails(),
        },
        rawResponse: true,
      }),
      transformResponse: (data: RawResponse<AuthResponseProfile>) => ({
        token: data.headers["x-auth-joiint"],
        refreshToken: data.headers["x-auth-refresh-joiint"],
        profile: data.data,
      }),
    }),
    claimOauthToken: build.mutation<
      {
        token: string;
        refreshToken: string;
      },
      {
        tkn: string;
      }
    >({
      query: (data) => ({
        url: "/api/oauth/claim",
        method: "POST",
        data: {
          ...data,
          dvc: getDvcDetails(),
        },
        rawResponse: true,
      }),
      transformResponse: (data: RawResponse<unknown>) => ({
        token: data.headers["x-auth-joiint"],
        refreshToken: data.headers["x-auth-refresh-joiint"],
      }),
    }),
    emailLogin: build.mutation<
      {
        token: string;
        refreshToken: string;
        profile: AuthResponseProfile;
      },
      {
        email: string;
        pass: string;
      }
    >({
      query: (data) => ({
        url: "/api/web/verify-email",
        method: "POST",
        data: { ...data, type: "org" },
        rawResponse: true,
      }),
      transformResponse: (data: RawResponse<AuthResponseProfile>) => ({
        token: data.headers["x-auth-joiint"],
        refreshToken: data.headers["x-auth-refresh-joiint"],
        profile: data.data,
      }),
    }),
    updateToken: build.mutation<void, string>({
      query: (token) => ({
        url: `/api/auth/user/update_token`,
        method: "POST",
        data: {
          [environment.firebaseTokenId]: {
            service: "web_fcm",
            type: "org",
            token,
          },
        },
      }),
    }),
    getInvites: build.query({ query: () => ({ url: "/api/auth/invites" }) }),
  }),
});

export const {
  useUserLogoutMutation,
  useRequestOtpMutation,
  useEmailLoginMutation,
  useVerifyOtpMutation,
  useUpdateTokenMutation,
  useClaimOauthTokenMutation,
  useGetInvitesQuery,
} = authApi;
