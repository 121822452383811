import { TaskCustomFieldType } from "@jugl-web/rest-api/tasks";
import { Button, Menu } from "@jugl-web/ui-components/cross-platform";
import { Switch } from "@jugl-web/ui-components/cross-platform/Switch";
import { cx, useTranslations, useUniqueDOMId } from "@jugl-web/utils";
import { FC, ReactNode, forwardRef } from "react";
import { taskCustomFieldTypeLabelById } from "../../consts";
import { ReactComponent as ChevronDownIcon } from "./icons/chevron-down.svg";

interface CustomFieldFromProps {
  children: ReactNode;
}

export const CustomFieldForm = ({ children }: CustomFieldFromProps) => (
  <div className="flex w-[400px] flex-col">{children}</div>
);

interface TitleProps {
  title: string;
  className?: string;
}

CustomFieldForm.Title = ({ title, className }: TitleProps) => (
  <h3
    className={cx(
      "text-dark m-0 w-[400px] text-center text-lg font-medium",
      className
    )}
  >
    {title}
  </h3>
);

interface FieldTypeSelectProps {
  value: TaskCustomFieldType;
  className?: string;
  onSelect: (type: TaskCustomFieldType) => void;
}

CustomFieldForm.FieldTypeSelect = (({ value, className, onSelect }) => {
  const { t } = useTranslations();

  return (
    <Menu<TaskCustomFieldType>
      selectedId={value}
      placement="bottom"
      adjustToTriggerWidth
      sections={[
        [
          {
            id: "text",
            label: t({
              id: "tasks-page.custom-field-type-text",
              defaultMessage: "Text",
            }),
            value: "text",
          },
          {
            id: "dropdown",
            label: t({
              id: "tasks-page.custom-field-type-dropdown",
              defaultMessage: "Dropdown",
            }),
            value: "dropdown",
          },
          {
            id: "date",
            label: t({
              id: "tasks-page.custom-field-type-date",
              defaultMessage: "Date",
            }),
            value: "date",
          },
          {
            id: "number",
            label: t({
              id: "tasks-page.custom-field-type-number",
              defaultMessage: "Number",
            }),
            value: "number",
          },
        ],
      ]}
      renderTrigger={({ Trigger, triggerRef, isOpen }) => (
        <Trigger
          ref={triggerRef}
          className={cx(
            "group w-full cursor-pointer border-none bg-transparent p-0 text-left outline-none",
            className
          )}
        >
          <span className="px-4 text-xs leading-3 text-[#828282]">
            {t({
              id: "tasks-page.custom-field-type",
              defaultMessage: "Field type",
            })}
            <span className="text-gradients-danger">*</span>
          </span>
          <div
            className={cx(
              "bg-grey-100 flex items-center justify-between rounded-xl py-3 px-6",
              "group-focus-visible:outline-primary-300 group-focus-visible:outline group-focus-visible:outline-2"
            )}
          >
            <span className="text-dark text-sm">
              {taskCustomFieldTypeLabelById[value]}
            </span>
            <ChevronDownIcon
              className={cx("transition-transform", isOpen && "rotate-180")}
            />
          </div>
        </Trigger>
      )}
      onSelect={(item, _, close) => {
        onSelect(item.value as TaskCustomFieldType);
        close();
      }}
    />
  );
}) as FC<FieldTypeSelectProps>;

interface TextFieldProps {
  label: string;
  value: string;
  placeholder?: string;
  isRequired?: boolean;
  className?: string;
  onChange: (name: string) => void;
}

// TODO: Extract to separate component
CustomFieldForm.TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ label, value, placeholder, isRequired, className, onChange }, ref) => {
    const labelId = useUniqueDOMId();

    return (
      <div className={cx("flex flex-col", className)}>
        <label
          htmlFor={labelId}
          className="cursor-pointer px-4 text-xs leading-3 text-[#828282]"
        >
          {label}
          {isRequired && <span className="text-gradients-danger">*</span>}
        </label>
        <input
          ref={ref}
          type="text"
          placeholder={placeholder}
          id={labelId}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          className="bg-grey-100 placeholder:text-grey-600 text-dark jugl__focusable-outline w-full rounded-xl border-none py-3 px-6 font-[Roboto] text-sm"
        />
      </div>
    );
  }
);

interface IsShownInCardSwitchProps {
  isChecked: boolean;
  className?: string;
  onChange: (value: boolean) => void;
}

CustomFieldForm.IsShownInCardSwitch = (({ isChecked, className, onChange }) => {
  const labelId = useUniqueDOMId();
  const { t } = useTranslations();

  return (
    <div className={cx("flex items-center gap-2.5 px-4", className)}>
      <Switch id={labelId} isChecked={isChecked} onChange={onChange} />
      <label
        htmlFor={labelId}
        className="text-dark-900 cursor-pointer text-xs leading-4"
      >
        {t({
          id: "tasks-page.show-field-in-task-card",
          defaultMessage: "Show field in a task card",
        })}
      </label>
    </div>
  );
}) as FC<IsShownInCardSwitchProps>;

interface ActionsProps {
  submitButton: {
    label: string;
    isDisabled: boolean;
    onClick: () => void;
  };
  onCancel: () => void;
}

CustomFieldForm.Actions = (({ submitButton, onCancel }) => {
  const { t } = useTranslations();

  return (
    <div className="flex items-center justify-center gap-3.5">
      <Button
        variant="contained"
        color="grey"
        className="w-[143px]"
        onClick={onCancel}
      >
        {t({
          id: "common.cancel",
          defaultMessage: "Cancel",
        })}
      </Button>
      <Button
        variant="contained"
        color="primary"
        className="w-[143px]"
        isDisabled={submitButton.isDisabled}
        onClick={submitButton.onClick}
      >
        {submitButton.label}
      </Button>
    </div>
  );
}) as FC<ActionsProps>;
