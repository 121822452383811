import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DriveItemDisplayType, FileType } from "@jugl-web/rest-api/drive/types";

type DriveState = {
  displayType: {
    [key: string]: DriveItemDisplayType;
  };
  objectAction: {
    id?: string;
    type?: FileType;
    name?: string;
    allowed_users?: { id: string; img: string; name: string }[];
    action?: "edit" | "rename";
  };
  visited: { id: string; name: string }[];
};

const initialState: DriveState = {
  displayType: {},
  objectAction: {
    id: undefined,
    type: undefined,
    name: undefined,
    allowed_users: undefined,
    action: undefined,
  },
  visited: [],
};

const driveSlice = createSlice({
  name: "drive",
  initialState,
  reducers: {
    change(
      state,
      action: PayloadAction<[id: string, display: DriveItemDisplayType]>
    ) {
      const [id, display] = action.payload;
      state.displayType[id] = display;
    },
    actionSidebar(state, action: PayloadAction<DriveState["objectAction"]>) {
      state.objectAction = action.payload;
    },
    setVisited(state, action: PayloadAction<{ id: string; name: string }[]>) {
      const visited = action.payload;
      state.visited = visited;
    },
  },
});

export const { change, actionSidebar, setVisited } = driveSlice.actions;
export default driveSlice;
