import { cx, useUniqueDOMId } from "@jugl-web/utils";
import {
  DetailedHTMLProps,
  InputHTMLAttributes,
  ReactNode,
  forwardRef,
} from "react";

type NativeCheckboxProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export type CheckboxSize = "sm" | "md";

export interface CheckboxProps
  extends Omit<NativeCheckboxProps, "type" | "checked" | "disabled" | "size"> {
  size?: CheckboxSize;
  label?: ReactNode;
  labelClassName?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
}

const sizeToClasses: Record<CheckboxSize, string> = {
  sm: "h-4 w-4",
  md: "h-5 w-5",
};

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      size = "md",
      label,
      labelClassName,
      isChecked,
      isDisabled,
      className,
      ...props
    },
    ref
  ) => {
    const id = useUniqueDOMId();
    const hasLabel = !!label;

    const checkbox = (
      <input
        ref={ref}
        id={id}
        type="checkbox"
        checked={isChecked}
        disabled={isDisabled}
        className={cx(
          "form-checkbox m-0 rounded",
          "focus-visible:outline-primary-300 focus:ring-0 focus:ring-transparent focus:ring-offset-transparent focus-visible:outline focus-visible:outline-2",
          "checked:bg-primary checked:hover:bg-primary checked:focus:bg-primary",
          "disabled:checked:bg-grey-300 disabled:checked:hover:bg-grey-300 disabled:checked:border-grey-300 disabled:cursor-not-allowed disabled:border-[#F2F2F2]",
          "border-grey-500 border-1 border-solid",
          !props.readOnly && "cursor-pointer",
          sizeToClasses[size],
          className
        )}
        {...props}
      />
    );

    if (hasLabel) {
      return (
        <div className="flex items-center gap-2">
          {checkbox}
          <label htmlFor={id} className={cx("select-none", labelClassName)}>
            {label}
          </label>
        </div>
      );
    }

    return checkbox;
  }
);
