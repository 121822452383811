import { ModuleNotificationsDialog } from "@jugl-web/domain-resources/module-notifications/components/ModuleNotificationsDialog";
import { useModuleNotificationsUnreadIndicator } from "@jugl-web/domain-resources/module-notifications/hooks/useModuleNotificationsUnreadIndicator";
import { DirectoryListItem } from "@jugl-web/rest-api/drive/types";
import {
  Text,
  TypographyVariant,
} from "@jugl-web/ui-components/cross-platform/Text";
import { NotificationsButton } from "@web-src/components/NotificationsButton";
import { RootState } from "@web-src/store";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useTranslations } from "@jugl-web/utils";
import { setVisited } from "../../driveSlice";
import { ReactComponent as ChevronLeftIcon } from "./assets/chevron-left.svg";
import { ReactComponent as FolderIcon } from "./assets/folder.svg";
import { useDriveProvider } from "../../providers";

const DriveHeader: React.FC<{
  entityId: string | undefined;
  openedFile?: DirectoryListItem | null;
  onFolderClick?: () => void;
}> = ({ entityId, onFolderClick, openedFile }) => {
  const visited = useSelector((state: RootState) => state.drive.visited);
  const dispatch = useDispatch();
  const { driveHook } = useDriveProvider();
  const { rootDirectory } = driveHook;
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslations();

  const [isNotificationsDialogOpen, setIsNotificationsDialogOpen] =
    useState(false);

  const {
    isUnread: hasUnreadNotifications,
    markAsRead: markNotificationsAsRead,
  } = useModuleNotificationsUnreadIndicator({ entityId, module: "drive" });

  const selectFolder = (index: number) => {
    const array = [...visited];
    array.splice(index + 1);
    dispatch(setVisited(array));
    onFolderClick?.();
  };

  const usedSpace = useMemo(
    () =>
      rootDirectory
        ? ((rootDirectory.total_space - rootDirectory.remaining_space) * 100) /
          rootDirectory.total_space
        : 0,
    [rootDirectory]
  );

  const returnToRoot = () => {
    dispatch(
      setVisited([
        {
          id: rootDirectory?.current_dir_id || "",
          name: "root",
        },
      ])
    );
  };

  const isAtRoot = visited.length === 1;

  return (
    <div className="flex h-20 w-full items-center bg-white">
      <FolderIcon className="ml-8" />
      <button
        type="button"
        className="ml-2 cursor-pointer border-none bg-transparent"
        onClick={() => {
          returnToRoot();
          onFolderClick?.();
        }}
        onKeyDown={() => {
          returnToRoot();
          onFolderClick?.();
        }}
      >
        <Text variant={TypographyVariant.h4}>
          {t({
            id: "drive-page.drive",
            defaultMessage: "Drive",
          })}
        </Text>
      </button>
      {isAtRoot ? (
        <>
          <div className="bg-primary-50 ml-10 h-2 w-[272px] rounded-3xl">
            <div
              className="bg-secondary-400 h-2 rounded-3xl"
              style={{
                width: `${Math.round(usedSpace)}%`,
              }}
            />
          </div>
          <Text
            variant={TypographyVariant.body1}
            className="text-grey-900 ml-2"
          >{`${Math.round(usedSpace)}/100%`}</Text>
        </>
      ) : (
        visited.map((element, index) => {
          if (index === 0) return null;
          return (
            <div key={element.id} className="flex items-center">
              <ChevronLeftIcon />
              <button
                className="border-none bg-transparent"
                type="button"
                onClick={() => {
                  selectFolder(index);
                  // In the scenario where a file is open and we navigate to another folder, we remove the fileId from the URL
                  if (openedFile) {
                    searchParams.delete("openedFile");
                    setSearchParams(searchParams);
                  }
                }}
              >
                <Text variant={TypographyVariant.h4} className="cursor-pointer">
                  {element.name}
                </Text>
              </button>
            </div>
          );
        })
      )}
      {openedFile && (
        <div className="flex items-center">
          <ChevronLeftIcon />
          <button className="border-none bg-transparent" type="button">
            <Text variant={TypographyVariant.h4} className="cursor-pointer">
              {openedFile.name}
            </Text>
          </button>
        </div>
      )}
      <div className="grow" />
      <NotificationsButton
        hasUnreadIndicator={hasUnreadNotifications}
        className="mx-8"
        onClick={() => {
          setIsNotificationsDialogOpen(true);
          markNotificationsAsRead();
        }}
      />
      {entityId && (
        <ModuleNotificationsDialog
          isOpen={isNotificationsDialogOpen}
          entityId={entityId}
          module="drive"
          onNotificationClick={(log) => {
            if (log.action === "deleted" && log.drive.type === "dir") {
              return;
            }
            setIsNotificationsDialogOpen(false);
            const { folderId, folderName } = (() => {
              if (log.drive.type === "dir") {
                return {
                  folderId: log.drive.id,
                  folderName: log.drive.name,
                };
              }
              return {
                folderId: log.drive.parent_dir_id,
                folderName: log.drive.parent_dir_name,
              };
            })();
            dispatch(
              setVisited([
                {
                  id: rootDirectory?.current_dir_id || "",
                  name: "root",
                },
                {
                  id: folderId,
                  name: folderName,
                },
              ])
            );
          }}
          onClose={() => setIsNotificationsDialogOpen(false)}
        />
      )}
    </div>
  );
};

export default DriveHeader;
