import { Button } from "@jugl-web/ui-components/cross-platform";
import React from "react";

export const DefaultErrorContent: React.FC<{ retry: () => void }> = ({
  retry,
}) => (
  <div className="flex h-full flex-col items-center justify-center gap-4 px-4 py-10 text-center text-sm text-gray-500">
    <p>Something went wrong</p>
    <Button size="small" color="grey" onClick={retry}>
      Retry
    </Button>
  </div>
);
