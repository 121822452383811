import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { useDispatch, useSelector } from "react-redux";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { isAPIError, useToast, useTranslations } from "@jugl-web/utils";
import { RootState } from "@web-src/store";
import { useSnackbar } from "notistack";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { ActionType } from "@jugl-web/rest-api/drive/types";
import { UserListItem } from "@jugl-web/domain-resources/users/components/UserListItem";
import { HeadlessUsersList } from "@jugl-web/domain-resources/users/components/HeadlessUsersList";
import { Button, TextField } from "@jugl-web/ui-components/cross-platform";
import { useDebounce } from "react-use";
import { Switch } from "@jugl-web/ui-components/cross-platform/Switch";
import { SidebarDrawer } from "@jugl-web/ui-components/web";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { useOnboarding } from "@web-src/modules/preferences/providers";
import { setVisited } from "../../driveSlice";
import { DriveError } from "../DisplayFolders/components/types";
import { useDriveProvider } from "../../providers";

const DriveForm: React.FC<{
  isOpen: boolean;
  onRequestClose: (arg1: boolean) => void;
  parentDirectory: string;
  type: ActionType | null;
}> = ({ isOpen, onRequestClose, parentDirectory, type }) => {
  const fileAction = useSelector(
    (state: RootState) => state.drive.objectAction
  );
  const visited = useSelector((state: RootState) => state.drive.visited);
  const dispatch = useDispatch();
  const { me, id: myId } = useMe();
  const params = useParams();
  const { t } = useTranslations();

  const { enqueueSnackbar } = useSnackbar();
  const { active } = useEntity();
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  useDebounce(() => setSearchQuery(search), 300, [search]);
  const { driveApi } = useRestApiProvider();
  const { driveHook } = useDriveProvider();
  const { data: driveData } = driveHook;
  const { toast } = useToast({ variant: "web" });
  const entityId = active?.id;

  const [checkboxes, setCheckboxes] = useState<{ [k: string]: boolean }>(
    fileAction.allowed_users?.reduce(
      (acc: { [key: string]: boolean }, item) => {
        acc[item.id] = true;
        return acc;
      },
      {}
    ) || {}
  );
  const [editDirectory] = driveApi.useEditDirectoryMutation();
  const [renameFile] = driveApi.useRenameFileMutation();

  const [directoryName, setDirectoryName] = useState("");

  useEffect(() => {
    if (!fileAction.name) return;
    setDirectoryName(fileAction.name);
  }, [fileAction.name]);

  const [isPrivate, setIsPrivate] = useState(
    !!fileAction.allowed_users?.length
  );

  const [createDirectory] = driveApi.useCreateDirectoryMutation();
  const { completeOnboardingStep, isOnboardingActive } = useOnboarding();

  const handleSubmit = async () => {
    const res = await createDirectory({
      entity_id: params.entityId || "",
      data: {
        name: directoryName || "",
        ...(params.activeChatId && { workspace_id: params.activeChatId }),
        mode: isPrivate ? "private" : "public",
        users:
          (isPrivate &&
            Object.keys(checkboxes)
              .filter((element) => checkboxes[element] === true)
              .concat(me?.id || "")
              .map((element) => element)) ||
          undefined,
        parent_dir_id: driveData?.current_dir_id || "",
      },
    });
    if ("error" in res && isAPIError(res.error)) {
      if (res.error.data.errors === DriveError.existingFolder) {
        enqueueSnackbar(
          t(
            {
              id: "drive-page.folder-name-already-exist",
              defaultMessage: `Folder name "{directoryName}" already exist. Try other option`,
            },
            {
              directoryName,
            }
          ),
          {
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            variant: "error",
            autoHideDuration: 2000,
          }
        );
      }
    }
    if ("data" in res && res.data) {
      if (isOnboardingActive) {
        completeOnboardingStep("drive");
      }
      onRequestClose(false);
      toast(
        t({
          id: "drive-page.folder-successfully-created",
          defaultMessage: "New Folder successfully created",
        }),
        { autoHideDuration: 3000 }
      );
    }
  };

  const handleEdit = async () => {
    let respond;
    if (fileAction.type === "dir") {
      respond = await editDirectory({
        entity_id: params.entityId || "",
        mode: isPrivate ? "private" : "public",
        id: fileAction.id || "",
        name: directoryName || "",
        parent_dir_id: parentDirectory,
        ...(params.activeChatId && { workspace_id: params.activeChatId }),
        users:
          (isPrivate &&
            Object.keys(checkboxes)
              .filter((element) => checkboxes[element] === true)
              .concat(me?.id || "")
              .map((element) => element)) ||
          [],
      });
      if ("data" in respond) {
        toast(
          t({
            id: "drive-page.folder-successfully-updated",
            defaultMessage: "Folder successfully updated",
          })
        );
        onRequestClose(false);
      }
    } else {
      respond = await renameFile({
        entity_id: params.entityId || "",

        id: fileAction.id || "",
        name: directoryName || "",
        parent_dir_id: parentDirectory,
        ...(params.activeChatId && { workspace_id: params.activeChatId }),
      });
    }
    if (
      ((respond && "data" in respond && respond.data) ||
        fileAction.name === directoryName) &&
      type !== ActionType.rename
    ) {
      if (visited[visited.length - 1].id === fileAction.id) {
        const array = [...visited];
        const updatedObj = {
          id: array[array.length - 1].id,
          name: directoryName,
        };
        array[array.length - 1] = updatedObj;
        dispatch(setVisited(array));
      }
    }
    if (type === ActionType.rename) {
      onRequestClose(false);
    }
  };

  return (
    <SidebarDrawer
      isOpen={Boolean(isOpen)}
      onClose={() => {
        setDirectoryName("");
        onRequestClose(false);
      }}
      title={
        type === ActionType.add
          ? t({
              id: "drive-page.create-new-folder",
              defaultMessage: "Create new folder",
            })
          : type === ActionType.edit
          ? t({
              id: "drive-page.edit-folder",
              defaultMessage: "Edit folder",
            })
          : t({
              id: "drive-page.rename-file",
              defaultMessage: "Rename File",
            })
      }
    >
      <SidebarDrawer.Content>
        <div className="flex h-full w-full flex-col pt-8 ">
          <TextField
            label={
              type === ActionType.add
                ? t({
                    id: "drive-page.folder-name",
                    defaultMessage: "Folder Name",
                  })
                : undefined
            }
            isFullWidth
            isRequired
            variant="underline"
            value={directoryName}
            onChange={(e) => setDirectoryName(e.target.value)}
            className="mx-8 mb-2.5"
          />
          {type !== ActionType.rename && (
            <>
              <div className="bg-grey-200 mx-8 mt-4 flex items-center justify-between p-2.5">
                <div className="flex flex-col gap-1">
                  <span className="text-xs font-medium tracking-tight">
                    {t({
                      id: "drive-page.make-it-private",
                      defaultMessage: "Do you want to make it Private?",
                    })}
                  </span>
                  <span className="text-grey-900 text-[11px]">
                    {t({
                      id: "drive-page.select-users-to-grant-access",
                      defaultMessage:
                        "Select the users you want to grant access",
                    })}
                  </span>
                </div>
                <Switch isChecked={isPrivate} onChange={setIsPrivate} />
              </div>
              {isPrivate && entityId && (
                <div className="mt-5 flex h-full flex-col">
                  <div className="my-2 px-8">
                    <SearchInput
                      variant="filled"
                      color="grey"
                      placeholder={t({
                        id: "common.search-with-ellipsis",
                        defaultMessage: "Search...",
                      })}
                      value={search}
                      onChange={(e) => setSearch(e.currentTarget.value)}
                    />
                  </div>
                  <div className="h-full w-full">
                    <HeadlessUsersList
                      virtualized
                      fetchParams={{
                        entityId,
                        searchQuery,
                        excludeUsers: myId,
                        workspaceId: params?.activeChatId
                          ? params.activeChatId
                          : undefined,
                      }}
                      userRenderer={(user) => (
                        <UserListItem
                          entityId={entityId}
                          userId={user.id}
                          variant="web"
                          mode="check"
                          isSelected={checkboxes[user.id]}
                          onSelect={() => {
                            setCheckboxes({
                              ...checkboxes,
                              [user.id]: !checkboxes[user.id],
                            });
                          }}
                          defaultSubTitle="department"
                          highlightText={search}
                        />
                      )}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </SidebarDrawer.Content>
      <SidebarDrawer.Actions>
        <Button
          fullWidth
          isDisabled={directoryName?.trimStart().length === 0}
          onClick={type === ActionType.add ? handleSubmit : handleEdit}
        >
          {type === ActionType.add
            ? t({
                id: "drive-page.create-folder",
                defaultMessage: "Create folder",
              })
            : t({
                id: "drive-page.save-changes",
                defaultMessage: "Save changes",
              })}
        </Button>
      </SidebarDrawer.Actions>
    </SidebarDrawer>
  );
};

export default DriveForm;
