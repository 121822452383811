import { TaskPriority } from "@jugl-web/rest-api/tasks";
import { useTranslations } from "@jugl-web/utils";
import { FC, SVGProps, useCallback, useMemo } from "react";
import { ReactComponent as HighPriorityIcon } from "./icons/high-priority.svg";
import { ReactComponent as LowPriorityIcon } from "./icons/low-priority.svg";
import { ReactComponent as MediumPriorityIcon } from "./icons/medium-priority.svg";
import { ReactComponent as NoPriorityIcon } from "./icons/no-priority.svg";

export interface TaskPriorityDetails {
  id: TaskPriority;
  shortLabel: string;
  longLabel: string;
  textColor: string;
  Icon: FC<SVGProps<SVGSVGElement> & { title?: string }>;
}

export const useTaskPriorities = () => {
  const { t } = useTranslations();

  const taskPriorityDetailsById = useMemo<
    Record<TaskPriority, TaskPriorityDetails>
  >(
    () => ({
      [TaskPriority.none]: {
        id: TaskPriority.none,
        longLabel: t({
          id: "tasks-page.none-priority-long-label",
          defaultMessage: "Not Assigned",
        }),
        shortLabel: t({
          id: "tasks-page.none-priority-short-label",
          defaultMessage: "None",
        }),
        textColor: "#737B7E",
        Icon: NoPriorityIcon,
      },
      [TaskPriority.low]: {
        id: TaskPriority.low,
        longLabel: t({
          id: "tasks-page.low-priority-long-label",
          defaultMessage: "Low priority",
        }),
        shortLabel: t({
          id: "tasks-page.low-priority-short-label",
          defaultMessage: "Low",
        }),
        textColor: "#398453",
        Icon: LowPriorityIcon,
      },
      [TaskPriority.medium]: {
        id: TaskPriority.medium,
        longLabel: t({
          id: "tasks-page.medium-priority-long-label",
          defaultMessage: "Medium priority",
        }),
        shortLabel: t({
          id: "tasks-page.medium-priority-short-label",
          defaultMessage: "Medium",
        }),
        textColor: "#B15806",
        Icon: MediumPriorityIcon,
      },
      [TaskPriority.high]: {
        id: TaskPriority.high,
        longLabel: t({
          id: "tasks-page.high-priority-long-label",
          defaultMessage: "High priority",
        }),
        shortLabel: t({
          id: "tasks-page.high-priority-short-label",
          defaultMessage: "High",
        }),
        textColor: "#C94841",
        Icon: HighPriorityIcon,
      },
    }),
    [t]
  );

  const getPriorityDetailsById = useCallback(
    (priority: TaskPriority): TaskPriorityDetails =>
      taskPriorityDetailsById[priority],
    [taskPriorityDetailsById]
  );

  const allPriorities = useMemo<TaskPriorityDetails[]>(() => {
    const orderedPriorities = [
      TaskPriority.none,
      TaskPriority.low,
      TaskPriority.medium,
      TaskPriority.high,
    ];

    return orderedPriorities.map(getPriorityDetailsById);
  }, [getPriorityDetailsById]);

  return {
    allPriorities,
    getPriorityDetailsById,
  };
};
