import { IMicrophoneAudioTrack } from "agora-rtc-sdk-ng";
import { useEffect, useState } from "react";

export const useSpeakingState = (
  audioTrack: IMicrophoneAudioTrack | undefined
) => {
  const [isSpeaking, setIsSpeaking] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newVolumeLevel = audioTrack?.getVolumeLevel() || 0;
      const newTalkingState = newVolumeLevel > 0.6;
      if (isSpeaking !== newTalkingState) setIsSpeaking(newTalkingState);
    }, 500);

    return () => clearInterval(intervalId);
  }, [audioTrack, isSpeaking]);

  return isSpeaking;
};
