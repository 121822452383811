import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DirectoryListItem } from "@jugl-web/rest-api/drive/types";
import {
  downloadBlobAsFile,
  useDownloadFile,
} from "@jugl-web/domain-resources/drive";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { Document, Page } from "react-pdf";
import type { PDFDocumentProxy } from "pdfjs-dist";
import { Button } from "@jugl-web/ui-components/cross-platform";
import { useToast, useTranslations } from "@jugl-web/utils";
import * as Sentry from "@sentry/react";
import { ReactComponent as DocIconComponent } from "./assets/doc-icon.svg";
import { ReactComponent as MusicIconComponent } from "./assets/music-icon.svg";
import { ReactComponent as PdfIconComponent } from "./assets/pdf-icon.svg";
import { ReactComponent as VideoIconComponent } from "./assets/video-icon.svg";
import { ReactComponent as XlsIconComponent } from "./assets/xls-icon.svg";

const toBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
  });

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",
};

export const FilePreview: React.FC<{ file: DirectoryListItem }> = ({
  file,
}) => {
  const { t } = useTranslations();
  const { toast } = useToast({ variant: "web" });
  const { downloadFile } = useDownloadFile();
  const { entity } = useEntity();
  const [progress, setProgress] = useState(0);
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [downloadedFile, setDownloadedFile] = useState<Blob>();
  const handleDownloadClick = useCallback(async () => {
    if (!entity) {
      return;
    }
    setDownloadInProgress(true);
    const response = await downloadFile({
      entityId: entity?.id || "",
      id: file.id,
      fileName: file.name,
      fileSize: file.size,
      mimeType: file.mime_type,
      onProgress: (bytesSent, totalBytes) => {
        setProgress(Math.round((bytesSent / totalBytes) * 100));
      },
    });
    setProgress(0);
    setDownloadInProgress(false);
    if ("data" in response) {
      setDownloadedFile(response.data as Blob);
    } else {
      if ("error" in response) {
        Sentry.captureException(response.error);
      }
      toast(
        t({
          id: "feedback.failed-to-download-file",
          defaultMessage: "Failed to Download File",
        })
      );
    }
  }, [
    downloadFile,
    entity,
    file.id,
    file.mime_type,
    file.name,
    file.size,
    toast,
    t,
  ]);

  const handleOpenClick = useCallback(() => {
    if (!downloadedFile) {
      return;
    }
    downloadBlobAsFile(downloadedFile, file.name);
  }, [downloadedFile, file.name]);

  const [pdfUrl, setPdfUrl] = useState<string>();
  const pdfLoadStarted = useRef<boolean>();
  useEffect(() => {
    if (pdfLoadStarted.current) {
      return;
    }
    if (entity && file.mime_type === "application/pdf") {
      pdfLoadStarted.current = true;
      downloadFile({
        entityId: entity?.id || "",
        id: file.id,
        fileName: file.name,
        fileSize: file.size,
        mimeType: file.mime_type,
        onProgress: (bytesSent, totalBytes) => {
          setProgress(Math.round((bytesSent / totalBytes) * 100));
        },
      }).then((response) => {
        if ("data" in response) {
          toBase64(response.data).then((base64) => {
            setPdfUrl(base64);
          });
        }
      });
    }
  }, [file, entity, downloadFile]);

  const [numPages, setNumPages] = useState<number>();
  function onDocumentLoadSuccess({
    numPages: nextNumPages,
  }: PDFDocumentProxy): void {
    setNumPages(nextNumPages);
  }

  const isPreviewAvailable = useMemo(
    () =>
      ["application/pdf"].includes(file.mime_type) ||
      file.mime_type.startsWith("video") ||
      file.mime_type.startsWith("image"),
    [file.mime_type]
  );

  // TODO:
  const $icon = useMemo(() => {
    if (file.mime_type === "application/pdf") {
      return <PdfIconComponent />;
    }
    if (file.mime_type.startsWith("audio")) {
      return <MusicIconComponent />;
    }
    if (file.mime_type.startsWith("video")) {
      return <VideoIconComponent />;
    }
    if (
      [
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ].includes(file.mime_type)
    ) {
      return <DocIconComponent />;
    }
    if (
      [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ].includes(file.mime_type)
    ) {
      return <XlsIconComponent />;
    }
    return <DocIconComponent />;
  }, [file.mime_type]);

  const $preview = useMemo(() => {
    if (file.mime_type === "application/pdf") {
      if (!pdfUrl) {
        return null;
      }
      return (
        <Document
          file={pdfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}
          className="w-full"
        >
          {Array.from(new Array(numPages), (el, index) => (
            <div className="w-full">
              <Page
                className="max-w-full"
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            </div>
          ))}
        </Document>
      );
    }
    if (file.mime_type.startsWith("video")) {
      return (
        <div className="flex h-full items-center justify-center p-10">
          <video
            src={file.stream_url}
            controls
            className="max-h-full max-w-full"
          />
        </div>
      );
    }
    if (file.mime_type.startsWith("image")) {
      return (
        <div className="flex h-full items-center justify-center p-10">
          <img
            src={file.stream_url}
            alt={file.name}
            className="max-h-full max-w-full"
          />
        </div>
      );
    }
    return null;
  }, [file.mime_type, file.name, file.stream_url, numPages, pdfUrl]);

  const $progress = useMemo(
    () => (
      <div className="flex items-center gap-5">
        <div className="h-[8px] w-[150px] overflow-hidden rounded bg-gray-200">
          <div
            style={{ width: `${progress}%` }}
            className="h-full bg-gray-500"
          />
        </div>
        <div className="text-xs text-gray-500">{progress}%</div>
      </div>
    ),
    [progress]
  );

  const $downloadOpenButton = useMemo(
    () => (
      <Button onClick={downloadedFile ? handleOpenClick : handleDownloadClick}>
        {downloadedFile
          ? t({
              id: "common.save",
              defaultMessage: "Save",
            })
          : t({
              id: "common.download",
              defaultMessage: "Download",
            })}
      </Button>
    ),
    [downloadedFile, handleDownloadClick, handleOpenClick, t]
  );

  return (
    <>
      {isPreviewAvailable ? (
        <div className="relative flex h-full w-full flex-col bg-white">
          <div className="flex h-[80px] w-full items-center justify-between px-8">
            <div className="flex items-center gap-2">
              <div>{$icon}</div>
              <div>{file.name}</div>
            </div>
            {downloadInProgress ? $progress : $downloadOpenButton}
          </div>
          <div className="flex-1 overflow-y-auto bg-gray-100">{$preview}</div>
        </div>
      ) : (
        <div className="flex h-full w-full flex-col items-center justify-center gap-3">
          <div>{$icon}</div>
          <div>{file.name}</div>
          {downloadInProgress ? $progress : $downloadOpenButton}
        </div>
      )}
    </>
  );
};
