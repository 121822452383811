import {
  Button,
  InteractiveContainer,
  TextField,
  OTPVerificationForm,
  PageLoaderFull,
} from "@jugl-web/ui-components/cross-platform";
import { BottomCenteredDrawer } from "@jugl-web/ui-components/web/BottomCenteredDrawer";
import React, { useMemo, useState } from "react";
import Lottie from "react-lottie";
import { isValidEmail, useToast, useTranslations } from "@jugl-web/utils";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { entitiesApi } from "@web-src/features/api/createApi";
import emailAnimation from "./assets/email.json";

export enum EmailVerificationStep {
  email_input = "email_input",
  code = "code",
}

const WorkspaceEmailVerificationContent: React.FC<{
  onRequestClose: () => void;
}> = ({ onRequestClose }) => {
  const { t } = useTranslations();
  const { entity } = useEntitySelectedProvider();
  const { toast } = useToast({ variant: "web" });
  const [workspaceEmailVerificationStep, setWorkspaceEmailVerificationStep] =
    useState<EmailVerificationStep>(EmailVerificationStep.email_input);
  const [emailVerify, { isLoading: isLoadingEmailVerify }] =
    entitiesApi.useVerifyEmailMutation();

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [workspaceEmail, setWorkspaceEmail] = useState(
    entity.billingEmail || ""
  );

  const isError = useMemo(
    () => !isValidEmail(workspaceEmail),
    [workspaceEmail]
  );

  const handleEmailChange = async () => {
    const response = await emailVerify({
      data: { billing_email: workspaceEmail },
      entityId: entity.id,
    });
    if (response && "data" in response) {
      setWorkspaceEmailVerificationStep(EmailVerificationStep.code);
    }
  };

  const handleEmailVerify = async (otp: string) => {
    const response = await emailVerify({
      data: { billing_email: workspaceEmail, otp },
      entityId: entity.id,
    });
    if (response && "data" in response) {
      toast(
        t({
          id: "workspace-page.workspace-email-was-successfully-verified",
          defaultMessage: "Workspace Email was successfully verified",
        })
      );
      onRequestClose();
    }
  };

  return (
    <>
      <PageLoaderFull isActive={isLoadingEmailVerify} isTransparent />
      <div className="flex h-full w-full items-center justify-center">
        <div>
          {workspaceEmailVerificationStep ===
          EmailVerificationStep.email_input ? (
            <form
              className="flex w-[370px] flex-col gap-4 text-center"
              onSubmit={async (e) => {
                e.preventDefault();
                setIsFormSubmitted(true);
                if (!isError) {
                  handleEmailChange();
                }
              }}
            >
              <Lottie
                options={{
                  animationData: emailAnimation,
                }}
                height={156}
                width={184}
              />
              <span className="text-dark text-[32px] font-semibold">
                {t({
                  id: "workspace-settings-page.workspace-email",
                  defaultMessage: "Workspace Email",
                })}
              </span>
              <span className="text-dark-600 leading-5">
                {t(
                  {
                    id: "workspace-settings-page.workspace-verification-email-info",
                    defaultMessage:
                      "Indicated Workspace Email will receive all Workspace updates, we want to verify it, to make sure your <securedDataText>data is secured</securedDataText> 🤝",
                  },
                  {
                    securedDataText: (text: (string | JSX.Element)[]) => (
                      <span className="text-primary-800">{text}</span>
                    ),
                  }
                )}
              </span>
              <TextField
                isFullWidth
                value={workspaceEmail}
                className="my-10"
                onChange={(e) => setWorkspaceEmail(e.target.value)}
                errorMessage={
                  isFormSubmitted && isError
                    ? t({
                        id: "workspace-settings-page.invalid-email",
                        defaultMessage: "Invalid email",
                      })
                    : undefined
                }
              />
              <Button
                type="submit"
                className="mx-auto w-[300px]"
                isDisabled={
                  entity.isEmailVerified &&
                  entity.billingEmail === workspaceEmail
                }
              >
                {t({
                  id: "common.verify",
                  defaultMessage: "Verify",
                })}
              </Button>
            </form>
          ) : (
            <OTPVerificationForm
              title={
                <span className="text-dark text-[32px] font-semibold leading-[48px]">
                  {t({
                    id: "workspace-settings-page.enter-code-sent-to-email",
                    defaultMessage: "Enter Code Sent to Your Email",
                  })}
                </span>
              }
              subTitle={
                <div className="flex flex-col items-center">
                  <span className="text-dark-600 leading-5">
                    {t(
                      {
                        id: "workspace-settings-page.we-sent-it-to-email",
                        defaultMessage: "We sent it to the Email {email}",
                      },
                      {
                        email: (
                          <span className="text-primary-800">
                            {workspaceEmail}
                          </span>
                        ),
                      }
                    )}
                  </span>
                  <InteractiveContainer
                    className="text-grey-900 hover:text-primary-800 w-max px-3 text-sm underline transition-all duration-300 hover:font-medium"
                    onClick={() =>
                      setWorkspaceEmailVerificationStep(
                        EmailVerificationStep.email_input
                      )
                    }
                  >
                    {t({
                      id: "workspace-settings-page.change-email",
                      defaultMessage: "Change email",
                    })}
                  </InteractiveContainer>
                </div>
              }
              onSubmit={(otp) => handleEmailVerify(otp)}
              onResend={() => (entity.billingEmail ? handleEmailChange : null)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export const WorkspaceEmailVerificationDialog: React.FC<{
  isOpen: boolean;
  onRequestClose: () => void;
}> = ({ isOpen, onRequestClose }) => {
  const { t } = useTranslations();
  return (
    <BottomCenteredDrawer
      isOpen={isOpen}
      onClose={onRequestClose}
      header={{
        type: "title",
        title: t({
          id: "workspace-settings-page.workspace-email",
          defaultMessage: "Workspace Email",
        }),
      }}
    >
      <WorkspaceEmailVerificationContent onRequestClose={onRequestClose} />
    </BottomCenteredDrawer>
  );
};
