import React, { useRef } from "react";
import {
  cx,
  onEnter,
  useIsFocused,
  useSearchInput,
  useTranslations,
} from "@jugl-web/utils";
import { ReactComponent as SearchIcon } from "./icons/search.svg";
import { ReactComponent as CancelIcon } from "./icons/cancel.svg";
import { InteractiveContainer, PlainButton } from "../../cross-platform";

export type ExpandableSearchBarSize = "md" | "lg";

export interface ExpandableSearchBarProps {
  color: "white" | "grey";
  size: ExpandableSearchBarSize;
  isAlwaysExpanded?: boolean;
  defaultValue?: string;
  onSearch: (searchQuery: string) => void;
}

const sizeToClasses: Record<ExpandableSearchBarSize, string> = {
  md: "h-8 w-8",
  lg: "h-10 w-10",
};

const sizeToIconClasses: Record<ExpandableSearchBarSize, string> = {
  md: "h-4 w-4",
  lg: "h-[18px] w-[18px]",
};

export const ExpandableSearchBar: React.FC<ExpandableSearchBarProps> = ({
  color,
  size,
  defaultValue,
  isAlwaysExpanded,
  onSearch,
}) => {
  const { hasInputValue, inputProps, reset } = useSearchInput({
    defaultValue,
    onSearch,
  });
  const { t } = useTranslations();
  const { isFocused, focusProps } = useIsFocused();
  const inputRef = useRef<HTMLInputElement>(null);

  const isSearchBarExpanded = isAlwaysExpanded || hasInputValue || isFocused;

  return (
    <InteractiveContainer
      className={cx(
        "jugl__focusable-outline relative w-max rounded-lg transition-colors",
        color === "grey" &&
          (isSearchBarExpanded ? "bg-grey-200" : "hover:bg-grey-200"),
        color === "white" &&
          (isSearchBarExpanded ? "bg-white" : "hover:bg-white")
      )}
      onKeyDown={() => inputRef.current?.focus()}
    >
      <SearchIcon
        className={cx(
          "pointer-events-none absolute top-1/2 -translate-y-1/2 transition-all",
          isSearchBarExpanded ? "left-4" : "left-1/2 -translate-x-1/2",
          sizeToIconClasses[size]
        )}
      />
      <input
        type="text"
        ref={inputRef}
        placeholder={t({
          id: "common.search-with-ellipsis",
          defaultMessage: "Search...",
        })}
        className={cx(
          "placeholder:text-grey border-none bg-transparent font-[Roboto] text-base font-medium leading-3 text-[#333333] outline-none transition-all placeholder:font-[Roboto] placeholder:font-normal placeholder:opacity-100",
          sizeToClasses[size],
          isSearchBarExpanded
            ? "w-[300px] px-[42px] opacity-100"
            : "cursor-pointer opacity-0"
        )}
        {...inputProps}
        {...focusProps}
      />
      <PlainButton
        className={cx(
          "absolute top-1/2 right-4 -translate-y-1/2 transition-opacity",
          hasInputValue ? "opacity-100" : "pointer-events-none opacity-0"
        )}
        onClick={reset}
        onKeyDown={onEnter(reset)}
      >
        <CancelIcon className={sizeToIconClasses[size]} />
      </PlainButton>
    </InteractiveContainer>
  );
};
