import { TaskPropertyButton } from "@jugl-web/ui-components/cross-platform/tasks/TaskPropertyButton";
import { FC } from "react";
import { useTranslations } from "@jugl-web/utils";
import { ReactComponent as DescriptionIcon } from "../../assets/description.svg";
import { FieldComponentProps } from "../../types";

export const DescriptionField: FC<FieldComponentProps<"description">> = ({
  isHidden,
  onClick,
}) => {
  const { t } = useTranslations();

  if (isHidden) {
    return null;
  }

  return (
    <TaskPropertyButton startIcon={<DescriptionIcon />} onClick={onClick}>
      {t({
        id: "tasks-page.add-description",
        defaultMessage: "Add description",
      })}
    </TaskPropertyButton>
  );
};
