import { FC } from "react";
import { ChevronButton } from "./ChevronButton";
import { EllipsisPlaceholder } from "./EllipsisPlaceholder";
import { PageNumberButton } from "./PageNumberButton";

export interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const MAX_SIBLINGS = 2;

export const Pagination: FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}: PaginationProps) => {
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;
  let isWithinEllipsis = false;

  return (
    <div className="flex items-center gap-1">
      <ChevronButton
        direction="left"
        isDisabled={isFirstPage}
        onClick={() => onPageChange(currentPage - 1)}
      />
      {Array.from({ length: totalPages }, (_, index) => {
        const isFirst = index === 0;
        const isCurrent = index === currentPage - 1;
        const isSibling = Math.abs(currentPage - 1 - index) <= MAX_SIBLINGS;
        const isLast = index === totalPages - 1;

        if (!isFirst && !isLast && !isCurrent && !isSibling) {
          if (isWithinEllipsis) {
            return null;
          }

          isWithinEllipsis = true;

          return <EllipsisPlaceholder />;
        }

        isWithinEllipsis = false;

        return (
          <PageNumberButton
            key={index}
            pageNumber={index + 1}
            isActive={isCurrent}
            onClick={onPageChange}
          />
        );
      })}
      <ChevronButton
        direction="right"
        isDisabled={isLastPage}
        onClick={() => onPageChange(currentPage + 1)}
      />
    </div>
  );
};
