import { useTaskStatuses } from "@jugl-web/domain-resources/tasks/hooks/useTaskStatuses";
import { InternalTaskFilters } from "@jugl-web/rest-api/tasks";
import { FC } from "react";
import { FilterPill } from "../FilterPill";

interface StatusFilterPillProps {
  entityId: string;
  statusId: InternalTaskFilters["statuses"][number];
  onRemove: () => void;
}

export const StatusFilterPill: FC<StatusFilterPillProps> = ({
  entityId,
  statusId,
  onRemove,
}) => {
  const { getStatusDetailsById } = useTaskStatuses({ entityId });

  const matchingStatusDetails = getStatusDetailsById(statusId);

  return <FilterPill label={matchingStatusDetails.label} onRemove={onRemove} />;
};
