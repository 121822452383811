import utcToZonedTime from "date-fns-tz/utcToZonedTime";
import parse from "date-fns/parse";
import { isIsoDate } from "./isIsoDate";

export const isoToLocalDate = (str: string) => {
  // TODO: re-check with dev
  const isoStr = str?.[str.length - 1] !== "Z" ? `${str}Z` : str;
  return utcToZonedTime(
    isIsoDate(isoStr)
      ? new Date(isoStr)
      : parse(str, "yyyy-MM-dd hh:mm", new Date()),
    new window.Intl.DateTimeFormat().resolvedOptions().timeZone
  );
};
