import { taskNameValidator } from "@jugl-web/domain-resources/tasks";
import { useTaskUnreadIndicator } from "@jugl-web/domain-resources/tasks/hooks/useTaskUnreadIndicator";
import { PlainButton } from "@jugl-web/ui-components/cross-platform/PlainButton";
import { cx, useTranslations } from "@jugl-web/utils";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { FC, useLayoutEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { ReactComponent as BellIcon } from "../../assets/bell.svg";
import { ReactComponent as ExpandIcon } from "../../assets/expand.svg";
import { TaskTableCellComponentProps } from "../../types";
import { TaskTableLayout } from "../TaskTableLayout";
import { TextCellEditor, TextCellEditorHandle } from "../TextCellEditor";

export const TaskNameCell: FC<TaskTableCellComponentProps> = ({
  task,
  entityId,
  permissions,
  searchQuery,
  cellProps,
  onUpdate,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const { isTaskUnread } = useTaskUnreadIndicator({ entityId });
  const { navigateToPage } = useNavigation();

  const $textCellEditorRef = useRef<TextCellEditorHandle | null>(null);

  const { t } = useTranslations();

  const isUnread = isTaskUnread(task.id);

  const handleCellClick = () => {
    if (!isEditing) {
      setIsEditing(true);
      return;
    }

    $textCellEditorRef.current?.focus();
  };

  const handleSubmit = (richText: string) => {
    onUpdate({ name: richText });
    setIsEditing(false);
  };

  useLayoutEffect(() => {
    if (isEditing) {
      $textCellEditorRef.current?.setContent(task.name);
    }
  }, [isEditing, task.name]);

  return (
    <TaskTableLayout.Cell
      isFocused={isEditing}
      isHoverable
      isDisabled={!permissions.canEditTitle}
      className={cx("justify-between gap-2", isEditing && "cursor-text")}
      onClick={handleCellClick}
      {...cellProps}
    >
      {isEditing ? (
        <TextCellEditor
          ref={$textCellEditorRef}
          placeholder={t({
            id: "form-controls.task-title.placeholder-extended",
            defaultMessage: "Enter task title",
          })}
          validator={taskNameValidator}
          onSubmit={handleSubmit}
          onCancel={() => setIsEditing(false)}
        />
      ) : (
        <>
          <TaskTableLayout.CellText className="flex-grow font-medium">
            {searchQuery ? (
              <Highlighter
                autoEscape
                highlightClassName="text-primary bg-primary-50"
                highlightTag="span"
                textToHighlight={task.name}
                searchWords={[searchQuery]}
              />
            ) : (
              task.name
            )}
          </TaskTableLayout.CellText>
          <div className="flex items-center gap-4">
            {isUnread && <BellIcon className="shrink-0" />}
            <PlainButton
              className="hover:bg-grey/5 hidden h-[34px] w-[34px] items-center justify-center rounded transition-colors group-hover/cell:flex"
              onClick={(event) => {
                event.stopPropagation();
                navigateToPage("tasksDetails", { taskId: task.id });
              }}
            >
              <ExpandIcon />
            </PlainButton>
          </div>
        </>
      )}
    </TaskTableLayout.Cell>
  );
};
