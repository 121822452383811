import { useRestApiProvider } from "@jugl-web/rest-api";
import { useEffect } from "react";

interface UseMarkTaskAsViewedOptions {
  entityId: string;
  taskId: string | undefined;
}

export const useMarkTaskAsViewed = ({
  entityId,
  taskId,
}: UseMarkTaskAsViewedOptions) => {
  const { tasksApi } = useRestApiProvider();

  const [markTaskAsViewed] = tasksApi.useMarkTaskAsViewedMutation();

  useEffect(() => {
    if (taskId) {
      markTaskAsViewed({ entityId, taskId });
    }
  }, [entityId, markTaskAsViewed, taskId]);
};
