import format from "date-fns/format";
import isThisYear from "date-fns/isThisYear";
import isToday from "date-fns/isToday";
import isTomorrow from "date-fns/isTomorrow";
import { useCallback } from "react";
import { useLanguage } from "../i18n/EnhancedIntlProvider";
import { useTranslations } from "./useTranslations";

type DateLabelFormattingVariant = "regular" | "concise";
type DateTimeLabelFormattingVariant = "regular";

export const useFormattedDate = () => {
  const { t } = useTranslations();
  const { dateLocale } = useLanguage();

  const localeAwareFormat = useCallback(
    (date: Date | number, formatString: string) =>
      format(date, formatString, {
        locale: dateLocale,
        weekStartsOn: 1,
      }),
    [dateLocale]
  );

  /**
   * Formats a date label based on the given variant:
   * - `regular` => `December 25, 2023`
   * - `concise` => `Dec 25, 2023`
   */
  const formatDateLabel = useCallback(
    (date: Date | number, variant: DateLabelFormattingVariant = "regular") => {
      if (isToday(date)) {
        return t({ id: "common.today", defaultMessage: "Today" });
      }

      if (isTomorrow(date)) {
        return t({ id: "common.tomorrow", defaultMessage: "Tomorrow" });
      }

      const formattedYear = isThisYear(date) ? "" : ", uuuu";

      if (variant === "concise") {
        return localeAwareFormat(date, "MMM d".concat(formattedYear));
      }

      return localeAwareFormat(date, "MMMM d".concat(formattedYear));
    },
    [localeAwareFormat, t]
  );

  /**
   * Formats a date time label based on the given variant:
   * - `regular` => `19 Jul 2024, 12:00 PM`
   */
  const formatDateTimeLabel = useCallback(
    (
      date: Date | number,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      variant: DateTimeLabelFormattingVariant = "regular"
    ) => {
      const dateLabel = (() => {
        if (isToday(date)) {
          return t({ id: "common.today", defaultMessage: "Today" });
        }

        if (isTomorrow(date)) {
          return t({ id: "common.tomorrow", defaultMessage: "Tomorrow" });
        }

        return localeAwareFormat(
          date,
          isThisYear(date) ? "d MMMM" : "d MMM uuuu"
        );
      })();

      const timeLabel = localeAwareFormat(date, "hh:mm a");

      return `${dateLabel}, ${timeLabel}`;
    },
    [localeAwareFormat, t]
  );

  return {
    localeAwareFormat,
    formatDateLabel,
    formatDateTimeLabel,
  };
};
