import { InternalTaskCustomField } from "@jugl-web/rest-api/tasks";
import {
  Button,
  PlainButton,
  TextField,
} from "@jugl-web/ui-components/cross-platform";
import { Dialog } from "@jugl-web/ui-components/cross-platform/Dialog";
import { MobileDrawer } from "@jugl-web/ui-components/mobile/MobileDrawer";
import { useAppVariant, useTranslations } from "@jugl-web/utils";
import { FC, useEffect, useRef, useState } from "react";
import { ReactComponent as CloseIcon } from "./assets/close.svg";

interface EditCustomTextOrNumberFieldDialogProps {
  isOpen: boolean;
  field: InternalTaskCustomField;
  rawValue: string;
  onSubmit: (value: string) => void;
  onClose: () => void;
  onLeaveAnimationEnd?: () => void;
}

export const EditCustomTextOrNumberFieldDialog: FC<
  EditCustomTextOrNumberFieldDialogProps
> = ({ isOpen, field, rawValue, onSubmit, onClose, onLeaveAnimationEnd }) => {
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { isMobile } = useAppVariant();
  const { t } = useTranslations();

  const hasValue = inputValue.trim().length > 0;
  const hasNumberType = field?.type === "number";
  const isValid = hasNumberType ? !Number.isNaN(Number(inputValue)) : true;

  const handleSubmit = () => {
    onSubmit(hasValue ? inputValue : "");
    onClose();
  };

  const commonDialogProps = {
    isOpen,
    initialFocus: inputRef,
    onClose,
    onTransitionEnd: () => {
      if (!isOpen) {
        onLeaveAnimationEnd?.();
      }
    },
  };

  const $actionButtons = (
    <>
      <Button
        variant="contained"
        color="grey"
        fullWidth
        className="h-10"
        onClick={onClose}
      >
        {t({
          id: "common.cancel",
          defaultMessage: "Cancel",
        })}
      </Button>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        isDisabled={!isValid}
        className="h-10"
        onClick={handleSubmit}
      >
        {t({
          id: "common.save",
          defaultMessage: "Save",
        })}
      </Button>
    </>
  );

  const $textField = (
    <TextField
      ref={inputRef}
      type={hasNumberType ? "number" : "text"}
      isFullWidth
      autoFocus
      placeholder={
        hasNumberType
          ? t({
              id: "form-controls.number-custom-field.placeholder",
              defaultMessage: "Enter some number here",
            })
          : t({
              id: "form-controls.text-custom-field.placeholder",
              defaultMessage: "Enter some text here",
            })
      }
      value={inputValue}
      onChange={(event) => setInputValue(event.target.value)}
      errorMessage={
        !isValid
          ? t({
              id: "feedback.only-numbers-allowed",
              defaultMessage: "Only numbers can be entered",
            })
          : undefined
      }
    />
  );

  useEffect(() => {
    if (isOpen) {
      setInputValue(rawValue);
    }
  }, [rawValue, isOpen]);

  if (isMobile) {
    return (
      <MobileDrawer
        header={{ title: field.name }}
        size="auto"
        {...commonDialogProps}
      >
        <MobileDrawer.Content className="mt-0 pt-1 pb-12">
          {$textField}
        </MobileDrawer.Content>
        <MobileDrawer.Actions className="flex-row pb-6">
          {$actionButtons}
        </MobileDrawer.Actions>
      </MobileDrawer>
    );
  }

  return (
    <Dialog className="w-[500px]" {...commonDialogProps}>
      <div
        className="flex h-[60px] items-center justify-between gap-2 px-8"
        style={{ boxShadow: "2px -6px 24px 0px rgba(18, 22, 34, 0.16)" }}
      >
        <span className="text-dark truncate text-base font-semibold">
          {field.name}
        </span>
        <PlainButton onClick={onClose}>
          <CloseIcon />
        </PlainButton>
      </div>
      <div className="px-8 py-10">{$textField}</div>
      <div
        className="flex h-[88px] items-center gap-3.5 px-6"
        style={{ boxShadow: "0px 6px 24px 0px rgba(18, 22, 34, 0.12)" }}
      >
        {$actionButtons}
      </div>
    </Dialog>
  );
};
