export enum SubscriptionPlanModuleId {
  chat = "Jugl::Chat",
  calls = "Jugl::Calls",
  people = "Jugl::People",
  timeLog = "Jugl::TimeLog",
  drive = "Jugl::Drive",
  conference = "Jugl::Conference",
  clients = "Jugl::Clients",
  reports = "Jugl::Reports",
  taskSharing = "Jugl::Task::Sharing",
}
