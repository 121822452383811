import { DragOverlay, useDraggable } from "@dnd-kit/core";
import { Portal } from "@headlessui/react";
import {
  InteractiveContainer,
  InteractiveContainerProps,
} from "@jugl-web/ui-components/cross-platform";
import { cx, useUniqueId } from "@jugl-web/utils";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { format } from "date-fns";
import { FC, forwardRef } from "react";
import Highlighter from "react-highlight-words";
import { TaskCardDraggableData } from "../../types";
import { shortenTaskTitle } from "../../utils";
import { TaskCardProps } from "./TaskCard";
import { useTaskCalendarCardAttributes } from "./useTaskCalendarCardStyles";

export const TASK_CALENDAR_PILL_HEIGHT = 26;

export const TaskCalendarPillBase = forwardRef<
  HTMLElement,
  InteractiveContainerProps
>(({ children, className, style, ...props }, ref) => (
  <InteractiveContainer
    ref={ref}
    className={cx(
      "jugl__focusable-outline relative flex w-full shrink-0 items-center rounded-lg py-1 px-1.5 transition-colors",
      className
    )}
    style={{ height: TASK_CALENDAR_PILL_HEIGHT, ...style }}
    {...props}
  >
    <span className="font-secondary text-dark overflow-hidden text-clip whitespace-nowrap text-xs">
      {children}
    </span>
  </InteractiveContainer>
));

export const TaskCalendarPill: FC<TaskCardProps> = (props) => {
  const {
    taskId,
    isUnread,
    title,
    dueDate,
    isOverdue,
    isCompleted,
    checklist,
    timeSpent,
    fields,
    highlightedText,
    className,
    draggable,
    columnId,
    onClick,
    ...divProps
  } = props;

  const uniqueId = useUniqueId();

  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: `task-${taskId}-${uniqueId}`,
    disabled: !draggable,
    data: {
      type: "task",
      taskId,
      columnId,
    } satisfies TaskCardDraggableData,
  });

  const statusBasedAttributes = useTaskCalendarCardAttributes(props);

  const { dateLocale } = useLanguage();

  const shortenedTitle = shortenTaskTitle(title);

  return (
    <>
      {isDragging && (
        <Portal>
          <DragOverlay>
            <TaskCalendarPill
              {...props}
              draggable={false}
              className="border-primary-600 border-px rotate-2 border-dashed opacity-80"
            />
          </DragOverlay>
        </Portal>
      )}
      <TaskCalendarPillBase
        ref={setNodeRef}
        className={cx(
          statusBasedAttributes.backgroundClassName,
          isCompleted ? "text-dark-700" : "text-black",
          className
        )}
        onClick={onClick as InteractiveContainerProps["onClick"]}
        {...listeners}
        {...attributes}
        {...divProps}
      >
        {isDragging && <div className="bg-dark-200 absolute inset-0 z-20" />}
        {dueDate && (
          <span
            className={cx(
              "mr-1 font-semibold underline",
              isCompleted ? "text-grey" : "text-dark-800"
            )}
          >
            {format(dueDate, "hh:mm a", { locale: dateLocale })}
          </span>
        )}
        {highlightedText ? (
          <Highlighter
            autoEscape
            highlightClassName="text-primary bg-primary-50"
            highlightTag="span"
            textToHighlight={shortenedTitle}
            searchWords={[highlightedText]}
          />
        ) : (
          shortenedTitle
        )}
      </TaskCalendarPillBase>
    </>
  );
};
