import React, { useMemo, useState } from "react";
import mime from "mime";
import { cx } from "@jugl-web/utils/cx";
import { ReactComponent as DocIconComponent } from "./icons/doc-icon.svg";
import { ReactComponent as PdfIconComponent } from "./icons/pdf-icon.svg";
import { ReactComponent as TextIconComponent } from "./icons/text-icon.svg";
import { ReactComponent as ZipIconComponent } from "./icons/zip-icon.svg";
import { ReactComponent as DownloadIconComponent } from "./icons/download-icon.svg";
import { ReactComponent as CancelIconComponent } from "./icons/cancel-icon.svg";
import { ReactComponent as ExcelIconComponent } from "./icons/excel-icon.svg";
import { ReactComponent as OtherIconComponent } from "./icons/other-icon.svg";
import { InteractiveContainer } from "../../InteractiveContainer";

export interface MessageFileAttachmentProps {
  name: string;
  previewImg?: string;
  mimeType?: string;
  onDownloadClick?: () => void;
  onCancelClick?: () => void;
  onOpenClick?: () => void;
  progress?: number;
}

// TODO: move to utils
const isCompressed = (mimeType: string) => {
  const compressedMimeTypes = [
    "application/vnd.rar",
    "application/x-rar-compressed",
    "application/octet-stream",
    "application/zip",
    "application/octet-stream",
    "application/x-zip-compressed",
    "multipart/x-zip",
  ];
  return compressedMimeTypes.includes(mimeType);
};

export const MessageFileAttachment: React.FC<MessageFileAttachmentProps> = ({
  mimeType,
  name,
  previewImg,
  onDownloadClick,
  onCancelClick,
  onOpenClick,
  progress,
}) => {
  const [previewError, setPreviewError] = useState<boolean>();
  const fileMimeType = useMemo(() => {
    if (mimeType) {
      return mimeType;
    }
    return mime.getType(name);
  }, [mimeType, name]);

  const $icon = useMemo(() => {
    const props = { className: "w-full h-full" };
    if (fileMimeType && isCompressed(fileMimeType)) {
      return <ZipIconComponent {...props} />;
    }
    if (fileMimeType === "application/pdf") {
      return <PdfIconComponent {...props} />;
    }
    if (fileMimeType === "text/plain") {
      return <TextIconComponent {...props} />;
    }
    if (
      fileMimeType &&
      [
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ].includes(fileMimeType)
    ) {
      return <DocIconComponent {...props} />;
    }
    if (
      fileMimeType &&
      [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ].includes(fileMimeType)
    ) {
      return <ExcelIconComponent {...props} />;
    }
    return <OtherIconComponent {...props} />;
  }, [fileMimeType]);

  const actionButtonClasses = "flex h-11 w-11 items-center justify-center";

  // TODO: check color
  return (
    <div
      className={cx(
        "relative flex h-11 min-w-[250px] max-w-[400px] items-center justify-between overflow-hidden rounded bg-[#ECF5FA]"
      )}
    >
      <div
        className={cx(
          "flex h-11 w-11 items-center justify-center",
          previewImg ? "mr-1 h-10 w-10" : "h-11 w-11"
        )}
      >
        {previewImg && !previewError ? (
          <img
            src={previewImg}
            alt={name}
            className="max-h-full max-w-full bg-cover"
            onError={() => setPreviewError(true)}
          />
        ) : (
          <div className="h-6 w-6">{$icon}</div>
        )}
      </div>
      <div className="text-dark ml-1 mr-2 flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
        {name}
      </div>
      {onDownloadClick && (
        <InteractiveContainer
          className={actionButtonClasses}
          onClick={onDownloadClick}
        >
          <DownloadIconComponent />
        </InteractiveContainer>
      )}
      {onCancelClick && (
        <InteractiveContainer
          className={actionButtonClasses}
          onClick={onCancelClick}
        >
          <CancelIconComponent />
        </InteractiveContainer>
      )}
      {onOpenClick && (
        <InteractiveContainer
          className={cx(actionButtonClasses, "mr-1")}
          onClick={onOpenClick}
        >
          <span className="text-primary-500 text-sm font-medium uppercase">
            Save
          </span>
        </InteractiveContainer>
      )}
      {progress !== undefined && (
        <div className="absolute bottom-0 left-0 h-[2px] w-full bg-[#DADADA]">
          <div
            className="h-full bg-[#4E93CA] transition-all"
            style={{ width: `${progress}%` }}
          />
        </div>
      )}
    </div>
  );
};
