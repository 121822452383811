import React, { PropsWithChildren, useMemo } from "react";
import { useTranslations } from "@jugl-web/utils";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { TabsLayout } from "@web-src/modules/common/components/TabsLayout";

type TabId = "main-settings" | "profile-info";

type TabItem = {
  id: TabId;
  title: string;
  hidden?: boolean;
  href?: string;
  onClick?: () => void;
};

export type UserAccountSettingsLayoutProps = PropsWithChildren<{
  selectedTab: TabId;
  rightContent?: React.ReactNode;
}>;

export const UserAccountSettingsLayout: React.FC<
  PropsWithChildren<UserAccountSettingsLayoutProps>
> = ({ children, selectedTab, rightContent }) => {
  const { t } = useTranslations();
  const { navigateToPage, generateUrl } = useNavigation();

  const tabs: TabItem[] = useMemo(() => {
    const result: TabItem[] = [
      {
        id: "main-settings",
        title: t({
          id: "settings-page.main-settings",
          defaultMessage: "Main Settings",
        }),
        onClick: () => navigateToPage("settingsMainSettings"),
      },
      {
        id: "profile-info",
        title: t({
          id: "settings-page.profile-info",
          defaultMessage: "Profile Info",
        }),
        onClick: () =>
          navigateToPage("settingsEditProfile", { section: "personal-info" }),
      },
    ];
    return result.filter((tab) => !tab.hidden);
  }, [t, navigateToPage]);

  return (
    <TabsLayout
      tabs={tabs}
      selectedTab={selectedTab}
      rightContent={rightContent}
      breadcrumbProps={{
        items: [
          {
            title: t({
              id: "settings-page.settings",
              defaultMessage: "Settings",
            }),
            href: generateUrl("settingsSettings"),
          },
          {
            title: t({
              id: "settings-page.account-settings",
              defaultMessage: "Account Settings",
            }),
          },
        ],
      }}
    >
      {children}
    </TabsLayout>
  );
};
