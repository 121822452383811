export const omit = <TObject extends object, TKey extends keyof TObject>(
  object: TObject,
  ...keys: TKey[]
): Omit<TObject, TKey> => {
  const result = { ...object };

  keys.forEach((key) => {
    if (key in result) {
      delete result[key];
    }
  });

  return result;
};
