import { TASK_COLUMNS_IDS } from "@jugl-web/domain-resources/tasks";
import { useTaskSelectors } from "@jugl-web/domain-resources/tasks/hooks/useTaskSelectors";
import { UserGeneralProfile } from "@jugl-web/domain-resources/users/components/UserGeneralProfile";
import { PreviewTask } from "@jugl-web/rest-api/tasks";
import {
  Avatar,
  Collapse,
  InteractiveContainer,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { cx, useTranslations } from "@jugl-web/utils";
import { useTasksPageContext } from "@web-src/features/tasks/TasksPageContext";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { endOfDay, endOfWeek, startOfWeek } from "date-fns";
import { FC, useMemo } from "react";
import { ReactComponent as ArrowDownIcon } from "../../assets/arrow-down.svg";
import { useWeekdays } from "../../hooks/useWeekdays";
import { CalendarViewLayout } from "../CalendarViewLayout";
import { CalendarWeekColumnList } from "../CalendarWeekColumnList";

interface CalendarWeekReporteePanelProps {
  isCollapsed: boolean;
  userId: string;
  meId: string;
  tasks: PreviewTask[];
  onToggle: () => void;
}

export const CalendarWeekReporteePanel: FC<CalendarWeekReporteePanelProps> = ({
  isCollapsed,
  userId,
  meId,
  tasks,
  onToggle,
}) => {
  const { taskCalendarDate } = useTasksPageContext();
  const { entityId } = useEntitySelectedProvider();

  const { createSelectTasksByDueDateInterval } = useTaskSelectors({ entityId });

  const weekdays = useWeekdays();

  const { t } = useTranslations();

  const selectTasksByDueDateInterval = useMemo(
    () => createSelectTasksByDueDateInterval(tasks),
    [createSelectTasksByDueDateInterval, tasks]
  );

  const tasksCountPerWeek = useMemo(
    () =>
      selectTasksByDueDateInterval(
        startOfWeek(taskCalendarDate, { weekStartsOn: 1 }),
        endOfWeek(taskCalendarDate, { weekStartsOn: 1 })
      ).length,
    [taskCalendarDate, selectTasksByDueDateInterval]
  );

  return (
    <div className="flex w-full flex-col gap-1">
      <InteractiveContainer
        onClick={onToggle}
        className="hover:bg-grey-100 flex h-[56px] w-full items-center bg-white px-8 transition-colors"
      >
        <PlainButton className="bg-dark-100 hover:text-primary-800 hover:bg-dark-200 mr-4 flex h-8 w-8 items-center justify-center rounded-lg text-[#93949A] transition-colors">
          <ArrowDownIcon
            className={cx("transition-transform", !isCollapsed && "rotate-180")}
          />
        </PlainButton>
        <UserGeneralProfile entityId={entityId} userId={userId}>
          {({ safeProfile, profile }) => (
            <div className="flex items-center gap-2">
              <Avatar
                size="sm"
                username={safeProfile.displayName}
                imageUrl={safeProfile.avatar}
              />
              <span className="font-secondary text-dark text-sm font-semibold">
                {safeProfile.displayName}{" "}
                {profile &&
                  profile.id === meId &&
                  `(${t({ id: "common.me", defaultMessage: "Me" })})`}
              </span>
            </div>
          )}
        </UserGeneralProfile>
        <span className="font-secondary text-grey-800 ml-2 text-xs">
          {t(
            {
              id: "tasks-page.tasks-count",
              defaultMessage:
                "{count} {count, plural, one {task} other {tasks}}",
            },
            { count: tasksCountPerWeek }
          )}
        </span>
      </InteractiveContainer>
      <Collapse isOpen={!isCollapsed} className="flex items-stretch gap-0.5">
        {weekdays.map((weekday) => {
          const reporteePerDayTasks = selectTasksByDueDateInterval(
            weekday.date,
            endOfDay(weekday.date)
          );

          return (
            <CalendarViewLayout.Column
              key={weekday.date.valueOf()}
              className="group h-auto max-h-[600px] min-h-[100px] min-w-[207px]"
            >
              <CalendarWeekColumnList
                date={weekday.date}
                tasks={reporteePerDayTasks}
                droppableConfig={{
                  id: TASK_COLUMNS_IDS.CALENDAR_VIEW_REPORTEE_ID(
                    weekday.date,
                    userId
                  ),
                  data: {
                    type: "calendarReportee",
                    value: {
                      dateISO: endOfDay(weekday.date).toISOString(),
                      reporteeId: userId,
                    },
                  },
                }}
                newTaskConfig={{
                  initialState: {
                    dueDate: endOfDay(weekday.date),
                    assigneeIds: [userId],
                  },
                  taskPropertiesPanelConfigOverrides: {
                    dueDate: { isDateEditable: false },
                    assignees: { isHidden: true },
                  },
                }}
              />
            </CalendarViewLayout.Column>
          );
        })}
      </Collapse>
    </div>
  );
};
