import { useCallback, useContext } from "react";
import {
  ChatMessagePayloadAttachment,
  PheonixPushAction,
} from "@web-src/features/chats/types";
import { PhoenixSocketContext } from "@web-src/features/chats/providers/PheonixSocket";
import { useUpdateChatMessages } from "@web-src/modules/chats/hooks/useUpdateChatMessages";

type EditMessageParams = {
  msgId: string;
  chatId: string;
  body?: string;
  attachment?: ChatMessagePayloadAttachment;
};

const useEditMessage = () => {
  const { channel } = useContext(PhoenixSocketContext);
  const { updateMessage } = useUpdateChatMessages();
  const editMessageCallback = useCallback(
    ({ msgId, body, attachment, chatId }: EditMessageParams) =>
      // eslint-disable-next-line no-async-promise-executor
      new Promise(async (resolve, reject) => {
        if (!channel) {
          reject(new Error("No channel"));
          return;
        }
        const message = channel?.push(PheonixPushAction.update, {
          msg_id: msgId,
          payload: attachment
            ? {
                attachments: [{ ...attachment, caption: body }],
                body: "",
              }
            : { body },
        });
        message.receive("ok", (e) => {
          updateMessage({
            chatId,
            item: {
              id: msgId,
              data: {
                edited: true,
                payload: attachment
                  ? {
                      attachments: [{ ...attachment, caption: body }],
                      body: "",
                    }
                  : { body },
              },
            },
            merge: true,
          });
          resolve(e);
        });
        message.receive("error", reject);
        message.receive("timeout", reject);
      }),
    [channel, updateMessage]
  );
  return editMessageCallback;
};

export default useEditMessage;
