import { createTheme, Shadows } from "@mui/material/styles";
import {
  primary,
  secondary,
  dark,
  grey,
  tertiary,
  gradients,
} from "@web-src/themes/colors";

function getTheme(darkMode?: boolean) {
  return createTheme({
    breakpoints: {
      keys: ["xs", "sm", "md", "lg", "xl"],
      values: { xs: 0, lg: 1280, sm: 600, xl: 1920, md: 960 },
    },
    transitions: {
      easing: {
        easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
        easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
        easeIn: "cubic-bezier(0.4, 0, 1, 1)",
        sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
      },
      duration: {
        standard: 300,
        short: 250,
        enteringScreen: 225,
        shorter: 200,
        leavingScreen: 195,
        shortest: 150,
        complex: 375,
      },
    },
    palette: {
      mode: darkMode ? "dark" : "light",
      juglPrimary: {
        50: "#E3F2FD",
        100: "#BBDEFB",
        200: "#90C9F9",
        300: "#63B4F6",
        400: "#42A4F5",
        500: "#2196F3",
        600: "#1F87E5",
        700: "#1A75D2",
        800: "#1764C0",
        900: "#1045A1",
        main: "#2196F3",
      },
      juglSecondary: {
        50: "#EBFFF5",
        100: "#C1EFD9",
        200: "#87E2B7",
        300: "#3AD68C",
        400: "#27BB74",
        500: "#09B965",
        600: "#429760",
        700: "#398453",
        800: "#327246",
        900: "#235531",
        main: "#09B965",
      },
      juglTertiary: {
        50: "#FAECEE",
        100: "#F8DFE2",
        200: "#F3CED2",
        300: "#DD9C9D",
        400: "#CE7877",
        500: "#E55353",
        600: "#D75144",
        700: "#C94841",
        800: "#B83E3A",
        900: "#9F2E29",
        main: "#E55353",
      },
      juglDark: {
        100: "#ECEFF0",
        200: "#E0E2E3",
        300: "#C5C9CB",
        400: "#A7AEB0",
        500: "#8F979A",
        600: "#737B7E",
        700: "#5A6367",
        800: "#40494D",
        900: "#2C3438",
        1000: "#1A2023",
        main: "#1A2023",
      },
      juglGrey: {
        100: "#F9F9F9",
        200: "#F2F2F4",
        300: "#DEDEE4",
        400: "#D2D2D8",
        500: "#C5C5CA",
        600: "#B5B5BB",
        700: "#A4A5AA",
        800: "#93949A",
        900: "#818288",
        1000: "#707577",
        main: "#707577",
      },
      juglBlack: "#000000",
      juglWhite: "#FFFFFF",
      juglGradients: {
        G1: "#22C59E",
        G2: "#EE5A8A",
        G3: "#AB59E4",
        G4: "#F1B43A",
        G5: "#4C8CEB",
        G6: "#F15C5C",
        danger: "#EA5353",
        success: "#50BA4D",
        warning: "#F7C647",
      },
      juglUsers: {
        user1: "#2196F3",
        user2: "#F33A9E",
        user3: "#F36021",
        user4: "#B021F3",
        user5: "#2FB819",
        user6: "#F32121",
        user7: "#019F70",
        user8: "#293AD3",
        user9: "#9A5E18",
        user10: "#B50870",
      },
      juglMessages: {
        chatBubbleSender: "#6192BE",
        chatBubbleReceiver: "#FFFFFF",
      },

      // TODO: Deprecated, to be removed
      blue: primary,
      primary: {
        ...primary,
        main: "#2196f3",
        light: "#63b4f6",
        dark: "#1a75d2",
      },
      secondary: {
        ...secondary,
        main: "#09b965",
        light: "#3ad68c",
        dark: "#398453",
      },
      success: {
        main: "#50BA4D",
      },
      dark: {
        main: dark.A100,
        ...dark,
      },
      grey: {
        ...grey,
      },
      tertiary: {
        main: tertiary["500"],
        ...tertiary,
      },
      gradients,
      chat: {
        blueBackground: "#4E93CA",
      },
    },
    shadows: [
      "none",
      ...[...Array(15)].map(() => "0px 4px 18px rgba(18, 22, 34, 0.05)"),
      "-2px 0px 3px rgba(0, 0, 0, 0.05)",
      ...[...Array(8)].map(() => "0px 4px 18px rgba(18, 22, 34, 0.05)"),
    ] as Shadows,
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            borderRadius: 8,
          },
          textInherit: {
            backgroundColor: darkMode ? dark.A100 : "#F2F2F4",
          },
          textError: {
            backgroundColor: darkMode ? dark.A100 : undefined,
          },
          sizeLarge: {
            padding: "12px 20px",
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            juglH1: "h1",
            juglH2: "h2",
            juglH3: "h3",
            juglH4: "h4",
            juglBody1: "span",
            juglBody2: "span",
            juglBody3: "span",
            juglFormField: "span",
            juglFormHelper: "span",
            juglLink1: "span",
            juglLink2: "span",
            juglLink3: "span",
          },
        },
        styleOverrides: {
          // Jugl
          juglH1: {
            fontWeight: 500,
            fontSize: "64px",
            lineHeight: "76px",
          },
          juglH2: {
            fontWeight: 500,
            fontSize: "36px",
            lineHeight: "44px",
          },
          juglH3: {
            fontWeight: 500,
            fontSize: "24px",
            lineHeight: "44px",
          },
          juglH4: {
            fontWeight: 500,
            fontSize: "20px",
            lineHeight: "28px",
          },
          juglBody1: {
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "28px",
          },
          juglBody2: {
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
          },
          juglBody3: {
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
          },
          juglFormField: {
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
          },
          juglFormHelper: {
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
          },
          juglLink1: {
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "24px",
          },
          juglLink2: {
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "20px",
          },
          juglLink3: {
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
          },
          juglMessageLarge: {
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "28px",
          },
          juglMessage: {
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
          },
          juglMessageTimestamp: {
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "28px",
          },

          // TODO: Deprecated, to be removed
          root: {
            lineHeight: "1.4",
          },
          body: {
            fontSize: 16,
            fontWeight: 400,
            lineHeight: "20px",
          },
          bodyBold: {
            fontSize: 16,
            fontWeight: 500,
            lineHeight: "20px",
          },
          headline: {
            fontSize: 18,
            lineHeight: "22px",
            fontWeight: 400,
          },
          subHeadline: {
            fontSize: 13,
            lineHeight: "16px",
            fontWeight: 400,
          },
          footnote: {
            fontSize: 13,
            lineHeight: "15px",
            fontWeight: 400,
          },
          footnoteBold: {
            fontSize: 13,
            lineHeight: "15px",
            fontWeight: 500,
          },
          button: {
            fontSize: 14,
            lineHeight: "18px",
            fontWeight: 500,
            textTransform: "uppercase",
          },
          callout: {
            fontSize: 14,
            lineHeight: "18px",
            fontWeight: 400,
          },
          calloutBold: {
            fontSize: 14,
            lineHeight: "18px",
            fontWeight: 700,
          },
          h1: {
            fontSize: 36,
            fontWeight: 400,
            lineHeight: "44px",
          },
          h3: {
            fontSize: 24,
            fontWeight: 700,
            lineHeight: "30px",
          },
          h4: {
            fontSize: 20,
            lineHeight: "24px",
            fontWeight: 400,
          },
          h5: {
            lineHeight: 1.2,
          },
          title3: {
            fontSize: "24px",
            fontWeight: 400,
            lineHeight: "30px",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            backgroundColor: "white",
          },
        },
      },
    },
  });
}

export default getTheme;
