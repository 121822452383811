import { FC } from "react";
import { useUserGeneralProfile } from "../../hooks/useUserGeneralProfile";

interface UserGeneralProfileProps {
  userId: string;
  entityId: string;
  children: (props: ReturnType<typeof useUserGeneralProfile>) => JSX.Element;
}

export const UserGeneralProfile: FC<UserGeneralProfileProps> = ({
  userId,
  entityId,
  children,
}) => {
  const userGeneralProfile = useUserGeneralProfile({ userId, entityId });
  return children(userGeneralProfile);
};
