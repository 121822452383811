import { DragOverlay, useDraggable } from "@dnd-kit/core";
import { Portal } from "@headlessui/react";
import {
  InteractiveContainer,
  InteractiveContainerProps,
} from "@jugl-web/ui-components/cross-platform";
import { cx, useUniqueId } from "@jugl-web/utils";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import format from "date-fns/format";
import { FC, useMemo } from "react";
import Highlighter from "react-highlight-words";
import { TaskCardDraggableData } from "../../types";
import { shortenTaskTitle } from "../../utils";
import { TaskCardProps } from "./TaskCard";
import { useTaskCalendarCardAttributes } from "./useTaskCalendarCardStyles";

export const TaskCalendarCard: FC<TaskCardProps> = (props) => {
  const {
    taskId,
    isUnread,
    title,
    dueDate,
    isOverdue,
    isCompleted,
    checklist,
    timeSpent,
    fields,
    highlightedText,
    className,
    style,
    draggable,
    columnId,
    onClick,
    ...divProps
  } = props;

  const uniqueId = useUniqueId();

  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: `task-${taskId}-${uniqueId}`,
    disabled: !draggable,
    data: {
      type: "task",
      taskId,
      columnId,
    } satisfies TaskCardDraggableData,
  });

  const statusBasedAttributes = useTaskCalendarCardAttributes(props);

  const { dateLocale } = useLanguage();

  const shortenedTitle = shortenTaskTitle(title);

  const labelField = useMemo(
    () => fields.find(({ id }) => id === "label"),
    [fields]
  );

  return (
    <>
      {isDragging && (
        <Portal>
          <DragOverlay>
            <TaskCalendarCard
              {...props}
              draggable={false}
              className="border-primary-600 border-px rotate-2 border-dashed opacity-80"
            />
          </DragOverlay>
        </Portal>
      )}
      <InteractiveContainer
        ref={setNodeRef}
        className={cx(
          "relative flex touch-pan-y flex-col gap-2.5 overflow-hidden rounded-lg px-2 py-3 transition-colors",
          statusBasedAttributes.backgroundClassName,
          className
        )}
        onClick={onClick as InteractiveContainerProps["onClick"]}
        {...listeners}
        {...attributes}
        {...divProps}
      >
        {isDragging && <div className="bg-dark-200 absolute inset-0 z-20" />}
        <div className="flex items-center gap-1">
          {statusBasedAttributes.icon}
          <span
            className={cx(
              "font-secondary text-[10px] leading-[15px]",
              statusBasedAttributes.textClassName
            )}
          >
            {statusBasedAttributes.statusLabel}
          </span>
        </div>
        <div className="flex flex-col">
          <div
            className={cx(
              "font-secondary break-words text-sm leading-[21px] tracking-[0.14px]",
              isCompleted ? "text-dark-700" : "text-black"
            )}
          >
            {dueDate && (
              <span
                className={cx(
                  "mr-2 font-semibold underline",
                  isCompleted ? "text-grey" : "text-dark-800"
                )}
              >
                {format(dueDate, "hh:mm a", { locale: dateLocale })}
              </span>
            )}
            <span>
              {highlightedText ? (
                <Highlighter
                  autoEscape
                  highlightClassName="text-primary bg-primary-50"
                  highlightTag="span"
                  textToHighlight={shortenedTitle}
                  searchWords={[highlightedText]}
                />
              ) : (
                shortenedTitle
              )}
            </span>
          </div>
        </div>
        {labelField && (
          <div className="mt-0.5 flex w-max max-w-full items-center gap-1.5 truncate rounded-md bg-white/70 py-0.5 px-2">
            <div
              className="h-1.5 w-1.5 shrink-0 rounded-full"
              style={{ backgroundColor: labelField.textColor }}
            />
            <span className="text-dark-700 leading-2 truncate text-xs">
              {labelField.text}
            </span>
          </div>
        )}
      </InteractiveContainer>
    </>
  );
};
