import { HookOutOfContextError } from "@jugl-web/utils";
import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { downloadBlobAsFile } from "../../drive";
import { useDownloadAttachment } from "../../drive/hooks";

interface DownloadManagerFile {
  id: string;
  fileSize: number;
  fileName: string;
  mimeType: string;
  entityId: string;
  module: "drive" | "attachments";
  status: "in-progress" | "completed" | "error";
  progress: number;
  file?: File;
  progressText?: string;
  localUrl?: string;
}

type DownloadParams = Pick<
  DownloadManagerFile,
  "entityId" | "fileSize" | "mimeType" | "module" | "fileName"
> & { path: string };

interface DownloadManagerContextValue {
  files: { [key: string]: DownloadManagerFile };
  download: (id: string, file: DownloadParams) => Promise<File>;
  open: (id: string) => void;
  cancel: (id: string) => void;
}

const DownloadManagerContext = createContext<DownloadManagerContextValue>(
  null as unknown as DownloadManagerContextValue
);

export const DownloadManagerProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { download: downloadAttachment } = useDownloadAttachment();

  const [files, setFiles] = useState<DownloadManagerContextValue["files"]>({});

  const open = useCallback(
    (id: string) => {
      const file = files[id];
      const fileObject = files[id]?.file;
      if (!fileObject) {
        return;
      }
      downloadBlobAsFile(fileObject, file.fileName);
    },
    [files]
  );

  const cancel = useCallback((id: string) => {}, []);

  const download = useCallback(
    async (id: string, params: DownloadParams) => {
      const file: DownloadManagerFile = {
        ...params,
        id,
        status: "in-progress",
        progress: 0,
      };
      setFiles((prev) => ({ ...prev, [id]: file }));
      const downloadedFile = await downloadAttachment({
        path: params.path,
        fileName: params.fileName,
        fileSize: params.fileSize,
        mimeType: params.mimeType,
        onProgress: (bytesSent, bytesTotal) => {
          setFiles((prev) => {
            const currentFile = prev[id];
            if (!currentFile) {
              return prev;
            }
            currentFile.progress = Math.round((bytesSent / bytesTotal) * 100);
            return { ...prev, [id]: { ...currentFile } };
          });
        },
      });
      setFiles((prev) => ({
        ...prev,
        [id]: {
          ...prev[id],
          file: downloadedFile,
          localUrl: URL.createObjectURL(downloadedFile),
          status: "completed",
        },
      }));
      return downloadedFile;
    },
    [downloadAttachment]
  );

  const value: DownloadManagerContextValue = useMemo(
    () => ({ files, download, open, cancel }),
    [download, open, files, cancel]
  );
  return (
    <DownloadManagerContext.Provider value={value}>
      {children}
    </DownloadManagerContext.Provider>
  );
};

export const useDownloadManagerProvider = () => {
  const context = useContext(DownloadManagerContext);

  if (!context) {
    throw new HookOutOfContextError(
      "useDownloadManagerProvider",
      "DownloadManagerContext"
    );
  }

  return context;
};
