import { BottomCenteredDrawer } from "@jugl-web/ui-components/web/BottomCenteredDrawer";
import { useTranslations } from "@jugl-web/utils";
import { useScreenTransitionManager } from "@jugl-web/utils/utils/ScreenTransitionManager";
import { FC } from "react";
import { useTaskFields } from "../../../hooks/useTaskFields";
import { CustomFieldsSection } from "../CustomFieldsSection";
import { ReactComponent as LabelFieldIcon } from "../icons/label-field.svg";
import { ReactComponent as StatusFieldIcon } from "../icons/status-field.svg";
import { TaskFieldBox } from "../TaskFieldBox";
import { ManageTaskFieldsDialogScreenToParametersMap } from "../types";
import { ErrorScreen } from "./ErrorScreen";
import { LoadingScreen } from "./LoadingScreen";
import { useTaskDefaultStatuses } from "../useTaskDefaultStatuses";

interface EntryScreenProps {
  entityId: string;
}

export const EntryScreen: FC<EntryScreenProps> = ({ entityId }) => {
  const { t } = useTranslations();
  const defaultStatuses = useTaskDefaultStatuses();

  const { labels, customStatuses, isLoading, isError } = useTaskFields({
    entityId,
  });

  const { transitionTo } =
    useScreenTransitionManager<ManageTaskFieldsDialogScreenToParametersMap>();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError) {
    return <ErrorScreen entityId={entityId} />;
  }

  return (
    <BottomCenteredDrawer.Content className="animate-fade-in p-10">
      <div className="mb-2.5 px-2">
        <span className="text-dark-800 text-sm font-medium leading-[21px]">
          {t({
            id: "tasks-page.task-default-fields",
            defaultMessage: "Default fields",
          })}
        </span>
      </div>
      <div className="mb-10 flex flex-col gap-6">
        <TaskFieldBox
          isDefault
          title={t({
            id: "tasks-page.task-label-field",
            defaultMessage: "Label",
          })}
          subtitle={{
            icon: <LabelFieldIcon />,
            label: t({
              id: "tasks-page.task-default-dropdown-field",
              defaultMessage: "Default dropdown field",
            }),
            valuesCount: labels.length,
          }}
          onClick={() => transitionTo({ name: "labels" })}
        />
        <TaskFieldBox
          isDefault
          title={t({
            id: "tasks-page.task-status-field",
            defaultMessage: "Status",
          })}
          subtitle={{
            icon: <StatusFieldIcon />,
            label: t({
              id: "tasks-page.task-default-dropdown-field",
              defaultMessage: "Default dropdown field",
            }),
            valuesCount: customStatuses.length,
            defaultValuesCount: defaultStatuses.length,
          }}
          onClick={() => transitionTo({ name: "statuses" })}
        />
      </div>
      <CustomFieldsSection entityId={entityId} />
    </BottomCenteredDrawer.Content>
  );
};
