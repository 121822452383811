import { useTabIsActive } from "@jugl-web/utils";
import { useUnreadIndicators } from "@web-src/components/UnreadIndicatorsProvider";
import { useCallback, useEffect, useState } from "react";

export type EntitiesUpdatesPopupState = "open" | "closed" | "dismissed";

interface UseEntitiesUpdatesPopupOptions {
  entityId: string | undefined;
}

export const useEntitiesUpdatesPopup = ({
  entityId,
}: UseEntitiesUpdatesPopupOptions) => {
  const [hasUpdateInAnotherEntity, setHasUpdateInAnotherEntity] =
    useState(false);

  const [entitiesUpdatesPopupState, setEntitiesUpdatesPopupState] =
    useState<EntitiesUpdatesPopupState>("closed");

  const [lastUpdatedEntityId, setLastUpdatedEntityId] = useState<
    string | undefined
  >();

  const isTabActive = useTabIsActive();

  const { anotherEntityUpdateEvent$ } = useUnreadIndicators();

  const onCloseEntitiesUpdatesPopup = useCallback((preventDismiss = false) => {
    setEntitiesUpdatesPopupState(preventDismiss ? "closed" : "dismissed");
  }, []);

  const cancelDismissedState = useCallback(() => {
    setEntitiesUpdatesPopupState((prevEntitiesUpdatesPopupState) => {
      if (prevEntitiesUpdatesPopupState === "dismissed") {
        return "closed";
      }

      return prevEntitiesUpdatesPopupState;
    });
  }, []);

  useEffect(() => {
    const anotherEntityUpdateEventSubscription =
      anotherEntityUpdateEvent$.subscribe(({ updatedEntityId }) => {
        setHasUpdateInAnotherEntity(true);

        if (entitiesUpdatesPopupState === "closed") {
          setEntitiesUpdatesPopupState("open");
          setLastUpdatedEntityId(updatedEntityId);
        }
      });

    return () => {
      anotherEntityUpdateEventSubscription.unsubscribe();
    };
  }, [anotherEntityUpdateEvent$, entitiesUpdatesPopupState]);

  useEffect(() => {
    cancelDismissedState();
    setHasUpdateInAnotherEntity(false);
  }, [entityId, cancelDismissedState]);

  useEffect(() => {
    if (!isTabActive) {
      cancelDismissedState();
    }
  }, [isTabActive, cancelDismissedState]);

  return {
    isEntitiesUpdatesPopupOpen: entitiesUpdatesPopupState === "open",
    hasUpdateInAnotherEntity,
    lastUpdatedEntityId,
    onCloseEntitiesUpdatesPopup,
  };
};
