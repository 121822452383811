import { UserCPanelModel, UserLoginActivity } from "@jugl-web/rest-api";
import { SidebarDrawer } from "@jugl-web/ui-components/web";
import { useTranslations } from "@jugl-web/utils";
import { usersApi } from "@web-src/features/api/createApi";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { Button, LoadingSpinner } from "@jugl-web/ui-components/cross-platform";
import React, { useCallback, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { CpanelLastActivityElement } from "./components/CpanelLastActivityElement";

const UserActivitySideBarContent: React.FC<{
  user?: UserCPanelModel;
}> = ({ user }) => {
  const { t } = useTranslations();
  const { entity } = useEntity();
  const [page, setPage] = useState(1);
  const [hasMoreActivities, setHasMoreActivities] = useState(true);
  const [activities, setActivities] = useState<UserLoginActivity[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [fetchActivities, { isLoading, isError }] =
    usersApi.useLazyGetUserLoginHistoryQuery();

  const handleFetchActivities = useCallback(async () => {
    if (!entity || !user?.id || !hasMoreActivities || isFetching) return;
    setIsFetching(true);
    const { data: response } = await fetchActivities({
      entityId: entity.id,
      userId: user.id,
      params: {
        page,
      },
    });
    if (response && "data" in response) {
      setActivities((prev) => [...prev, ...response.data]);
      if (page < response.total_pages) {
        setPage((prev) => prev + 1);
      } else {
        setHasMoreActivities(false);
      }
    }
    setIsFetching(false);
  }, [page, fetchActivities, entity, user, hasMoreActivities, isFetching]);

  const { ref: inViewRef, inView } = useInView();

  useEffect(() => {
    if (inView) {
      handleFetchActivities();
    }
  }, [inView, handleFetchActivities]);

  return isLoading ? (
    <div className="mt-8 flex justify-center">
      <LoadingSpinner />
    </div>
  ) : isError ? (
    <div className="text-tertiary-400 flex flex-col items-center gap-4 text-lg">
      {t({
        id: "cpanel-page.something-went-wrong",
        defaultMessage: "Something went wrong!",
      })}
      <Button onClick={handleFetchActivities}>
        {t({
          id: "cpanel-page.click-to-try-again",
          defaultMessage: "Click to try again",
        })}
      </Button>
    </div>
  ) : activities ? (
    <>
      <div className="flex flex-col gap-4">
        {activities.map((loginActivity, idx) => (
          <CpanelLastActivityElement activity={loginActivity} key={+idx} />
        ))}
      </div>
      {isFetching ? (
        <div className="mt-8 flex justify-center">
          <LoadingSpinner />
        </div>
      ) : hasMoreActivities ? (
        <div ref={inViewRef} />
      ) : null}
    </>
  ) : null;
};

export const UserActivitySideBar: React.FC<{
  isOpen: boolean;
  user?: UserCPanelModel;
  onRequestClose: () => void;
}> = ({ isOpen, user, onRequestClose }) => {
  const { t } = useTranslations();
  return (
    <SidebarDrawer
      isOpen={isOpen}
      onClose={onRequestClose}
      hasBackdrop
      title={`${user?.display_name}: ${t({
        id: "cpanel-page.login-history",
        defaultMessage: "Login history",
      })}`}
    >
      <SidebarDrawer.Content className="p-8">
        <UserActivitySideBarContent user={user} />
      </SidebarDrawer.Content>
    </SidebarDrawer>
  );
};
