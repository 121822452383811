import { filterSetNameValidator } from "@jugl-web/domain-resources/tasks";
import { useTaskFiltering } from "@jugl-web/domain-resources/tasks/components/TaskFilteringProvider";
import { useTaskFields } from "@jugl-web/domain-resources/tasks/hooks/useTaskFields";
import { useTaskFiltersStateContext } from "@jugl-web/domain-resources/tasks/hooks/useTaskFiltersState";
import { BottomCenteredDrawer } from "@jugl-web/ui-components/web/BottomCenteredDrawer";
import { useAppVariant, useToast, useTranslations } from "@jugl-web/utils";
import { FC, useState } from "react";
import { FilterSetForm } from "../../../FilterSetForm";

interface CreateFilterSetScreenProps {
  entityId: string;
  onDialogClose: () => void;
}

export const CreateFilterSetScreen: FC<CreateFilterSetScreenProps> = ({
  entityId,
  onDialogClose,
}) => {
  const [name, setName] = useState("");
  const isNameValid = filterSetNameValidator(name);

  const { filtersState } = useTaskFiltersStateContext();

  const { createFilterSet, isCreatingFilterSet } = useTaskFields({ entityId });
  const { changeFiltering } = useTaskFiltering();

  const { t } = useTranslations();
  const { variant } = useAppVariant();
  const { toast } = useToast({ variant });

  const handleSubmit = async () => {
    try {
      const createdFilterSet = await createFilterSet(name, filtersState);

      toast(
        <span>
          {t(
            {
              id: "feedback.filter-set-created",
              defaultMessage: "Filter set {name} has been created",
            },
            { name: <b>{createdFilterSet.name}</b> }
          )}
        </span>,
        { variant: "success" }
      );

      changeFiltering({ type: "filter-set", filterSetId: createdFilterSet.id });
      onDialogClose();
    } catch {
      // Do nothing
    }
  };

  return (
    <BottomCenteredDrawer.Content className="flex items-center justify-center py-6">
      <FilterSetForm className="gap-14">
        <FilterSetForm.Header type="create" />
        <FilterSetForm.Input
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <FilterSetForm.SubmitButton
          isDisabled={isCreatingFilterSet || !isNameValid}
          onClick={handleSubmit}
        >
          {t({ id: "common.save", defaultMessage: "Save" })}
        </FilterSetForm.SubmitButton>
      </FilterSetForm>
    </BottomCenteredDrawer.Content>
  );
};
