import { useAppVariant, useTranslations } from "@jugl-web/utils";
import { forwardRef } from "react";
import { ReactComponent as BackArrowIcon } from "../../assets/back-arrow.svg";
import { ReactComponent as CloseIcon } from "../../assets/close.svg";
import { ReactComponent as DownloadIconMobile } from "./assets/download-mobile.svg";
import { ReactComponent as DownloadIconWeb } from "./assets/download-web.svg";
import { TopPanelButton } from "../TopPanelButton";

interface TopPanelProps {
  fileName: string;
  fileAuthorName?: string;
  onDownload?: () => void;
  onClose: () => void;
}

export const TopPanel = forwardRef<HTMLDivElement, TopPanelProps>(
  ({ fileAuthorName, fileName, onDownload, onClose }, ref) => {
    const { t } = useTranslations();
    const { isMobile } = useAppVariant();

    if (isMobile) {
      return (
        <div
          ref={ref}
          className="flex h-[50px] shrink-0 items-center justify-between gap-4 bg-[#121516] px-3"
          style={{ boxShadow: "2px -6px 24px 0px rgba(18, 22, 34, 0.13)" }}
        >
          <div className="flex items-center gap-4 overflow-hidden">
            <TopPanelButton
              isGhost
              icon={<BackArrowIcon />}
              onClick={onClose}
            />
            <div className="flex flex-col">
              <div
                className="font-secondary w-[200px] truncate text-base font-semibold text-white"
                title={fileName}
              >
                {fileName}
              </div>
              {fileAuthorName && (
                <span className="text-dark-200 truncate text-[13px]">
                  {fileAuthorName}
                </span>
              )}
            </div>
          </div>
          {onDownload && (
            <TopPanelButton
              isGhost
              icon={<DownloadIconMobile />}
              onClick={onDownload}
            />
          )}
        </div>
      );
    }

    return (
      <div
        ref={ref}
        className="flex h-[120px] shrink-0 items-center justify-between px-20"
        style={{
          background:
            "linear-gradient(180deg, rgba(22, 22, 23, 0.88) 0%, rgba(22, 22, 23, 0.00) 100%)",
        }}
      >
        <div className="flex flex-col">
          <span
            className="font-secondary max-w-md truncate text-lg font-semibold text-white"
            title={fileName}
          >
            {fileName}
          </span>
          {fileAuthorName && (
            <span className="text-dark-200 truncate">{fileAuthorName}</span>
          )}
        </div>
        <div className="flex items-center gap-10">
          {onDownload && (
            <TopPanelButton
              icon={<DownloadIconWeb />}
              hint={t({ id: "common.download", defaultMessage: "Download" })}
              onClick={onDownload}
            />
          )}
          <TopPanelButton
            icon={<CloseIcon />}
            hint={t({ id: "common.close", defaultMessage: "Close" })}
            onClick={onClose}
          />
        </div>
      </div>
    );
  }
);
