import { Customer } from "@jugl-web/rest-api";
import {
  InternalTaskFilters,
  PreviewTask,
  TaskDefaultStatus,
  TaskPriority,
} from "@jugl-web/rest-api/tasks";
import { useTranslations } from "@jugl-web/utils";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import addDays from "date-fns/addDays";
import endOfDay from "date-fns/endOfDay";
import format from "date-fns/format";
import startOfDay from "date-fns/startOfDay";
import range from "lodash/range";
import { useMemo } from "react";
import { getCustomerDetails } from "../../customers/utils";
import { HeadlessUserItem } from "../../users/hooks/useHeadlessUsersList";
import { TaskSorting } from "../components/TaskSortingProvider";
import { TASK_COLUMNS_IDS } from "../consts";
import { TaskColumnDroppableData, TaskListMode } from "../types";
import { useTaskFields } from "./useTaskFields";
import { TaskFormState } from "./useTaskFormState";
import { useTaskPriorities } from "./useTaskPriorities";
import { useTaskStatuses } from "./useTaskStatuses";
import { useTasks } from "./useTasks";
import { TaskView, TaskViewType } from "./useTasksViews";

export type CreateTaskOptions = {
  fields: {
    assignee?: boolean;
    dueDate?: boolean;
  };
  initialState: Partial<TaskFormState>;
};

export type TaskViewColumnTitle =
  | { type: "text"; value: string }
  | { type: "user"; userId: string }
  | {
      type: "customer";
      name: string;
      company: string | undefined;
      avatar: string | null;
    };

export type TableColorSet = "primary" | "secondary" | "tertiary" | "grey";

export type TaskViewColumn = {
  id: string;
  title: TaskViewColumnTitle;
  skipOnSearch?: boolean;
  skipIfNoItems?: boolean;
  items: PreviewTask[];
  tableView: {
    tableColorSet: TableColorSet;
  };
  isOverdue?: boolean;
  droppableData?: TaskColumnDroppableData;
  createTaskOptions?: CreateTaskOptions;
};

export type UseTasksViewColumnsOptions = {
  entityId: string;
  meId: string;
  mode: TaskListMode;
  users: HeadlessUserItem[];
  customers: Customer[];
  view: TaskView;
  searchQuery: string;
  filters: InternalTaskFilters;
  sorting: TaskSorting;
};

export const useTasksViewColumns = ({
  entityId,
  meId,
  mode,
  users,
  customers,
  view,
  searchQuery,
  filters,
  sorting,
}: UseTasksViewColumnsOptions): TaskViewColumn[] => {
  const {
    tasks,
    selectNoDueDateTasks,
    selectOverdueTasks,
    selectTasksByDueDateInterval,
    selectTasksByLabelId,
    selectTasksByStatus,
    selectTasksByPriority,
    selectTasksByCustomDropdownFieldValue,
    selectTasksByReporteeId,
    selectTasksByAssigneeId,
    selectTasksByCustomerId,
  } = useTasks({
    entityId,
    mode,
    searchQuery,
    filters,
    forceShowCompleted: view.type === TaskViewType.status,
    sorting,
  });

  const { labels, customFields } = useTaskFields({ entityId });
  const { allStatuses } = useTaskStatuses({ entityId });
  const { allPriorities } = useTaskPriorities();
  const { t } = useTranslations();
  const { dateLocale } = useLanguage();

  const columns: TaskViewColumn[] = useMemo(() => {
    let result: TaskViewColumn[] = [];

    switch (view.type) {
      case TaskViewType.default:
        result = [
          {
            id: TASK_COLUMNS_IDS.DEFAULT_VIEW_LAST_UPDATED,
            title: {
              type: "text",
              value: t({
                id: "tasks-page.last-updated",
                defaultMessage: "Last Updated",
              }),
            },
            items: [...tasks].sort((a, b) =>
              a.updated_at > b.updated_at ? -1 : 1
            ),
            tableView: {
              tableColorSet: "primary",
            },
            skipOnSearch: true,
          },
          {
            id: TASK_COLUMNS_IDS.DEFAULT_VIEW_ALL_TASKS,
            title: {
              type: "text",
              value: t({
                id: "tasks-page.all-tasks",
                defaultMessage: "All Tasks",
              }),
            },
            items: tasks,
            tableView: {
              tableColorSet: "primary",
            },
          },
          {
            id: TASK_COLUMNS_IDS.DEFAULT_VIEW_OVERDUE,
            title: {
              type: "text",
              value: t({
                id: "tasks-page.overdue",
                defaultMessage: "Overdue",
              }),
            },
            isOverdue: true,
            items: selectOverdueTasks(),
            tableView: {
              tableColorSet: "tertiary",
            },
            skipOnSearch: true,
          },
          {
            id: TASK_COLUMNS_IDS.DEFAULT_VIEW_TODAY,
            title: {
              type: "text",
              value: t({
                id: "tasks-page.today",
                defaultMessage: "Today",
              }),
            },
            items: selectTasksByDueDateInterval(
              startOfDay(new Date()),
              endOfDay(new Date())
            ),
            tableView: {
              tableColorSet: "secondary",
            },
            droppableData: {
              type: "dueDate",
              value: endOfDay(new Date()).toISOString(),
            },
            createTaskOptions: {
              fields: {
                assignee: true,
              },
              initialState: {
                dueDate: endOfDay(new Date()),
              },
            },
          },
        ];
        break;

      case TaskViewType.templates:
        result = [];
        break;

      case TaskViewType.labels: {
        const unassignedColumn: TaskViewColumn = {
          id: TASK_COLUMNS_IDS.LABELS_VIEW_WITHOUT_LABEL,
          title: {
            type: "text",
            value: t({
              id: "tasks-page.without-label",
              defaultMessage: "Without Label",
            }),
          },
          items: selectTasksByLabelId(null),
          tableView: {
            tableColorSet: "grey",
          },
          droppableData: {
            type: "label",
            value: null,
          },
        };

        result = [
          ...(!filters.labels?.length ? [unassignedColumn] : []),
          ...(labels || []).reduce<TaskViewColumn[]>((acc, label) => {
            const isFilteredOut = !!(
              filters.labels.length > 0 && !filters.labels.includes(label.id)
            );

            if (isFilteredOut) {
              return acc;
            }

            const tasksMarkedByLabel = selectTasksByLabelId(label.id);

            return [
              ...acc,
              {
                id: label.id,
                title: {
                  type: "text",
                  value: label.text,
                },
                items: tasksMarkedByLabel,
                tableView: {
                  tableColorSet: "primary",
                },
                createTaskOptions: {
                  fields: {
                    assignee: true,
                    dueDate: true,
                  },
                  initialState: {
                    labelId: label.id,
                  },
                },
                droppableData: {
                  type: "label",
                  value: label.id,
                },
              },
            ];
          }, []),
        ];
        break;
      }

      case TaskViewType.status: {
        const tableColorSetByStatus: Record<string, TableColorSet> = {
          [TaskDefaultStatus.completed]: "secondary",
          [TaskDefaultStatus.notStarted]: "grey",
        };

        result = [
          ...allStatuses.map<TaskViewColumn>((statusDetails) => ({
            id: TASK_COLUMNS_IDS.STATUS_VIEW_STATUS_ID(statusDetails.id),
            title: { type: "text", value: statusDetails.label },
            createTaskOptions:
              statusDetails.id !== "completed"
                ? {
                    fields: {
                      assignee: true,
                      dueDate: true,
                    },
                    initialState: {
                      statusId: statusDetails.id,
                    },
                  }
                : undefined,
            items: selectTasksByStatus(statusDetails.id),
            tableView: {
              tableColorSet:
                tableColorSetByStatus[statusDetails.id] || "primary",
            },
            droppableData: {
              type: "status",
              value: statusDetails.id,
            },
          })),
        ];
        break;
      }

      case TaskViewType.priority: {
        result = allPriorities.map(
          (item) =>
            ({
              id: TASK_COLUMNS_IDS.PRIORITY_VIEW_PRIORITY(item.id),
              title: {
                type: "text",
                value: item.shortLabel,
              },
              createTaskOptions:
                TaskPriority.none !== item.id
                  ? {
                      fields: {
                        assignee: true,
                        dueDate: true,
                      },
                      initialState: {
                        priority: item.id,
                      },
                    }
                  : undefined,
              items: selectTasksByPriority(item.id),
              tableView: {
                tableColorSet:
                  item.id === TaskPriority.none ? "grey" : "primary",
              },
              droppableData: {
                type: "priority",
                value: item.id,
              },
            } as TaskViewColumn)
        );
        break;
      }

      case TaskViewType.date: {
        result = [
          {
            id: TASK_COLUMNS_IDS.DATE_VIEW_OVERDUE,
            title: {
              type: "text",
              value: t({
                id: "tasks-page.overdue",
                defaultMessage: "Overdue",
              }),
            },
            skipIfNoItems: true,
            items: selectOverdueTasks(),
            tableView: {
              tableColorSet: "tertiary",
            },
            isOverdue: true,
          },
          {
            id: TASK_COLUMNS_IDS.DATE_VIEW_WITHOUT_DUE_DATE,
            title: {
              type: "text",
              value: t({
                id: "tasks-page.without-due-date",
                defaultMessage: "Without a due date",
              }),
            },
            items: selectNoDueDateTasks(),
            tableView: {
              tableColorSet: "grey",
            },
            createTaskOptions: {
              fields: {
                assignee: true,
              },
              initialState: {
                dueDate: null,
              },
            },
            skipIfNoItems: true,
            droppableData: {
              type: "dueDate",
              value: null,
            },
          },
          ...range(7).map((_, idx) => {
            const dateFrom = startOfDay(addDays(new Date(), idx));
            const dateTo = endOfDay(addDays(new Date(), idx));

            let title = `${format(dateFrom || new Date(), "MMM", {
              locale: dateLocale,
            })} ${format(dateFrom || new Date(), "d", { locale: dateLocale })}`;

            const isToday = idx === 0;
            const isTomorrow = idx === 1;

            if (isToday) {
              title = t({
                id: "tasks-page.today",
                defaultMessage: "Today",
              });
            } else if (isTomorrow) {
              title = t({
                id: "tasks-page.tomorrow",
                defaultMessage: "Tomorrow",
              });
            }

            return {
              id: TASK_COLUMNS_IDS.DATE_VIEW(dateTo),
              title: {
                type: "text",
                value: title,
              },
              items: selectTasksByDueDateInterval(dateFrom, dateTo),
              tableView: {
                tableColorSet: isToday ? "secondary" : "primary",
              },
              createTaskOptions: {
                fields: {
                  assignee: true,
                },
                initialState: {
                  dueDate: dateTo,
                },
              },
              droppableData: {
                type: "dueDate",
                value: dateTo.toISOString(),
              },
            } as TaskViewColumn;
          }),
        ];
        break;
      }

      case TaskViewType.assignee: {
        const isWithoutAssigneeColumnFilteredOut =
          filters.assignees.length > 0 && !filters.assignees.includes(null);

        if (!isWithoutAssigneeColumnFilteredOut) {
          result.push({
            id: TASK_COLUMNS_IDS.ASSIGNEE_VIEW_WITHOUT_ASSIGNEE,
            title: {
              type: "text",
              value: t({
                id: "tasks-page.without-assignee",
                defaultMessage: "Without Assignee",
              }),
            },
            items: selectTasksByAssigneeId(null),
            tableView: {
              tableColorSet: "grey",
            },
            droppableData: {
              type: "assignee",
              value: TASK_COLUMNS_IDS.ASSIGNEE_VIEW_WITHOUT_ASSIGNEE,
            },
          });
        }

        const isMeFilteredOut =
          filters.assignees.length > 0 && !filters.assignees.includes(meId);

        if (!isMeFilteredOut) {
          result.push({
            id: TASK_COLUMNS_IDS.ASSIGNEE_VIEW_ASSIGNEE_ID(meId),
            title: {
              type: "user",
              userId: meId,
            },
            items: selectTasksByAssigneeId(meId),
            tableView: {
              tableColorSet: "secondary",
            },
            createTaskOptions: {
              fields: {
                dueDate: true,
              },
              initialState: {
                assigneeIds: [meId],
              },
            },
            droppableData: {
              type: "assignee",
              value: meId,
            },
          });
        }

        users.forEach((user) => {
          const isUserMe = user.id === meId;

          if (isUserMe) {
            return;
          }

          const isUserFilteredOut =
            filters.assignees.length > 0 &&
            !filters.assignees.includes(user.id);

          if (isUserFilteredOut) {
            return;
          }

          result.push({
            id: TASK_COLUMNS_IDS.ASSIGNEE_VIEW_ASSIGNEE_ID(user.id),
            title: {
              type: "user",
              userId: user.id,
            },
            items: selectTasksByAssigneeId(user.id),
            tableView: {
              tableColorSet: "primary",
            },
            createTaskOptions: {
              fields: {
                dueDate: true,
              },
              initialState: {
                assigneeIds: [user.id],
              },
            },
            droppableData: {
              type: "assignee",
              value: user.id,
            },
          });
        });
        break;
      }

      case TaskViewType.reportee: {
        const isManagerColumnFilteredOut =
          filters.assignees.length > 0 &&
          !filters.assignees.includes(null) &&
          !filters.assignees.includes(meId);

        if (!isManagerColumnFilteredOut) {
          result.push({
            id: TASK_COLUMNS_IDS.REPORTEE_VIEW_REPORTEE_ID(meId),
            title: {
              type: "user",
              userId: meId,
            },
            items: selectTasksByReporteeId(meId),
            tableView: {
              tableColorSet: "secondary",
            },
            createTaskOptions: {
              fields: {
                dueDate: true,
              },
              initialState: {
                assigneeIds: [meId],
              },
            },
            droppableData: {
              type: "assignee",
              value: meId,
            },
          });
        }

        users.forEach((user) => {
          const isUserMe = user.id === meId;

          if (isUserMe) {
            return;
          }

          const isUserFilteredOut =
            filters.assignees.length > 0 &&
            !filters.assignees.includes(user.id);

          if (isUserFilteredOut) {
            return;
          }

          result.push({
            id: TASK_COLUMNS_IDS.REPORTEE_VIEW_REPORTEE_ID(user.id),
            title: {
              type: "user",
              userId: user.id,
            },
            items: selectTasksByReporteeId(user.id),
            tableView: {
              tableColorSet: "primary",
            },
            createTaskOptions: {
              fields: {
                dueDate: true,
              },
              initialState: {
                assigneeIds: [user.id],
              },
            },
            droppableData: {
              type: "assignee",
              value: user.id,
            },
          });
        });
        break;
      }

      case TaskViewType.customer: {
        if (!filters.customers.length || filters.customers.includes(null)) {
          result.push({
            id: TASK_COLUMNS_IDS.CUSTOMER_VIEW_WITHOUT_CUSTOMER,
            title: {
              type: "text",
              value: t({
                id: "tasks-page.without-customer",
                defaultMessage: "Without Customer",
              }),
            },
            items: selectTasksByCustomerId(null),
            tableView: {
              tableColorSet: "grey",
            },
            droppableData: {
              type: "customer",
              value: TASK_COLUMNS_IDS.CUSTOMER_VIEW_WITHOUT_CUSTOMER,
            },
          });
        }

        customers.forEach((customer) => {
          const customerDetails = getCustomerDetails(customer);

          const isCustomerFilteredOut =
            filters.customers.length > 0 &&
            !filters.customers.includes(customer.id);

          if (isCustomerFilteredOut) {
            return;
          }

          result.push({
            id: TASK_COLUMNS_IDS.CUSTOMER_VIEW_CUSTOMER_ID(customer.id),
            title: {
              type: "customer",
              name: customerDetails.fullName,
              company: customerDetails.companyName,
              avatar: customerDetails.avatarUrl,
            },
            items: selectTasksByCustomerId(customer.id),
            tableView: {
              tableColorSet: "primary",
            },
            droppableData: {
              type: "customer",
              value: customer.id,
            },
            createTaskOptions: {
              fields: {
                assignee: true,
                dueDate: true,
              },
              initialState: {
                customer,
              },
            },
          });
        });
        break;
      }

      case TaskViewType.customDropdownField: {
        const customField = customFields.find(
          (item) => item.id === view.customDropdownFieldId
        );

        if (customField) {
          result = [
            {
              id: TASK_COLUMNS_IDS.CUSTOM_DROPDOWN_FIELD_VIEW_NOT_ASSIGNED(
                view.customDropdownFieldId
              ),
              title: {
                type: "text",
                value: t({
                  id: "tasks-page.not-assigned",
                  defaultMessage: "Not Assigned",
                }),
              },
              skipIfNoItems: true,
              items: selectTasksByCustomDropdownFieldValue(
                view.customDropdownFieldId,
                null,
                customField
              ),
              tableView: {
                tableColorSet: "grey",
              },
              droppableData: {
                type: "customField",
                value: {
                  id: view.customDropdownFieldId,
                  value: null,
                },
              },
            },
            ...(customField?.values?.map(
              (item) =>
                ({
                  id: TASK_COLUMNS_IDS.CUSTOM_DROPDOWN_FIELD_VIEW_VALUE_ID(
                    view.customDropdownFieldId,
                    item.id
                  ),
                  title: {
                    type: "text",
                    value: item.value,
                  },
                  createTaskOptions: {
                    customField: true,
                    fields: {
                      assignee: true,
                      dueDate: true,
                    },
                    initialState: {
                      customFields: { [customField.id]: item.id },
                    },
                  },
                  items: selectTasksByCustomDropdownFieldValue(
                    view.customDropdownFieldId,
                    item.id,
                    customField
                  ),
                  tableView: {
                    tableColorSet: "primary",
                  },
                  droppableData: {
                    type: "customField",
                    value: {
                      id: view.customDropdownFieldId,
                      value: item.id,
                    },
                  },
                } as TaskViewColumn)
            ) || []),
          ];
        }
        break;
      }

      default:
        break;
    }

    if (searchQuery) {
      result = result.filter(
        (item) => item.items.length > 0 && !item.skipOnSearch
      );
    }

    result = result.filter((item) =>
      item.skipIfNoItems ? item.items.length > 0 : true
    );

    return result;
  }, [
    allPriorities,
    allStatuses,
    customFields,
    customers,
    dateLocale,
    filters.assignees,
    filters.customers,
    filters.labels,
    labels,
    meId,
    searchQuery,
    selectNoDueDateTasks,
    selectOverdueTasks,
    selectTasksByAssigneeId,
    selectTasksByCustomDropdownFieldValue,
    selectTasksByCustomerId,
    selectTasksByDueDateInterval,
    selectTasksByLabelId,
    selectTasksByPriority,
    selectTasksByReporteeId,
    selectTasksByStatus,
    t,
    tasks,
    users,
    view.customDropdownFieldId,
    view.type,
  ]);

  return columns;
};
