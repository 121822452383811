import { useTaskFields } from "@jugl-web/domain-resources/tasks/hooks/useTaskFields";
import { FC, useMemo } from "react";
import { AssigneeFilterSection } from "./components/AssigneeFilterSection";
import { CustomFieldFilterSection } from "./components/CustomFieldFilterSection";
import { CustomerFilterSection } from "./components/CustomerFilterSection";
import { LabelFilterSection } from "./components/LabelFilterSection";
import { PriorityFilterSection } from "./components/PriorityFilterSection";
import { StatusFilterSection } from "./components/StatusFilterSection";
import { TypeFilterSection } from "./components/TypeFilterSection";

interface TaskFilterSectionsProps {
  entityId: string;
}

export const TaskFilterSections: FC<TaskFilterSectionsProps> = ({
  entityId,
}) => {
  const { customFields } = useTaskFields({ entityId });

  const dropdownCustomFields = useMemo(
    () => customFields.filter((customField) => customField.type === "dropdown"),
    [customFields]
  );

  return (
    <div className="flex flex-col">
      {[
        TypeFilterSection,
        LabelFilterSection,
        PriorityFilterSection,
        StatusFilterSection,
        AssigneeFilterSection,
        CustomerFilterSection,
      ].map((FilterSectionComponent) => (
        <FilterSectionComponent
          key={FilterSectionComponent.name}
          entityId={entityId}
        />
      ))}
      {dropdownCustomFields.map((customField) => (
        <CustomFieldFilterSection
          key={customField.id}
          customField={customField}
          entityId={entityId}
        />
      ))}
    </div>
  );
};
