import { cx } from "@jugl-web/utils";
import React, { useState, useEffect, useRef, PropsWithChildren } from "react";
import { ReactComponent as ChevronSvg } from "./assets/chevron.svg";

export type HorizontalScrollableContainerProps = PropsWithChildren<{
  className?: string;
  wrapperClassName?: string;
  hiddenScrollBar?: boolean;
}>;

export const HorizontalScrollableContainer: React.FC<
  HorizontalScrollableContainerProps
> = ({ children, wrapperClassName, className, hiddenScrollBar }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showLeftChevron, setShowLeftChevron] = useState(false);
  const [showRightChevron, setShowRightChevron] = useState(false);
  const [isHoveringLeft, setHoveringLeft] = useState(false);
  const [isHoveringRight, setHoveringRight] = useState(false);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return () => null;

    const handleScroll = () => {
      if (container.scrollLeft > 0) {
        setShowLeftChevron(true);
      } else {
        setShowLeftChevron(false);
        setHoveringLeft(false);
      }

      if (
        container.scrollLeft <
        container.scrollWidth - container.clientWidth
      ) {
        setShowRightChevron(true);
      } else {
        setShowRightChevron(false);
        setHoveringRight(false);
      }
    };

    container.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial check
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    if (!container || (!isHoveringRight && !isHoveringLeft)) return () => null;

    const scrollWhileHovering = () => {
      const scrollAmount = 5; // Adjust as needed

      if (isHoveringLeft) {
        container.scrollLeft -= scrollAmount;
      } else if (isHoveringRight) {
        container.scrollLeft += scrollAmount;
      }
    };

    const intervalId = setInterval(() => {
      requestAnimationFrame(scrollWhileHovering);
    }, 10);

    return () => {
      clearInterval(intervalId);
    };
  }, [
    isHoveringLeft,
    isHoveringRight,
    setShowLeftChevron,
    setShowRightChevron,
  ]);

  return (
    <div className={cx("relative", wrapperClassName)}>
      <div
        className={cx(
          "overflow-x-auto",
          hiddenScrollBar ?? "jugl__hidden-scrollbar",
          className
        )}
        ref={containerRef}
      >
        {children}
      </div>
      {showLeftChevron && (
        <div
          className={cx(
            "absolute left-0 top-0 cursor-pointer",
            "flex h-full w-[7%] items-center justify-center bg-gradient-to-r from-[#00000033] to-transparent",
            { hidden: !showLeftChevron }
          )}
          onMouseEnter={() => setHoveringLeft(true)}
          onMouseLeave={() => setHoveringLeft(false)}
        >
          <ChevronSvg className="rotate-90" />
        </div>
      )}
      <div
        className={cx(
          "absolute right-0 top-0 cursor-pointer",
          "flex h-full w-[7%] items-center justify-center bg-gradient-to-l from-[#00000033] to-transparent",
          { hidden: !showRightChevron }
        )}
        onMouseEnter={() => setHoveringRight(true)}
        onMouseLeave={() => setHoveringRight(false)}
      >
        <ChevronSvg className="-rotate-90 transform" />
      </div>
    </div>
  );
};
