import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import AgoraRTC, {
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  ILocalVideoTrack,
  IRemoteVideoTrack,
  IRemoteAudioTrack,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  ILocalAudioTrack,
} from "agora-rtc-sdk-ng";
import { usePrevious } from "@web-src/utils/helper";
import isEqual from "lodash/isEqual";
import { useSelector } from "react-redux";
import { selectUserId } from "@web-src/features/auth/authSlice";
import VirtualBackgroundExtension, {
  IVirtualBackgroundProcessor,
} from "agora-extension-virtual-background";
import {
  AIDenoiserExtension,
  AIDenoiserProcessorLevel,
  AIDenoiserProcessorMode,
} from "agora-extension-ai-denoiser";
import { ScenarioCallType } from "@web-src/features/chats/types";
import { useToast, useTranslations } from "@jugl-web/utils";
import { ActiveCallProps } from "../types";
import { useCallsSettings } from "./useCallsSettings";

const virtualBackgroundExtension = new VirtualBackgroundExtension();
const denoiser = new AIDenoiserExtension({
  assetsPath: "",
});
AgoraRTC.registerExtensions([virtualBackgroundExtension, denoiser]);

const processor = virtualBackgroundExtension.createProcessor();
const audioProcessor = denoiser.createProcessor();
processor.init("../assets/agora-wasm.wasm");
processor.enable();
interface RemoteVideoTrack extends IRemoteVideoTrack {
  _player?: {
    _videoElementStatus: "none" | "playing";
  };
  _uintId?: number;
}
interface AgoraRTCRemoteUser extends IAgoraRTCRemoteUser {
  _uintid?: number;
}
type UserTracks = {
  video?: RemoteVideoTrack;
  audio?: IRemoteAudioTrack;
};

type ActiveCallParams = {
  localAudioTrack: IMicrophoneAudioTrack | undefined;
  localVideoTrack: ICameraVideoTrack | undefined;
  localScreenShareTrack: ILocalVideoTrack | undefined;
  remoteUsers: AgoraRTCRemoteUser[] | undefined;
  userTracks: {
    [key: string]: UserTracks;
  };
  toggleScreenShare: () => void;
  toggleVideo: () => void;
  toggleAudio: () => void;
  toggleProcessor: () => void;
  leaveCall: () => void;

  screenSharingEnabled: boolean;
  videoEnabled: boolean;
  audioEnabled: boolean;
  processor: IVirtualBackgroundProcessor;
  handleStopScreenShare: () => void;
  joinConferenceFromStaging: () => void;
  stage: "staging" | "participation";
};

const screenSharingClient = AgoraRTC.createClient({
  mode: "rtc",
  codec: "vp8",
});

export const useActiveCall = (
  client: IAgoraRTCClient,
  rtcProps: ActiveCallProps | undefined
): ActiveCallParams | null => {
  const [localAudioTrack, setLocalAudioTrack] =
    useState<IMicrophoneAudioTrack>();
  const [localVideoTrack, setLocalVideoTrack] = useState<ICameraVideoTrack>();
  const [localScreenShareTrack, setLocalScreenShareTrack] =
    useState<ILocalVideoTrack>();
  const [localScreenShareAudioTrack, setLocalScreenShareAudioTrack] =
    useState<ILocalAudioTrack>();
  const [cameraEnableInProgress, setCameraEnableInProgress] = useState(false);
  const localScreenShareTrackRef = useRef<
    ILocalVideoTrack | [ILocalVideoTrack, ILocalAudioTrack]
  >();
  const [userTracks, setUserTracks] = useState<ActiveCallParams["userTracks"]>(
    {}
  );
  const [remoteUsers, setRemoteUsers] = useState<IAgoraRTCRemoteUser[]>([]);
  const meId = useSelector(selectUserId);
  const { getCallsSettings, setCallsSettings } = useCallsSettings();
  const previousRtcProps = usePrevious(rtcProps);

  const [screenSharingEnabled, setScreenSharingEnabled] =
    useState<boolean>(false);
  const [videoEnabled, setVideoEnabled] = useState<boolean>(true);
  const [audioEnabled, setAudioEnabled] = useState<boolean>(false);
  const [processorEnabled, setProcessorEnabled] = useState<boolean>();
  const [stage, setStage] = useState<"staging" | "participation">("staging");
  const { toast } = useToast({ variant: "web" });
  const { t } = useTranslations();
  const handleSetRemoteUsers = (users: IAgoraRTCRemoteUser[]) => {
    const filteredUsers: IAgoraRTCRemoteUser[] = users.filter((user) => {
      const id = user.uid.toString();
      return !id.includes("staging_");
    });
    setRemoteUsers(filteredUsers);
  };
  const toggleVideo = useCallback(async () => {
    const storedDevicesSettings = getCallsSettings();
    if (!localVideoTrack) {
      if (cameraEnableInProgress) {
        return;
      }
      setCameraEnableInProgress(true);
      try {
        const videoTrack = await AgoraRTC.createCameraVideoTrack({
          optimizationMode: "motion",
        });
        videoTrack.setEncoderConfiguration(
          storedDevicesSettings?.camera_resolution || "480p_1"
        );
        setLocalVideoTrack(videoTrack);

        if (storedDevicesSettings?.camera_id) {
          const devices = await AgoraRTC.getCameras();
          const isDevicePresent = devices.find(
            (camera) => camera.deviceId === storedDevicesSettings?.camera_id
          );
          if (isDevicePresent) {
            videoTrack?.setDevice(storedDevicesSettings?.camera_id);
          }
        }
        await client.publish(videoTrack);
        await videoTrack?.pipe(processor).pipe(videoTrack.processorDestination);
        await client.enableDualStream();
        setVideoEnabled(true);
        setCallsSettings({
          isVideoOn: true,
        });
      } catch (error) {
        if (error instanceof Error) {
          const isPermissionError = error.message.includes("Permission denied");
          if (isPermissionError) {
            toast(
              t({
                id: "call-conference.video-no-browser-permissions",
                defaultMessage:
                  "Unable to access the camera. Please check your browser permissions and ensure that access to the camera is allowed.",
              }),
              {
                variant: "error",
              }
            );
            setVideoEnabled(false);
            setCallsSettings({
              isVideoOn: false,
            });
          }
        }
      } finally {
        setCameraEnableInProgress(false);
      }
      return;
    }
    const nextIsDisabled = !localVideoTrack.enabled;
    if (nextIsDisabled) {
      await client.disableDualStream();
    }
    await localVideoTrack?.setEnabled(nextIsDisabled);
    setCallsSettings({
      isVideoOn: false,
    });
    setVideoEnabled(nextIsDisabled);
  }, [
    getCallsSettings,
    localVideoTrack,
    setCallsSettings,
    cameraEnableInProgress,
    client,
    toast,
    t,
  ]);
  useEffect(() => {
    const storedDevicesSettings = getCallsSettings();

    if (
      !rtcProps ||
      (previousRtcProps && isEqual(rtcProps, previousRtcProps))
    ) {
      return;
    }
    const isCall = rtcProps.type === ScenarioCallType.call;
    const clientPrefix = stage === "staging" && !isCall ? "staging" : "web";
    client
      .join(
        rtcProps.appId,
        rtcProps.channel,
        rtcProps.token,
        `${clientPrefix}_${meId}`
      )
      .then(async () => {
        try {
          const audioTrack = await AgoraRTC.createMicrophoneAudioTrack({
            encoderConfig: "speech_standard",
            ANS: true,
            AEC: true,
          });
          if (!isCall) {
            audioTrack.setEnabled(false);
            if (storedDevicesSettings?.microphone_id) {
              audioTrack.setDevice(storedDevicesSettings?.microphone_id);
            }
            if (storedDevicesSettings?.isAudioOn) {
              await client.publish(audioTrack);
              audioTrack.setEnabled(true);
              setAudioEnabled(true);
            }

            if (storedDevicesSettings?.isVideoOn) {
              toggleVideo();
              setVideoEnabled(true);
            }
          }
          if (isCall) {
            await client.publish(audioTrack);
            audioTrack.setEnabled(true);
            setAudioEnabled(true);
            if (rtcProps.video) {
              toggleVideo();
              setVideoEnabled(true);
            }
          }

          setLocalAudioTrack(audioTrack);
          audioTrack.pipe(audioProcessor).pipe(audioTrack.processorDestination);
        } catch (error) {
          if (error instanceof Error) {
            toast(error.message, { variant: "error" });
          }
        }
      });
    setVideoEnabled(false);
  }, [
    rtcProps,
    previousRtcProps,
    client,
    meId,
    stage,
    toggleVideo,
    getCallsSettings,
    toast,
  ]);
  useEffect(() => {
    if (!client) return () => {};
    handleSetRemoteUsers(client.remoteUsers);
    const handleUserPublished = async (
      user: IAgoraRTCRemoteUser,
      mediaType: "audio" | "video"
    ) => {
      await client.subscribe(user, mediaType);
      setUserTracks((prev) => {
        const currentUserTracks = prev[user.uid] || {};
        if (mediaType === "audio") {
          currentUserTracks.audio = user.audioTrack;
        }
        if (mediaType === "video") {
          currentUserTracks.video = user.videoTrack;
        }
        return {
          ...prev,
          [user.uid]: currentUserTracks,
        };
      });
      handleSetRemoteUsers(Array.from(client.remoteUsers));
    };
    const handleUserUnpublished = (
      user: IAgoraRTCRemoteUser,
      mediaType: "audio" | "video"
    ) => {
      setUserTracks((prev) => {
        const currentUserTracks = prev[user.uid] || {};
        if (mediaType === "audio") {
          currentUserTracks.audio = user.audioTrack;
        }
        if (mediaType === "video") {
          currentUserTracks.video = user.videoTrack;
        }
        return {
          ...prev,
          [user.uid]: currentUserTracks,
        };
      });
      handleSetRemoteUsers(Array.from(client.remoteUsers));
    };
    const handleUserJoined = () => {
      handleSetRemoteUsers(Array.from(client.remoteUsers));
    };
    const handleUserLeft = () => {
      handleSetRemoteUsers(Array.from(client.remoteUsers));
    };

    client.on("user-published", handleUserPublished);
    client.on("user-unpublished", handleUserUnpublished);
    client.on("user-joined", handleUserJoined);
    client.on("user-left", handleUserLeft);

    return () => {
      client.off("user-published", handleUserPublished);
      client.off("user-unpublished", handleUserUnpublished);
      client.off("user-joined", handleUserJoined);
      client.off("user-left", handleUserLeft);
    };
  }, [client]);

  useEffect(() => {
    const isError = localAudioTrack instanceof Error;
    if (isError) {
      return;
    }
    if (audioEnabled && localAudioTrack) {
      audioProcessor.setMode(AIDenoiserProcessorMode.NSNG);
      audioProcessor.setLevel(AIDenoiserProcessorLevel.AGGRESSIVE);
      audioProcessor.enable();
    }
  }, [audioEnabled, localAudioTrack]);
  useEffect(() => {
    remoteUsers.forEach((user) => {
      client.setStreamFallbackOption(user.uid, 2);
    });
  }, [remoteUsers, client]);
  const handleStopScreenShare = useCallback(async () => {
    if (Array.isArray(localScreenShareTrackRef?.current)) {
      await localScreenShareTrackRef.current[0]?.close();
      await localScreenShareTrackRef.current[1]?.close();
    } else {
      await localScreenShareTrackRef.current?.close();
    }
    await localScreenShareAudioTrack?.close();
    await localScreenShareTrack?.close();
    await screenSharingClient.leave();
    setLocalScreenShareAudioTrack(undefined);
    setLocalScreenShareTrack(undefined);
    setScreenSharingEnabled(false);
  }, [localScreenShareAudioTrack, localScreenShareTrack]);

  const toggleScreenShare = useCallback(async () => {
    if (screenSharingEnabled || localScreenShareTrack) {
      handleStopScreenShare();
      return;
    }
    const screenShareTrack = await AgoraRTC.createScreenVideoTrack(
      {
        optimizationMode: "detail",
      },
      "auto"
    );
    localScreenShareTrackRef.current = screenShareTrack;
    const screenShareVideoTrack = Array.isArray(screenShareTrack)
      ? screenShareTrack[0]
      : screenShareTrack;
    const screenShareAudioTrack = Array.isArray(screenShareTrack)
      ? screenShareTrack[1]
      : undefined;
    setLocalScreenShareTrack(screenShareVideoTrack);
    setLocalScreenShareAudioTrack(screenShareAudioTrack);
    if (!rtcProps) return;
    screenSharingClient
      .join(
        rtcProps?.appId,
        rtcProps?.channel,
        rtcProps?.token,
        `screenSharing_${meId}`
      )
      .then(async () => {
        screenSharingClient.publish(screenShareVideoTrack);
      });
    setScreenSharingEnabled(true);
  }, [
    screenSharingEnabled,
    localScreenShareTrack,
    rtcProps,
    meId,
    handleStopScreenShare,
  ]);

  const toggleAudio = useCallback(async () => {
    if (!localAudioTrack) {
      try {
        const audioTrack = await AgoraRTC.createMicrophoneAudioTrack({
          encoderConfig: "speech_standard",
          ANS: true,
          AEC: true,
        });
        setLocalAudioTrack(audioTrack);
        audioTrack.pipe(audioProcessor).pipe(audioTrack.processorDestination);
      } catch (error) {
        setVideoEnabled(false);
        setCallsSettings({
          isVideoOn: false,
        });
        if (error instanceof Error) {
          const isPermissionError = error.message.includes("Permission denied");
          if (isPermissionError) {
            toast(
              t({
                id: "call-conference.audio-no-browser-permissions",
                defaultMessage:
                  "Unable to access the microphone. Please check your browser permissions and ensure that access to the microphone is allowed.",
              }),
              {
                variant: "error",
              }
            );
          }
        }
      }
    } else {
      const nextIsDisabled = !localAudioTrack?.enabled;
      setCallsSettings({
        isAudioOn: nextIsDisabled,
      });
      await localAudioTrack?.setEnabled(nextIsDisabled);
      setAudioEnabled(nextIsDisabled);
      if (nextIsDisabled && localAudioTrack) {
        await client.publish(localAudioTrack);
      }
    }
  }, [localAudioTrack, setCallsSettings, toast, t, client]);

  const toggleProcessor = useCallback(async () => {
    if (processor?.enabled) {
      processor.disable();
    } else {
      processor?.enable();
    }
    setProcessorEnabled(!processorEnabled);
  }, [processorEnabled]);
  const leaveCall = useCallback(async () => {
    if (localAudioTrack) {
      localAudioTrack.close();
      setLocalAudioTrack(undefined);
    }
    if (localVideoTrack) {
      localVideoTrack.close();
      setLocalVideoTrack(undefined);
    }
    if (localScreenShareTrack) {
      handleStopScreenShare();
    }
    setUserTracks({});
    setRemoteUsers([]);
    setStage("staging");
    await client.leave();
    await screenSharingClient.leave();
  }, [
    client,
    handleStopScreenShare,
    localAudioTrack,
    localScreenShareTrack,
    localVideoTrack,
  ]);

  const joinConferenceFromStaging = useCallback(async () => {
    await leaveCall();
    setStage("participation");
  }, [leaveCall, setStage]);
  const params: ActiveCallParams | null = useMemo(
    () =>
      rtcProps
        ? {
            localAudioTrack,
            localVideoTrack,
            remoteUsers,
            userTracks,
            toggleScreenShare,
            toggleVideo,
            toggleAudio,
            toggleProcessor,
            leaveCall,
            screenSharingEnabled,
            localScreenShareTrack,
            videoEnabled,
            audioEnabled,
            processor,
            handleStopScreenShare,
            joinConferenceFromStaging,
            stage,
          }
        : null,
    [
      localAudioTrack,
      localVideoTrack,
      remoteUsers,
      rtcProps,
      toggleScreenShare,
      userTracks,
      toggleVideo,
      toggleAudio,
      toggleProcessor,
      leaveCall,
      handleStopScreenShare,
      screenSharingEnabled,
      localScreenShareTrack,
      videoEnabled,
      audioEnabled,
      joinConferenceFromStaging,
      stage,
    ]
  );

  return params;
};
