import { DescriptionBox } from "@jugl-web/domain-resources/tasks/components/DescriptionBox";
import { TaskExtraPropertiesPanel } from "@jugl-web/domain-resources/tasks/components/TaskExtraPropertiesPanel";
import { TaskPropertiesPanel } from "@jugl-web/domain-resources/tasks/components/TaskPropertiesPanel";
import { TaskPermissions } from "@jugl-web/domain-resources/tasks/hooks/useTaskPermissions";
import { DetailedTask } from "@jugl-web/rest-api/tasks";
import { FC, useState } from "react";

interface TaskDetailsPropertiesPanelProps {
  entityId: string;
  task: DetailedTask;
  permissions: TaskPermissions;
  updateCurrentTask: (updatedTask: Partial<DetailedTask>) => Promise<void>;
  onAttachmentsClick: () => void;
  onCustomerAssign?: () => void;
}

export const TaskDetailsPropertiesPanel: FC<
  TaskDetailsPropertiesPanelProps
> = ({
  entityId,
  task,
  permissions,
  updateCurrentTask,
  onAttachmentsClick,
  onCustomerAssign,
}) => {
  const [isEditingDescription, setIsEditingDescription] = useState(false);

  return (
    <div className="mt-4 w-4/5 max-w-[700px]">
      {(task.desc || isEditingDescription) && (
        <DescriptionBox
          description={task.desc || ""}
          isEditing={isEditingDescription}
          classes={{ editingBox: "mb-8", descriptionText: "mb-8" }}
          onChange={(value) => updateCurrentTask({ desc: value })}
          onStartEditing={() => setIsEditingDescription(true)}
          onFinishEditing={() => {
            setIsEditingDescription(false);
          }}
        />
      )}
      <TaskPropertiesPanel
        entityId={entityId}
        config={{
          description: {
            isHidden:
              isEditingDescription ||
              !!task.desc ||
              !permissions.canEditDescription,
            onClick: () => setIsEditingDescription(true),
          },
          status: {
            statusId: task.status,
            isReadonly: !permissions.canEditStatus,
            onChange: (value) => updateCurrentTask({ status: value }),
          },
          dueDate: {
            date: task.due_at ? new Date(task.due_at) : null,
            displayAs: "date",
            isReadonly: !permissions.canEditDueDate,
            onChange: (date) =>
              updateCurrentTask({ due_at: date ? date.toISOString() : null }),
          },
          assignees: {
            assigneeIds: task.assignees,
            isReadonly: !permissions.canEditAssignees,
            onChange: (assigneeIds) =>
              updateCurrentTask({ assignees: assigneeIds }),
            isHidden: task.is_self,
          },
          priority: {
            priority: task.priority,
            isReadonly: !permissions.canEditPriority,
            onChange: (priority) => updateCurrentTask({ priority }),
          },
          label: {
            labelId: task.label_id,
            isReadonly: !permissions.canEditLabel,
            onChange: (labelId) => updateCurrentTask({ label_id: labelId }),
          },
          customer: {
            customerId: task.cust_id,
            isReadonly: !permissions.canEditCustomer,
            onChange: async (customer) => {
              updateCurrentTask({ cust_id: customer?.id || null });
              onCustomerAssign?.();
            },
          },
          timeSpent: {
            duration: task.duration,
            isReadonly: !permissions.canEditTimeSpentOnTask,
            onChange: (duration) => updateCurrentTask({ duration }),
          },
          attachments: {
            count: task.attachments.length,
            onClick: onAttachmentsClick,
          },
          customFields: {
            onFieldChange: (fieldId, rawValue) =>
              updateCurrentTask({
                custom_fields: {
                  ...task.custom_fields,
                  [fieldId]: rawValue || "",
                },
              }),
            valuesById: task.custom_fields || {},
            isReadonly: !permissions.canEditCustomFields,
          },
        }}
      />
      <TaskExtraPropertiesPanel
        entityId={entityId}
        config={{
          privateTask: {
            isHidden: !task.is_self,
            type: "presentational",
          },
          allowAssigneesEdit: {
            isChecked: task.can_assignee_edit,
            isHidden:
              !permissions.canManageAllowAssigneeToEditOption || task.is_self,
            onChange: (isChecked) =>
              updateCurrentTask({ can_assignee_edit: isChecked }),
          },
          completeChecklistInOrder: {
            isChecked: task.has_chklist_chk,
            isHidden: !permissions.canManageCompleteInSpecificOrderOption,
            onChange: (isChecked) =>
              updateCurrentTask({ has_chklist_chk: isChecked }),
          },
        }}
        className="mt-8"
      />
    </div>
  );
};
