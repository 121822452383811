import { PeopleModuleLog, PeopleModuleLogChanges } from "@jugl-web/rest-api";
import {
  addSearchParamsToURL,
  isoToLocalDate,
  joinReactNodes,
} from "@jugl-web/utils";
import { joinReactNodesInHumanReadableForm } from "@jugl-web/utils/utils/joinReactNodes";
import { ReactNode, useCallback, useMemo } from "react";

type SupportedPeopleModuleLogChanges = Required<PeopleModuleLogChanges>;

type ChangeKeyToMessageRendererMap = {
  [Key in keyof SupportedPeopleModuleLogChanges]: (
    arg: SupportedPeopleModuleLogChanges[Key]
  ) => ReactNode;
};

export const usePeopleLogParser = () => {
  const peopleAddedMessageRenderer = useCallback(
    (username: string, addedUsername: string) => (
      <>
        <b>{username}</b> has added <b>{addedUsername}</b> to the workspace
      </>
    ),
    []
  );

  const peopleInvitedMessageRenderer = useCallback(
    (username: string, invitedUsername: string) => (
      <>
        <b>{username}</b> has invited <b>{invitedUsername}</b> to the workspace
      </>
    ),
    []
  );

  const peopleAcceptedMessageRenderer = useCallback(
    (username: string) => (
      <>
        <b>{username}</b> has accepted the invitation to the workspace
      </>
    ),
    []
  );

  const peopleDeclinedMessageRenderer = useCallback(
    (username: string) => (
      <>
        <b>{username}</b> has declined the invitation to the workspace
      </>
    ),
    []
  );

  const peopleLeftMessageRenderer = useCallback(
    (username: string) => (
      <>
        <b>{username}</b> has left the workspace
      </>
    ),
    []
  );

  const peopleUpdatedMessageRenderer = useCallback(
    (username: string, changesMessage: ReactNode, updatedUsername: string) =>
      joinReactNodes(
        [
          <b>{username}</b>,
          changesMessage,
          <>
            for the user <b>{updatedUsername}</b>
          </>,
        ],
        " "
      ),
    []
  );

  const peopleDeletedMessageRenderer = useCallback(
    (username: string, deletedUsername: string) => (
      <>
        <b>{username}</b> has removed <b>{deletedUsername}</b> from the
        workspace
      </>
    ),
    []
  );

  const peopleAccountDeletedMessageRenderer = useCallback(
    (username: string) => (
      <>
        <b>{username}</b> has deleted their account, and has been removed from
        the workspace
      </>
    ),
    []
  );

  const changeKeyToMessageRendererMap: ChangeKeyToMessageRendererMap = useMemo(
    () => ({
      role: (role) => (
        <>
          has updated role to <b>{role}</b>
        </>
      ),
      status: (status) => (
        <>
          has updated status to <b>{status}</b>
        </>
      ),
      emails: () => "has updated email",
      mobiles: () => "has updated phone number",
      username: () => "has updated name",
    }),
    []
  );

  const peopleChangeRenderer = useCallback(
    <
      TKey extends keyof SupportedPeopleModuleLogChanges,
      TValue extends SupportedPeopleModuleLogChanges[TKey]
    >(
      key: TKey,
      value: TValue
    ): ReactNode | undefined => changeKeyToMessageRendererMap[key]?.(value),
    [changeKeyToMessageRendererMap]
  );

  const extractAffectedUsernameFromLog = useCallback((log: PeopleModuleLog) => {
    if (log.people.first_name && log.people.last_name) {
      return `${log.people.first_name} ${log.people.last_name}`;
    }

    return log.people.username || "Unknown user";
  }, []);

  const extractMessageFromChanges = useCallback(
    (changes: PeopleModuleLogChanges) => {
      const changeMessages = Object.keys(changes).reduce<ReactNode[]>(
        (acc, key) => {
          const changeKey = key as keyof SupportedPeopleModuleLogChanges;

          const changeValue = changes[
            changeKey
          ] as SupportedPeopleModuleLogChanges[keyof SupportedPeopleModuleLogChanges];

          let changeMessage: ReactNode;

          try {
            changeMessage = peopleChangeRenderer(changeKey, changeValue);
          } catch {
            // Do nothing
          }

          if (!changeMessage) {
            // eslint-disable-next-line no-console
            console.warn(
              "Couldn't parse the following people module log change",
              { changeKey, changeValue }
            );

            return acc;
          }

          return [...acc, changeMessage];
        },
        []
      );

      // Fallback message if there is no valid change message
      if (changeMessages.length === 0) {
        return `has introduced a change`;
      }

      return joinReactNodesInHumanReadableForm(changeMessages);
    },

    [peopleChangeRenderer]
  );

  const peopleMessageRenderer = useCallback(
    (username: string, log: PeopleModuleLog) => {
      switch (log.action) {
        case "added":
          return peopleAddedMessageRenderer(
            username,
            extractAffectedUsernameFromLog(log)
          );

        case "invited":
          return peopleInvitedMessageRenderer(
            username,
            extractAffectedUsernameFromLog(log)
          );

        case "accepted":
          return peopleAcceptedMessageRenderer(
            extractAffectedUsernameFromLog(log)
          );

        case "declined":
          return peopleDeclinedMessageRenderer(
            extractAffectedUsernameFromLog(log)
          );

        case "left":
          return peopleLeftMessageRenderer(extractAffectedUsernameFromLog(log));

        case "updated":
          return peopleUpdatedMessageRenderer(
            username,
            extractMessageFromChanges(log.changes),
            extractAffectedUsernameFromLog(log)
          );

        case "deleted":
          return peopleDeletedMessageRenderer(
            username,
            extractAffectedUsernameFromLog(log)
          );

        case "acc_deleted":
          return peopleAccountDeletedMessageRenderer(
            extractAffectedUsernameFromLog(log)
          );

        default:
          throw new Error(`Unsupported people log action: ${log.action}`);
      }
    },
    [
      extractAffectedUsernameFromLog,
      extractMessageFromChanges,
      peopleAcceptedMessageRenderer,
      peopleAccountDeletedMessageRenderer,
      peopleAddedMessageRenderer,
      peopleDeclinedMessageRenderer,
      peopleDeletedMessageRenderer,
      peopleInvitedMessageRenderer,
      peopleLeftMessageRenderer,
      peopleUpdatedMessageRenderer,
    ]
  );

  const parsePeopleLog = useCallback(
    (log: PeopleModuleLog) => {
      const username = [log.action_by.first_name, log.action_by.last_name].join(
        " "
      );

      return {
        id: log.id,
        user: {
          name: username,
          avatarUrl: log.action_by.img
            ? addSearchParamsToURL(log.action_by.img, {
                t: log.action_by.updated_at,
              })
            : null,
        },
        message: peopleMessageRenderer(username, log),
        date: new Date(isoToLocalDate(log.inserted_at)),
      };
    },
    [peopleMessageRenderer]
  );

  return { parsePeopleLog };
};
