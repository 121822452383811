import {
  PaginatedRequestParams,
  PaginatedResponse,
  RtkBaseQuery,
  RtkEmptySplitApi,
} from "../../types";
import { fetchAllPages } from "../../utils";
import { WorkspacesApiTag } from "./tags";
import { Workspace, WorkspaceParticipant } from "./types";

export const addWorkspacesApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [WorkspacesApiTag.participants, WorkspacesApiTag.workspace],
  });

  const workspacesApi = apiWithTags.injectEndpoints({
    endpoints: (builder) => ({
      createWorkspace: builder.mutation<
        void,
        {
          data: {
            opts: { title: string };
            participants?: string[];
            display_pic_file?: File;
          };
          entityId: string;
        }
      >({
        query: ({ data, entityId }) => {
          const formData = new FormData();
          formData.set("opts", JSON.stringify(data.opts));
          formData.set("participants", JSON.stringify(data.participants));
          if (data.display_pic_file) {
            formData.set("display_picture_file", data.display_pic_file);
          }
          return {
            url: `/api/auth/entity/${entityId}/workspaces`,
            data: formData,
            method: "POST",
          };
        },
      }),

      updateWorkspace: builder.mutation<
        Workspace,
        {
          data: {
            opts: { title: string };
            participants?: string[];
            display_pic_file?: File;
          };
          entityId: string;
          id: string;
        }
      >({
        query: ({ data, entityId, id }) => {
          const formData = new FormData();
          formData.set("opts", JSON.stringify(data.opts));
          formData.set("participants", JSON.stringify(data.participants));
          if (data.display_pic_file) {
            formData.set("display_picture_file", data.display_pic_file);
          }
          return {
            url: `/api/auth/entity/${entityId}/workspaces/${id}`,
            data: formData,
            method: "PUT",
          };
        },
        invalidatesTags: (a0, a1, { id }) => [
          { type: WorkspacesApiTag.workspace, id },
        ],
      }),

      getWorkspace: builder.query<
        Workspace,
        { workspaceId: string; entityId: string }
      >({
        query: ({ workspaceId, entityId }) => ({
          url: `/api/auth/entity/${entityId}/workspaces/${workspaceId}`,
        }),
        providesTags: (a0, a1, { workspaceId }) => [
          { type: WorkspacesApiTag.workspace, id: workspaceId },
        ],
      }),
      getWorkspaceParticipants: builder.query<
        PaginatedResponse<WorkspaceParticipant>,
        {
          entityId: string;
          workspaceId: string;
          params: PaginatedRequestParams<{ search?: string }>;
        }
      >({
        query: ({ entityId, workspaceId, params }) => ({
          url: `/api/auth/entity/${entityId}/workspaces/${workspaceId}/participants`,
          params,
        }),
        providesTags: (_result, _error, { workspaceId }) => [
          {
            type: WorkspacesApiTag.participants,
            id: `${workspaceId}`,
          },
        ],
      }),
      addWorkspaceParticipants: builder.mutation<
        void,
        {
          participants: string[];
          entityId: string;
          id: string;
        }
      >({
        query: ({ participants, id, entityId }) => ({
          url: `/api/auth/entity/${entityId}/workspaces/${id}/add`,
          method: "POST",
          data: {
            participants,
          },
        }),
        invalidatesTags: (e1, e2, { id }) => [
          {
            type: WorkspacesApiTag.participants,
            id,
          },
        ],
      }),
      deleteWorkspaceParticipant: builder.mutation<
        void,
        {
          id: string;
          userId: string;
          entityId: string;
        }
      >({
        query: ({ id, userId, entityId }) => ({
          url: `/api/auth/entity/${entityId}/workspaces/${id}/${userId}`,
          method: "DELETE",
        }),
        invalidatesTags: (e1, e2, { id }) => [
          {
            type: WorkspacesApiTag.participants,
            id,
          },
        ],
      }),

      _DEPRECATED_workspaceAllParticipants: builder.query<
        WorkspaceParticipant[],
        { entityId: string; id: string }
      >({
        queryFn: async (
          { entityId, id },
          _queryApi,
          _extraOptions,
          baseQuery
        ) => ({
          data: await fetchAllPages<WorkspaceParticipant>(
            `/api/auth/entity/${entityId}/workspaces/${id}/participants`,
            {},
            baseQuery as unknown as RtkBaseQuery
          ),
        }),
        providesTags: (e1, e2, { id }) => [
          {
            type: WorkspacesApiTag.participants,
            id,
          },
        ],
      }),
      deleteWorkspace: builder.mutation<
        void,
        {
          id: string;
          entityId: string;
        }
      >({
        query: ({ id, entityId }) => ({
          url: `/api/auth/entity/${entityId}/workspaces/${id}`,
          method: "DELETE",
        }),
        // TODO: tags
      }),
    }),
    overrideExisting: true,
  });

  return workspacesApi;
};

export type WorkspacesApi = ReturnType<typeof addWorkspacesApi>;
