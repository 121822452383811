import {
  TaskChecklist,
  TaskChecklistHandle,
  getDueDateBasedOnDays,
  taskNameValidator,
  templateChecklistItemAdapters,
} from "@jugl-web/domain-resources/tasks";
import { DescriptionBox } from "@jugl-web/domain-resources/tasks/components/DescriptionBox";
import { TaskExtraPropertiesPanel } from "@jugl-web/domain-resources/tasks/components/TaskExtraPropertiesPanel";
import { TaskPropertiesPanel } from "@jugl-web/domain-resources/tasks/components/TaskPropertiesPanel";
import { TitleBox } from "@jugl-web/domain-resources/tasks/components/TitleBox";
import { useCreateTask } from "@jugl-web/domain-resources/tasks/hooks/useCreateTask";
import { useLocalTaskChecklistHandlers } from "@jugl-web/domain-resources/tasks/hooks/useLocalTaskChecklistHandlers";
import { useTaskFormState } from "@jugl-web/domain-resources/tasks/hooks/useTaskFormState";
import { DetailedTaskTemplate } from "@jugl-web/rest-api/tasks-templates";
import { Alert } from "@jugl-web/ui-components/cross-platform/Alert";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { useTranslations } from "@jugl-web/utils";
import { useScreenTransitionManager } from "@jugl-web/utils/utils/ScreenTransitionManager";
import { TaskFormDialog } from "@web-src/features/tasks/TaskFormDialog";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useOnboarding } from "@web-src/modules/preferences/providers";
import { FC, useEffect, useRef, useState } from "react";
import { TemplatePreviewDialogScreenToParametersMap } from "../../types";

interface TaskCreationScreenProps {
  template: DetailedTaskTemplate;
  entityId: string;
  meId: string;
  onDialogClose: () => void;
}

export const TaskCreationScreen: FC<TaskCreationScreenProps> = ({
  template,
  entityId,
  meId,
  onDialogClose,
}) => {
  const {
    formState,
    isDirty,
    resetFormState,
    updateFormState,
    updateChecklistItems,
    updateCustomField,
  } = useTaskFormState();

  const taskChecklistHandlers = useLocalTaskChecklistHandlers({
    itemsSetter: updateChecklistItems,
  });

  const [discardChangesDialogState, setDiscardChangesDialogState] = useState<{
    onDiscard: () => void;
  } | null>(null);
  const isDiscardChangesDialogOpen = !!discardChangesDialogState?.onDiscard;

  const [isEditingDescription, setIsEditingDescription] = useState(false);

  const { transitionTo, transitionRequest$ } =
    useScreenTransitionManager<TemplatePreviewDialogScreenToParametersMap>();

  const { t } = useTranslations();

  const { navigateToPage } = useNavigation();
  const { isOnboardingActive, completeOnboardingStep } = useOnboarding();

  const { createTask, showTaskCreationToast, isLoading } = useCreateTask({
    entityId,
    onNavigate: (taskId) => navigateToPage("tasksDetails", { taskId }),
  });

  const $titleTextareaRef = useRef<HTMLTextAreaElement | null>(null);
  const $taskChecklistRef = useRef<TaskChecklistHandle | null>(null);
  // TO DO: Temporarily hidden
  // const $attachmentsSectionRef = useRef<HTMLDivElement | null>(null);

  const isDescriptionBoxVisible = formState.description || isEditingDescription;
  const isValid = taskNameValidator(formState.title);

  const handleSubmit = async () => {
    try {
      const createdTask = await createTask(formState);

      showTaskCreationToast(
        createdTask.id,
        t({
          id: "feedback.task-created-from-template-tap-to-open",
          defaultMessage:
            "Task has been created based on the template. Tap to open it",
        })
      );

      if (isOnboardingActive) {
        completeOnboardingStep("task");
      }

      onDialogClose();
    } catch {
      // Do nothing
    }
  };

  useEffect(() => {
    resetFormState({
      title: template.name || "",
      description: template.desc || "",
      dueDate: template.due_in ? getDueDateBasedOnDays(template.due_in) : null,
      labelId: template.label_id,
      priority: template.priority,
      checklistItems: template.checklist.map(
        templateChecklistItemAdapters.toInternalModel
      ),
      customFields: template.custom_fields || {},
    });
  }, [resetFormState, template]);

  useEffect(() => {
    const subscription = transitionRequest$.subscribe((request) => {
      if (isDirty()) {
        setDiscardChangesDialogState({ onDiscard: request.resolve });
      } else {
        request.resolve();
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [isDirty, transitionRequest$]);

  return (
    <>
      <TaskFormDialog.Content>
        <TaskFormDialog.TitleSection>
          <TitleBox
            title={formState.title}
            textareaRef={$titleTextareaRef}
            classes={{ titleText: "py-3" }}
            onInternalValueChange={(title) => updateFormState("title", title)}
          />
          {isDescriptionBoxVisible && (
            <DescriptionBox
              description={formState.description}
              isEditing={isEditingDescription}
              classes={{ editingBox: "mt-2", descriptionText: "mt-2 py-2" }}
              onChange={(description) =>
                updateFormState("description", description)
              }
              onStartEditing={() => setIsEditingDescription(true)}
              onFinishEditing={() => setIsEditingDescription(false)}
            />
          )}
          <TaskPropertiesPanel
            entityId={entityId}
            config={{
              description: {
                isHidden: !!formState.description || isEditingDescription,
                onClick: () => setIsEditingDescription(true),
              },
              status: {
                statusId: formState.statusId,
                onChange: (statusId) => updateFormState("statusId", statusId),
                hideCompleted: true,
              },
              dueDate: {
                date: formState.dueDate,
                displayAs: "date",
                onChange: (dueDate) => updateFormState("dueDate", dueDate),
              },
              assignees: {
                assigneeIds: formState.assigneeIds,
                onChange: (assigneeIds) =>
                  updateFormState("assigneeIds", assigneeIds),
                isHidden: formState.isPrivate,
              },
              label: {
                labelId: formState.labelId,
                onChange: (labelId) => updateFormState("labelId", labelId),
              },
              priority: {
                priority: formState.priority,
                onChange: (priority) => updateFormState("priority", priority),
              },
              customer: {
                customerId: formState.customer ? formState.customer.id : null,
                onChange: (customer) => updateFormState("customer", customer),
              },
              // TODO: Temporarily hidden
              // attachments: {
              //   count: formState.attachments.length,
              //   onClick: () => {
              //     $attachmentsSectionRef.current?.scrollIntoView({
              //       behavior: "smooth",
              //     });
              //   },
              // },
              customFields: {
                valuesById: formState.customFields,
                onFieldChange: updateCustomField,
              },
            }}
            className="mt-8"
          />
          <TaskExtraPropertiesPanel
            entityId={entityId}
            config={{
              privateTask: {
                type: "interactive",
                isChecked: formState.isPrivate,
                onChange: (isChecked) =>
                  updateFormState("isPrivate", isChecked),
              },
              allowAssigneesEdit: {
                isChecked: formState.allowAssigneesEdit,
                onChange: (isChecked) =>
                  updateFormState("allowAssigneesEdit", isChecked),
                isHidden: formState.isPrivate,
              },
              completeChecklistInOrder: {
                isChecked: formState.completeChecklistInOrder,
                onChange: (isChecked) =>
                  updateFormState("completeChecklistInOrder", isChecked),
              },
            }}
            className="mt-8"
          />
        </TaskFormDialog.TitleSection>
        <TaskFormDialog.ChecklistSection
          onAddItem={() => $taskChecklistRef.current?.addItem()}
        >
          <TaskChecklist
            ref={$taskChecklistRef}
            entityId={entityId}
            meId={meId}
            items={formState.checklistItems}
            isCompletable={false}
            isAssignable={!formState.isPrivate}
            {...taskChecklistHandlers}
          />
        </TaskFormDialog.ChecklistSection>
        {/* To be done */}
        {/* <TaskFormDialog.AttachmentsSection
          ref={$attachmentsSectionRef}
          onAddAttachment={() => {}}
        >
        </TaskFormDialog.AttachmentsSection> */}
      </TaskFormDialog.Content>
      <TaskFormDialog.Actions>
        <Button
          variant="contained"
          color="grey"
          className="h-10 w-[200px]"
          onClick={() => transitionTo({ name: "templatePreview" })}
        >
          {t({
            id: "common.cancel",
            defaultMessage: "Cancel",
          })}
        </Button>
        <Button
          variant="contained"
          color="primary"
          isDisabled={!isValid || isLoading}
          className="h-10 w-[200px]"
          onClick={handleSubmit}
        >
          {t({
            id: "tasks-page.create-task",
            defaultMessage: "Create task",
          })}
        </Button>
      </TaskFormDialog.Actions>
      <Alert
        isOpen={isDiscardChangesDialogOpen}
        title={t({
          id: "tasks-page.discard-changes-warning-title",
          defaultMessage: "Discard changes?",
        })}
        content={t({
          id: "tasks-page.discard-changes-details",
          defaultMessage: "If you discard, the task information won't be saved",
        })}
        buttons={[
          {
            text: t({
              id: "common.cancel",
              defaultMessage: "Cancel",
            }),
            role: "close",
          },
          {
            text: t({
              id: "common.discard",
              defaultMessage: "Discard",
            }),
            color: "primary",
            onClick: (_, actions) => {
              discardChangesDialogState?.onDiscard();
              actions.closeAlert();
            },
          },
        ]}
        onRequestClose={() => setDiscardChangesDialogState(null)}
      />
    </>
  );
};
