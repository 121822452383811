import { FC } from "react";
import { PlainButton } from "../../cross-platform/PlainButton";
import { ReactComponent as ArrowLeftIcon } from "./icons/arrow-left.svg";
import { FullScreenHeaderProps } from "./types";

export const FullScreenHeader: FC<FullScreenHeaderProps> = ({
  title,
  isDetached,
  onClose,
}) => (
  <header
    className="flex items-center gap-4 px-4 pb-4 pt-5"
    style={
      isDetached ? { boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.04)" } : undefined
    }
  >
    <PlainButton onClick={onClose}>
      <ArrowLeftIcon />
    </PlainButton>
    <h3 className="text-dark m-0 text-xl font-normal leading-4">{title}</h3>
  </header>
);
