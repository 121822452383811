import { DragOverlay } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Portal } from "@headlessui/react";
import { FC } from "react";

export interface DraggableTaskChecklistContainerProps {
  id: string;
  index: number;
  isDragDisabled: boolean;
  children: JSX.Element;
}

export const DraggableTaskChecklistContainer: FC<
  DraggableTaskChecklistContainerProps
> = ({ id, index, isDragDisabled, children }) => {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    disabled: isDragDisabled,
    data: { index },
  });

  return (
    <>
      <Portal>
        {isDragging && (
          <DragOverlay>
            <div className="mb-4">{children}</div>
          </DragOverlay>
        )}
      </Portal>
      <div
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        className="mb-4"
        style={{
          opacity: isDragging ? 0.4 : undefined,
          transform: CSS.Translate.toString(transform),
          transition,
        }}
      >
        {children}
      </div>
    </>
  );
};
