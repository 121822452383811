import { useCallback, useState } from "react";
import { getStorageItemWithFallback, saveItemToStorage } from "../storage";

const produceUseStorage =
  (storageGetter: () => Storage) =>
  <TValue>(key: string, initialValue: TValue) => {
    const storage = storageGetter();

    const [storedValue, setStoredValue] = useState(() =>
      getStorageItemWithFallback(key, initialValue, storage)
    );

    const setValue = useCallback(
      (value: TValue | ((previousValue: TValue) => TValue)) => {
        setStoredValue((previousStoredValue) => {
          const resolvedValue =
            value instanceof Function ? value(previousStoredValue) : value;

          saveItemToStorage(key, resolvedValue, storage);

          return resolvedValue;
        });
      },
      [key, storage]
    );

    return [storedValue, setValue] as const;
  };

export const useLocalStorage = produceUseStorage(() => localStorage);
export const useSessionStorage = produceUseStorage(() => sessionStorage);
