import React from "react";
import { Text } from "@jugl-web/ui-components/cross-platform/Text";
import { ReactComponent as FolderIcon } from "./assets/folder.svg";
import DriveMenu from "../../../DriveMenu";

const DriveEmptyPage: React.FC<{
  title: string;
  isMenuVisible?: boolean;
  subtitle?: string;
}> = ({ title, subtitle, isMenuVisible }) => (
  <div className="flex h-full w-full flex-col items-center justify-center">
    <FolderIcon />
    <Text variant="h4">{title}</Text>
    {subtitle && (
      <Text variant="body1" className="mb-7 max-w-[248px] text-center">
        {subtitle}
      </Text>
    )}
    {isMenuVisible && <DriveMenu />}
  </div>
);

export default DriveEmptyPage;
