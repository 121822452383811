import { PageNavParams } from "@web-src/modules/navigation/types";
import { ModulePagesConfig } from "@web-src/modules/navigation/types/ModulePagesConfigs";
import { SubscriptionPlanModuleId } from "@jugl-web/rest-api/entities/models/common-types/SubscriptionPlanModuleId";
import AdminReportsPage from "./AdminReportsPage";
import ScheduledReportsPage from "./ScheduledReportsPage";
import ScheduledReportsFormPage from "./ScheduledReportsFormPage";

export type ReportsPagesNavigationParams = {
  reportsAdmin: PageNavParams<{ reportType: string }>;
  reportsScheduled: PageNavParams;
  reportsScheduledForm: PageNavParams;
  reportsScheduledFormEdit: PageNavParams<{ reportId: string }>;
};

export const reportsPagesNavigationConfig: ModulePagesConfig<ReportsPagesNavigationParams> =
  {
    reportsAdmin: {
      element: <AdminReportsPage />,
      path: "reports/:reportType",
      isPrivate: true,
      subscriptionModuleRequired: SubscriptionPlanModuleId.reports,
    },
    reportsScheduled: {
      element: <ScheduledReportsPage />,
      path: "scheduled-reports",
      isPrivate: true,
      isAdminRequired: true,
    },
    reportsScheduledForm: {
      element: <ScheduledReportsFormPage />,
      path: "scheduled-reports-form",
      isPrivate: true,
      isAdminRequired: true,
    },
    reportsScheduledFormEdit: {
      element: <ScheduledReportsFormPage />,
      path: "scheduled-reports-form/:reportId",
      isPrivate: true,
      isAdminRequired: true,
    },
  };
