import React, { useState, useRef } from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
  Text,
  TypographyVariant,
} from "@jugl-web/ui-components/cross-platform/Text";
import { RootState } from "@web-src/store";
import { useFloating, flip, shift, autoUpdate } from "@floating-ui/react";
import { useSelector } from "react-redux";
import { ActionType } from "@jugl-web/rest-api/drive/types";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { useSearchParams } from "react-router-dom";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { useTusUpload } from "@jugl-web/domain-resources/files/hooks/useTusUpload";
import { FilesModule } from "@jugl-web/domain-resources/files/types";
import { useToast, useTranslations } from "@jugl-web/utils";
import { SpotlightTooltip } from "@jugl-web/ui-components/cross-platform/SpotlightTooltip";
import { useOnboarding } from "@web-src/modules/preferences/providers";
import FolderIcon from "./assets/folder.svg";
import FileIcon from "./assets/file.svg";
import PlusIcon from "./assets/plus.svg";
import { ReactComponent as AddIcon } from "./assets/add.svg";
import DriveForm from "../DriveForm";
import { useDriveProvider } from "../../providers";

const DriveMenu: React.FC = () => {
  const { t } = useTranslations();
  const [searchParams] = useSearchParams();
  const currentDirectoryId = searchParams.get("directoryId");
  const { entity } = useEntity();
  const { uploadFiles, driveHook } = useDriveProvider();
  const { refetch } = driveHook;
  const visited = useSelector((state: RootState) => state.drive.visited);
  const [isCreateDirectoryModalOpen, setIsCreateDirectoryModalOpen] =
    useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { uploadFile } = useTusUpload({
    module: FilesModule.drive,
    entityId: entity?.id,
  });
  const { toast, closeToast } = useToast({ variant: "web" });
  const {
    completeOnboardingStep,
    openedSpotlight,
    openSpotlight,
    isOnboardingActive,
  } = useOnboarding();
  const handleFileInputChange = async () => {
    if (!inputRef.current?.files || !entity?.id) {
      return;
    }
    const files = Array.from(inputRef.current.files);
    // TODO:
    if (currentDirectoryId) {
      uploadFiles(currentDirectoryId, files).then((count) => {
        if (count !== 0) {
          if (isOnboardingActive) {
            completeOnboardingStep("drive");
          }
          toast(
            t(
              {
                id: "drive-page.successfully-uploaded-files",
                defaultMessage:
                  "Successfully uploaded {count} {count, plural, one {file} other {files}}",
              },
              {
                count,
              }
            ),
            {
              autoHideDuration: 3000,
            }
          );
        }
      });
      return;
    }
    const toastId = toast(
      t(
        {
          id: "drive-page.uploading-files",
          defaultMessage:
            "Uploading {count} {count, plural, one {file} other {files}}",
        },
        {
          count: files.length,
        }
      ),
      {
        persist: true,
      }
    );
    try {
      await Promise.all(
        files.map((file) =>
          uploadFile({
            file,
            extraMetaData: { parent_dir_id: currentDirectoryId || "" },
          })
        )
      );
      refetch();
    } finally {
      closeToast(toastId);
    }
  };

  const menuElements = [
    {
      id: 0,
      action: () => inputRef.current?.click(),
      text: t({
        id: "drive-page.upload-files",
        defaultMessage: "Upload Files",
      }),
      icon: FileIcon,
      secondIcon: undefined,
    },
    {
      id: 1,
      action: () => setIsCreateDirectoryModalOpen(true),
      text: t({
        id: "drive-page.create-new-folder",
        defaultMessage: "Create new folder",
      }),
      icon: FolderIcon,
      secondIcon: PlusIcon,
    },
  ];

  const { refs, floatingStyles } = useFloating({
    middleware: [flip(), shift()],
    whileElementsMounted: autoUpdate,
    placement: "bottom-end",
  });

  return (
    <>
      <SpotlightTooltip
        isOpen={openedSpotlight === "drive"}
        placement="bottom"
        tip={t({
          id: "drive-page.create-folder-onboarding-hint",
          defaultMessage: "Tap here to create Folder or upload Files ✨",
        })}
        showButton={false}
        renderTrigger={({ ref, props }) => (
          <div ref={ref} {...props}>
            <Menu as="div" className="relative">
              <div className="w-[200px]">
                <MenuButton
                  as={Button}
                  size="small"
                  iconEnd={<AddIcon />}
                  fullWidth
                  ref={refs.setReference}
                  onClick={() => openSpotlight(undefined)}
                >
                  {t({
                    id: "common.create",
                    defaultMessage: "Create",
                  })}
                </MenuButton>
              </div>
              <MenuItems
                ref={refs.setFloating}
                style={{ ...floatingStyles }}
                className="absolute top-full z-10 mt-2 flex w-[376px] flex-col justify-items-center gap-4 rounded-xl bg-white p-4"
              >
                {menuElements.map((element) => {
                  if (element.id === 0 && visited.length === 1) return null;
                  return (
                    <MenuItem key={element.id}>
                      <div
                        tabIndex={0}
                        role="button"
                        className="bg-grey-100 flex h-[60px] w-[344px] cursor-pointer items-center rounded-xl px-5"
                        onClick={element.action}
                        onKeyDown={element.action}
                      >
                        <img src={element.icon} alt="" />
                        <Text variant={TypographyVariant.h4} className="mx-1.5">
                          {element.text}
                        </Text>
                        {element.secondIcon && (
                          <img
                            src={element.secondIcon}
                            className="ml-auto"
                            alt=""
                          />
                        )}
                      </div>
                    </MenuItem>
                  );
                })}
              </MenuItems>
            </Menu>
          </div>
        )}
        onDismiss={() => openSpotlight(undefined)}
      />
      {isCreateDirectoryModalOpen && (
        <DriveForm
          isOpen={isCreateDirectoryModalOpen}
          onRequestClose={setIsCreateDirectoryModalOpen}
          parentDirectory={currentDirectoryId || ""}
          type={ActionType.add}
        />
      )}
      <input
        ref={inputRef}
        type="file"
        multiple
        onChange={handleFileInputChange}
        hidden
      />
    </>
  );
};

export default DriveMenu;
