import { Alert } from "@jugl-web/ui-components/cross-platform";
import { FC } from "react";
import Lottie from "react-lottie";
import errorAnimation from "./assets/error-animation.json";

export interface AppSumoSubscriptionChangeAlertProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onConfirm: () => void;
}

export const AppSumoSubscriptionChangeAlert: FC<
  AppSumoSubscriptionChangeAlertProps
> = ({ isOpen, onRequestClose, onConfirm }) => (
  <Alert
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    title="Subscription changes"
    img={<Lottie options={{ animationData: errorAnimation }} width={100} />}
    content="Please pay attention, that current plan Lifetime Jugl Basic was activated from one-time promocode. If you will Change Plan to different one, you will no longer be able to activate previous Lifetime Jugl Basic plan."
    buttons={[
      { role: "close", text: "Cancel" },
      { text: "Okay", onClick: onConfirm },
    ]}
  />
);
