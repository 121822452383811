import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  TASK_COLUMNS_IDS,
  TaskCardDraggableData,
  TaskColumnDroppableData,
} from "@jugl-web/domain-resources/tasks";
import { useSelectTaskById } from "@jugl-web/domain-resources/tasks/hooks/useSelectTaskById";
import { getTaskPermissions } from "@jugl-web/domain-resources/tasks/hooks/useTaskPermissions";
import {
  useUpdateTask,
  useUpdateTaskFactory,
} from "@jugl-web/domain-resources/tasks/hooks/useUpdateTask";
import { PreviewTask } from "@jugl-web/rest-api/tasks";
import { assert, useToast, useTranslations } from "@jugl-web/utils";
import { copyTime } from "@jugl-web/utils/date-time/copyTime";
import { selectUserId } from "@web-src/features/auth/authSlice";
import UserProfileName from "@web-src/features/users/components/UserProfileName";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { isoToLocalDate } from "@web-src/utils/helper";
import { isBefore, isPast, startOfDay } from "date-fns";
import { ComponentProps, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTasksPageContext } from "../../TasksPageContext";

type AnyData = Record<string, unknown> | undefined;

const isValidDraggableData = (data: AnyData): data is TaskCardDraggableData =>
  !!data && "type" in data && data.type === "task";

const isValidDroppableData = (data: AnyData): data is TaskColumnDroppableData =>
  !!data;

type ColumnDropHandler<
  TColumnType extends TaskColumnDroppableData["type"],
  TExtraFields extends object = object
> = (
  props: {
    task: PreviewTask;
    permissions: ReturnType<typeof getTaskPermissions>;
    value: Extract<TaskColumnDroppableData, { type: TColumnType }>["value"];
    updateTask: ReturnType<typeof useUpdateTask>;
  } & TExtraFields
) => void;

export const useTasksDnd = () => {
  const { taskListMode } = useTasksPageContext();
  const { entity } = useEntitySelectedProvider();
  const meId = useSelector(selectUserId) || "";

  const updateTaskFactory = useUpdateTaskFactory({
    entityId: entity.id,
    isInTeamTasksContext: taskListMode === "team",
  });

  const { t } = useTranslations();
  const { toast } = useToast({ variant: "web" });

  const selectTaskById = useSelectTaskById({ entityId: entity.id });

  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10,
    },
  });

  const sensors = useSensors(mouseSensor);

  // #region Common logic
  const showNotEnoughPermissionsToast = useCallback(() => {
    toast(
      t({
        id: "feedback.no-permissions-to-perform-action",
        defaultMessage: "You don't have permissions to perform this action",
      }),
      { variant: "error" }
    );
  }, [t, toast]);

  const showUsersUnassignedToast = useCallback(
    (userIds: string[]) =>
      toast(
        <span>
          {userIds.length > 1
            ? t(
                {
                  id: "feedback.users-unassigned-from-task",
                  defaultMessage:
                    "<fancyText>{number}</fancyText> employees was unassigned from Task",
                },
                {
                  fancyText: (text: (string | JSX.Element)[]) => (
                    <span className="font-semibold">{text}</span>
                  ),
                  number: userIds.length,
                }
              )
            : t(
                {
                  id: "feedback.user-unassigned-from-task",
                  defaultMessage:
                    "<fancyText>{user}</fancyText> was unassigned from Task",
                },
                {
                  fancyText: (text: (string | JSX.Element)[]) => (
                    <span className="font-semibold">{text}</span>
                  ),
                  user: <UserProfileName userId={userIds[0]} />,
                }
              )}
        </span>
      ),
    [t, toast]
  );

  const showUserUnassignedToast = useCallback(
    (userId: string) =>
      toast(
        <span>
          {t(
            {
              id: "feedback.user-unassigned-from-task",
              defaultMessage:
                "<fancyText>{user}</fancyText> was unassigned from Task",
            },
            {
              fancyText: (text: (string | JSX.Element)[]) => (
                <span className="font-semibold">{text}</span>
              ),
              user: <UserProfileName userId={userId} />,
            }
          )}
        </span>
      ),
    [t, toast]
  );

  const showUserAssignedToast = useCallback(
    (userId: string, sourceColumnId: string, isOnlyAssignee: boolean) =>
      toast(
        <span>
          {isOnlyAssignee
            ? t(
                {
                  id: "feedback.user-assigned-to-task",
                  defaultMessage:
                    "<fancyText>{user}</fancyText> was assigned to Task",
                },
                {
                  fancyText: (text: (string | JSX.Element)[]) => (
                    <span className="font-semibold">{text}</span>
                  ),
                  user: <UserProfileName userId={userId} />,
                }
              )
            : t(
                {
                  id: "feedback.user1-replaced-user2-task",
                  defaultMessage:
                    "<fancyText>{user1}</fancyText> replaced <fancyText>{user2}</fancyText> from Task",
                },
                {
                  fancyText: (text: (string | JSX.Element)[]) => (
                    <span className="font-semibold">{text}</span>
                  ),
                  user1: <UserProfileName userId={userId} />,
                  user2: <UserProfileName userId={sourceColumnId} />,
                }
              )}
        </span>
      ),
    [t, toast]
  );

  const isDueDateInPast = useCallback(
    (value: string | null) =>
      value !== null && isBefore(new Date(value), startOfDay(new Date())),
    []
  );

  const getUpdatedDueDate = useCallback(
    (task: PreviewTask, value: string | null) => {
      // Prevent setting due date in the past
      if (isDueDateInPast(value)) {
        return task.due_at;
      }

      let dueAt: string | null;

      // If the task has been moved to a column without a due date,
      // set the due date to null
      if (value === null) {
        dueAt = null;
      }
      // If the task has been moved to a column with a due date and doesn't have a due date yet,
      // set the due date to the column's due date
      else if (task.due_at === null) {
        dueAt = value;
      }
      // If the task has been moved to a column with a due date and has a due date already,
      // update the due date to the column's due date while keeping the time
      else {
        const taskDueDate = isoToLocalDate(task.due_at);
        const updatedTaskDueDate = new Date(value);

        const updatedTaskDueDateWithOriginalTime = copyTime(
          taskDueDate,
          updatedTaskDueDate
        );

        // If the task's due date is in the past, set the due date to the column's due date
        if (isPast(updatedTaskDueDateWithOriginalTime)) {
          dueAt = value;
        }
        // or keep the original time if the task's due date is still in the future
        else {
          dueAt = updatedTaskDueDateWithOriginalTime.toISOString();
        }
      }

      return dueAt;
    },
    [isDueDateInPast]
  );

  const getUpdatedAssignees = useCallback(
    (task: PreviewTask, value: string, previousAssigneeId: string) => {
      // If the task has been moved to a column without an assignee,
      // set the assignees to an empty array
      const isMovedToWithoutAssigneeColumn =
        value === TASK_COLUMNS_IDS.ASSIGNEE_VIEW_WITHOUT_ASSIGNEE;

      if (isMovedToWithoutAssigneeColumn) {
        showUsersUnassignedToast(task.assignees);

        return [];
      }

      // If the task has been moved to a column with the same assignee,
      // remove the assignee from the task
      const isMovedToUserWithSameTaskAssigned = task.assignees.includes(value);

      if (isMovedToUserWithSameTaskAssigned) {
        showUserUnassignedToast(previousAssigneeId);

        return task.assignees.filter(
          (assigneeId) => assigneeId !== previousAssigneeId
        );
      }

      // If the task has been moved to a column with a different assignee,
      // add the new assignee to the task and remove the old assignee
      const isMovedToUserThatIsNotTaskAssignee =
        !task.assignees.includes(value);

      if (isMovedToUserThatIsNotTaskAssignee) {
        showUserAssignedToast(
          value,
          previousAssigneeId,
          task.assignees.length === 0
        );

        return [
          value,
          ...task.assignees.filter(
            (assigneeId) => assigneeId !== previousAssigneeId
          ),
        ];
      }

      // Although this should never happen, return the original assignees
      // if none of the above conditions are met
      return task.assignees;
    },
    [showUserAssignedToast, showUserUnassignedToast, showUsersUnassignedToast]
  );
  // #endregion

  // #region Column drop handlers
  const handleDueDateColumnDrop: ColumnDropHandler<"dueDate"> = useCallback(
    ({ task, permissions, value, updateTask }) => {
      if (!permissions.canEditDueDate) {
        showNotEnoughPermissionsToast();
        return;
      }

      const dueAt = getUpdatedDueDate(task, value);

      updateTask({ due_at: dueAt });
    },
    [getUpdatedDueDate, showNotEnoughPermissionsToast]
  );

  const handleLabelColumnDrop: ColumnDropHandler<"label"> = useCallback(
    ({ permissions, value, updateTask }) => {
      if (!permissions.canEditLabel) {
        showNotEnoughPermissionsToast();
        return;
      }

      updateTask({ label_id: value });
    },
    [showNotEnoughPermissionsToast]
  );

  const handlePriorityColumnDrop: ColumnDropHandler<"priority"> = useCallback(
    ({ permissions, value, updateTask }) => {
      if (!permissions.canEditPriority) {
        showNotEnoughPermissionsToast();
        return;
      }

      updateTask({ priority: value });
    },
    [showNotEnoughPermissionsToast]
  );

  const handleStatusColumnDrop: ColumnDropHandler<"status"> = useCallback(
    ({ permissions, value, updateTask }) => {
      if (!permissions.canEditStatus) {
        showNotEnoughPermissionsToast();
        return;
      }

      updateTask({ status: value });
    },
    [showNotEnoughPermissionsToast]
  );

  const handleAssigneeColumnDrop: ColumnDropHandler<
    "assignee",
    { sourceColumnId: string }
  > = useCallback(
    ({ task, permissions, value, sourceColumnId, updateTask }) => {
      if (!permissions.canEditAssignees) {
        showNotEnoughPermissionsToast();
        return;
      }

      const updatedAssignees = getUpdatedAssignees(task, value, sourceColumnId);

      updateTask({ assignees: updatedAssignees });
    },
    [getUpdatedAssignees, showNotEnoughPermissionsToast]
  );

  const handleCalendarReporteeColumnDrop: ColumnDropHandler<
    "calendarReportee",
    { sourceColumnId: string }
  > = useCallback(
    ({ task, permissions, value, sourceColumnId, updateTask }) => {
      if (isDueDateInPast(value.dateISO)) {
        return;
      }

      const sourceReporteeId = (() => {
        // First, check if the task doesn't come from the "no due date" or "overdue" column
        // (which would mean that the task belongs to the manager)
        if (
          sourceColumnId === TASK_COLUMNS_IDS.DATE_VIEW_WITHOUT_DUE_DATE ||
          sourceColumnId === TASK_COLUMNS_IDS.DATE_VIEW_OVERDUE
        ) {
          return meId;
        }

        // At this point, the source column should be a reportee column with a following ID pattern:
        // `calendar-view__{isoDate}__{reporteeId}`
        return sourceColumnId.split("__")[2];
      })();

      const hasReporteeChanged = sourceReporteeId !== value.reporteeId;

      if (hasReporteeChanged && !permissions.canEditAssignees) {
        showNotEnoughPermissionsToast();
        return;
      }

      const updatedDueDate = getUpdatedDueDate(task, value.dateISO);
      const hasDueDateChanged = updatedDueDate !== task.due_at;

      if (hasDueDateChanged && !permissions.canEditDueDate) {
        showNotEnoughPermissionsToast();
        return;
      }

      let updatedAssignees = task.assignees;

      if (hasReporteeChanged) {
        updatedAssignees = getUpdatedAssignees(
          task,
          value.reporteeId,
          sourceReporteeId
        );
      }

      updateTask({ due_at: updatedDueDate, assignees: updatedAssignees });
    },
    [
      getUpdatedAssignees,
      getUpdatedDueDate,
      isDueDateInPast,
      meId,
      showNotEnoughPermissionsToast,
    ]
  );

  const handleCustomerColumnDrop: ColumnDropHandler<"customer"> = useCallback(
    ({ permissions, value, updateTask }) => {
      if (!permissions.canEditCustomer) {
        showNotEnoughPermissionsToast();
        return;
      }

      updateTask({
        cust_id:
          value === TASK_COLUMNS_IDS.CUSTOMER_VIEW_WITHOUT_CUSTOMER
            ? null
            : value,
      });
    },
    [showNotEnoughPermissionsToast]
  );

  const handleCustomFieldColumnDrop: ColumnDropHandler<"customField"> =
    useCallback(
      ({ task, permissions, value, updateTask }) => {
        if (!permissions.canEditCustomFields) {
          showNotEnoughPermissionsToast();
          return;
        }

        updateTask({
          custom_fields: {
            ...task.custom_fields,
            [value.id]: value.value || "",
          },
        });
      },
      [showNotEnoughPermissionsToast]
    );
  // #endregion

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      assert(!!meId, "meId is not defined");

      const { active: draggable, over: droppable } = event;

      if (
        !isValidDraggableData(draggable.data.current) ||
        !droppable ||
        !isValidDroppableData(droppable.data.current)
      ) {
        return;
      }

      const matchingTask = selectTaskById(draggable.data.current.taskId);

      if (!matchingTask) {
        return;
      }

      const sourceColumnId = draggable.data.current.columnId;
      const targetColumnId = droppable.id;

      if (!sourceColumnId || sourceColumnId === targetColumnId) {
        return;
      }

      const permissions = getTaskPermissions({ task: matchingTask, meId });
      const updateTask = updateTaskFactory(matchingTask.id);

      switch (droppable.data.current.type) {
        case "dueDate":
          handleDueDateColumnDrop({
            task: matchingTask,
            permissions,
            value: droppable.data.current.value,
            updateTask,
          });
          break;

        case "label": {
          handleLabelColumnDrop({
            task: matchingTask,
            permissions,
            value: droppable.data.current.value,
            updateTask,
          });
          break;
        }

        case "priority":
          handlePriorityColumnDrop({
            task: matchingTask,
            permissions,
            value: droppable.data.current.value,
            updateTask,
          });
          break;

        case "status":
          handleStatusColumnDrop({
            task: matchingTask,
            permissions,
            value: droppable.data.current.value,
            updateTask,
          });
          break;

        case "assignee":
          handleAssigneeColumnDrop({
            task: matchingTask,
            permissions,
            value: droppable.data.current.value,
            sourceColumnId,
            updateTask,
          });
          break;

        case "calendarReportee":
          handleCalendarReporteeColumnDrop({
            task: matchingTask,
            permissions,
            value: droppable.data.current.value,
            sourceColumnId,
            updateTask,
          });
          break;

        case "customer":
          handleCustomerColumnDrop({
            task: matchingTask,
            permissions,
            value: droppable.data.current.value,
            updateTask,
          });
          break;

        case "customField":
          handleCustomFieldColumnDrop({
            task: matchingTask,
            permissions,
            value: droppable.data.current.value,
            updateTask,
          });
          break;

        default:
          break;
      }
    },
    [
      handleAssigneeColumnDrop,
      handleCalendarReporteeColumnDrop,
      handleCustomFieldColumnDrop,
      handleCustomerColumnDrop,
      handleDueDateColumnDrop,
      handleLabelColumnDrop,
      handlePriorityColumnDrop,
      handleStatusColumnDrop,
      meId,
      selectTaskById,
      updateTaskFactory,
    ]
  );

  const dndContextProps = useMemo<ComponentProps<typeof DndContext>>(
    () => ({ sensors, onDragEnd }),
    [sensors, onDragEnd]
  );

  return { dndContextProps };
};
