import React from "react";
import { Text } from "@jugl-web/ui-components/cross-platform";

export const ListItem: React.FC<{
  icon: React.ReactNode;
  text: string | React.ReactNode;
}> = ({ icon: Icon, text }) => (
  <div className="mr-4 flex flex-row items-center gap-2 overflow-hidden text-ellipsis">
    <div className="min-w-4">{Icon}</div>
    <Text variant="body2" className="text-grey overflow-hidden text-ellipsis">
      {text}
    </Text>
  </div>
);
