import { useTranslations } from "@jugl-web/utils";
import React, { useMemo } from "react";
import { InfoTabId } from "../../types";

export const ChatInfoEmptyContent: React.FC<{ tab: InfoTabId }> = ({ tab }) => {
  const { t } = useTranslations();
  const emptyContentMessage = useMemo(() => {
    switch (tab) {
      case InfoTabId.workspaceMembers:
        return t({
          id: "chats-page.no-members-found",
          defaultMessage: "No members found",
        });
      case InfoTabId.photoAttachments:
        return t({
          id: "chats-page.no-photos-found",
          defaultMessage: "No photos found",
        });
      case InfoTabId.videoAttachments:
        return t({
          id: "chats-page.no-videos-found",
          defaultMessage: "No videos found",
        });
      case InfoTabId.filesAttachments:
        return t({
          id: "chats-page.no-files-found",
          defaultMessage: "No files found",
        });
      case InfoTabId.audioAttachments:
        return t({
          id: "chats-page.no-audio-found",
          defaultMessage: "No audio found",
        });
      case InfoTabId.voiceAttachments:
        return t({
          id: "chats-page.no-voice-messages-found",
          defaultMessage: "No voice messages found",
        });
      default:
        return null;
    }
  }, [tab, t]);

  return (
    <div className="text-grey-900 mt-20 text-center text-sm">
      {emptyContentMessage}
    </div>
  );
};
