import { InternalTaskCustomField } from "@jugl-web/rest-api/tasks";
import { FC, useCallback } from "react";
import {
  CustomSpecificFieldCellProps,
  TaskTableCellComponentProps,
} from "../../types";
import { CustomDateFieldCell } from "../CustomDateFieldCell";
import { CustomDropdownFieldCell } from "../CustomDropdownFieldCell";
import { CustomNumberFieldCell } from "../CustomNumberFieldCell";
import { CustomTextFieldCell } from "../CustomTextFieldCell";

interface CustomFieldCellProps extends TaskTableCellComponentProps {
  field: InternalTaskCustomField;
}

export const CustomFieldCell: FC<CustomFieldCellProps> = ({
  task,
  field,
  permissions,
  cellProps,
  onUpdate,
}) => {
  const handleFieldUpdate = useCallback(
    (value: string) => {
      onUpdate({
        custom_fields: {
          ...task.custom_fields,
          [field.id]: value,
        },
      });
    },
    [field.id, onUpdate, task.custom_fields]
  );

  const specificFieldProps: CustomSpecificFieldCellProps = {
    field,
    rawValue: task.custom_fields?.[field.id] || "",
    isEditable: permissions.canEditCustomFields,
    cellProps,
    onFieldUpdate: handleFieldUpdate,
  };

  switch (field.type) {
    case "text":
      return <CustomTextFieldCell {...specificFieldProps} />;
    case "number":
      return <CustomNumberFieldCell {...specificFieldProps} />;
    case "date":
      return <CustomDateFieldCell {...specificFieldProps} />;
    case "dropdown":
      return <CustomDropdownFieldCell {...specificFieldProps} />;
    default:
      throw new Error(`Unknown field type: ${field.type}`);
  }
};
