import { cx } from "@jugl-web/utils";
import { FC, HTMLProps } from "react";

export const TaskChecklistItemContainer: FC<HTMLProps<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => (
  <div
    className={cx("bg-grey-100 @container rounded-lg p-4", className)}
    {...props}
  >
    {children}
  </div>
);
