import React, { useState } from "react";
import { MultiSectionLayout } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { HeaderBreadcrumbs } from "@jugl-web/ui-components/web";
import { ControlBar } from "./components/ControlBar";
import { ScheduledReportsTable } from "./components/ScheduledReportsTable";

export const ScheduledReportsPage: React.FC = () => {
  const { t } = useTranslations();
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <MultiSectionLayout
      className="bg-gray-100"
      header={
        <>
          <HeaderBreadcrumbs
            items={[
              {
                title: t({
                  id: "scheduled-reports-page.auto-reports",
                  defaultMessage: "Auto Reports",
                }),
              },
            ]}
          />
          <ControlBar onSearchQueryChange={setSearchQuery} />
        </>
      }
    >
      <ScheduledReportsTable searchQuery={searchQuery} />
    </MultiSectionLayout>
  );
};
