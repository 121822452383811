import {
  Button,
  MultiSectionLayout,
} from "@jugl-web/ui-components/cross-platform";
import { HeaderBreadcrumbs } from "@jugl-web/ui-components/web";
import { FC, useMemo, useState } from "react";
import Lottie from "react-lottie";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { apiUTCToLocalDateTime } from "@jugl-web/utils";
import format from "date-fns/format";
import addDays from "date-fns/addDays";
import { EntityParticipantRole } from "@jugl-web/rest-api";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { Navigate } from "react-router-dom";
import subscriptionExpiredMemberAnimation from "./assets/subscription-expired-member.json";
import subscriptionExpiredAdminAnimation from "./assets/subscription-expired-admin.json";
import { ReactComponent as JuglLogo } from "./assets/jugl-logo.svg";

export const WorkspaceNoSubscriptionPage: FC = () => {
  const { navigateToPage } = useNavigation();
  const [isSelectingSubscription, setIsSelectingSubscription] = useState(false);
  const { entity, subscriptionInfo } = useEntitySelectedProvider();
  const isAdmin = entity.role === EntityParticipantRole.admin;
  const {
    graceEndedDate,
    workspaceDeleteDate,
  }: { graceEndedDate: string; workspaceDeleteDate: string } = useMemo(
    () => ({
      graceEndedDate: format(
        apiUTCToLocalDateTime(subscriptionInfo.graceTill),
        "MMMM dd, yyyy"
      ),
      workspaceDeleteDate: format(
        addDays(apiUTCToLocalDateTime(subscriptionInfo.graceTill), 30),
        "MMMM dd, yyyy"
      ),
    }),
    [subscriptionInfo]
  );

  const $content = useMemo(() => {
    if (isAdmin) {
      return (
        <>
          <Lottie
            options={{ animationData: subscriptionExpiredAdminAnimation }}
            width={300}
            height={250}
          />
          <div className="font-secondary mt-6 mb-5 text-[32px] font-medium leading-[44px]">
            Upgrade Subscription to Continue
          </div>
          <div className="mb-2 text-[#40494D]">
            <span className="font-medium text-[#1764C0]">{entity.name}</span>{" "}
            Free Grace period was ended on {graceEndedDate}. To restore access
            to your Workspace please Update Subscription
          </div>
          <div className="text-[#B83E3A]">
            On {workspaceDeleteDate}, this Workspace will be unfortunately
            deleted
          </div>
          <div className="mt-12 flex flex-col gap-6">
            <Button
              color="primary"
              onClick={() => navigateToPage("workspaceSubscription")}
            >
              Select Plan
            </Button>
            <Button
              color="primary"
              onClick={() => navigateToPage("entitySelect", undefined)}
              variant="text"
            >
              Switch Workspace
            </Button>
          </div>
        </>
      );
    }
    return (
      <>
        <Lottie
          options={{ animationData: subscriptionExpiredMemberAnimation }}
          width={300}
          height={180}
        />
        <div className="font-secondary mt-6 mb-5 text-[32px] font-medium leading-[44px]">
          Workspace Subscription expired
        </div>
        <div className="mb-2 text-[#40494D]">
          <span className="font-medium text-[#1764C0]">{entity.name}</span> Free
          Grace period was ended on {graceEndedDate}. To restore access, please
          reach out to Admin of the Workspace
        </div>
        <div className="mt-12">
          <Button
            color="primary"
            onClick={() => navigateToPage("entitySelect")}
            variant="text"
          >
            Switch Workspace
          </Button>
        </div>
      </>
    );
  }, [
    isAdmin,
    entity.name,
    graceEndedDate,
    workspaceDeleteDate,
    navigateToPage,
  ]);

  if (subscriptionInfo.planIsActive) {
    return (
      <Navigate
        to={{
          pathname: `/${entity.id}/chats`,
        }}
      />
    );
  }
  return (
    <MultiSectionLayout
      className="fixed h-screen w-screen"
      header={
        <HeaderBreadcrumbs
          icon={<JuglLogo />}
          items={
            isSelectingSubscription
              ? [
                  {
                    title: "Workspace Subscription",
                    onClick: () => setIsSelectingSubscription(false),
                  },
                  { title: "Update Subscription" },
                ]
              : [{ title: "Workspace Subscription" }]
          }
        />
      }
    >
      <div className="flex h-full items-center justify-center">
        <div className="flex h-full max-w-[600px] flex-col items-center justify-center text-center">
          {$content}
        </div>
      </div>
    </MultiSectionLayout>
  );
};
