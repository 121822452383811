import { useModuleNotificationsUnreadIndicator } from "@jugl-web/domain-resources/module-notifications/hooks/useModuleNotificationsUnreadIndicator";
import { HeadlessUsersList } from "@jugl-web/domain-resources/users/components/HeadlessUsersList";
import { EntityParticipantRole } from "@jugl-web/rest-api";
import {
  Button,
  EmptyListContent,
  MultiSectionLayout,
} from "@jugl-web/ui-components/cross-platform";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { HeaderBreadcrumbs } from "@jugl-web/ui-components/web";
import { ControlBarButton } from "@jugl-web/ui-components/web/ControlBarButton";
import { useSearchInput } from "@jugl-web/utils";
import { NotificationsButton } from "@web-src/components/NotificationsButton";
import PeopleInfoSidebar from "@web-src/features/people/components/PeopleInfoSidebar";
import { useMarkModuleAsRead } from "@web-src/hooks/useMarkModuleAsRead";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { PeopleUserItem } from "../../components/PeopleUserItem";
import { ReactComponent as AddIcon } from "./assets/add-icon.svg";
import { ReactComponent as GroupIcon } from "./assets/group-icon.svg";
import { PeopleNotificationsDialog } from "./components/PeopleNotificationsDialog";

export const PeoplePage: FC = () => {
  const { entityId, entity } = useEntitySelectedProvider();
  const { navigateToPage } = useNavigation();
  const { searchQuery, reset, inputProps } = useSearchInput();
  const [isNotificationsDialogOpen, setIsNotificationsDialogOpen] =
    useState(false);
  const [profileIdToOpen, setProfileIdToOpen] = useState<string | null>(null);
  const {
    isUnread: hasUnreadNotifications,
    markAsRead: markNotificationsAsRead,
  } = useModuleNotificationsUnreadIndicator({
    entityId: entity?.id,
    module: "people",
  });

  useMarkModuleAsRead(entity?.id, "people");
  return (
    <>
      <MultiSectionLayout
        header={
          <>
            <HeaderBreadcrumbs
              items={[{ title: "People" }]}
              rightContent={
                <NotificationsButton
                  hasUnreadIndicator={hasUnreadNotifications}
                  onClick={() => {
                    setIsNotificationsDialogOpen(true);
                    markNotificationsAsRead();
                  }}
                />
              }
              disableShadow
            />
            <div className="flex items-center justify-between bg-[#EEF2F5] py-4 px-8">
              <div className="max-w-[280px]">
                <SearchInput
                  variant="filled"
                  color="white"
                  onReset={reset}
                  {...inputProps}
                  onClear={reset}
                />
              </div>
              <div className="flex items-center gap-6">
                <Link to="teams" relative="path" className="no-underline">
                  <ControlBarButton
                    label="Teams"
                    onClick={() => navigateToPage("peopleTeams")}
                    startSlot={<GroupIcon />}
                  />
                </Link>
                {entity.role === EntityParticipantRole.admin && (
                  <>
                    <Button
                      onClick={() => navigateToPage("workspaceMembers")}
                      iconEnd={<AddIcon />}
                      className="h-10"
                    >
                      Manage members
                    </Button>
                  </>
                )}
              </div>
            </div>
          </>
        }
      >
        <HeadlessUsersList
          fetchParams={{ entityId, searchQuery, sortBy: "name" }}
          virtualized
          userRenderer={(user) => (
            <PeopleUserItem
              entityId={entityId}
              userId={user.id}
              onClick={() => setProfileIdToOpen(user.id)}
              highlightString={searchQuery}
            />
          )}
          emptyContent={<EmptyListContent type="noResults" />}
          errorContent={(retry) => (
            <EmptyListContent type="error" onRefetch={retry} />
          )}
        />
      </MultiSectionLayout>
      <PeopleNotificationsDialog
        isOpen={isNotificationsDialogOpen}
        entityId={entity.id}
        onClose={() => setIsNotificationsDialogOpen(false)}
      />

      {profileIdToOpen && (
        <PeopleInfoSidebar
          isOpen
          userId={profileIdToOpen}
          onClose={() => setProfileIdToOpen(null)}
        />
      )}
    </>
  );
};
