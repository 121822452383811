import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  offset,
  Placement,
  ReferenceType,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  UseHoverProps,
  useInteractions,
  useTransitionStyles,
} from "@floating-ui/react";
import { Portal } from "@headlessui/react";
import { cx } from "@jugl-web/utils";
import { FC, ReactNode, useRef, useState } from "react";

const tooltipDelayToUseHoverDelay: Record<
  TooltipDelay,
  UseHoverProps["delay"]
> = {
  default: { open: 500 },
  none: undefined,
};

type TooltipDelay = "default" | "none";

export interface RenderTooltipTriggerProps {
  ref: (node: ReferenceType | null) => void;
  props: Record<string, unknown>;
}

export interface TooltipProps {
  children: ReactNode;
  isOpen?: boolean;
  placement?: Placement;
  delay?: TooltipDelay;
  isDisabled?: boolean;
  className?: string;
  arrowClassName?: string;
  renderTrigger: (props: RenderTooltipTriggerProps) => JSX.Element;
}

export const Tooltip: FC<TooltipProps> = ({
  children,
  isOpen,
  placement = "bottom",
  delay = "default",
  isDisabled,
  className,
  arrowClassName,
  renderTrigger,
}) => {
  const isControlled = typeof isOpen === "boolean";
  const [isInternallyOpen, setIsInternallyOpen] = useState(false);

  const arrowRef = useRef<SVGSVGElement | null>(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isControlled ? isOpen : isInternallyOpen,
    onOpenChange: isControlled ? undefined : setIsInternallyOpen,
    placement,
    middleware: [
      offset({ mainAxis: 12 }),
      flip(),
      shift(),
      arrow({ element: arrowRef, padding: 8 }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const { isMounted, styles } = useTransitionStyles(context);

  const hover = useHover(context, {
    delay: tooltipDelayToUseHoverDelay[delay],
    move: false,
  });

  const focus = useFocus(context, { enabled: false });

  const dismiss = useDismiss(context, { referencePress: true });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
  ]);

  const referenceProps = getReferenceProps();

  const floatingProps = getFloatingProps({
    onClick: (event) => event.stopPropagation(),
  });

  return (
    <>
      {renderTrigger({ ref: refs.setReference, props: referenceProps })}
      {isMounted && !isDisabled && (
        <Portal>
          <div
            ref={refs.setFloating}
            className={cx(
              "jugl__border-box-component bg-dark/80 z-tooltip max-w-[260px] rounded-lg p-2 text-sm leading-[20px] text-white",
              className
            )}
            style={{ ...floatingStyles, ...styles }}
            {...floatingProps}
          >
            {children}
            <FloatingArrow
              ref={arrowRef}
              context={context}
              className={cx("fill-dark/80", arrowClassName)}
            />
          </div>
        </Portal>
      )}
    </>
  );
};
