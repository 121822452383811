import { useTranslations } from "@jugl-web/utils";
import { ReactNode, useMemo } from "react";
import { ReactComponent as CheckIcon } from "./assets/check.svg";
import { TaskCardProps } from "./TaskCard";

interface TaskCalendarCardAttributes {
  statusLabel: string;
  icon: ReactNode;
  backgroundClassName: string;
  textClassName: string;
}

export const useTaskCalendarCardAttributes = ({
  isCompleted,
  isOverdue,
  dueDate,
}: TaskCardProps) => {
  const { t } = useTranslations();

  const cardStylesByStatus = useMemo(
    () =>
      ({
        "to-do": {
          statusLabel: t({
            id: "tasks-page.to-do-status",
            defaultMessage: "To-do",
          }),
          icon: (
            <div className="mx-0.5 h-1.5 w-1.5 rounded-full bg-[#5560DC]" />
          ),
          backgroundClassName: "bg-[#E3ECFD] hover:bg-[#DCE7FC]",
          textClassName: "text-[#2955A9]",
        },
        "no-due-date": {
          statusLabel: t({
            id: "tasks-page.to-do-status",
            defaultMessage: "To-do",
          }),
          icon: (
            <div className="mx-0.5 h-1.5 w-1.5 rounded-full bg-[#5560DC]" />
          ),
          backgroundClassName: "bg-grey-200 hover:bg-dark-100",
          textClassName: "text-[#2955A9]",
        },
        completed: {
          statusLabel: t({
            id: "tasks-page.completed-status",
            defaultMessage: "Completed",
          }),
          icon: <CheckIcon />,
          backgroundClassName: "bg-[#F1F5F1] hover:bg-[#EBF2EB]",
          textClassName: "text-users-user7",
        },
        overdue: {
          statusLabel: t({
            id: "tasks-page.overdue",
            defaultMessage: "Overdue",
          }),
          icon: (
            <div className="bg-gradients-danger mx-0.5 h-1.5 w-1.5 rounded-full" />
          ),
          backgroundClassName: "bg-tertiary-50 hover:bg-tertiary-100",
          textClassName: "text-tertiary-700",
        },
      } satisfies Record<string, TaskCalendarCardAttributes>),
    [t]
  );

  const statusBasedStyles = useMemo(() => {
    if (isCompleted) {
      return cardStylesByStatus.completed;
    }

    if (isOverdue) {
      return cardStylesByStatus.overdue;
    }

    if (!dueDate) {
      return cardStylesByStatus["no-due-date"];
    }

    return cardStylesByStatus["to-do"];
  }, [cardStylesByStatus, dueDate, isCompleted, isOverdue]);

  return statusBasedStyles;
};
