import { Button } from "@jugl-web/ui-components/cross-platform";
import {
  DatePicker,
  DatePickerHandle,
} from "@jugl-web/ui-components/cross-platform/DatePicker";
import { cx, useTranslations } from "@jugl-web/utils";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { endOfDay, format, isBefore } from "date-fns";
import { FC, useEffect, useRef, useState } from "react";
import { Popover, PopoverProps } from "../../cross-platform/Popover";
import { DueTimeInput } from "./components/DueTimeInput";

export interface DueDatePickerPopoverProps
  extends Pick<PopoverProps, "renderTrigger" | "placement"> {
  initialDate?: Date;
  isDateEditable?: boolean;
  className?: string;
  onSubmit: (date: Date | null) => void;
}

export const DueDatePickerPopover: FC<DueDatePickerPopoverProps> = ({
  initialDate,
  isDateEditable = true,
  className,
  placement,
  renderTrigger,
  onSubmit,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    initialDate
  );

  const datePickerRef = useRef<DatePickerHandle | null>(null);

  const { t } = useTranslations();
  const { dateLocale } = useLanguage();

  const handleDaySelect = (date: Date) => {
    setSelectedDate((previousDate) => {
      if (previousDate) {
        const now = new Date();
        const updatedDate = new Date(date);

        updatedDate.setHours(
          previousDate.getHours(),
          previousDate.getMinutes()
        );

        // Check if the current time can be set to the selected date (if it's not in the past)
        if (!isBefore(updatedDate, now)) {
          return updatedDate;
        }
      }

      return date;
    });
  };

  const handleClear = () => {
    if (!selectedDate) {
      return;
    }

    if (isDateEditable) {
      datePickerRef.current?.clear();
      setSelectedDate(undefined);
      return;
    }

    setSelectedDate(endOfDay(selectedDate));
  };

  useEffect(() => {
    setSelectedDate(initialDate);
  }, [initialDate]);

  return (
    <Popover
      renderTrigger={renderTrigger}
      placement={placement}
      className={cx("w-[343px] px-4 pt-4 pb-8", className)}
      onMount={() => setSelectedDate(initialDate)}
    >
      {({ onClose }) => (
        <div className="flex flex-col gap-8">
          {isDateEditable && (
            <DatePicker
              ref={datePickerRef}
              initialDate={selectedDate}
              minDate={new Date()}
              dateTransformation="endOfDay"
              onDaySelect={handleDaySelect}
            />
          )}
          <div className="flex flex-col gap-2">
            <span className="font-secondary text-dark text-sm font-semibold">
              {t({ id: "tasks-page.due-to", defaultMessage: "Due to" })}
            </span>
            <div className="flex items-center gap-2">
              <div
                className={cx(
                  "flex h-10 shrink-0 cursor-default items-center rounded-md px-4 transition-colors",
                  selectedDate
                    ? "bg-primary-50 text-dark-800"
                    : "bg-grey-200 text-dark-600"
                )}
              >
                {selectedDate
                  ? format(selectedDate, "dd/MM/yyyy", { locale: dateLocale })
                  : t({
                      id: "tasks-page.no-due-date",
                      defaultMessage: "No due date",
                    })}
              </div>
              <DueTimeInput
                selectedDate={selectedDate}
                onDateChange={setSelectedDate}
                onClear={handleClear}
              />
            </div>
          </div>
          <div className="flex items-center gap-3">
            <Button
              fullWidth
              variant="contained"
              color="grey"
              className="h-10"
              onClick={handleClear}
            >
              {t({ id: "common.clear", defaultMessage: "Clear" })}
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className="h-10"
              onClick={() => {
                onSubmit(selectedDate || null);
                onClose();
              }}
            >
              {t({ id: "common.save", defaultMessage: "Save" })}
            </Button>
          </div>
        </div>
      )}
    </Popover>
  );
};
