import { getImagePathForSharedLibrary } from "@jugl-web/utils";
import imageFileSrc from "./assets/image-file.png";
import docFileSrc from "./assets/doc-file.png";
import excelFileSrc from "./assets/excel-file.png";
import pdfFileSrc from "./assets/pdf-file.png";
import textFileSrc from "./assets/text-file.png";
import unknownFileSrc from "./assets/unknown-file.png";
import zipFileSrc from "./assets/zip-file.png";
import audioFileSrc from "./assets/audio-file.png";
import videoFileSrc from "./assets/video-file.png";

type SupportedFileType =
  | "image"
  | "audio"
  | "video"
  | "pdf"
  | "document"
  | "archive"
  | "sheet"
  | "text";

type FileType = SupportedFileType | "unknown";

const fileExtensionToType: Record<string, SupportedFileType> = {
  // image
  jpg: "image",
  jpeg: "image",
  png: "image",
  svg: "image",
  webp: "image",
  gif: "image",
  bmp: "image",
  tiff: "image",

  // audio
  mp3: "audio",
  wav: "audio",
  flac: "audio",
  aac: "audio",
  ogg: "audio",
  wma: "audio",
  m4a: "audio",

  // video
  mp4: "video",
  avi: "video",
  mov: "video",
  mkv: "video",
  wmv: "video",

  // pdf
  pdf: "pdf",

  // document
  doc: "document",
  docx: "document",
  odt: "document",

  // archive
  zip: "archive",
  rar: "archive",
  "7z": "archive",

  // sheet
  xls: "sheet",
  xlsx: "sheet",
  ods: "sheet",

  // text
  txt: "text",
};

const fileTypeToImageSrc: Partial<Record<SupportedFileType, unknown>> = {
  image: imageFileSrc,
  audio: audioFileSrc,
  video: videoFileSrc,
  pdf: pdfFileSrc,
  document: docFileSrc,
  archive: zipFileSrc,
  sheet: excelFileSrc,
  text: textFileSrc,
};

export const extractFileNameParts = (fileName: string) => {
  const parts = fileName.split(".");
  const name = parts.slice(0, -1).join(".");

  if (parts.length === 1) {
    return { name: fileName, extension: "" };
  }

  return {
    name,
    extension: parts[parts.length - 1].toLowerCase(),
  };
};

export const getFileType = (filePath: string): FileType =>
  fileExtensionToType[extractFileNameParts(filePath).extension] || "unknown";

export const getFileImagePath = (filePath: string): string => {
  const fileType = getFileType(filePath);
  const fileImageSrc =
    fileTypeToImageSrc[fileType as SupportedFileType] || unknownFileSrc;

  return getImagePathForSharedLibrary(fileImageSrc);
};
