import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import { cx, isValidEmail, useTranslations } from "@jugl-web/utils";
import React from "react";
import {
  Control,
  FieldErrors,
  UseFormRegister,
  useFieldArray,
} from "react-hook-form";
import { ReactComponent as PlusIcon } from "./assets/plus.svg";
import { ReactComponent as DeleteIcon } from "./assets/delete.svg";
import { ScheduledReportsFormValues } from "../../types";

export const EmailsInput: React.FC<{
  control: Control<ScheduledReportsFormValues>;
  register: UseFormRegister<ScheduledReportsFormValues>;
  errors: FieldErrors<ScheduledReportsFormValues>;
}> = ({ control, register, errors }) => {
  const { t } = useTranslations();

  const {
    fields: emails,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "recipients",
    rules: { required: true },
  });

  return (
    <InteractiveContainer
      className={cx(
        "flex min-h-[32px] w-full flex-wrap items-center gap-2.5",
        emails.length && "cursor-default"
      )}
      onClick={() =>
        !emails.length && append({ value: "" }, { shouldFocus: true })
      }
    >
      {emails.map((email, idx) => (
        <div className="relative shrink-0" key={email.id}>
          <input
            {...register(`recipients.${idx}.value`, {
              validate: (value) =>
                typeof value === "string" ? isValidEmail(value) : true,
              onBlur: (event) =>
                event.target.value.trim().length === 0 && remove(idx),
            })}
            className={cx(
              "bg-dark-100 focus:bg-grey-200 hover:bg-dark-200 text-dark rounded-lg border-none p-2 pr-8 font-[Roboto] text-sm outline-none transition-colors placeholder:text-[#BDBDBD]",
              errors.recipients?.[idx] &&
                "bg-tertiary-100 hover:bg-tertiary-200"
            )}
            placeholder={t({
              id: "scheduled-reports-page.enter-email",
              defaultMessage: "Enter Email",
            })}
          />
          <InteractiveContainer
            className="hover:bg-grey-100 absolute top-1/2 right-2 flex h-4 w-4 -translate-y-1/2 items-center justify-center rounded-full bg-white transition-colors"
            onClick={() => remove(idx)}
          >
            <DeleteIcon />
          </InteractiveContainer>
        </div>
      ))}
      {emails.length && !errors.recipients ? (
        <InteractiveContainer
          className="bg-dark-100 hover:bg-dark-200 flex h-7 w-7 shrink-0 items-center justify-center rounded-full transition-colors"
          onClick={() => append({ value: "" }, { shouldFocus: true })}
        >
          <PlusIcon />
        </InteractiveContainer>
      ) : null}
    </InteractiveContainer>
  );
};
