import { DriveModuleLog } from "@jugl-web/rest-api";
import { addSearchParamsToURL, isoToLocalDate } from "@jugl-web/utils";
import { useCallback } from "react";

type DriveObjectType = DriveModuleLog["drive"]["type"];

const objectTypeToLabel: Record<DriveObjectType, string> = {
  dir: "folder",
  file: "file",
};

export const useDriveLogParser = () => {
  const driveFolderCreatedMessageRenderer = useCallback(
    (username: string, folderName: string) => (
      <>
        <b>{username}</b> has created a new folder <b>{folderName}</b>
      </>
    ),
    []
  );

  const driveFileAddedMessageRenderer = useCallback(
    (username: string, fileName: string, folderName: string) => (
      <>
        <b>{username}</b> has added file <b>{fileName}</b> to folder{" "}
        <b>{folderName}</b>
      </>
    ),
    []
  );

  const driveObjectRenamedMessageRenderer = useCallback(
    (
      username: string,
      objectType: DriveObjectType,
      oldObjectName: string,
      newObjectName: string
    ) => (
      <>
        <b>{username}</b> has renamed {objectTypeToLabel[objectType]}{" "}
        <b>{oldObjectName}</b> to <b>{newObjectName}</b>
      </>
    ),
    []
  );

  const driveObjectUpdatedMessageRenderer = useCallback(
    (username: string, objectType: DriveObjectType, objectName: string) => (
      <>
        <b>{username}</b> has updated {objectTypeToLabel[objectType]}{" "}
        <b>{objectName}</b>
      </>
    ),
    []
  );

  const driveObjectDeletedMessageRenderer = useCallback(
    (username: string, objectType: DriveObjectType, objectName: string) => (
      <>
        <b>{username}</b> has deleted {objectTypeToLabel[objectType]}{" "}
        <b>{objectName}</b>
      </>
    ),
    []
  );

  const driveMessageRenderer = useCallback(
    (username: string, log: DriveModuleLog) => {
      if (log.action === "added" && log.drive.type === "dir") {
        return driveFolderCreatedMessageRenderer(username, log.drive.name);
      }

      if (log.action === "added" && log.drive.type === "file") {
        return driveFileAddedMessageRenderer(
          username,
          log.drive.name,
          log.drive.parent_dir_name
        );
      }

      if (log.action === "renamed") {
        return driveObjectRenamedMessageRenderer(
          username,
          log.drive.type,
          log.changes.old_name || "",
          log.drive.name
        );
      }

      if (log.action === "updated") {
        return driveObjectUpdatedMessageRenderer(
          username,
          log.drive.type,
          log.drive.name
        );
      }

      if (log.action === "deleted") {
        return driveObjectDeletedMessageRenderer(
          username,
          log.drive.type,
          log.drive.name
        );
      }

      throw new Error(`Unsupported drive log action: ${log.action}`);
    },
    [
      driveFileAddedMessageRenderer,
      driveFolderCreatedMessageRenderer,
      driveObjectDeletedMessageRenderer,
      driveObjectRenamedMessageRenderer,
      driveObjectUpdatedMessageRenderer,
    ]
  );

  const parseDriveLog = useCallback(
    (log: DriveModuleLog) => {
      const username = [log.action_by.first_name, log.action_by.last_name].join(
        " "
      );

      return {
        id: log.id,
        user: {
          name: username,
          avatarUrl: log.action_by.img
            ? addSearchParamsToURL(log.action_by.img, {
                t: log.action_by.updated_at,
              })
            : null,
        },
        message: driveMessageRenderer(username, log),
        date: new Date(isoToLocalDate(log.inserted_at)),
      };
    },
    [driveMessageRenderer]
  );

  return { parseDriveLog };
};
