import { useRestApiProvider } from "@jugl-web/rest-api";
import { ScreenTransitionWrapper } from "@jugl-web/ui-components/cross-platform/ScreenTransitionWrapper";
import { LoadingAnimation } from "@jugl-web/ui-components/cross-platform/content/LoadingAnimation";
import { DRAWER_HEADER_HEIGHT_PX } from "@jugl-web/ui-components/web/DrawerHeader";
import { useScreenTransitionManager } from "@jugl-web/utils/utils/ScreenTransitionManager";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { TaskFormDialog } from "@web-src/features/tasks/TaskFormDialog";
import { FC, useMemo } from "react";
import {
  TemplatePreviewDialogProps,
  TemplatePreviewDialogScreenToParametersMap,
} from "../../types";
import { TaskCreationScreen } from "../TaskCreationScreen";
import { TemplatePreviewScreen } from "../TemplatePreviewScreen";

export const TemplatePreviewDialogContent: FC<TemplatePreviewDialogProps> = ({
  isOpen,
  templateId,
  entityId,
  meId,
  onClose,
}) => {
  const { tasksTemplatesApi } = useRestApiProvider();

  const { data: template, isLoading } = tasksTemplatesApi.useGetTemplateQuery(
    isOpen && templateId ? { entityId, templateId } : skipToken
  );

  const { screen, renderContent } =
    useScreenTransitionManager<TemplatePreviewDialogScreenToParametersMap>();

  const content = useMemo(() => {
    if (!template || isLoading) {
      return (
        <TaskFormDialog.Content className="flex items-center justify-center">
          <LoadingAnimation />
        </TaskFormDialog.Content>
      );
    }

    return renderContent({
      templatePreview: (
        <TemplatePreviewScreen
          template={template}
          entityId={entityId}
          meId={meId}
        />
      ),
      taskCreation: (
        <TaskCreationScreen
          template={template}
          entityId={entityId}
          meId={meId}
          onDialogClose={onClose}
        />
      ),
    });
  }, [entityId, isLoading, meId, onClose, renderContent, template]);

  return (
    <ScreenTransitionWrapper
      screenName={screen.name}
      style={{ height: `calc(100% - ${DRAWER_HEADER_HEIGHT_PX}px)` }}
    >
      {content}
    </ScreenTransitionWrapper>
  );
};
