import { EntityInvitationDto } from "./EntityInvitationDto";
import { EntityInvitationModel } from "./EntityInvitationModel";

export const transformEntityInvitationDtoToModel = (
  dto: EntityInvitationDto
): EntityInvitationModel => ({
  id: dto.id,
  entityId: dto.entity.id,
  entityImg: dto.entity.display_pic,
  entityDescription: dto.entity.desc,
  entityName: dto.entity.name,
  inviterName: [dto.user.first_name, dto.user.last_name]
    .filter((item) => item)
    .join(" "),
  inviterAvatar: dto.user.img,
  invitationDate: dto.inserted_at,
});
