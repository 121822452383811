import { ChangeEvent, useCallback, useState } from "react";
import { useDebouncedCallback } from "./useDebouncedCallback";

interface UseSearchInputOptions {
  defaultValue?: string;
  debounce?: number;
  onSearch?: (searchQuery: string) => void | Promise<void>;
}

const DEFAULT_DEBOUNCE_TIME = 300;

export const useSearchInput = ({
  defaultValue = "",
  debounce = DEFAULT_DEBOUNCE_TIME,
  onSearch,
}: UseSearchInputOptions = {}) => {
  const [inputValue, setInputValue] = useState(defaultValue);
  const [searchQuery, setSearchQuery] = useState(defaultValue);

  const search = useCallback(
    (query: string) => {
      setSearchQuery(query);
      onSearch?.(query);
    },
    [onSearch]
  );

  const debouncedSearch = useDebouncedCallback(search, debounce);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setInputValue(value);
    debouncedSearch(value.trim());
  };

  const inputProps = {
    value: inputValue,
    onChange,
  };

  const hasSearchQuery = searchQuery.length > 0;
  const hasInputValue = inputValue.length > 0;

  const reset = useCallback(() => {
    setInputValue("");
    debouncedSearch("");
  }, [debouncedSearch]);

  return {
    searchQuery,
    hasSearchQuery,
    hasInputValue,
    inputProps,
    reset,
  };
};
