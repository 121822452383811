import React, { useState, useMemo, useCallback } from "react";
import {
  Event,
  LocationEventsSummary,
} from "@jugl-web/rest-api/location/types";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { locationApi } from "@web-src/features/api/createApi";
import Loader from "@web-src/components/Loader";
import { PaginatedResponse } from "@web-src/features/api/types";
import { Text } from "@jugl-web/ui-components/cross-platform";
import { cx, useTranslations } from "@jugl-web/utils";
import { workdayDurationCalculator } from "@web-src/utils/workdayDurationCalculator";
import { useParams } from "react-router-dom";
import { ReactComponent as PinkStoperIcon } from "./assets/pink-stoper.svg";
import NoLocations from "../NoLocations";
import TrackingCard from "./components/TrackingCard";
import TrackingEventDetails from "./components/TrackingEventDetails";

export const EventDetailsList: React.FC<{
  list: Event[] | undefined;
  summaryData: PaginatedResponse<LocationEventsSummary> | undefined;
  isFetching: boolean;
  timezone: string;
}> = ({ list, summaryData, isFetching, timezone }) => {
  const [isEventDetailsOpen, setIsEventDetailsOpen] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<Event>();
  const { t } = useTranslations();
  const params = useParams();
  const toggleEventDetails = useCallback(
    (event: Event) => {
      setIsEventDetailsOpen(true);
      setSelectedEvent(event);
    },
    [setIsEventDetailsOpen, setSelectedEvent]
  );
  const closeEventDetails = useCallback(() => {
    setIsEventDetailsOpen(false);
  }, []);
  const totalTimeStr = useMemo(() => {
    const durationInSeconds = workdayDurationCalculator(
      summaryData?.data[0]?.events || [],
      params.date || "",
      timezone
    );
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    return `${hours}${t({
      id: "common.hour-short",
      defaultMessage: "h",
    })}, ${minutes}${t({
      id: "common.minute-short",
      defaultMessage: "min",
    })}`;
  }, [params.date, summaryData, t, timezone]);

  const locationsToGeolookup = useMemo(
    () =>
      list?.reduce((accumulator: { lat: number; lng: number }[], event) => {
        const locPts = [
          {
            lat: event.loc && event.loc[0]?.loc_pts.lat,
            lng: event.loc && event.loc[0]?.loc_pts.lon,
          },
          {
            lat: event.loc && event.loc[event.loc.length - 1]?.loc_pts.lat,
            lng: event.loc && event.loc[event.loc.length - 1]?.loc_pts.lon,
          },
        ];
        return [...accumulator, ...locPts];
      }, []),
    [list]
  );
  const { data: geocodeData, isLoading: isAddressLoading } =
    locationApi.useGeocodeLookupQuery(
      locationsToGeolookup?.length
        ? {
            cordinates: locationsToGeolookup,
          }
        : skipToken
    );

  const getAddressMap = useMemo(() => {
    const addressMap: { [key: string]: string } = {};
    geocodeData?.forEach((data) => {
      addressMap[`${data.cordinates.lat}${data.cordinates.lng}`] =
        data.formatted_address;
    });
    return addressMap;
  }, [geocodeData]);
  return (
    <div className="relative flex h-full w-full flex-col justify-start">
      <div className="mb-2 flex h-[88px] w-full items-center bg-white px-8">
        <div className=" flex w-full place-content-center items-center justify-between">
          <span className="flex items-center gap-2">
            <PinkStoperIcon />
            <Text variant="body1">
              {t({
                id: "location-page.daily-total",
                defaultMessage: "Daily total",
              })}
              :
            </Text>
          </span>
          <Text variant="h4">{totalTimeStr}</Text>
        </div>
      </div>
      {!isFetching && (
        <div
          className={cx("w-full flex-col gap-1 overflow-scroll", {
            flex: list && list.length,
            hidden: !list || !list.length,
          })}
        >
          {!isFetching &&
            list?.map((event) => (
              <TrackingCard
                addressMap={getAddressMap}
                key={event.event_id}
                event={event}
                toggleEventDetails={() => toggleEventDetails(event)}
                timezone={timezone}
                isAddressLoading={isAddressLoading}
              />
            ))}
          {isEventDetailsOpen && selectedEvent && (
            <TrackingEventDetails
              key={selectedEvent?.event_id}
              onClose={closeEventDetails}
              event={selectedEvent}
            />
          )}
        </div>
      )}

      {isFetching && <Loader />}
      {!isFetching && !list?.length && <NoLocations />}
    </div>
  );
};
