import { SideImageLayout } from "@jugl-web/ui-components/web/layouts";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import {
  OTPVerificationForm,
  PageLoaderFull,
} from "@jugl-web/ui-components/cross-platform";
import { FC, useCallback, useState } from "react";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useToast } from "@jugl-web/utils";
import { useEntitySelectedProvider } from "../../providers/EntityProvider";

export const EntityVerifyEmailPage: FC = () => {
  const { navigateToPage } = useNavigation();
  const { entity, verifyBillingEmail } = useEntitySelectedProvider();
  const { entitiesApi } = useRestApiProvider();
  const [verifyEmail, { isLoading }] = entitiesApi.useVerifyEmailMutation();
  const [otpError, setOtpError] = useState(false);
  const { toast } = useToast({ variant: "web" });

  const verifyOtp = useCallback(
    async (otp: string) => {
      if (!entity.billingEmail) {
        return;
      }
      setOtpError(false);

      const response = await verifyEmail({
        entityId: entity.id,
        data: { billing_email: entity.billingEmail, otp },
      });
      if ("error" in response && response.error) {
        setOtpError(true);
        return;
      }
      toast("Email verified successfully");
      navigateToPage("chatsChats");
    },
    [entity.billingEmail, entity.id, navigateToPage, toast, verifyEmail]
  );

  return (
    <>
      <SideImageLayout onBackClick={() => navigateToPage("chatsChats")}>
        <OTPVerificationForm
          error={otpError ? "Invalid OTP" : undefined}
          title="Enter Code Sent to Your Email"
          subTitle={
            <>
              We sent it to the Email{" "}
              <span className="font-semibold text-blue-500">
                {entity.billingEmail}
              </span>
            </>
          }
          onSubmit={verifyOtp}
          onResend={() =>
            entity.billingEmail
              ? verifyBillingEmail(entity.id, entity.billingEmail)
              : null
          }
        />
      </SideImageLayout>
      <PageLoaderFull isTransparent isActive={isLoading} />
    </>
  );
};
