import { HookOutOfContextError } from "@jugl-web/utils";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { UserCPanelModel } from "@jugl-web/rest-api";
import { Subject } from "rxjs";

type OpenedSideBar = {
  user?: UserCPanelModel;
  sideBar: "loginActivity" | "documents" | "invite" | "userProfile";
};
type OpenedAlert = {
  alert: "copyInvitation";
};

interface CPanelPageContextValue {
  searchQuery: string;
  selectedUsers: UserCPanelModel[];
  setSearchQuery: Dispatch<SetStateAction<string>>;
  toggleUserSelectState: (selectedUser: UserCPanelModel) => void;
  clearSelectedUsers: () => void;

  openedSideBar: OpenedSideBar | null;
  openedAlert: OpenedAlert | null;
  setOpenedSideBar: (sideBar: OpenedSideBar | null) => void;
  setOpenedAlert: (alert: OpenedAlert | null) => void;

  updateUserDocCountDelta: (userId: string, delta: number) => void;
  userIdToDocCountDelta: Record<string, number>;
  resetUserDocCountDelta: () => void;

  refetchUsers$: Subject<void>;
  updateUser$: Subject<{ id: string; data: Partial<UserCPanelModel> }>;
}

const CPanelPageContext = createContext<CPanelPageContextValue | null>(null);

export const CPanelPageProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUsers, setSelectedUsers] = useState<UserCPanelModel[]>([]);
  const [openedSideBar, setOpenedSideBar] =
    useState<CPanelPageContextValue["openedSideBar"]>(null);
  const [openedAlert, setOpenedAlert] =
    useState<CPanelPageContextValue["openedAlert"]>(null);
  const [userIdToDocCountDelta, setUserIdToDocCountDelta] = useState<
    Record<string, number>
  >({});

  const updateUserDocCountDelta = useCallback(
    (userId: string, delta: number) => {
      setUserIdToDocCountDelta((prev) => ({
        ...prev,
        [userId]: (prev[userId] || 0) + delta,
      }));
    },
    []
  );

  const resetUserDocCountDelta = useCallback(() => {
    setUserIdToDocCountDelta({});
  }, []);

  const toggleUserSelectState = useCallback(
    (selectedUser: UserCPanelModel) =>
      setSelectedUsers((prev) => {
        const isSelected = prev.some(
          (user) => user.entity_rel_id === selectedUser.entity_rel_id
        );
        return isSelected
          ? prev.filter(
              (user) => user.entity_rel_id !== selectedUser.entity_rel_id
            )
          : [...prev, selectedUser];
      }),
    []
  );

  const clearSelectedUsers = useCallback(() => setSelectedUsers([]), []);

  const refetchUsers$ = useMemo(() => new Subject<void>(), []);

  const updateUser$ = useMemo(
    () => new Subject<{ id: string; data: Partial<UserCPanelModel> }>(),
    []
  );

  const contextValue = useMemo<CPanelPageContextValue>(
    () => ({
      searchQuery,
      selectedUsers,
      setSearchQuery,
      toggleUserSelectState,
      clearSelectedUsers,
      openedSideBar,
      openedAlert,
      setOpenedSideBar,
      setOpenedAlert,
      userIdToDocCountDelta,
      updateUserDocCountDelta,
      resetUserDocCountDelta,
      refetchUsers$,
      updateUser$,
    }),
    [
      searchQuery,
      selectedUsers,
      setSearchQuery,
      toggleUserSelectState,
      clearSelectedUsers,
      openedSideBar,
      openedAlert,
      setOpenedSideBar,
      setOpenedAlert,
      userIdToDocCountDelta,
      updateUserDocCountDelta,
      resetUserDocCountDelta,
      refetchUsers$,
      updateUser$,
    ]
  );
  return (
    <CPanelPageContext.Provider value={contextValue}>
      {children}
    </CPanelPageContext.Provider>
  );
};

export const useCPanelPageContext = () => {
  const context = useContext(CPanelPageContext);

  if (!context) {
    throw new HookOutOfContextError(
      "useCPanelPageContext",
      "CPanelPageContext"
    );
  }

  return context;
};
