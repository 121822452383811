import { useRestApiProvider } from "@jugl-web/rest-api";
import { TaskTemplateFolder } from "@jugl-web/rest-api/tasks-templates";
import {
  ListBoxItem,
  ListBoxProps,
} from "@jugl-web/ui-components/cross-platform/ListBox";
import { ResourcePickerDrawer } from "@jugl-web/ui-components/mobile/ResourcePickerDrawer";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { useAppVariant, useTranslations } from "@jugl-web/utils";
import { FC, useMemo, useState } from "react";
import { FieldComponentProps } from "../../types";
import {
  TaskPropertyToggleButton,
  TaskPropertyToggleButtonProps,
} from "../TaskPropertyToggleButton";

interface SaveTaskAsTemplateFieldProps
  extends FieldComponentProps<"saveTaskAsTemplate"> {
  entityId: string;
}

export const SaveTaskAsTemplateField: FC<SaveTaskAsTemplateFieldProps> = ({
  entityId,
  templateFolder,
  onTemplateFolderChange,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { t } = useTranslations();
  const { isMobile } = useAppVariant();

  const { tasksTemplatesApi } = useRestApiProvider();

  const { data: templateFolders } =
    tasksTemplatesApi.useGetTemplateFoldersQuery({ entityId });

  const foldersAsListItems = useMemo<ListBoxItem<TaskTemplateFolder>[]>(
    () =>
      (templateFolders || []).map((folder) => ({
        id: folder.id,
        value: folder,
      })),
    [templateFolders]
  );

  const baseButtonLabel = t({
    id: "tasks-page.save-task-as-template",
    defaultMessage: "Save task as template",
  });

  const commonButtonProps: TaskPropertyToggleButtonProps = {
    label: baseButtonLabel.concat(
      templateFolder ? `: ${templateFolder.name} ${templateFolder.img}` : ""
    ),
    isChecked: !!templateFolder,
  };

  const commonResourcePickerProps = {
    title: t({
      id: "tasks-page.save-template-to",
      defaultMessage: "Save template to...",
    }),
    items: foldersAsListItems,
    selectionBehavior: { mode: "single", canToggle: true },
    hasSearch: true,
    defaultSelectedIds: templateFolder ? [templateFolder.id] : [],
    maxVisibleItems: 6,
    itemSize: "md",
    spaceBetweenItems: "normal",
    renderLabel: (item) => item.value.name,
    renderStartIcon: (item) => (
      <div
        className="flex h-8 w-8 items-center justify-center rounded-full border-[3px] border-solid"
        style={{ borderColor: item.value.color }}
      >
        {item.value.img}
      </div>
    ),
  } satisfies ListBoxProps<TaskTemplateFolder> & { title: string };

  if (isMobile) {
    return (
      <>
        <TaskPropertyToggleButton
          onClick={() => setIsDialogOpen(true)}
          {...commonButtonProps}
        />
        <ResourcePickerDrawer
          isOpen={isDialogOpen}
          onSubmit={(_, [item]) => {
            if (!item) {
              onTemplateFolderChange(null);
            } else if (item.value) {
              onTemplateFolderChange(item.value);
            }
          }}
          onClose={() => setIsDialogOpen(false)}
          {...commonResourcePickerProps}
        />
      </>
    );
  }

  return (
    <ResourcePickerPopover
      className="w-[375px]"
      placement="bottom-start"
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          ref={triggerRef}
          as={TaskPropertyToggleButton}
          {...commonButtonProps}
        />
      )}
      onSelect={({ item, isSelected, onClose }) => {
        onTemplateFolderChange(isSelected ? item.value : null);
        onClose();
      }}
      {...commonResourcePickerProps}
    />
  );
};
