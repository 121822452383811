import Box from "@mui/material/Box";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import {
  AudioRecorderError,
  useAudioRecorder,
} from "@web-src/features/app/hooks/useAudioRecorder";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import StopOutlinedIcon from "@mui/icons-material/StopOutlined";
import ChatInputButton from "@web-src/features/chats/components/ChatInput/ChatInputButton";
import { useTranslations } from "@jugl-web/utils";
import AudioPlayer from "./ChatAudioPlayer";
import RecordingVisualizer from "./RecordingVisualizer";

const maxRecordingTime = 300;

const ChatAudioRecorder: React.FC<{
  onCancel?: () => void;
  onSend?: (file: File) => void;
  loading?: boolean;
}> = ({ onCancel, onSend, loading }) => {
  const { t } = useTranslations();
  const $vizualizer = useRef<{
    requestUpdate: (currentVolume: number) => void;
  } | null>(null);
  const volumeLevelListener = useCallback(
    (level: number) => $vizualizer?.current?.requestUpdate(level),
    []
  );
  const { stop, lastRecordedFile, error, timeInSeconds, timeStr } =
    useAudioRecorder({
      maxTimeInSeconds: maxRecordingTime,
      autoStart: true,
      volumeLevelListener,
    });
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (error === AudioRecorderError.noPermissions) {
      enqueueSnackbar(
        t({
          id: "feedback.no-recording-permissions",
          defaultMessage: "No Audio Recording permissions",
        }),
        {
          variant: "error",
        }
      );
      onCancel?.();
    }
  }, [error, onCancel, enqueueSnackbar, t]);
  const recorderProgress = useMemo(
    () => Math.round((timeInSeconds / maxRecordingTime) * 100),
    [timeInSeconds]
  );
  const handleSendClick = useCallback(() => {
    if (!lastRecordedFile) {
      return;
    }
    onSend?.(lastRecordedFile.file);
  }, [lastRecordedFile, onSend]);
  return (
    <Box>
      <Stack
        direction="row"
        gap="24px"
        alignItems="center"
        sx={{ width: "100%", height: "46px" }}
      >
        {lastRecordedFile ? (
          <>
            <IconButton
              color="error"
              sx={{
                bgcolor: "juglTertiary.100",
                height: "40px",
                width: "40px",
              }}
              onClick={onCancel}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
            <Box sx={{ flex: 1 }}>
              <AudioPlayer url={lastRecordedFile.url} />
            </Box>
            <ChatInputButton
              onClick={handleSendClick}
              loading={loading}
              disabled={loading}
            />
          </>
        ) : (
          <>
            <RecordingVisualizer ref={$vizualizer} />
            <Stack direction="row" alignItems="center" sx={{ flex: 1 }}>
              <Typography
                color="juglGrey.900"
                fontWeight="500"
                fontSize="14px"
                sx={{ mr: "16px" }}
              >
                {timeStr}
              </Typography>
              <Box
                sx={{
                  flex: 1,
                  height: "4px",
                  bgcolor: "juglGrey.200",
                  borderRadius: "4px",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    height: "4px",
                    bgcolor: "juglPrimary.500",
                    width: `${recorderProgress}px`,
                  }}
                />
              </Box>
            </Stack>
            <IconButton
              color="default"
              sx={{
                bgcolor: "juglGrey.200",
                height: "40px",
                width: "40px",
              }}
              onClick={stop}
            >
              <StopOutlinedIcon />
            </IconButton>
          </>
        )}
      </Stack>
    </Box>
  );
};

export default ChatAudioRecorder;
