import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import React, { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { environment } from "@web-src/environments/environment";
import { useSelector } from "react-redux";
import { selectUserId } from "@web-src/features/auth/authSlice";
import useEntity from "@web-src/features/app/hooks/useEntity";

const isSentryEnabled = () =>
  !!environment.sentryWhitelistHosts.find((item) =>
    // eslint-disable-next-line no-restricted-globals
    item instanceof RegExp ? item.test(location.host) : item === location.host
  );

if (isSentryEnabled()) {
  Sentry.init({
    dsn: environment.sentryDsn,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      // NOTE: temporarily disabled
      // Sentry.replayIntegration({
      //   maskAllText: false,
      //   blockAllMedia: false,
      // }),
    ],

    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

export const SentryUserSetter: React.FC = () => {
  const meId = useSelector(selectUserId);
  const { entity } = useEntity();

  useEffect(() => {
    if (isSentryEnabled() && entity?.id && meId) {
      Sentry.setUser({
        id: meId,
        entityId: entity.id,
      });
    }
  }, [entity?.id, meId]);

  return null;
};
