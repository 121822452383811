import { SidebarDrawer } from "@jugl-web/ui-components/web";
import { useTranslations } from "@jugl-web/utils";
import { Button } from "@jugl-web/ui-components/cross-platform";
import React, { useMemo, useState } from "react";
import useConversations from "@web-src/features/chats/hooks/useConversations";
import { UserEntityPreferences } from "@web-src/modules/settings/pages/SettingsPage/types";
import { ChatType } from "@web-src/features/chats/types";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import Lottie from "react-lottie";
import emptyAnimation from "./assets/muted-chats.json";
import { MutedChatsList } from "./components/MutedChatsList";
import { AddMutedChatsSidebar } from "./components/AddMutedChatsSidebar";

export const MutedChatsDetails: React.FC<{
  isOpen: boolean;
  onRequestClose: () => void;
  userEntityPreferences: UserEntityPreferences;
}> = ({ isOpen, onRequestClose, userEntityPreferences }) => {
  const { t } = useTranslations();
  const { conversations } = useConversations();
  const [searchQuery, setSearchQuery] = useState("");

  const mutedPrivateConversations = useMemo(
    () =>
      conversations
        .filter((item) => {
          const mutePreference =
            userEntityPreferences?.notification_pref?.chats?.except?.[item.id]
              ?.mute;
          if (
            searchQuery &&
            !item.data.title?.toLowerCase().includes(searchQuery.toLowerCase())
          ) {
            return false;
          }
          return (
            item.data.type === ChatType.chat &&
            mutePreference &&
            mutePreference !== "none"
          );
        })
        .map((item) => item.data),
    [
      conversations,
      searchQuery,
      userEntityPreferences?.notification_pref?.chats?.except,
    ]
  );

  const mutedGroupConversations = useMemo(
    () =>
      conversations
        .filter((item) => {
          const mutePreference =
            userEntityPreferences?.notification_pref?.groups?.except?.[item.id]
              ?.mute;
          if (
            searchQuery &&
            !item.data.title?.toLowerCase().includes(searchQuery.toLowerCase())
          ) {
            return false;
          }
          return (
            item.data.type === ChatType.muc &&
            mutePreference &&
            mutePreference !== "none"
          );
        })
        .map((item) => item.data),
    [
      conversations,
      searchQuery,
      userEntityPreferences?.notification_pref?.groups?.except,
    ]
  );

  const [addMutedChatsIsOpen, setAddMutedChatsIsOpen] = useState(false);

  return (
    <>
      <SidebarDrawer
        isOpen={isOpen}
        onClose={onRequestClose}
        onGoBack={onRequestClose}
        hasBackdrop={false}
        title={t({
          id: "settings-page.muted-chats",
          defaultMessage: "Muted Chats",
        })}
      >
        <SidebarDrawer.Content className="flex flex-col">
          <div className="px-4 py-6">
            <SearchInput
              color="primary"
              variant="filled"
              onChange={(e) => setSearchQuery(e.currentTarget.value)}
              value={searchQuery}
            />
          </div>
          {mutedPrivateConversations.length ||
          mutedGroupConversations.length ? (
            <>
              {mutedPrivateConversations.length ? (
                <MutedChatsList
                  header={t({
                    id: "settings-page.private-chats",
                    defaultMessage: "Private Chats",
                  })}
                  chats={mutedPrivateConversations}
                />
              ) : null}
              {mutedPrivateConversations.length &&
              mutedGroupConversations.length ? (
                <div className="my-6 h-[12px] bg-[#ECEFF0]" />
              ) : null}
              {mutedGroupConversations.length ? (
                <MutedChatsList
                  header={t({
                    id: "settings-page.group-chats",
                    defaultMessage: "Group Chats",
                  })}
                  chats={mutedGroupConversations}
                />
              ) : null}
            </>
          ) : (
            <div className="flex h-full flex-col items-center justify-center px-8">
              <Lottie
                options={{ animationData: emptyAnimation }}
                height={150}
                width={150}
              />
              <div className="text-center text-sm text-[#4F4F4F]">
                {t({
                  id: "settings-page.no-muted-chats",
                  defaultMessage: "There is no muted chats yet",
                })}
              </div>
              <Button
                className="mt-8"
                onClick={() => setAddMutedChatsIsOpen(true)}
              >
                {t({
                  id: "settings-page.mute-chats",
                  defaultMessage: "Mute chats",
                })}
              </Button>
            </div>
          )}
          <AddMutedChatsSidebar
            isOpen={addMutedChatsIsOpen}
            onRequestClose={() => setAddMutedChatsIsOpen(false)}
          />
        </SidebarDrawer.Content>
        {mutedPrivateConversations.length || mutedGroupConversations.length ? (
          <SidebarDrawer.Actions>
            <Button fullWidth onClick={() => setAddMutedChatsIsOpen(true)}>
              {t({
                id: "settings-page.add-chats",
                defaultMessage: "Add chats",
              })}
            </Button>
          </SidebarDrawer.Actions>
        ) : null}
      </SidebarDrawer>
    </>
  );
};
