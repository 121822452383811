import React from "react";
import { useTranslations } from "@jugl-web/utils";
import { ReactComponent as SearchIcon } from "./assets/search.svg";

const DriveInput: React.FC<{
  value: string;
  onChange: (value: string) => void;
}> = ({ value, onChange }) => {
  const { t } = useTranslations();
  return (
    <div className="relative h-10 w-[400px] rounded-lg bg-white">
      <SearchIcon className="absolute top-1/2 left-3 -translate-y-1/2" />
      <input
        className="h-10 w-full rounded-lg border-none pl-10 text-base font-medium leading-3 text-[#333333] outline-none"
        placeholder={t({
          id: "common.search-with-ellipsis",
          defaultMessage: "Search...",
        })}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export default DriveInput;
