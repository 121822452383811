import {
  Button,
  ButtonProps,
} from "@jugl-web/ui-components/cross-platform/Button";
import { useTranslations } from "@jugl-web/utils";
import { FC, ReactNode, forwardRef } from "react";
import { ReactComponent as AddIcon } from "../../assets/add.svg";

export const FilterSectionLayout = ({ children }: { children: ReactNode }) => (
  <div className="border-grey-200 flex flex-col gap-3 border-b border-l-0 border-r-0 border-t-0 border-solid px-12 py-5 transition-colors last:border-b-0 hover:bg-[#FAFAFA]">
    {children}
  </div>
);

FilterSectionLayout.Header = (({ title, endSlot }) => (
  <div className="flex items-center justify-between">
    <span className="text-onyx">{title}</span>
    {endSlot}
  </div>
)) as FC<{ title: string; endSlot?: ReactNode }>;

FilterSectionLayout.AddButton = forwardRef<
  HTMLButtonElement,
  Omit<ButtonProps, "ref">
>((props, ref) => {
  const { t } = useTranslations();

  return (
    <Button
      ref={ref}
      color="grey"
      className="p-2 font-normal"
      iconEnd={<AddIcon />}
      {...props}
    >
      {t({ id: "common.add", defaultMessage: "Add" })}
    </Button>
  );
});

FilterSectionLayout.PillsContainer = (({ children }) => (
  <div className="flex flex-wrap gap-3">{children}</div>
)) as FC<{ children: ReactNode }>;
