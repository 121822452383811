import { Menu, MenuConfig } from "@jugl-web/ui-components/cross-platform";
import { Tooltip } from "@jugl-web/ui-components/web";
import { cx } from "@jugl-web/utils";
import { FC } from "react";
import { ReactComponent as ArrowDownIcon } from "../../assets/arrow-down.svg";

interface ManageFilterSetButtonProps {
  label: string;
  isDisabled?: boolean;
  tip?: string;
  menuConfig?: MenuConfig;
  onClick: () => void;
}

export const ManageFilterSetButton: FC<ManageFilterSetButtonProps> = ({
  label,
  isDisabled,
  tip,
  menuConfig,
  onClick,
}) => {
  const isSplit = !!menuConfig;

  const commonClasses = cx(
    "bg-primary-50 text-primary-800 font-primary flex h-8 cursor-pointer items-center border-0 px-3 text-sm font-normal tracking-[0.14px] transition",
    isDisabled ? "cursor-not-allowed opacity-70" : "hover:bg-[#D1ECFF]"
  );

  return (
    <div className="flex items-center gap-0.5">
      <Tooltip
        isDisabled={!tip}
        className="px-3"
        renderTrigger={({ ref, props }) => (
          <button
            ref={ref}
            type="button"
            className={cx(
              commonClasses,
              isSplit ? "rounded-l-lg" : "rounded-lg"
            )}
            disabled={isDisabled}
            onClick={onClick}
            {...props}
          >
            {label}
          </button>
        )}
      >
        {tip}
      </Tooltip>
      {isSplit && (
        <Menu
          className="min-w-[136px]"
          placement="bottom-end"
          renderTrigger={({ Trigger, triggerRef }) => (
            <Trigger
              ref={triggerRef}
              className={cx(commonClasses, "rounded-r-lg")}
              disabled={isDisabled}
            >
              <ArrowDownIcon />
            </Trigger>
          )}
          {...menuConfig}
        />
      )}
    </div>
  );
};
