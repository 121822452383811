import { PageNavParams } from "@web-src/modules/navigation/types";
import { ModulePagesConfig } from "@web-src/modules/navigation/types/ModulePagesConfigs";
import SelectSubscriptionPage from "./SelectSubscriptionPage";
import { WorkspaceNoSubscriptionPage } from "./WorkspaceNoSubscriptionPage";
import WorkspaceSettingsPage from "./WorkspaceSettingsPage";
import WorkspaceSubscriptionPage from "./WorkspaceSubscriptionPage";
import { CPanelPage } from "./CPanelPage";

export type WorkspacePagesNavigationParams = {
  workspaceSubscription: PageNavParams;
  workspaceNoSubscription: PageNavParams;
  workspaceSelectSubscription: PageNavParams;
  workspaceSettings: PageNavParams;
  workspaceCPanel: PageNavParams;
};

export const workspacePagesNavigationConfig: ModulePagesConfig<WorkspacePagesNavigationParams> =
  {
    workspaceSubscription: {
      element: <WorkspaceSubscriptionPage />,
      path: "workspace/subscription",
      isPrivate: true,
      isAdminRequired: true,
      isActiveSubscriptionOptional: true,
    },
    workspaceNoSubscription: {
      element: <WorkspaceNoSubscriptionPage />,
      path: "no-subscription",
      isPrivate: true,
      isActiveSubscriptionOptional: true,
    },
    workspaceSelectSubscription: {
      element: <SelectSubscriptionPage />,
      path: "workspace/subscription/manage",
      isPrivate: true,
      isAdminRequired: true,
      isActiveSubscriptionOptional: true,
    },
    workspaceSettings: {
      element: <WorkspaceSettingsPage />,
      path: "workspace/settings",
      isPrivate: true,
      isAdminRequired: true,
    },
    workspaceCPanel: {
      element: <CPanelPage />,
      path: "workspace/members",
      isPrivate: true,
      isAdminRequired: true,
    },
  };
