import { TaskCustomStatus } from "@jugl-web/rest-api/tasks";
import { useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import { taskCustomStatusValidator } from "../../validators";
import {
  GenericFieldListItem,
  GenericFieldListItemProps,
} from "./GenericFieldListItem";

export interface TaskStatusListItemProps
  extends Omit<
    GenericFieldListItemProps,
    "item" | "placeholder" | "validator" | "renderStartSlot"
  > {
  customStatus: TaskCustomStatus;
  order: number;
}

export const TaskStatusListItem: FC<TaskStatusListItemProps> = ({
  customStatus,
  order,
  ...props
}) => {
  const { t } = useTranslations();

  return (
    <GenericFieldListItem
      item={customStatus}
      placeholder={t({
        id: "form-controls.task-custom-status.placeholder",
        defaultMessage: "Enter status name",
      })}
      validator={taskCustomStatusValidator}
      renderStartSlot={() => (
        <div className="mr-3 flex h-8 w-8 items-center justify-center rounded-lg border-4 border-solid border-white">
          <span className="text-dark-700 text-[10px] font-medium">
            #{order}
          </span>
        </div>
      )}
      {...props}
    />
  );
};
