import { useUserGeneralProfile } from "@jugl-web/domain-resources/users/hooks/useUserGeneralProfile";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { ChatMessagePayloadAttachment } from "@web-src/features/chats/types";
import { getChatMessageAttachmentUrls } from "@web-src/modules/chats/utils/getChatMessageAttachmentUrls";
import { CommonAudioPlayer } from "@web-src/modules/media/components/CommonAudioPlayer";
import format from "date-fns/format";
import React from "react";

export const VoiceAttachmentItem: React.FC<{
  attachment: ChatMessagePayloadAttachment;
  senderUserId: string;
  date: string;
}> = ({ date, attachment, senderUserId }) => {
  const { entity } = useEntity();
  const { dateLocale } = useLanguage();

  if (!entity) {
    throw new Error("Selected entity is required");
  }
  const { profile } = useUserGeneralProfile({
    entityId: entity?.id,
    userId: senderUserId,
  });

  const { stream } = getChatMessageAttachmentUrls(entity.id, attachment);

  return (
    <CommonAudioPlayer url={stream}>
      <div className="flex items-center justify-between gap-2">
        <div className="text-dark truncate font-medium">
          {profile?.displayName}
        </div>
        <div className="text-grey shrink-0 text-xs font-medium">
          {format(new Date(date), "MMM dd, hh:mm a", { locale: dateLocale })}
        </div>
      </div>
    </CommonAudioPlayer>
  );
};
