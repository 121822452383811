import { UserEntityStatus } from "@jugl-web/rest-api";
import { cx } from "@jugl-web/utils";
import { FC, useMemo } from "react";

export const UserEntityStatusBadge: FC<{ status: UserEntityStatus }> = ({
  status,
}) => {
  const badgeConfig = useMemo(() => {
    let result: {
      className: string;
      statusText: string;
    } | null = null;
    switch (status) {
      case UserEntityStatus.active:
        result = {
          className: "text-[#327246] bg-[#D9FFEC]",
          statusText: "Active",
        };
        break;
      case UserEntityStatus.accDeleted:
      case UserEntityStatus.left:
        result = {
          className: "text-white bg-[#D75144]",
          statusText:
            status === UserEntityStatus.accDeleted
              ? "User deleted account"
              : "User left",
        };
        break;
      case UserEntityStatus.inactive:
        result = {
          className: "text-[#818288] bg-[#ECEFF0]",
          statusText: "Suspended",
        };
        break;
      case UserEntityStatus.inviteRequested:
        result = {
          className: "text-[#1045A1] bg-[#D3ECFF]",
          statusText: "Invitation requested",
        };
        break;
      case UserEntityStatus.requestDeclined:
        result = {
          className: "text-[#9F2E29] bg-[#F8DFE2]",
          statusText: "Invitation declined",
        };
        break;
      default:
    }
    return result;
  }, [status]);

  if (!badgeConfig) {
    return null;
  }
  const { className, statusText } = badgeConfig;
  return (
    <div className={cx(className, "rounded p-2 text-sm")}>{statusText}</div>
  );
};
