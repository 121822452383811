export const getUsernameInitials = (username: string) => {
  const nameParts = username.split(" ");

  if (nameParts.length > 1) {
    const [firstName, lastName] = nameParts;
    return firstName.charAt(0).concat(lastName.charAt(0));
  }

  const [name] = nameParts;
  return name.substring(0, 2);
};
