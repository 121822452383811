import { TaskCustomFieldType, TaskPriority } from "@jugl-web/rest-api/tasks";
import { toISODate } from "@jugl-web/utils/date-time/toISODate";
import { ReactComponent as CustomDateFieldBlankIcon } from "./icons/custom-date-field-blank.svg";
import { ReactComponent as CustomDropdownFieldBlankIcon } from "./icons/custom-dropdown-field-blank.svg";
import { ReactComponent as CustomNumberFieldBlankIcon } from "./icons/custom-number-field-blank.svg";
import { ReactComponent as CustomTextFieldBlankIcon } from "./icons/custom-text-field-blank.svg";

export const TITLE_MIN_LENGTH = 2;
export const TITLE_MAX_LENGTH = 200;
export const CUSTOM_DROPDOWN_VALUE_CHAR_LIMIT = 50;
export const TASK_TEXT_FIELD_CHAR_LIMIT = 50;
export const FILTER_SET_NAME_CHAR_LIMIT = 80;

export const taskCustomFieldTypeLabelById: Record<TaskCustomFieldType, string> =
  {
    text: "Text",
    number: "Number",
    date: "Date",
    dropdown: "Dropdown",
  };

export const TASK_LABEL_COLORS = [
  "#6A1262",
  "#AD1255",
  "#1B6565",
  "#647F16",
  "#313D85",
  "#701C4F",
  "#995C17",
  "#187964",
];

export const customFieldTypeToIconDetailsMap = {
  text: {
    icon: CustomTextFieldBlankIcon,
    color: "#EBFFF5",
  },
  dropdown: {
    icon: CustomDropdownFieldBlankIcon,
    color: "#E3F2FD",
  },
  date: {
    icon: CustomDateFieldBlankIcon,
    color: "#FFF7EB",
  },
  number: {
    icon: CustomNumberFieldBlankIcon,
    color: "#FDEBFF",
  },
};

export const TEMPLATE_FOLDER_PRESETS = [
  { color: "#FE6250", emoji: "📙" },
  { color: "#B1DD8B", emoji: "🧩" },
  { color: "#864FFD", emoji: "🦄" },
  { color: "#EE719E", emoji: "📚" },
  { color: "#A7C6FF", emoji: "📘" },
  { color: "#E63B7A", emoji: "🥰" },
  { color: "#00A1D8", emoji: "🦋" },
  { color: "#FFAB01", emoji: "🤓" },
  { color: "#CDE8B5", emoji: "🍀" },
];

export const TASK_COLUMNS_IDS = {
  DEFAULT_VIEW_LAST_UPDATED: "default-view__last-updated",
  DEFAULT_VIEW_ALL_TASKS: "default-view__all-tasks",
  DEFAULT_VIEW_OVERDUE: "default-view__overdue",
  DEFAULT_VIEW_TODAY: "default-view__today",

  DATE_VIEW_OVERDUE: "date-view__overdue",
  DATE_VIEW_WITHOUT_DUE_DATE: "date-view__without-due-date",
  DATE_VIEW_TODAY: "date-view__today",
  DATE_VIEW_TOMORROW: "date-view__tomorrow",
  DATE_VIEW: (date: Date) => `date-view__${toISODate(date)}`,

  LABELS_VIEW_WITHOUT_LABEL: "labels-view__without-label",
  LABELS_VIEW_LABEL_ID: (labelId: string) => `labels-view__label-${labelId}`,

  PRIORITY_VIEW_PRIORITY: (priority: TaskPriority) =>
    `priority-view__${priority}`,

  STATUS_VIEW_STATUS_ID: (statusId: string) =>
    `status-view__status-${statusId}`,

  ASSIGNEE_VIEW_WITHOUT_ASSIGNEE: "assignee-view__without-assignee",
  ASSIGNEE_VIEW_ASSIGNEE_ID: (assigneeId: string) => assigneeId,

  REPORTEE_VIEW_REPORTEE_ID: (reporteeId: string) => reporteeId,
  CALENDAR_VIEW_REPORTEE_ID: (date: Date, reporteeId: string) =>
    `calendar-view__${toISODate(date)}__${reporteeId}`,

  CUSTOMER_VIEW_WITHOUT_CUSTOMER: "customer-view__without-customer",
  CUSTOMER_VIEW_CUSTOMER_ID: (customerId: string) =>
    `customer-view__customer-${customerId}`,

  CUSTOM_DROPDOWN_FIELD_VIEW_NOT_ASSIGNED: (customFieldId: string) =>
    `custom-dropdown-field-view__not-assigned-${customFieldId}`,
  CUSTOM_DROPDOWN_FIELD_VIEW_VALUE_ID: (
    customFieldId: string,
    valueId: string
  ) => `custom-dropdown-field-view__value-${customFieldId}-${valueId}`,
} as const;
