import { assert, getUniqueId } from "@jugl-web/utils";
import {
  PaginatedRequestParams,
  PaginatedResponse,
  RtkEmptySplitApi,
} from "../../types";
import { ModuleNotificationsApiTag } from "./tags";
import {
  ModuleNotification,
  RawModuleNotificationsResponse,
  SupportedNotificationsModule,
} from "./types";

export const addModuleNotificationsApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [ModuleNotificationsApiTag.ModuleLog],
  });

  const moduleNotificationsApi = apiWithTags.injectEndpoints({
    endpoints: (builder) => ({
      getModuleNotifications: builder.query<
        PaginatedResponse<ModuleNotification>,
        PaginatedRequestParams<{
          entityId: string;
          module: SupportedNotificationsModule;
          flag?: boolean;
        }>
      >({
        query: ({ entityId, module, flag, ...pageParams }) => ({
          url: `/api/auth/entity/${entityId}/logs`,
          params: { module, flag, ...pageParams },
        }),
        transformResponse: (response: RawModuleNotificationsResponse) => {
          // Unify the response structure
          if (response.data.length === 0) {
            return {
              ...response,
              data: [],
            };
          }

          if ("logs" in response.data[0]) {
            return {
              ...response,
              data: response.data.map((notification) => {
                assert("logs" in notification);

                return {
                  id: getUniqueId(),
                  logs: notification.logs,
                };
              }),
            };
          }

          return {
            ...response,
            data: response.data.map((log) => {
              assert(!("logs" in log));

              return {
                id: getUniqueId(),
                logs: [log],
              };
            }),
          };
        },
      }),

      getModuleNotificationsUnreadIndicator: builder.query<
        { unread: boolean },
        { entityId: string; module: SupportedNotificationsModule }
      >({
        query: ({ entityId, module }) => ({
          url: `/api/auth/entity/${entityId}/visits`,
          params: { type: "module_unread", module },
        }),
      }),

      markModuleNotificationsUnreadIndicatorAsUnread: builder.mutation<
        null,
        { entityId: string; module: SupportedNotificationsModule }
      >({
        queryFn: () => ({ data: null }),
        onQueryStarted: ({ entityId, module }, { dispatch }) => {
          dispatch(
            moduleNotificationsApi.util.updateQueryData(
              "getModuleNotificationsUnreadIndicator",
              { entityId, module },
              (draftState) => {
                draftState.unread = true;
              }
            )
          );
        },
      }),

      markModuleNotificationsUnreadIndicatorAsRead: builder.mutation<
        void,
        { entityId: string; module: SupportedNotificationsModule }
      >({
        query: ({ entityId, module }) => ({
          url: `/api/auth/entity/${entityId}/visits`,
          method: "POST",
          data: { type: "module_unread", module },
        }),
        onQueryStarted: (args, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            moduleNotificationsApi.util.updateQueryData(
              "getModuleNotificationsUnreadIndicator",
              args,
              (draftState) => {
                draftState.unread = false;
              }
            )
          );

          queryFulfilled.catch(patchResult.undo);
        },
      }),

      markNotificationAsRead: builder.mutation<
        void,
        {
          entityId: string;
          logIds: string[];
        }
      >({
        query: ({ entityId, logIds }) => ({
          url: `/api/auth/entity/${entityId}/logs`,
          method: "PUT",
          data: { id: logIds },
        }),
      }),

      markObjectLevelIndicatorAsRead: builder.mutation<
        void,
        {
          entityId: string;
          objectId: string;
          module: SupportedNotificationsModule;
        }
      >({
        query: ({ entityId, objectId, module }) => ({
          url: `/api/auth/entity/${entityId}/logs`,
          method: "PUT",
          data: { id: [objectId], module, type: "object" },
        }),
      }),
    }),
    overrideExisting: false,
  });

  return moduleNotificationsApi;
};

export type ModuleNotificationsApi = ReturnType<
  typeof addModuleNotificationsApi
>;
