import { ConfirmationPopup } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import React from "react";

export const ChatBotRestartConfirmationDialog: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onRestart: () => void;
}> = ({ isOpen, onClose, onRestart }) => {
  const { t } = useTranslations();
  return (
    <ConfirmationPopup
      isOpen={isOpen}
      variant="web"
      onRequestClose={onClose}
      title={t({
        id: "tasks-page.restart-bot",
        defaultMessage: "Restart Bot",
      })}
      message={t({
        id: "tasks-page.restart-bot-confirmation",
        defaultMessage: "Are you sure you want to restart bot now?",
      })}
      hasCancelButton
      buttons={[
        {
          label: t({
            id: "common.restart",
            defaultMessage: "Restart",
          }),
          color: "primary",
          onClick: () => {
            onRestart();
            onClose();
          },
        },
      ]}
    />
  );
};
