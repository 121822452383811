import {
  DPDatesConfig,
  DPUserConfig,
  useDatePickerContext,
} from "@rehookify/datepicker";
import { useCallback, useMemo } from "react";

interface UseTimePickerOptions
  extends Pick<DPDatesConfig, "minDate" | "maxDate"> {
  onDatesChange: DPUserConfig["onDatesChange"];
}

const MIN_HOUR = 0;
const MAX_HOUR = 23;
const MIN_MINUTE = 0;
const MAX_MINUTE = 59;

export const useTimePicker = ({
  minDate,
  maxDate,
  onDatesChange,
}: UseTimePickerOptions) => {
  const context = useDatePickerContext();

  const selectedDate = useMemo(
    () => context.data.selectedDates[0] || new Date(),
    [context.data.selectedDates]
  );

  const hoursIn24HourFormat = selectedDate.getHours();
  const hoursIn12HourFormat = hoursIn24HourFormat % 12 || 12;
  const minutes = selectedDate.getMinutes();
  const meridiem = hoursIn24HourFormat > 11 ? "PM" : "AM";

  const isWithinRange = useCallback(
    (date: Date) => {
      if (minDate && date < minDate) {
        return false;
      }

      if (maxDate && date > maxDate) {
        return false;
      }

      return true;
    },
    [minDate, maxDate]
  );

  const setHours = useCallback(
    (value: number) => {
      const updatedDate = new Date(selectedDate);

      updatedDate.setHours(value);

      if (isWithinRange(updatedDate)) {
        onDatesChange([updatedDate]);
      }
    },
    [isWithinRange, onDatesChange, selectedDate]
  );

  const setMinutes = useCallback(
    (value: number) => {
      const updatedDate = new Date(selectedDate);

      updatedDate.setMinutes(value);

      if (isWithinRange(updatedDate)) {
        onDatesChange([updatedDate]);
      }
    },
    [isWithinRange, onDatesChange, selectedDate]
  );

  const bumpHours = useCallback(() => {
    const nextHour = hoursIn24HourFormat + 1;
    setHours(nextHour > MAX_HOUR ? MIN_HOUR : nextHour);
  }, [hoursIn24HourFormat, setHours]);

  const dropHours = useCallback(() => {
    const previousHour = hoursIn24HourFormat - 1;
    setHours(previousHour < MIN_HOUR ? MAX_HOUR : previousHour);
  }, [hoursIn24HourFormat, setHours]);

  const changeHours = useCallback(
    (value: number) => {
      const isValid = value >= MIN_HOUR && value <= MAX_HOUR;

      if (isValid) {
        setHours(value);
      }
    },
    [setHours]
  );

  const bumpMinutes = useCallback(() => {
    const nextMinute = minutes + 1;
    setMinutes(nextMinute > MAX_MINUTE ? MIN_MINUTE : nextMinute);
  }, [minutes, setMinutes]);

  const dropMinutes = useCallback(() => {
    const previousMinute = minutes - 1;
    setMinutes(previousMinute < MIN_MINUTE ? MAX_MINUTE : previousMinute);
  }, [minutes, setMinutes]);

  const changeMinutes = useCallback(
    (value: number) => {
      const isValid = value >= MIN_MINUTE && value <= MAX_MINUTE;

      if (isValid) {
        setMinutes(value);
      }
    },
    [setMinutes]
  );

  const toggleMeridiem = useCallback(() => {
    const updatedHours = (hoursIn24HourFormat + 12) % 24;
    setHours(updatedHours);
  }, [hoursIn24HourFormat, setHours]);

  return {
    hoursIn24HourFormat,
    hoursIn12HourFormat,
    minutes,
    meridiem,
    bumpHours,
    dropHours,
    changeHours,
    bumpMinutes,
    dropMinutes,
    changeMinutes,
    toggleMeridiem,
  };
};
