import { SubscriptionPlanColor } from "../types/SubscriptionPlanColor";
import { SubscriptionPlanColorInfo } from "../types/SubscriptionPlanColorInfo";

export const SUBSCRIPTION_PLAN_COLORS: Record<
  SubscriptionPlanColor,
  SubscriptionPlanColorInfo
> = {
  pink: {
    main: "#F97EC0",
    mainDarker: "#E95CA8",
    text: "#831651",
  },
  green: {
    main: "#4ED8B7",
    mainDarker: "#38C6A4",
    text: "#16833B",
  },
  violet: {
    main: "#D28BEC",
    mainDarker: "#83167F",
    text: "#83167F",
  },
};
