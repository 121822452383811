export const reorder = <TType>(
  array: TType[],
  startIndex: number,
  endIndex: number
) => {
  const copy = [...array];
  const [removed] = copy.splice(startIndex, 1);
  copy.splice(endIndex, 0, removed);
  return copy;
};
