import React, { useMemo } from "react";
import { Text } from "@jugl-web/ui-components/cross-platform";

import { format } from "date-fns";
import { cx } from "@jugl-web/utils";
import UserProfileName from "@web-src/features/users/components/UserProfileName";
import UserProfileAvatar from "@web-src/features/users/components/UserProfileAvatar";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { SearchChatMessage } from "../../types";
import { ReactComponent as ImageIcon } from "./icons/image-icon.svg";
import { ReactComponent as FileIcon } from "./icons/file.svg";
import { ReactComponent as VideoIcon } from "./icons/video.svg";
import { ReactComponent as PdfIcon } from "./icons/pdf.svg";
import { ReactComponent as GifIcon } from "./icons/gif.svg";
import { ReactComponent as DocIcon } from "./icons/documents.svg";
import { tokenizeMessageBody } from "../../utils";
import ChatMessageBubbleBody from "../ChatMessageBubble/ChatMessageBubbleBody";

interface MessageCardProps extends SearchChatMessage {
  searchQuery: string;
  onClick: (id: string, createdAt: string) => void;
  isSelected: boolean;
}

const videoExtensions = [
  "mp4",
  "mov",
  "avi",
  "mkv",
  "webm",
  "flv",
  "vob",
  "ogv",
  "ogg",
  "drc",
  "gif",
  "gifv",
  "mng",
  "mts",
  "m2ts",
  "ts",
  "qt",
  "wmv",
  "yuv",
  "rm",
  "rmvb",
  "asf",
  "amv",
  "mpg",
  "mp2",
  "mpeg",
  "mpe",
  "mpv",
  "m2v",
  "m4v",
  "svi",
  "3gp",
  "3g2",
  "mxf",
  "roq",
  "nsv",
  "f4v",
  "f4p",
  "f4a",
  "f4b",
];

const imageExtensions = ["jpg", "jpeg", "png", "bmp", "webp", "svg", "ico"];
const audioExtensions = [
  "mp3",
  "wav",
  "ogg",
  "flac",
  "aac",
  "wma",
  "m4a",
  "aiff",
  "ra",
  "mid",
  "midi",
  "weba",
];
const documentExtensions = [
  "doc",
  "docx",
  "xls",
  "xlsx",
  "ppt",
  "pptx",
  "txt",
  "rtf",
  "csv",
  "odt",
  "ods",
  "odp",
  "epub",
  "md",
  "tex",
  "xml",
  "json",
  "html",
  "htm",
];
export const MessageCard: React.FC<MessageCardProps> = ({
  from,
  body,
  caption,
  file_name: fileName,
  msg_id: msgId,
  created_at: createdAt,
  searchQuery,
  onClick,
  isSelected,
}) => {
  const textContent = body || caption || "";
  const { dateLocale } = useLanguage();
  const tokens = tokenizeMessageBody(textContent);
  const handleFileIcon = useMemo(() => {
    const fileExtension = fileName.split(".").pop();
    if (!fileExtension) {
      return <FileIcon className="mr-1" />;
    }

    switch (true) {
      case documentExtensions.includes(fileExtension):
        return <DocIcon className="mr-1" />;
      case fileExtension === "gif":
        return <GifIcon className="mr-1" />;
      case fileExtension === "pdf":
        return <PdfIcon className="mr-1" />;
      case imageExtensions.includes(fileExtension):
        return <ImageIcon className="mr-1" />;
      case videoExtensions.includes(fileExtension):
        return <VideoIcon className="mr-1" />;
      case audioExtensions.includes(fileExtension):
        return <VideoIcon className="mr-1" />;
      default:
        return <FileIcon className="mr-1" />;
    }
  }, [fileName]);
  return (
    <div className="overflow-hidden">
      <div
        className={cx(
          "hover:bg-primary-50 min-h-[80px] cursor-pointer px-8 py-4",
          {
            "bg-primary-50": isSelected,
          }
        )}
        onClick={() => onClick(msgId, createdAt)}
      >
        <div className="flex flex-row items-start justify-between gap-3">
          <div className="flex w-4/6 flex-row items-start gap-3">
            <UserProfileAvatar
              userId={from}
              wrapperClassName="h-12 w-12 rounded-full bg-grey-100 flex items-center place-content-center bg-gradient-to-b from-gray-300 to-dark-100 mt-0.5"
              className="bg-grey-100 h-12 w-12 rounded-full"
              lettersClassName="h-12 w-12 text-dark flex items-center justify-center text-base font-semibold uppercase leading-3"
            />
            <div className="flex flex-col">
              <Text variant="body1" className="text-dark font-medium">
                <UserProfileName userId={from} />
              </Text>
              <div className="flex max-w-[140px] flex-col">
                <div className="align-center flex flex-shrink-0">
                  {fileName && handleFileIcon}
                  {fileName || ""}
                </div>
                <div title={textContent}>
                  <ChatMessageBubbleBody
                    tokens={tokens}
                    outgoing={false}
                    searchQuery={searchQuery}
                  />
                </div>
              </div>
            </div>
          </div>
          <Text variant="body2" className="text-grey mt-4">
            {format(new Date(createdAt), "dd MMM, yyyy", {
              locale: dateLocale,
            })}
          </Text>
        </div>
      </div>
      <div className="bg-grey-200 h-[1px] w-5/6" />
    </div>
  );
};
