import { PageNavParams } from "@web-src/modules/navigation/types";
import { ModulePagesConfig } from "@web-src/modules/navigation/types/ModulePagesConfigs";
import { EntityCreatePage } from "./EntityCreatePage";
import { EntitySelectPage } from "./EntitySelectPage";
import { EntityVerifyEmailPage } from "./EntityVerifyEmailPage";

export type EntityPagesNavigationParams = {
  entityCreate: PageNavParams;
  entitySelect: PageNavParams<never, { error?: string; resourceName?: string }>;
  entityVerifyEmail: PageNavParams;
};

export const entityPagesNavigationConfig: ModulePagesConfig<EntityPagesNavigationParams> =
  {
    entityCreate: {
      element: <EntityCreatePage />,
      path: "/org-create",
      isPrivate: true,
      isEntityOptional: true,
    },
    entitySelect: {
      element: <EntitySelectPage />,
      path: "/org-select",
      isPrivate: true,
      isEntityOptional: true,
    },
    entityVerifyEmail: {
      element: <EntityVerifyEmailPage />,
      path: "verify-email",
      isPrivate: true,
    },
  };
