import { TaskDefaultStatus, TaskLabel } from "@jugl-web/rest-api/tasks";
import {
  LiveUpdateEvent,
  TASK_COMMENT_MODULE,
  TASK_MODULE,
} from "../../../live-updates";

export const CREATED_ACTION = "created";
export const UPDATED_ACTION = "updated";
export const DELETED_ACTION = "deleted";
export const ATTACHMENT_ADDED_ACTION = "attachment_added";
export const ATTACHMENT_RENAMED_ACTION = "attachment_renamed";
export const ATTACHMENT_DELETED_ACTION = "attachment_deleted";

// #region Task live updates
export type TaskLiveUpdateEvent<
  TAction extends string = string,
  TData extends object = object
> = LiveUpdateEvent<TAction, TData, typeof TASK_MODULE>;

export type TaskLiveUpdateCreatedEvent = TaskLiveUpdateEvent<
  typeof CREATED_ACTION,
  {
    id: string;
    entity_id: string;
    label_id: string | null;
    due_at: string | null;
  }
>;

export type TaskLiveUpdateUpdatedEvent = TaskLiveUpdateEvent<
  typeof UPDATED_ACTION,
  {
    id: string;
    entity_id: string;
    old_due_at: string | null;
    old_label_id: string | null;
    label?: TaskLabel | null;
    due_at?: string | null;
    assignee?: "updated";
    checklist?: "updated";
    status?: TaskDefaultStatus;
    // ...rest of delta properties that are not relevant at this moment
  }
>;

export type TaskLiveUpdateDeletedEvent = TaskLiveUpdateEvent<
  typeof DELETED_ACTION,
  {
    id: string;
    entity_id: string;
    label_id: string | null;
    due_at: string | null;
  }
>;

export type TaskLiveUpdateAttachmentAddedEvent = TaskLiveUpdateEvent<
  typeof ATTACHMENT_ADDED_ACTION,
  {
    id: string;
    entity_id: string;
    attachment_id: string;
    file_name: string;
  }
>;

export type TaskLiveUpdateAttachmentRenamedEvent = TaskLiveUpdateEvent<
  typeof ATTACHMENT_RENAMED_ACTION,
  {
    id: string;
    entity_id: string;
    attachment_id: string;
    file_name: string;
    old_file_name: string;
  }
>;

export type TaskLiveUpdateAttachmentDeletedEvent = TaskLiveUpdateEvent<
  typeof ATTACHMENT_DELETED_ACTION,
  {
    id: string;
    entity_id: string;
    attachment_id: string;
    file_name: string;
  }
>;
// #endregion

// #region Task comment live updates
export type TaskCommentLiveUpdateEvent<
  TAction extends string = string,
  TData extends object = object
> = LiveUpdateEvent<TAction, TData, typeof TASK_COMMENT_MODULE>;

export type TaskCommentLiveUpdateCreatedEvent = TaskCommentLiveUpdateEvent<
  typeof CREATED_ACTION,
  {
    comment_id: string;
    entity_id: string;
    task_id: string;
  }
>;

export type TaskCommentLiveUpdateUpdatedEvent = TaskCommentLiveUpdateEvent<
  typeof UPDATED_ACTION,
  {
    comment_id: string;
    entity_id: string;
    task_id: string;
  }
>;

export type TaskCommentLiveUpdateDeletedEvent = TaskCommentLiveUpdateEvent<
  typeof DELETED_ACTION,
  {
    comment_id: string;
    entity_id: string;
    task_id: string;
  }
>;
// #endregion
