import { UserCPanelModel, useRestApiProvider } from "@jugl-web/rest-api";
import {
  Button,
  Input,
  InteractiveContainer,
  PhoneInput,
  inputValueToPhoneString,
  phoneStringToInputValue,
} from "@jugl-web/ui-components/cross-platform";
import { SidebarDrawer } from "@jugl-web/ui-components/web";
import { isValidEmail, useToast } from "@jugl-web/utils";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import React, { FC, PropsWithChildren } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useOnboarding } from "@web-src/modules/preferences/providers";
import { ReactComponent as TrashIconSvg } from "./assets/trash-icon.svg";
import { useCPanelPageContext } from "../../CPanelPageProvider";

type InvitationFormType = {
  name: string;
  emails: { value: string }[];
  mobiles: { value: string }[];
};

const FormFieldWrapper: FC<PropsWithChildren<{ label: string }>> = ({
  label,
  children,
}) => (
  <div className="flex-gap-1 flex w-full flex-col text-xs text-gray-500">
    <div>{label}</div>
    {children}
  </div>
);

export const UserInvitationSidebarContent: React.FC<{
  user?: UserCPanelModel;
  onRequestClose: () => void;
}> = ({ user, onRequestClose }) => {
  const { completeOnboardingStep, isOnboardingActive } = useOnboarding();
  const {
    register,
    control,
    handleSubmit,
    formState: invitationFormState,
    getValues,
  } = useForm<InvitationFormType>({
    mode: "all",
    defaultValues: {
      name: user?.username,
      emails: user?.emails?.length
        ? user.emails.map((item) => ({ value: item }))
        : [{ value: "" }],
      mobiles: user?.mobiles?.length
        ? user.mobiles.map((item) => ({ value: item }))
        : [{ value: "" }],
    },
  });

  const {
    fields: emailFields,
    append: appendEmail,
    remove: removeEmail,
  } = useFieldArray<InvitationFormType>({
    control,
    name: "emails",
    keyName: "id",
  });

  const {
    fields: mobileFields,
    append: appendMobile,
    remove: removeMobile,
  } = useFieldArray<InvitationFormType>({
    control,
    name: "mobiles",
    keyName: "id",
  });

  const { usersApi } = useRestApiProvider();
  const { entityId } = useEntitySelectedProvider();
  const [sendEntityInvitation, { isLoading: isSendLoading }] =
    usersApi.useSendEntityInvitationMutation();
  const [resendEntityInvitation, { isLoading: isResendLoading }] =
    usersApi.useResendEntityInvitationMutation();

  const { refetchUsers$ } = useCPanelPageContext();

  const { toast } = useToast({ variant: "web" });

  const handleFormSubmit = handleSubmit(async (formData) => {
    const data = {
      username: formData.name,
      emails: formData.emails
        .map((item) => item.value)
        .filter((item) => !!item),
      mobiles: formData.mobiles
        .map((item) => item.value)
        .filter((item) => !!item && phoneStringToInputValue(item).isValid),
    };
    if (user) {
      const resentResp = await resendEntityInvitation({
        entityId,
        entityRelId: user.entity_rel_id,
        data,
      });
      if ("data" in resentResp && resentResp.data) {
        toast("Invitation re-sent successfully");
        onRequestClose();
        refetchUsers$.next();
      }
      return;
    }
    const sendResp = await sendEntityInvitation({
      entityId,
      data,
    });
    if ("data" in sendResp && sendResp.data) {
      toast("Invitation sent successfully");
      if (isOnboardingActive) {
        completeOnboardingStep("people");
      }
      onRequestClose();
      refetchUsers$.next();
    }
  });

  return (
    <>
      <SidebarDrawer.Content className="p-8">
        <div className="w-full">
          <div className="mb-4">
            <FormFieldWrapper label="Name">
              <Input
                className="w-full"
                placeholder="Name"
                {...register(`name`, {
                  required: true,
                })}
              />
            </FormFieldWrapper>
          </div>
          <div className="flex flex-col gap-4">
            {emailFields.map((item, idx) => (
              <div key={item.id} className="flex w-full">
                <FormFieldWrapper label="Email">
                  <Input
                    className="w-full"
                    placeholder="Email"
                    {...register(`emails.${idx}.value`, {
                      validate: (value) => {
                        const hasPhones = getValues("mobiles").find(
                          (phone) => !!phone.value
                        );
                        if (!value && hasPhones) {
                          return true;
                        }
                        return isValidEmail(value);
                      },
                    })}
                  />
                </FormFieldWrapper>
                {emailFields.length > 1 && (
                  <InteractiveContainer
                    className="p-4"
                    onClick={() => removeEmail(idx)}
                  >
                    <TrashIconSvg />
                  </InteractiveContainer>
                )}
              </div>
            ))}
          </div>
          <InteractiveContainer
            className="text-primary-500 px-2 py-2 text-sm"
            onClick={() => appendEmail({ value: "" })}
          >
            + Add email
          </InteractiveContainer>
          <div className="mt-4 flex flex-col gap-4">
            {mobileFields.map((item, idx) => (
              <div key={item.id} className="flex">
                <Controller
                  control={control}
                  name={`mobiles.${idx}.value`}
                  rules={{
                    validate: (mobileValue) => {
                      const hasEmails = getValues("emails").find(
                        (email) => !!email.value
                      );
                      const obj = phoneStringToInputValue(mobileValue);
                      if (!obj.phone && hasEmails) {
                        return true;
                      }
                      return obj.isValid;
                    },
                  }}
                  render={({ field }) => (
                    <FormFieldWrapper label="Phone">
                      <div className="border-0 border-b border-solid border-black/20">
                        <PhoneInput
                          value={phoneStringToInputValue(field.value)}
                          onChange={(obj) => {
                            field.onChange(inputValueToPhoneString(obj));
                          }}
                        />
                      </div>
                    </FormFieldWrapper>
                  )}
                />
                {mobileFields.length > 1 && (
                  <InteractiveContainer
                    className="p-4"
                    onClick={() => removeMobile(idx)}
                  >
                    <TrashIconSvg />
                  </InteractiveContainer>
                )}
              </div>
            ))}
          </div>
          <InteractiveContainer
            className="text-primary-500 px-2 py-2 text-sm"
            onClick={() => appendMobile({ value: "" })}
          >
            + Add phone
          </InteractiveContainer>
        </div>
      </SidebarDrawer.Content>
      <SidebarDrawer.Actions>
        <Button
          fullWidth
          onClick={handleFormSubmit}
          isDisabled={
            // !invitationFormState?.isDirty ||
            !invitationFormState?.isValid || isSendLoading || isResendLoading
          }
        >
          Submit
        </Button>
      </SidebarDrawer.Actions>
    </>
  );
};

export const UserInvitationSidebar: React.FC<{
  isOpen: boolean;
  user?: UserCPanelModel;
  onRequestClose: () => void;
}> = ({ isOpen, user, onRequestClose }) => (
  <SidebarDrawer
    isOpen={isOpen}
    onClose={onRequestClose}
    hasBackdrop
    title={user ? "Update Invitation" : "Add Member"}
  >
    <UserInvitationSidebarContent user={user} onRequestClose={onRequestClose} />
  </SidebarDrawer>
);
