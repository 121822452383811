import React from "react";
import { LoadingAnimation } from "../LoadingAnimation";

export const ListLoading: React.FC = () => (
  <div className="relative h-full w-full">
    <div className="absolute top-0 left-0 bottom-0 right-0 overflow-hidden px-8">
      <div className="flex flex-col gap-5 py-5">
        {[...new Array(7)].map((_, idx) => (
          <div
            key={+idx}
            className="h-[100px] animate-pulse rounded-lg bg-gray-100"
          />
        ))}
      </div>
    </div>
    <div
      className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center"
      style={{
        background:
          "linear-gradient(180deg, rgba(255, 255, 255, 0.00) 62.62%, #FFF 100%)",
      }}
    >
      <LoadingAnimation />
    </div>
  </div>
);
