import { Alert } from "@jugl-web/ui-components/cross-platform";
import { FC } from "react";
import Lottie from "react-lottie";
import alertAnimation from "./assets/alert-animation.json";
import { ReactComponent as PlusIcon } from "./assets/plus-icon.svg";

export type ManageSeatsCTAProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  utilizedSeats: number;
  planSeats: number;
  additionalSeats: number;
};

export const ManageSeatsCTA: FC<ManageSeatsCTAProps> = ({
  isOpen,
  onRequestClose,
  utilizedSeats,
  planSeats,
  additionalSeats,
}) => (
  <Alert
    isCloseButtonVisible
    header="Workspace Seats"
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    title="Manage seats"
    img={<Lottie options={{ animationData: alertAnimation }} width={300} />}
    content={
      <div className="flex flex-col gap-10">
        <div className="text-center">
          Oh no! Looks like this Workspace already have more Users than number
          of seats you&apos;re trying to purchase.
        </div>
        <div className="flex flex-col gap-[14px] px-5">
          <div className="flex h-9 items-center justify-center rounded-xl bg-[#FEF4F5] px-2 text-center text-sm">
            Users in Workspace: {utilizedSeats}
          </div>
          <div className="flex items-center gap-2">
            <div className="flex h-9 flex-1 items-center justify-center rounded-xl bg-[#E3F2FD] px-2 text-center text-sm">
              Basic plan seats: {planSeats}
            </div>
            <PlusIcon />
            <div className="flex h-9 flex-1 items-center justify-center rounded-xl bg-[#E3F2FD] px-2 text-center text-sm">
              Additional seats: {additionalSeats}
            </div>
          </div>
        </div>
      </div>
    }
    buttons={[{ role: "close", text: "Okay", color: "primary" }]}
  />
);
