import {
  HookOutOfContextError,
  useToast,
  useTranslations,
} from "@jugl-web/utils";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useEntity from "@web-src/features/app/hooks/useEntity";
import {
  TIMEZONE_MISMATCH_ALERT_SHOWN,
  getStorageItemWithFallback,
  saveItemToStorage,
} from "@jugl-web/utils/storage";
import {
  Country,
  Timezone,
  getCountryForTimezone,
  getTimezone,
  getTimezonesForCountry,
} from "countries-and-timezones";
import { logger } from "@web-src/utils/logger";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { TimezoneAlert } from "../components/TimezoneAlert";
import { getSystemTimezone } from "../utils";

interface TimeZoneProviderProps {
  children: React.ReactNode;
}

export const FALLBACK_TIME_ZONE = "America/New_York";
const DEFAULT_TIMEZONE = "Etc/UTC";

const TimeZoneContext = createContext<{
  timeZone: Timezone | undefined;
  region: Country | undefined;
  isLoading: boolean;
  isTimezoneMismatch: boolean;
  isTimezoneMismatchAlertOpen: boolean;
  openTimezoneMismatchAlert: () => void;
  changeRegion: (regionObj: Country) => void;
  changeZone: (zoneObj: Timezone) => void;
} | null>(null);

const initZone = (timeZone?: string) => {
  if (timeZone) {
    const timezoneObj = getTimezone(timeZone);
    if (timezoneObj) {
      return timezoneObj;
    }
  }
  const systemTimeZone = getSystemTimezone();
  if (systemTimeZone) {
    return systemTimeZone;
  }
  return getTimezone(FALLBACK_TIME_ZONE);
};

const initRegion = (zone: Timezone) =>
  getCountryForTimezone(zone.name) || getCountryForTimezone(FALLBACK_TIME_ZONE);

const TimeZoneProvider = ({ children }: TimeZoneProviderProps) => {
  const [timeZone, setTimeZone] = useState<Timezone | undefined>(undefined);
  const [region, setRegion] = useState<Country | undefined>(undefined);
  const [showTimezoneMismatchAlert, setShowTimezoneMismatchAlert] =
    useState(false);
  const isTimezoneMismatchChecked = useRef(false);
  const { usersApi } = useRestApiProvider();
  const [updateTimezone] = usersApi.useUpdateMyUserMutation();
  const { profile, isLoading } = useMe();
  const { entity } = useEntity();
  const { t } = useTranslations();
  const { toast } = useToast({ variant: "web" });

  const isTimezoneMismatch = useMemo(() => {
    const systemTimeZone = getSystemTimezone();
    if (
      !profile?.timezone ||
      !systemTimeZone ||
      systemTimeZone.name === profile.timezone
    ) {
      return false;
    }
    return true;
  }, [profile?.timezone]);

  const changeZone = useCallback(
    async (zoneObj: Timezone, regionObj?: Country) => {
      try {
        const response = await updateTimezone({
          timezone: zoneObj.name,
        });
        if (response && "data" in response) {
          toast(
            t({
              id: "feedback.time-zone-was-updated",
              defaultMessage: "Time Zone was updated",
            })
          );
          setTimeZone(zoneObj);
          if (regionObj) {
            setRegion(regionObj);
          }
        }
      } catch (e) {
        logger.error(e);
      }
    },
    [updateTimezone, t, toast]
  );

  // Sets the system timezone if it hasn't been set yet
  useEffect(() => {
    if (!profile) return;
    const { timezone } = profile;
    const systemTimeZone = getSystemTimezone();
    if ((!timezone || timezone === DEFAULT_TIMEZONE) && systemTimeZone) {
      updateTimezone({
        timezone: systemTimeZone.name,
      });
    }
  }, [profile, updateTimezone]);

  // Sets the user's timezone in the application.
  // If there's a discrepancy between the user's timezone
  // and the system timezone, it displays an alert
  useEffect(() => {
    if (
      !isTimezoneMismatchChecked.current &&
      entity?.id &&
      profile?.timezone &&
      profile.timezone !== DEFAULT_TIMEZONE
    ) {
      const timezoneMismatchAlertShown = getStorageItemWithFallback(
        TIMEZONE_MISMATCH_ALERT_SHOWN,
        false
      );
      const systemTimeZone = getSystemTimezone();
      const isTimezoneDifference = systemTimeZone?.name !== profile.timezone;
      if (isTimezoneDifference && !timezoneMismatchAlertShown) {
        setShowTimezoneMismatchAlert(true);
        saveItemToStorage(TIMEZONE_MISMATCH_ALERT_SHOWN, true);
      } else if (!isTimezoneDifference && timezoneMismatchAlertShown) {
        localStorage.removeItem(TIMEZONE_MISMATCH_ALERT_SHOWN);
      }
      isTimezoneMismatchChecked.current = true;
    }

    const initTimeZone = initZone(profile?.timezone);
    setTimeZone(initTimeZone);
    setRegion(initRegion(initTimeZone));
  }, [profile?.timezone, entity?.id]);

  const changeRegion = useCallback(
    (regionObj: Country) => {
      const zoneObj = getTimezonesForCountry(regionObj.id)[0];
      changeZone(zoneObj, regionObj);
    },
    [changeZone]
  );

  const openTimezoneMismatchAlert = useCallback(
    () => setShowTimezoneMismatchAlert(true),
    []
  );

  const contextValue = useMemo(
    () => ({
      timeZone,
      region,
      isLoading,
      isTimezoneMismatch,
      isTimezoneMismatchAlertOpen: showTimezoneMismatchAlert,
      openTimezoneMismatchAlert,
      changeRegion,
      changeZone,
    }),
    [
      timeZone,
      region,
      isLoading,
      openTimezoneMismatchAlert,
      changeRegion,
      changeZone,
      isTimezoneMismatch,
      showTimezoneMismatchAlert,
    ]
  );

  return (
    <TimeZoneContext.Provider value={contextValue}>
      <TimezoneAlert
        isOpen={showTimezoneMismatchAlert}
        onClose={() => setShowTimezoneMismatchAlert(false)}
      />
      {children}
    </TimeZoneContext.Provider>
  );
};

const useTimeZone = () => {
  const context = useContext(TimeZoneContext);

  if (!context) {
    throw new HookOutOfContextError("useTimeZone", "TimeZoneContext");
  }

  return context;
};

export { TimeZoneProvider, useTimeZone };
