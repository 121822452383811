import { UserCPanelModel } from "@jugl-web/rest-api";
import { ConfirmationPopup } from "@jugl-web/ui-components/cross-platform";
import { useToast, useTranslations } from "@jugl-web/utils";
import { usersApi } from "@web-src/features/api/createApi";
import useEntity from "@web-src/features/app/hooks/useEntity";
import React from "react";
import { useCPanelPageContext } from "../../CPanelPageProvider";

export const DeleteUsersConfirmation: React.FC<{
  isOpen: boolean;
  users: UserCPanelModel[];
  onSuccess: () => void;
  onRequestClose: () => void;
}> = ({ isOpen, users, onRequestClose, onSuccess }) => {
  const { t } = useTranslations();
  const { entity } = useEntity();
  const { toast } = useToast({ variant: "web" });
  const { refetchUsers$ } = useCPanelPageContext();
  const [deleteUserApi] = usersApi.useDeleteUserMutation();
  const deleteUsers = async () => {
    if (!entity?.id) return;
    const data = await Promise.all(
      users.map((user) =>
        deleteUserApi({
          entityId: entity.id,
          entityRelId: user.entity_rel_id,
        })
      )
    );
    // TODO: better error handling
    if (data?.[0] && "data" in data[0]) {
      refetchUsers$.next();
      toast(
        t(
          {
            id: "cpanel-page.deleted-user",
            defaultMessage:
              "{usersCount, plural, one {# User} other {# Users}} was deleted",
          },
          {
            usersCount: users.length,
          }
        )
      );
      onSuccess();
      onRequestClose();
    }
  };

  return (
    <ConfirmationPopup
      variant="web"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={
        users.length === 1
          ? t({
              id: "cpanel-page.delete-user",
              defaultMessage: "Delete user",
            })
          : t({
              id: "cpanel-page.delete-users",
              defaultMessage: "Delete users",
            })
      }
      message={
        users.length === 1
          ? t(
              {
                id: "cpanel-page.delete-user-message",
                defaultMessage:
                  "Are you sure you want to delete user {username}?",
              },
              {
                username: users[0].display_name,
              }
            )
          : t(
              {
                id: "cpanel-page.delete-users-message",
                defaultMessage:
                  "Are you sure you want to delete users {usernames}?",
              },
              {
                usernames: users.map(
                  (user, idx) =>
                    `${user.display_name}${idx < users.length - 1 && ", "}`
                ),
              }
            )
      }
      hasCancelButton
      buttons={[
        {
          color: "tertiary",
          label: t({
            id: "common.delete",
            defaultMessage: "Delete",
          }),
          onClick: deleteUsers,
        },
      ]}
    />
  );
};
