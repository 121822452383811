// TODO: remove
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import SideBar, { InlineSideBar } from "@web-src/components/SideBar";
import useEntity from "@web-src/features/app/hooks/useEntity";
import {
  Button,
  InteractiveContainer,
  LoadingSpinner,
} from "@jugl-web/ui-components/cross-platform";
import { useMe } from "@web-src/features/app/hooks/useMe";
import SearchableCheckboxList from "@web-src/components/SearchableCheckboxList";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useTranslations } from "@jugl-web/utils";
import useActiveChat from "@web-src/features/chats/hooks/useActiveChat";
import { HeadlessUsersList } from "@jugl-web/domain-resources/users/components/HeadlessUsersList";
import { UserListItem } from "@jugl-web/domain-resources/users/components/UserListItem";
import { ListHeading } from "@jugl-web/ui-components/cross-platform/list/ListHeading";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import {
  EntitySpaceType,
  UserGeneralProfile,
  useRestApiProvider,
} from "@jugl-web/rest-api";
import WorkspaceForm from "../WorkspaceForm";
import { ReactComponent as FilterListIcon } from "./assets/filter.svg";

export const CreateWorkspaceSideBar: React.FC<{
  isOpen: boolean;
  onRequestClose: () => void;
}> = ({ isOpen, onRequestClose }) => {
  const { entity } = useEntity();
  const { t } = useTranslations();
  const { setActiveChat } = useActiveChat();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [formValue, setFormValue] = useState<{
    title: string;
    avatar?: File;
  }>();
  const [usersSelected, setUsersSelected] = useState<boolean>(false);

  const { usersApi, workspacesApi, entitySpacesApi } = useRestApiProvider();
  // TODO: add isLoading
  // TODO: pagination?
  const { data: users } = usersApi.use_DEPRECATED_getAllUsersQuery(
    entity ? { entityId: entity.id } : skipToken
  );
  const { id: myId } = useMe();
  const [searchQuery, setSearchQuery] = useState<string>("");

  const selectedUserItems = useMemo(
    () => users?.filter((item) => selectedUsers.includes(item.id)),
    [users, selectedUsers]
  );
  const [createWorkspace, { isLoading, isSuccess }] =
    workspacesApi.useCreateWorkspaceMutation();

  const { data: spaces, isLoading: spacesIsLoading } =
    entitySpacesApi.use_DEPRECATED_allEntitySpacesListQuery(
      entity
        ? {
            entityId: entity.id,
            params: {
              type: EntitySpaceType.dept,
            },
          }
        : skipToken
    );
  // TODO: type
  const spacesFilters = useMemo(
    () =>
      spaces?.map(({ id, info }: any) => ({
        id,
        value: id,
        label: info?.title,
      })),
    [spaces]
  );
  // TODO: types
  const [filterOpened, setFilterOpened] = useState<boolean>();
  const [selectedFilters, setSelectedFilters] = useState<
    | {
        spaces?: string[];
      }
    // TODO: type
    | any
  >({});
  const handleIsSelected = useCallback(
    (key: any, item: any, count: any) => {
      if (item === "all") {
        return selectedFilters[key]
          ? selectedFilters[key].length === count
          : false;
      }
      return selectedFilters[key] ? selectedFilters[key].includes(item) : false;
    },
    [selectedFilters]
  );
  const handleSelection = (key: any, checked: any, items: any) => {
    const selectedFiltersCopy = { ...selectedFilters };
    if (!selectedFiltersCopy[key]) {
      selectedFiltersCopy[key] = [];
    }
    if (checked) {
      selectedFiltersCopy[key] = [
        ...new Set([
          ...selectedFiltersCopy[key],
          ...items.map((item: any) => item.value),
        ]),
      ];
    } else {
      selectedFiltersCopy[key] = selectedFiltersCopy[key].filter(
        (item: any) => !items.map(({ value }: any) => value).includes(item)
      );
    }
    setSelectedFilters(selectedFiltersCopy);
  };
  const handleClearFilters = () => {
    setSelectedFilters({});
    setFilterOpened(false);
  };

  useEffect(() => {
    if (isSuccess) {
      onRequestClose();
    }
  }, [isSuccess, onRequestClose]);

  const handleUserSelect = useCallback((selectedUser: UserGeneralProfile) => {
    setSelectedUsers((prev) =>
      prev.includes(selectedUser.id)
        ? prev.filter((item) => item !== selectedUser.id)
        : [...prev, selectedUser.id]
    );
  }, []);

  // TODO: beat into two components
  const sideBarData: {
    title: string;
    submitFunction: () => void;
    submitEndabled: () => boolean;
    submitText: string;
    $content: React.ReactNode;
    onRequestClose: () => void;
  } = useMemo(() => {
    if (usersSelected) {
      return {
        title: t({
          id: "chats-page.create-group-chat",
          defaultMessage: "Create New Group Chat",
        }),
        submitEndabled: () => !!formValue?.title.trim(),
        submitFunction: async () => {
          if (!entity?.id || !formValue?.title) {
            return;
          }
          const workspace = await createWorkspace({
            entityId: entity.id,
            data: {
              opts: {
                title: formValue.title,
              },
              participants: selectedUsers,
              display_pic_file: formValue?.avatar,
            },
          });
          if ("data" in workspace) {
            // TODO: type
            setActiveChat((workspace.data as any).id, true);
          }
        },
        submitText: t({
          id: "common.create",
          defaultMessage: "Create",
        }),
        $content: (
          <div>
            <WorkspaceForm onChange={setFormValue} />
            {entity ? (
              <>
                {selectedUserItems?.map((user) => (
                  <UserListItem
                    key={user.id}
                    entityId={entity.id}
                    userId={user.id}
                    variant="web"
                    defaultSubTitle="role"
                  />
                ))}
              </>
            ) : null}
          </div>
        ),
        onRequestClose,
      };
    }
    return {
      title: t({
        id: "chats-page.create-group-chat",
        defaultMessage: "Create New Group Chat",
      }),
      submitFunction: () => {
        setUsersSelected(true);
      },
      submitEndabled: () => selectedUsers.length > 0,
      submitText: t({
        id: "common.continue",
        defaultMessage: "Continue",
      }),
      $content: (
        <div className="flex h-full flex-col">
          <div className="flex flex-col gap-2.5 py-6 px-8">
            <span className="font-bold">
              {t({
                id: "chats-page.select-members",
                defaultMessage: "Select Members",
              })}
            </span>
            <div className="flex items-center gap-2">
              <SearchInput
                className="my-2"
                variant="blank"
                onChange={(e) => setSearchQuery(e.currentTarget.value)}
                containerClassName="w-full"
              />
              <InteractiveContainer onClick={setFilterOpened.bind(null, true)}>
                <FilterListIcon
                  className={
                    filterOpened || selectedFilters?.spaces?.length
                      ? "text-gradients-success"
                      : "text-primary"
                  }
                />
              </InteractiveContainer>
            </div>
          </div>
          <div className="flex-1">
            {entity ? (
              <HeadlessUsersList
                fetchParams={{
                  entityId: entity.id,
                  searchQuery,
                  excludeUsers: myId,
                  departments: selectedFilters?.spaces?.join(","),
                }}
                userRenderer={(user) => (
                  <UserListItem
                    entityId={entity.id}
                    userId={user.id}
                    variant="web"
                    mode="check"
                    isSelected={selectedUsers.includes(user.id)}
                    onSelect={handleUserSelect}
                    defaultSubTitle="role"
                    highlightText={searchQuery}
                  />
                )}
                // TODO: re-check with UI
                emptyContent={
                  <div className="flex h-full w-full items-center justify-center text-center text-sm text-gray-500">
                    No users found
                  </div>
                }
                // TODO: re-check with UI
                loadingContent={
                  <div className="flex h-full w-full items-center justify-center">
                    <LoadingSpinner />
                  </div>
                }
                virtualized
                segregate={{
                  type: "department",
                  groupHeaderRenderer: ({ title }) => (
                    <ListHeading>{title}</ListHeading>
                  ),
                }}
              />
            ) : null}
          </div>
        </div>
      ),
      onRequestClose,
    };
  }, [
    usersSelected,
    t,
    filterOpened,
    selectedFilters?.spaces,
    entity,
    myId,
    searchQuery,
    onRequestClose,
    selectedUserItems,
    formValue?.title,
    formValue?.avatar,
    createWorkspace,
    selectedUsers,
    setActiveChat,
    handleUserSelect,
  ]);
  return (
    <SideBar
      onRequestClose={sideBarData.onRequestClose}
      isOpen={isOpen}
      title={sideBarData.title}
      leftContent={
        filterOpened ? (
          <InlineSideBar
            title={t({ id: "common.filter", defaultMessage: "Filter" })}
            onRequestClose={setFilterOpened.bind(null, false)}
            footer={
              <Button
                variant="contained"
                fullWidth
                color="grey"
                isDisabled={!!selectedFilters.length}
                onClick={handleClearFilters}
              >
                {t({
                  id: "common.clear",
                  defaultMessage: "Clear",
                })}
              </Button>
            }
          >
            {spacesIsLoading ? (
              <div className="mt-5 flex items-center justify-center">
                <LoadingSpinner />
              </div>
            ) : (
              <div className="py-4 px-8">
                <SearchableCheckboxList
                  showSearch
                  items={spacesFilters || []}
                  name="Department"
                  isSelected={(item) =>
                    handleIsSelected("spaces", item, spacesFilters?.length)
                  }
                  onSelect={(checked, items) =>
                    handleSelection("spaces", checked, items)
                  }
                />
              </div>
            )}
          </InlineSideBar>
        ) : undefined
      }
      footer={
        <Button
          onClick={sideBarData.submitFunction}
          variant="contained"
          fullWidth
          isDisabled={!sideBarData.submitEndabled() || isLoading}
        >
          {sideBarData.submitText}
        </Button>
      }
    >
      {sideBarData.$content}
    </SideBar>
  );
};
