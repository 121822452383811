import { cx } from "@jugl-web/utils";
import { FC, ReactNode, useState } from "react";
import {
  Collapse,
  InteractiveContainer,
} from "@jugl-web/ui-components/cross-platform";
import { ReactComponent as ChevronDownIcon } from "../../icons/chevron-down.svg";

export const HomeSideBarAccordion: FC<{
  title: ReactNode;
  isHomeSidebarExpanded: boolean;
  children: ReactNode;
  onSideBarExpand: () => void;
  onClick?: () => void;
}> = ({ title, isHomeSidebarExpanded, children, onSideBarExpand, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    if (!isHomeSidebarExpanded) {
      onSideBarExpand();
      setIsOpen(true);
    } else {
      setIsOpen((prev) => !prev);
    }

    onClick?.();
  };

  return (
    <div>
      <InteractiveContainer
        onClick={handleClick}
        className={cx(
          "mx-2.5 flex items-center rounded-lg text-white transition-colors duration-300 hover:bg-[#107CDE]",
          isHomeSidebarExpanded
            ? "h-[39px] justify-between"
            : "h-[57px] justify-center"
        )}
      >
        {title}
        {isHomeSidebarExpanded && (
          <div
            className={cx(
              "bg-primary-400 text-primary-100 mr-4 flex h-[18px] w-[18px] items-center justify-center rounded transition-transform duration-300",
              isOpen && "rotate-180"
            )}
          >
            <ChevronDownIcon />
          </div>
        )}
      </InteractiveContainer>
      <Collapse
        isOpen={isOpen}
        className={cx(!isHomeSidebarExpanded && "hidden")}
      >
        {children}
      </Collapse>
    </div>
  );
};
