import React, { PropsWithChildren } from "react";
import {
  InteractiveContainer,
  Menu,
  MenuItem,
} from "@jugl-web/ui-components/cross-platform";
import { cx } from "@jugl-web/utils";
import { ReactComponent as MenuIcon } from "./assets/menu-icon.svg";
import { ReactComponent as ArrowIcon } from "./assets/arrow.svg";
import { PromoBackground } from "../../PromoBackground";
import { PromoText } from "../../PromoText";

export type SideImageLayoutProps = PropsWithChildren<{
  onBackClick?: () => void;
  className?: string;
  menu?: [
    { id: string; label: string; icon?: JSX.Element; onClick: () => void }
  ];
}>;

export const SideImageLayout: React.FC<SideImageLayoutProps> = ({
  children,
  onBackClick,
  className,
  menu,
}) => (
  <div
    className={cx(
      "fixed top-0 left-0 bottom-0 right-0 grid-cols-2 overflow-y-auto md:grid"
    )}
  >
    <div
      className={cx("relative flex h-full w-full  overflow-y-auto bg-white")}
    >
      <div className={cx("m-auto py-10", className)}>{children}</div>
      {!!onBackClick && (
        <InteractiveContainer
          onClick={onBackClick}
          className="bg-grey-200 absolute top-5 left-5 z-50 flex h-10 w-10 items-center justify-center rounded-lg  hover:brightness-95"
        >
          <ArrowIcon />
        </InteractiveContainer>
      )}
      {!!menu && (
        <Menu
          placement="bottom-end"
          sections={[
            [
              ...menu.map(
                (item) =>
                  ({
                    id: item.id,
                    label: item.label,
                    icon: item.icon,
                    onSelect: (e, close) => {
                      item.onClick();
                      close();
                    },
                  } as MenuItem)
              ),
            ],
          ]}
          renderTrigger={({ Trigger, triggerRef }) => (
            <Trigger
              ref={triggerRef}
              className="bg-grey-200 absolute top-5 right-5 z-50 flex h-10 w-10 items-center justify-center rounded-lg hover:brightness-95"
              as={InteractiveContainer}
            >
              <MenuIcon />
            </Trigger>
          )}
        />
      )}
    </div>
    <div className="relative hidden h-full flex-col md:flex">
      <div className="absolute h-full w-full">
        <PromoBackground />
      </div>
      <div className="z-10 flex h-full items-center justify-center p-5">
        <PromoText />
      </div>
    </div>
  </div>
);
