import { Mention } from "@jugl-web/ui-components/cross-platform/RichTextarea";
import { HookOutOfContextError } from "@jugl-web/utils";
import { OpenConfirmationDialogFn } from "@jugl-web/utils/hooks/useConfirmationDialogState";
import { createContext, useContext } from "react";
import { TaskChecklistProps } from "./types";

interface TaskChecklistContextValue
  extends Required<
    Pick<
      TaskChecklistProps,
      | "entityId"
      | "meId"
      | "isCompleteInSpecificOrder"
      | "isEditable"
      | "isCompletable"
      | "isAssignable"
      | "onlyReportees"
      | "displayDueDateAs"
    >
  > {
  mentions: Mention[];
  onTriggerSaveBeforeLeavingDialog: OpenConfirmationDialogFn;
  onTriggerDeleteConfirmationDialog: OpenConfirmationDialogFn;
}

const TaskChecklistContext = createContext<TaskChecklistContextValue | null>(
  null
);

export const useTaskChecklistContext = () => {
  const context = useContext(TaskChecklistContext);

  if (!context) {
    throw new HookOutOfContextError(
      "useTaskChecklistItem",
      "TaskChecklistItemContext"
    );
  }

  return context;
};

export const TaskChecklistProvider = TaskChecklistContext.Provider;
