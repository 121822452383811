import { useTranslations } from "@jugl-web/utils";
import React, { useMemo, useState } from "react";
import { BroadcastChannel } from "broadcast-channel";
import { BroadcastChannelName } from "@web-src/features/app/types";
import { getSessionId } from "@web-src/utils/session";
import {
  InteractiveContainer,
  OTPVerificationForm,
} from "@jugl-web/ui-components/cross-platform";
import { useVerifyOtpMutation } from "@web-src/features/auth/authApi";
import { useAppDispatch } from "@web-src/store";
import { proceedLogin } from "@web-src/features/auth/authSlice";
import { AuthStep, LoginMethod, PhoneMethod } from "../../types";
import { useLoginPage } from "../../providers/LoginPageProvider";
import { ReactComponent as ArrowIcon } from "./assets/arrow.svg";

const channel = new BroadcastChannel(BroadcastChannelName.auth);

const OtpVerification: React.FC = () => {
  const {
    loginMethod,
    email,
    phone,
    phoneMethod,
    setAuthStep,
    id,
    requestOtpVerification,
  } = useLoginPage();
  const { t } = useTranslations();
  const emitterId = getSessionId();
  const dispatch = useAppDispatch();
  const [incorrectOtp, setIncorrectOtp] = useState(false);
  const [verifyOtp, { isLoading }] = useVerifyOtpMutation();

  const handleSubmit = async (otp: string) => {
    if (isLoading) return;
    const resp = await verifyOtp({
      id,
      otp,
    });
    if ("data" in resp) {
      const { token, refreshToken } = resp.data;
      channel.postMessage({ emitterId });
      dispatch(proceedLogin({ token, refreshToken }));
    } else {
      setIncorrectOtp(true);
    }
  };

  const { title, subTitle, buttonText } = useMemo(() => {
    if (loginMethod === LoginMethod.email) {
      return {
        title: t({
          id: "login-page.enter-code-sent-to-email",
          defaultMessage: "Enter Code Sent to Your Email",
        }),
        subTitle: t(
          {
            id: "login-page.we-sent-it-to-email",
            defaultMessage: "We sent it to the Email {email}",
          },
          {
            email: <span className="text-primary-800">{email}</span>,
          }
        ),
        buttonText: t({
          id: "login-page.change-email",
          defaultMessage: "Change email",
        }),
      };
    }
    if (phoneMethod === PhoneMethod.sms) {
      return {
        title: t({
          id: "login-page.enter-code-sent-to-number",
          defaultMessage: "Enter Code Sent to Your Number",
        }),
        subTitle: t(
          {
            id: "login-page.we-sent-it-to-number",
            defaultMessage: "We sent it to the Phone Number {number}",
          },
          {
            number: (
              <span className="text-primary font-medium">{`${phone.code}${phone.phone}`}</span>
            ),
          }
        ),
        buttonText: t({
          id: "login-page.change-number",
          defaultMessage: "Change phone number",
        }),
      };
    }
    return {
      title: t({
        id: "login-page.enter-code-sent-to-whatsapp",
        defaultMessage: "Enter Code Sent to Your WhatsApp Account",
      }),
      subTitle: t(
        {
          id: "login-page.we-sent-it-to-whatsapp",
          defaultMessage:
            "We sent it to the WhatsApp Account with number {number}",
        },
        {
          number: (
            <span className="text-primary font-medium">{`${phone.code}${phone.phone}`}</span>
          ),
        }
      ),
      buttonText: t({
        id: "login-page.change-number",
        defaultMessage: "Change phone number",
      }),
    };
  }, [loginMethod, t, email, phone.code, phone.phone, phoneMethod]);

  return (
    <div className="flex h-full grow flex-col items-center gap-5">
      <InteractiveContainer
        onClick={() => setAuthStep(AuthStep.login)}
        className="bg-grey-100 hover:bg-grey-200 absolute top-5 left-5 flex h-12 w-12 items-center justify-center rounded-lg drop-shadow-[0_4px_18px_rgba(18,22,34,0.05)] duration-300"
      >
        <ArrowIcon />
      </InteractiveContainer>
      <div className="flex h-full flex-1 flex-col items-center">
        <OTPVerificationForm
          title={title}
          subTitle={
            <div className="flex flex-col items-center gap-[6px]">
              <span className="text-grey-800 text-sm">{subTitle}</span>
              <InteractiveContainer
                className="text-grey-900 hover:text-primary-800 w-max px-3 text-sm underline transition-all duration-300 hover:font-medium"
                onClick={() => setAuthStep(AuthStep.login)}
              >
                {buttonText}
              </InteractiveContainer>
            </div>
          }
          onSubmit={handleSubmit}
          onResend={requestOtpVerification}
          error={incorrectOtp ? "Incorrect OTP" : undefined}
        />
      </div>
    </div>
  );
};

export default OtpVerification;
