import { useCallback, useEffect, useRef } from "react";

type DebouncedCallback<TArgs extends unknown[]> = (...args: TArgs) => void;

export const useDebouncedCallback = <TArgs extends unknown[]>(
  callback: DebouncedCallback<TArgs>,
  delay: number
): DebouncedCallback<TArgs> => {
  const timeoutRef = useRef<number>();

  useEffect(
    () => () => {
      if (typeof timeoutRef.current !== "undefined") {
        window.clearTimeout(timeoutRef.current);
      }
    },
    []
  );

  return useCallback(
    (...args) => {
      if (typeof timeoutRef.current !== "undefined") {
        window.clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = window.setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay]
  );
};
