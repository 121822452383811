import { EntityModel } from "@jugl-web/rest-api";
import { Alert } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import { EntityAvatarWithDangerIndicator } from "@web-src/modules/entities/components/EntityAvatarWithDangerIndicator";

interface LastEntityAdminWarningDialogProps {
  isOpen: boolean;
  entity: EntityModel | null;
  onDeleteEntityRequest: () => void;
  onClose: () => void;
}

export const LastEntityAdminWarningDialog: FC<
  LastEntityAdminWarningDialogProps
> = ({ isOpen, entity, onDeleteEntityRequest, onClose }) => {
  const { t } = useTranslations();

  return (
    <Alert
      isOpen={isOpen}
      onRequestClose={onClose}
      isCloseButtonVisible
      title={t({
        id: "cpanel-page.manage-workspace-admins-dialog-title",
        defaultMessage: "Manage workspace admins",
      })}
      img={<EntityAvatarWithDangerIndicator entity={entity} />}
      content={
        <span>
          {t(
            {
              id: "cpanel-page.manage-workspace-admins-dialog-description",
              defaultMessage:
                "You are the only admin of {entityName} workspace. Before you leave, please assign additional admins to this workspace.",
            },
            {
              entityName: (
                <span className="text-primary-800 font-semibold">
                  {entity?.name}
                </span>
              ),
            }
          )}
        </span>
      }
      buttons={[
        {
          text: t({ id: "common.okay", defaultMessage: "Okay" }),
          color: "primary",
          onClick: onClose,
        },
        {
          text: t({
            id: "workspace-page.delete-workspace",
            defaultMessage: "Delete workspace",
          }),
          variant: "text",
          color: "tertiary",
          onClick: onDeleteEntityRequest,
        },
      ]}
    />
  );
};
