import { FC } from "react";
import { SubscriptionInfoModel } from "@jugl-web/rest-api";
import { useEntitySubscriptionProvider } from "@web-src/modules/entities/providers/EntitySubscriptionProvider";
import { useToast } from "@jugl-web/utils";
import { ReactComponent as PaymentMethodIcon } from "./assets/payment-method-icon.svg";
import { InfoItem } from "../InfoItem";

export const PaymentMethodInfoItem: FC<{
  subscriptionInfo: SubscriptionInfoModel;
}> = ({ subscriptionInfo: { card } }) => {
  const { toast } = useToast({ variant: "web" });
  const { updateCardDetails } = useEntitySubscriptionProvider();
  return (
    <InfoItem
      action={{
        text: "Update",
        color: "gray",
        onClick: async () => {
          const result = await updateCardDetails();
          if (result.result === "cancelled") {
            return;
          }
          if (result.result === "success") {
            toast("Card updated successfully");
          } else {
            toast("Something went wrong, please try again later", {
              variant: "error",
            });
          }
        },
      }}
      title={
        card ? `${card.card.brand} **** ${card.card.last4}` : "Info not added"
      }
      description="Payment Method"
      icon={<PaymentMethodIcon />}
    />
  );
};
