import React, { memo, useMemo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ChatMessageActionType } from "@web-src/features/chats/types";
import { IconButton } from "@mui/material";
import ReplyIcon from "@mui/icons-material/Reply";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslations } from "@jugl-web/utils";
import {
  clearMessageBody,
  getChatMessageBody,
} from "@web-src/features/chats/utils";
import UserProfileName from "@web-src/features/users/components/UserProfileName";
import ChatMessageAttachment from "../ChatMessageBubble/ChatMessageAttachment";
import { ChatMessage } from "../../types";

const MessageActionItem: React.FC<{
  message?: ChatMessage;
  onCancel: () => void;
  action?: ChatMessageActionType;
}> = memo(({ message, onCancel, action }) => {
  const { t } = useTranslations();
  const body = useMemo(
    () =>
      message?.payload?.body
        ? clearMessageBody(getChatMessageBody(message))
        : "",
    [message]
  );
  if (!message) {
    return null;
  }
  return (
    <Box
      sx={{
        display: "block",
        width: "100%",
        pb: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Box>
          {action === ChatMessageActionType.edit && (
            <>
              <Typography variant="footnoteBold" color="primary">
                {t({
                  id: "common.edit",
                  defaultMessage: "Edit",
                })}
              </Typography>
            </>
          )}
          {action === ChatMessageActionType.reply && (
            <>
              <Typography variant="footnoteBold" color="primary">
                <ReplyIcon sx={{ fontSize: "14px" }} />
                {t({
                  id: "chats-page.reply-to",
                  defaultMessage: "Reply to",
                })}
              </Typography>
              <Typography
                variant="footnoteBold"
                sx={{ display: "inline-block", marginLeft: "5px" }}
              >
                <UserProfileName userId={message?.from} />
              </Typography>
            </>
          )}
        </Box>
        <IconButton size="small" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </Box>
      {message.payload.attachments?.length && <ChatMessageAttachment />}
      <Typography
        variant="body"
        fontSize="15px"
        lineHeight="18px"
        color="dark.A100"
        whiteSpace="pre-wrap"
        sx={{ wordWrap: "break-word" }}
      >
        {body}
      </Typography>
    </Box>
  );
});

export default MessageActionItem;
