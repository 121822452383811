import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import { FC } from "react";
import Lottie from "react-lottie";
import { useTranslations } from "@jugl-web/utils";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import animationIconData from "./assets/animation-icon.json";
import { ReactComponent as AddIcon } from "./assets/add-icon.svg";

export const CreateNewEntityButton: FC = () => {
  const { navigateToPage } = useNavigation();
  const { t } = useTranslations();

  const handleAddOrganizationClick = async () => {
    navigateToPage("entityCreate");
  };

  return (
    <InteractiveContainer
      className="flex h-[84px] items-center overflow-hidden rounded-2xl"
      onClick={handleAddOrganizationClick}
      as="div"
      style={{
        background: "linear-gradient(270deg, #DAEFFB 0.03%, #F6F3FF 99.98%)",
      }}
    >
      <div className="relative -top-[6px]">
        <Lottie
          options={{ animationData: animationIconData }}
          width={84}
          height={84}
        />
      </div>
      <div className="flex-1">
        <div className="font-medium leading-4">
          {t({
            id: "entities-page.create-new-workspace",
            defaultMessage: "Create New Workspace",
          })}
        </div>
        <div className="leading-2 text-grey-1000 text-xs">
          {t({
            id: "entities-page.tap-to-create",
            defaultMessage: "Tap to create",
          })}
        </div>
      </div>
      <div className="bg-primary-500 mr-[18px] flex h-[28px] w-[28px] items-center justify-center rounded-[6px]">
        <AddIcon />
      </div>
    </InteractiveContainer>
  );
};
