export enum FileType {
  dir = "dir",
  file = "file",
  favourite = "favourite",
}

export enum FileAccessType {
  anyone = "anyone",
  restricted = "restricted",
}

export enum PermissionsMode {
  private = "private",
  public = "public",
}

export enum ActionType {
  rename = "rename",
  edit = "edit",
  add = "add",
}

export enum DriveItemDisplayType {
  tiles = "TILES",
  list = "LIST",
}

export type DriveFile = {
  access_type: FileAccessType;
  created_by: string;
  id: string;
  name: string;
};

export type DirectoryListItem = {
  content_type: string;
  created_by: string;
  doc_count: number;
  entity_id: string;
  mime_type: string;
  id: string;
  name: string;
  permission: {
    mode: PermissionsMode;
    users: { id: string; img: string; name: string }[];
  };
  preview_available: boolean;
  preview_url: string;
  stream_url: string;
  size: number;
  type: FileType;
  updated_at: string;
  workspace_id: string;
  status: "valid" | "invalid";
  meta?: {
    sys_dir?: boolean;
    name_format?: "user_id";
  };
  short_url: string;
};

export type DirectoryList = {
  data: DirectoryListItem[];
  remaining_space: number;
  total_space: number;
  current_dir_id: string;
  current_dir_name: string;
  short_url: string;
  current_dir_permission: {
    mode: PermissionsMode;
    users: { id: string; img: string; name: string }[];
  };
  current_dir_meta?: {
    sys_dir: boolean;
  };
};

export type Directory = {
  entity_id: string;
  id: string;
  name: string;
  status: "valid" | "invalid";
  type: "dir";
  updated_at: string;
  workspace_id: string;
  short_url: string | null;
};
