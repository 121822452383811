import { TaskPropertyButton } from "@jugl-web/ui-components/cross-platform/tasks/TaskPropertyButton";
import { cx, useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import { ReactComponent as AttachmentIcon } from "../../assets/attachment.svg";
import { FieldComponentProps } from "../../types";

export const AttachmentsField: FC<FieldComponentProps<"attachments">> = ({
  count,
  onClick,
}) => {
  const { t } = useTranslations();

  const hasAnyAttachment = count > 0;

  return (
    <TaskPropertyButton
      className={cx(hasAnyAttachment && "bg-primary-50 text-primary-900")}
      startIcon={
        <AttachmentIcon className={cx(hasAnyAttachment && "text-primary")} />
      }
      onClick={onClick}
    >
      {t(
        {
          id: "task-properties-panel-component.attachments-count",
          defaultMessage:
            "{count} {count, plural, one {attachment} other {attachments}}",
        },
        { count }
      )}
    </TaskPropertyButton>
  );
};
