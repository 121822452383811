import { ExpectedTaskCustomDropdownFieldValue } from "@jugl-web/rest-api/tasks";
import {
  ResourcePickerDrawer,
  ResourcePickerDrawerProps,
} from "@jugl-web/ui-components/mobile/ResourcePickerDrawer";
import {
  ResourcePickerPopover,
  ResourcePickerPopoverProps,
} from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { assert, useAppVariant } from "@jugl-web/utils";
import { FC, useState } from "react";
import { CustomFieldProps } from "../../types";
import { getCustomFieldButtonId } from "../../utils";
import {
  TaskCustomFieldPropertyButton,
  TaskCustomFieldPropertyButtonProps,
} from "../TaskCustomFieldPropertyButton";

export const CustomDropdownField: FC<CustomFieldProps> = ({
  field,
  rawValue,
  isReadonly,
  onChange,
  onFinishEditing,
}) => {
  assert(field.type === "dropdown", "Field should be of type dropdown");

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { isMobile } = useAppVariant();

  const commonButtonProps: TaskCustomFieldPropertyButtonProps = {
    isDisabled: isReadonly,
    id: getCustomFieldButtonId(field.id),
    field,
    rawValue,
  };

  const commonResourcePickerProps = {
    title: field.name,
    items: (field.values || []).map((value) => ({ id: value.id, value })),
    selectionBehavior: { mode: "single", canToggle: true },
    hasSearch: true,
    defaultSelectedIds: rawValue ? [rawValue] : undefined,
    maxVisibleItems: 7,
    itemSize: "lg",
    spaceBetweenItems: "compact",
    renderLabel: (item) => item.value.value,
    onSelect: ({ item, isSelected, onClose }) => {
      onChange?.(isSelected ? item.id : "");
      onClose();
    },
  } satisfies Partial<
    | ResourcePickerDrawerProps<ExpectedTaskCustomDropdownFieldValue>
    | ResourcePickerPopoverProps<ExpectedTaskCustomDropdownFieldValue>
  >;

  if (isMobile) {
    return (
      <>
        <TaskCustomFieldPropertyButton
          onClick={() => setIsDialogOpen(true)}
          {...commonButtonProps}
        />
        <ResourcePickerDrawer
          isOpen={isDialogOpen}
          onClose={() => {
            setIsDialogOpen(false);
            onFinishEditing();
          }}
          {...commonResourcePickerProps}
        />
      </>
    );
  }

  return (
    <ResourcePickerPopover
      placement="bottom-start"
      className="w-[375px]"
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          ref={triggerRef}
          as={TaskCustomFieldPropertyButton}
          {...commonButtonProps}
        />
      )}
      onUnmount={onFinishEditing}
      {...commonResourcePickerProps}
    />
  );
};
