import React from "react";
import { AvatarSelect, AvatarSelectSize } from "@jugl-web/ui-components/web";
import { useTranslations } from "@jugl-web/utils";
import {
  Button,
  Checkbox,
  TextField,
} from "@jugl-web/ui-components/cross-platform";
import { Link } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { usersApi } from "@web-src/features/api/createApi";
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from "@jugl-web/utils/consts";
import {
  useFileSelect,
  avatarFileSelectConfig,
} from "@jugl-web/domain-resources/files/providers/FileSelectProvider";

type FormValues = {
  firstName: string;
  lastName: string;
  avatar?: File;
  consent: boolean;
};

const ProfileCreationForm: React.FC = () => {
  const { t } = useTranslations();
  const {
    register,
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<FormValues>();

  const { selectFile } = useFileSelect(avatarFileSelectConfig);

  const [createProfile, { isLoading: isCreateLoading }] =
    usersApi.useCreateProfileMutation();

  const [updateProfilePicture, { isLoading: isProfilePictureLoading }] =
    usersApi.useUpdateUserProfilePictureMutation();

  const onSubmit: SubmitHandler<FormValues> = async (params) => {
    await createProfile({
      first_name: params.firstName,
      last_name: params.lastName,
    });
    if (params.avatar) {
      await updateProfilePicture(params.avatar);
    }
  };

  return (
    <form className="flex flex-col gap-10" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-4 text-center">
        <span className="text-dark text-[32px] font-semibold">
          {t({
            id: "profile-form-page.new-profile",
            defaultMessage: "New Profile",
          })}
        </span>
        <span className="text-dark-600">
          {t({
            id: "profile-form-page.create-account-provide-info",
            defaultMessage:
              "To create an account, please provide the following info",
          })}{" "}
          🙌
        </span>
      </div>
      <div className="mx-auto">
        <Controller
          name="avatar"
          control={control}
          render={({ field: { onChange, value } }) => (
            <AvatarSelect
              value={value}
              onSave={(file) => onChange(file)}
              size={AvatarSelectSize.xl}
              showRemoveButton
              externalSelectFile={selectFile}
            />
          )}
        />
      </div>
      <TextField
        isFullWidth
        autoFocus
        placeholder={t({
          id: "profile-form-page.enter-first-name",
          defaultMessage: "Enter First Name",
        })}
        {...register("firstName", {
          required: true,
          validate: (value) => !!value.trim(),
        })}
      />
      <TextField
        isFullWidth
        placeholder={t({
          id: "profile-form-page.enter-last-name",
          defaultMessage: "Enter Last Name",
        })}
        {...register("lastName", {
          required: true,
          validate: (value) => !!value.trim(),
        })}
      />
      <label
        className="text-dark-700 flex-center flex items-center gap-2"
        htmlFor="consent-checkbox"
      >
        <Checkbox
          id="consent-checkbox"
          {...register("consent", { required: true })}
        />
        <div>
          {t(
            {
              id: "profile-form-page.accepting-privacy-policy-and-terms-of-service",
              defaultMessage:
                "I agree with the <privacyPolicyLink>Privacy Policy</privacyPolicyLink> and <termsOfUseLink>Terms of use</termsOfUseLink>",
            },
            {
              privacyPolicyLink: (privacyPolicy: (string | JSX.Element)[]) => (
                <Link
                  to="https://jugl.com/privacy-policy"
                  target="_blank"
                  className="text-primary no-underline hover:brightness-90"
                >
                  {privacyPolicy}
                </Link>
              ),
              termsOfUseLink: (termsOfUse: (string | JSX.Element)[]) => (
                <Link
                  to="https://jugl.com/terms-of-service"
                  target="_blank"
                  className="text-primary no-underline hover:brightness-90"
                >
                  {termsOfUse}
                </Link>
              ),
            }
          )}
        </div>
      </label>
      <div className="flex flex-col items-center">
        <Button
          className="mx-auto w-3/4"
          type="submit"
          isDisabled={!isValid || isCreateLoading || isProfilePictureLoading}
        >
          {t({
            id: "profile-form-page.create-account",
            defaultMessage: "Create account",
          })}
        </Button>
        <div className="text-dark-500 mt-5 w-[300px] text-center text-sm leading-[160%]">
          You are agreeing to our{" "}
          <a
            className="text-primary-600"
            href={TERMS_OF_USE_URL}
            target="_blank"
            rel="noreferrer"
          >
            Terms and Conditions
          </a>{" "}
          and{" "}
          <a
            className="text-primary-600"
            href={PRIVACY_POLICY_URL}
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </form>
  );
};

export default ProfileCreationForm;
