import { PageNavParams } from "@web-src/modules/navigation/types";
import { ModulePagesConfig } from "@web-src/modules/navigation/types/ModulePagesConfigs";
import { SubscriptionPlanModuleId } from "@jugl-web/rest-api/entities/models/common-types/SubscriptionPlanModuleId";
import { CallsHistory } from "./CallsHistory";

export type CallsPagesNavigationParams = {
  callsHistory: PageNavParams;
};

export const callsPagesNavigationConfig: ModulePagesConfig<CallsPagesNavigationParams> =
  {
    callsHistory: {
      element: <CallsHistory />,
      path: "calls",
      isPrivate: true,
      subscriptionModuleRequired: SubscriptionPlanModuleId.clients,
      isAdminRequired: false,
    },
  };
