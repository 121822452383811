import { FC, useState, useEffect } from "react";
import { Alert, Text } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import Lottie from "react-lottie";
import { useSearchParams } from "react-router-dom";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import welcomeAnimation from "./assets/welcome-animation.json";

export const WelcomeToEntityAlert: FC = () => {
  const { t } = useTranslations();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { entity } = useEntitySelectedProvider();
  useEffect(() => {
    if (searchParams.get("showWelcomeAlert")) {
      setIsAlertOpen(true);
      setSearchParams({});
    }
  }, [searchParams, setSearchParams]);
  return (
    <Alert
      isCloseButtonVisible
      isOpen={isAlertOpen}
      onRequestClose={() => setIsAlertOpen(false)}
      img={
        <Lottie
          options={{ animationData: welcomeAnimation }}
          height={226}
          width={300}
        />
      }
      title={t(
        {
          id: "settings-page.welcome-alert-title",
          defaultMessage: "Welcome to {entityName}!",
        },
        {
          entityName: entity.name,
        }
      )}
      content={
        <Text variant="body2" className="text-dark-800">
          {t({
            id: "settings-page.welcome-alert-description",
            defaultMessage: "We are happy to see you in our Team! 🚀",
          })}
        </Text>
      }
      buttons={[
        {
          text: t({ id: "common.okay", defaultMessage: "Okay" }),
          color: "primary",
          onClick: () => setIsAlertOpen(false),
        },
      ]}
    />
  );
};
