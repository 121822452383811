export enum BroadcastChannelName {
  auth = "auth",
}

export enum AppMode {
  development = "development",
  production = "production",
}

export enum LoginMethod {
  otp = "otp",
  credentials = "credentials",
}

export enum TabRoute {
  tasks = "tasks",
  taskTemplates = "templates",
  chats = "chats",
  eduAdmin = "edu-admin",
  eduLessons = "edu-lessons",
  eduFeeds = "edu-feeds",
  eduAssignments = "edu-assignments",
  eduAttendance = "edu-attendance",
  eduSchoolEdit = "edu-school-edit",
  settings = "settings",
  people = "people",
  clients = "clients",
  drive = "drive",
  schedule = "schedule",
  cpanel = "cpanel",
  workspaceSettings = "workspace-settings",
  scheduledReportsForm = "scheduled-reports-form",
  scheduledReports = "scheduled-reports",
}

export enum PageRoute {
  login = "login",
  profileForm = "profile-form",
  schoolSelect = "school-select",
  orgSelect = "org-select",
  orgCreate = "org-create",
  location = "location",
  conference = "conference",
  noSubscription = "no-subscription",
}
