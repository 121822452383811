import React, { useState } from "react";

import { SidebarDrawer } from "@jugl-web/ui-components/web";
import { useTranslations, cx } from "@jugl-web/utils";
import { Button, Text } from "@jugl-web/ui-components/cross-platform";

const Component: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (message: string) => void;
}> = ({ isOpen, onClose, onSubmit }) => {
  const { t } = useTranslations();
  const [selectedOption, setSelectedOption] = useState<string>();
  const [customMessage, setCustomMessage] = useState<string>();
  const options = [
    t({
      id: "audio-call-component.reply-option-1",
      defaultMessage: "Can’t talk now. What’s up?",
    }),
    t({
      id: "audio-call-component.reply-option-2",
      defaultMessage: "I’ll Call you right back.",
    }),
    t({
      id: "audio-call-component.reply-option-3",
      defaultMessage: "I’ll Call you later.",
    }),
    t({
      id: "audio-call-component.reply-option-4",
      defaultMessage: "Can’t talk now. Call me later??",
    }),
  ];
  return (
    <SidebarDrawer
      className="width-[400px]"
      title={t({
        id: "audio-call-component.quick-reply",
        defaultMessage: "Quick Reply",
      })}
      onClose={onClose}
      isOpen={isOpen}
    >
      <SidebarDrawer.Content>
        <div className="mt-10 flex flex-col gap-4">
          {options.map((option) => (
            <div
              onClick={() => {
                setSelectedOption(option);
                setCustomMessage(undefined);
              }}
              className={cx(
                "hover:bg-grey-100 w-full cursor-pointer py-2 pl-8",
                {
                  "bg-grey-100": selectedOption === option,
                }
              )}
            >
              <Text
                variant="body2"
                className="text-dark  cursor-pointer font-bold"
              >
                {option}
              </Text>
            </div>
          ))}
        </div>
        <div className="border-grey-200 width-full mt-8 border-t-[1px] border-b-0 border-l-0 border-r-0 border-solid px-8 pt-10">
          <Text variant="timestamp" className="text-dark">
            {t({
              id: "audio-call-component.custom-message",
              defaultMessage: "Custom message...",
            })}
          </Text>
          <textarea
            placeholder={t({
              id: "audio-call-component.write-your-message",
              defaultMessage: "Write your message",
            })}
            style={{ width: "100%" }}
            className="border-grey-300 bg-grey-100 rounded-md py-3 pl-4"
            value={customMessage || ""}
            onChange={(e) => {
              setCustomMessage(e.target.value);
              setSelectedOption(undefined);
            }}
          />
        </div>
        <div className="mx-8 mt-8">
          <Button
            className="w-full"
            onClick={() => onSubmit(selectedOption || customMessage || "")}
            isDisabled={!selectedOption && !customMessage}
          >
            {t({ id: "common.send", defaultMessage: "Send" })}
          </Button>
        </div>
      </SidebarDrawer.Content>
    </SidebarDrawer>
  );
};

export const MessageSidebar = React.memo(Component);
