import React, { useMemo } from "react";
import { Select } from "@jugl-web/ui-components/cross-platform/Select";
import { Text, Input } from "@jugl-web/ui-components/cross-platform";
import { useTranslations, cx } from "@jugl-web/utils";
import {
  Event,
  EventStage,
  EventTypes,
} from "@jugl-web/rest-api/location/types";
import { isImageUrl } from "@web-src/utils/helper";
import { ReactComponent as DownloadIcon } from "./assets/download.svg";
import { ReactComponent as CloseIcon } from "./assets/close.svg";

const StyledLabel = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <span
    className={cx("text-onyx ml-4 text-xs uppercase leading-4", className)}
    style={{
      fontVariant: "all-small-caps",
    }}
  >
    {children}
  </span>
);

type EventStageKeys = keyof typeof EventStage;

export const TrackingEventDetails: React.FC<{
  onClose: () => void;
  event: Event;
}> = ({ onClose, event }) => {
  const { t } = useTranslations();
  const attachments = useMemo(
    () =>
      event?.attachmnts.map((element) => {
        if (isImageUrl(element.file_path)) {
          return (
            <div
              key={element.id}
              onClick={() => window.open(element.file_path)}
            >
              <img
                src={element.file_path}
                alt=""
                style={{
                  width: "166px",
                  height: "166px",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
              />
            </div>
          );
        }
        return (
          <div
            key={element.id}
            onClick={() => window.open(element.file_path)}
            className="bg-grey-200 flex h-[166px] w-[166px] cursor-pointer items-center justify-center"
          >
            <DownloadIcon />
          </div>
        );
      }),
    [event?.attachmnts]
  );
  return (
    <div className="absolute top-0 -right-[480px] z-[99] h-[calc(100vh-60px)] w-[480px] bg-white">
      <div className="border-px border-grey-200 flex items-center justify-between border-solid px-8">
        <Text variant="h4" className="text-dark-A100 font-bold">
          {t({
            id: "location-page.meeting-details",
            defaultMessage: "Meeting details",
          })}
        </Text>
        <CloseIcon className="text-grey-A100" onClick={onClose} />
      </div>

      <div className="flex flex-col gap-4 py-0 px-4">
        <div>
          <StyledLabel>
            {t({
              id: "location-page.event-type",
              defaultMessage: "Type of event",
            })}
          </StyledLabel>
          <Select
            disabled
            value={event?.opts?.event_type}
            className="bg-grey-100 w-full font-semibold"
          >
            <option value={EventTypes.sales}>{EventTypes.sales}</option>
            <option value={EventTypes.service}>{EventTypes.service}</option>
            <option value={EventTypes.others}>{EventTypes.others}</option>
          </Select>
        </div>
        <div>
          <StyledLabel>
            {t({
              id: "common.stage",
              defaultMessage: "Stage",
            })}
          </StyledLabel>
          <Select
            disabled
            value={event.opts.stage}
            className="bg-grey-100 w-full font-semibold"
          >
            {(Object.keys(EventStage) as Array<EventStageKeys>).map(
              (element: EventStageKeys) => (
                <option key={EventStage[element]} value={EventStage[element]}>
                  {EventStage[element]}
                </option>
              )
            )}
            <option value={EventStage.qualified}>{EventStage.qualified}</option>
          </Select>
        </div>
        <div className="w-full">
          <StyledLabel>
            {t({
              id: "form-controls.client-name.label",
              defaultMessage: "Client name",
            })}
          </StyledLabel>
          <Input
            className="w-full font-medium"
            value={event?.opts?.cname}
            disabled
          />
        </div>
        <div>
          <StyledLabel>
            {t({
              id: "form-controls.client-description.label",
              defaultMessage: "Client description",
            })}
          </StyledLabel>
          <Input
            className="w-full font-medium"
            value={event?.opts?.cdesc}
            disabled
          />
        </div>
        <div>
          <StyledLabel className="ml-5 mb-1">
            {t({
              id: "form-controls.expenses-incurred.label",
              defaultMessage: "Expenses incurred",
            })}
          </StyledLabel>
          <div className="flex w-full flex-row gap-4">
            <div className="w-[106px]">
              <StyledLabel>
                {t({
                  id: "common.currency",
                  defaultMessage: "Currency",
                })}
              </StyledLabel>
              <Select
                disabled
                value={event?.opts?.crcy_type || "USD"}
                className="bg-grey-100 w-full pt-3 font-semibold"
              >
                <option value={event?.opts?.crcy_type || "USD"}>
                  {event?.opts?.crcy_type || "USD"}
                </option>
              </Select>
            </div>
            <div className="flex w-full flex-col">
              <StyledLabel>
                {t({
                  id: "common.amount",
                  defaultMessage: "Amount",
                })}
              </StyledLabel>
              <Input
                className="font-medium"
                value={event?.opts?.crcy_value}
                disabled
              />
            </div>
          </div>
        </div>
        <div>
          <StyledLabel>
            {t({
              id: "common.notes",
              defaultMessage: "Notes",
            })}
          </StyledLabel>
          <div className="bg-grey-100 mt-1 p-4 font-medium" />
        </div>
        {event?.attachmnts.length > 0 && (
          <div className="ml-3 flex gap-3">{attachments}</div>
        )}
      </div>
    </div>
  );
};
