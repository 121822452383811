import {
  Checkbox,
  TaskPropertyButton,
  TaskPropertyButtonProps,
} from "@jugl-web/ui-components/cross-platform";
import { cx } from "@jugl-web/utils";
import { forwardRef } from "react";

export interface TaskPropertyToggleButtonProps
  extends Omit<TaskPropertyButtonProps, "children" | "onChange"> {
  label: string;
  isChecked: boolean;
  onChange?: (isChecked: boolean) => void;
}

export const TaskPropertyToggleButton = forwardRef<
  HTMLButtonElement,
  TaskPropertyToggleButtonProps
>(({ label, isChecked, onChange, ...props }, ref) => (
  <TaskPropertyButton
    ref={ref}
    startIcon={<Checkbox isChecked={isChecked} readOnly size="sm" />}
    className={cx(isChecked && "bg-primary-50 text-primary-900")}
    onClick={() => onChange?.(!isChecked)}
    {...props}
  >
    {label}
  </TaskPropertyButton>
));
