import { TaskLabel } from "@jugl-web/rest-api/tasks";
import { DotIcon } from "@jugl-web/ui-components/cross-platform/DotIcon";
import { ListBoxItem } from "@jugl-web/ui-components/cross-platform/ListBox";
import {
  TaskPropertyButton,
  TaskPropertyButtonProps,
} from "@jugl-web/ui-components/cross-platform/tasks/TaskPropertyButton";
import {
  ResourcePickerDrawer,
  ResourcePickerDrawerProps,
} from "@jugl-web/ui-components/mobile/ResourcePickerDrawer";
import {
  ResourcePickerPopover,
  ResourcePickerPopoverProps,
} from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { cx, useAppVariant, useTranslations } from "@jugl-web/utils";
import { hexColorWithTransparency } from "@jugl-web/utils/utils/hexColorWithTransparency";
import { FC, useMemo, useState } from "react";
import { useTaskFields } from "../../../../hooks/useTaskFields";
import { ReactComponent as LabelIcon } from "../../assets/label.svg";
import { FieldComponentProps } from "../../types";

interface LabelFieldProps extends FieldComponentProps<"label"> {
  entityId: string;
}

export const LabelField: FC<LabelFieldProps> = ({
  entityId,
  labelId,
  isReadonly,
  onChange,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { isMobile } = useAppVariant();
  const { t } = useTranslations();

  const isFieldVisible = !!labelId || !isReadonly;

  // #region list
  const { labels, noneLabel, getLabelById } = useTaskFields({ entityId });

  const labelsAsListItems = useMemo<ListBoxItem<TaskLabel>[]>(
    () => [
      { id: noneLabel.id, value: noneLabel },
      ...labels.map((l) => ({ id: l.id, value: l })),
    ],
    [labels, noneLabel]
  );
  // #endregion

  // #region button
  const label = labelId ? getLabelById(labelId) : undefined;
  const hasLabel = !!label;
  // #endregion

  const commonButtonProps: TaskPropertyButtonProps = {
    isDisabled: isReadonly,
    startIcon: hasLabel ? (
      <DotIcon size="sm" color={label.color} />
    ) : (
      <LabelIcon />
    ),
    className: cx(
      "max-w-[250px]",
      hasLabel &&
        !isReadonly &&
        "hover:brightness-100 hover:backdrop-brightness-95"
    ),
    style: {
      color: hasLabel ? label.color : undefined,
      backgroundColor: hasLabel
        ? hexColorWithTransparency(label.color, 12)
        : undefined,
    },
    children: hasLabel
      ? t(
          {
            id: "tasks-page.label-with-text",
            defaultMessage: "Label: {text}",
          },
          { text: label.text }
        )
      : t({
          id: "tasks-page.add-label",
          defaultMessage: "Add label",
        }),
  };

  const commonResourcePickerProps = {
    title: t({
      id: "tasks-page.select-task-label",
      defaultMessage: "Select task label",
    }),
    items: labelsAsListItems,
    selectionBehavior: { mode: "single", canToggle: false },
    hasSearch: true,
    defaultSelectedIds: hasLabel ? [label.id] : [noneLabel.id],
    maxVisibleItems: 8,
    itemSize: "lg",
    spaceBetweenItems: "compact",
    renderLabel: (item) => item.value.text,
    renderStartIcon: (item) => <DotIcon color={item.value.color} />,
    onSelect: ({ item, onClose }) => {
      onChange?.(item.id === noneLabel.id ? null : item.id);
      onClose();
    },
  } satisfies Partial<
    ResourcePickerDrawerProps<TaskLabel> | ResourcePickerPopoverProps<TaskLabel>
  >;

  if (!isFieldVisible) {
    return null;
  }

  if (isMobile) {
    return (
      <>
        <TaskPropertyButton
          onClick={() => setIsDialogOpen(true)}
          {...commonButtonProps}
        />
        <ResourcePickerDrawer
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          {...commonResourcePickerProps}
        />
      </>
    );
  }

  return (
    <ResourcePickerPopover
      placement="bottom-start"
      className="w-[375px]"
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          ref={triggerRef}
          as={TaskPropertyButton}
          {...commonButtonProps}
        />
      )}
      {...commonResourcePickerProps}
    />
  );
};
