import { FC } from "react";
import { Menu, PlainButton } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { ReactComponent as MenuIcon } from "./assets/menu.svg";

export const NoSubscriptionHeaderMenu: FC = () => {
  const { navigateToPage } = useNavigation();
  const { t } = useTranslations();
  return (
    <Menu
      placement="right-end"
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          as={PlainButton}
          ref={triggerRef}
          className="hover:bg-grey-100 h-10 w-10 rounded-full transition-all duration-300"
        >
          <MenuIcon className="text-white" />
        </Trigger>
      )}
      onSelect={(_item, _event, close) => close()}
      sections={[
        [
          {
            id: "switch-workspace",
            label: t({
              id: "workspace-settings-page.switch-workspace",
              defaultMessage: "Switch Workspace",
            }),
            onSelect: () => navigateToPage("entitySelect"),
          },
        ],
      ]}
    />
  );
};
