import { useRestApiProvider } from "@jugl-web/rest-api";
import { DetailedTask, TaskDefaultStatus } from "@jugl-web/rest-api/tasks";
import { ConfirmationPopup } from "@jugl-web/ui-components/cross-platform";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { MobileDrawer } from "@jugl-web/ui-components/mobile/MobileDrawer";
import { AppVariant, useTranslations } from "@jugl-web/utils";

export interface TaskCompleteConfirmationDialogProps {
  isOpen: boolean;
  variant: AppVariant;
  entityId: string;
  task: DetailedTask;
  onClose: () => void;
  onCompletion: () => void;
}

export const TaskCompleteConfirmationDialog = ({
  isOpen,
  variant,
  entityId,
  task,
  onClose,
  onCompletion,
}: TaskCompleteConfirmationDialogProps) => {
  const { tasksApi } = useRestApiProvider();
  const { t } = useTranslations();

  const [updateTask, { isLoading: isUpdateTaskLoading }] =
    tasksApi.useUpdateTaskMutation();

  const areAllChecklistItemsCompleted = task.checklist.every(
    (item) => item.is_completed
  );

  const message = areAllChecklistItemsCompleted ? (
    <>
      {t({
        id: "tasks-page.mark-task-as-completed",
        defaultMessage: "Are you sure you want to mark this task as completed",
      })}
      ?
    </>
  ) : (
    <>
      {t({
        id: "tasks-page.unfinished-checklist",
        defaultMessage:
          "You have unfinished checklist items for this task. The task will be considered complete, but the unfinished items will be noted",
      })}
      .
      <br />
      <br />
      {t({
        id: "tasks-page.mark-as-complete",
        defaultMessage: "Are you sure you want to mark it as complete?",
      })}
    </>
  );

  const onConfirm = () =>
    updateTask({
      entityId,
      taskId: task.id,
      updatedTask: { status: TaskDefaultStatus.completed },
    }).then((response) => {
      if ("data" in response) {
        onClose();
        onCompletion();
      }
    });

  if (variant === "mobile") {
    return (
      <MobileDrawer
        isOpen={isOpen}
        header={{
          title: t({
            id: "tasks-page.complete-task",
            defaultMessage: "Complete task",
          }),
        }}
        onClose={onClose}
      >
        <MobileDrawer.Content>
          <p className="text-onyx mt-2 mb-10 text-sm leading-[21px]">
            {message}
          </p>
          <div className="flex flex-col gap-2.5">
            <Button
              color="primary"
              variant="contained"
              isDisabled={isUpdateTaskLoading}
              onClick={onConfirm}
              uppercase
            >
              {t({
                id: "common.complete",
                defaultMessage: "Complete",
              })}
            </Button>
            <Button
              color="primary"
              variant="text"
              isDisabled={isUpdateTaskLoading}
              onClick={onClose}
              uppercase
            >
              {t({
                id: "common.cancel",
                defaultMessage: "Cancel",
              })}
            </Button>
          </div>
        </MobileDrawer.Content>
      </MobileDrawer>
    );
  }

  return (
    <ConfirmationPopup
      variant={variant}
      isOpen={isOpen}
      title={t({
        id: "tasks-page.complete-task",
        defaultMessage: "Complete task",
      })}
      message={message}
      hasCancelButton
      buttons={[
        {
          label: t({
            id: "common.complete",
            defaultMessage: "Complete",
          }),
          color: "primary",
          isDisabled: isUpdateTaskLoading,
          onClick: onConfirm,
        },
      ]}
      onRequestClose={onClose}
    />
  );
};
