import React from "react";
import animationData from "@jugl-web/utils/animations/loader-light.json";
import Lottie from "react-lottie";
import { cx } from "@jugl-web/utils";

export const ChatInfoLoading: React.FC<{
  isInitialLoading?: boolean;
}> = ({ isInitialLoading }) => (
  <div className={cx(isInitialLoading ? "mt-20" : "mt-5")}>
    <Lottie options={{ animationData }} height={72} width={72} />
  </div>
);
