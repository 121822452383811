import { cx } from "@jugl-web/utils";
import { FC } from "react";

export interface TaskChecklistItemNumberProps {
  index: number;
  isHighlighted?: boolean;
  className?: string;
}

export const TaskChecklistItemNumber: FC<TaskChecklistItemNumberProps> = ({
  index,
  isHighlighted = false,
  className,
}) => (
  <div
    className={cx(
      "flex h-8 w-8 shrink-0 items-center justify-center rounded-lg",
      isHighlighted ? "bg-primary text-white" : "bg-dark-100 text-dark-500",
      className
    )}
  >
    <span className="leading-2 text-xs font-medium">#{index + 1}</span>
  </div>
);
