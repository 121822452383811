import React, { useRef, useEffect, useState, useMemo } from "react";
import classNames from "classnames";
import { cx, useTranslations } from "@jugl-web/utils";
import { ReactComponent as ImageIcon } from "./assets/image.svg";
import { ReactComponent as RemoveIcon } from "./assets/remove.svg";
import { ReactComponent as SmileIcon } from "./assets/smile.svg";
// eslint-disable-next-line import/no-cycle
import { CropImageModal } from "./components/CropImageModal/CropImageModal";
import {
  InteractiveContainer,
  LoadingAnimation,
  Menu,
} from "../../cross-platform";

export enum AvatarSelectSize {
  xs = "xs",
  s = "s",
  m = "m",
  l = "l",
  xl = "xl",
  xxl = "xxl",
}

const fileToUrl = (input: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e?.target?.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(input);
  });

export type AvatarSelectProps = {
  onSave: (value?: File) => void;
  size?: AvatarSelectSize;
  value?: File;
  defaultSrc?: string;
  placeholder?: React.ReactNode;
  showRemoveButton?: boolean;
  externalSelectFile?: () => Promise<File[] | null | undefined>;
};
export const AvatarSelect: React.FC<AvatarSelectProps> = ({
  onSave,
  size = AvatarSelectSize.xl,
  value,
  defaultSrc,
  placeholder,
  showRemoveButton,
  externalSelectFile,
}) => {
  const $input = useRef<HTMLInputElement>(null);
  const [displayImage, setDisplayImage] = useState<File | undefined>(undefined);
  const [showCropImageModal, setShowCropImageModal] = useState<boolean>(false);
  const { t } = useTranslations();
  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = async (
    e
  ) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }
    e.target.value = "";
    setDisplayImage(file);
    setShowCropImageModal(true);
  };
  const [valueSrc, setValueSrc] = useState<string | undefined>(defaultSrc);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleSaveImage = (image: File) => {
    onSave(image);
    handleCloseCropImageModal();
  };

  useEffect(() => {
    if (!defaultSrc) return;
    setIsImageLoaded(false);
    setValueSrc(defaultSrc);
  }, [defaultSrc]);

  useEffect(() => {
    if (value) {
      fileToUrl(value).then((res) => {
        if (typeof res === "string" || typeof res === "undefined") {
          setValueSrc(res);
        }
      });
    }
  }, [value]);

  const handleFileSelect = async () => {
    if (externalSelectFile) {
      const file = await externalSelectFile();
      if (!file) {
        return;
      }
      setDisplayImage(file[0]);
      setShowCropImageModal(true);
    } else {
      $input.current?.click();
    }
  };

  const imagePixelSize = useMemo(() => {
    switch (size) {
      case AvatarSelectSize.xs:
        return `h-[24px] w-[24px]`;
      case AvatarSelectSize.s:
        return `h-[32px] w-[32px]`;
      case AvatarSelectSize.m:
        return `h-[40px] w-[40px]`;
      case AvatarSelectSize.l:
        return `h-[56px] w-[56px]`;
      case AvatarSelectSize.xl:
        return `h-[100px] w-[100px]`;
      case AvatarSelectSize.xxl:
        return `h-[156px] w-[156px]`;
      default:
        return `h-[100px] w-[100px]`;
    }
  }, [size]);

  const handleCloseCropImageModal = () => setShowCropImageModal(false);

  const containerStyles = cx(
    "hover:border-grey-500 shrink-0 rounded-full border border-dashed border-transparent bg-transparent hover:bg-white p-1 transition hover:drop-shadow-[0_5px_8px_rgba(0,0,0,0.08)]",
    imagePixelSize
  );
  const contentStyles =
    "flex h-full w-full items-center justify-center overflow-hidden rounded-full";

  return (
    <>
      <input
        ref={$input}
        onChange={handleInputChange}
        type="file"
        accept="image/png, image/jpg, image/jpeg"
        name="image"
        hidden
      />
      {!valueSrc ? (
        <InteractiveContainer
          onClick={handleFileSelect}
          className={containerStyles}
        >
          <div
            className={classNames(contentStyles, !placeholder && "bg-grey-100")}
          >
            {placeholder || <SmileIcon />}
          </div>
        </InteractiveContainer>
      ) : (
        <Menu
          placement="right"
          renderTrigger={({ Trigger, triggerRef }) => (
            <Trigger
              as={InteractiveContainer}
              ref={triggerRef}
              className={containerStyles}
            >
              <div className={contentStyles}>
                <img
                  src={valueSrc}
                  alt=""
                  className="h-full w-full"
                  onLoad={() => setIsImageLoaded(true)}
                  hidden={!isImageLoaded}
                />
                {!isImageLoaded && <LoadingAnimation size="lg" />}
              </div>
            </Trigger>
          )}
          sections={[
            [
              {
                id: "upload",
                icon: <ImageIcon />,
                label: t({
                  id: "avatar-select-component.upload-new-photo",
                  defaultMessage: "Upload New Photo",
                }),
                onSelect: (event, close) => {
                  event.stopPropagation();
                  close();
                  handleFileSelect();
                },
              },
              {
                isHidden: !showRemoveButton,
                id: "remove",
                icon: <RemoveIcon />,
                label: t({
                  id: "avatar-select-component.remove-picture",
                  defaultMessage: "Remove picture",
                }),
                onSelect: (event, close) => {
                  event.stopPropagation();
                  close();
                  onSave(undefined);
                  setValueSrc(undefined);
                },
              },
            ],
          ]}
        />
      )}
      {showCropImageModal && displayImage && (
        <CropImageModal
          isOpen={showCropImageModal}
          image={displayImage}
          onClose={handleCloseCropImageModal}
          onSave={handleSaveImage}
          onChangeImage={handleFileSelect}
        />
      )}
    </>
  );
};

export default AvatarSelect;
