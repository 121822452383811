import { useState } from "react";

export const useIsFocused = () => {
  const [isFocused, setIsFocused] = useState(false);

  return {
    isFocused,
    focusProps: {
      onFocus: () => setIsFocused(true),
      onBlur: () => setIsFocused(false),
    },
  };
};
