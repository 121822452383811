import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { assert } from "@jugl-web/utils";
import { FC } from "react";
import { CustomSpecificFieldCellProps } from "../../types";
import { CustomFieldCellContent } from "../CustomFieldCellContent";
import { TaskTableLayout } from "../TaskTableLayout";

export const CustomDropdownFieldCell: FC<CustomSpecificFieldCellProps> = ({
  field,
  rawValue,
  isEditable,
  cellProps,
  onFieldUpdate,
}) => {
  assert(field.type === "dropdown", "Field should be of type dropdown");

  return (
    <ResourcePickerPopover
      placement="bottom"
      className="w-[375px]"
      renderTrigger={({ Trigger, triggerRef, isOpen, onClose }) => (
        <Trigger
          ref={triggerRef}
          as={TaskTableLayout.Cell}
          isFocused={isOpen}
          isHoverable
          isDisabled={!isEditable}
          {...cellProps}
        >
          <CustomFieldCellContent
            field={field}
            rawValue={rawValue}
            isEditable={isEditable}
            isCellFocused={isOpen}
            onClearValue={() => {
              onFieldUpdate("");
              onClose();
            }}
          />
        </Trigger>
      )}
      items={(field.values || []).map((value) => ({ id: value.id, value }))}
      selectionBehavior={{ mode: "single", canToggle: false }}
      hasSearch
      defaultSelectedIds={rawValue ? [rawValue] : undefined}
      maxVisibleItems={5}
      itemSize="md"
      spaceBetweenItems="compact"
      renderLabel={(item) => item.value.value}
      onSelect={({ item, onClose }) => {
        onFieldUpdate(item.id);
        onClose();
      }}
    />
  );
};
