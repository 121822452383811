import { useParams } from "react-router-dom";
import { useToast, useTranslations } from "@jugl-web/utils";
import { useRestApiProvider } from "@jugl-web/rest-api";

export const useDeleteObject = () => {
  const { driveApi } = useRestApiProvider();
  const [deleteContent] = driveApi.useDeleteContentMutation();
  const params = useParams();
  const { toast } = useToast({ variant: "web" });
  const { t } = useTranslations();

  const deleteObject = async (type: "directory" | "file", id: string) => {
    const res = await deleteContent({
      entity_id: params.entityId || "",
      id,
    });
    if ("data" in res) {
      toast(
        type === "directory"
          ? t({
              id: "drive-page.folder-successfully-deleted",
              defaultMessage: "1 Folder successfully deleted",
            })
          : t({
              id: "drive-page.file-successfully-deleted",
              defaultMessage: "1 File successfully deleted",
            })
      );
    }
  };

  return {
    deleteObject,
  };
};
