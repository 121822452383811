import noop from "lodash/noop";
import { useCallback, useState } from "react";

export interface ConfirmationDialogStateHandlers {
  onConfirm: () => void;
  onCancel?: () => void;
}

export interface ConfirmationDialogState
  extends ConfirmationDialogStateHandlers {
  isOpen: boolean;
}

export type OpenConfirmationDialogFn = (
  handlers: ConfirmationDialogStateHandlers
) => void;

const INITIAL_STATE: ConfirmationDialogState = {
  isOpen: false,
  onConfirm: noop,
  onCancel: noop,
};

export const useConfirmationDialogState = (
  initialState: Partial<ConfirmationDialogState> = INITIAL_STATE
) => {
  const [state, setState] = useState(() => ({
    ...INITIAL_STATE,
    ...initialState,
  }));

  const open = useCallback((handlers: ConfirmationDialogStateHandlers) => {
    setState({ ...handlers, isOpen: true });
  }, []);

  const close = useCallback(() => {
    setState(INITIAL_STATE);
  }, []);

  const confirm = useCallback(
    (options?: { closeOnceConfirmed: boolean }) => {
      state.onConfirm();

      if (options?.closeOnceConfirmed) {
        close();
      }
    },
    [close, state]
  );

  const cancel = useCallback(
    (options?: { closeOnceCanceled: boolean }) => {
      state.onCancel?.();

      if (options?.closeOnceCanceled) {
        close();
      }
    },
    [close, state]
  );

  return {
    isOpen: state.isOpen,
    open,
    close,
    confirm,
    cancel,
  };
};
