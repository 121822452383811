import { useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import { FieldComponentProps } from "../../types";
import { TaskPropertyToggleButton } from "../TaskPropertyToggleButton";

type AllowAssigneesEditFieldProps = FieldComponentProps<"allowAssigneesEdit">;

export const AllowAssigneesEditField: FC<AllowAssigneesEditFieldProps> = ({
  isChecked,
  isHidden,
  onChange,
}) => {
  const { t } = useTranslations();

  if (isHidden) {
    return null;
  }

  return (
    <TaskPropertyToggleButton
      label={t({
        id: "tasks-page.allow-assignees-edit",
        defaultMessage: "Allow assignees to edit",
      })}
      isChecked={isChecked}
      onChange={onChange}
    />
  );
};
