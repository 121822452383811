import { autoUpdate, offset, useFloating } from "@floating-ui/react-dom";
import {
  Avatar,
  InteractiveContainer,
} from "@jugl-web/ui-components/cross-platform";
import { cx } from "@jugl-web/utils";
import { FC, useCallback } from "react";
import Lottie from "react-lottie";
import { useEntityProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { Link } from "react-router-dom";
import useEntity from "../../hooks/useEntity";
import { useMe } from "../../hooks/useMe";
import bellAnimationData from "./animations/bell-animation.json";
import { Divider } from "./components/Divider";
import { EntitiesUpdatesPopup } from "./components/EntitiesUpdatesPopup";
import { HomeSideBarAccordion } from "./components/HomeSideBarAccordion";
import { UnreadCountBox } from "./components/UnreadCountBox";
import styles from "./HomeSideBar.module.css";
import { ReactComponent as ChevronRightIcon } from "./icons/chevron-right.svg";
import { ReactComponent as JuglLogoIcon } from "./icons/jugl.svg";
import { NavigationItem } from "./types";
import { useEntitiesUpdatesPopup } from "./useEntitiesUpdatesPopup";
import { useHomeSideBar } from "./useHomeSideBar";
import { EntitySubscriptionInfoItem } from "./components/EntitySubscriptionInfoItem/EntitySubscriptionInfoItem";

export const HomeSideBar: FC = () => {
  const { entity, list } = useEntity();
  const { isModuleAvailable, moduleRequiredAction } = useEntityProvider();
  const { profile } = useMe();
  const { navigateToPage } = useNavigation();

  const { navigationItems, isSideBarExpanded, updateIsSideBarExpanded } =
    useHomeSideBar();

  const {
    isEntitiesUpdatesPopupOpen,
    hasUpdateInAnotherEntity,
    lastUpdatedEntityId,
    onCloseEntitiesUpdatesPopup,
  } = useEntitiesUpdatesPopup({ entityId: entity?.id });

  const { refs, floatingStyles } = useFloating({
    placement: "right-end",
    middleware: [offset({ mainAxis: isSideBarExpanded ? -15 : -10 })],
    whileElementsMounted: autoUpdate,
    transform: false,
  });

  const renderSideBarElements = useCallback(
    (elements: NavigationItem[]) =>
      elements
        .filter(
          (navigationItem) =>
            !navigationItem.hidden &&
            (navigationItem.requiredRole
              ? entity && navigationItem.requiredRole.includes(entity.role)
              : true)
        )
        .map((navigationItem) => {
          if (navigationItem.type === "accordion") {
            return (
              <HomeSideBarAccordion
                key={navigationItem.title}
                title={
                  <div className="flex flex-col overflow-hidden">
                    <div
                      className={cx(
                        "flex items-center gap-3",
                        isSideBarExpanded ? "px-2.5" : "justify-center"
                      )}
                    >
                      {navigationItem.icon && (
                        <navigationItem.icon
                          className={cx(
                            "shrink-0 transition-transform duration-300",
                            isSideBarExpanded ? "scale-90" : "scale-100"
                          )}
                        />
                      )}
                      <span
                        className={cx(
                          "truncate text-base",
                          isSideBarExpanded ? "block" : "hidden"
                        )}
                      >
                        {navigationItem.title}
                      </span>
                    </div>
                    <div
                      className={cx(
                        "mt-0.5 flex justify-center",
                        isSideBarExpanded ? "hidden" : "block"
                      )}
                    >
                      <span className="text-[13px] text-white">
                        {navigationItem.title}
                      </span>
                    </div>
                  </div>
                }
                isHomeSidebarExpanded={isSideBarExpanded}
                onSideBarExpand={() => updateIsSideBarExpanded(true)}
                onClick={navigationItem.onClick}
              >
                {renderSideBarElements(navigationItem.items)}
              </HomeSideBarAccordion>
            );
          }

          const navigationItemContent = (
            <>
              <div
                className={cx(
                  "flex items-center",
                  navigationItem.icon ? "gap-3" : "gap-7",
                  isSideBarExpanded ? "px-2.5" : "justify-center"
                )}
              >
                <div className="relative leading-[0]">
                  {navigationItem.icon && (
                    <navigationItem.icon
                      className={cx(
                        "transition-transform duration-300",
                        isSideBarExpanded ? "scale-90" : "scale-100"
                      )}
                    />
                  )}
                  {!isSideBarExpanded &&
                    (navigationItem.unreadCount || 0) > 0 && (
                      <UnreadCountBox
                        count={navigationItem.unreadCount as number}
                        isItemActive={!!navigationItem.active}
                        className="absolute right-[6px] top-1/2 -translate-y-1/2 translate-x-full"
                      />
                    )}
                </div>
                <div
                  className={cx(
                    isSideBarExpanded
                      ? "flex w-full items-center justify-between truncate"
                      : "hidden"
                  )}
                >
                  <span className="truncate text-base">
                    {navigationItem.title}
                  </span>
                  {(navigationItem.unreadCount || 0) > 0 && (
                    <UnreadCountBox
                      count={navigationItem.unreadCount as number}
                      isItemActive={!!navigationItem.active}
                    />
                  )}
                </div>
              </div>
              <div
                className={cx(
                  "mt-0.5 flex justify-center",
                  isSideBarExpanded && "hidden"
                )}
              >
                <span className="text-[13px]">{navigationItem.title}</span>
              </div>
            </>
          );

          const navigationItemStyles = cx(
            "group mx-2.5 flex shrink-0 select-none flex-col justify-center rounded-lg text-white no-underline transition-colors duration-300",
            navigationItem.active
              ? "bg-[#1A75D2] font-bold"
              : "hover:bg-[#107CDE]",
            isSideBarExpanded ? "h-[39px]" : "h-[57px]"
          );

          return navigationItem.type === "link" ? (
            <Link
              key={navigationItem.title}
              className={navigationItemStyles}
              to={navigationItem.href}
              onClick={(e) => {
                if (
                  navigationItem.subscriptionModuleRequired &&
                  !isModuleAvailable(navigationItem.subscriptionModuleRequired)
                ) {
                  e.preventDefault();
                  moduleRequiredAction(
                    navigationItem.subscriptionModuleRequired
                  );
                }

                navigationItem.onClick?.();
              }}
            >
              {navigationItemContent}
            </Link>
          ) : (
            <InteractiveContainer
              key={navigationItem.title}
              className={navigationItemStyles}
              onClick={() =>
                navigationItem.subscriptionModuleRequired
                  ? moduleRequiredAction(
                      navigationItem.subscriptionModuleRequired,
                      navigationItem.onClick
                    )
                  : navigationItem.onClick()
              }
            >
              {navigationItemContent}
            </InteractiveContainer>
          );
        }),
    [
      entity,
      isSideBarExpanded,
      updateIsSideBarExpanded,
      isModuleAvailable,
      moduleRequiredAction,
    ]
  );

  return (
    <>
      <div
        className={cx(
          "bg-primary-600 group/homeSideBar flex h-screen flex-col transition-[width] duration-300",
          isSideBarExpanded ? "w-[210px]" : "w-[110px]"
        )}
      >
        <div className="relative flex shrink-0 flex-col">
          <InteractiveContainer
            className={cx(
              "flex items-center pt-[15px] pb-3 transition-all duration-300 hover:bg-[#107CDE]",
              isSideBarExpanded ? "px-6" : "px-[39px]"
            )}
            onClick={() =>
              navigateToPage("settingsEditProfile", {
                section: "personal-info",
              })
            }
          >
            <div className="relative shrink-0 leading-[0]">
              <Avatar
                size={isSideBarExpanded ? "sm" : "md"}
                className="border border-solid border-[#F2F2F2]"
                username={profile?.displayName || ""}
                imageUrl={profile?.avatar}
              />
              <div
                className={cx(
                  "bg-secondary-200 border-primary-600 absolute right-[-2px] top-[-2px] box-content h-2 w-2 rounded-full border-2 border-solid transition-opacity duration-300",
                  isSideBarExpanded ? "opacity-0" : "opacity-100"
                )}
              />
            </div>
            <div
              className={cx(
                "ml-[13px] flex grow flex-col overflow-hidden",
                !isSideBarExpanded && "hidden"
              )}
            >
              <span className="truncate text-base text-white">
                {profile?.firstName}
              </span>
              <span className="text-primary-50 text-xs">Online</span>
            </div>
          </InteractiveContainer>
          <InteractiveContainer
            className={cx(
              "bg-primary-600 border-px absolute right-0 top-1/2 z-[51] flex h-6 w-6 translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full border-solid border-white text-white opacity-0 transition-all duration-300 group-hover/homeSideBar:opacity-100",
              isSideBarExpanded && "-rotate-180"
            )}
            onClick={() => updateIsSideBarExpanded(!isSideBarExpanded)}
          >
            <ChevronRightIcon />
          </InteractiveContainer>
        </div>
        <Divider />
        <div
          className={cx(
            "mt-2 flex grow flex-col overflow-y-auto overflow-x-hidden",
            isSideBarExpanded ? "gap-2.5" : "gap-1",
            styles.homeSideBarScrollbar
          )}
        >
          {renderSideBarElements(navigationItems)}
        </div>
        <EntitySubscriptionInfoItem isSideBarExpanded={isSideBarExpanded} />
        <InteractiveContainer
          ref={refs.setReference}
          className={cx(
            "group/user flex h-[76px] shrink-0 items-center transition-all duration-300 hover:bg-[#107CDE]",
            isSideBarExpanded ? "px-5" : "px-[39px]"
          )}
          onClick={() => navigateToPage("entitySelect", undefined)}
        >
          <div className="relative">
            <Avatar
              size="md"
              className="shrink-0"
              username={entity?.name || ""}
              imageUrl={entity?.logoImg}
            />
            <div
              className={cx(
                "bg-primary-700 absolute right-0 bottom-0 flex h-5 w-5 translate-x-1.5 items-center justify-center rounded-full transition-opacity duration-300",
                hasUpdateInAnotherEntity ? "opacity-100" : "opacity-0"
              )}
            >
              <Lottie
                options={{ animationData: bellAnimationData }}
                height={24}
                width={24}
              />
            </div>
          </div>
          <span
            className={cx(
              "ml-3 grow truncate text-white",
              isSideBarExpanded ? "block" : "hidden"
            )}
          >
            {entity?.name}
          </span>
        </InteractiveContainer>
        <Divider />
        <div className="relative mt-4 mb-8 h-[22px]">
          <JuglLogoIcon
            className={cx(
              "absolute transition-transform duration-300",
              isSideBarExpanded
                ? "translate-x-5 delay-300"
                : "-translate-x-full"
            )}
          />
          <JuglLogoIcon
            className={cx(
              "absolute left-1/2 -translate-x-1/2 transition-opacity duration-300",
              isSideBarExpanded ? "opacity-0" : "opacity-100 delay-300"
            )}
          />
        </div>
      </div>
      <EntitiesUpdatesPopup
        ref={refs.setFloating}
        style={floatingStyles}
        isOpen={isEntitiesUpdatesPopupOpen}
        entities={list}
        lastUpdatedEntityId={lastUpdatedEntityId}
        onClose={onCloseEntitiesUpdatesPopup}
      />
    </>
  );
};
