import React, { memo, useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { UserProfile, useRestApiProvider } from "@jugl-web/rest-api";
import { useTranslations } from "@jugl-web/utils";
import {
  Button,
  ConfirmationPopup,
  FormControlLabel,
  InteractiveContainer,
  LoadingAnimation,
  Popover,
  TextField,
} from "@jugl-web/ui-components/cross-platform";
import { DatePicker } from "@jugl-web/ui-components/cross-platform/DatePicker";
import { useProfileUpdateToast } from "../../hooks/useProfileUpdateToast";
import { DateButton } from "../DateButton/DateButton";

type Inputs = {
  country: string;
  state: string;
  city: string;
  start_date: Date | undefined;
};

const schema = yup.object().shape({
  country: yup.string().required(),
  state: yup.string().required(),
  city: yup.string().required(),
  start_date: yup.date().required(),
});

const EditCurrentCityFormContainer = memo(() => {
  const { usersApi } = useRestApiProvider();
  const { data } = usersApi.useGetUserProfileQuery({});
  return <EditCurrentCityFormComponent {...{ data }} />;
});

const EditCurrentCityFormComponent: React.FC<{
  data: UserProfile | undefined;
}> = ({ data }) => (
  <div className="w-1/2">
    {!data ? (
      <LoadingAnimation />
    ) : (
      <EditCurrentCityForm
        data={data}
        type={data.locations.length === 0 ? "add" : "update"}
      />
    )}
  </div>
);

const EditCurrentCityForm: React.FC<{
  data: UserProfile;
  type: "add" | "update";
}> = ({ data, type }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { isValid, isDirty },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    values: {
      start_date: data.locations[0]?.moved_in
        ? new Date(data.locations[0].moved_in)
        : undefined,
      country: data.locations[0]?.country || "",
      state: data.locations[0]?.state || "",
      city: data.locations[0]?.city || "",
    },
  });
  const [openModal, setOpenModal] = useState(false);
  const { usersApi } = useRestApiProvider();
  const [addCity, { isLoading }] = usersApi.useAddUserProfileSectionMutation();
  const [updateCity, { isLoading: isLoadingUpdate }] =
    usersApi.useUpdateUserProfileSectionMutation();
  const [deleteCity, { isLoading: isLoadingDelete }] =
    usersApi.useDeleteUserProfileSectionMutation();
  const { showProfileUpdatedSnackbar } = useProfileUpdateToast();

  const onSubmit: SubmitHandler<Inputs> = async (params) => {
    const { city, state, country, start_date: startDate } = { ...params };
    let res;
    if (type === "add") {
      res = await addCity({
        data: {
          type: "location",
          moved_in: startDate,
          city,
          state,
          country_name: country,
        },
      });
    } else {
      res = await updateCity({
        item_id: data?.locations[0].id,
        data: {
          type: "location",
          moved_in: startDate,
          city,
          state,
          country_name: country,
        },
      });
    }
    if ("data" in res) {
      showProfileUpdatedSnackbar();
    }
  };

  const onDeleteCity = async () => {
    const res = await deleteCity({
      type: "location",
      item_id: data.locations[0].id,
    });
    if ("data" in res) {
      showProfileUpdatedSnackbar();
      setOpenModal(false);
    }
  };
  const { t } = useTranslations();

  return (
    <>
      <div className="pt-10">
        <span className="pl-5 font-medium">
          {t({
            id: "current-city-form-page.add-current-city",
            defaultMessage: "Add Current City",
          })}
        </span>
      </div>
      <div className="px-10 pb-10 pt-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex w-full gap-[70px]">
            <div className="flex w-1/2 flex-col gap-12">
              <TextField
                isRequired
                label={t({
                  id: "form-controls.country.label",
                  defaultMessage: "Country",
                })}
                isFullWidth
                placeholder={t({
                  id: "form-controls.country-name.placeholder",
                  defaultMessage: "Country Name",
                })}
                {...register("country")}
              />
              <TextField
                isRequired
                label={t({
                  id: "form-controls.city.label",
                  defaultMessage: "City",
                })}
                isFullWidth
                placeholder={t({
                  id: "form-controls.city-name.placeholder",
                  defaultMessage: "City Name",
                })}
                {...register("city")}
              />
              <Button
                isDisabled={
                  !isValid || isLoading || isLoadingUpdate || !isDirty
                }
                fullWidth
                variant="contained"
                type="submit"
              >
                {t({
                  id: "common.save",
                  defaultMessage: "Save",
                })}
              </Button>
            </div>
            <div className="flex w-1/2 flex-col gap-12">
              <TextField
                isRequired
                label={t({
                  id: "form-controls.state.label",
                  defaultMessage: "State",
                })}
                isFullWidth
                placeholder={t({
                  id: "form-controls.state-name.placeholder",
                  defaultMessage: "State Name",
                })}
                {...register("state")}
              />
              <Controller
                name="start_date"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Popover
                    className="w-[340px]"
                    placement="bottom-start"
                    renderTrigger={({ Trigger, triggerRef }) => (
                      <Trigger ref={triggerRef} as={InteractiveContainer}>
                        <FormControlLabel isRequired className="ml-2.5">
                          {t({
                            id: "form-controls.start-date.label",
                            defaultMessage: "Start Date",
                          })}
                        </FormControlLabel>
                        <DateButton date={value} />
                      </Trigger>
                    )}
                  >
                    {({ onClose }) => (
                      <DatePicker
                        onSubmit={onChange}
                        initialDate={value}
                        onClose={onClose}
                        className="p-4"
                        dateTransformation="endOfDay"
                      />
                    )}
                  </Popover>
                )}
              />
              {data.locations.length !== 0 && (
                <Button
                  isDisabled={isLoadingDelete}
                  fullWidth
                  variant="outlined"
                  onClick={() => setOpenModal(true)}
                >
                  {t({
                    id: "current-city-form-page.delete-current-city-info",
                    defaultMessage: "Delete Current City Info",
                  })}
                </Button>
              )}
            </div>
          </div>
        </form>
      </div>
      {openModal && (
        <ConfirmationPopup
          isOpen={openModal}
          onRequestClose={() => setOpenModal(false)}
          hasCancelButton
          message={t({
            id: "current-city-form-page.want-remove-current-city-information",
            defaultMessage:
              "Do you really want to remove current city information?",
          })}
          variant="web"
          title={t({
            id: "delete-modal.delete-confirmation-title",
            defaultMessage: "Delete Confirmation",
          })}
          buttons={[
            {
              label: t({
                id: "common.delete",
                defaultMessage: "Delete",
              }),
              color: "tertiary",
              onClick: onDeleteCity,
              isDisabled: isLoading,
            },
          ]}
        />
      )}
    </>
  );
};

export default EditCurrentCityFormContainer;
