import { AxiosResponse } from "axios";

type ErrorCodeAPIError = AxiosResponse<{
  errors: string;
}>;

export function isErrorCodeOnlyAPIError(
  error: unknown
): error is ErrorCodeAPIError {
  return !!(error && typeof error === "object" && "data" in error);
}
