import { Alert } from "@jugl-web/ui-components/cross-platform";
import { FC } from "react";
import { EntityModel, EntityParticipantRole } from "@jugl-web/rest-api";
import readOnlyGif from "./assets/read-only.gif";

export const WriteRestrictedAlert: FC<{
  isOpen: boolean;
  onRequestClose: () => void;
  onRequestPlanUpgrade: (closeAlert: () => void) => void;
  entity: EntityModel;
}> = ({ isOpen, onRequestClose, entity, onRequestPlanUpgrade }) => (
  <Alert
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    header="Workspace Subscription"
    title="No actions allowed"
    img={readOnlyGif}
    buttons={
      entity.role === EntityParticipantRole.admin
        ? [
            {
              text: "Upgrade plan",
              onClick: (e, { closeAlert }) => {
                onRequestPlanUpgrade(closeAlert);
              },
            },
          ]
        : undefined
    }
    content={
      <span>
        You cant update anything, since Workspace is in Read-Only mode.
        {entity.role === EntityParticipantRole.admin ? (
          <span>
            To get Edit mode, please{" "}
            <span className="text-primary-900 font-bold">
              Upgrade Workspace subscription
            </span>
          </span>
        ) : (
          <span>
            To get Edit mode, please reach out to the Admin of workspace to{" "}
            <span className="text-primary-900 font-bold">
              {" "}
              Upgrade Subscription
            </span>
          </span>
        )}
      </span>
    }
  />
);
