import React, { PropsWithChildren } from "react";
import { SidebarDrawer } from "@jugl-web/ui-components/web";

export const ChatSideBar: React.FC<
  PropsWithChildren<{
    title?: string;
    footer?: React.ReactElement;
    onRequestClose: () => void;
  }>
> = ({ children, title, footer, onRequestClose }) => (
  <div className="shadow-[-2px 0px 3px rgba(0, 0, 0, 0.05)] relative z-[100] h-full min-w-[300px] max-w-[415px]">
    <SidebarDrawer isOpen title={title} onClose={onRequestClose} inline>
      <SidebarDrawer.Content>{children}</SidebarDrawer.Content>
      <SidebarDrawer.Actions>{footer}</SidebarDrawer.Actions>
    </SidebarDrawer>
  </div>
);
