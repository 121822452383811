import { cx } from "@jugl-web/utils";
import { ReactNode } from "react";
import { Button } from "../Button";
import { Text } from "../Text";
import { ReactComponent as EmptyStateIcon } from "./icons/empty-state.svg";
import { ReactComponent as NetworkErrorIcon } from "./icons/network-error.svg";
import { ReactComponent as UnknownErrorIcon } from "./icons/unknown-error.svg";
import { ReactComponent as AlertIcon } from "./icons/alert.svg";

export type FeedbackBoxIconType =
  | "empty-state"
  | "network-error"
  | "unknown-error"
  | "alert";

const iconTypeToIcon: Record<FeedbackBoxIconType, JSX.Element> = {
  "empty-state": <EmptyStateIcon />,
  "network-error": <NetworkErrorIcon />,
  "unknown-error": <UnknownErrorIcon />,
  alert: <AlertIcon />,
};

export interface FeedbackBoxProps {
  iconType: FeedbackBoxIconType;
  title: ReactNode;
  subtitle?: ReactNode;
  actionButton?: {
    label: string;
    onClick: () => void;
  };
  className?: string;
}

export const FeedbackBox = ({
  iconType,
  title,
  subtitle,
  actionButton,
  className,
}: FeedbackBoxProps) => (
  <div className={cx("flex flex-col items-center p-4 text-center", className)}>
    <div className="mb-6">{iconTypeToIcon[iconType]}</div>
    <Text app="mobile" variant="h3" className="m-0">
      {title}
    </Text>
    {subtitle && (
      <Text app="mobile" variant="body2" className="mb-0 mt-3">
        {subtitle}
      </Text>
    )}
    {actionButton && (
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={actionButton.onClick}
        className="leading-2 mt-10 h-10 w-full text-sm"
      >
        {actionButton.label}
      </Button>
    )}
  </div>
);
