import { cx } from "@jugl-web/utils";
import { useTasksPageContext } from "@web-src/features/tasks/TasksPageContext";
import {
  eachDayOfInterval,
  eachMonthOfInterval,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";
import { FC } from "react";
import { useWeekdays } from "../../hooks/useWeekdays";

export const CalendarYearView: FC = () => {
  const { taskCalendarDate } = useTasksPageContext();

  const weekdays = useWeekdays();

  const startOfYearDate = startOfYear(taskCalendarDate);
  const endOfYearDate = endOfYear(taskCalendarDate);

  const months = eachMonthOfInterval({
    start: startOfYearDate,
    end: endOfYearDate,
  });

  return (
    <div className="animate-fade-in grid grid-cols-4 gap-y-5 gap-x-12">
      {months.map((month) => {
        const startOfWeekDate = startOfWeek(startOfMonth(month), {
          weekStartsOn: 1,
        });
        const endOfWeekDate = endOfWeek(endOfMonth(month), { weekStartsOn: 1 });

        const calendarDays = eachDayOfInterval({
          start: startOfWeekDate,
          end: endOfWeekDate,
        });

        return (
          <div key={month.valueOf()} className="flex flex-col gap-1.5">
            <h3>{format(month, "MMMM")}</h3>
            <div className="flex flex-col gap-4">
              <div className="grid grid-cols-7 gap-4">
                {weekdays.map((weekday) => (
                  <div key={weekday.label}>{weekday.label}</div>
                ))}
              </div>
              <div className="grid grid-cols-7 gap-4">
                {calendarDays.map((day) => {
                  const isInCurrentMonth = day.getMonth() === month.getMonth();

                  return (
                    <div
                      key={day.valueOf()}
                      className={cx(
                        isInCurrentMonth ? "text-black" : "text-grey-700"
                      )}
                    >
                      {format(day, "d")}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
