import { cx, getUsernameInitials } from "@jugl-web/utils";
import { FC, useState } from "react";
import { ReactComponent as SmallCheckIcon } from "./icons/small-check.svg";

export type AvatarSize =
  | "xs"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "2xl"
  | "3xl"
  | "4xl"
  | "5xl";

export interface AvatarProps {
  username: string;
  size?: AvatarSize;
  imageUrl?: string | null;
  isSelected?: boolean;
  className?: string;
  labelClassName?: string;
}

const sizeToClasses: Record<AvatarSize, string> = {
  xs: "h-4 w-4",
  sm: "h-6 w-6",
  md: "h-8 w-8",
  lg: "h-12 w-12",
  xl: "h-14 w-14",
  "2xl": "h-16 w-16",
  "3xl": "h-[72px] w-[72px]",
  "4xl": "w-20 h-20",
  "5xl": "h-[150px] w-[150px]",
};

const sizeToTextClasses: Record<AvatarSize, string> = {
  xs: "text-[10px]",
  sm: "text-sm",
  md: "text-xs",
  lg: "text-base",
  xl: "text-lg",
  "2xl": "text-xl",
  "3xl": "text-2xl",
  "4xl": "text-3xl",
  "5xl": "text-4xl",
};

export interface AvatarContainerProps {
  children: JSX.Element;
  size?: AvatarSize;
  isSelected?: boolean;
  className?: string;
}

export const AvatarContainer: FC<AvatarContainerProps> = ({
  children,
  size = "md",
  isSelected = false,
  className,
}) => (
  <div className="relative inline-flex">
    <div
      className={cx(
        "relative inline-flex overflow-hidden rounded-full",
        isSelected && "outline-primary outline outline-1",
        sizeToClasses[size],
        className
      )}
    >
      {children}
    </div>
    {isSelected && (
      <div className="bg-primary absolute bottom-0 right-0 flex h-[18px] w-[18px] items-center justify-center rounded-full">
        <SmallCheckIcon />
      </div>
    )}
  </div>
);

export const Avatar: FC<AvatarProps> = ({
  username,
  size = "md",
  imageUrl,
  className,
  labelClassName,
  isSelected = false,
}) => {
  const [hasImageLoadingError, setHasImageLoadingError] = useState(false);

  const hasImage = !!imageUrl;
  const initials = getUsernameInitials(username);

  return (
    <AvatarContainer size={size} isSelected={isSelected} className={className}>
      {hasImage && !hasImageLoadingError ? (
        <img
          src={imageUrl}
          alt={username}
          className="h-full w-full object-cover"
          onError={() => setHasImageLoadingError(true)}
        />
      ) : (
        <span
          className={cx(
            "text-dark flex h-full w-full items-center justify-center text-base font-semibold uppercase leading-3",
            sizeToTextClasses[size],
            labelClassName
          )}
          style={{
            background: "linear-gradient(180deg, #CECED3 0%, #ECEFF0 69.27%)",
          }}
        >
          {initials}
        </span>
      )}
    </AvatarContainer>
  );
};
