import { cx } from "@jugl-web/utils";

export interface PageNumberButtonProps {
  pageNumber: number;
  isActive: boolean;
  onClick: (pageNumber: number) => void;
}
export const PageNumberButton = ({
  pageNumber,
  isActive,
  onClick,
}: PageNumberButtonProps) => (
  <button
    type="button"
    className={cx(
      "flex h-8 w-8 items-center justify-center rounded",
      isActive
        ? "bg-grey-300 border-none"
        : "border-dark-100 hover:border-primary cursor-pointer border border-solid bg-white"
    )}
    disabled={isActive}
    onClick={() => onClick(pageNumber)}
  >
    <span className="text-grey-800 text-sm font-semibold leading-3">
      {pageNumber}
    </span>
  </button>
);
