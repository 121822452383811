import { HookOutOfContextError } from "@jugl-web/utils";
import {
  getStorageItemWithFallback,
  saveItemToStorage,
  TEMPLATE_FOLDERS_FILTER_KEY,
  TEMPLATE_FOLDERS_SEARCH_QUERY_KEY,
} from "@jugl-web/utils/storage";
import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

export enum TemplateFolderFilter {
  AllFolders = "allFolders",
  MyFolders = "myFolders",
  CreatedByJugl = "createdByJugl",
  CreatedByOthers = "createdByOthers",
}

interface TaskTemplateFoldersPageContextValue {
  searchQuery: string;
  filter: TemplateFolderFilter;
  isNewFolderDialogOpen: boolean;
  changeSearchQuery: (updatedQuery: string) => void;
  changeFilter: (updatedFilter: TemplateFolderFilter) => void;
  setIsNewFolderDialogOpen: Dispatch<SetStateAction<boolean>>;
}

const TaskTemplateFoldersPageContext =
  createContext<TaskTemplateFoldersPageContextValue | null>(null);

interface TaskTemplateFoldersPageContextProviderProps {
  children: ReactNode;
}

export const TaskTemplateFoldersPageContextProvider: FC<
  TaskTemplateFoldersPageContextProviderProps
> = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState<string>(() =>
    getStorageItemWithFallback<string>(
      TEMPLATE_FOLDERS_SEARCH_QUERY_KEY,
      "",
      sessionStorage
    )
  );

  const [filter, setFilter] = useState<TemplateFolderFilter>(() =>
    getStorageItemWithFallback<TemplateFolderFilter>(
      TEMPLATE_FOLDERS_FILTER_KEY,
      TemplateFolderFilter.AllFolders,
      sessionStorage
    )
  );

  const [isNewFolderDialogOpen, setIsNewFolderDialogOpen] = useState(false);

  const changeSearchQuery = useCallback((updatedQuery: string) => {
    setSearchQuery(updatedQuery);
    saveItemToStorage(
      TEMPLATE_FOLDERS_SEARCH_QUERY_KEY,
      updatedQuery,
      sessionStorage
    );
  }, []);

  const changeFilter = useCallback((updatedFilter: TemplateFolderFilter) => {
    setFilter(updatedFilter);
    saveItemToStorage(
      TEMPLATE_FOLDERS_FILTER_KEY,
      updatedFilter,
      sessionStorage
    );
  }, []);

  const contextValue = useMemo<TaskTemplateFoldersPageContextValue>(
    () => ({
      searchQuery,
      filter,
      isNewFolderDialogOpen,
      changeSearchQuery,
      changeFilter,
      setIsNewFolderDialogOpen,
    }),
    [
      changeFilter,
      changeSearchQuery,
      filter,
      isNewFolderDialogOpen,
      searchQuery,
    ]
  );

  return (
    <TaskTemplateFoldersPageContext.Provider value={contextValue}>
      {children}
    </TaskTemplateFoldersPageContext.Provider>
  );
};

export const useTaskTemplateFoldersPageContext = () => {
  const context = useContext(TaskTemplateFoldersPageContext);

  if (!context) {
    throw new HookOutOfContextError(
      "useTaskTemplateFoldersPageContext",
      "TaskTemplateFoldersPageContext"
    );
  }

  return context;
};
