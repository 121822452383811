import React, { useEffect, useState } from "react";
import { AvatarSelect } from "@jugl-web/ui-components/web/AvatarSelect";
import { useTranslations } from "@jugl-web/utils";
import {
  useFileSelect,
  avatarFileSelectConfig,
} from "@jugl-web/domain-resources/files/providers/FileSelectProvider";
import { TextField } from "@jugl-web/ui-components/cross-platform";

const WorkspaceForm: React.FC<{
  onChange?: (params: { title: string; avatar?: File }) => void;
  defaultTitle?: string;
  defaultImg?: string;
}> = ({ onChange, defaultImg, defaultTitle }) => {
  const { t } = useTranslations();
  const { selectFile } = useFileSelect(avatarFileSelectConfig);

  const [selectedAvatar, setSelectedAvatar] = useState<File>();
  const [title, setTitle] = useState<string>(defaultTitle || "");
  const handleTitleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setTitle(e.currentTarget.value);
  };
  useEffect(() => {
    onChange?.({ title, avatar: selectedAvatar });
  }, [title, selectedAvatar, onChange]);

  return (
    <>
      <div className="flex flex-col items-center gap-10 p-6">
        <span className="font-medium">
          {t({
            id: "chats-page.add-group-details",
            defaultMessage: "Add Group Details",
          })}
        </span>
        <AvatarSelect
          onSave={setSelectedAvatar}
          value={selectedAvatar}
          defaultSrc={defaultImg}
          externalSelectFile={selectFile}
        />
      </div>
      <div className="mx-8 mb-4 flex flex-col gap-[50px]">
        <TextField
          variant="outlined"
          value={title}
          isFullWidth
          onChange={handleTitleChange}
          placeholder={t({
            id: "form-controls.group-name.placeholder",
            defaultMessage: "Group Name",
          })}
        />
      </div>
    </>
  );
};

export default WorkspaceForm;
