import { FC, ReactNode, useMemo } from "react";
import { SubscriptionPlanModule } from "@jugl-web/rest-api/entities/models/common-types/SubscriptionPlanModule";
import { ReactComponent as FeatureAvailableIcon } from "./assets/feature-available-icon.svg";
import { ReactComponent as FeatureUnavailableIcon } from "./assets/feature-unavailable-icon.svg";
import { ReactComponent as FeatureAvailableEnterprise } from "./assets/feature-available-enterprise.svg";

export type FeatureListProps = {
  features: SubscriptionPlanModule[];
  isEnterprise?: boolean;
};

export const FeatureList: FC<FeatureListProps> = ({
  features,
  isEnterprise,
}) => {
  const items: { text: string; icon: ReactNode }[] = useMemo(
    () =>
      features.map((feature) => ({
        text: feature.desc,
        icon: feature.available ? (
          isEnterprise ? (
            <FeatureAvailableEnterprise />
          ) : (
            <FeatureAvailableIcon />
          )
        ) : (
          <FeatureUnavailableIcon />
        ),
      })),
    [features, isEnterprise]
  );
  return (
    <div className="flex flex-col gap-5">
      {items.map((item, idx) => (
        <div key={+idx} className="flex items-center gap-2">
          {item.icon}
          <span className="text-sm leading-[150%] tracking-[-0.14px]">
            {item.text}
          </span>
        </div>
      ))}
    </div>
  );
};
