import { FC } from "react";
import { ReactComponent as ChevronLeftIcon } from "./icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "./icons/chevron-right.svg";

export interface ChevronButtonProps {
  direction: "left" | "right";
  isDisabled: boolean;
  onClick: () => void;
}

export const ChevronButton: FC<ChevronButtonProps> = ({
  direction,
  isDisabled,
  onClick,
}) => (
  <button
    type="button"
    className="text-grey disabled:text-grey-400 flex h-8 w-8 cursor-pointer items-center justify-center border-none bg-transparent disabled:cursor-default"
    disabled={isDisabled}
    onClick={onClick}
  >
    {direction === "left" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
  </button>
);
