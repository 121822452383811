import axios, { AxiosInstance } from "axios";

// TODO: refresh token
export const getAxiosInstance = (
  tokenGetter?: () => string | undefined,
  forceBaseUrl?: string
): AxiosInstance => {
  let baseURL = forceBaseUrl;
  if (!baseURL) {
    baseURL =
      process.env.NODE_ENV === "development"
        ? ``
        : process.env.NEXT_PUBLIC_API_URL;
  }
  const api = axios.create({
    baseURL,
    withCredentials: false,
  });
  api.interceptors.request.use((config) => {
    const token = tokenGetter?.();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
  return api;
};
