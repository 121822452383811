import { useRestApiProvider } from "@jugl-web/rest-api";
import { Alert, PageLoaderFull } from "@jugl-web/ui-components/cross-platform";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Stripe, StripeCardElement, loadStripe } from "@stripe/stripe-js";
import { UpdateCardAlertResult } from "./types";

const UPDATE_CARD_FORM_ID = "update-card-form";

export type UpdateCardAlertProps = { title?: string; entityId: string };

export type UpdateCardAlertHandle = {
  start: () => Promise<UpdateCardAlertResult>;
};

export const UpdateCardAlert = forwardRef<
  UpdateCardAlertHandle,
  UpdateCardAlertProps
>(({ title, entityId }, ref) => {
  const { entitiesApi } = useRestApiProvider();
  const [updateCardDetails, { isLoading: isUpdateCardDetailsLoading }] =
    entitiesApi.useUpdateCardDetailsMutation();

  const startResolveFunc = useRef<
    ((value: UpdateCardAlertResult) => void) | null
  >(null);
  const stripeObjects = useRef<{
    stripe: Stripe;
    cardElement: StripeCardElement;
    clientSecret: string;
  } | null>(null);

  const [isInProgress, setIsInProgress] = useState(false);
  const [isUpdateAlertOpen, setIsUpdateAlertOpen] = useState(false);
  const [isStripeLoading, setIsStripeLoading] = useState(false);
  const [stripeErrorMessage, setStripeErrorMessage] = useState<string>();

  const finish = (result: UpdateCardAlertResult) => {
    startResolveFunc.current?.(result);
    if (isStripeLoading) {
      setIsStripeLoading(false);
    }
    if (isUpdateAlertOpen) {
      setIsUpdateAlertOpen(false);
    }
    if (stripeErrorMessage) {
      setStripeErrorMessage(undefined);
    }
    startResolveFunc.current = null;
    setIsInProgress(false);
  };

  const proceedCardUpdate = async () => {
    try {
      const updateCardDetailsResponse = await updateCardDetails({ entityId });
      if (
        "error" in updateCardDetailsResponse &&
        updateCardDetailsResponse.error
      ) {
        finish({ result: "apiError", error: updateCardDetailsResponse.error });
        return;
      }
      if (
        !("data" in updateCardDetailsResponse) ||
        !updateCardDetailsResponse.data
      ) {
        finish({ result: "apiError" });
        return;
      }
      setIsStripeLoading(true);
      const stripe = await loadStripe(updateCardDetailsResponse.data.pub_key);
      if (!stripe) {
        finish({ result: "stripeError" });
        return;
      }
      const paymentStripeElement = stripe
        .elements({
          clientSecret: updateCardDetailsResponse.data.secret,
          loader: "auto",
        })
        .create("card", { hidePostalCode: true });
      setIsUpdateAlertOpen(true);
      await new Promise((r) => {
        setTimeout(r, 200);
      });
      stripeObjects.current = {
        stripe,
        cardElement: paymentStripeElement,
        clientSecret: updateCardDetailsResponse.data.secret,
      };
      paymentStripeElement.mount(`#${UPDATE_CARD_FORM_ID}`);
      setIsStripeLoading(false);
    } catch (error) {
      finish({ result: "unknownError", error });
    }
  };

  const handleConfirmCardClick = async () => {
    if (stripeObjects.current === null) {
      return;
    }
    if (stripeErrorMessage) {
      setStripeErrorMessage(undefined);
    }
    try {
      setIsStripeLoading(true);
      const { stripe, cardElement, clientSecret } = stripeObjects.current;
      const { error } = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });
      if (error) {
        setStripeErrorMessage(
          error.message || "Something went wrong, please try again later"
        );
        return;
      }
      finish({ result: "success" });
    } catch (error) {
      finish({ result: "unknownError", error });
    } finally {
      setIsStripeLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    start: () =>
      new Promise<UpdateCardAlertResult>((resolve) => {
        setIsInProgress(true);
        startResolveFunc.current = resolve;
        proceedCardUpdate();
      }),
  }));

  if (!isInProgress) {
    return null;
  }
  return (
    <>
      <PageLoaderFull isActive={isUpdateCardDetailsLoading} isTransparent />
      <Alert
        isOpen={isUpdateAlertOpen}
        title={title || "Provide your card details"}
        content={
          <>
            <form className="py-5" id={UPDATE_CARD_FORM_ID} />
            {!!stripeErrorMessage && (
              <div className="mt-2 text-left text-sm text-red-500">
                {stripeErrorMessage}
              </div>
            )}
          </>
        }
        onRequestClose={() => {
          finish({ result: "cancelled" });
        }}
        buttons={[
          {
            text: "Cancel",
            role: "close",
          },
          {
            text: "Continue",
            isDisabled: isStripeLoading,
            onClick: handleConfirmCardClick,
          },
        ]}
      />
    </>
  );
});
