export const hexColorWithTransparency = (
  hexColor: string,
  transparencyInPercent: number
) => {
  if (hexColor.length === 4) {
    hexColor = `#${hexColor
      .substring(1)
      .split("")
      .map((d) => d + d)
      .join("")}`;
  }

  return hexColor.concat(transparencyInPercent.toString());
};
