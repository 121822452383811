import { Dispatch, SetStateAction, useCallback } from "react";
import {
  TaskChecklistHandlers,
  TaskChecklistItem,
} from "../components/TaskChecklist";

interface UseLocalTaskChecklistHandlersOptions {
  itemsSetter: Dispatch<SetStateAction<TaskChecklistItem[]>>;
}

export const useLocalTaskChecklistHandlers = ({
  itemsSetter,
}: UseLocalTaskChecklistHandlersOptions): TaskChecklistHandlers => {
  const handleAddItem = useCallback<TaskChecklistHandlers["onAddItem"]>(
    (item) => {
      itemsSetter((previousItems) => [...previousItems, item]);
    },
    [itemsSetter]
  );

  const handleUpdateItem = useCallback<TaskChecklistHandlers["onUpdateItem"]>(
    (updatedItem) => {
      itemsSetter((previousItems) =>
        previousItems.map((previousItem) =>
          previousItem.id === updatedItem.id ? updatedItem : previousItem
        )
      );
    },
    [itemsSetter]
  );

  const handleDeleteItem = useCallback<TaskChecklistHandlers["onDeleteItem"]>(
    (itemId) => {
      itemsSetter((previousItems) =>
        previousItems.filter((item) => item.id !== itemId)
      );
    },
    [itemsSetter]
  );

  const handleReorderItems = useCallback<
    TaskChecklistHandlers["onReorderItems"]
  >(
    (newItems) => {
      itemsSetter(newItems);
    },
    [itemsSetter]
  );

  return {
    onAddItem: handleAddItem,
    onUpdateItem: handleUpdateItem,
    onDeleteItem: handleDeleteItem,
    onReorderItems: handleReorderItems,
  };
};
