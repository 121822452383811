import { SubscriptionIntervalType } from "../common-types/SubscriptionIntervalType";
import {
  SubscriptionPlanAddonSeatsDto,
  SubscriptionPlanDto,
  SubscriptionPlanPlanDto,
} from "./SubscriptionPlanDto";
import { SubscriptionPlanModel } from "./SubscriptionPlanModel";

export const transformSubscriptionPlansDtoToModels = (
  dto: SubscriptionPlanDto[]
): SubscriptionPlanModel[] => {
  const plans = dto.filter(
    (plan) => plan.metadata.type === "plan" && !!plan.recurring
  ) as SubscriptionPlanPlanDto[];
  const monthSeatsAddon = dto.find(
    (plan) =>
      plan.metadata.type === "addon_seats" &&
      plan.recurring.interval === "month"
  ) as SubscriptionPlanAddonSeatsDto;
  const yearSeatsAddon = dto.find(
    (plan) =>
      plan.metadata.type === "addon_seats" && plan.recurring.interval === "year"
  ) as SubscriptionPlanAddonSeatsDto;

  const result = plans.map((plan) => ({
    name: plan.name,
    id: plan.id,
    currency: plan.currency,
    intervalType: plan.recurring.interval,
    price: plan.price,
    seatsCount: plan.metadata.seats,
    seatsUpdateAvailable: plan.metadata.allowed_addons?.includes("addon_seats"),
    features: plan.metadata.features,
    enforcePaymentMethod: plan.metadata.enforce_pm_presense,
    additionalSeatPriceId:
      plan.recurring.interval === "month"
        ? monthSeatsAddon.id
        : yearSeatsAddon.id,
    additionalSeatPrice:
      plan.recurring.interval === "month"
        ? monthSeatsAddon.price
        : yearSeatsAddon.price,
    canApplyPromo: plan.metadata.can_apply_promo,
    planType: plan.metadata.plan_type,
  }));

  const enterprisePlan = dto.find(
    (plan) =>
      (plan as SubscriptionPlanPlanDto).metadata.plan_type === "enterprise"
  ) as SubscriptionPlanPlanDto;

  if (enterprisePlan) {
    const intervals: SubscriptionIntervalType[] = ["month", "year"];
    intervals.forEach((interval) => {
      result.push({
        name: enterprisePlan.name,
        id: `enterprise-${interval}`,
        currency: "usd",
        intervalType: interval,
        price: 0,
        seatsCount: 0,
        seatsUpdateAvailable: false,
        features: enterprisePlan.metadata.features,
        enforcePaymentMethod: false,
        additionalSeatPriceId: "",
        additionalSeatPrice: 0,
        canApplyPromo: false,
        planType: "enterprise",
      });
    });
  }

  return result;
};
