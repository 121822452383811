import { PageNavParams } from "@web-src/modules/navigation/types";
import { ModulePagesConfig } from "@web-src/modules/navigation/types/ModulePagesConfigs";
import ChatsPage from "@web-src/pages/Chats";

export type ChatsPagesNavigationParams = {
  chatsChats: PageNavParams;
  chatsActiveChat: PageNavParams<{ activeChatId: string }>;
};

export const chatsPagesNavigationConfig: ModulePagesConfig<ChatsPagesNavigationParams> =
  {
    chatsChats: {
      element: <ChatsPage />,
      path: "chats",
      isPrivate: true,
    },
    chatsActiveChat: {
      element: <ChatsPage />,
      path: "chats/:activeChatId",
      isPrivate: true,
    },
  };
