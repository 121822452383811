import { BottomCenteredDrawer } from "@jugl-web/ui-components/web/BottomCenteredDrawer";
import { useTranslations, useToast } from "@jugl-web/utils";
import { useState } from "react";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { InputStep } from "./components/InputStep/InputStep";
import { VerificationStep } from "./components/VerificationStep/VerificationStep";
import { LoginMethodType } from "../../types";

export const LoginMethodVerificationDialog: React.FC<{
  isOpen: boolean;
  type: LoginMethodType | null;
  onClose: () => void;
}> = ({ isOpen, type, onClose }) => {
  const { t } = useTranslations();
  const { toast } = useToast({ variant: "web" });
  const { authApi } = useRestApiProvider();

  const [addLoginMethod] = authApi.useAddLoginMethodMutation();

  const [verifyLoginMethod] = authApi.useVerifyLoginMethodMutation();

  const [drawerStep, setDrawerStep] = useState<"input" | "verification">(
    "input"
  );

  const [inputValue, setInputValue] = useState("");

  const handleSubmitToValidation = async () => {
    if (!type || !inputValue) return;
    const response = await addLoginMethod({
      type,
      value: inputValue,
    });
    if ("data" in response) {
      setDrawerStep("verification");
    }
  };

  const handleClose = () => {
    onClose();
    setInputValue("");
    setDrawerStep("input");
  };
  const handleSubmitOTP = async (otp: string) => {
    if (!type || !inputValue) return;
    const response = await verifyLoginMethod({
      type,
      value: inputValue,
      otp,
    });

    if ("data" in response) {
      handleClose();
      toast(
        type === "email"
          ? t({
              id: "settings-page.email-was-added",
              defaultMessage: "Email was successfully added",
            })
          : t({
              id: "settings-page.phone-was-added",
              defaultMessage: "Phone number was successfully added",
            })
      );
    }
  };

  return (
    <BottomCenteredDrawer
      isOpen={isOpen}
      header={{
        type: "title",
        title: t({
          id: "settings-page.new-login-method",
          defaultMessage: "New Login Method",
        }),
      }}
      onClose={handleClose}
    >
      {type && drawerStep === "input" && (
        <InputStep
          onSubmit={handleSubmitToValidation}
          onChange={setInputValue}
          type={type}
          value={inputValue}
        />
      )}
      {type && drawerStep === "verification" && (
        <VerificationStep
          value={inputValue}
          onSubmit={handleSubmitOTP}
          onGoBack={() => setDrawerStep("input")}
          onResend={handleSubmitToValidation}
          type={type}
        />
      )}
    </BottomCenteredDrawer>
  );
};
