import { HookOutOfContextError } from "@jugl-web/utils/errors";
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from "react";

interface AuthTokenContextValue {
  getToken: () => string | null;
}

const AuthTokenContext = createContext<AuthTokenContextValue>(
  null as unknown as AuthTokenContextValue
);

export const AuthTokenProvider: React.FC<
  PropsWithChildren<{ getter: () => string | null }>
> = ({ children, getter }) => {
  const value: AuthTokenContextValue = useMemo(
    () => ({ getToken: getter }),
    [getter]
  );
  return (
    <AuthTokenContext.Provider value={value}>
      {children}
    </AuthTokenContext.Provider>
  );
};

export const useAuthTokenProvider = () => {
  const context = useContext(AuthTokenContext);

  if (!context) {
    throw new HookOutOfContextError("useAuthTokenProvider", "AuthTokenContext");
  }

  return context;
};
