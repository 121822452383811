import { Button, Text } from "@jugl-web/ui-components/cross-platform";
import { cx, useAppVariant, useTranslations } from "@jugl-web/utils";
import { forwardRef } from "react";
import Lottie from "react-lottie";
import mime from "mime";
import animationData from "../../assets/loading-animation.json";
import { FilePreviewConfig, FilePreviewType } from "../../types";
import { ReactComponent as FileIcon } from "./assets/file.svg";
import { ReactComponent as UnknownFileIcon } from "./assets/unknown-file-icon.svg";

interface MediaPanelProps {
  config: FilePreviewConfig;
  previewType: FilePreviewType;
  isLoading: boolean;
  hasError: boolean;
  onLoad: () => void;
  onError: () => void;
  onClose: () => void;
  onDownload?: () => void;
}

const UNKNOWN_FILE_EXTENSION = "bin";

export const MediaPanel = forwardRef<HTMLDivElement, MediaPanelProps>(
  (
    {
      config,
      previewType,
      isLoading,
      hasError,
      onLoad,
      onError,
      onDownload,
      onClose,
    },
    ref
  ) => {
    const { t } = useTranslations();
    const { isMobile } = useAppVariant();
    const fileExtension = mime.getExtension(config.mimeType);
    return (
      <div
        ref={ref}
        className="flex items-center justify-center"
        style={{ height: `calc(100vh - ${isMobile ? "44px" : "120px"})` }}
        onClick={onClose}
      >
        <div
          className={cx(
            "relative flex h-[90%] items-center justify-center overflow-hidden",
            isMobile ? "w-[95%]" : "w-[80%]"
          )}
        >
          {!hasError && (
            <>
              {previewType === "image" && (
                <>
                  <div
                    className={cx(
                      "absolute top-1/2 left-1/2 z-0 -translate-x-1/2 -translate-y-1/2 transition-opacity",
                      isLoading ? "opacity-100" : "opacity-0"
                    )}
                  >
                    <Lottie
                      options={{ animationData }}
                      height={70}
                      width={70}
                    />
                  </div>
                  <img
                    src={config.url}
                    alt={config.name}
                    className="relative z-10 max-h-full max-w-full select-none rounded-lg"
                    onClick={(event) => event.stopPropagation()}
                    onLoad={onLoad}
                    onError={onError}
                  />
                </>
              )}
              {previewType === "video" && (
                <video
                  className={cx(
                    "max-h-full max-w-full rounded-lg",
                    isMobile ? "w-full" : "min-h-[300px] min-w-[400px]"
                  )}
                  autoPlay
                  controls
                  playsInline
                  onClick={(event) => event.stopPropagation()}
                  onCanPlay={onLoad}
                  onError={onError}
                >
                  <source src={config.url} type={config.mimeType} />
                </video>
              )}
              {previewType === "audio" && (
                <audio
                  autoPlay
                  controls
                  onClick={(event) => event.stopPropagation()}
                  onCanPlay={onLoad}
                  onError={onError}
                >
                  <source src={config.url} type={config.mimeType} />
                </audio>
              )}
            </>
          )}
          {(previewType === "other" || hasError) && (
            <div
              className="flex flex-col items-center p-12"
              onClick={(event) => event.stopPropagation()}
            >
              <span className="relative">
                <FileIcon />
                <Text
                  variant="body3"
                  className="text-primary absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform font-medium"
                >
                  {fileExtension === UNKNOWN_FILE_EXTENSION ? (
                    <UnknownFileIcon />
                  ) : (
                    fileExtension
                  )}
                </Text>
              </span>
              <span
                className={cx(
                  "font-secondary mt-6 overflow-hidden text-ellipsis text-center font-bold text-white",
                  isMobile ? "text-lg leading-5" : "text-2xl leading-7"
                )}
              >
                {config.name}
              </span>
              <span
                className={cx(
                  "mt-2 mb-10 font-normal leading-4 text-white",
                  isMobile ? "text-sm" : "text-base"
                )}
              >
                {t({
                  id: "file-preview-component.preview-not-available",
                  defaultMessage: "Preview is not available",
                })}
              </span>
              {onDownload && (
                <Button
                  color="primary"
                  variant="contained"
                  className={cx(isMobile ? "h-10 w-[250px]" : "h-12 w-[300px]")}
                  onClick={onDownload}
                >
                  {t({
                    id: "common.download-file",
                    defaultMessage: "Download file",
                  })}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
);
