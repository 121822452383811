export const primary = {
  50: "#E3F2FD",
  100: "#BBDEFB",
  200: "#90CAF9",
  300: "#64B5F6",
  400: "#42A5F5",
  500: "#2196F3", // main
  600: "#1E88E5",
  700: "#1A75D2",
  800: "#1764C0",
  900: "#1045A1",
};

export const secondary = {
  50: "#EBFFF5",
  100: "#C1EFD9",
  200: "#87E2B7",
  300: "#3AD68C",
  400: "#27BB74",
  500: "#09B965", // main
  600: "#429760",
  700: "#398453",
  800: "#327246",
  900: "#235531",
};

export const dark = {
  50: "#ECEFF0",
  100: "#ECEFF0",
  200: "#E0E2E3",
  300: "#C5C9CB",
  400: "#A7AEB0",
  500: "#8F979A",
  600: "#737B7E",
  700: "#5A6367",
  800: "#40494D",
  900: "#2C3438",
  A100: "#1A2023",
};

export const grey = {
  100: "#F9F9F9",
  200: "#F2F2F4",
  300: "#DEDEE4",
  400: "#D2D2D8",
  500: "#C5C5CA",
  600: "#B5B5BB",
  700: "#A4A5AA",
  800: "#93949A",
  900: "#818288",
  A100: "#707577",
  B100: "#E8E8E8",
  C100: "#757575",
  D100: "#F2F2F2",
  E100: "#E3E3E3",
};

/**
 * red shades
 */
export const tertiary = {
  50: "#FAECEE",
  100: "#F8DFE2",
  200: "#F3CED2",
  300: "#DD9C9D",
  400: "#CE7877",
  500: "#E55353", // main
  600: "#D75144",
  700: "#C94841",
  800: "#B83E3A",
  900: "#9F2E29",
};

export const gradients = {
  g1: "#22C59E",
  g2: "#EE5A8A",
  g3: "#AB59E4",
  g4: "#F1B43A",
  g5: "#4C8CEB",
  danger: "#EA5353",
  success: "#50BA4D",
  warning: "#F7C647",
  warningLight: "#FFF7E3",
};

export const white = {
  500: "#FFFFFF",
};

export const black = {
  400: "#f3d1d1",
  500: "#000000",
};
