import {
  Avatar,
  InteractiveContainer,
} from "@jugl-web/ui-components/cross-platform";
import { cx } from "@jugl-web/utils";
import { FC, ReactNode } from "react";

export type ListItemCardProps = {
  title: string;
  subTitle?: ReactNode | string;
  avatarSrc?: string | null;
  subAvatarSrc?: string | null;
  subAvatarTitle?: string | null;
  rightContent?: ReactNode;
  onClick?: () => void;
};

export const ListItemCard: FC<ListItemCardProps> = ({
  title,
  subTitle,
  avatarSrc,
  subAvatarSrc,
  subAvatarTitle,
  rightContent,
  onClick,
}) => (
  <InteractiveContainer
    className={cx(
      "flex items-center gap-4 rounded-2xl bg-[#F9F9FA] p-4 transition-colors",
      { "hover:bg-[#F5F5F8]": !!onClick }
    )}
    isDisabled={!onClick}
    onClick={onClick}
  >
    <div className="relative">
      <Avatar username={title} size="lg" imageUrl={avatarSrc} />
      {subAvatarSrc && (
        <div className="absolute bottom-0 right-0">
          <Avatar
            username={subAvatarTitle || ""}
            size="xs"
            imageUrl={subAvatarSrc}
          />
        </div>
      )}
    </div>
    <div className="min-w-0 flex-1">
      <div className="truncate font-medium leading-4">{title}</div>
      {subTitle && (
        <div className="leading-2 truncate text-xs text-[#707577]">
          {subTitle}
        </div>
      )}
    </div>
    {!!rightContent && <div>{rightContent}</div>}
  </InteractiveContainer>
);
