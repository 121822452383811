import {
  ExtraMetaData,
  useTusUpload,
} from "@jugl-web/domain-resources/files/hooks/useTusUpload";
import { FilesModule } from "@jugl-web/domain-resources/files/types";
import { UsersApiTag } from "@jugl-web/rest-api";
import { getUniqueId } from "@jugl-web/utils";
import { usersApi } from "@web-src/features/api/createApi";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

export interface UploadingFileItem {
  id: string;
  userId: string;
  file: File;
  progress: number;
  isCompleted?: boolean;
  isFailed?: boolean;
}

export const useUploadDocuments = () => {
  const [uploadingFiles, setUploadingFiles] = useState<UploadingFileItem[]>([]);
  const { entity } = useEntity();
  const dispatch = useDispatch();
  const { uploadFile } = useTusUpload({
    module: FilesModule.drive,
    entityId: entity?.id,
  });

  const deleteFileFromUploadingFiles = useCallback((fileId: string) => {
    const timeoutId = setTimeout(() => {
      setUploadingFiles((prev) => prev.filter((file) => file.id !== fileId));
    }, 1500);

    return () => clearTimeout(timeoutId);
  }, []);

  const deleteUploadingFiles = useCallback(
    (id: string) =>
      setUploadingFiles((prev) => prev.filter((file) => file.id !== id)),
    []
  );

  const uploadFiles = useCallback(
    async (files: File[], userId: string, extraMetaData: ExtraMetaData) => {
      if (!entity?.id) return;
      const newUploadingItems: UploadingFileItem[] = files.map((file) => ({
        id: getUniqueId(),
        userId,
        file,
        progress: 0,
        isFailed: false,
      }));
      setUploadingFiles((prev) => [...prev, ...newUploadingItems]);
      await Promise.all(
        newUploadingItems.map((item) =>
          uploadFile({
            file: item.file,
            extraMetaData,
            onProgress: (bytesSend, bytesTotal) => {
              setUploadingFiles((prev) =>
                prev.map((file) => {
                  if (file.id === item.id) {
                    return {
                      ...file,
                      progress: Math.round((bytesSend / bytesTotal) * 100),
                    };
                  }
                  return file;
                })
              );
            },
          })
            .then(() => {
              setUploadingFiles((prev) =>
                prev.map((file) => {
                  if (file.id === item.id) {
                    return {
                      ...file,
                      isCompleted: true,
                    };
                  }
                  return file;
                })
              );
              deleteFileFromUploadingFiles(item.id);
              dispatch(
                usersApi.util.invalidateTags([
                  {
                    type: UsersApiTag.userDocuments,
                    id: userId,
                  },
                ])
              );
            })
            .catch(() => {
              setUploadingFiles((prev) =>
                prev.map((file) => {
                  if (file.id === item.id) {
                    return {
                      ...file,
                      isFailed: true,
                    };
                  }
                  return file;
                })
              );
            })
        )
      );
    },
    [uploadFile, dispatch, deleteFileFromUploadingFiles, entity?.id]
  );

  const retryUpload = useCallback(
    (id: string, extraMetaData: ExtraMetaData, userId?: string) => {
      const fileToRetryUpload = uploadingFiles.find((file) => file.id === id);
      if (!fileToRetryUpload || !userId) return;
      setUploadingFiles((prev) => prev.filter((file) => file.id !== id));
      uploadFiles([fileToRetryUpload.file], userId, extraMetaData);
    },
    [uploadingFiles, uploadFiles]
  );

  return { uploadingFiles, uploadFiles, deleteUploadingFiles, retryUpload };
};
