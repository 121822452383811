import { cx, useTranslations } from "@jugl-web/utils";
import { FC } from "react";

export interface BetaTagProps {
  className?: string;
}

export const BetaTag: FC<BetaTagProps> = ({ className }) => {
  const { t } = useTranslations();

  return (
    <div
      className={cx(
        "inline-flex h-[27px] min-w-[48px] shrink-0 select-none items-center justify-center rounded-2xl px-3",
        className
      )}
      style={{
        background:
          "linear-gradient(112.66deg, #FFFFFF -1.8%, #62ACF1 19.38%, #4497E4 41.85%)",
      }}
    >
      <span className="font-secondary text-[10px] font-semibold text-white">
        {t({ id: "common.beta", defaultMessage: "Beta" })}
      </span>
    </div>
  );
};
