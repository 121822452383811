import { assert } from "@jugl-web/utils";
import { useLocalStorage } from "@jugl-web/utils/hooks/useStorage";
import { TASK_PREFERENCES_BY_TASK_ID_KEY } from "@jugl-web/utils/storage";
import { useCallback, useMemo } from "react";

export type TaskPreferences = {
  areCompletedChecklistItemsHidden: boolean;
};

type TaskPreferencesByTaskId = Record<string, TaskPreferences>;

const DEFAULT_TASK_PREFERENCES: TaskPreferences = {
  areCompletedChecklistItemsHidden: false,
};

interface UseTaskPreferencesOptions {
  taskId: string | undefined;
}

export const useTaskPreferences = ({ taskId }: UseTaskPreferencesOptions) => {
  const [preferencesByTaskId, setPreferencesByTaskId] =
    useLocalStorage<TaskPreferencesByTaskId>(
      TASK_PREFERENCES_BY_TASK_ID_KEY,
      {}
    );

  const preferences = useMemo(() => {
    if (!taskId) {
      return DEFAULT_TASK_PREFERENCES;
    }

    return preferencesByTaskId[taskId] || DEFAULT_TASK_PREFERENCES;
  }, [taskId, preferencesByTaskId]);

  const updatePreference = useCallback(
    <
      TField extends keyof TaskPreferences,
      TValue extends TaskPreferences[TField]
    >(
      field: TField,
      value: TValue | ((previousValue: TaskPreferences[TField]) => TValue)
    ) => {
      assert(!!taskId, "Task ID must be defined to update preferences");

      setPreferencesByTaskId((previousPreferencesByTaskId) => {
        const resolvedValue =
          typeof value === "function"
            ? value(
                previousPreferencesByTaskId[taskId]?.[field] ??
                  DEFAULT_TASK_PREFERENCES[field]
              )
            : value;

        return {
          ...previousPreferencesByTaskId,
          [taskId]: {
            ...previousPreferencesByTaskId[taskId],
            [field]: resolvedValue,
          },
        };
      });
    },
    [setPreferencesByTaskId, taskId]
  );

  return {
    preferences,
    updatePreference,
  };
};
