import React from "react";
import UserProfileAvatar from "@web-src/features/users/components/UserProfileAvatar";
import UserProfileName from "@web-src/features/users/components/UserProfileName";
import { Text } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { ReactComponent as MinimizeIcon } from "./assets/minimize.svg";
import { ToggleButton } from "../../../ToggleButton";

export const Header: React.FC<{
  userJoined: boolean;
  callIsOnHold: boolean;
  isIncomingCall: boolean;
  formattedDuration: string;
  callerId: string;
  handleMinimize: () => void;
}> = ({
  userJoined,
  callIsOnHold,
  isIncomingCall,
  formattedDuration,
  callerId,
  handleMinimize,
}) => {
  const { t } = useTranslations();

  return (
    <div className="relative flex h-[80px] flex-row items-center bg-[#3D3D3E] px-2">
      <span className="ml-6 flex flex-row gap-3">
        <UserProfileAvatar
          userId={callerId}
          wrapperClassName="h-[48px] w-[48px] rounded-full bg-grey-100 flex items-center place-content-center bg-gradient-to-b from-gray-300 to-dark-100 mt-0.5"
          className="bg-grey-100 h-[48px] w-[48px] rounded-full"
          lettersClassName="h-[48px] w-[48px] text-dark flex items-center justify-center text-base font-semibold uppercase leading-3"
        />
        <span className="flex flex-col place-content-center">
          <Text variant="body2" className="font-bold text-white">
            <UserProfileName userId={callerId} />
          </Text>
          <Text variant="body3" className="text-dark-200">
            {isIncomingCall
              ? t({
                  id: "audio-call-component.incoming-call",
                  defaultMessage: "Incoming call",
                })
              : t({
                  id: "audio-call-component.outgoing-call",
                  defaultMessage: "Outgoing call",
                })}
            {!userJoined && (
              <Text variant="timestamp" className="text-primary-200">
                {" "}
                {t({
                  id: "audio-call-component.ringing",
                  defaultMessage: "Ringing",
                })}
                ...
              </Text>
            )}
            {userJoined && !callIsOnHold && (
              <Text variant="timestamp" className="text-primary-200 ml-2">
                {formattedDuration}
              </Text>
            )}
            {callIsOnHold && (
              <Text variant="timestamp" className="text-primary-200 ml-2">
                {t({
                  id: "audio-call-component.call-is-on-hold",
                  defaultMessage: "Call is on hold",
                })}
              </Text>
            )}
          </Text>
        </span>
      </span>
      <div className="absolute right-14">
        <ToggleButton
          onClick={handleMinimize}
          isOn={false}
          onIcon={MinimizeIcon}
          offIcon={MinimizeIcon}
          noBgChange
          className="h-[48px] w-[48px]"
        />
      </div>
    </div>
  );
};
