import { TaskCustomFieldType } from "@jugl-web/rest-api/tasks";
import { BottomCenteredDrawer } from "@jugl-web/ui-components/web/BottomCenteredDrawer";
import { useAppVariant, useToast, useTranslations } from "@jugl-web/utils";
import { useScreenTransitionManager } from "@jugl-web/utils/utils/ScreenTransitionManager";
import { FC, useState } from "react";
import { useTaskFields } from "../../../hooks/useTaskFields";
import { taskCustomFieldNameValidator } from "../../../validators";
import { CustomFieldForm } from "../CustomFieldForm";
import { ManageTaskFieldsDialogScreenToParametersMap } from "../types";

interface NewCustomFieldScreenProps {
  entityId: string;
  initialFieldName?: string;
  initialFieldType?: TaskCustomFieldType;
  initialIsShownInCard?: boolean;
}

export const NewCustomFieldScreen: FC<NewCustomFieldScreenProps> = ({
  entityId,
  initialFieldName = "",
  initialFieldType = "text",
  initialIsShownInCard = false,
}) => {
  const [fieldName, setFieldName] = useState(initialFieldName);
  const [fieldType, setFieldType] =
    useState<TaskCustomFieldType>(initialFieldType);
  const [isShownInCard, setIsShownInCard] = useState(initialIsShownInCard);

  const { transitionTo } =
    useScreenTransitionManager<ManageTaskFieldsDialogScreenToParametersMap>();

  const { createCustomField, isCreatingCustomField } = useTaskFields({
    entityId,
  });

  const { toast } = useToast({ variant: useAppVariant().variant });
  const { t } = useTranslations();

  const hasDropdownType = fieldType === "dropdown";
  const isValid = taskCustomFieldNameValidator(fieldName);

  const handleSubmit = async () => {
    if (hasDropdownType) {
      transitionTo({
        name: "newCustomDropdownFieldValues",
        fieldName,
        isShownInCard,
      });

      return;
    }

    try {
      await createCustomField({
        name: fieldName,
        type: fieldType,
        isShownInCard,
      });

      transitionTo({ name: "entry" });
      toast(
        t({
          id: "feedback.custom-field-created",
          defaultMessage: "Custom field has been successfully created",
        }),
        {
          variant: "success",
        }
      );
    } catch {
      toast(
        t({
          id: "feedback.custom-field-creation-failed",
          defaultMessage: "Failed to create a custom field",
        }),
        { variant: "error" }
      );
    }
  };

  return (
    <BottomCenteredDrawer.Content className="flex flex-col items-center justify-center py-6">
      <CustomFieldForm>
        <CustomFieldForm.Title
          title={t({
            id: "tasks-page.new-custom-field",
            defaultMessage: "New custom field",
          })}
          className="mb-8"
        />
        <CustomFieldForm.FieldTypeSelect
          value={fieldType}
          className="mb-8"
          onSelect={setFieldType}
        />
        <CustomFieldForm.TextField
          isRequired
          label={t({
            id: "form-controls.custom-field-name.label",
            defaultMessage: "Field name",
          })}
          value={fieldName}
          placeholder={t({
            id: "form-controls.custom-field.placeholder",
            defaultMessage: "Enter field name",
          })}
          className="mb-6"
          onChange={setFieldName}
        />
        <CustomFieldForm.IsShownInCardSwitch
          isChecked={isShownInCard}
          className="mb-10"
          onChange={setIsShownInCard}
        />
        <CustomFieldForm.Actions
          submitButton={{
            label: hasDropdownType
              ? t({ id: "common.next", defaultMessage: "Next" })
              : t({ id: "common.create", defaultMessage: "Create" }),
            isDisabled: !isValid || isCreatingCustomField,
            onClick: handleSubmit,
          }}
          onCancel={() => transitionTo({ name: "entry" })}
        />
      </CustomFieldForm>
    </BottomCenteredDrawer.Content>
  );
};
