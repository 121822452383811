import { cx } from "@jugl-web/utils";
import { useFormattedDate } from "@jugl-web/utils/hooks/useFormattedDate";
import { isToday } from "date-fns";
import { FC } from "react";
import { CalendarViewLayout } from "../CalendarViewLayout";

interface CalendarWeekColumnHeaderProps {
  date: Date;
}

export const CalendarWeekColumnHeader: FC<CalendarWeekColumnHeaderProps> = ({
  date,
}) => {
  const { localeAwareFormat } = useFormattedDate();

  const isTodayColumn = isToday(date);

  return (
    <CalendarViewLayout.ColumnHeader className="relative gap-2.5">
      <div
        className={cx(
          "text-dark-600 flex h-8 w-8 items-center justify-center rounded-full",
          isTodayColumn ? "bg-primary text-white" : "bg-grey-100 text-dark-600"
        )}
      >
        {localeAwareFormat(date, "d")}
      </div>
      <span
        className={cx(
          "font-secondary text-sm",
          isTodayColumn ? "text-primary-800 font-medium" : "text-dark-800"
        )}
      >
        {localeAwareFormat(date, "EEE")}
      </span>
      {isTodayColumn && (
        <div className="bg-primary-200 absolute -bottom-[1px] left-0 right-0 h-0.5 translate-y-0.5" />
      )}
    </CalendarViewLayout.ColumnHeader>
  );
};
