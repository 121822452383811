import { usePagination, useTranslations } from "@jugl-web/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { isoToLocalDate } from "@web-src/utils/helper";
import isToday from "date-fns/isToday";
import format from "date-fns-tz/format";
import { useLazyMessagesQuery } from "@web-src/features/chats/chatsApi";
import {
  ChatMessage,
  MessagesApiFetchParams,
} from "@web-src/features/chats/types";
import isDate from "date-fns/isDate";
import * as Sentry from "@sentry/react";

import { getChatPaginationId } from "../../utils/getChatPaginationId";

type MessagesLoadDirection = "old" | "new";

type LastPageState = {
  oldLastTime?: string;
  oldLastReached?: boolean;
  newLastTime?: string;
  newLastReached?: boolean;
};

export const useMessages = ({
  entityId,
  chatId,
  noInitialLoad,
  initFromMessage,
  fetchParams,
}: {
  entityId?: string;
  // TODO: make required
  chatId?: string;
  fetchParams?: Partial<MessagesApiFetchParams>;
  noInitialLoad?: boolean;
  initFromMessage?: {
    created: string;
    id: string;
  };
}) => {
  const { t } = useTranslations();
  const { dateLocale } = useLanguage();
  const {
    items: messages,
    addItems,
    setLastPageState,
    lastPageState,
    isLoading: messagesIsLoading,
    setIsLoadingState,
    isInitialized,
    reset: resetPagination,
  } = usePagination<ChatMessage, LastPageState>(
    getChatPaginationId(chatId, fetchParams)
  );
  const [isInitialLoadError, setInitialLoadError] = useState<boolean>();
  const [
    loadMessages,
    { isLoading: isMessagesLoading, isError: isMessagesError },
  ] = useLazyMessagesQuery();
  const handleLoadMessages = useCallback(
    async ({
      loadDireaction,
      forceTime,
      initialLoad,
      reset,
    }: {
      loadDireaction: MessagesLoadDirection;
      forceTime?: string;
      initialLoad?: boolean;
      reset?: boolean;
    }) => {
      if (
        !entityId ||
        !chatId ||
        (loadDireaction === "old" && lastPageState?.oldLastReached) ||
        (loadDireaction === "new" && lastPageState?.newLastReached)
      ) {
        return;
      }
      setIsLoadingState(true);
      let time = forceTime || `${new Date().getFullYear() + 1}-01-01 00:00:00`;
      if (!forceTime && !reset) {
        switch (loadDireaction) {
          case "new":
            if (lastPageState?.newLastTime) {
              time = lastPageState.newLastTime;
            }
            break;
          case "old":
            if (lastPageState?.oldLastTime) {
              time = lastPageState.oldLastTime;
            }
            break;
          default:
        }
      }
      const response = await loadMessages({
        params: {
          entity_id: entityId,
          time,
          limit: 20,
          chat_id: chatId,
          filter: loadDireaction === "new" ? "old" : undefined,
          ...fetchParams,
        },
      });
      if (!response.data) {
        // TODO: error handling
        setIsLoadingState(false);
        throw response.error as Error;
      }
      let loadedMessages = response.data.data.map((item) => ({
        id: item.msg_id,
        data: item,
      }));
      switch (loadDireaction) {
        case "new": {
          let newLastPageState = { ...lastPageState };
          if (initialLoad) {
            const initialOldResponse = await loadMessages({
              params: {
                entity_id: entityId,
                time,
                limit: 20,
                chat_id: chatId,
                ...fetchParams,
              },
            });
            // TODO: error handling
            if (initialOldResponse?.data) {
              const initialOldMessages = initialOldResponse?.data?.data.map(
                (item) => ({
                  id: item.msg_id,
                  data: item,
                })
              );

              loadedMessages = [
                ...initialOldMessages.reverse(),
                ...loadedMessages,
              ];

              // addItems(initialOldMessages, "start");
              newLastPageState.oldLastReached =
                !initialOldResponse.data.remaining_item;
              if (initialOldMessages?.length) {
                newLastPageState.oldLastTime =
                  initialOldMessages[
                    initialOldMessages.length - 1
                  ].data.timestamp;
              }
            }
          }
          if (!loadedMessages?.length) {
            addItems([], "end");
            setLastPageState({
              ...newLastPageState,
              newLastReached: !response.data.remaining_item,
            });
            break;
          }
          addItems(loadedMessages, "end");
          newLastPageState = {
            ...newLastPageState,
            newLastTime:
              loadedMessages[loadedMessages.length - 1].data.timestamp,
            newLastReached: !response.data.remaining_item,
          };
          if (initialLoad) {
            newLastPageState.oldLastTime = loadedMessages[0].data.timestamp;
          }
          setLastPageState(newLastPageState);
          break;
        }
        case "old": {
          if (!loadedMessages?.length) {
            addItems([], "start");
            const lastPageStateToSet: LastPageState = {
              ...lastPageState,
              oldLastReached: !response.data.remaining_item,
            };
            if (initialLoad) {
              lastPageStateToSet.newLastReached = true;
            }
            setLastPageState(lastPageStateToSet);
            break;
          }
          loadedMessages.reverse();
          addItems(loadedMessages, "start");
          const newLastPageState = {
            ...lastPageState,
            oldLastTime: loadedMessages[0].data.timestamp,
            oldLastReached: !response.data.remaining_item,
          };
          if (initialLoad || reset) {
            newLastPageState.newLastReached = true;
          }
          setLastPageState(newLastPageState);
          break;
        }
        default:
      }
      setIsLoadingState(false);
    },
    [
      addItems,
      chatId,
      entityId,
      lastPageState,
      loadMessages,
      setIsLoadingState,
      setLastPageState,
      fetchParams,
    ]
  );

  const resetToBottom = useCallback(() => {
    resetPagination();
    handleLoadMessages({ loadDireaction: "old", reset: true });
  }, [handleLoadMessages, resetPagination]);

  useEffect(() => {
    if (
      !entityId ||
      !chatId ||
      noInitialLoad ||
      messagesIsLoading ||
      messages?.length ||
      lastPageState ||
      isInitialized ||
      isInitialLoadError
    ) {
      return;
    }
    handleLoadMessages({
      loadDireaction: initFromMessage ? "new" : "old",
      forceTime: initFromMessage?.created,
      initialLoad: true,
    }).catch(() => {
      setInitialLoadError(true);
    });
  }, [
    handleLoadMessages,
    lastPageState,
    messages?.length,
    messagesIsLoading,
    noInitialLoad,
    entityId,
    chatId,
    initFromMessage,
    isInitialized,
    isInitialLoadError,
  ]);

  const messageGroups = useMemo(() => {
    const counts: number[] = [];
    const groups: string[] = [];
    if (!messages) {
      return {
        counts,
        groups,
      };
    }
    messages.forEach((message) => {
      const date = isoToLocalDate(message.data.timestamp);
      if (!isDate(date)) {
        Sentry.captureException(
          new Error(`Invalid message time value ${JSON.stringify(message)}`)
        );
        return;
      }
      const dateStr = isToday(date)
        ? t({
            id: "common.today",
            defaultMessage: "Today",
          })
        : format(date, "d MMMM", { locale: dateLocale });
      if (groups.includes(dateStr)) {
        counts[counts.length - 1] += 1;
      } else {
        groups.push(dateStr);
        counts.push(1);
      }
    });
    return {
      counts,
      groups,
    };
  }, [messages, t, dateLocale]);

  return {
    messages,
    messageGroups,
    isMessagesLoading,
    isMessagesError,
    load: handleLoadMessages,
    resetPagination,
    resetToBottom,
    newLastLoaded: lastPageState?.newLastReached,
    oldLastLoaded: lastPageState?.oldLastReached,
  };
};
