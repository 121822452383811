import { TaskCalendarView } from "@jugl-web/domain-resources/tasks";
import { ListBoxItem, Radio } from "@jugl-web/ui-components/cross-platform";
import { PopoverProps } from "@jugl-web/ui-components/cross-platform/Popover";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { useTranslations } from "@jugl-web/utils";
import { useTasksPageContext } from "@web-src/features/tasks/TasksPageContext";
import { FC, useMemo } from "react";

export const TaskCalendarViewsPopover: FC<
  Pick<PopoverProps, "renderTrigger">
> = ({ renderTrigger }) => {
  const { taskCalendarView, setTaskCalendarView } = useTasksPageContext();

  const { t } = useTranslations();

  const calendarViewsAsListItems = useMemo<
    ListBoxItem<{ view: TaskCalendarView; label: string }>[]
  >(
    () => [
      {
        id: "week",
        value: {
          view: "week",
          label: t({
            id: "tasks-page.calendar-view-week",
            defaultMessage: "Week View",
          }),
        },
      },
      {
        id: "month",
        value: {
          view: "month",
          label: t({
            id: "tasks-page.calendar-view-month",
            defaultMessage: "Month View",
          }),
        },
      },
      // {
      //   id: "year",
      //   value: {
      //     view: "year",
      //     label: t({
      //       id: "tasks-page.calendar-view-year",
      //       defaultMessage: "Year view",
      //     }),
      //   },
      // },
    ],
    [t]
  );

  return (
    <ResourcePickerPopover
      placement="bottom"
      items={calendarViewsAsListItems}
      selectionBehavior={{ mode: "single", canToggle: false }}
      defaultSelectedIds={[taskCalendarView]}
      itemSize="sm"
      spaceBetweenItems="compact"
      renderTrigger={renderTrigger}
      renderLabel={(item) => item.value.label}
      renderEndIcon={(item) => (
        <Radio readOnly isChecked={taskCalendarView === item.value.view} />
      )}
      className="w-[190px]"
      onSelect={({ item, onClose }) => {
        setTaskCalendarView(item.value.view);
        onClose();
      }}
    />
  );
};
