import { AvatarSelect, CountryPicker } from "@jugl-web/ui-components/web";
import { FC, useCallback, useEffect } from "react";
import { Controller, FormState, useForm } from "react-hook-form";
import { Button, Input } from "@jugl-web/ui-components/cross-platform";
import { cx, isValidEmail, useTranslations } from "@jugl-web/utils";
import { CountryPickerValue } from "@jugl-web/ui-components/web/CountryPicker/types";
import countries from "countries-phone-masks";
import {
  useFileSelect,
  avatarFileSelectConfig,
} from "@jugl-web/domain-resources/files/providers/FileSelectProvider";
import { ReactComponent as ChevronIcon } from "./assets/chevron.svg";

export type EntityFormValues = {
  name: string;
  email: string;
  country?: CountryPickerValue;
  imageFile?: File;
};

export type EntityFormProps = {
  defaultValues?: Pick<EntityFormValues, "name" | "email" | "imageFile"> & {
    countryIso: string;
  };
  defaultImgSrc?: string | null;
  canDeleteImage?: boolean;
  onSubmit: (values: EntityFormValues) => void;
  onFormStateChange?: (state: FormState<EntityFormValues>) => void;
};

export const EntityForm: FC<EntityFormProps> = ({
  defaultValues,
  onSubmit,
  defaultImgSrc,
  onFormStateChange,
  canDeleteImage = false,
}) => {
  const { register, handleSubmit, setValue, watch, control, formState } =
    useForm<EntityFormValues>({
      mode: "all",
      defaultValues: {
        ...defaultValues,
        country: countries.find(
          (country) => country.iso === defaultValues?.countryIso
        ),
      },
    });

  const { selectFile } = useFileSelect(avatarFileSelectConfig);

  const { isValid } = formState;

  const { t } = useTranslations();

  const handleChangeImage = useCallback(
    (file?: File) => {
      setValue("imageFile", file, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [setValue]
  );

  useEffect(() => {
    onFormStateChange?.(formState);
  }, [formState, onFormStateChange]);

  const displayImage = watch("imageFile");

  const selectedCountry = watch("country");

  const handleFormSubmit = handleSubmit((values) => {
    onSubmit?.(values);
  });

  return (
    <div className="flex max-w-[464px] flex-col items-center px-8">
      <AvatarSelect
        onSave={handleChangeImage}
        value={displayImage}
        defaultSrc={defaultImgSrc || undefined}
        showRemoveButton={canDeleteImage}
        externalSelectFile={selectFile}
      />
      <div className="mt-7 flex w-[400px] flex-col gap-8">
        <Input
          className="w-full"
          placeholder="Enter Workspace Name"
          {...register("name", {
            required: true,
            validate: (value) => !!value.trim(),
          })}
        />
        <Input
          className="w-full"
          placeholder="Enter Workspace Email"
          {...register("email", { required: true, validate: isValidEmail })}
        />
        <Controller
          control={control}
          name="country"
          rules={{
            required: {
              value: true,
              message: t({
                id: "form-controls.organisation-country.error-is-required",
                defaultMessage: "Organisation Country is required",
              }),
            },
          }}
          render={({ field }) => (
            <CountryPicker
              countryIso={field.value?.iso}
              onSelect={(item) => field.onChange(item)}
              placement="top"
            >
              {(isOpen) => (
                <div className="flex items-center justify-between border-0 border-b border-solid border-black/20 p-2.5">
                  <span
                    className={cx(
                      selectedCountry?.name ? "text-dark" : "text-dark-500"
                    )}
                  >
                    {selectedCountry?.name ||
                      t({
                        id: "form-controls.business-country.placeholder",
                        defaultMessage: "Business Country",
                      })}
                  </span>
                  <ChevronIcon
                    className={cx(
                      "transition-transform",
                      isOpen && "rotate-180"
                    )}
                  />
                </div>
              )}
            </CountryPicker>
          )}
        />
      </div>
      <Button
        isDisabled={!isValid}
        className="mt-16 w-[300px]"
        onClick={handleFormSubmit}
      >
        Submit
      </Button>
    </div>
  );
};
