import React from "react";
import { ReactComponent as SearchIcon } from "./assets/search.svg";

const ModalInput: React.FC<{
  onChange: (value: string) => void;
  placeholder: string;
  hasIcon: boolean;
}> = ({ onChange, hasIcon, placeholder }) => (
  <div className="relative h-10 w-full">
    {hasIcon && (
      <SearchIcon className="absolute top-1/2 left-3 -translate-y-1/2" />
    )}
    <input
      className={`border-grey-200 h-10 w-full border-x-0 border-b border-t-0 ${
        hasIcon ? "pl-10" : ""
      } text-base font-medium leading-3 text-[#333333] outline-none`}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
    />
  </div>
);
export default ModalInput;
