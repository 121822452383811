import { SerializedEditorState, SerializedLexicalNode } from "lexical";
import { useCallback, useState } from "react";
import { singletonHook } from "react-singleton-hook";

interface UseChatInputStateType {
  chatsState: {
    [key: string]: {
      body?: SerializedEditorState<SerializedLexicalNode>;
      file?: File;
    };
  };
  updateChatState?: (
    chatId: string,
    state: { body?: SerializedEditorState<SerializedLexicalNode>; file?: File }
  ) => void;
}

const useChatInputState = (): UseChatInputStateType => {
  const [chatsState, setChatsState] = useState<
    UseChatInputStateType["chatsState"]
  >({});
  const updateChatState = useCallback(
    (
      chatId: string,
      state: {
        body?: SerializedEditorState<SerializedLexicalNode>;
        file?: File;
      }
    ) => {
      setChatsState((prev) => ({ ...prev, [chatId]: state }));
    },
    []
  );
  return { chatsState, updateChatState };
};

export default singletonHook(
  {
    chatsState: {},
    updateChatState: undefined,
  },
  useChatInputState
);
