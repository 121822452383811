import { skipToken } from "@reduxjs/toolkit/dist/query";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useMatch } from "react-router-dom";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { selectActiveChatId, setActiveChatId } from "../chatsSlice";
import useConversations from "./useConversations";

const useActiveChat = () => {
  const dispatch = useDispatch();
  const match = useMatch(`/:entityId/chats/:chatId/*`);
  const { navigateToPage } = useNavigation();
  const activeChatId = useSelector(selectActiveChatId);
  const { conversations } = useConversations();
  const { entity } = useEntity();
  const chatId = activeChatId || match?.params.chatId;
  const chat = useMemo(
    () =>
      chatId ? conversations?.find((item) => item.id === chatId) : undefined,
    [chatId, conversations]
  );
  const meId = useSelector(selectUserId);
  const { workspacesApi } = useRestApiProvider();
  const { data: participants, isFetching: isParticipantsFetching } =
    workspacesApi.use_DEPRECATED_workspaceAllParticipantsQuery(
      !entity || !chat || chat?.data?.type !== "muc"
        ? skipToken
        : { id: chat.id, entityId: entity.id }
    );
  const isParticipant = useMemo(() => {
    if (
      chat?.data?.type === "chat" ||
      chat?.data.type === "entity_conversation"
    ) {
      return undefined;
    }
    return !!participants?.find((item) => item.user_id === meId);
  }, [meId, participants, chat]);

  return {
    chat: chat?.data,
    isParticipant,
    participants:
      isParticipantsFetching || chat?.data?.type === "chat" ? [] : participants,
    setActiveChat: (id: string, navigate = true) => {
      if (navigate) {
        navigateToPage(
          "chatsActiveChat",
          { activeChatId: id },
          { replacePathOnly: true }
        );
      }
      dispatch(setActiveChatId(id));
    },
  };
};

export default useActiveChat;
