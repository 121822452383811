import {
  InternalTaskCustomField,
  InternalTaskFilters,
} from "@jugl-web/rest-api/tasks";
import { HookOutOfContextError } from "@jugl-web/utils";
import isEqual from "lodash/isEqual";
import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { INITIAL_FILTERS_STATE } from "./consts";
import { UpdateFiltersFn } from "./types";
import { getActiveFiltersCount, getStateTransformer } from "./utils";

export const useTaskFiltersState = (initialState = INITIAL_FILTERS_STATE) => {
  const [internalInitialState, setInternalInitialState] =
    useState(initialState);

  const [filtersState, setFiltersState] = useState(internalInitialState);

  const activeFiltersCount = useMemo(
    () => getActiveFiltersCount(filtersState),
    [filtersState]
  );

  const hasActiveFilter = activeFiltersCount > 0;

  const isDirty = useCallback(
    () => !isEqual(filtersState, internalInitialState),
    [filtersState, internalInitialState]
  );

  const updateFiltersState = useCallback<UpdateFiltersFn>((field, value) => {
    setFiltersState((previousFilters) => {
      const transformState = getStateTransformer(previousFilters);
      return transformState(field, value);
    });
  }, []);

  const updateCustomFieldFilter = useCallback(
    (
      customField: InternalTaskCustomField,
      values: InternalTaskFilters["customFields"]["string"]
    ) => {
      updateFiltersState("customFields", (previousCustomFields) => ({
        ...previousCustomFields,
        [customField.id]: values,
      }));
    },
    [updateFiltersState]
  );

  const clearFiltersState = useCallback(() => {
    setFiltersState(INITIAL_FILTERS_STATE);
  }, []);

  const resetFiltersState = useCallback((newState = INITIAL_FILTERS_STATE) => {
    setInternalInitialState(newState);
    setFiltersState(newState);
  }, []);

  const filtersStateContext = {
    filtersState,
    activeFiltersCount,
    hasActiveFilter,
    isDirty,
    updateFiltersState,
    updateCustomFieldFilter,
    clearFiltersState,
    resetFiltersState,
  };

  return {
    ...filtersStateContext,
    filtersStateContext,
  };
};

// #region Context-related stuff
type UseTaskFiltersStateContext = ReturnType<
  typeof useTaskFiltersState
>["filtersStateContext"];

const TaskFiltersStateContext =
  createContext<UseTaskFiltersStateContext | null>(null);

interface TaskFiltersStateProviderProps {
  children: ReactNode;
  context: UseTaskFiltersStateContext;
}

export const TaskFiltersStateProvider: FC<TaskFiltersStateProviderProps> = ({
  children,
  context,
}) => (
  <TaskFiltersStateContext.Provider value={context}>
    {children}
  </TaskFiltersStateContext.Provider>
);

export const useTaskFiltersStateContext = () => {
  const context = useContext(TaskFiltersStateContext);

  if (!context) {
    throw new HookOutOfContextError(
      "useTaskFiltersStateContext",
      "TaskFiltersStateProvider"
    );
  }

  return context;
};
// #endregion
