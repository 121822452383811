import { DndContext } from "@dnd-kit/core";
import { useTasks } from "@jugl-web/domain-resources/tasks/hooks/useTasks";
import { useTranslations } from "@jugl-web/utils";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { FC } from "react";
import { useTasksDnd } from "../TaskKanbanView/hooks/useTasksDnd";
import { useTasksPageContext } from "../TasksPageContext";
import { TaskViewLoading } from "../TaskViewLoading";
import { CalendarMonthView } from "./components/CalendarMonthView";
import { CalendarTeamWeekView } from "./components/CalendarTeamWeekView";
import { CalendarWeekView } from "./components/CalendarWeekView";
import { CalendarYearView } from "./components/CalendarYearView";
import { NavigationColumn } from "./components/NavigationColumn";

export const TaskCalendarView: FC = () => {
  const { taskCalendarView, taskListMode } = useTasksPageContext();

  const { entityId } = useEntitySelectedProvider();

  const { isLoading } = useTasks({ entityId, mode: taskListMode });

  const { dndContextProps } = useTasksDnd();

  const { t } = useTranslations();

  if (isLoading) {
    return (
      <TaskViewLoading
        label={t({
          id: "tasks-page.loading-tasks",
          defaultMessage: "Loading Tasks...",
        })}
        className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
      />
    );
  }

  return (
    <DndContext {...dndContextProps}>
      <div className="animate-fade-in flex h-full min-w-fit bg-[#EEF2F5]">
        <NavigationColumn />
        {taskListMode === "personal" ? (
          <>
            {taskCalendarView === "week" && <CalendarWeekView />}
            {taskCalendarView === "month" && <CalendarMonthView />}
            {taskCalendarView === "year" && <CalendarYearView />}
          </>
        ) : (
          <CalendarTeamWeekView />
        )}
      </div>
    </DndContext>
  );
};
