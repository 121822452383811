import { FILE_FORMAT } from "../consts";

const TO_RADIANS = Math.PI / 180;

export async function rotateImage(image: HTMLImageElement) {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("No 2d context");
  }
  canvas.width = image.naturalHeight;
  canvas.height = image.naturalWidth;

  ctx.clearRect(0, 0, image.naturalWidth, image.naturalHeight);

  ctx.save();
  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.rotate(90 * TO_RADIANS);
  ctx.drawImage(image, -image.naturalWidth / 2, -image.naturalHeight / 2);

  const blob = await new Promise<Blob | null>((resolve) => {
    canvas.toBlob(resolve, FILE_FORMAT);
  });
  if (blob) {
    return URL.createObjectURL(blob);
  }

  throw new Error("Error occurred while rotating the image");
}
