import { PageNavParams } from "@web-src/modules/navigation/types";
import { ModulePagesConfig } from "@web-src/modules/navigation/types/ModulePagesConfigs";
import { PeoplePage } from "./PeoplePage";
import { TeamsPage } from "./TeamsPage";
import { CPanelPage } from "../../workspace/pages/CPanelPage/CPanelPage";

export type PeoplePagesNavigationParams = {
  workspaceMembers: PageNavParams;
  peoplePeople: PageNavParams;
  peopleTeams: PageNavParams;
};

export const peoplePagesNavigationConfig: ModulePagesConfig<PeoplePagesNavigationParams> =
  {
    workspaceMembers: {
      element: <CPanelPage />,
      path: "/workspace/members",
      isPrivate: true,
      isActiveSubscriptionOptional: true,
    },
    peoplePeople: {
      element: <PeoplePage />,
      path: "people",
      isPrivate: true,
    },
    peopleTeams: {
      element: <TeamsPage />,
      path: "people/teams",
      isPrivate: true,
    },
  };
