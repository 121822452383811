import { cx, useTranslations } from "@jugl-web/utils";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import OTPInput from "react-otp-input";
import { usePrevious } from "react-use";
import { CountDownTimer } from "./components/CountDownTimer";

export type OTPVerificationFormParams = {
  title?: ReactNode | string;
  subTitle?: ReactNode | string;
  otp?: string;
  className?: string;
  onChange?: (otp: string) => void;
  onSubmit?: (otp: string) => void;
  onResend: () => void;
  error?: string;
};

export const OTPVerificationForm: React.FC<OTPVerificationFormParams> = ({
  title,
  subTitle,
  otp: initialOtp,
  className,
  onChange,
  onSubmit,
  onResend,
  error,
}) => {
  const { t } = useTranslations();
  const [otp, setOtp] = useState(initialOtp || "");
  const previousOpt = usePrevious(otp);
  const isOTPValid = useMemo(() => otp.length === 6, [otp]);
  const [timerActive, setTimerActive] = useState(true);

  useEffect(() => {
    if (!isOTPValid || previousOpt === otp) {
      return;
    }
    onSubmit?.(otp);
  }, [isOTPValid, onSubmit, otp, previousOpt]);

  return (
    <div
      className={cx(
        "flex h-full max-w-[450px] grow flex-col items-center gap-5 p-8",
        className
      )}
    >
      {(title || subTitle) && (
        <div className="mb-10 flex flex-col items-center gap-5 text-center">
          {title && (
            <span className="text-dark w-3/4 text-4xl font-medium">
              {title}
            </span>
          )}
          {subTitle && (
            <span className="text-grey-800 text-sm">{subTitle}</span>
          )}
        </div>
      )}
      <div className="flex flex-col items-center">
        <OTPInput
          value={otp}
          onChange={(e) => {
            const value = e.replace(/[^0-9]/g, "");
            onChange?.(value);
            setOtp(value);
          }}
          containerStyle={{
            justifyContent: "space-between",
            gap: "10px",
            marginBottom: "20px",
            height: "56px",
          }}
          renderInput={(props) => (
            <input {...props} className="focus-within:border-primary" />
          )}
          inputType="tel"
          inputStyle={{
            width: "100%",
            height: "100%",
            background: error ? "#FAECEE" : "#F2F2F4",
            border: "none",
            borderRadius: "10px",
            fontSize: "18px",
            lineHeight: "22px",
            fontWeight: "500",
          }}
          numInputs={6}
        />
        {error && (
          <span className="text-gradients-danger text-xs">{error}</span>
        )}
      </div>
      <div className="mt-auto flex flex-col items-center gap-2">
        <span className="text-grey">
          {t({
            id: "otp-verification-page.code-not-received-message",
            defaultMessage: "Didn't get code yet?",
          })}
        </span>
        {timerActive ? (
          <CountDownTimer
            onCountDownEnd={() => {
              setTimerActive(false);
            }}
          />
        ) : (
          <span
            className="text-primary cursor-pointer text-sm font-semibold"
            onClick={() => {
              setTimerActive(true);
              onResend();
            }}
          >
            {t({
              id: "otp-verification-page.tap-to-resend",
              defaultMessage: "Tap to Resend",
            })}
          </span>
        )}
      </div>
    </div>
  );
};
