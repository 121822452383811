export const addSearchParamsToURL = (
  url: string,
  params: Record<string, string | number | undefined>
): string => {
  let newUrl = url;

  const hasQueryString = url.includes("?");

  const searchParams = hasQueryString
    ? new URLSearchParams(url.split("?")[1])
    : new URLSearchParams();

  Object.keys(params).forEach((paramName) => {
    const paramValue = params[paramName];
    if (paramValue === undefined) {
      return;
    }

    const encodedParamName = encodeURIComponent(paramName);
    const encodedParamValue = String(paramValue);

    if (!searchParams.has(paramName)) {
      searchParams.append(encodedParamName, encodedParamValue);
    } else {
      searchParams.set(encodedParamName, encodedParamValue);
    }
  });

  const paramString = searchParams.toString();
  newUrl += hasQueryString ? `&${paramString}` : `?${paramString}`;

  return newUrl;
};
