import { Avatar, PlainButton } from "@jugl-web/ui-components/cross-platform";
import { cx, onEnter, useTranslations } from "@jugl-web/utils";
import { CustomContentProps, SnackbarContent, useSnackbar } from "notistack";
import { forwardRef, useMemo } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useRestApiProvider } from "@jugl-web/rest-api";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { ReactComponent as ArrowIcon } from "./icons/arrow.svg";
import { ReactComponent as CloseIcon } from "./icons/close.svg";
import { ReactComponent as DriveIcon } from "./icons/drive.svg";
import { ReactComponent as TasksIcon } from "./icons/tasks.svg";
import { InAppNotification } from "../../providers/NotificationsProvider";

export interface InAppNotificationSnackbarProps extends CustomContentProps {
  notification: InAppNotification;
}

export const InAppNotificationSnackbar = forwardRef<
  HTMLDivElement,
  InAppNotificationSnackbarProps
>(({ id, notification, style }, ref) => {
  const { closeSnackbar } = useSnackbar();
  const { entity } = useEntity();
  const { t } = useTranslations();

  const { usersApi, workspacesApi } = useRestApiProvider();
  const { data: userProfile } = usersApi.useGetUserProfileQuery(
    (notification.module === "calls" || notification.module === "chats") &&
      notification.senderId
      ? {
          params: {
            user_id: notification.senderId,
          },
        }
      : skipToken
  );

  const { data: workspace } = workspacesApi.useGetWorkspaceQuery(
    notification.module === "groups" && entity?.id && notification.senderId
      ? {
          entityId: notification.entityId,
          workspaceId: notification.senderId,
        }
      : skipToken
  );

  const avatar = useMemo(() => {
    switch (notification.module) {
      case "groups":
        return (
          <Avatar
            size="md"
            username={workspace?.title ?? ""}
            imageUrl={workspace?.display_picture}
          />
        );

      case "calls":
      case "chats":
        return (
          <Avatar
            size="md"
            username={`${userProfile?.general.first_name} ${userProfile?.general.last_name}`}
            imageUrl={userProfile?.general.img}
          />
        );

      default:
        return (
          <div className="bg-primary flex h-8 w-8 items-center justify-center rounded-full">
            {notification.module === "drive" ? (
              <DriveIcon />
            ) : notification.module === "task" ? (
              <TasksIcon />
            ) : null}
          </div>
        );
    }
  }, [notification.module, workspace, userProfile]);

  const handleClose = () => {
    closeSnackbar(id);
  };

  const handleClick = () => {
    notification.onClick?.();
    handleClose();
  };

  return (
    <SnackbarContent
      ref={ref}
      role="alert"
      tabIndex={0}
      onClick={handleClick}
      onKeyDown={onEnter(handleClick)}
      className={cx(
        "jugl__focusable-outline relative flex w-[343px] flex-col gap-3 rounded-2xl bg-white py-[18px] px-4",
        notification.onClick && "cursor-pointer"
      )}
      style={{ ...style, boxShadow: "0px 2px 24px 0px rgba(0, 0, 0, 0.14)" }}
    >
      <div className="w-full overflow-hidden">
        <div className="flex items-center gap-2.5">
          <div className="shrink-0">{avatar}</div>
          <div className="flex flex-col gap-1">
            {(notification.module === "task" ||
              notification.module === "drive") && (
              <span className="text-dark-400 text-xs">
                {notification.module === "drive"
                  ? t({
                      id: "inAppNotificationSnackbar.drive-updates",
                      defaultMessage: "Drive updates",
                    })
                  : t({
                      id: "inAppNotificationSnackbar.task-updates",
                      defaultMessage: "Task updates",
                    })}
              </span>
            )}
            <span className="text-dark-800 truncate text-sm font-medium">
              {notification.title}
            </span>
          </div>
        </div>
        {notification.body && (
          <div className="flex items-center gap-2">
            <span
              className="text-dark-800 ml-[42px] flex-grow text-sm font-normal leading-[21px]"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: notification.body,
              }}
            />
            <ArrowIcon className="shrink-0" />
          </div>
        )}
      </div>
      <PlainButton
        className="jugl__focusable-outline absolute right-4 top-[18px]"
        onClick={(event) => {
          event.stopPropagation();
          handleClose();
        }}
        onKeyDown={onEnter((event) => {
          event.stopPropagation();
          handleClose();
        })}
      >
        <CloseIcon />
      </PlainButton>
    </SnackbarContent>
  );
});
