import React, { useContext, useEffect, useMemo, useState } from "react";
import { IAgoraRTCRemoteUser } from "agora-rtc-sdk-ng";
import useDebounce from "@web-src/hooks/useDebounce";
import { CallsContext } from "../../../../providers/Calls";
import UserCallWindow from "../UserCallWindow";
import { useCallsSettings } from "../../../../hooks";

const RemoteUserCallWindow: React.FC<{
  user: IAgoraRTCRemoteUser;
  minimized?: boolean;
  isCall?: boolean;
  duration?: string;
  containerClassName?: string;
  profileContainerClassName?: string;
  showAvatarOnly?: boolean;
  hide?: boolean;
  screenSharingId?: string;
  isFullscreen?: boolean;
  gridAvatarSize?: number;
}> = ({
  user,
  minimized,
  isCall,
  duration,
  containerClassName,
  profileContainerClassName,
  showAvatarOnly,
  hide,
  screenSharingId,
  isFullscreen,
  gridAvatarSize,
}) => {
  const { client, activeCall, playbackDevices } = useContext(CallsContext);
  const { getCallsSettings } = useCallsSettings();
  const [isAudioMuted, setIsAudioMuted] = useState<boolean>(true);
  const [isVideoMuted, setIsVideoMuted] = useState<boolean>(true);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const id = useMemo(() => (Math.random() * 1e16).toFixed(), []);
  const storedSettings = getCallsSettings();
  const debouncedIsSpeaking = useDebounce(isSpeaking, 1000);

  useEffect(() => {
    const userInfoUpdateCallback = (
      uid: string,
      msg:
        | "mute-audio"
        | "mute-video"
        | "enable-local-video"
        | "unmute-audio"
        | "unmute-video"
        | "disable-local-video"
    ) => {
      if (uid !== user.uid) {
        return;
      }
      switch (msg) {
        case "enable-local-video":
        case "unmute-video":
          setIsVideoMuted(false);
          break;
        case "disable-local-video":
        case "mute-video":
          activeCall?.userTracks[user.uid]?.video?.stop();
          setIsVideoMuted(true);
          break;
        case "unmute-audio":
          setIsAudioMuted(false);
          break;
        case "mute-audio":
          activeCall?.userTracks[user.uid]?.audio?.stop();
          setIsAudioMuted(true);
          break;
        default:
      }
    };

    client?.on("user-info-updated", userInfoUpdateCallback);
    return () => {
      client?.off("user-info-updated", userInfoUpdateCallback);
    };
  }, [activeCall?.userTracks, client, id, user]);

  useEffect(() => {
    // TODO: optimize
    const videoTrack = activeCall?.userTracks[user.uid]?.video;
    if (videoTrack && videoTrack._player?._videoElementStatus !== "playing") {
      videoTrack.play(id, { fit: "contain" });
      setIsVideoMuted(false);
    }
  }, [activeCall?.userTracks, id, minimized, user.uid]);

  useEffect(() => {
    // TODO: optimize
    const audioTrack = activeCall?.userTracks[user.uid]?.audio;
    const callsSettings = storedSettings;
    const isPlaybackPresent = playbackDevices?.find(
      (device) => device.deviceId === callsSettings?.playback_id
    );
    if (callsSettings && isPlaybackPresent) {
      audioTrack?.setPlaybackDevice(callsSettings.playback_id);
    }
    if (audioTrack && !audioTrack.isPlaying) {
      audioTrack.play();
      setIsAudioMuted(false);
    }
  }, [activeCall?.userTracks, id, playbackDevices, storedSettings, user.uid]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newVolumeLevel = user.audioTrack?.getVolumeLevel() || 0;
      const newTalkingState = newVolumeLevel > 0.45;
      if (isSpeaking !== newTalkingState) setIsSpeaking(newTalkingState);
    }, 250);

    return () => clearInterval(intervalId);
  }, [user, isSpeaking]);

  useEffect(
    () => () => {
      activeCall?.userTracks[user.uid]?.video?.stop();
      activeCall?.userTracks[user.uid]?.audio?.stop();
    },
    [activeCall?.userTracks, user.uid]
  );

  if (hide) {
    return null;
  }
  return (
    <UserCallWindow
      isSpeaking={debouncedIsSpeaking}
      userId={user.uid}
      isAudioMuted={isAudioMuted}
      isVideoMuted={isVideoMuted}
      playerId={id}
      isCall={isCall}
      duration={duration}
      containerClassName={containerClassName}
      profileContainerClassName={profileContainerClassName}
      showAvatarOnly={showAvatarOnly}
      minimized={minimized}
      screenSharingId={screenSharingId}
      isFullscreen={isFullscreen || false}
      gridAvatarSize={gridAvatarSize}
    />
  );
};

export default RemoteUserCallWindow;
