import {
  InteractiveContainer,
  InteractiveContainerProps,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { cx, useTranslations } from "@jugl-web/utils";
import {
  CSSProperties,
  FC,
  HTMLAttributes,
  ReactNode,
  forwardRef,
} from "react";
import { ReactComponent as CrossIcon } from "../../assets/cross.svg";
import { TABLE_DIMENSIONS } from "../../consts";
import { CellLeftMargin } from "../CellLeftMargin";

export const TaskTableLayout = () => {};

interface RowProps {
  children: ReactNode;
  height?: number;
  isHoverable?: boolean;
  isFirst?: boolean;
  className?: string;
  style?: CSSProperties;
  onClick?: InteractiveContainerProps["onClick"];
}

TaskTableLayout.Row = forwardRef<HTMLDivElement, RowProps>(
  (
    {
      children,
      height,
      isHoverable = true,
      isFirst = false,
      className,
      style,
      onClick,
    },
    ref
  ) => {
    const isInteractive = !!onClick;

    const baseProps = {
      ref,
      className: cx("flex", isHoverable && "group", className),
      style: {
        height,
        gap: TABLE_DIMENSIONS.TABLE_CELL_GAP,
        marginTop: !isFirst ? TABLE_DIMENSIONS.TABLE_CELL_GAP : undefined,
        ...style,
      },
      children,
    };

    if (isInteractive) {
      return <InteractiveContainer {...baseProps} onClick={onClick} />;
    }

    return <div {...baseProps} />;
  }
);

export interface CellProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  width?: number;
  isFocused?: boolean;
  isHoverable?: boolean;
  isDisabled?: boolean;
  hasMarginLine?: boolean;
  shouldGrow?: boolean;
  className?: string;
  onClick?: InteractiveContainerProps["onClick"];
}

TaskTableLayout.Cell = forwardRef<HTMLDivElement, CellProps>(
  (
    {
      children,
      width,
      isFocused = false,
      isHoverable = false,
      isDisabled = false,
      hasMarginLine = false,
      shouldGrow = false,
      className,
      style,
      onClick,
      ...props
    },
    ref
  ) => {
    const isInteractive = !!onClick;
    const canHover = isHoverable && !isDisabled;

    const commonProps = {
      ref,
      className: cx(
        "relative flex shrink-0 items-center bg-white px-4 transition-colors group-hover:bg-grey-100 group/cell outline outline-0 outline-grey-400 outline-offset-0",
        "focus-visible:outline-primary-200 focus-visible:outline-2 focus-visible:hover:outline-2",
        canHover && "hover:bg-grey-100 hover:outline-1",
        isFocused &&
          "bg-grey-100 outline-primary-200 outline-2 hover:outline-2",
        shouldGrow && "flex-grow",
        className
      ),
      style: { width, ...style },
      children: (
        <>
          {hasMarginLine && <CellLeftMargin />}
          {children}
        </>
      ),
      ...props,
    };

    if (isInteractive) {
      return (
        <InteractiveContainer
          {...commonProps}
          isDisabled={isDisabled}
          onClick={onClick}
        />
      );
    }

    return <div {...commonProps} />;
  }
);

TaskTableLayout.OutermostEmptyCell = (() => (
  <TaskTableLayout.Cell
    className="px-0"
    width={TABLE_DIMENSIONS.TABLE_OUTERMOST_COLUMN_WIDTH}
  />
)) as FC;

interface CellTextProps {
  children: ReactNode;
  isFaded?: boolean;
  isCrossedOut?: boolean;
  className?: string;
}

TaskTableLayout.CellText = (({
  children,
  isFaded = false,
  isCrossedOut = false,
  className,
}) => (
  <span
    className={cx(
      "truncate text-sm leading-3 tracking-[0.14px] transition-colors",
      isFaded ? "text-grey-700" : "text-dark-800",
      isCrossedOut && "line-through",
      className
    )}
  >
    {children}
  </span>
)) as FC<CellTextProps>;

interface CellLabelProps {
  children: ReactNode;
  className?: string;
}

TaskTableLayout.CellLabel = (({ children, className }) => (
  <span className={cx("text-dark-700 truncate text-xs", className)}>
    {children}
  </span>
)) as FC<CellLabelProps>;

interface CellContentSkeletonProps {
  type: "avatars";
}

TaskTableLayout.CellContentSkeleton = (({ type }) => {
  if (type === "avatars") {
    const commonProps =
      "h-[22px] w-[22px] rounded-full border border-solid border-white";

    return (
      <div className="flex animate-pulse items-center">
        <div className={cx("bg-grey-300 -mr-1", commonProps)} />
        <div className={cx("bg-dark-100 -mr-1", commonProps)} />
        <div className={cx("bg-grey-200", commonProps)} />
      </div>
    );
  }

  throw new Error(`Unsupported skeleton type: ${type}`);
}) as FC<CellContentSkeletonProps>;

interface AppearingAddValueCellTextProps {
  isStatic?: boolean;
  isHidden?: boolean;
  messageType?: "tap-to-add" | "tap-to-enter";
}

TaskTableLayout.AppearingAddValueCellText = (({
  isStatic,
  isHidden,
  messageType = "tap-to-add",
}) => {
  const { t } = useTranslations();

  const message = {
    "tap-to-add": t({
      id: "common.tap-to-add",
      defaultMessage: "Tap to add",
    }),
    "tap-to-enter": t({
      id: "common.tap-to-enter",
      defaultMessage: "Tap to enter",
    }),
  }[messageType];

  if (isHidden) {
    return null;
  }

  return (
    <TaskTableLayout.CellText
      className={cx(
        "text-dark-500 group-hover/cell:block",
        isStatic ? "block" : "hidden"
      )}
    >
      {message}
    </TaskTableLayout.CellText>
  );
}) as FC<AppearingAddValueCellTextProps>;

interface ClearCellValueButtonProps {
  isAppearing?: boolean;
  isDisabled?: boolean;
  isHidden?: boolean;
  className?: string;
  onClick: HTMLAttributes<HTMLButtonElement>["onClick"];
  onKeyDown?: HTMLAttributes<HTMLButtonElement>["onKeyDown"];
}

TaskTableLayout.ClearCellValueButton = (({
  isAppearing = false,
  isDisabled = false,
  isHidden,
  className,
  onClick,
  onKeyDown,
}) => {
  if (isHidden) {
    return null;
  }

  return (
    <PlainButton
      className={cx(
        "jugl__focusable-outline hover:bg-grey/5 flex h-[34px] w-[34px] shrink-0 items-center justify-center rounded text-[#828282] transition-colors disabled:cursor-not-allowed disabled:text-[#E0E0E0] disabled:hover:bg-transparent",
        isAppearing ? "hidden group-hover/cell:block" : "block",
        className
      )}
      disabled={isDisabled}
      onKeyDown={onKeyDown}
      onClick={onClick}
    >
      <CrossIcon className="transition-colors" />
    </PlainButton>
  );
}) as FC<ClearCellValueButtonProps>;
