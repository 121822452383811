import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import { Tooltip } from "@jugl-web/ui-components/web";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import React, { useMemo } from "react";
import { useUserListBox } from "@jugl-web/domain-resources/users/hooks/useUserListBox";
import { useTranslations } from "@jugl-web/utils";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useUserGeneralProfile } from "@jugl-web/domain-resources/users/hooks/useUserGeneralProfile";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import useNavigateToChat from "@web-src/features/chats/hooks/useNavigateToChat";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { UserRole } from "@jugl-web/rest-api";
import { ReactComponent as UserIcon } from "./assets/user-icon.svg";
import { ReactComponent as InfoIcon } from "./assets/info-icon.svg";

export const UserManagersPopover: React.FC<{ userId: string }> = ({
  userId,
}) => {
  const { navigateToPage } = useNavigation();
  const { me } = useMe();
  const { t } = useTranslations();
  const { entity } = useEntitySelectedProvider();
  const navigateToChat = useNavigateToChat();

  const { getListBoxProps, users } = useUserListBox({
    entityId: entity.id,
  });

  const { profile } = useUserGeneralProfile({
    entityId: entity.id,
    userId,
  });

  const userManagers = useMemo(
    () =>
      users
        .filter((user) => profile?.managers.includes(user.id))
        .map((user) => ({ id: user.id, value: user })),
    [users, profile?.managers]
  );

  return (
    <ResourcePickerPopover
      placement="bottom-end"
      title={
        <div className="flex items-center gap-3">
          <span>
            {t({
              id: "cpanel-page.direct-managers",
              defaultMessage: "Direct managers",
            })}
          </span>
          <Tooltip
            placement="bottom-end"
            className="z-tooltip p-4"
            delay="none"
            renderTrigger={({ ref, props }) => (
              <InfoIcon ref={ref} className="cursor-help" {...props} />
            )}
          >
            {t({
              id: "cpanel-page.direct-managers-description",
              defaultMessage:
                "Direct Managers can see Tasks, Reports and other work flow information 📊",
            })}
          </Tooltip>
        </div>
      }
      customButtonLabel={t({
        id: "people-page.manage",
        defaultMessage: "Manage",
      })}
      onSubmit={
        me?.role === UserRole.admin
          ? () => navigateToPage("workspaceMembers")
          : undefined
      }
      className="w-[315px]"
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          ref={triggerRef}
          as={InteractiveContainer}
          className="flex h-12 w-12 items-center justify-center rounded-lg hover:bg-[#EEF2F5]"
        >
          <UserIcon />
        </Trigger>
      )}
      onSelect={({ item, onClose }) => {
        navigateToChat(item.id);
        onClose();
      }}
      {...getListBoxProps({
        items: userManagers,
        maxVisibleItems: 5,
        hasSearch: false,
      })}
    />
  );
};
