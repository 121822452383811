import { TaskDefaultStatus } from "@jugl-web/rest-api/tasks";
import { useCallback, useMemo } from "react";
import { useTaskFields } from "./useTaskFields";
import {
  useDefaultTaskStatuses,
  TaskStatusDetails,
} from "./useDefaultTaskStatuses";

export interface UseTaskStatusesOptions {
  entityId: string | undefined;
  skipRemote?: boolean;
}

export const useTaskStatuses = ({
  entityId,
  skipRemote,
}: UseTaskStatusesOptions) => {
  const { customStatuses, getCustomStatusById } = useTaskFields({
    entityId,
    skipRemote,
  });
  const { defaultStatusDetailsById } = useDefaultTaskStatuses();

  const getStatusDetailsById = useCallback(
    (statusId: TaskDefaultStatus | string): TaskStatusDetails => {
      if (statusId in defaultStatusDetailsById) {
        return defaultStatusDetailsById[
          statusId as keyof typeof defaultStatusDetailsById
        ];
      }

      const customStatus = getCustomStatusById(statusId);

      if (!customStatus) {
        // Use the default "not-started" status as a fallback if the custom status is not found
        return defaultStatusDetailsById["not-started"];
      }

      return {
        id: customStatus.id,
        label: customStatus.text,
      };
    },
    [defaultStatusDetailsById, getCustomStatusById]
  );

  const allStatuses = useMemo<TaskStatusDetails[]>(() => {
    const orderedStatusIds = [
      TaskDefaultStatus.notStarted,
      TaskDefaultStatus.inProgress,
      ...customStatuses.map((status) => status.id),
      TaskDefaultStatus.completed,
    ];

    return orderedStatusIds.map((statusId) => getStatusDetailsById(statusId));
  }, [customStatuses, getStatusDetailsById]);

  return {
    allStatuses,
    getStatusDetailsById,
  };
};
