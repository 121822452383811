import {
  Context,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Subject } from "rxjs";
import { HookOutOfContextError } from "../errors/HookOutOfContext";

type ScreenToParametersMap = Record<string, object>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type NoParameters = {};

export interface TransitionRequest {
  name?: string;
  resolve: () => void;
}

export type Screen<TScreenToParametersMap extends ScreenToParametersMap> = {
  [TScreenName in keyof TScreenToParametersMap]: {
    name: TScreenName;
  } & TScreenToParametersMap[TScreenName];
}[keyof TScreenToParametersMap];

interface TransitionToFnOptions {
  force: boolean;
}

export type TransitionToFn<
  TScreenToParametersMap extends ScreenToParametersMap
> = (
  screen: Screen<TScreenToParametersMap>,
  options?: TransitionToFnOptions
) => void;

type RenderContentMap<
  TScreenToParametersMap extends ScreenToParametersMap,
  TOutput
> = {
  [TScreenName in keyof TScreenToParametersMap]:
    | TOutput
    | ((parameters: TScreenToParametersMap[TScreenName]) => TOutput);
};

type RenderContentFn<TScreenToParametersMap extends ScreenToParametersMap> = <
  TOutput
>(
  map: RenderContentMap<TScreenToParametersMap, TOutput>
) => TOutput;

interface ScreenTransitionManagerContextValue<
  TScreenToParametersMap extends ScreenToParametersMap
> {
  screen: Screen<TScreenToParametersMap>;
  transitionTo: TransitionToFn<TScreenToParametersMap>;
  renderContent: RenderContentFn<TScreenToParametersMap>;
  transitionRequest$: Subject<TransitionRequest>;
}

const ScreenTransitionManagerContext =
  createContext<ScreenTransitionManagerContextValue<ScreenToParametersMap> | null>(
    null
  );

interface ScreenTransitionManagerProviderProps<
  TScreenToParametersMap extends ScreenToParametersMap
> {
  children: ReactNode;
  initialScreen: Screen<TScreenToParametersMap>;
}

export const ScreenTransitionManagerProvider = <
  TScreenToParametersMap extends ScreenToParametersMap
>({
  children,
  initialScreen,
}: ScreenTransitionManagerProviderProps<TScreenToParametersMap>) => {
  const [currentScreen, setCurrentScreen] =
    useState<Screen<TScreenToParametersMap>>(initialScreen);

  const transitionRequest$ = useMemo(
    () => new Subject<TransitionRequest>(),
    []
  );

  const transitionTo: TransitionToFn<TScreenToParametersMap> = useCallback(
    (nextScreen, transitionOptions) => {
      const transitionResolver = () => setCurrentScreen(nextScreen);

      if (transitionRequest$.observed && !transitionOptions?.force) {
        transitionRequest$.next({ resolve: transitionResolver });
      } else {
        transitionResolver();
      }
    },
    [transitionRequest$]
  );

  const renderContent = useCallback<RenderContentFn<TScreenToParametersMap>>(
    (map) => {
      const { name, ...parameters } = currentScreen;
      const renderer = map[name];

      return typeof renderer === "function" ? renderer(parameters) : renderer;
    },
    [currentScreen]
  );

  const contextValue = useMemo<
    ScreenTransitionManagerContextValue<TScreenToParametersMap>
  >(
    () => ({
      screen: currentScreen,
      transitionTo,
      renderContent,
      transitionRequest$,
    }),
    [currentScreen, transitionTo, renderContent, transitionRequest$]
  );

  // Assertion to unknown is needed because of weird type error
  // which is probably some mismatch between web and mobile TS configs
  const TypedContext =
    ScreenTransitionManagerContext as unknown as Context<ScreenTransitionManagerContextValue<TScreenToParametersMap> | null>;

  return (
    <TypedContext.Provider value={contextValue}>
      {children}
    </TypedContext.Provider>
  );
};

export const useScreenTransitionManager = <
  TScreenToParametersMap extends ScreenToParametersMap
>() => {
  const context = useContext(ScreenTransitionManagerContext);

  if (!context) {
    throw new HookOutOfContextError(
      "useScreenTransitionManager",
      "ScreenTransitionManagerContext"
    );
  }

  return context as unknown as ScreenTransitionManagerContextValue<TScreenToParametersMap>;
};
