import { UserFile } from "@jugl-web/rest-api";
import { Alert, Input } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import React, { useEffect, useState } from "react";

export const FileRenameDialog: React.FC<{
  isOpen: boolean;
  isLoading: boolean;
  file?: UserFile;
  onRequestClose: () => void;
  onRename: (name: string) => void;
}> = ({ isOpen, file, isLoading, onRequestClose, onRename }) => {
  const [value, setValue] = useState("");
  const { t } = useTranslations();

  useEffect(() => {
    setValue(file?.name || "");
  }, [file]);

  return (
    <Alert
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={t({
        id: "cpanel-page.rename-file",
        defaultMessage: "Rename file",
      })}
      content={
        <Input
          className="w-full bg-transparent"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      }
      buttons={[
        {
          text: t({
            id: "common.cancel",
            defaultMessage: "Cancel",
          }),
          onClick: onRequestClose,
          role: "close",
          color: "grey",
        },
        {
          text: t({
            id: "common.save",
            defaultMessage: "Save",
          }),
          isDisabled: value === "" || isLoading,
          onClick: () => onRename(value),
        },
      ]}
    />
  );
};
