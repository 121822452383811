import React, { forwardRef, useCallback } from "react";
import { useToast, useTranslations } from "@jugl-web/utils";

const MAX_FILE_SIZE = 78643200;

const ChatInputFileSelect = forwardRef<
  HTMLInputElement,
  { onChange: (file?: File) => void; attachmentAccept: string }
>(({ onChange, attachmentAccept }, ref) => {
  const { t } = useTranslations();
  const { toast } = useToast({ variant: "web" });
  const handleFileInputChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        const fileSize = e.target?.files?.[0].size;
        if (fileSize && fileSize > MAX_FILE_SIZE) {
          toast(
            t(
              {
                id: "feedback.too-large-file-megabytes",
                defaultMessage:
                  "The file is too large. The maximum size is {fileSize}MB",
              },
              {
                fileSize: "75",
              }
            ),
            {
              variant: "error",
            }
          );
          return;
        }
        onChange(e.target.files?.[0]);
      },
      [onChange, toast, t]
    );
  return (
    <input
      type="file"
      multiple={false}
      accept={attachmentAccept}
      style={{
        position: "absolute",
        left: -1000000,
        top: -1000000,
        opacity: 0,
        visibility: "hidden",
        overflow: "hidden",
      }}
      onChange={handleFileInputChange}
      ref={ref}
    />
  );
});

export default ChatInputFileSelect;
