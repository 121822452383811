import { Button } from "@jugl-web/ui-components/cross-platform";
import { FC } from "react";
import Lottie from "react-lottie";
import alertJson from "./assets/alert.json";

export type FullPageErrorProps = {
  title?: string;
  message?: string;
  onRetry?: () => void;
};

export const FullPageError: FC<FullPageErrorProps> = ({
  title,
  message,
  onRetry,
}) => (
  <div className="fixed left-0 right-0 top-0 bottom-0 z-[10000] flex flex-col items-center justify-center gap-4 bg-white p-10 text-center">
    <Lottie options={{ animationData: alertJson }} width={100} height={100} />
    <div className="text-2xl font-medium">
      {title || "Opps, something went wrong"}
    </div>
    <div className="text-gray-600">
      {message || "Please try again later, or contact support"}
    </div>
    {onRetry && (
      <Button className="mt-4" onClick={onRetry}>
        Retry
      </Button>
    )}
  </div>
);
