import React, { useEffect } from "react";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useParams } from "react-router-dom";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { environment } from "@web-src/environments/environment";
import { useSnackbar } from "notistack";
import { useTranslations } from "@jugl-web/utils";

const AdminReportsPage: React.FC = () => {
  const routeParams = useParams();
  const { t } = useTranslations();
  const { me } = useMe();
  // TODO: reports temporairly enabled for everyone
  const isAdmin = !false || me?.role === "admin";
  const dashboardId = routeParams.reportType
    ? environment.supersetDashboardIds[routeParams.reportType]
    : undefined;
  const { supersetApi } = useRestApiProvider();

  const [getToken] = supersetApi.useLazyGetTokenQuery();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!dashboardId) {
      return;
    }
    getToken({
      domain: environment.supersetDomain,
      dashboardId,
      entityId: routeParams.entityId || "",
    })
      .then((res) => {
        const token = async () =>
          new Promise<string>((resolve) => {
            setTimeout(resolve, 100, res?.data?.token);
          });
        const $container = document.getElementById("raport-container");
        if (!$container) {
          return;
        }
        embedDashboard({
          id: dashboardId,
          supersetDomain: environment.supersetDomain,
          mountPoint: $container,
          fetchGuestToken: () => token(),
          dashboardUiConfig: {
            hideTab: true,
            hideTitle: true,
            hideChartControls: false,
            filters: {
              entity_name: false,
              expanded: false,
            },
          },
        });
      })
      .catch(() => {
        enqueueSnackbar(
          t({
            id: "admin-reports-page.couldnt-load-dashboard",
            defaultMessage: "Couldn't load the dashboard",
          }),
          {
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            variant: "error",
            autoHideDuration: 2000,
          }
        );
      });
  }, [dashboardId, enqueueSnackbar, getToken, routeParams.entityId, t]);
  if (!isAdmin) {
    return (
      <div className="w-100 flex h-[100vh] place-content-center items-center uppercase">
        {t({
          id: "admin-reports-page.no-permission",
          defaultMessage: "No permission",
        })}
      </div>
    );
  }
  return (
    <div>
      <div
        id="raport-container"
        className="h-100vh [&>iframe]:min-h-[100vh] [&>iframe]:w-[calc(100%)] [&>iframe]:border-none"
      />
    </div>
  );
};

export default AdminReportsPage;
