import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Portal,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { assert, HookOutOfContextError } from "@jugl-web/utils";

import {
  createContext,
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { MediaPanel } from "./components/MediaPanel";
import { TopPanel } from "./components/TopPanel";
import { FilePreviewConfig } from "./types";
import { inferTypeFromMimeType } from "./utils";

export interface FilePreviewContextValue {
  isOpen: boolean;
  previewFile: (config: FilePreviewConfig) => void;
  closePreview: () => void;
}

const FilePreviewContext = createContext<FilePreviewContextValue | null>(null);

type MediaState = "ready" | "loading" | "error";

interface FilePreviewProviderProps {
  children: JSX.Element;
}

export const FilePreviewProvider: FC<FilePreviewProviderProps> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [mediaState, setMediaState] = useState<MediaState>("loading");
  const [currentConfig, setCurrentConfig] = useState<FilePreviewConfig | null>(
    null
  );

  const getPreviewType = useCallback(() => {
    assert(!!currentConfig, "No file to preview");
    return inferTypeFromMimeType(currentConfig.mimeType);
  }, [currentConfig]);

  const previewFile = useCallback((config: FilePreviewConfig) => {
    setIsOpen(true);
    setCurrentConfig(config);
  }, []);

  const contextValue = useMemo<FilePreviewContextValue>(
    () => ({ isOpen, previewFile, closePreview: () => setIsOpen(false) }),
    [isOpen, previewFile]
  );

  useEffect(() => {
    if (currentConfig) {
      setMediaState(getPreviewType() !== "other" ? "loading" : "ready");
    }
  }, [currentConfig, getPreviewType]);

  useEffect(() => {
    if (isOpen) {
      document?.body?.classList.add("overflow-hidden");
    } else {
      document?.body?.classList.remove("overflow-hidden");
    }
  }, [isOpen]);

  return (
    <FilePreviewContext.Provider value={contextValue}>
      {children}
      <Portal>
        <Transition show={isOpen} as={Fragment}>
          <Dialog
            unmount
            as="div"
            className="jugl__border-box-component"
            onClose={() => setIsOpen(false)}
          >
            <TransitionChild
              as={Fragment}
              enter="transition-opacity duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <DialogBackdrop className="fixed inset-0 z-50 bg-[rgba(14,14,14,0.66)] backdrop-blur-[1px]" />
            </TransitionChild>
            <DialogPanel className="fixed top-0 left-0 bottom-0 right-0 z-[1300] flex h-screen w-screen flex-col">
              <TransitionChild
                as={Fragment}
                enter="transition duration-200"
                enterFrom="opacity-0 -translate-y-full"
                enterTo="opacity-100 translate-y-0"
                leave="transition duration-200"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 -translate-y-full"
              >
                {currentConfig && (
                  <TopPanel
                    fileName={currentConfig.name}
                    onDownload={currentConfig.onDownload}
                    onClose={() => setIsOpen(false)}
                    fileAuthorName={currentConfig.username}
                  />
                )}
              </TransitionChild>
              <TransitionChild
                as={Fragment}
                enter="transition duration-200"
                enterFrom="opacity-0 scale-90"
                enterTo="opacity-100 scale-100"
                leave="transition duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-90"
              >
                {currentConfig && (
                  <MediaPanel
                    config={currentConfig}
                    previewType={getPreviewType()}
                    isLoading={mediaState === "loading"}
                    hasError={mediaState === "error"}
                    onLoad={() => setMediaState("ready")}
                    onError={() => setMediaState("error")}
                    onDownload={currentConfig.onDownload}
                    onClose={() => setIsOpen(false)}
                  />
                )}
              </TransitionChild>
            </DialogPanel>
          </Dialog>
        </Transition>
      </Portal>
    </FilePreviewContext.Provider>
  );
};

export const useFilePreview = () => {
  const context = useContext(FilePreviewContext);

  if (!context) {
    throw new HookOutOfContextError("useFilePreview", "FilePreviewContext");
  }

  return context;
};
