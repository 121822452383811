import { Text } from "@jugl-web/ui-components/cross-platform/Text";
import {
  OTPVerificationForm,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { LoginMethodType } from "../../../../types";

export const VerificationStep: React.FC<{
  value: string;
  type: LoginMethodType;
  onGoBack: () => void;
  onSubmit: (otp: string) => void;
  onResend: () => void;
}> = ({ value, type, onSubmit, onGoBack, onResend }) => {
  const { t } = useTranslations();
  return (
    <div className="flex h-full w-full items-center justify-center">
      <div>
        <OTPVerificationForm
          title={
            type === "email"
              ? t({
                  id: "settings-page.enter-otp-code-mail-title",
                  defaultMessage: "Enter Code Sent to Your Email",
                })
              : t({
                  id: "settings-page.enter-otp-code-mobile-title",
                  defaultMessage: "Enter Code Sent to Your Number",
                })
          }
          subTitle={
            <>
              <div className="text-[#5e686b]">
                {type === "email"
                  ? t(
                      {
                        id: "settings-page.enter-otp-code-mail.description",
                        defaultMessage: "We sent it to the Email <b>{mail}</b>",
                      },
                      {
                        b: (text: (string | JSX.Element)[]) => (
                          <Text variant="body2" className="text-primary">
                            {text}
                          </Text>
                        ),
                        mail: value,
                      }
                    )
                  : t(
                      {
                        id: "settings-page.enter-otp-code-numberl.description",
                        defaultMessage:
                          "We sent it to the Number <b>{number}</b>",
                      },
                      {
                        b: (text: (string | JSX.Element)[]) => (
                          <Text variant="body2" className="text-primary">
                            {text}
                          </Text>
                        ),
                        number: value,
                      }
                    )}
              </div>
              <PlainButton
                className="text-grey-900 mt-1.5 text-sm underline"
                onClick={onGoBack}
              >
                {type === "email"
                  ? t({
                      id: "settings-page.change-email",
                      defaultMessage: "Change Email",
                    })
                  : t({
                      id: "settings-page.change-number",
                      defaultMessage: "Change Number",
                    })}
              </PlainButton>
            </>
          }
          onSubmit={onSubmit}
          onResend={onResend}
        />
      </div>
    </div>
  );
};
