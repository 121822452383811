import { FC } from "react";
import Lottie from "react-lottie";
import loaderAnimationData from "./assets/loader.json";

type AnimationSize = "xs" | "sm" | "md" | "lg" | "xl" | "2xl";

const sizeToPixels: Record<AnimationSize, number> = {
  xs: 16,
  sm: 24,
  md: 32,
  lg: 48,
  xl: 56,
  "2xl": 64,
};

export type LoadingAnimationProps = {
  size?: AnimationSize | { custom: number };
};

export const LoadingAnimation: FC<LoadingAnimationProps> = ({
  size = "2xl",
}) => (
  <Lottie
    options={{ animationData: loaderAnimationData }}
    width={typeof size === "string" ? sizeToPixels[size] : size.custom}
    height={typeof size === "string" ? sizeToPixels[size] : size.custom}
  />
);
