import { createEntityAdapter } from "@reduxjs/toolkit";
import { TasksApiTag } from "./tags";
import {
  DetailedTask,
  ExpectedTaskCustomDropdownFieldValue,
  InternalTaskCustomField,
  InternalTaskFilterSet,
  InternalTaskFilters,
  PreviewTask,
  TaskCustomField,
  TaskFilterSet,
  TaskFilters,
} from "./types";

export const getTaskIdTag = (taskId: string) => ({
  type: TasksApiTag.task,
  id: `ID-${taskId}`,
});

export const getEntityTasksIdTag = (entityId: string) => ({
  type: TasksApiTag.task,
  id: `ENTITY-${entityId}`,
});

export const isExpectedTaskCustomDropdownFieldValue = (
  value: null | Record<string, unknown>
): value is ExpectedTaskCustomDropdownFieldValue =>
  value !== null &&
  typeof value.id === "string" &&
  typeof value.value === "string" &&
  typeof value.order === "number";

export const sortCollectionByOrder = <TItem extends { order: number }>(
  items: TItem[]
) => [...items].sort((itemA, itemB) => itemA.order - itemB.order);

export const adaptTaskCustomFieldsToInternalCustomFields = (
  customFields: TaskCustomField[]
) =>
  customFields.map<InternalTaskCustomField>((field) => {
    const hasDropdownType = field.field_type === "dropdown";

    const internalField: InternalTaskCustomField = {
      id: field.id,
      name: field.field,
      type: field.field_type,
      order: field.order,
      isShownInCard: !!field.show_in_card,
      values: hasDropdownType
        ? (field.values || []).filter(isExpectedTaskCustomDropdownFieldValue)
        : undefined,
    };

    return internalField;
  });

export const adaptDetailedTaskToPreviewTask = (
  detailedTask: DetailedTask
): PreviewTask => ({
  ...detailedTask,
  chklist_completed: detailedTask.checklist.filter((item) => item.is_completed)
    .length,
  chklist_total: detailedTask.checklist.length,
});

export const previewTasksAdapter = createEntityAdapter<PreviewTask>({
  selectId: (task) => task.id,
});

// We prefix task description with a hash because WAF doesn't allow strings starting with HTML tags
const TASK_DESCRIPTION_HASH = "_gJ15r";

export const withPrefixedTaskDescription = <
  TPayload extends Partial<DetailedTask>
>(
  payload: TPayload
): TPayload => {
  if (!payload.desc) {
    return payload;
  }

  return { ...payload, desc: TASK_DESCRIPTION_HASH.concat(payload.desc) };
};

export const stripTaskDescriptionPrefix = (description: string) => {
  if (!description.startsWith(TASK_DESCRIPTION_HASH)) {
    return description;
  }

  return description.slice(TASK_DESCRIPTION_HASH.length);
};

export const adaptInternalTaskFiltersToTaskFilters = (
  filters: InternalTaskFilters
): TaskFilters => ({
  assignee: filters.assignees ? [...filters.assignees] : null,
  customer: filters.customers ? [...filters.customers] : null,
  label: filters.labels ? [...filters.labels] : null,
  overdue: filters.isOverdue,
  recurring: filters.isRecurring,
  priority: filters.priorities ? [...filters.priorities] : null,
  status: filters.statuses ? [...filters.statuses] : null,
  custom_fields: filters.customFields
    ? Object.fromEntries(
        Object.entries(filters.customFields).map(([key, value]) => [
          key,
          [...value],
        ])
      )
    : null,
});

const adaptTaskFiltersToInternalTaskFilters = (
  filters: TaskFilters
): InternalTaskFilters => ({
  assignees: filters.assignee || [],
  customers: filters.customer || [],
  labels: filters.label || [],
  isOverdue: filters.overdue,
  isRecurring: filters.recurring,
  priorities: filters.priority || [],
  statuses: filters.status || [],
  customFields: filters.custom_fields || {},
});

export const adaptTaskFilterSetToInternalTaskFilterSet = (
  filterSet: TaskFilterSet
): InternalTaskFilterSet => ({
  id: filterSet.id,
  name: filterSet.name,
  filters: adaptTaskFiltersToInternalTaskFilters(filterSet),
  updatedAt: filterSet.updated_at,
});

export const adaptInternalTaskFilterSetToTaskFilterSet = (
  filterSet: InternalTaskFilterSet
): TaskFilterSet => ({
  id: filterSet.id,
  name: filterSet.name,
  updated_at: filterSet.updatedAt,
  ...adaptInternalTaskFiltersToTaskFilters(filterSet.filters),
});

// TODO: Should be removed after backend migrates/removes tasks without names
export const filterTasksWithoutName = <TTask extends Pick<PreviewTask, "name">>(
  tasks: TTask[]
) => tasks.filter((task) => task.name !== null);
