import { TaskAttachment } from "@jugl-web/rest-api/tasks";
import {
  Button,
  Dialog,
  PlainButton,
  TextField,
  Text,
} from "@jugl-web/ui-components/cross-platform";
import { MobileDrawer } from "@jugl-web/ui-components/mobile";
import { useAppVariant, useTranslations } from "@jugl-web/utils";
import { FC, useEffect, useRef, useState, useMemo } from "react";
import { ReactComponent as CloseDialogIcon } from "../../assets/close-dialog.svg";

interface RenameTaskAttachmentDialogProps {
  isOpen: boolean;
  attachment: TaskAttachment;
  onSubmit: (name: string) => void;
  onClose: () => void;
}

export const RenameTaskAttachmentDialog: FC<
  RenameTaskAttachmentDialogProps
> = ({ isOpen, attachment, onSubmit, onClose }) => {
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { isMobile } = useAppVariant();
  const { t } = useTranslations();

  const hasInputValue = inputValue.trim().length > 0;

  const fileExtension = useMemo(() => {
    const fileNameParts = attachment.name.split(".");
    const hasExtension = fileNameParts.length > 1;

    return hasExtension ? (fileNameParts.at(-1) as string) : "";
  }, [attachment.name]);

  const handleSubmit = () => {
    const trimmedInputValue = inputValue.trim();

    let fileName = trimmedInputValue;

    if (fileExtension) {
      fileName += `.${fileExtension}`;
    }

    const hasChanged = fileName !== attachment.name;

    if (hasChanged) {
      onSubmit(fileName);
    }

    onClose();
  };

  const title = t({ id: "common.rename-file", defaultMessage: "Rename file" });

  const $actionButtons = (
    <>
      <Button
        variant="contained"
        color="grey"
        fullWidth
        className="h-10"
        onClick={onClose}
      >
        {t({ id: "common.cancel", defaultMessage: "Cancel" })}
      </Button>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        isDisabled={!hasInputValue}
        className="h-10"
        onClick={handleSubmit}
      >
        {t({ id: "common.save", defaultMessage: "Save" })}
      </Button>
    </>
  );

  const $textField = (
    <TextField
      ref={inputRef}
      type="text"
      isFullWidth
      autoFocus
      placeholder={t({
        id: "form-controls.attachment-name.placeholder",
        defaultMessage: "Enter file name here",
      })}
      inputClassName="text-dark pr-11 text-sm font-semibold tracking-[0.14px]"
      value={inputValue}
      onChange={(event) => setInputValue(event.target.value)}
    />
  );

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    if (fileExtension) {
      const fileNameParts = attachment.name.split(".");
      const fileNameWithoutExtension = fileNameParts.slice(0, -1).join(".");
      setInputValue(fileNameWithoutExtension);
    } else {
      setInputValue(attachment.name);
    }
  }, [isOpen, attachment, fileExtension]);

  if (isMobile) {
    return (
      <MobileDrawer
        isOpen={isOpen}
        header={{ title }}
        size="auto"
        initialFocus={inputRef}
        onClose={onClose}
      >
        <MobileDrawer.Content className="mt-0 pt-1 pb-12">
          <div className="relative">
            {$textField}
            {fileExtension.length > 0 && (
              <Text
                variant="body3"
                className="text-dark-700 absolute right-1 top-1/2 max-w-[40px] -translate-y-1/2 transform overflow-hidden truncate"
                title={fileExtension}
              >
                {fileExtension}
              </Text>
            )}
          </div>
        </MobileDrawer.Content>
        <MobileDrawer.Actions className="flex-row pb-6">
          {$actionButtons}
        </MobileDrawer.Actions>
      </MobileDrawer>
    );
  }

  return (
    <Dialog
      isOpen={isOpen}
      className="w-[500px]"
      initialFocus={inputRef}
      onClose={onClose}
    >
      <div
        className="flex h-[60px] items-center justify-between px-8"
        style={{ boxShadow: "2px -6px 24px 0px rgba(18, 22, 34, 0.16)" }}
      >
        <span className="text-dark text-base font-semibold">{title}</span>
        <PlainButton onClick={onClose}>
          <CloseDialogIcon />
        </PlainButton>
      </div>
      <div className="relative px-8 py-10">
        {$textField}
        {fileExtension.length > 0 && (
          <Text
            variant="body3"
            className="text-dark-700 absolute right-8 top-1/2 max-w-[40px] -translate-y-1/2 transform overflow-hidden truncate text-left"
            title={fileExtension}
          >
            {fileExtension}
          </Text>
        )}
      </div>
      <div
        className="flex h-[88px] items-center gap-3.5 px-6"
        style={{ boxShadow: "0px 6px 24px 0px rgba(18, 22, 34, 0.12)" }}
      >
        {$actionButtons}
      </div>
    </Dialog>
  );
};
