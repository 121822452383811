import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { HookOutOfContextError } from "@jugl-web/utils";
import { SubscriptionPlanModel } from "@jugl-web/rest-api/entities/models/SubscriptionPlan/SubscriptionPlanModel";
import { useEntitySubscriptionProvider } from "@web-src/modules/entities/providers/EntitySubscriptionProvider";
import { PromoCodeInfo } from "@jugl-web/rest-api/entities/models/common-types/PromoCodeInfo";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import {
  DEFAULT_APP_SUMO_PLAN_NAME,
  DEFAULT_APP_SUMO_SEATS_COUNT,
  useRestApiProvider,
} from "@jugl-web/rest-api";
import { SubscriptionIntervalType } from "@jugl-web/rest-api/entities/models/common-types/SubscriptionIntervalType";
import { SeatsUpdateAlert } from "../SeatsUpdateAlert";
import { usePaymentStrings } from "../../hooks/usePaymentStrings";
import { ManageSeatsCTA } from "./components/ManageSeatsCTA";
import { BasicPlanLimitAlert } from "./components/BasicPlanLimitAlert";

type SelectSubscriptionFormContextValue = {
  additionalSeats: number;
  updateAdditionalSeats: () => void;

  selectedPlan: SubscriptionPlanModel | undefined;
  selectPlan: (plan: SubscriptionPlanModel) => void;

  selectedSubscriptionInterval: SubscriptionIntervalType;
  selectSubscriptionInterval: (interval: SubscriptionIntervalType) => void;

  confirmPurchase: () => void;

  appliedPromoCodes: PromoCodeInfo[];
  addPromoCode: (promoCode: PromoCodeInfo) => void;
  removePromoCode: (idx: number) => void;

  isAppSumoMode: boolean;

  plans: SubscriptionPlanModel[] | undefined;
  plansIsLoading: boolean;
  plansIsError: boolean;
  plansRefetch: () => void;
};

const SelectSubscriptionFormContext =
  createContext<SelectSubscriptionFormContextValue | null>(null);

export const SelectSubscriptionFormProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { entityId, subscriptionInfo } = useEntitySelectedProvider();
  const { entitiesApi } = useRestApiProvider();
  const {
    data: plans,
    isLoading: plansIsLoading,
    isError: plansIsError,
    refetch: plansRefetch,
  } = entitiesApi.useGetSubscriptionPlansQuery({ entityId });

  const { getAdditionalUserPriceNote } = usePaymentStrings();
  const [additionalSeats, setAdditionalSeats] = useState<number>(0);

  const { changeSubscription, applyAppSumoSubscription } =
    useEntitySubscriptionProvider();

  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlanModel>();
  const [selectedSubscriptionInterval, setSelectedSubscriptionInterval] =
    useState<SubscriptionIntervalType>("month");
  const [isAdditionalSeatsAlertOpen, setIsAdditionalSeatsAlertOpen] =
    useState<boolean>(false);

  const updateAdditionalSeats = useCallback(() => {
    setIsAdditionalSeatsAlertOpen(true);
  }, []);

  const [isManageSeatsCTAOpen, setIsManageSeatsCTAOpen] = useState(false);
  const [userLimitAlertState, setUserLimitAlertState] = useState<{
    isOpen: boolean;
    planName?: string;
    seatsLimit?: number;
  }>({ isOpen: false });
  const closeUserLimitAlert = useCallback(() => {
    setUserLimitAlertState({ isOpen: false });
  }, []);

  const [appliedPromoCodes, setAppliedPromoCodes] = useState<PromoCodeInfo[]>(
    []
  );
  const addPromoCode = useCallback(
    (promoCode: PromoCodeInfo) => {
      setAppliedPromoCodes((prev) => {
        if (prev.length === 0 && promoCode.type === "app_sumo") {
          setSelectedSubscriptionInterval("month");
          setAdditionalSeats(0);
          setSelectedPlan(
            plans?.find(
              (plan) =>
                plan.planType === "basic" && plan.intervalType === "month"
            )
          );
        }
        if (
          (prev.length > 0 && promoCode.type !== "app_sumo") ||
          !!prev.find((item) => item.type !== "app_sumo")
        ) {
          throw new Error("Standard promo codes do not stack");
        }
        return [...prev, promoCode];
      });
    },
    [plans]
  );

  const removePromoCode = useCallback((idx: number) => {
    setAppliedPromoCodes((prev) => prev.filter((_, i) => i !== idx));
  }, []);

  const confirmPurchase: SelectSubscriptionFormContextValue["confirmPurchase"] =
    useCallback(() => {
      if (appliedPromoCodes?.[0]?.type === "app_sumo") {
        const totalSeatsToPurchase =
          appliedPromoCodes.length * DEFAULT_APP_SUMO_SEATS_COUNT;
        if (totalSeatsToPurchase < subscriptionInfo.seatsUtilized) {
          setUserLimitAlertState({
            isOpen: true,
            planName: DEFAULT_APP_SUMO_PLAN_NAME,
            seatsLimit: totalSeatsToPurchase,
          });
          return;
        }
        applyAppSumoSubscription(
          appliedPromoCodes[0].plan_id,
          appliedPromoCodes.map((code) => code.code)
        );
        return;
      }
      if (!selectedPlan || !subscriptionInfo) {
        return;
      }
      const totalSeats = selectedPlan.seatsCount + additionalSeats;
      if (subscriptionInfo.seatsUtilized > totalSeats) {
        if (selectedPlan.planType === "basic") {
          setUserLimitAlertState({
            isOpen: true,
            planName: selectedPlan.name,
            seatsLimit: selectedPlan.seatsCount,
          });
        } else {
          setIsManageSeatsCTAOpen(true);
        }
        return;
      }
      // NOTE: we're sure that normal code can be applied only once by error handling above
      const appliedPromoCode = appliedPromoCodes?.[0];
      changeSubscription(selectedPlan, additionalSeats, appliedPromoCode?.id);
    }, [
      additionalSeats,
      appliedPromoCodes,
      applyAppSumoSubscription,
      changeSubscription,
      selectedPlan,
      subscriptionInfo,
    ]);

  const selectPlan = useCallback(
    (plan: SubscriptionPlanModel) => {
      if (plan.id === selectedPlan?.id) {
        return;
      }
      if (
        plan.planType === "basic" &&
        subscriptionInfo.seatsUtilized > plan.seatsCount
      ) {
        setUserLimitAlertState({
          isOpen: true,
          planName: plan.name,
          seatsLimit: plan.seatsCount,
        });
        return;
      }
      setSelectedPlan(plan);
      setAdditionalSeats(
        subscriptionInfo.seatsUtilized - plan.seatsCount > 0
          ? subscriptionInfo.seatsUtilized - plan.seatsCount
          : 0
      );
    },
    [selectedPlan?.id, subscriptionInfo.seatsUtilized]
  );

  const selectSubscriptionInterval = useCallback(
    (interval: SubscriptionIntervalType) =>
      setSelectedSubscriptionInterval(interval),
    []
  );

  const value: SelectSubscriptionFormContextValue = useMemo(
    () => ({
      additionalSeats,
      updateAdditionalSeats,
      selectedPlan,
      setSelectedPlan,
      confirmPurchase,
      selectPlan,
      selectedSubscriptionInterval,
      selectSubscriptionInterval,
      appliedPromoCodes,
      addPromoCode,
      removePromoCode,
      plans,
      plansIsLoading,
      plansIsError,
      plansRefetch,
      isAppSumoMode: appliedPromoCodes?.[0]?.type === "app_sumo",
    }),
    [
      selectSubscriptionInterval,
      selectedSubscriptionInterval,
      additionalSeats,
      confirmPurchase,
      selectedPlan,
      updateAdditionalSeats,
      selectPlan,
      appliedPromoCodes,
      addPromoCode,
      removePromoCode,
      plans,
      plansIsLoading,
      plansIsError,
      plansRefetch,
    ]
  );

  return (
    <SelectSubscriptionFormContext.Provider value={value}>
      <ManageSeatsCTA
        isOpen={isManageSeatsCTAOpen}
        onRequestClose={() => setIsManageSeatsCTAOpen(false)}
        utilizedSeats={subscriptionInfo.seatsUtilized}
        planSeats={selectedPlan?.seatsCount || 0}
        additionalSeats={additionalSeats}
      />
      <BasicPlanLimitAlert
        isOpen={userLimitAlertState.isOpen}
        onRequestClose={closeUserLimitAlert}
        seatsLimit={userLimitAlertState.seatsLimit || 0}
        planName={userLimitAlertState.planName || ""}
      />
      <SeatsUpdateAlert
        isOpen={isAdditionalSeatsAlertOpen}
        onSubmit={(count) => {
          setAdditionalSeats(count);
          setIsAdditionalSeatsAlertOpen(false);
        }}
        priceLabel={
          selectedPlan ? getAdditionalUserPriceNote(selectedPlan) : undefined
        }
        minCount={
          selectedPlan?.seatsCount &&
          subscriptionInfo.seatsUtilized - selectedPlan.seatsCount > 0
            ? subscriptionInfo.seatsUtilized - selectedPlan.seatsCount
            : 0
        }
        defaultCount={additionalSeats}
        onRequestClose={() => setIsAdditionalSeatsAlertOpen(false)}
      />
      {children}
    </SelectSubscriptionFormContext.Provider>
  );
};

export const useSelectSubscriptionFormProvider = () => {
  const context = useContext(SelectSubscriptionFormContext);

  if (!context) {
    throw new HookOutOfContextError(
      "useSelectSubscriptionFormProvider",
      "SelectSubscriptionFormContext"
    );
  }

  return context;
};
