import React from "react";
import { isSameDay, parseISO } from "date-fns";
import { utcToZonedTime, format } from "date-fns-tz";
import { Text } from "@jugl-web/ui-components/cross-platform";
import { cx, useTranslations } from "@jugl-web/utils";
import { ReactComponent as ClockIcon } from "./assets/greyclock.svg";

export const TimeString: React.FC<{
  date: string;
  hideIcon?: boolean;
  checkIfSameDay?: boolean;
  timezone: string;
}> = ({ date, hideIcon, checkIfSameDay, timezone }) => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const startTime = date.endsWith("Z") ? parseISO(date) : parseISO(`${date}Z`);
  const zonedStartTime = utcToZonedTime(startTime, timezone || timeZone);
  const formattedTime = format(zonedStartTime, "h:mm a", {
    timeZone: timezone || timeZone,
  });
  const sameDay = isSameDay(zonedStartTime, new Date(date));
  const { t } = useTranslations();
  return (
    <div className="flex flex-row items-center gap-1">
      <Text
        variant="text2Regular"
        className={cx("text-grey font-semibold", {
          "text-lowercase": checkIfSameDay && !sameDay,
        })}
      >
        {checkIfSameDay && !sameDay
          ? t({ id: "common.continued", defaultMessage: "continued" })
          : formattedTime}
      </Text>
      {!hideIcon && <ClockIcon />}
    </div>
  );
};
