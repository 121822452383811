import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addWeeks from "date-fns/addWeeks";
import addMonths from "date-fns/addMonths";
import format from "date-fns/format";
import { ChatBotReceivedMessage } from "./types";
import { ReactComponent as CsvIcon } from "./icons/csv.svg";
import { ReactComponent as CloudIcon } from "./icons/cloud.svg";
import { ReactComponent as GraphIcon } from "./icons/graph.svg";
import { ReactComponent as GroupIcon } from "./icons/group.svg";
import { ReactComponent as ReportIcon } from "./icons/report.svg";
import { ReactComponent as CalendarIcon } from "./icons/calendar.svg";

export const downloadReport = (linkSource: string, fileName: string) => {
  const downloadLink = document.createElement("a");
  document.body.appendChild(downloadLink);
  downloadLink.href = linkSource;
  downloadLink.style.display = "none";
  downloadLink.target = "_self";
  downloadLink.download = fileName;
  downloadLink.click();
  downloadLink.remove();
};

export const getIcon = (iconName?: string) => {
  switch (iconName) {
    case "task_report":
      return <ReportIcon />;
    case "temp_time_sheet":
    case "by_date":
    case "date_picker":
    case "emp_time_sheet":
      return <CalendarIcon />;
    case "by_employees":
      return <GroupIcon />;
    case "csv":
      return <CsvIcon />;
    case "graph":
      return <GraphIcon />;
    case "conv":
      return <CloudIcon />;
    default:
      return null;
  }
};

export const getNextChoices = (message: ChatBotReceivedMessage) =>
  message.action_value
    ? Object.keys(message.action_value).map((actionValueName) => {
        if (actionValueName === "FROM_DATE")
          return reportTimeRanges[message.key as keyof typeof reportTimeRanges]
            .from;
        if (actionValueName === "TO_DATE")
          return reportTimeRanges[message.key as keyof typeof reportTimeRanges]
            .to;
        const choice =
          message.action_value?.[
            actionValueName as keyof typeof message.action_value
          ];
        if (choice) return choice;
        return "";
      })
    : undefined;

export const reportTimeRanges = {
  current_week: {
    from: format(startOfWeek(new Date()), "yyyy-MM-dd"),
    to: format(endOfWeek(new Date()), "yyyy-MM-dd"),
  },
  previous_week: {
    from: format(startOfWeek(addWeeks(new Date(), -1)), "yyyy-MM-dd"),
    to: format(endOfWeek(addWeeks(new Date(), -1)), "yyyy-MM-dd"),
  },
  current_month: {
    from: format(startOfMonth(new Date()), "yyyy-MM-dd"),
    to: format(endOfMonth(new Date()), "yyyy-MM-dd"),
  },
  previous_month: {
    from: format(startOfMonth(addMonths(new Date(), -1)), "yyyy-MM-dd"),
    to: format(endOfMonth(addMonths(new Date(), -1)), "yyyy-MM-dd"),
  },
};

export const getImageSrc = (message?: ChatBotReceivedMessage) =>
  `data:${message?.filetype};base64, ${message?.base64}`;

export const formatTimeLabel = (date: Date) => {
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
};
