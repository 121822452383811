import { EntityModel, useRestApiProvider } from "@jugl-web/rest-api";
import { assert, useToast, useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import { DangerEntityActionConfirmationDialog } from "@web-src/modules/entities/components/DangerEntityActionConfirmationDialog";

interface DeleteEntityConfirmationDialogProps {
  isOpen: boolean;
  entity: EntityModel | null;
  onClose: () => void;
  onEntityDeleted?: () => void;
}

export const DeleteEntityConfirmationDialog: FC<
  DeleteEntityConfirmationDialogProps
> = ({ isOpen, entity, onClose, onEntityDeleted }) => {
  const { t } = useTranslations();
  const { toast } = useToast({ variant: "web" });

  const { entitiesApi } = useRestApiProvider();

  const [deleteEntity, { isLoading }] = entitiesApi.useDeleteEntityMutation();

  const handleConfirmDelete = async () => {
    assert(!!entity, "Entity must be defined");

    const response = await deleteEntity({ entityId: entity.id });

    if ("data" in response) {
      toast(
        t(
          {
            id: "feedback.workspace-has-been-removed",
            defaultMessage: "Workspace {entityName} has been removed",
          },
          { entityName: entity.name }
        ),
        { variant: "success" }
      );

      onEntityDeleted?.();
      onClose();
    }
  };

  return (
    <DangerEntityActionConfirmationDialog
      isOpen={isOpen}
      entity={entity}
      title={t({
        id: "workspace-page.delete-workspace",
        defaultMessage: "Delete workspace",
      })}
      message={t(
        {
          id: "workspace-page.delete-workspace-confirmation-description",
          defaultMessage:
            "You are about to delete the {entityName} workspace. Please note that by doing so, you will lose access to all information within this workspace. This action cannot be undone.",
        },
        {
          entityName: (
            <span className="text-primary-800 font-semibold">
              {entity?.name}
            </span>
          ),
        }
      )}
      requiredActionMessage={t(
        {
          id: "entities-page.delete-workspace-confirmation-input-description",
          defaultMessage:
            "To confirm this action, please type the <b>name of the workspace</b> you wish to delete",
        },
        { b: (text: (string | JSX.Element)[]) => <b>{text}</b> }
      )}
      submitButton={{
        label: t({
          id: "workspace-page.delete-workspace",
          defaultMessage: "Delete workspace",
        }),
        isDisabled: isLoading,
        onClick: handleConfirmDelete,
      }}
      onClose={onClose}
    />
  );
};
