import { TaskViewColumn } from "@jugl-web/domain-resources/tasks/hooks/useTasksViewColumns";
import { UserGeneralProfile } from "@jugl-web/domain-resources/users/components/UserGeneralProfile";
import { Avatar } from "@jugl-web/ui-components/cross-platform";
import { cx, useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import { tableColorSetToClasses } from "../../consts";

interface TaskTableTitleProps {
  entityId: string;
  meId: string | undefined;
  viewColumn: TaskViewColumn;
}

export const TaskTableTitle: FC<TaskTableTitleProps> = ({
  entityId,
  meId,
  viewColumn,
}) => {
  const { t } = useTranslations();

  const textClasses = cx(
    "font-secondary text-base font-semibold leading-4 tracking-[0.18px]",
    tableColorSetToClasses[viewColumn.tableView.tableColorSet].label
  );

  if (viewColumn.title.type === "text") {
    return <span className={textClasses}>{viewColumn.title.value}</span>;
  }

  if (viewColumn.title.type === "user") {
    return (
      <UserGeneralProfile entityId={entityId} userId={viewColumn.title.userId}>
        {({ safeProfile, profile }) => (
          <div className="flex items-center gap-2.5">
            <Avatar
              size="sm"
              username={safeProfile.displayName}
              imageUrl={safeProfile.avatar}
            />
            <span className={textClasses}>
              {safeProfile.displayName}{" "}
              {profile &&
                profile.id === meId &&
                `(${t({ id: "common.me", defaultMessage: "Me" })})`}
            </span>
          </div>
        )}
      </UserGeneralProfile>
    );
  }

  if (viewColumn.title.type === "customer") {
    return (
      <div className="flex items-center gap-2.5">
        <Avatar
          imageUrl={viewColumn.title.avatar}
          username={viewColumn.title.name}
          size="sm"
        />
        <span className={textClasses}>{viewColumn.title.name}</span>
      </div>
    );
  }

  throw new Error("Invalid title type");
};
