import { useTranslations } from "@jugl-web/utils";

export const NoResults = () => {
  const { t } = useTranslations();

  return (
    <div className="mt-2 text-center text-sm leading-[21px] text-[#4F4F4F]">
      {t({
        id: "list-box-component.no-results",
        defaultMessage: "No results 😔",
      })}
    </div>
  );
};
