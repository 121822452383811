import React, { useState, memo } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  UserProfile,
  UserProfileAwards,
  useRestApiProvider,
} from "@jugl-web/rest-api";
import { ReactComponent as BinIcon } from "@web-src/images/icons/delete.svg";
import format from "date-fns/format";
import { isoToLocalDate } from "@web-src/utils/helper";
import { AvatarSelect } from "@jugl-web/ui-components/web/AvatarSelect";
import { useTranslations } from "@jugl-web/utils";
import { DatePicker } from "@jugl-web/ui-components/cross-platform/DatePicker";
import {
  InteractiveContainer,
  Popover,
  Button,
  Divider,
  LoadingAnimation,
  TextField,
  FormControlLabel,
  ConfirmationPopup,
} from "@jugl-web/ui-components/cross-platform";
import { Accordion } from "@jugl-web/ui-components/cross-platform/Accordion";
import { ReactComponent as TrophyLineIcon } from "./assets/trophy-line.svg";
import { useProfileUpdateToast } from "../../hooks/useProfileUpdateToast";
import { DateButton } from "../DateButton/DateButton";

type Inputs = {
  name_award: string;
  awarded_on: Date | undefined;
  awarded_by: string;
};

const schema = yup.object().shape({
  name_award: yup.string().required(),
  awarded_by: yup.string().required(),
  awarded_on: yup.date().required(),
});

const EditAwardsFormContainer = memo(() => {
  const { usersApi } = useRestApiProvider();
  const { data } = usersApi.useGetUserProfileQuery({});
  return <EditAwardsFormComponent {...{ data }} />;
});

const EditAwardsFormComponent: React.FC<{
  data: UserProfile | undefined;
}> = ({ data }) => {
  const { t } = useTranslations();
  return (
    <div className="w-1/2">
      {!data ? (
        <LoadingAnimation />
      ) : data.awards.length === 0 ? (
        <div className="pt-10">
          <span className="pl-5 font-medium">
            {t({
              id: "awards-form-page.add-new-award",
              defaultMessage: "Add New Award",
            })}
          </span>
          <EditAwardsForm type="add" />
        </div>
      ) : (
        <EditAwardsList {...{ data }} />
      )}
    </div>
  );
};

const EditAwardsList: React.FC<{ data: UserProfile }> = ({ data }) => {
  const [displayForm, setDisplayForm] = useState(false);
  const { t } = useTranslations();
  return (
    <div className="pt-5">
      <span className="pl-5 font-medium">
        {t({
          id: "awards-form-page.awards",
          defaultMessage: "Awards",
        })}
      </span>
      {data.awards.map((el, idx) => (
        <>
          <EditAwardsListItem key={el.id} data={el} />
          {data.awards.length - 1 > idx && <Divider />}
        </>
      ))}
      {!displayForm && (
        <Button
          className="ml-5 mt-5 mb-10 w-1/2"
          variant="outlined"
          onClick={() => setDisplayForm(true)}
        >
          {t({
            id: "form-controls.add-new-award.button",
            defaultMessage: "Add New Award",
          })}
        </Button>
      )}
      {displayForm && (
        <>
          <span className="ml-5 font-medium">
            {t({
              id: "awards-form-page.add-new-award",
              defaultMessage: "Add New Award",
            })}
          </span>
          <EditAwardsForm type="add" onSuccess={() => setDisplayForm(false)} />
        </>
      )}
    </div>
  );
};

const EditAwardsListItem: React.FC<{ data: UserProfileAwards }> = ({
  data,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const { usersApi } = useRestApiProvider();
  const [deleteAward, { isLoading }] =
    usersApi.useDeleteUserProfileSectionMutation();
  const { showProfileUpdatedSnackbar } = useProfileUpdateToast();
  const { t } = useTranslations();

  const handleDeleteAward = async () => {
    const res = await deleteAward({
      type: "award",
      item_id: data.id,
    });
    if ("data" in res) {
      showProfileUpdatedSnackbar();
      setOpenModal(false);
    }
  };

  return (
    <>
      <Accordion
        variant="web"
        title={
          <div className="flex w-full items-center gap-5 px-5 py-3">
            {data.img ? (
              <img
                src={data.img}
                className="h-9 w-9 rounded-full"
                alt="Award"
              />
            ) : (
              <div className="flex h-9 w-9 items-center justify-center rounded-full bg-[rgba(238,90,138,0.1)]">
                <TrophyLineIcon />
              </div>
            )}
            <div className="flex grow flex-col gap-1">
              <span>{data.name}</span>
              {data.date && (
                <span className="text-grey text-sm">
                  {format(isoToLocalDate(data.date), "dd/MMMM/yyyy")}
                </span>
              )}
            </div>
            <Button
              color="tertiary"
              className="text-sm font-medium uppercase"
              isDisabled={isLoading}
              onClick={(e) => {
                e.stopPropagation();
                setOpenModal(true);
              }}
              variant="text"
              iconStart={<BinIcon />}
            >
              {t({
                id: "common.delete",
                defaultMessage: "Delete",
              })}
            </Button>
          </div>
        }
        className="flex-row-reverse px-4"
      >
        {({ onClose }) => (
          <EditAwardsForm {...{ data }} type="update" onSuccess={onClose} />
        )}
      </Accordion>
      {openModal && (
        <ConfirmationPopup
          isOpen={openModal}
          onRequestClose={() => setOpenModal(false)}
          hasCancelButton
          message={t({
            id: "awards-form-page.award-information-removal-confirmation",
            defaultMessage: "Do you really want to remove Award information?",
          })}
          variant="web"
          title={t({
            id: "delete-modal.delete-confirmation-title",
            defaultMessage: "Delete Confirmation",
          })}
          buttons={[
            {
              label: t({
                id: "common.delete",
                defaultMessage: "Delete",
              }),
              color: "tertiary",
              onClick: handleDeleteAward,
              isDisabled: isLoading,
            },
          ]}
        />
      )}
    </>
  );
};

const EditAwardsForm: React.FC<{
  data?: UserProfileAwards;
  type: "add" | "update";
  onSuccess?: () => void;
}> = ({ data, type, onSuccess }) => {
  const { usersApi } = useRestApiProvider();
  const [addAward, { isLoading }] = usersApi.useAddUserProfileSectionMutation();
  const [selectedAvatar, setSelectedAvatar] = useState<File>();
  const [updateAward, { isLoading: isLoadingUpdate }] =
    usersApi.useUpdateUserProfileSectionMutation();
  const { showProfileUpdatedSnackbar } = useProfileUpdateToast();
  const { t } = useTranslations();
  const {
    register,
    handleSubmit,
    control,
    formState: { isValid, isDirty },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    values: {
      name_award: data?.name || "",
      awarded_by: data?.awarded_by || "",
      awarded_on: data?.date ? new Date(data.date) : undefined,
    },
  });

  const onSubmit: SubmitHandler<Inputs> = async (params) => {
    const {
      name_award: NameAward,
      awarded_by: AwardedBy,
      awarded_on: AwardedOn,
    } = { ...params };
    const dataForm = new FormData();
    dataForm.set("type", "award");
    dataForm.set("name", NameAward);
    dataForm.set("awarded_by", AwardedBy);
    dataForm.set("date", AwardedOn?.toISOString() || "");
    if (selectedAvatar) dataForm.set("display_pic_file", selectedAvatar);
    let res;
    if (type === "add") {
      res = await addAward({ data: dataForm });
    } else {
      res = await updateAward({
        item_id: data?.id,
        data: dataForm,
      });
    }
    if ("data" in res) {
      showProfileUpdatedSnackbar();
      onSuccess?.();
    }
  };

  return (
    <div className="px-10 pb-10 pt-5">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-5">
          <AvatarSelect
            onSave={setSelectedAvatar}
            value={selectedAvatar}
            defaultSrc={data?.img || undefined}
            placeholder={<TrophyLineIcon className="h-10 w-10" />}
          />
        </div>
        <div className="flex w-full gap-[70px]">
          <div className="flex w-1/2 flex-col gap-12">
            <TextField
              isRequired
              label={t({
                id: "form-controls.award-name.label",
                defaultMessage: "Name of Award",
              })}
              isFullWidth
              placeholder={t({
                id: "form-controls.award-name.placeholder",
                defaultMessage: "Name of Award",
              })}
              {...register("name_award")}
            />
            <TextField
              isRequired
              label={t({
                id: "form-controls.awarded-by.label",
                defaultMessage: "Awarded By",
              })}
              isFullWidth
              placeholder={t({
                id: "form-controls.awarded-by.placeholder",
                defaultMessage: "Awarded By",
              })}
              {...register("awarded_by")}
            />
            <Button
              isDisabled={!isValid || isLoading || isLoadingUpdate || !isDirty}
              fullWidth
              variant="contained"
              type="submit"
            >
              {t({
                id: "common.save",
                defaultMessage: "Save",
              })}
            </Button>
          </div>
          <div className="w-1/2">
            <Controller
              name="awarded_on"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Popover
                  className="w-[340px]"
                  placement="bottom-start"
                  renderTrigger={({ Trigger, triggerRef }) => (
                    <Trigger ref={triggerRef} as={InteractiveContainer}>
                      <FormControlLabel isRequired className="ml-2.5">
                        {t({
                          id: "form-controls.award-date.label",
                          defaultMessage: "Awarded On (Date)",
                        })}
                      </FormControlLabel>
                      <DateButton date={value} />
                    </Trigger>
                  )}
                >
                  {({ onClose }) => (
                    <DatePicker
                      maxDate={new Date()}
                      onSubmit={onChange}
                      initialDate={value}
                      onClose={onClose}
                      className="p-4"
                      dateTransformation="endOfDay"
                    />
                  )}
                </Popover>
              )}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditAwardsFormContainer;
