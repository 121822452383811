import { assert, cx, useTranslations } from "@jugl-web/utils";
import { DPMonth, useDatePickerContext } from "@rehookify/datepicker";
import { FC, MouseEvent, useState } from "react";
import { Button } from "../../../Button";
import { PickerBaseProps } from "../../types";

interface MonthPickerProps extends PickerBaseProps {
  hasNavigation?: boolean;
}

export const MonthPicker: FC<MonthPickerProps> = ({
  onViewChange,
  hasNavigation = true,
}) => {
  const { t } = useTranslations();

  const {
    data: { months, calendars },
    propGetters: { monthButton },
  } = useDatePickerContext();

  const { month, year } = calendars[0];

  const [internalMonth, setInternalMonth] = useState(() => {
    const matchingMonth = months.find((dpMonth) => dpMonth.month === month);

    if (matchingMonth && !matchingMonth.disabled) {
      return matchingMonth;
    }

    const firstAvailableMonth = months.find((dpMonth) => !dpMonth.disabled);

    return firstAvailableMonth;
  });

  const handleMonthSelect = (
    event: MouseEvent<HTMLButtonElement>,
    dpMonth: DPMonth
  ) => {
    if (dpMonth.disabled) {
      return;
    }

    if (!hasNavigation) {
      monthButton(dpMonth).onClick?.(event);
      onViewChange("dayPicker");
    } else {
      setInternalMonth(dpMonth);
    }
  };

  const handleSubmit = (event: MouseEvent<HTMLButtonElement>) => {
    assert(!!internalMonth, "Month must be selected");
    monthButton(internalMonth).onClick?.(event);
    onViewChange("dayPicker");
  };

  return (
    <>
      <div className="bg-grey-100 flex h-[42px] items-center justify-between rounded-lg px-6">
        <span className="text-grey-800 text-[13px] font-medium">
          {t({
            id: "date-picker-component.select-month",
            defaultMessage: "Select Month",
          })}
        </span>
        <span className="text-dark-800 text-[18px] font-bold">
          {internalMonth?.month}
        </span>
      </div>
      <div className="mt-6 grid grid-cols-3 gap-x-1.5 gap-y-6">
        {months.map((dpMonth) => {
          const isSelected = internalMonth?.$date === dpMonth.$date;

          return (
            <button
              key={dpMonth.month + year}
              type="button"
              className={cx(
                "h-11 cursor-pointer rounded-[32px] border-none font-[Roboto] text-sm transition-colors",
                "bg-grey-100 hover:bg-grey-200 font-normal text-[#19181A]",
                dpMonth.now &&
                  "bg-primary-50 text-primary hover:bg-primary-50 font-semibold",
                dpMonth.disabled &&
                  "text-grey-500 bg-grey-100 hover:bg-grey-100 cursor-not-allowed font-normal",
                isSelected &&
                  "bg-primary hover:bg-primary font-semibold text-white"
              )}
              onClick={(event) => handleMonthSelect(event, dpMonth)}
            >
              {dpMonth.month}
            </button>
          );
        })}
      </div>
      {hasNavigation && (
        <div className="mt-8 flex gap-4">
          <Button
            color="grey"
            fullWidth
            onClick={() => onViewChange("dayPicker")}
          >
            {t({ id: "common.cancel", defaultMessage: "Cancel" })}
          </Button>
          <Button
            fullWidth
            isDisabled={internalMonth === undefined}
            onClick={handleSubmit}
          >
            {t({ id: "common.select", defaultMessage: "Select" })}
          </Button>
        </div>
      )}
    </>
  );
};
