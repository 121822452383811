import React from "react";
import formatDate from "date-fns/format";
import { UserProfileEducations } from "@jugl-web/rest-api";
import { cx } from "@jugl-web/utils";

const PersonalInfoElement: React.FC<{
  icon: React.ReactNode;
  primaryText: string;
  secondaryText: string;
  hideBorder?: boolean;
  contentText?: string | null;
  educations?: UserProfileEducations[];
}> = ({
  icon,
  primaryText,
  secondaryText,
  hideBorder,
  contentText,
  educations,
}) => (
  <div
    className={cx(
      "flex w-full flex-col items-start justify-start py-5",
      !hideBorder && "border-grey-200 border-0 border-b border-solid"
    )}
  >
    <div className="flex items-center gap-2">
      <div className="flex h-[36px] w-[36px] items-center justify-center rounded-full bg-[rgba(34,197,158,0.1)]">
        {icon}
      </div>
      <div className="flex flex-col gap-0.5">
        <span className="text-dark font-medium capitalize leading-3">
          {primaryText}
        </span>
        <span
          className={cx(
            "leading-2 text-[15px]",
            contentText ? "text-dark" : "text-grey"
          )}
        >
          {contentText || secondaryText}
        </span>
      </div>
    </div>
    {educations &&
      educations?.map((element, idx) => (
        <div
          className="relative flex flex-col items-start justify-start overflow-hidden pt-6 pr-6 pl-[52px]"
          key={element.id}
        >
          <div className="border-grey-300 absolute top-0 left-[17px] h-[34px] w-5 rounded-bl-full border-0 border-l border-b border-solid" />
          {educations.length - 1 > idx && (
            <div className="bg-grey-300 absolute top-0 left-[17px] h-full w-[1px]" />
          )}
          <div className="flex flex-col gap-0.5">
            <span className="text-dark font-medium capitalize leading-3">
              {element.name}
            </span>
            <span
              className={cx(
                "leading-2 text-[15px]",
                contentText ? "text-dark" : "text-grey"
              )}
            >
              {element.where}
            </span>
          </div>
          <span className="text-grey text-[15px]">
            {element.start &&
              `${formatDate(new Date(element.start), "MMM yyyy")} - `}
            {element.end && formatDate(new Date(element.end), "MMM yyyy")}
          </span>
        </div>
      ))}
  </div>
);

export default PersonalInfoElement;
