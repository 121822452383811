import React from "react";
import {
  InteractiveContainer,
  LoadingSpinner,
  Menu,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { UploadingFileItem } from "../../hooks/useUploadDocuments";
import { ReactComponent as SuccessfullyIcon } from "../../icons/successfully.svg";
import { ReactComponent as RetryIcon } from "../../icons/retry.svg";
import { ReactComponent as FailedIcon } from "../../icons/failed.svg";
import { ReactComponent as VerticalDotsIcon } from "../../icons/vertical-dots.svg";
import { ReactComponent as DeleteIcon } from "../../icons/delete.svg";

export const UploadingFile: React.FC<{
  item: UploadingFileItem;
  onDelete: (id: string) => void;
  onRetry: () => void;
}> = ({ item, onDelete, onRetry }) => {
  const { t } = useTranslations();
  return (
    <div className="bg-grey-100 flex w-full items-center gap-4 rounded-xl py-4 px-6">
      <div className="flex h-10 w-10 items-center justify-center">
        {item.isCompleted && <SuccessfullyIcon />}
        {item.isFailed && (
          <InteractiveContainer
            className="bg-tertiary-50 flex h-full w-full items-center justify-center rounded-full"
            onClick={onRetry}
          >
            <RetryIcon />
          </InteractiveContainer>
        )}
        {!item.isCompleted && !item.isFailed && <LoadingSpinner />}
      </div>
      <div className="flex grow flex-col overflow-hidden">
        <span className="text-dark truncate font-medium">{item.file.name}</span>
        {item.isFailed ? (
          <div className="flex items-center gap-1">
            <FailedIcon />
            <span className="text-tertiary-700 text-xs font-medium">
              {t({
                id: "cpanel-page.failed-tap-to retry",
                defaultMessage: "Failed. Tap to retry",
              })}
            </span>
          </div>
        ) : (
          <>
            <div className="text-grey my-1 text-sm font-medium">
              {item.progress}%
            </div>
            <div className="bg-dark-200 h-1 w-full overflow-hidden rounded">
              <div
                style={{ width: `${item.progress}%` }}
                className="bg-primary h-full"
              />
            </div>
          </>
        )}
      </div>
      {item.isFailed && (
        <Menu
          sections={[
            [
              {
                id: "delete",
                icon: <DeleteIcon />,
                label: t({
                  id: "cpanel-page.delete",
                  defaultMessage: "Delete",
                }),
                onSelect: (e, close) => {
                  onDelete(item.id);
                  close();
                },
              },
            ],
          ]}
          placement="right"
          renderTrigger={({ Trigger, triggerRef }) => (
            <Trigger as={PlainButton} ref={triggerRef}>
              <VerticalDotsIcon />
            </Trigger>
          )}
        />
      )}
    </div>
  );
};
