import React from "react";
import { Avatar } from "@jugl-web/ui-components/cross-platform";
import { useUserGeneralProfile } from "@jugl-web/domain-resources/users/hooks/useUserGeneralProfile";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { UserCPanelModel, UserRole } from "@jugl-web/rest-api";
import { cx } from "@jugl-web/utils";
import Highlighter from "react-highlight-words";

export const CpanelUserInfo: React.FC<{
  userId?: string | null;
  cPanelUser: UserCPanelModel;
  searchQuery?: string;
}> = ({ userId, cPanelUser, searchQuery }) => {
  const { entity } = useEntity();
  const { profile } = useUserGeneralProfile({
    entityId: entity?.id,
    userId: userId || undefined,
    skip: !userId || !entity?.id,
  });
  return (
    <div className="flex items-center gap-4">
      <Avatar
        size="md"
        username={profile?.displayName || cPanelUser.display_name}
        imageUrl={profile?.avatar || cPanelUser.profile?.img}
      />
      <div className="flex flex-col gap-1">
        <span className="text-dark">
          {searchQuery ? (
            <Highlighter
              highlightClassName="text-primary font-semibold bg-transparent"
              autoEscape
              searchWords={[searchQuery]}
              textToHighlight={profile?.displayName || cPanelUser.display_name}
            />
          ) : (
            profile?.displayName || cPanelUser.display_name
          )}
        </span>
        <span
          className={cx("text-primary text-sm", {
            "text-primary": cPanelUser.role === UserRole.admin,
            "text-grey": cPanelUser.role === UserRole.member,
          })}
        >
          {cPanelUser.role === UserRole.admin ? "Admin" : "Member"}
        </span>
      </div>
    </div>
  );
};
