import { FC } from "react";

export interface TaskCardPillProps {
  label: string;
  backgroundColor?: string;
  textColor?: string;
}

export const PILL_BASE_BG_COLOR = "#F9F9F9";
export const PILL_BASE_TEXT_COLOR = "#40494D";

export const TaskCardPill: FC<TaskCardPillProps> = ({
  label,
  backgroundColor = PILL_BASE_BG_COLOR,
  textColor = PILL_BASE_TEXT_COLOR,
}) => (
  <div
    className="leading-2 truncate rounded-md py-0.5 px-1.5 text-xs"
    style={{
      backgroundColor,
      color: textColor,
    }}
    title={label}
  >
    {label}
  </div>
);
