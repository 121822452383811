import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import useWaveAudio from "@web-src/features/app/hooks/useWaveAudio";
import { Typography, useTheme } from "@mui/material";
import { useUniqueDOMId } from "@jugl-web/utils";

const ChatAudioPlayer: React.FC<{ url: string }> = ({ url }) => {
  const theme = useTheme();
  const id = useUniqueDOMId();
  const { playing, play, pause, durationStr, currentTimeStr } = useWaveAudio(
    url,
    {
      container: `#${id}`,
      waveColor: theme.palette.juglPrimary[500],
      progressColor: theme.palette.juglSecondary[500],
      cursorColor: "rgba(0,0,0,0)",
      cursorWidth: 0,
      barWidth: 3,
      barGap: 4,
      barMinHeight: 5,
      barRadius: 3,
      height: 60,
      hideScrollbar: true,
    }
  );
  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        height: "46px",
        border: "1px solid",
        borderColor: "primary.500",
        borderRadius: "30px",
        overflow: "hidden",
        bgcolor: "juglPrimary.50",
      }}
    >
      <Box sx={{ width: "100px" }}>
        <IconButton
          onClick={playing ? pause : play}
          color="primary"
          sx={{ ml: "10px" }}
        >
          {playing ? <PauseRoundedIcon /> : <PlayArrowRoundedIcon />}
        </IconButton>
      </Box>
      <Box
        sx={{
          flex: 1,
          "wave > wave": {
            // Color waveform progress background
            backgroundColor: "juglSecondary.50",
            borderRadius: "4px",
          },
        }}
      >
        <div id={id} />
      </Box>
      <Box
        sx={{
          width: "100px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          paddingRight: "16px",
        }}
      >
        <Typography
          sx={{
            color: "primary.500",
            fontSize: "12px",
            lineHeight: "12px",
            fontWeight: 500,
            mr: "2px",
          }}
        >
          {currentTimeStr}
        </Typography>
        <Typography
          sx={{
            fontSize: "11px",
            lineHeight: "11px",
            color: "grey.A100",
            fontWeight: 500,
          }}
        >
          /{durationStr}
        </Typography>
      </Box>
    </Stack>
  );
};

export default ChatAudioPlayer;
