import React, {
  Fragment,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
} from "react";
import { Virtuoso, GroupedVirtuoso } from "react-virtuoso";
import { useInView } from "react-intersection-observer";
import { HeadlessUserItem } from "./types";
import {
  HeadlessUsersListHookParams,
  useHeadlessUsersList,
} from "../../hooks/useHeadlessUsersList";
import { DefaultEmptyContent } from "./components/DefaultEmptyContent";
import { DefaultLoadingContent } from "./components/DefaultLoadingContent";
import { DefaultErrorContent } from "./components/DefaultErrorContent";
import { DefaultMoreLoadingErrorContent } from "./components/DefaultMoreLoadingErrorContent";
import { DefaultLoadingMoreContent } from "./components/DefaultLoadingMoreContent";

export type HeadlessUsersListHandle = { refetch: () => void };

export type HeadlessUsersListParams = {
  fetchParams: HeadlessUsersListHookParams;

  userRenderer?: (user: HeadlessUserItem) => JSX.Element;
  children?: (params: {
    users?: HeadlessUserItem[];
    isLoading?: boolean;
    isError?: boolean;
  }) => JSX.Element;

  segregate?: {
    type: "department";
    groupHeaderRenderer: (params: { title: string; id: string }) => JSX.Element;
  };

  virtualized?: boolean;

  emptyContent?: JSX.Element;
  loadingContent?: JSX.Element;
  moreLoadingContent?: JSX.Element;
  errorContent?: (retry: () => void) => JSX.Element;
  moreLoadingErrorContent?: (retry: () => void) => JSX.Element;
};

export const HeadlessUsersList = forwardRef<
  HeadlessUsersListHandle,
  HeadlessUsersListParams
>(
  (
    {
      userRenderer,
      emptyContent,
      loadingContent,
      moreLoadingContent,
      errorContent,
      moreLoadingErrorContent,
      segregate,
      virtualized,
      fetchParams,
      children,
    },
    ref
  ) => {
    const {
      users,
      isLoading,
      isError,
      loadMore,
      refetch,
      listState,
      reachedEnd,
      retry,
    } = useHeadlessUsersList(fetchParams);
    const { ref: inViewRef, inView } = useInView();

    useImperativeHandle(ref, () => ({
      refetch,
    }));

    useEffect(() => {
      if (segregate && fetchParams.workspaceId) {
        // eslint-disable-next-line no-console
        console.info(
          `[HeadlessUsersList]: segregation not working with workspace filter`
        );
      }
    }, [segregate, fetchParams.workspaceId]);

    const groupsInfo: {
      groups: { title: string; id: string }[];
      groupCounts: number[];
    } | null = useMemo(() => {
      if (!segregate || fetchParams.workspaceId || listState?.isSearch) {
        return null;
      }
      const result: {
        groups: { title: string; id: string }[];
        groupCounts: number[];
      } = {
        groups: [],
        groupCounts: [],
      };
      const noDepartmentId = "no-department";
      users.forEach((item) => {
        const departmentId = item.space ? item.space.id : noDepartmentId;
        let currentGroup = result.groups.find(
          (group) => group.id === departmentId
        );
        if (!currentGroup) {
          currentGroup = {
            id: departmentId,
            title: !item.space ? "Without department" : item.space.title,
          };
          result.groups.push(currentGroup);
          result.groupCounts.push(0);
        }
        result.groupCounts[result.groupCounts.length - 1] =
          result.groupCounts[result.groupCounts.length - 1] + 1;
      });
      return result;
    }, [segregate, fetchParams.workspaceId, listState?.isSearch, users]);

    const handleVirtuosoEndReached = useCallback(() => {
      if (reachedEnd) {
        return;
      }
      loadMore();
    }, [loadMore, reachedEnd]);

    useEffect(() => {
      if (inView) {
        loadMore();
      }
    }, [inView, loadMore]);

    const $infiniteBottomContent = useMemo(() => {
      if (isError) {
        return (
          moreLoadingErrorContent?.(retry) || (
            <DefaultMoreLoadingErrorContent retry={retry} />
          )
        );
      }
      if (isLoading) {
        return moreLoadingContent || <DefaultLoadingMoreContent />;
      }
      return null;
    }, [
      isError,
      isLoading,
      moreLoadingContent,
      moreLoadingErrorContent,
      retry,
    ]);

    if (children) {
      return children({ users, isLoading, isError });
    }

    if (!users?.length) {
      if (isError) {
        return errorContent?.(retry) || <DefaultErrorContent retry={retry} />;
      }
      if (isLoading) {
        return loadingContent || <DefaultLoadingContent />;
      }
      return emptyContent || <DefaultEmptyContent />;
    }

    if (!userRenderer) {
      throw new Error('Either "children" or "userRenderer" is required');
    }

    if (virtualized) {
      if (groupsInfo && segregate) {
        return (
          <GroupedVirtuoso
            key="grouped"
            style={{ height: "100%" }}
            groupCounts={groupsInfo.groupCounts}
            groupContent={(index) =>
              segregate.groupHeaderRenderer(groupsInfo.groups[index])
            }
            itemContent={(index) => userRenderer(users[index])}
            endReached={handleVirtuosoEndReached}
            components={{ Footer: () => $infiniteBottomContent }}
          />
        );
      }
      return (
        <Virtuoso
          key="plain"
          style={{ height: "100%" }}
          totalCount={users.length}
          itemContent={(index) => userRenderer(users[index])}
          endReached={handleVirtuosoEndReached}
          components={{ Footer: () => $infiniteBottomContent }}
        />
      );
    }

    const $bottomContent = (
      <>
        {$infiniteBottomContent}
        {!isLoading && !isError && !reachedEnd ? <div ref={inViewRef} /> : null}
      </>
    );

    if (groupsInfo && segregate) {
      return (
        <>
          {groupsInfo.groupCounts.map((groupCount, groupIdx) => (
            <Fragment key={+groupIdx}>
              {segregate.groupHeaderRenderer(groupsInfo.groups[groupIdx])}
              {(() => {
                const startIdx = groupsInfo.groupCounts
                  .slice(0, groupIdx)
                  .reduce((a, b) => a + b, 0);
                const items = [];
                for (let i = startIdx; i < startIdx + groupCount - 1; i += 1) {
                  const user = users[i];
                  items.push(
                    <Fragment key={user.id}>{userRenderer(users[i])}</Fragment>
                  );
                }
                return items;
              })()}
            </Fragment>
          ))}
          {$bottomContent}
        </>
      );
    }
    return (
      <>
        {users.map((item) => (
          <Fragment key={item.id}>{userRenderer(item)}</Fragment>
        ))}
        {$bottomContent}
      </>
    );
  }
);
