import React, { memo, useMemo } from "react";
import Highlighter from "react-highlight-words";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Avatar } from "@jugl-web/ui-components/cross-platform";
import Box from "@mui/material/Box";
import { ChatItem, ChatType } from "@web-src/features/chats/types";
import { isoToLocalDate } from "@web-src/utils/helper";
import format from "date-fns/format";
import { primary } from "@web-src/themes/colors";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { getEntityUserDisplayName } from "@web-src/features/entities/utils";
import UserProfileName from "@web-src/features/users/components/UserProfileName";
import { useTranslations } from "@jugl-web/utils";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { MENTIONS_ALL_ID } from "@jugl-web/utils/consts";
import { environment } from "@web-src/environments/environment";
import { useNotifications } from "@web-src/modules/notifications/providers/NotificationsProvider";
import isToday from "date-fns/isToday";
import { ReactComponent as NotificationsMuteAllExceptMentionsIcon } from "./icons/notifications_mute_all_except_mentions.svg";
import { ReactComponent as NotificationsMuteAllIcon } from "./icons/notifications_mute_all.svg";
import ChatListAttachment from "../ChatListAttachment";
import { ReactComponent as BellIcon } from "./icons/bell.svg";

const ChatListItem: React.FC<{
  chat: ChatItem;
  active: boolean;
  onClick?: () => void;
  simple?: boolean;
  searchChat: string;
}> = ({ chat, onClick, active, simple, searchChat }) => {
  const theme = useTheme();
  const { me } = useMe();
  const { t } = useTranslations();
  const { dateLocale } = useLanguage();
  const { entity } = useEntity();
  const name = useMemo(
    () =>
      chat.isSelf
        ? `${me ? getEntityUserDisplayName(me) : ""} (${t({
            id: "common.you",
            defaultMessage: "You",
          })})`
        : chat.title || "",
    [chat.isSelf, chat.title, me, t]
  );

  const lastMessageString = useMemo(() => {
    if (chat.lastMessage) {
      return chat.lastMessage.message;
    }
    return t({
      id: "chats-page.no-messages",
      defaultMessage: "No messages",
    });
  }, [chat.lastMessage, t]);
  const dateStr = useMemo(() => {
    if (!chat.lastMessage?.timestamp) {
      return null;
    }
    const date = isoToLocalDate(chat.lastMessage.timestamp);
    return (
      chat.lastMessage?.timestamp &&
      format(date, isToday(date) ? "hh:mm a" : "dd MMM, hh:mm a", {
        locale: dateLocale,
      })
    );
  }, [chat.lastMessage?.timestamp, dateLocale]);

  const isEntityConversation = useMemo(
    () => chat.type === ChatType.entity_conversation,
    [chat.type]
  );

  const avatarSrc = useMemo(() => {
    if (isEntityConversation) {
      return entity?.logoImg;
    }
    if (chat.isSelf) {
      return me?.profile?.img;
    }
    return chat.img;
  }, [
    chat.img,
    chat.isSelf,
    entity?.logoImg,
    isEntityConversation,
    me?.profile?.img,
  ]);

  const { getChatNotificationsPreferences } = useNotifications();
  const chatNotificationPreferences = useMemo(
    () => chat?.id && getChatNotificationsPreferences(chat?.id),
    [chat?.id, getChatNotificationsPreferences]
  );

  const $notificationsIcon = useMemo(() => {
    switch (chatNotificationPreferences) {
      case "all":
        return <NotificationsMuteAllIcon />;
      case "all_except_mentions":
        return <NotificationsMuteAllExceptMentionsIcon />;
      case "none":
      default:
        return null;
    }
  }, [chatNotificationPreferences]);

  return (
    <Box
      onClick={onClick}
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "0 32px",
        height: "80px",
        cursor: "pointer",
        position: "relative",
        overflow: "hidden",
        backgroundColor: active ? "primary.50" : undefined,
        ":hover": active
          ? undefined
          : {
              backgroundColor: theme.palette.grey["100"],
            },
      }}
    >
      <div className="relative mr-3">
        <Avatar
          size="lg"
          imageUrl={avatarSrc}
          username={name}
          className={
            isEntityConversation
              ? "border-primary-700 border border-solid"
              : undefined
          }
        />
        {isEntityConversation && (
          <div className="absolute top-0 right-0 flex h-4 w-4 items-center justify-center rounded-full bg-white">
            <BellIcon />
          </div>
        )}
      </div>
      <Box sx={{ flex: 1, minWidth: 0 }}>
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          className="flex items-center gap-2"
        >
          <Typography
            noWrap
            variant="bodyBold"
            sx={{
              mark: {
                background: primary[500],
                fontWeight: "700",
              },
            }}
          >
            <Highlighter
              autoEscape
              searchWords={[searchChat]}
              textToHighlight={name || chat?.id || ""}
            />
          </Typography>
          {$notificationsIcon}
        </Box>
        {!simple && (
          <Typography
            noWrap
            variant="callout"
            color={
              isEntityConversation
                ? "primary.800"
                : active
                ? "dark.800"
                : "grey"
            }
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: chat.unreadCount && !chat.isSelf ? "bold" : undefined,
              display: "block",
            }}
          >
            <div className="overflow-hidden text-ellipsis whitespace-nowrap">
              {isEntityConversation ? (
                <span>
                  {t({
                    id: "chats-page.workspace-notifications",
                    defaultMessage: "Workspace Notifications",
                  })}
                </span>
              ) : chat.lastMessage?.deleted ? (
                <span>
                  {t({
                    id: "chats-page.deleted-message",
                    defaultMessage: "Message deleted by owner",
                  })}
                </span>
              ) : (
                <>
                  {chat.lastMessage?.from && chat.type === "muc" && (
                    <UserProfileName
                      userId={chat.lastMessage.from}
                      suffix=": "
                    />
                  )}
                  {chat.lastMessage?.tokenizedMessage?.length ? (
                    chat.lastMessage?.tokenizedMessage.map((item, idx) => (
                      // TODO: make component
                      <span key={item.id}>
                        {item.type === "userName" ? (
                          <UserProfileName userId={item.rawString} />
                        ) : item.type === "mention" &&
                          item.value === MENTIONS_ALL_ID ? (
                          t({
                            id: "common.all",
                            defaultMessage: "All",
                          })
                        ) : (
                          item.humanizedString || item.rawString
                        )}
                        {idx !== chat.lastMessage?.tokenizedMessage.length
                          ? " "
                          : ""}
                      </span>
                    ))
                  ) : chat.lastMessage?.attachments ? (
                    <ChatListAttachment
                      attachmentType={chat.lastMessage.attachments[0].type}
                      attachmentMime={chat.lastMessage.attachments[0].mimetype}
                      attachmentName={chat.lastMessage.attachments[0].name}
                    />
                  ) : (
                    lastMessageString
                  )}
                </>
              )}
            </div>
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          textAligh: "right",
          display: "flex",
          flexDirection: "column",
          alignItems: "right",
        }}
      >
        {dateStr && (
          <Typography variant="footnote" color="grey.900">
            {dateStr}
          </Typography>
        )}
        {!simple &&
        chat.unreadCount &&
        !chat.isSelf &&
        // TODO: prevent showing minus on production until we identify the issue
        (environment.production ? chat.unreadCount > 0 : true) ? (
          <Box
            sx={{
              fontSize: "10px",
              lineHeight: "11px",
              padding: "2px 6px",
              color: "white",
              borderRadius: "20px",
              marginLeft: "auto",
              marginTop: "4px",
              backgroundColor: "secondary.main",
              fontWeight: "bold",
            }}
          >
            {chat.unreadCount}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default memo(ChatListItem);
