import { Button } from "@jugl-web/ui-components/cross-platform";
import { BottomCenteredDrawer } from "@jugl-web/ui-components/web/BottomCenteredDrawer";
import { FC } from "react";
import { useTranslations } from "@jugl-web/utils";
import { useTaskFields } from "../../../hooks/useTaskFields";
import { ReactComponent as WarningIcon } from "../icons/warning.svg";

interface ErrorScreenProps {
  entityId: string;
}

export const ErrorScreen: FC<ErrorScreenProps> = ({ entityId }) => {
  const { refetch, isFetching } = useTaskFields({ entityId });
  const { t } = useTranslations();

  return (
    <BottomCenteredDrawer.Content className="flex flex-col items-center justify-center text-center">
      <WarningIcon className="mb-6" />
      <span className="text-dark mb-3 text-xl font-medium leading-4">
        {t({
          id: "feedback.something-went-wrong",
          defaultMessage: "Something went wrong",
        })}
      </span>
      <span className="text-dark mb-10 text-sm leading-3">
        {t({
          id: "feedback.trying-to-fix",
          defaultMessage: "We are trying to fix it",
        })}
      </span>
      <Button
        variant="contained"
        color="primary"
        isDisabled={isFetching}
        className="w-[200px]"
        onClick={refetch}
      >
        {t({
          id: "common.try-again",
          defaultMessage: "Try again",
        })}
      </Button>
    </BottomCenteredDrawer.Content>
  );
};
