import React, { useContext, useMemo, useCallback } from "react";
import { Event, LocationEvents } from "@jugl-web/rest-api/location/types";
import { LocationContext } from "@web-src/modules/location/pages/LocationPage/LocationPage";
import { ReactComponent as ClockIcon } from "@web-src/images/icons/clock.svg";
import { Text } from "@jugl-web/ui-components/cross-platform";
import { useTranslations, cx } from "@jugl-web/utils";
import getSymbolFromCurrency from "currency-symbol-map";
import { TimeString } from "./components/TimeString";
import { ListItem } from "./components/ListItem";
import { EventListItem } from "./components/EventListItem/EventListItem";
import { ReactComponent as UserIcon } from "./assets/user-2-line.svg";
import { ReactComponent as VisitedLocationIcon } from "./assets/visited-location.svg";
import { ReactComponent as Flowicon } from "./assets/flow.svg";
import { ReactComponent as AttachmentIcon } from "./assets/attachment-2.svg";
import { ReactComponent as ClockInIcon } from "./assets/clock-in.svg";
import { ReactComponent as ClockOutIcon } from "./assets/clock-out.svg";
import { ReactComponent as PausedIcon } from "./assets/paused.svg";
import { ReactComponent as ResumedIcon } from "./assets/resumed.svg";
import { ReactComponent as DrivingIcon } from "./assets/driving.svg";
import { ReactComponent as GreyPinIcon } from "./assets/grey-pin.svg";
import { ReactComponent as GpsLostIcon } from "./assets/gps-lost.svg";
import { ReactComponent as GpsConnectedIcon } from "./assets/gps-connected.svg";
import { ReactComponent as GreyClockIcon } from "./assets/greyclock.svg";

export const TrackingCard: React.FC<{
  toggleEventDetails?: (event: Event) => void;
  event: Event;
  timezone: string;
  addressMap?: { [key: string]: string };
  isAddressLoading?: boolean;
}> = ({
  event,
  toggleEventDetails,
  addressMap,
  timezone,
  isAddressLoading,
}) => {
  const { t } = useTranslations();
  const startAddress = isAddressLoading
    ? `${t({ id: "common.loading", defaultMessage: "Loading" })}...`
    : addressMap?.[
        `${event.loc?.[0]?.loc_pts.lat}${event.loc?.[0]?.loc_pts.lon}`
      ];
  const { setLocationToFocus } = useContext(LocationContext);
  const {
    type,
    start_time: startTime,
    end_time: endTime,
    opts,
    attachmnts,
    loc,
  } = event;

  const memoizedEventTime = useMemo(
    () => <TimeString date={startTime} timezone={timezone} />,
    [startTime, timezone]
  );

  const handleOnClick = useCallback(() => {
    const cordinates = loc && {
      lat: loc[0]?.loc_pts.lat,
      lon: loc[0]?.loc_pts.lon,
    };
    setLocationToFocus(cordinates);
    if (opts?.event_type && toggleEventDetails) {
      toggleEventDetails(event);
    }
  }, [event, loc, opts?.event_type, setLocationToFocus, toggleEventDetails]);
  const currencySymbol =
    getSymbolFromCurrency(opts?.crcy_type) || getSymbolFromCurrency("USD");
  const detailsList = useMemo(
    () => (
      <div className={cx("pl-8", { hidden: !opts?.event_type })}>
        <div className="border-primary-300 mt-2 mb-2 flex h-[22px] w-[48px] items-center justify-center rounded border border-solid">
          <Text variant="text2Regular" className="text-dark-a100">
            {opts?.event_type}
          </Text>
        </div>
        <div className="flex flex-col gap-2">
          {opts?.stage && (
            <ListItem text={opts?.stage || ""} icon={<Flowicon />} />
          )}
          {opts?.cname && (
            <ListItem
              text={`${(opts?.cname && `${opts?.cname},`) || ""} ${
                opts?.cdesc || ""
              }`}
              icon={<UserIcon />}
            />
          )}
          {opts?.crcy_value && (
            <ListItem
              text={opts?.crcy_value || ""}
              icon={<span className="text-grey">{currencySymbol}</span>}
            />
          )}
          {attachmnts?.length > 0 && (
            <ListItem
              text={`${attachmnts.length} ${t({
                id: "common.files",
                defaultMessage: "Files",
              })}`}
              icon={<AttachmentIcon />}
            />
          )}
        </div>
      </div>
    ),
    [
      attachmnts.length,
      currencySymbol,
      opts?.cdesc,
      opts?.cname,
      opts?.crcy_value,
      opts?.event_type,
      opts?.stage,
      t,
    ]
  );
  if (type === LocationEvents.driving) {
    const endAddress =
      addressMap &&
      event.end_time &&
      addressMap[
        `${event.loc.at(-1)?.loc_pts.lat}${event.loc.at(-1)?.loc_pts.lon}`
      ];
    return (
      <div
        className="flex cursor-pointer flex-row bg-white py-4"
        onClick={handleOnClick}
      >
        <div>
          <EventListItem
            containerClassNames="pt-0 px-0 pl-8 pr-3"
            onClick={handleOnClick}
            icon={<DrivingIcon />}
          />
        </div>
        <div className="flex w-full flex-col gap-2 overflow-hidden pr-7">
          <ListItem
            text={
              <Text variant="text2Regular" className="text-grey gap-1">
                {t({
                  id: "common.end",
                  defaultMessage: "End",
                })}
                :{" "}
                <Text variant="body3" className="text-dark font-medium">
                  {endAddress || "Ongoing"}
                </Text>
              </Text>
            }
            icon={<GreyPinIcon />}
          />
          <ListItem
            text={
              <Text variant="text2Regular" className="text-grey  gap-1">
                {t({
                  id: "common.start",
                  defaultMessage: "Start",
                })}
                :{" "}
                <Text variant="body3" className="text-dark font-medium">
                  {startAddress}
                </Text>
              </Text>
            }
            icon={<GreyPinIcon />}
          />
          <span className="flex flex-row">
            <GreyClockIcon className="mr-1.5" />
            <Text variant="text2Regular" className="text-grey mr-4 flex gap-1">
              {t({
                id: "common.start",
                defaultMessage: "Start",
              })}
              : <TimeString date={startTime} hideIcon timezone={timezone} />
            </Text>
            <Text variant="text2Regular" className="text-grey flex gap-1">
              {t({
                id: "common.end",
                defaultMessage: "End",
              })}
              :
              {(endTime && (
                <TimeString
                  hideIcon
                  date={endTime}
                  checkIfSameDay
                  timezone={timezone}
                />
              )) ||
                t({
                  id: "common.now",
                  defaultMessage: "Now",
                })}
            </Text>
          </span>
        </div>
      </div>
    );
  }
  if (type === LocationEvents.clock_in) {
    return (
      <EventListItem
        containerClassNames="bg-secondary-50 dark.A100"
        onClick={handleOnClick}
        text={t({
          id: "location-page.clock-in",
          defaultMessage: "Clock In",
        })}
        eventTime={memoizedEventTime}
        icon={<ClockInIcon />}
        address={startAddress}
      />
    );
  }
  if (type === LocationEvents.clock_out) {
    return (
      <EventListItem
        containerClassNames="bg-tertiary-50"
        onClick={handleOnClick}
        text={t({
          id: "location-page.clock-out",
          defaultMessage: "Clock Out",
        })}
        eventTime={memoizedEventTime}
        address={startAddress}
        icon={<ClockOutIcon />}
      />
    );
  }
  if (type === LocationEvents.location_disabled) {
    return (
      <EventListItem
        containerClassNames="bg-tertiary-50"
        onClick={handleOnClick}
        icon={<GpsLostIcon />}
        text={t({
          id: "location-page.gps-lost",
          defaultMessage: "GPS Lost",
        })}
        eventTime={memoizedEventTime}
        address={startAddress}
      />
    );
  }
  if (type === LocationEvents.offline) {
    return (
      <EventListItem
        containerClassNames="bg-tertiary-50"
        onClick={handleOnClick}
        icon={<GpsLostIcon />}
        text={t({
          id: "location-page.connection-lost",
          defaultMessage: "Connection Lost",
        })}
        eventTime={memoizedEventTime}
        address={startAddress}
      />
    );
  }
  if (type === LocationEvents.paused) {
    return (
      <EventListItem
        containerClassNames="bg-tertiary-50"
        onClick={handleOnClick}
        icon={<PausedIcon />}
        text={t({
          id: "common.paused",
          defaultMessage: "Paused",
        })}
        eventTime={memoizedEventTime}
        address={startAddress}
      />
    );
  }
  if (type === LocationEvents.resumed) {
    return (
      <EventListItem
        containerClassNames="bg-secondary-50"
        onClick={handleOnClick}
        icon={<ResumedIcon />}
        text={t({
          id: "common.resumed",
          defaultMessage: "Resumed",
        })}
        eventTime={memoizedEventTime}
        address={startAddress}
        detailsList={detailsList}
      />
    );
  }
  if (type === LocationEvents.location_enabled) {
    return (
      <EventListItem
        containerClassNames="bg-white"
        onClick={handleOnClick}
        icon={<GpsConnectedIcon />}
        text={t({
          id: "location-page.gps-connected",
          defaultMessage: "GPS Connected",
        })}
        eventTime={memoizedEventTime}
        address={startAddress}
        detailsList={detailsList}
      />
    );
  }
  if (type === LocationEvents.online) {
    return (
      <EventListItem
        containerClassNames="bg-white"
        onClick={handleOnClick}
        icon={<GpsConnectedIcon />}
        text={t({
          id: "location-page.connection-restored",
          defaultMessage: "Connection Restored",
        })}
        eventTime={memoizedEventTime}
        address={startAddress}
        detailsList={detailsList}
      />
    );
  }
  if (type === LocationEvents.idle) {
    return (
      <EventListItem
        containerClassNames="bg-white relative h-unset display-unset"
        onClick={handleOnClick}
      >
        <div className="flex flex-row place-content-center items-center justify-start gap-2">
          <div className="h-5 w-5">
            <VisitedLocationIcon />
          </div>
          <Text
            variant="body2"
            className="text-dark.A100 overflow-hidden text-ellipsis font-medium"
          >
            {startAddress ||
              t({
                id: "location-page.unknown-location",
                defaultMessage: "Unknown location",
              })}
          </Text>
        </div>
        <div className="flex flex-row items-center gap-1 pl-8">
          <GreyClockIcon className="mr-1" />
          <TimeString date={startTime} hideIcon timezone={timezone} />-
          {(endTime && (
            <TimeString
              date={endTime}
              checkIfSameDay
              hideIcon
              timezone={timezone}
            />
          )) || (
            <>
              <Text
                variant="text2Regular"
                className="text-grey-a100 font-medium"
              >
                {t({
                  id: "common.now",
                  defaultMessage: "Now",
                })}
              </Text>
              <ClockIcon className="h-4 w-4" />
            </>
          )}
        </div>
        {opts?.event_type && detailsList}
      </EventListItem>
    );
  }
  return <></>;
};
