import { useRestApiProvider } from "@jugl-web/rest-api";
import { Buffer } from "buffer";
import mime from "mime";

const TUS_DOWNLOAD_CHUNK_SIZE_IN_BYTES = 5 * 1024 * 1024; // 5MB

export const useDownloadFile = () => {
  const { driveApi } = useRestApiProvider();
  const [downloadChunk] = driveApi.useDownloadFileChunkMutation();
  const [downloadGuestFileChunk] = driveApi.useDownloadGuestFileChunkMutation();

  const downloadFile = async ({
    entityId,
    id,
    fileName,
    fileSize,
    mimeType,
    isGuestApp,
    onProgress,
  }: {
    entityId: string;
    id: string;
    fileName: string;
    fileSize: number;
    mimeType: string;
    isGuestApp?: boolean;
    chunkSize?: number;
    onProgress?: (bytesSent: number, totalBytes: number) => void;
  }): Promise<{ data: File } | Error> => {
    const downloadFileRequest = isGuestApp
      ? downloadGuestFileChunk
      : downloadChunk;
    const numChunks = Math.ceil(fileSize / TUS_DOWNLOAD_CHUNK_SIZE_IN_BYTES);
    let file = new File([], fileName, { type: mimeType });
    try {
      for (let i = 0; i < numChunks; i += 1) {
        const start = i * TUS_DOWNLOAD_CHUNK_SIZE_IN_BYTES;
        const end =
          Math.min(fileSize, start + TUS_DOWNLOAD_CHUNK_SIZE_IN_BYTES) - 1;
        // eslint-disable-next-line no-await-in-loop
        const response = await downloadFileRequest({
          entityId,
          id,
          range: [start, end],
        });
        onProgress?.(end, fileSize);
        if ("data" in response) {
          const chunkData = Buffer.from(response.data, "base64");
          const chunkBlob = new Blob([chunkData], {
            type: mimeType,
          });

          const fileBlob = new Blob([file.slice(0, start), chunkBlob], {
            type: mimeType,
          });
          file = new File(
            [fileBlob],
            `${fileName}.${mime.getExtension(mimeType)}`,
            {
              type: mimeType,
            }
          );
        } else {
          throw new Error();
        }
      }
    } catch (err) {
      return err as Error;
    }
    return { data: file };
  };

  return { downloadFile };
};
