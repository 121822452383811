import { SidebarDrawer } from "@jugl-web/ui-components/web";
import { useTranslations } from "@jugl-web/utils";
import React from "react";
import useEntity from "@web-src/features/app/hooks/useEntity";
import {
  InteractiveContainer,
  LoadingSpinner,
} from "@jugl-web/ui-components/cross-platform";
import { Switch } from "@jugl-web/ui-components/cross-platform/Switch";
import { usePreferencesProvider } from "@jugl-web/domain-resources/preferences";
import { useUserEntityPreferences } from "@web-src/modules/preferences/hooks/useUserEntityPreferences";
import { NotificationsPreferencesSection } from "./components/NotificationsPreferencesSection";
import { MutedChatsSection } from "./components/MutedChatsSection";
import { NotificationCategory } from "../../types";
import { extractNumberFromSoundType } from "./utils/extractNumberFromSoundType";

export const NotificationsSettingsSidebar: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const { t } = useTranslations();
  const { entity } = useEntity();
  const { preferences, updatePreferences, isLoading, isError } =
    useUserEntityPreferences();

  const {
    preferences: appPreferences,
    updatePreferences: updateAppPreferences,
  } = usePreferencesProvider();

  const updateNotificationsPreferences = (
    notificationPreferences: NotificationCategory
  ) => {
    if (!entity?.id) return;
    updatePreferences({
      notification_pref: notificationPreferences,
    });
  };

  return (
    <SidebarDrawer
      isOpen={isOpen}
      onClose={onClose}
      title={t({
        id: "settings-page.notifications-and-sounds",
        defaultMessage: "Notifications and Sounds",
      })}
    >
      <SidebarDrawer.Content className="bg-dark-100 flex flex-col gap-4">
        {isLoading ? (
          <div className="flex h-full w-full items-center justify-center">
            <LoadingSpinner />
          </div>
        ) : isError || !preferences ? (
          <div className="text-tertiary-400 mt-7 w-full text-center text-lg">
            {t({
              id: "settings-page.something-went-wrong",
              defaultMessage: "Something went wrong!",
            })}
          </div>
        ) : (
          <>
            <InteractiveContainer
              className="flex items-center justify-between bg-white p-6"
              onClick={() =>
                updateAppPreferences({
                  inAppNotificationsEnabled:
                    !appPreferences.inAppNotificationsEnabled,
                })
              }
            >
              <div className="flex flex-col gap-1">
                <span className="text-dark">
                  {t({
                    id: "settings-page.in-app-notifications",
                    defaultMessage: "In-App Notifications",
                  })}
                </span>
                <span className="text-xs text-[#828282]">
                  {t({
                    id: "settings-page.in-app-notifications-description",
                    defaultMessage: "From Organization that you’re logged in",
                  })}
                </span>
              </div>
              <Switch isChecked={appPreferences.inAppNotificationsEnabled} />
            </InteractiveContainer>
            <MutedChatsSection userEntityPreferences={preferences} />
            <NotificationsPreferencesSection
              title={t({
                id: "settings-page.private-chat-notifications",
                defaultMessage: "Private Chat Notifications",
              })}
              isNotificationSoundActive={
                preferences.notification_pref?.chats?.sound ?? true
              }
              notificationSoundId={
                extractNumberFromSoundType(
                  preferences.notification_pref?.chats?.sound_web
                ) ?? 0
              }
              onChange={(chatsPreferences) =>
                updateNotificationsPreferences({
                  chats: chatsPreferences,
                })
              }
            />
            <NotificationsPreferencesSection
              title={t({
                id: "settings-page.group-chat-notifications",
                defaultMessage: "Group Chat Notifications",
              })}
              isNotificationSoundActive={
                preferences.notification_pref?.groups?.sound ?? true
              }
              notificationSoundId={
                extractNumberFromSoundType(
                  preferences.notification_pref?.groups?.sound_web
                ) ?? 0
              }
              onChange={(groupsPreferences) =>
                updateNotificationsPreferences({
                  groups: groupsPreferences,
                })
              }
            />
            <NotificationsPreferencesSection
              title={t({
                id: "settings-page.calls-notifications",
                defaultMessage: "Calls Notifications",
              })}
              isNotificationSoundActive={
                preferences.notification_pref?.calls?.sound ?? true
              }
              notificationSoundId={
                extractNumberFromSoundType(
                  preferences.notification_pref?.calls?.sound_web
                ) ?? 0
              }
              onChange={(callsPreferences) =>
                updateNotificationsPreferences({
                  calls: callsPreferences,
                })
              }
            />
            <NotificationsPreferencesSection
              title={t({
                id: "settings-page.tasks-notifications",
                defaultMessage: "Tasks Notifications",
              })}
              isNotificationSoundActive={
                preferences.notification_pref?.task?.sound ?? true
              }
              notificationSoundId={
                extractNumberFromSoundType(
                  preferences.notification_pref?.task?.sound_web
                ) ?? 0
              }
              onChange={(tasksPreferences) =>
                updateNotificationsPreferences({
                  task: tasksPreferences,
                })
              }
            />
            <NotificationsPreferencesSection
              title={t({
                id: "settings-page.drive-notifications",
                defaultMessage: "Drive Notifications",
              })}
              isNotificationSoundActive={
                preferences.notification_pref?.drive?.sound ?? true
              }
              notificationSoundId={
                extractNumberFromSoundType(
                  preferences.notification_pref?.drive?.sound_web
                ) ?? 0
              }
              onChange={(drivePreferences) =>
                updateNotificationsPreferences({
                  drive: drivePreferences,
                })
              }
            />
          </>
        )}
      </SidebarDrawer.Content>
    </SidebarDrawer>
  );
};
