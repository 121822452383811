import format from "date-fns/format";
import isToday from "date-fns/isToday";
import isYesterday from "date-fns/isYesterday";
import isPast from "date-fns/isPast";
import differenceInMinutes from "date-fns/differenceInMinutes";
import utcToZonedTime from "date-fns-tz/utcToZonedTime";
import parseISO from "date-fns/parseISO";
import { isoToLocalDate } from "./helper";

export const DEFAULT_DATE_FORMAT = "MM/dd/yyyy";
export const DEFAULT_DATETIME_FORMAT = "MM/dd/yyyy h:mm aa";

export const isValidDate = (d) =>
  d instanceof Date && !Number.isNaN(d.getTime());

export const dateString = (date) => {
  const toDate =
    new Date(date) instanceof Date ? date : new Date().toISOString();
  const todayDate = new Date();
  const localDate = isoToLocalDate(toDate);

  if (differenceInMinutes(todayDate, localDate) < 1) {
    return "Just Now";
  }

  if (isToday(localDate)) {
    return "Today";
  }

  if (isYesterday(localDate)) {
    return "Yesterday";
  }

  return format(localDate, "d, MMM yyyy");
};

export const dateTimeString = (date) => {
  const toDate =
    new Date(date) instanceof Date ? date : new Date().toISOString();

  const todayDate = new Date();
  const localDate = isoToLocalDate(toDate);
  if (differenceInMinutes(todayDate, localDate) < 1) {
    return "Just Now";
  }

  if (isToday(localDate)) {
    return `Today ${format(localDate, "h:mm a")}`;
  }

  if (isYesterday(localDate)) {
    return `Yesterday ${format(localDate, "h:mm a")}`;
  }

  return format(localDate, "do MMM yyyy, h:mm a");
};
export const getDueDateString = (dueDate) => {
  const toDate =
    new Date(dueDate) instanceof Date ? dueDate : new Date().toISOString();

  const localDate = isoToLocalDate(toDate);
  return `${isPast(localDate) ? "Overdue" : "Due"} on ${
    isToday(localDate)
      ? `Today ${format(localDate, "h:mm a")}`
      : format(localDate, "do MMM yyyy")
  }`;
};

export const getConditionalTimeDateString = (date) => {
  const toDate =
    new Date(date) instanceof Date ? date : new Date().toISOString();

  const localDate = isoToLocalDate(toDate);

  return isToday(localDate)
    ? format(localDate, "h:mm a")
    : format(localDate, "d MMM, yyyy, h:mm a");
};

export const isoToTimeStr = (isoStr) =>
  format(
    utcToZonedTime(
      parseISO(isoStr),
      new window.Intl.DateTimeFormat().resolvedOptions().timeZone
    ),
    "p"
  );

export const secondsToTime = (seconds) => {
  let firstCharacterIdx = 15;
  if (seconds >= 3600) {
    firstCharacterIdx = 11;
  }
  if (seconds >= 600) {
    firstCharacterIdx = 14;
  }
  return new Date(seconds * 1000).toISOString().slice(firstCharacterIdx, 19);
};
