import {
  AuthPagesNavigationParams,
  authPagesNavigationConfig,
} from "../auth/pages/navigation-config";
import {
  CommonPagesNavigationParams,
  commonPagesNavigationConfig,
} from "../common/pages/navigation-config";
import {
  ConferencePagesNavigationParams,
  conferencePagesNavigationConfig,
} from "../conference/pages/navigation-config";
import {
  CustomersPagesNavigationParams,
  customersPagesNavigationConfig,
} from "../customers/pages/navigation-config";
import {
  DrivePagesNavigationParams,
  drivePagesNavigationConfig,
} from "../drive/pages/navigation-config";
import {
  LocationPagesNavigationParams,
  locationPagesNavigationConfig,
} from "../location/pages/navigation-config";
import {
  ReportsPagesNavigationParams,
  reportsPagesNavigationConfig,
} from "../reports/pages/navigation-config";
import {
  SchedulePagesNavigationParams,
  schedulePagesNavigationConfig,
} from "../schedule/pages/navigation-config";
import {
  SettingsPagesNavigationParams,
  settingsPagesNavigationConfig,
} from "../settings/pages/navigation-config";
import {
  WorkspacePagesNavigationParams,
  workspacePagesNavigationConfig,
} from "../workspace/pages/navigation-config";
import {
  EntityPagesNavigationParams,
  entityPagesNavigationConfig,
} from "../entities/pages/navigation-config";
import {
  PeoplePagesNavigationParams,
  peoplePagesNavigationConfig,
} from "../people/pages/navigation-config";
import {
  TasksPagesNavigationParams,
  tasksPagesNavigationConfig,
} from "../tasks/pages/navigation-config";
import {
  ChatsPagesNavigationParams,
  chatsPagesNavigationConfig,
} from "../chats/pages/navigation-config";
import {
  CallsPagesNavigationParams,
  callsPagesNavigationConfig,
} from "../calls/pages/navigation-config";

export type RootPagesNavigationParams = PeoplePagesNavigationParams &
  AuthPagesNavigationParams &
  DrivePagesNavigationParams &
  CommonPagesNavigationParams &
  ConferencePagesNavigationParams &
  CustomersPagesNavigationParams &
  LocationPagesNavigationParams &
  ReportsPagesNavigationParams &
  SchedulePagesNavigationParams &
  SettingsPagesNavigationParams &
  WorkspacePagesNavigationParams &
  EntityPagesNavigationParams &
  TasksPagesNavigationParams &
  ChatsPagesNavigationParams &
  CallsPagesNavigationParams;

export const rootNavigationPageConfig = {
  ...peoplePagesNavigationConfig,
  ...authPagesNavigationConfig,
  ...drivePagesNavigationConfig,
  ...commonPagesNavigationConfig,
  ...conferencePagesNavigationConfig,
  ...customersPagesNavigationConfig,
  ...locationPagesNavigationConfig,
  ...reportsPagesNavigationConfig,
  ...schedulePagesNavigationConfig,
  ...settingsPagesNavigationConfig,
  ...workspacePagesNavigationConfig,
  ...entityPagesNavigationConfig,
  ...tasksPagesNavigationConfig,
  ...chatsPagesNavigationConfig,
  ...callsPagesNavigationConfig,
};
