export enum EditProfileTiles {
  PersonalInfo = "personal-info",
  Education = "education",
  CurrentCity = "current-city",
  BirthPlace = "birth-place",
  Awards = "awards",
  Certifications = "certifications",
  Achievements = "achievements",
  Service = "service",
  Experience = "experience",
}

export enum SettingsRoutes {
  EditProfile = "edit-profile",
}

export type ChatNotificationsMuteOptions =
  | "all"
  | "all_except_mentions"
  | "none";

export type ChatNotificationsException = {
  [chatId: string]: {
    mute: ChatNotificationsMuteOptions;
  };
};

export interface NotificationCategorySettings {
  noti?: boolean;
  sound?: boolean;
  sound_web?: string;
  except?: ChatNotificationsException;
}

export interface NotificationCategory {
  chats?: NotificationCategorySettings;
  groups?: NotificationCategorySettings;
  drive?: NotificationCategorySettings;
  task?: NotificationCategorySettings;
  calls?: NotificationCategorySettings;
  user?: NotificationCategorySettings;
}

export type SoundSupportedModule = keyof NotificationCategory;

export type UserEntityPreferences = {
  notification_pref?: NotificationCategory;
};
