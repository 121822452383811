const KB = 1024;
const MB = KB * 1024;
const GB = MB * 1024;

export const calculatedSize = (sizeInBytes: number) => {
  if (sizeInBytes < KB) {
    return `${sizeInBytes} B`;
  }
  if (sizeInBytes < MB) {
    return `${(sizeInBytes / KB).toFixed(0)} KB`;
  }
  if (sizeInBytes < GB) {
    return `${(sizeInBytes / MB).toFixed(1)} MB`;
  }
  return `${(sizeInBytes / GB).toFixed(1)} GB`;
};
