import { cx } from "@jugl-web/utils/cx";
import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef } from "react";

export type PlainButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export const PlainButton = forwardRef<HTMLButtonElement, PlainButtonProps>(
  ({ children, className, disabled, ...buttonProps }, ref) => (
    <button
      ref={ref}
      type="button"
      className={cx(
        "border-none bg-transparent p-0 leading-[0]",
        !disabled && "cursor-pointer",
        className
      )}
      disabled={disabled}
      {...buttonProps}
    >
      {children}
    </button>
  )
);
