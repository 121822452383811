export const LoadingSkeleton = () => (
  <div className="animate-fade-in">
    <div className="flex animate-pulse flex-col gap-7">
      {Array.from({ length: 9 }).map((_, index, array) => (
        <div
          key={+index}
          className="h-20 rounded-lg bg-white"
          style={{ opacity: (1 / array.length) * (array.length - index) }}
        />
      ))}
    </div>
  </div>
);
