import { Alert } from "@jugl-web/ui-components/cross-platform";
import React from "react";
import Lottie from "react-lottie";
import { useToast, useTranslations } from "@jugl-web/utils";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { getTimezone } from "countries-and-timezones";
import timezoneAnimation from "./assets/timezone-animation.json";

export const TimezoneAlert: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const { t } = useTranslations();
  const { usersApi } = useRestApiProvider();
  const [updateTimezone, { isLoading }] = usersApi.useUpdateMyUserMutation();
  const systemZone = getTimezone(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const { toast } = useToast({ variant: "web" });

  const handleTimezoneChange = async () => {
    if (!systemZone) return;
    const resp = await updateTimezone({
      timezone: systemZone.name,
    });
    if (resp && "data" in resp) {
      toast(
        t({
          id: "feedback.time-zone-was-updated",
          defaultMessage: "Time Zone was updated",
        })
      );
      onClose();
    }
  };

  return (
    <Alert
      isOpen={isOpen}
      onRequestClose={onClose}
      img={
        <Lottie
          options={{ animationData: timezoneAnimation }}
          height={180}
          width={270}
        />
      }
      title={t({
        id: "settings-page.timezone-settings",
        defaultMessage: "Time Zone Settings",
      })}
      content={t(
        {
          id: "settings-page.mismatch-timezone-message",
          defaultMessage:
            "Seems like your device Time Zone is different than in Jugl Settings. Do you want to update Time Zone to {timezoneName} for Jugl as well?",
        },
        {
          timezoneName: (
            <span className="text-primary-800 font-semibold">
              {systemZone
                ? `${systemZone.name} (UTC${systemZone.utcOffsetStr})`
                : ""}
            </span>
          ),
        }
      )}
      buttons={[
        {
          text: t({
            id: "common.later",
            defaultMessage: "Later",
          }),
          role: "close",
        },
        {
          text: t({
            id: "common.update",
            defaultMessage: "Update",
          }),
          isDisabled: isLoading,
          color: "primary",
          onClick: handleTimezoneChange,
        },
      ]}
    />
  );
};
