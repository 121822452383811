import { useCustomers } from "@jugl-web/domain-resources/customers/hooks/useCustomers";
import { InternalTaskFilters } from "@jugl-web/rest-api/tasks";
import { Avatar } from "@jugl-web/ui-components/cross-platform/Avatar";
import { useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import { ReactComponent as WithoutUserIcon } from "../../assets/without-user.svg";
import { FilterPill, LoadingFilterPill } from "../FilterPill";

interface CustomerFilterPillProps {
  entityId: string;
  customerId: InternalTaskFilters["customers"][number];
  onRemove: () => void;
}

export const CustomerFilterPill: FC<CustomerFilterPillProps> = ({
  entityId,
  customerId,
  onRemove,
}) => {
  const { t } = useTranslations();
  const { getCustomerDetailsById, isLoading } = useCustomers({ entityId });

  if (isLoading) {
    return <LoadingFilterPill />;
  }

  if (customerId === null) {
    return (
      <FilterPill
        label={t({
          id: "tasks-page.without-customer",
          defaultMessage: "Without customer",
        })}
        startIcon={<WithoutUserIcon className="h-5 w-5" />}
        onRemove={onRemove}
      />
    );
  }

  const customerDetails = getCustomerDetailsById(customerId);

  return (
    <FilterPill
      label={
        customerDetails?.fullName ||
        t({
          id: "tasks-page.unknown-customer",
          defaultMessage: "Unknown customer",
        })
      }
      startIcon={
        <Avatar
          size="xs"
          username={
            customerDetails?.fullName ||
            t({
              id: "tasks-page.unknown-customer",
              defaultMessage: "Unknown customer",
            })
          }
          imageUrl={customerDetails?.avatarUrl}
        />
      }
      onRemove={onRemove}
    />
  );
};
