import { useTaskFiltersStateContext } from "@jugl-web/domain-resources/tasks/hooks/useTaskFiltersState";
import { TaskStatusDetails } from "@jugl-web/domain-resources/tasks/hooks/useDefaultTaskStatuses";
import { useTaskStatuses } from "@jugl-web/domain-resources/tasks/hooks/useTaskStatuses";
import { ListBoxItem } from "@jugl-web/ui-components/cross-platform/ListBox";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { useTranslations } from "@jugl-web/utils";
import { FC, useMemo } from "react";
import { FilterSectionProps } from "../../types";
import { FilterSectionLayout } from "../FilterSectionLayout";
import { StatusFilterPill } from "../StatusFilterPill";

export const StatusFilterSection: FC<FilterSectionProps> = ({ entityId }) => {
  const { allStatuses } = useTaskStatuses({ entityId });

  const { filtersState, updateFiltersState } = useTaskFiltersStateContext();

  const { t } = useTranslations();

  const statusesAsListItems = useMemo<ListBoxItem<TaskStatusDetails>[]>(
    () => allStatuses.map((status) => ({ id: status.id, value: status })),
    [allStatuses]
  );

  return (
    <FilterSectionLayout>
      <FilterSectionLayout.Header
        title={t({
          id: "tasks-page.by-status-filter",
          defaultMessage: "By Status",
        })}
        endSlot={
          <ResourcePickerPopover
            placement="bottom-end"
            title={t({
              id: "tasks-page.select-status",
              defaultMessage: "Select status",
            })}
            items={statusesAsListItems}
            selectionBehavior={{ mode: "multiple" }}
            hasSearch
            defaultSelectedIds={filtersState.statuses}
            maxVisibleItems={5}
            itemSize="md"
            spaceBetweenItems="compact"
            renderLabel={(item) => item.value.label}
            className="w-[315px]"
            renderTrigger={({ Trigger, triggerRef }) => (
              <Trigger ref={triggerRef} as={FilterSectionLayout.AddButton} />
            )}
            onSubmit={(ids) => updateFiltersState("statuses", ids)}
          />
        }
      />
      {filtersState.statuses.length > 0 && (
        <FilterSectionLayout.PillsContainer>
          {filtersState.statuses.map((statusId) => (
            <StatusFilterPill
              key={statusId}
              entityId={entityId}
              statusId={statusId}
              onRemove={() =>
                updateFiltersState("statuses", (previousStatuses) =>
                  previousStatuses.filter((s) => s !== statusId)
                )
              }
            />
          ))}
        </FilterSectionLayout.PillsContainer>
      )}
    </FilterSectionLayout>
  );
};
