import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ChatItem,
  ChatMessage,
  ChatMessageAction,
  ChatMessageActionType,
  ChatMessagePayloadType,
} from "@web-src/features/chats/types";
import useEntity from "@web-src/features/app/hooks/useEntity";
import {
  PaginationComponent,
  PaginationComponentHandle,
  PaginationItem,
  useDebouncedCallback,
  useToast,
  useTranslations,
} from "@jugl-web/utils";
import { useSelector, useDispatch } from "react-redux";
import { selectUserId } from "@web-src/features/auth/authSlice";
import useDebounce from "@web-src/hooks/useDebounce";
import { environment } from "@web-src/environments/environment";
import isSameDay from "date-fns/isSameDay";
import { useMessages } from "@web-src/modules/chats/hooks/useMessages";
import { ReactComponent as ChevronsDownIcon } from "./images/chevrons-down.svg";
import ChatDateHeader from "./ChatDateHeader";
import useSendMessageReadRecept from "../../hooks/useSendMessageReadRecept";
import { isMessagesDebugMode } from "../../utils";
import ChatMessageBubble from "../ChatMessageBubble";
import MessageReactionsSideBar from "../MessageReactionsSideBar";
import {
  selectSelectedSearchMessage,
  setSelectedSearchMessage,
} from "../../chatsSlice";
import { ChatMessageProvider } from "../../providers/ChatMessageProvider";

function useMemoOnce<T>(getter: () => T, deps: unknown[]) {
  const value = useRef<T>();
  const memorizedValue = useMemo(() => {
    if (value.current) {
      return value.current;
    }
    value.current = getter();
    return value.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  return memorizedValue;
}

const isDebugMode = isMessagesDebugMode();

export interface ChatMessagesWindowHandle {
  scrollToBottom: () => void;
}

const getInitFromMessage = (
  chat: ChatItem
):
  | {
      msgId: string;
      createdAt: string;
      type: "first-unread" | "last-read";
    }
  | undefined => {
  if (chat.lastReadMessage) {
    return {
      ...chat.lastReadMessage,
      type: "last-read",
    };
  }
  if (chat.firstUnreadMessage) {
    return {
      ...chat.firstUnreadMessage,
      type: "first-unread",
    };
  }
  return undefined;
};

const ChatMessagesWindow = forwardRef<
  ChatMessagesWindowHandle,
  {
    chat: ChatItem;
    disableMessageActions?: boolean;
    messageAction: ChatMessageAction;
    selectedMessages: ChatMessage[];
    setSelectedMessages: React.Dispatch<React.SetStateAction<ChatMessage[]>>;
  }
>(
  (
    {
      chat,
      disableMessageActions,
      messageAction,
      selectedMessages,
      setSelectedMessages,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const { entity } = useEntity();
    const { t } = useTranslations();
    const meId = useSelector(selectUserId);
    const selectedSearchMessage = useSelector(selectSelectedSearchMessage);

    const [atBottom, setAtBottom] = useState(true);
    const debouncedAtBottom = useDebounce(atBottom, 500);
    const initFromMessageDetails = useMemoOnce(
      () => ({ current: getInitFromMessage(chat) }),
      [chat]
    );
    const { toast } = useToast({ variant: "web" });
    const initFromMessage = getInitFromMessage(chat);
    const initMsg = useMemo(() => {
      if (selectedSearchMessage) {
        return {
          created: selectedSearchMessage.created_at,
          id: selectedSearchMessage.id,
        };
      }
      if (initFromMessage) {
        return {
          created: initFromMessage.createdAt,
          id: initFromMessage.msgId,
        };
      }
      return undefined;
    }, [initFromMessage, selectedSearchMessage]);
    const {
      messages,
      load,
      isMessagesLoading,
      messageGroups,
      resetPagination,
      resetToBottom,
      newLastLoaded,
      oldLastLoaded,
    } = useMessages({
      entityId: entity?.id,
      chatId: chat?.id,
      initFromMessage: initMsg,
    });

    const transformedMessages = useMemo(
      () =>
        messages?.map((item) => {
          const messagePaginationItem: PaginationItem<ChatMessage> = JSON.parse(
            JSON.stringify(item)
          );
          const message = messagePaginationItem.data;
          // Convert attachments
          // TODO: dirty!
          const attachment = message.payload?.attachments?.[0];
          if (
            attachment &&
            (!attachment.uid || attachment.url?.includes("amazonaws.com"))
          ) {
            const uid = attachment.url?.split("/").pop();
            attachment.uid = uid || "";
            attachment._preview_url = `${environment.apiUrl}/api/auth/attachment/stream?file=${uid}`;
            attachment._stream_url = `${environment.apiUrl}/api/auth/attachment/stream?file=${uid}`;
          } else if (attachment?.uid) {
            attachment._preview_url = `${environment.apiUrl}/api/auth/attachment/preview?file=${entity?.id}/${attachment.uid}`;
            attachment._stream_url = `${environment.apiUrl}/api/auth/attachment/stream?file=${entity?.id}/${attachment.uid}`;
          }

          const replyAttachment =
            message.payload?.reply_attributes_map?.reply_attachments?.[0];
          if (
            replyAttachment &&
            (!replyAttachment.uid ||
              replyAttachment.url?.includes("amazonaws.com"))
          ) {
            const uid = replyAttachment.url?.split("/").pop();
            replyAttachment.uid = uid || "";
            replyAttachment._preview_url = `${environment.apiUrl}/api/auth/attachment/stream?file=${uid}`;
            replyAttachment._stream_url = `${environment.apiUrl}/api/auth/attachment/stream?file=${uid}`;
          } else if (replyAttachment?.uid) {
            replyAttachment._preview_url = `${environment.apiUrl}/api/auth/attachment/preview?file=${entity?.id}/${replyAttachment.uid}`;
            replyAttachment._stream_url = `${environment.apiUrl}/api/auth/attachment/stream?file=${entity?.id}/${replyAttachment.uid}`;
          }
          return messagePaginationItem;
        }) || [],
      [entity?.id, messages]
    );

    useEffect(() => {
      if (selectedSearchMessage?.created_at) {
        resetPagination();
        load({
          loadDireaction: "new",
          forceTime: selectedSearchMessage.created_at,
          initialLoad: true,
          reset: true,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSearchMessage?.created_at]);
    const [reactionSideBarMessageId, setReactionSideBarMessageId] =
      useState<string>();
    const reactionSideBarMessage = useMemo(() => {
      if (!reactionSideBarMessageId) {
        return undefined;
      }
      return messages.find((item) => item.id === reactionSideBarMessageId)
        ?.data;
    }, [reactionSideBarMessageId, messages]);
    const messageNotFound = useDebouncedCallback(
      () =>
        toast(
          t({
            id: "chats-page.message-not-found",
            defaultMessage: "Message not found",
          })
        ),
      400
    );

    const firstItemIndexInitialized = useRef<number | null | undefined>();
    const firstItemIndex = useMemo(() => {
      if (
        (firstItemIndexInitialized.current !== undefined ||
          firstItemIndexInitialized.current != null) &&
        !selectedSearchMessage
      ) {
        return firstItemIndexInitialized.current;
      }
      if (
        (!selectedSearchMessage && !initFromMessageDetails.current) ||
        !messages?.length
      ) {
        return undefined;
      }
      const messageIdx = messages.findIndex(
        (item) =>
          item.id ===
          (selectedSearchMessage?.id || initFromMessageDetails.current?.msgId)
      );

      if (messageIdx === -1) {
        firstItemIndexInitialized.current = null;
        return undefined;
      }
      if (selectedSearchMessage && messages[messageIdx].data.deleted) {
        messageNotFound();
        firstItemIndexInitialized.current = null;
        return undefined;
      }
      firstItemIndexInitialized.current = messages.length - 1 - messageIdx;
      if (initFromMessageDetails.current?.type === "last-read") {
        firstItemIndexInitialized.current -= 1;
      }
      return firstItemIndexInitialized.current;
    }, [
      initFromMessageDetails,
      messages,
      selectedSearchMessage,
      messageNotFound,
    ]);

    const sendMessageReadReceipt = useSendMessageReadRecept();
    const [messageToSendReadReceipt, setMessageToSendReadReceipt] =
      useState<ChatMessage>();
    const [sendingReadReceipt, setSendingReadReceipt] = useState<boolean>();

    useEffect(() => {
      if (sendingReadReceipt || !messageToSendReadReceipt) {
        return undefined;
      }
      const deboundTimeout = setTimeout(() => {
        setSendingReadReceipt(true);
        if (!messageToSendReadReceipt || !chat.id) {
          return;
        }
        sendMessageReadReceipt({
          bulk: true,
          msgId: messageToSendReadReceipt.msg_id,
          receiptId: messageToSendReadReceipt.msg_receipt_id,
          chatId: chat.id,
          chatType: chat.type,
          msgTimestamp: messageToSendReadReceipt.timestamp,
        }).finally(() => {
          // TODO: error handling?
          setMessageToSendReadReceipt(undefined);
          setSendingReadReceipt(false);
        });
      }, 200);
      return () => {
        clearTimeout(deboundTimeout);
      };
    }, [
      messageToSendReadReceipt,
      sendingReadReceipt,
      chat.id,
      chat.type,
      sendMessageReadReceipt,
    ]);

    const $pagination = useRef<PaginationComponentHandle>(null);

    const handleScrollToBottom = useCallback(() => {
      if (newLastLoaded) {
        $pagination?.current?.scrollToBottom();
        return;
      }
      initFromMessageDetails.current = undefined;
      firstItemIndexInitialized.current = undefined;
      dispatch(setSelectedSearchMessage(undefined));
      resetToBottom();
    }, [dispatch, initFromMessageDetails, newLastLoaded, resetToBottom]);

    const handleMessageShow = useCallback(
      (message: ChatMessage) => {
        if (
          !message.msg_receipt_id ||
          !chat?.id ||
          // meId === message.from ||
          (chat.lastReadMessage?.createdAt &&
            chat.lastReadMessage.createdAt >= message.timestamp) ||
          messageToSendReadReceipt?.msg_id === message.msg_id ||
          (messageToSendReadReceipt &&
            messageToSendReadReceipt.timestamp >= message.timestamp)
        ) {
          return;
        }
        setMessageToSendReadReceipt(message);
      },
      [chat?.id, chat.lastReadMessage?.createdAt, messageToSendReadReceipt]
    );

    const onMessageSelect = useCallback(
      (selected: boolean, message: ChatMessage) =>
        selected
          ? setSelectedMessages((prev) => [...prev, message])
          : setSelectedMessages((prev) =>
              prev.filter((item) => item.msg_id !== message.msg_id)
            ),
      [setSelectedMessages]
    );

    const onReactionClick = useCallback(
      (message: ChatMessage) => setReactionSideBarMessageId(message.msg_id),
      [setReactionSideBarMessageId]
    );
    const itemRenderer = useCallback(
      (
        idx: number,
        e1: unknown,
        e2: unknown,
        context: { items: PaginationItem<ChatMessage>[] }
      ) => {
        const contextMessages = context.items;
        const message: ChatMessage = context.items?.[idx]?.data;
        if (!message || !chat) {
          return <div className="h-[1px]" />;
        }
        const grouped = {
          isFirst: false,
          isLast: false,
        };
        if (message?.payload?.type === ChatMessagePayloadType.message) {
          const prevMessage = contextMessages[idx - 1]?.data;
          const nextMessage = contextMessages[idx + 1]?.data;
          if (
            prevMessage?.payload?.type !== ChatMessagePayloadType.message ||
            !prevMessage ||
            prevMessage?.from !== message.from ||
            !isSameDay(
              new Date(prevMessage.timestamp),
              new Date(message.timestamp)
            )
          ) {
            grouped.isFirst = true;
          }
          if (
            nextMessage?.payload?.type !== ChatMessagePayloadType.message ||
            !nextMessage ||
            nextMessage?.from !== message.from
          ) {
            grouped.isLast = true;
          }
        }
        let isFirstUnread = false;
        if (initFromMessageDetails.current) {
          if (initFromMessageDetails.current.type === "first-unread") {
            isFirstUnread =
              initFromMessageDetails.current.msgId === message.msg_id;
          } else {
            isFirstUnread =
              initFromMessageDetails.current.msgId ===
              contextMessages[idx - 1]?.id;
          }
        }
        const isSearchedMessage =
          selectedSearchMessage &&
          selectedSearchMessage?.id === message?.msg_id &&
          !message.deleted;

        return (
          <ChatMessageProvider message={message}>
            <ChatMessageBubble
              message={message}
              onMessageShow={handleMessageShow}
              chatType={chat?.type}
              grouped={grouped}
              isFirstUnread={isFirstUnread}
              disableMessageActions={disableMessageActions}
              onReactionsClick={onReactionClick}
              isSelected={selectedMessages?.some(
                (item) => item.msg_id === message.msg_id
              )}
              showCheckbox={
                messageAction?.type === ChatMessageActionType.forward ||
                (messageAction?.type === ChatMessageActionType.delete &&
                  message.from === meId)
              }
              onSelect={onMessageSelect}
              isSearchedMessage={isSearchedMessage}
            />
          </ChatMessageProvider>
        );
      },
      [
        chat,
        initFromMessageDetails,
        handleMessageShow,
        disableMessageActions,
        meId,
        messageAction,
        onMessageSelect,
        onReactionClick,
        selectedMessages,
        selectedSearchMessage,
      ]
    );

    useImperativeHandle(ref, () => ({
      scrollToBottom: () => handleScrollToBottom(),
    }));

    if (!chat) {
      return null;
    }
    return (
      <>
        {reactionSideBarMessage && (
          <MessageReactionsSideBar
            message={reactionSideBarMessage}
            isOpen
            onRequestClose={setReactionSideBarMessageId.bind(null, undefined)}
          />
        )}
        <div className="relative h-full w-full">
          {messages?.length
            ? (!debouncedAtBottom || !newLastLoaded) && (
                <div
                  className="absolute bottom-[20px] right-[30px] z-50 flex h-[36px] w-[36px] cursor-pointer items-center justify-center rounded-[18px] bg-black"
                  onClick={handleScrollToBottom}
                >
                  {chat.unreadCount ? (
                    <div className="bg-secondary-500 absolute top-[-10px] flex h-[16px] min-w-[20px] items-center justify-center rounded-[10px] p-[1px] text-[12px] font-bold text-white">
                      {chat.unreadCount}
                    </div>
                  ) : undefined}
                  <ChevronsDownIcon />
                </div>
              )
            : null}
          {messages?.length ? (
            <PaginationComponent
              items={transformedMessages}
              endReached={
                isDebugMode
                  ? undefined
                  : load?.bind(null, { loadDireaction: "old" })
              }
              startReached={
                isDebugMode
                  ? undefined
                  : load?.bind(null, { loadDireaction: "new" })
              }
              ref={$pagination}
              HeaderContent={
                isDebugMode ? (
                  <div className="h-5">
                    <button
                      onClick={load?.bind(null, { loadDireaction: "old" })}
                      type="button"
                      disabled={oldLastLoaded}
                    >
                      {oldLastLoaded
                        ? t({
                            id: "chats-page.reached-start",
                            defaultMessage: "Reached start",
                          })
                        : t({
                            id: "chats-page.load-more",
                            defaultMessage: "Load more",
                          })}
                    </button>
                  </div>
                ) : undefined
              }
              FooterContent={
                isDebugMode ? (
                  <div className="h-5">
                    <button
                      onClick={load?.bind(null, { loadDireaction: "new" })}
                      type="button"
                      disabled={newLastLoaded}
                    >
                      {newLastLoaded
                        ? t({
                            id: "chats-page.reached-end",
                            defaultMessage: "Reached end",
                          })
                        : t({
                            id: "chats-page.load-more",
                            defaultMessage: "Load more",
                          })}
                    </button>
                  </div>
                ) : (
                  <div className="h-5" />
                )
              }
              isLoading={isMessagesLoading}
              id={chat?.id}
              reverse
              groupedRenderer={itemRenderer}
              initialFirstItem={firstItemIndex || undefined}
              grouped={{
                groupContent: (idx) => (
                  <ChatDateHeader date={messageGroups?.groups?.[idx]} />
                ),
                groupCounts: messageGroups?.counts,
              }}
              extraVirtuosoParams={{
                atBottomStateChange: setAtBottom,
                overscan: 1000,
              }}
            />
          ) : null}
        </div>
      </>
    );
  }
);

export default ChatMessagesWindow;
