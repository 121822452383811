import {
  TaskTemplateCard,
  TaskTemplateCardContainer,
  useTemplateCard,
} from "@jugl-web/domain-resources/tasks/components/TaskTemplateCard";
import {
  TemplateFilter,
  useTasksTemplates,
} from "@jugl-web/domain-resources/tasks/hooks/useTasksTemplates";
import { UserGeneralProfile } from "@jugl-web/domain-resources/users/components/UserGeneralProfile";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { PreviewTaskTemplate } from "@jugl-web/rest-api/tasks-templates";
import { Alert, FeedbackBox } from "@jugl-web/ui-components/cross-platform";
import { useAppVariant, useToast, useTranslations } from "@jugl-web/utils";
import { TasksPageLayout } from "@web-src/components/TasksPageLayout";
import { useTaskTemplatesPageContext } from "@web-src/features/tasks/TaskTemplatesPageContext";
import { TaskViewLoading } from "@web-src/features/tasks/TaskViewLoading";
import { FC, useState } from "react";
import { ManageTaskTemplateDialog } from "../ManageTaskTemplateDialog";
import { TaskViewEmptyState } from "../TaskViewEmptyState";
import { TemplatePreviewDialog } from "../TemplatePreviewDialog";
import { ReactComponent as AddBlankIcon } from "./assets/add-blank.svg";
import { ReactComponent as AddLargeIcon } from "./assets/add-large.svg";

interface TaskTemplatesContentProps {
  entityId: string;
  meId: string;
  folderId: string;
  isAdmin: boolean;
}

export const TaskTemplatesContent: FC<TaskTemplatesContentProps> = ({
  entityId,
  meId,
  folderId,
  isAdmin,
}) => {
  const { searchQuery, filter, setIsNewTemplateDialogOpen } =
    useTaskTemplatesPageContext();

  const [bufferedTemplate, setBufferedTemplate] =
    useState<PreviewTaskTemplate | null>(null);

  const [isPreviewTemplateDialogOpen, setIsPreviewTemplateDialogOpen] =
    useState(false);

  const [isEditTemplateDialogOpen, setIsEditTemplateDialogOpen] =
    useState(false);

  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] =
    useState(false);

  const { tasksTemplatesApi } = useRestApiProvider();

  const { variant } = useAppVariant();
  const { toast } = useToast({ variant });
  const { t } = useTranslations();

  const [deleteTemplate, { isLoading: isDeletingTemplate }] =
    tasksTemplatesApi.useDeleteTemplateMutation();

  const handleDeleteTemplate = async (templateId: string) => {
    const response = await deleteTemplate({ entityId, templateId });

    if ("data" in response) {
      setIsDeleteConfirmationDialogOpen(false);
      toast(
        t({
          id: "feedback.template-deleted",
          defaultMessage: "Template has been deleted",
        })
      );
    }
  };

  const { getTemplateCardProps } = useTemplateCard({ entityId });

  const { templates, isLoading, isError } = useTasksTemplates({
    entityId,
    meId,
    folderId,
    searchQuery,
    filter,
  });

  const hasAnyFilteringCriteria =
    searchQuery.length > 0 || filter !== TemplateFilter.AllTemplates;

  const isEmpty = templates.length === 0;

  if (isError) {
    return (
      <TasksPageLayout.Content>
        <FeedbackBox
          iconType="alert"
          title={t({
            id: "feedback.something-went-wrong",
            defaultMessage: "Something went wrong",
          })}
          subtitle={t({
            id: "feedback.error-occurred-try-again-message",
            defaultMessage: "We are trying to fix it, please try again",
          })}
          actionButton={{
            label: t({
              id: "common.refresh-page",
              defaultMessage: "Refresh page",
            }),
            onClick: () => window.location.reload(),
          }}
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        />
      </TasksPageLayout.Content>
    );
  }

  if (isLoading) {
    return (
      <TasksPageLayout.Content>
        <TaskViewLoading
          label={t({
            id: "tasks-page.loading-templates",
            defaultMessage: "Loading templates...",
          })}
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        />
      </TasksPageLayout.Content>
    );
  }

  if (isEmpty && hasAnyFilteringCriteria) {
    return (
      <TasksPageLayout.Content>
        <TaskViewEmptyState className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
      </TasksPageLayout.Content>
    );
  }

  return (
    <TasksPageLayout.Content className="px-8 pt-10 pb-8">
      <div className="animate-fade-in flex flex-wrap gap-[60px]">
        {!hasAnyFilteringCriteria && (
          <TaskTemplateCardContainer
            gradientColor="tertiary"
            avatarSlot={
              <div className="bg-gradients-g2 flex h-10 w-10 items-center justify-center rounded-full">
                <AddLargeIcon />
              </div>
            }
            onClick={() => setIsNewTemplateDialogOpen(true)}
          >
            <div className="flex flex-col gap-2">
              <span className="text-dark text-lg font-medium leading-[24px]">
                {t({
                  id: "tasks-page.create-your-template",
                  defaultMessage: "Create your template",
                })}
              </span>
              <span className="text-grey text-xs leading-[14px]">
                {t({
                  id: "tasks-page.create-custom-template",
                  defaultMessage:
                    "Create your own custom template from scratch",
                })}
              </span>
            </div>
            <div className="text-primary mt-10 flex items-center gap-2">
              <span className="leading-2 text-sm font-medium">
                {t({
                  id: "tasks-page.add-template",
                  defaultMessage: "Add template",
                })}
              </span>
              <AddBlankIcon />
            </div>
          </TaskTemplateCardContainer>
        )}
        {templates.map((template) => {
          const canManageTemplate = isAdmin || template.created_by === meId;

          return (
            <UserGeneralProfile
              key={template.id}
              entityId={entityId}
              userId={template.created_by}
            >
              {({ safeProfile }) => (
                <TaskTemplateCard
                  onClick={() => {
                    setBufferedTemplate(template);
                    setIsPreviewTemplateDialogOpen(true);
                  }}
                  shouldShowMoreFieldsTooltip
                  highlightedText={searchQuery}
                  menuConfig={
                    canManageTemplate
                      ? {
                          sections: [
                            [
                              {
                                id: "edit",
                                label: t({
                                  id: "tasks-page.edit-template",
                                  defaultMessage: "Edit template",
                                }),
                                onSelect: (event, close) => {
                                  event.stopPropagation();
                                  close();
                                  setBufferedTemplate(template);
                                  setIsEditTemplateDialogOpen(true);
                                },
                              },
                              {
                                id: "delete",
                                label: t({
                                  id: "tasks-page.delete-template",
                                  defaultMessage: "Delete template",
                                }),
                                onSelect: (event, close) => {
                                  event.stopPropagation();
                                  close();
                                  setBufferedTemplate(template);
                                  setIsDeleteConfirmationDialogOpen(true);
                                },
                              },
                            ],
                          ],
                        }
                      : undefined
                  }
                  avatar={{
                    username: safeProfile.displayName,
                    imageUrl: safeProfile.avatar,
                  }}
                  {...getTemplateCardProps(template)}
                />
              )}
            </UserGeneralProfile>
          );
        })}
      </div>
      <TemplatePreviewDialog
        isOpen={isPreviewTemplateDialogOpen}
        templateId={bufferedTemplate?.id || null}
        entityId={entityId}
        meId={meId}
        onClose={() => setIsPreviewTemplateDialogOpen(false)}
      />
      <ManageTaskTemplateDialog
        isOpen={isEditTemplateDialogOpen}
        entityId={entityId}
        meId={meId}
        folderId={folderId}
        editingTemplateId={bufferedTemplate?.id}
        onClose={() => setIsEditTemplateDialogOpen(false)}
      />
      <Alert
        isOpen={isDeleteConfirmationDialogOpen}
        title={t({
          id: "tasks-page.delete-task-template",
          defaultMessage: "Delete task template",
        })}
        content={t(
          {
            id: "tasks-page.confirm-delete-task-template",
            defaultMessage:
              "Are you sure you want to delete task template {templateName}?",
          },
          {
            templateName: (
              <span className="text-primary-800 font-semibold">
                {bufferedTemplate?.name}
              </span>
            ),
          }
        )}
        buttons={[
          {
            text: t({
              id: "common.cancel",
              defaultMessage: "Cancel",
            }),
            role: "close",
          },
          {
            text: t({
              id: "common.delete",
              defaultMessage: "Delete",
            }),
            color: "tertiary",
            isDisabled: isDeletingTemplate,
            onClick: () => handleDeleteTemplate(bufferedTemplate?.id || ""),
          },
        ]}
        onRequestClose={() => setIsDeleteConfirmationDialogOpen(false)}
      />
    </TasksPageLayout.Content>
  );
};
