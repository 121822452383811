import React, { useState } from "react";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { SidebarDrawer } from "@jugl-web/ui-components/web/SidebarDrawer";
import { TextField } from "@jugl-web/ui-components/cross-platform/TextField";
import { SelectField } from "@jugl-web/ui-components/cross-platform/SelectField";
import { Checkbox } from "@jugl-web/ui-components/cross-platform/Checkbox";
import { Text } from "@jugl-web/ui-components/cross-platform/Text";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { useForm } from "react-hook-form";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useRestApiProvider, enabledFieldTypes } from "@jugl-web/rest-api";
import { Pill } from "@jugl-web/ui-components/cross-platform";
import {
  DraggableChecklist,
  useDraggableChecklistController,
} from "@jugl-web/ui-components/cross-platform/DraggableChecklist";
import { PlainButton } from "@jugl-web/ui-components/cross-platform/PlainButton";
import { useToast, useTranslations } from "@jugl-web/utils";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";

export const AddNewFieldDialog: React.FC<{
  isOpen: boolean;
  section: "personal" | "company";
  onClose: () => void;
  onGoBack: () => void;
}> = ({ isOpen, section, onClose, onGoBack }) => {
  const { t } = useTranslations();
  const { customersFormFieldApi } = useRestApiProvider();
  const [dropdownList, setDropdownList] = useState<string[]>([]);
  const { entity } = useEntity();
  const { data: formFields } = customersFormFieldApi.useFormFieldsQuery(
    entity?.id ? { entityId: entity.id } : skipToken
  );
  const { toast } = useToast({ variant: "web" });
  const draggableChecklistController = useDraggableChecklistController();
  const [addFormField] = customersFormFieldApi.useAddFormFieldMutation();
  const {
    handleSubmit,
    register,
    formState: { isValid },
    setValue,
    watch,
    reset,
  } = useForm();
  const normalizeName = (name: string) =>
    name.trim().toLowerCase().replace(" ", "_");
  const onSubmit = handleSubmit(async (data) => {
    const maxPosition = Math.max.apply(
      null,
      formFields?.data
        .filter((item) => item.section === section)
        .map((item) => item.pos) || []
    );
    const normalizedName = normalizeName(data.name);
    const isNameAlreadyUsed = formFields?.data.find(
      (field) => field.name === normalizedName && field.section === section
    );
    if (isNameAlreadyUsed) {
      toast(
        t(
          {
            id: "feedback.field-name-already-exists",
            defaultMessage: "Field with name {name} already exists",
          },
          {
            name: data.name,
          }
        ),
        {
          variant: "error",
        }
      );
      return;
    }
    if (entity && data && maxPosition) {
      const response = await addFormField({
        entityId: entity.id,
        params: {
          section,
          name: normalizedName,
          type: data.type,
          required: data.required,
          pos: maxPosition + 1,
          value: data.type === "dropdown" ? dropdownList?.join(",") : null,
        },
      });

      if ("data" in response) {
        toast(
          t({
            id: "feedback.field-added",
            defaultMessage: "Field has been added",
          })
        );
        onGoBack();
        reset({ name: "", type: "text", required: false });
      }
    }
  });
  const params = watch();

  return (
    <SidebarDrawer
      isOpen={isOpen}
      title={
        section === "personal"
          ? t({
              id: "customers-page.new-field-personal-info",
              defaultMessage: "New field to Personal Info",
            })
          : t({
              id: "customers-page.new-field-company-info",
              defaultMessage: "New field to Company Info",
            })
      }
      onClose={onClose}
      onGoBack={onGoBack}
    >
      <SidebarDrawer.Content className="px-8 py-6">
        <div className="flex flex-col gap-6">
          <TextField
            variant="underline"
            placeholder={t({
              id: "form-controls.field-label.placeholder",
              defaultMessage: "Enter label here",
            })}
            label={t({
              id: "form-controls.field-label.label",
              defaultMessage: "Field label",
            })}
            isRequired
            isFullWidth
            {...register("name", {
              required: true,
              minLength: 2,
              pattern: /^\S+(?: \S+)*$/,
            })}
          />
          <SelectField
            label={t({
              id: "form-controls.field-type.label",
              defaultMessage: "Field type",
            })}
            isRequired
            isFullWidth
            {...register("type", { required: true })}
          >
            {enabledFieldTypes.map((field) => (
              <option>{field}</option>
            ))}
          </SelectField>
          <span className="flex items-center gap-2">
            <Checkbox
              isChecked={params.required}
              onChange={(e) => setValue("required", e.target.checked)}
            />
            <Text variant="timestamp">
              {t({
                id: "form-controls.mandatory-field.label",
                defaultMessage: "Mandatory Field",
              })}
            </Text>
          </span>
          {params.type === "dropdown" && (
            <div className="flex flex-col gap-3">
              <div className="flex flex-row items-center justify-between px-1">
                <Text variant="body2" className="capitalize">
                  {t({
                    id: "form-controls.dropdown-value.label",
                    defaultMessage: "Dropdown Value",
                  })}
                </Text>
                <div className="bg-grey-200 rounded-lg">
                  <div className="cursor-pointer">
                    <PlainButton
                      {...draggableChecklistController.addButtonProps}
                    >
                      <Pill
                        label={t({
                          id: "common.add",
                          defaultMessage: "Add",
                        })}
                        size="sm"
                        endIcon={<PlusIcon />}
                      />
                    </PlainButton>
                  </div>
                </div>
              </div>
              <div className="[&>:nth-child()]:bg-green-200">
                <DraggableChecklist
                  controller={draggableChecklistController}
                  hideCheckbox
                  onChange={(items) =>
                    setDropdownList(items.map((item) => item.text))
                  }
                />
              </div>
            </div>
          )}
        </div>
      </SidebarDrawer.Content>
      <SidebarDrawer.Actions className="flex w-full flex-col gap-4">
        <Button
          className="uppercase"
          isDisabled={
            !isValid ||
            (params.type === "dropdown" && dropdownList?.length === 0)
          }
          onClick={onSubmit}
        >
          {t({
            id: "common.save",
            defaultMessage: "Save",
          })}
        </Button>
        <Button
          variant="contained"
          className="bg-grey-600/10 text-dark-800 uppercase hover:bg-slate-600/20"
          onClick={() => onGoBack()}
        >
          {t({
            id: "common.cancel",
            defaultMessage: "Cancel",
          })}
        </Button>
      </SidebarDrawer.Actions>
    </SidebarDrawer>
  );
};
