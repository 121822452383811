import { getDueDateLabel } from "@jugl-web/domain-resources/tasks";
import { Popover } from "@jugl-web/ui-components/cross-platform";
import { DatePicker } from "@jugl-web/ui-components/cross-platform/DatePicker";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { isoToLocalDate } from "@web-src/utils/helper";
import { FC } from "react";
import { ChecklistTableCellComponentProps } from "../../types";
import { TaskTableLayout } from "../TaskTableLayout";

export const ChecklistItemDueDateCell: FC<ChecklistTableCellComponentProps> = ({
  item,
  specificOrderCompletionState,
  permissions,
  cellProps,
  onUpdate,
}) => {
  const { dateLocale } = useLanguage();

  const adaptedDueDate = item.due_at
    ? new Date(isoToLocalDate(item.due_at))
    : null;

  return (
    <Popover
      placement="bottom"
      renderTrigger={({ Trigger, triggerRef, isOpen, onClose }) => (
        <Trigger
          as={TaskTableLayout.Cell}
          ref={triggerRef}
          isFocused={isOpen}
          isHoverable
          isDisabled={!permissions.canManageChecklistItem}
          className="justify-between gap-2"
          {...cellProps}
        >
          {adaptedDueDate ? (
            <TaskTableLayout.CellText
              isFaded={
                item.is_completed || specificOrderCompletionState.isDisabled
              }
            >
              {getDueDateLabel({
                dueDate: adaptedDueDate,
                locale: dateLocale,
              })}
            </TaskTableLayout.CellText>
          ) : (
            <TaskTableLayout.AppearingAddValueCellText
              isStatic={isOpen}
              isHidden={!permissions.canManageChecklistItem}
            />
          )}
          {adaptedDueDate && permissions.canManageChecklistItem && (
            <TaskTableLayout.ClearCellValueButton
              isAppearing={!isOpen}
              onClick={(event) => {
                event.stopPropagation();
                onUpdate({ due_at: null });
                onClose();
              }}
            />
          )}
        </Trigger>
      )}
      className="w-[375px] px-4 py-6"
    >
      {({ onClose }) => (
        <DatePicker
          initialDate={adaptedDueDate || undefined}
          minDate={new Date()}
          dateTransformation="endOfDay"
          onDaySelect={(dueDate) => {
            onUpdate({ due_at: dueDate.toISOString() });
            onClose();
          }}
        />
      )}
    </Popover>
  );
};
