import { TableColorSet } from "@jugl-web/domain-resources/tasks/hooks/useTasksViewColumns";

export const TABLE_DIMENSIONS = {
  TABLE_CELL_GAP: 2,

  TABLE_TITLE_ROW_HEIGHT: 50,
  TABLE_HEADINGS_ROW_HEIGHT: 46,
  TABLE_TASK_ROW_HEIGHT: 56,
  TABLE_TASK_CHECKLIST_ITEM_ROW_HEIGHT: 56,
  TABLE_TASK_NEW_ITEM_ROW_HEIGHT: 40,
  TABLE_TASK_SEPARATOR_ROW_HEIGHT: 48,

  TABLE_OUTERMOST_COLUMN_WIDTH: 56,

  TABLE_TASK_TITLE_COLUMN_WIDTH: 576,
  TABLE_DUE_DATE_COLUMN_WIDTH: 220,
  TABLE_ASSIGNEE_COLUMN_WIDTH: 220,
  TABLE_CHECKLIST_COLUMN_WIDTH: 120,
  TABLE_PRIORITY_COLUMN_WIDTH: 160,
  TABLE_LABEL_COLUMN_WIDTH: 220,
  TABLE_STATUS_COLUMN_WIDTH: 220,
  TABLE_CUSTOM_FIELD_COLUMN_WIDTH: 220,
};

export const tableColorSetToClasses: Record<
  TableColorSet,
  { border: string; label: string }
> = {
  primary: {
    border: "border-gradients-g5",
    label: "text-dark-800",
  },
  secondary: {
    border: "border-gradients-g1",
    label: "text-dark-800",
  },
  tertiary: {
    border: "border-tertiary-400",
    label: "text-tertiary-900",
  },
  grey: {
    border: "border-[#BDBDBD]",
    label: "text-dark-800",
  },
};
