import React from "react";
import { cx } from "@jugl-web/utils";
import UserProfileAvatar from "@web-src/features/users/components/UserProfileAvatar";
import { ReactComponent as MutedMic } from "./assets/muted-mic.svg";

export const CallAvatar: React.FC<{
  userId: string;
  isSpeaking: boolean;
  size: number;
  showMutedIcon?: boolean;
}> = ({ userId, isSpeaking, size, showMutedIcon }) => {
  if (!size || !userId) {
    return null;
  }
  return (
    <div
      className={cx(
        `hxs:scale-[0.8] relative flex place-items-center items-center justify-center rounded-full transition-colors duration-500`,
        {
          [` bg-primary-500`]: isSpeaking,
        }
      )}
      style={{ width: `${size + 8}px`, height: `${size + 8}px` }}
    >
      <div
        style={{
          width: `${size}px`,
          height: `${size}px`,
        }}
      >
        <UserProfileAvatar
          userId={userId}
          wrapperClassName={cx(
            `h-full w-full rounded-full bg-grey-100 flex items-center place-content-center bg-gradient-to-b from-gray-300 to-dark-100`
          )}
          className={cx(
            `bg-grey-100 h-full w-full rounded-full border-[1px] border-solid border-white`
          )}
          lettersClassName={cx(
            `h-full w-full text-dark flex items-center justify-center text-base font-semibold uppercase leading-3`
          )}
        />
        <MutedMic
          className={cx(
            "bg-dark/70 absolute -bottom-4 left-1/2 -translate-x-1/2 rounded-full border-[1px] border-solid border-white/20",
            {
              hidden: !showMutedIcon,
            }
          )}
        />
      </div>
    </div>
  );
};
