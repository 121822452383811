import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import { useSearchInput, useTranslations } from "@jugl-web/utils";
import React, { useCallback, useMemo, useState } from "react";
import { SidebarDrawer } from "@jugl-web/ui-components/web";
import { getAllCountries, Country } from "countries-and-timezones";
import { Radio } from "@jugl-web/ui-components/cross-platform/Radio";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { ReactComponent as ChevronRightIcon } from "../../assets/chevron-right.svg";

export const RegionDialogPicker: React.FC<{
  region: Country;
  setRegion: (region: Country) => void;
}> = ({ region, setRegion }) => {
  const { t } = useTranslations();
  const [isOpen, setIsOpen] = useState(false);
  const { inputProps, reset, searchQuery } = useSearchInput();
  const regions = useMemo(() => getAllCountries(), []);
  const getRegion = useCallback(
    (regionName: string) => regions[regionName as keyof typeof regions],
    [regions]
  );
  const filteredRegions = useMemo(
    () =>
      Object.keys(regions).filter((regionName) =>
        getRegion(regionName)
          .name.toUpperCase()
          .includes(searchQuery.toUpperCase())
      ),
    [regions, searchQuery, getRegion]
  );
  const onRequestClose = () => {
    reset();
    setIsOpen(false);
  };
  return (
    <>
      <InteractiveContainer
        className="bg-grey-200 flex items-center justify-between rounded-lg p-4"
        onClick={() => setIsOpen(true)}
      >
        <div className="flex flex-col gap-4">
          <p className="m-0 text-sm text-[#828282]">
            {t({
              id: "settings-page.region",
              defaultMessage: "Region",
            })}
          </p>
          <p className="text-dark m-0">{region.name}</p>
        </div>
        <ChevronRightIcon />
      </InteractiveContainer>
      <SidebarDrawer
        isOpen={isOpen}
        onClose={onRequestClose}
        title={t({
          id: "settings-page.region",
          defaultMessage: "Region",
        })}
        onGoBack={onRequestClose}
      >
        <SidebarDrawer.Content className="overflow-y-auto p-10">
          <SearchInput variant="filled" color="primary" {...inputProps} />
          <div className="mt-5">
            {filteredRegions.map((reg, idx) => (
              <React.Fragment key={reg}>
                <InteractiveContainer
                  className="flex items-center gap-3"
                  onClick={() => setRegion(getRegion(reg))}
                >
                  <Radio
                    isChecked={region.name === getRegion(reg).name}
                    readOnly
                  />
                  <p className="text-dark">{getRegion(reg).name}</p>
                </InteractiveContainer>
                {idx !== Object.keys(regions).length - 1 && (
                  <div className="bg-grey-200 my-3 h-px w-full" />
                )}
              </React.Fragment>
            ))}
          </div>
        </SidebarDrawer.Content>
      </SidebarDrawer>
    </>
  );
};
