import {
  ListBoxItemComponent,
  ListBoxItemComponentProps,
} from "@jugl-web/ui-components/cross-platform/ListBox";
import { FC } from "react";
import { ReactComponent as WithoutUserIcon } from "./assets/without-user.svg";

interface WithoutUserListBoxItemProps
  extends Omit<ListBoxItemComponentProps, "label" | "startSlot"> {
  label: string;
}

export const WithoutUserListBoxItem: FC<WithoutUserListBoxItemProps> = ({
  label,
  ...props
}) => (
  <ListBoxItemComponent
    {...props}
    label={label}
    startSlot={<WithoutUserIcon />}
  />
);
