import { autoUpdate, useFloating } from "@floating-ui/react-dom";
import { EntityModel } from "@jugl-web/rest-api";
import {
  InteractiveContainer,
  Menu,
} from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { useSelectEntity } from "@web-src/features/app/hooks/useSelectEntity";
import { FC, useRef, useState } from "react";
import { ListItemCard } from "../ListItemCard";
import { ReactComponent as BellIcon } from "./assets/bell.svg";
import { ReactComponent as LeaveIcon } from "./assets/leave.svg";
import { ReactComponent as MenuIcon } from "./assets/menu.svg";
import { EntityUpdatesTooltip } from "./components/EntityUpdatesTooltip";

export interface EntityCardProps {
  entity: EntityModel;
  onLeaveEntity: () => void;
}

const TOOLTIP_APPEARANCE_DEBOUNCE_TIME_MS = 300;

export const EntityCard: FC<EntityCardProps> = ({ entity, onLeaveEntity }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const selectEntity = useSelectEntity();
  const tooltipTimeoutRef = useRef<number>();

  const { t } = useTranslations();

  const { refs, floatingStyles } = useFloating({
    placement: "right",
    transform: false,
    strategy: "fixed",
    whileElementsMounted: autoUpdate,
  });

  const handleMouseEnter = () => {
    if (isTooltipVisible) {
      window.clearTimeout(tooltipTimeoutRef.current);
    } else {
      tooltipTimeoutRef.current = window.setTimeout(() => {
        setIsTooltipVisible(true);
      }, TOOLTIP_APPEARANCE_DEBOUNCE_TIME_MS);
    }
  };

  const handleMouseLeave = () => {
    if (isTooltipVisible) {
      tooltipTimeoutRef.current = window.setTimeout(() => {
        setIsTooltipVisible(false);
      }, TOOLTIP_APPEARANCE_DEBOUNCE_TIME_MS);
    } else {
      window.clearTimeout(tooltipTimeoutRef.current);
    }
  };

  return (
    <>
      <div className="relative">
        <EntityUpdatesTooltip
          ref={refs.setFloating}
          isVisible={isTooltipVisible}
          entityId={entity.id}
          style={floatingStyles}
        />
        <ListItemCard
          avatarSrc={entity.logoImg}
          title={entity.name}
          subTitle={
            entity.subscriptionInfo.type === "expired" ? (
              <span className="text-red-500">Subscription expired</span>
            ) : undefined
          }
          onClick={() => selectEntity(entity)}
          rightContent={
            <div className="flex items-center gap-4">
              {entity.unread && (
                <InteractiveContainer
                  ref={refs.setReference}
                  className="hover:bg-dark-100 flex h-8 w-8 items-center justify-center rounded-lg bg-transparent transition-colors"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={(event) => event.stopPropagation()}
                >
                  <BellIcon />
                </InteractiveContainer>
              )}
              <Menu
                placement="bottom-end"
                sections={[
                  [
                    {
                      id: "leave-workspace",
                      label: t({
                        id: "entities-page.leave-workspace",
                        defaultMessage: "Leave workspace",
                      }),
                      icon: <LeaveIcon />,
                      onSelect: (_, close) => {
                        onLeaveEntity();
                        close();
                      },
                    },
                  ],
                ]}
                renderTrigger={({ Trigger, triggerRef }) => (
                  <Trigger
                    ref={triggerRef}
                    as={InteractiveContainer}
                    className="hover:bg-dark-100 flex h-8 w-8 items-center justify-center rounded-lg bg-transparent transition-colors"
                    onClick={(event) => event.stopPropagation()}
                  >
                    <MenuIcon />
                  </Trigger>
                )}
              />
            </div>
          }
        />
      </div>
    </>
  );
};
