import { createSlice, createSelector } from "@reduxjs/toolkit";
import uniqBy from "lodash/uniqBy";
import mergeWith from "lodash/mergeWith";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import isUndefined from "lodash/isUndefined";
import { PaginationItem } from "./types";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const mergeCustomizer = (b: unknown, a: unknown) => {
  if (isArray(a)) {
    return a;
  }
  return isObject(a) || isUndefined(a) ? undefined : a;
};

type PaginationState = {
  items: { [key: string]: PaginationItem[] };
  lastPageState: { [key: string]: unknown };
  loadingState: { [key: string]: boolean };
  initState: { [key: string]: boolean };
};

export type RootStateWithPagination = {
  libUtilsPagination: PaginationState;
};

export type PaginationListPosition = "start" | "end";

const getInitialState = (): PaginationState => ({
  items: {},
  loadingState: {},
  initState: {},
  lastPageState: {},
});

const paginationSlice = createSlice({
  name: "libUtilsPagination",
  initialState: getInitialState(),
  reducers: {
    addItems: (
      state,
      {
        payload: { paginationId, items, position = "end" },
      }: {
        payload: {
          paginationId: string;
          items: PaginationItem[];
          position?: PaginationListPosition;
        };
      }
    ) => {
      const itemsToUpdate = [
        ...(position === "start" ? items : []),
        ...(state.items[paginationId] || []),
        ...(position === "end" ? items : []),
      ];
      if (!state.initState[paginationId]) {
        state.initState[paginationId] = true;
      }
      state.items = {
        ...state.items,
        [paginationId]: uniqBy(itemsToUpdate, "id"),
      };
    },
    bumpItem: (
      state,
      {
        payload: { paginationId, itemId },
      }: {
        payload: {
          paginationId: string;
          itemId: string;
        };
      }
    ) => {
      let bumpedItem: PaginationItem | undefined;
      const restItems = state.items[paginationId]?.filter((item) => {
        const isBumpedItem = item.id === itemId;
        if (isBumpedItem) {
          bumpedItem = item;
        }
        return !isBumpedItem;
      });
      if (!bumpedItem) {
        return;
      }
      state.items = {
        ...state.items,
        [paginationId]: [bumpedItem, ...restItems],
      };
    },
    addOrUpdateItem: (
      state,
      {
        payload: {
          paginationId,
          item,
          position = "end",
          merge,
          skipIfNotInitialized = true,
        },
      }: {
        payload: {
          paginationId: string;
          item: PaginationItem;
          position?: PaginationListPosition;
          merge?: boolean;
          skipIfNotInitialized?: boolean;
        };
      }
    ) => {
      if (skipIfNotInitialized && !state.initState[paginationId]) {
        return;
      }
      const itemIdx = state.items[paginationId]?.findIndex(
        ({ id }) => item.id === id
      );
      if (!state.initState[paginationId]) {
        state.initState[paginationId] = true;
      }
      if (itemIdx !== -1) {
        const itemsToUpdate = [...(state.items[paginationId] || [])];
        itemsToUpdate[itemIdx] = merge
          ? mergeWith(itemsToUpdate[itemIdx], item, mergeCustomizer)
          : item;
        state.items = {
          ...state.items,
          [paginationId]: itemsToUpdate,
        };
        return;
      }
      const itemsToUpdate = [
        ...(position === "start" ? [item] : []),
        ...(state.items[paginationId] || []),
        ...(position === "end" ? [item] : []),
      ];
      state.items = {
        ...state.items,
        [paginationId]: uniqBy(itemsToUpdate, "id"),
      };
    },
    updateItem: (
      state,
      {
        payload: { paginationId, item, merge },
      }: {
        payload: {
          paginationId: string;
          item: PaginationItem;
          merge?: boolean;
        };
      }
    ) => {
      const itemsToUpdate = [...(state.items[paginationId] || [])];
      const itemIdx = state.items[paginationId]?.findIndex(
        ({ id }) => item.id === id
      );
      if (itemIdx === undefined || itemIdx === -1) {
        return;
      }
      itemsToUpdate[itemIdx] = merge
        ? mergeWith(itemsToUpdate[itemIdx], item, mergeCustomizer)
        : item;
      state.items = {
        ...state.items,
        [paginationId]: itemsToUpdate,
      };
    },
    changeItemId: (
      state,
      {
        payload: { paginationId, newId, oldId },
      }: {
        payload: {
          paginationId: string;
          newId: string;
          oldId: string;
        };
      }
    ) => {
      const itemsToUpdate = [...(state.items[paginationId] || [])];
      const itemIdx = state.items[paginationId]?.findIndex(
        ({ id }) => oldId === id
      );
      if (
        (itemIdx !== undefined && itemIdx === -1) ||
        !state.items[paginationId]
      ) {
        return;
      }
      const item = { ...state.items[paginationId][itemIdx] };
      item.id = newId;
      itemsToUpdate[itemIdx] = item;
      state.items = {
        ...state.items,
        [paginationId]: itemsToUpdate,
      };
    },
    deleteItem: (
      state,
      {
        payload: { paginationId, itemId },
      }: { payload: { paginationId: string; itemId: string } }
    ) => {
      const updatedItems = [...(state.items[paginationId] || [])].filter(
        ({ id }) => itemId !== id
      );
      state.items = {
        ...state.items,
        [paginationId]: updatedItems,
      };
    },
    resetItems: (
      state,
      { payload: { paginationId } }: { payload: { paginationId: string } }
    ) => {
      const itemsToSet = { ...state.items };
      if (itemsToSet[paginationId]) {
        delete itemsToSet[paginationId];
      }
      state.items = itemsToSet;
      state.initState[paginationId] = false;
    },
    setLastPageState: (
      state,
      {
        payload: { paginationId, pageState },
      }: { payload: { paginationId: string; pageState: unknown } }
    ) => {
      state.lastPageState[paginationId] = pageState;
    },
    setIsLoadingState: (
      state,
      {
        payload: { paginationId, isLoading },
      }: { payload: { paginationId: string; isLoading: boolean } }
    ) => {
      state.loadingState[paginationId] = isLoading;
    },
    setInitState: (
      state,
      {
        payload: { initState, paginationId },
      }: { payload: { initState: boolean; paginationId: string } }
    ) => {
      state.initState[paginationId] = initState;
    },
    resetAll: () => getInitialState(),
  },
});

export const selectPaginationItems = createSelector(
  (state: RootStateWithPagination) => state.libUtilsPagination.items,
  (_: unknown, paginationId: string) => paginationId,
  (items, paginationId) => items[paginationId]
);

export const selectPaginationIsLoading = createSelector(
  (state: RootStateWithPagination) => state.libUtilsPagination.loadingState,
  (_: unknown, paginationId: string) => paginationId,
  (isLoadingState, paginationId) => isLoadingState[paginationId]
);

export const selectPaginationLastPageState = createSelector(
  (state: RootStateWithPagination) => state.libUtilsPagination.lastPageState,
  (_: unknown, paginationId: string) => paginationId,
  (pageState, paginationId) => pageState[paginationId]
);

export const selectPaginationIsInit = createSelector(
  (state: RootStateWithPagination) => state.libUtilsPagination.initState,
  (_: unknown, paginationId: string) => paginationId,
  (isInitState, paginationId) => isInitState[paginationId]
);

export const {
  updateItem,
  addItems,
  deleteItem,
  setLastPageState,
  setIsLoadingState,
  resetItems,
  changeItemId,
  addOrUpdateItem,
  bumpItem,
  resetAll,
  setInitState,
} = paginationSlice.actions;

export default paginationSlice;
