import { cx, useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import { ReactComponent as ClearIcon } from "./icons/clear.svg";
import { ReactComponent as SearchIcon } from "./icons/search.svg";
import { BaseInputProps } from "./types";

interface FilledSearchInputProps extends BaseInputProps {
  color: "primary" | "grey" | "white";
}

const colorToClasses: Record<FilledSearchInputProps["color"], string> = {
  primary:
    "bg-primary/5 font-medium text-[#333333] placeholder:text-grey placeholder:font-normal",
  grey: "bg-grey-100 font-medium text-[#333333] placeholder:text-grey placeholder:font-normal",
  white:
    "bg-white font-medium text-[#333333] placeholder:text-grey placeholder:font-normal",
};

export const FilledSearchInput: FC<FilledSearchInputProps> = ({
  color,
  alwaysShowClear,
  className,
  containerClassName,
  onClear,
  ...inputProps
}) => {
  const { t } = useTranslations();

  const isClearButtonVisible = (onClear && inputProps.value) || alwaysShowClear;

  return (
    <div className={cx("relative", containerClassName)}>
      <SearchIcon className="pointer-events-none absolute left-4 top-1/2 -translate-y-1/2" />
      <input
        type="text"
        placeholder={`${t({
          id: "common.search",
          defaultMessage: "Search",
        })}...`}
        className={cx(
          "h-10 w-full rounded-lg border-none pl-11 pr-4 text-base leading-3 outline-none",
          isClearButtonVisible ? "pr-12" : "pr-4",
          colorToClasses[color],
          className
        )}
        {...inputProps}
      />
      {isClearButtonVisible && (
        <ClearIcon
          onClick={onClear}
          className="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer"
        />
      )}
    </div>
  );
};
