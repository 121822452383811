import { useRestApiProvider } from "@jugl-web/rest-api";
import { HookOutOfContextError, useToast } from "@jugl-web/utils";
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SubscriptionPlanModel } from "@jugl-web/rest-api/entities/models/SubscriptionPlan/SubscriptionPlanModel";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { PageLoaderFull } from "@jugl-web/ui-components";
import {
  UpdateCardAlert,
  UpdateCardAlertHandle,
  UpdateCardAlertResult,
} from "./components/UpdateCardAlert";
import { useEntityProvider } from "../EntityProvider";
import { SubscriptionUpdatedAlert } from "./components/SubscriptionUpdatedAlert";

const SHOULD_SHOW_SUBSCRIPTION_UPDATED_ALERT_KEY = `jugl::shouldShowSubscriptionUpdatedAlert`;

interface EntitySubscriptionContextValue {
  navigateToManageSubscription: () => void;
  updateCardDetails: () => Promise<UpdateCardAlertResult>;
  changeSubscription: (
    plan: SubscriptionPlanModel,
    seatsCount: number,
    promoCode?: string
  ) => void;
  applyAppSumoSubscription: (planId: string, codes: string[]) => void;
}

const EntitySubscriptionContext =
  createContext<EntitySubscriptionContextValue | null>(null);

export const EntitySubscriptionProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { navigateToPage } = useNavigation();
  const { entity, subscriptionInfo, refetchSubscriptionInfo } =
    useEntityProvider();
  const entityId = entity?.id;
  const { entitiesApi } = useRestApiProvider();

  const [isSubscriptionAlertOpen, setIsSubscriptionUpdatedPlan] =
    useState<boolean>(
      !!sessionStorage.getItem(SHOULD_SHOW_SUBSCRIPTION_UPDATED_ALERT_KEY)
    );
  useEffect(() => {
    sessionStorage.removeItem(SHOULD_SHOW_SUBSCRIPTION_UPDATED_ALERT_KEY);
  }, []);

  const { toast } = useToast({ variant: "web" });

  const updateCardAlertRef = useRef<UpdateCardAlertHandle>(null);

  const navigateToManageSubscription = useCallback(() => {
    navigateToPage("workspaceSelectSubscription");
  }, [navigateToPage]);

  const updateCardDetails = useCallback(async () => {
    if (!updateCardAlertRef.current) {
      throw new Error("Update card alert not rendered");
    }
    const result = await updateCardAlertRef.current.start();
    if (result.result === "success") {
      refetchSubscriptionInfo();
    }
    return result;
  }, [refetchSubscriptionInfo]);

  const [upgradeSubscription] = entitiesApi.useUpgradeSubscriptionMutation();
  const [subscriptionUpgradeInProgress, setSubscriptionUpgradeInProgress] =
    useState<boolean>(false);

  const applyAppSumoSubscription: EntitySubscriptionContextValue["applyAppSumoSubscription"] =
    useCallback(
      async (planId, promoCodes) => {
        if (!entityId) {
          return;
        }
        setSubscriptionUpgradeInProgress(true);
        const response = await upgradeSubscription({
          entityId,
          data: {
            price_id: planId,
            promo_code: promoCodes,
          },
        });
        const handleError = () => {
          setSubscriptionUpgradeInProgress(false);
          toast("Failed to upgrade subscription", { variant: "error" });
        };
        if ("error" in response && response.error) {
          handleError();
          return;
        }
        await new Promise((r) => {
          setTimeout(r, 3000);
        });
        sessionStorage.setItem(SHOULD_SHOW_SUBSCRIPTION_UPDATED_ALERT_KEY, "1");
        window.open(`/${entityId}/workspace/subscription`, "_self");
      },
      [entityId, toast, upgradeSubscription]
    );

  const changeSubscription: EntitySubscriptionContextValue["changeSubscription"] =
    useCallback(
      async (plan, seatsCount, promoCode) => {
        if (!entityId) {
          return;
        }
        if (plan.enforcePaymentMethod && !subscriptionInfo?.card) {
          const result = await updateCardDetails();
          if (result.result !== "success") {
            return;
          }
          setSubscriptionUpgradeInProgress(true);
          await new Promise((r) => {
            setTimeout(r, 2000);
          });
        } else {
          setSubscriptionUpgradeInProgress(true);
        }
        const response = await upgradeSubscription({
          entityId,
          data: {
            price_id: plan.id,
            promo_code: promoCode,
            additional_seats:
              seatsCount || plan.additionalSeatPriceId
                ? {
                    qty: seatsCount || 0,
                    id: plan.additionalSeatPriceId,
                  }
                : undefined,
          },
        });
        const handleError = () => {
          setSubscriptionUpgradeInProgress(false);
          toast("Failed to upgrade subscription", { variant: "error" });
        };
        if ("error" in response && response.error) {
          handleError();
          return;
        }
        if (
          "data" in response &&
          response.data.result === "action_required" &&
          entityId
        ) {
          const stripe = await loadStripe(response.data.pub_key);
          if (!stripe) {
            handleError();
            return;
          }
          const nextActionResult = await stripe?.handleNextAction({
            clientSecret: response.data.secret,
          });
          if (
            nextActionResult?.error ||
            nextActionResult?.paymentIntent?.status !== "succeeded"
          ) {
            handleError();
            return;
          }
        }
        await new Promise((r) => {
          setTimeout(r, 3000);
        });
        sessionStorage.setItem(SHOULD_SHOW_SUBSCRIPTION_UPDATED_ALERT_KEY, "1");
        window.open(`/${entityId}/workspace/subscription`, "_self");
      },
      [
        entityId,
        updateCardDetails,
        upgradeSubscription,
        subscriptionInfo,
        toast,
      ]
    );

  const value: EntitySubscriptionContextValue = useMemo(
    () => ({
      navigateToManageSubscription,
      updateCardDetails,
      changeSubscription,
      applyAppSumoSubscription,
    }),
    [
      navigateToManageSubscription,
      updateCardDetails,
      changeSubscription,
      applyAppSumoSubscription,
    ]
  );

  return (
    <EntitySubscriptionContext.Provider value={value}>
      {children}
      <PageLoaderFull isActive={subscriptionUpgradeInProgress} isTransparent />
      {entityId && (
        <UpdateCardAlert ref={updateCardAlertRef} entityId={entityId} />
      )}
      {subscriptionInfo && (
        <SubscriptionUpdatedAlert
          isOpen={isSubscriptionAlertOpen}
          subscriptionInfo={subscriptionInfo}
          onRequestClose={() => setIsSubscriptionUpdatedPlan(false)}
        />
      )}
    </EntitySubscriptionContext.Provider>
  );
};

export const useEntitySubscriptionProvider = () => {
  const context = useContext(EntitySubscriptionContext);

  if (!context) {
    throw new HookOutOfContextError(
      "useEntitySubscriptionProvider",
      "EntitySubscriptionContext"
    );
  }

  return context;
};
