import { cx } from "@jugl-web/utils";
import React, { ReactNode, forwardRef } from "react";

export type ButtonVariant = "contained" | "outlined" | "text";
export type ButtonSize = "regular" | "small";
export type ButtonColor =
  | "primary"
  | "secondary"
  | "tertiary"
  | "grey"
  | "white";

type VariantAndColorToClasses = Record<
  ButtonVariant,
  Record<ButtonColor, string>
>;

const variantAndColorToClasses: VariantAndColorToClasses = {
  contained: {
    primary: cx(
      "border-none bg-primary text-white fill-white",
      "hover:bg-primary-800",
      "disabled:hover:bg-primary"
    ),
    secondary: cx(
      "border-none bg-secondary text-white fill-white",
      "hover:bg-secondary-800",
      "disabled:hover:bg-secondary"
    ),
    tertiary: cx(
      "border-none bg-tertiary text-white fill-white",
      "hover:bg-tertiary-800",
      "disabled:hover:bg-tertiary"
    ),
    grey: cx(
      "border-none bg-grey-200 text-dark fill-white",
      "hover:bg-grey-300",
      "disabled:hover:bg-grey-200"
    ),
    white: cx(
      "border-none bg-white text-dark fill-white",
      "hover:bg-grey-100",
      "disabled:hover:bg-white"
    ),
  },
  outlined: {
    primary: cx(
      "bg-transparent border border-solid border-primary text-primary fill-primary",
      "hover:border-primary-800 hover:text-primary-800 hover:fill-primary-800",
      "disabled:hover:border-primary disabled:hover:text-primary disabled:hover:fill-primary"
    ),
    secondary: cx(
      "bg-transparent border border-solid border-secondary text-secondary fill-secondary",
      "hover:border-secondary-800 hover:text-secondary-800 hover:fill-secondary-800",
      "disabled:hover:border-secondary disabled:hover:text-secondary disabled:hover:fill-secondary"
    ),
    tertiary: cx(
      "bg-transparent border border-solid border-tertiary text-tertiary fill-tertiary",
      "hover:border-tertiary-800 hover:text-tertiary-800 hover:fill-tertiary-800",
      "disabled:hover:border-tertiary disabled:hover:text-tertiary disabled:hover:fill-tertiary"
    ),
    grey: cx(
      "bg-transparent border border-solid border-grey text-grey fill-grey",
      "hover:border-grey-800 hover:text-grey-800 hover:fill-grey-800",
      "disabled:hover:border-tertiary disabled:hover:text-tertiary disabled:hover:fill-tertiary"
    ),
    white: cx(
      "bg-transparent border border-solid border-white text-grey fill-grey",
      "hover:border-grey-800 hover:text-grey-800 hover:fill-grey-800",
      "disabled:hover:border-tertiary disabled:hover:text-tertiary disabled:hover:fill-tertiary"
    ),
  },
  text: {
    primary: cx(
      "bg-transparent border-none bg-none text-primary fill-primary",
      "hover:text-primary-800 hover:fill-primary-800",
      "disabled:hover:text-primary disabled:hover:fill-primary"
    ),
    secondary: cx(
      "bg-transparent border-none bg-none text-secondary fill-secondary",
      "hover:text-secondary-800 hover:fill-secondary-800",
      "disabled:hover:text-secondary disabled:hover:fill-secondary"
    ),
    tertiary: cx(
      "bg-transparent border-none bg-none text-tertiary fill-tertiary",
      "hover:text-tertiary-800 hover:fill-tertiary-800",
      "disabled:hover:text-tertiary disabled:hover:fill-tertiary"
    ),
    grey: cx(
      "bg-transparent border-none bg-none text-grey fill-grey",
      "hover:text-grey-800 hover:fill-grey-800",
      "disabled:hover:text-grey disabled:hover:fill-grey"
    ),
    white: cx(
      "bg-transparent border-none bg-none text-white fill-white",
      "hover:text-grey-100 hover:fill-grey-100",
      "disabled:hover:text-white disabled:hover:fill-white"
    ),
  },
};

const sizeToClasses: Record<ButtonSize, string> = {
  regular: "py-[15px] px-5",
  small: "py-2 px-4",
};

type NativeButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export interface ButtonProps extends Omit<NativeButtonProps, "disabled"> {
  children?: ReactNode;
  color?: ButtonColor;
  variant?: ButtonVariant;
  size?: ButtonSize;
  uppercase?: boolean;
  isDisabled?: boolean;
  fullWidth?: boolean;
  iconStart?: React.ReactNode;
  iconEnd?: React.ReactNode;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      color = "primary",
      variant = "contained",
      size = "regular",
      uppercase,
      isDisabled,
      fullWidth,
      iconStart,
      iconEnd,
      className,
      ...props
    },
    ref
  ) => (
    <button
      ref={ref}
      className={cx(
        "leading-2 inline-flex cursor-pointer select-none items-center justify-center gap-2 rounded-lg font-[Roboto] text-sm font-semibold transition-colors",
        "disabled:cursor-not-allowed disabled:opacity-80",
        uppercase && "uppercase",
        fullWidth && "w-full",
        variantAndColorToClasses[variant][color],
        sizeToClasses[size],
        className
      )}
      disabled={isDisabled}
      type="button"
      {...props}
    >
      {iconStart}
      {children}
      {iconEnd}
    </button>
  )
);

export default Button;
