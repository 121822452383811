import { PlainButton } from "@jugl-web/ui-components/cross-platform";
import { cx } from "@jugl-web/utils";
import { ButtonHTMLAttributes, ReactNode, forwardRef } from "react";

export const HeaderButton = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement> & { icon: ReactNode }
>(({ icon, className, ...restProps }, ref) => (
  <PlainButton
    className={cx(
      "bg-grey-100 h-10 w-10 rounded-lg hover:brightness-90",
      className
    )}
    {...restProps}
    ref={ref}
  >
    {icon}
  </PlainButton>
));
