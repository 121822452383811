import { cx } from "@jugl-web/utils";
import { ReactNode } from "react";

export interface SectionProps {
  children: ReactNode;
  className?: string;
}

export const Section = ({ children, className }: SectionProps) => (
  <div
    className={cx(
      "border-b-dark-100 border-b-4 border-t-0 border-l-0 border-r-0 border-solid bg-white p-8 last:grow last:border-b-0",
      className
    )}
  >
    {children}
  </div>
);
