import React, { memo } from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { ButtonBase } from "@mui/material";
import {
  getFileTypeByUrl,
  isImageUrl,
  isVideoUrl,
} from "@web-src/utils/helper";
import FileAttachment from "@web-src/components/FileAttachment";

const ChatInputFilePreview: React.FC<{
  file: File;
  isLoading: boolean;
  onRemoveClick: () => void;
}> = memo(({ file, isLoading, onRemoveClick }) => {
  const fileType = file && getFileTypeByUrl(file?.name);
  const isAttachmentVideoOrAudio =
    file && (isVideoUrl(file.name) || isImageUrl(file.name));
  return (
    <Box sx={{ position: "relative", marginTop: "8px" }}>
      {fileType && ["image", "video"].includes(fileType) ? (
        <Box
          sx={{
            width: "160px",
            height: isAttachmentVideoOrAudio ? "100px" : "50px",
            border: `1px solid`,
            borderColor: "grey.300",
            borderRadius: "10px",
            position: "relative",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {fileType === "video" ? (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video
              src={URL.createObjectURL(file)}
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          ) : (
            <img
              src={URL.createObjectURL(file)}
              alt={file?.name}
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          )}
          {!isLoading && (
            <ButtonBase
              onClick={onRemoveClick}
              sx={{
                width: "25px",
                height: "25px",
                borderRadius: "12.5px",
                position: "absolute",
                top: "5px",
                right: "5px",
                backgroundColor: "#000",
                color: "white",
                fontSize: "12px",
                padding: "5px",
                zIndex: 1000,
              }}
            >
              <CloseIcon />
            </ButtonBase>
          )}
        </Box>
      ) : (
        <FileAttachment
          url={file?.name}
          onRemoveFile={onRemoveClick}
          isLink={undefined}
          fileName={undefined}
        />
      )}
    </Box>
  );
});

export default ChatInputFilePreview;
