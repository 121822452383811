import { taskChecklistNameValidator } from "@jugl-web/domain-resources/tasks";
import { useTaskMentions } from "@jugl-web/domain-resources/tasks/hooks/useTaskMentions";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { DetailedTask } from "@jugl-web/rest-api/tasks";
import { Checkbox } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { FC, useRef, useState } from "react";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { TABLE_DIMENSIONS } from "../../consts";
import { ChecklistItemNumber } from "../ChecklistItemNumber";
import { TaskTableLayout } from "../TaskTableLayout";
import { TextCellEditor, TextCellEditorHandle } from "../TextCellEditor";

interface NewTaskChecklistItemRowProps {
  task: DetailedTask;
  entityId: string;
  meId: string;
}

export const NewTaskChecklistItemRow: FC<NewTaskChecklistItemRowProps> = ({
  task,
  entityId,
  meId,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const $textCellEditorRef = useRef<TextCellEditorHandle | null>(null);

  const { mentions } = useTaskMentions({
    entityId,
    meId,
    taskAssigneeIds: task.assignees,
    taskCreatorId: task.created_by,
  });

  const { tasksApi } = useRestApiProvider();

  const [addChecklistItemMutation, { isLoading }] =
    tasksApi.useAddChecklistItemMutation();

  const { t } = useTranslations();

  const handleSubmit = async (richText: string) => {
    try {
      await addChecklistItemMutation({
        entityId,
        taskId: task.id,
        item: {
          order: task.checklist.length,
          name: richText,
          user_id: null,
          due_at: null,
        },
      });

      setIsEditing(false);
    } catch {
      // Do nothing
    }
  };

  if (!isEditing) {
    return (
      <TaskTableLayout.Row
        isHoverable
        height={TABLE_DIMENSIONS.TABLE_TASK_NEW_ITEM_ROW_HEIGHT}
        onClick={() => setIsEditing(true)}
      >
        <TaskTableLayout.OutermostEmptyCell />
        <TaskTableLayout.Cell hasMarginLine shouldGrow onClick={() => {}}>
          <div className="flex items-center gap-2.5">
            <PlusIcon />
            <TaskTableLayout.CellLabel>
              {t({
                id: "tasks-page.add-checklist-item",
                defaultMessage: "Add Checklist item",
              })}
            </TaskTableLayout.CellLabel>
          </div>
        </TaskTableLayout.Cell>
      </TaskTableLayout.Row>
    );
  }

  return (
    <TaskTableLayout.Row
      style={{
        minHeight: TABLE_DIMENSIONS.TABLE_TASK_CHECKLIST_ITEM_ROW_HEIGHT,
      }}
    >
      <TaskTableLayout.OutermostEmptyCell />
      <TaskTableLayout.Cell
        isFocused
        hasMarginLine
        width={TABLE_DIMENSIONS.TABLE_TASK_TITLE_COLUMN_WIDTH}
        className="cursor-text justify-between"
        onClick={() => $textCellEditorRef.current?.focus()}
      >
        <div className="flex grow items-center gap-2">
          <Checkbox isDisabled readOnly className="mr-0.5 h-[18px] w-[18px]" />
          <ChecklistItemNumber index={task.checklist.length} />
          <TextCellEditor
            ref={$textCellEditorRef}
            mentions={mentions}
            placeholder={t({
              id: "form-controls.task-checklist.placeholder",
              defaultMessage: "Enter checklist title",
            })}
            isDisabled={isLoading}
            validator={taskChecklistNameValidator}
            onSubmit={handleSubmit}
            onCancel={() => setIsEditing(false)}
          />
        </div>
      </TaskTableLayout.Cell>
      <TaskTableLayout.Cell shouldGrow />
    </TaskTableLayout.Row>
  );
};
