import isNil from "lodash/isNil";
import { Fragment, ReactNode } from "react";

export const joinReactNodes = (nodes: ReactNode[], separator: ReactNode) => (
  <>
    {nodes.map((node, index) => (
      <Fragment key={+index}>
        {node}
        {!isNil(nodes[index + 1]) && separator}
      </Fragment>
    ))}
  </>
);

export const joinReactNodesInHumanReadableForm = (nodes: ReactNode[]) => {
  if (nodes.length === 1) {
    return nodes[0];
  }

  if (nodes.length === 2) {
    return joinReactNodes(nodes, " and ");
  }

  const headNodes = nodes.slice(0, -1);
  const tailNode = nodes[nodes.length - 1];

  return joinReactNodes([joinReactNodes(headNodes, ", "), tailNode], ", and ");
};
