import { useTranslations } from "@jugl-web/utils";
import React, { useMemo } from "react";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { Step } from "@jugl-web/ui-components/web";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { UserRole, useRestApiProvider } from "@jugl-web/rest-api";
import useEntity from "@web-src/features/app/hooks/useEntity";
import buildTeamImage from "./assets/build-team.jpg";
import profilePicImage from "./assets/profile-pic.jpg";
import firstTaskImage from "./assets/first-task.jpg";
import drivePossibilitiesImage from "./assets/drive-possibilities.jpg";
import firstMessageImage from "./assets/first-message.jpg";
import { GetStartedDialogStep } from "../../types";

type OnboardingStep = Step & {
  title: string;
  type: GetStartedDialogStep;
  description: string;
  image: React.ReactNode;
  onStart: () => void;
};

export const useOnboardingSteps = () => {
  const { t } = useTranslations();
  const { navigateToPage } = useNavigation();
  const { me } = useMe();
  const { usersApi } = useRestApiProvider();
  const { entity } = useEntity();
  const { data: onboardingStatus } = usersApi.useGetGlobalPreferencesQuery(
    undefined,
    { skip: !entity?.id }
  );

  const steps: OnboardingStep[] = useMemo(
    () => [
      ...(me?.role === UserRole.admin
        ? ([
            {
              id: "people",
              type: "people",
              isCompleted: !!onboardingStatus?.people,
              label: t({
                id: "get-started-dialog-component.build-team",
                defaultMessage: "Build a Team!",
              }),
              title: `${t({
                id: "get-started-dialog-component.build-team",
                defaultMessage: "Build a Team!",
              })} 🔥`,
              description: t({
                id: "get-started-dialog-component.add-people-to-workspace",
                defaultMessage: "Add other people to Workspace",
              }),
              image: (
                <img
                  src={buildTeamImage}
                  alt="Build a Team!"
                  className="w-full"
                />
              ),
              onStart: () => navigateToPage("workspaceMembers"),
            },
          ] as OnboardingStep[])
        : []),
      {
        id: "profile",
        type: "profile",
        isCompleted: !!onboardingStatus?.profile,
        label: t({
          id: "get-started-dialog-component.set-profile-pic",
          defaultMessage: "Set up your Profile pic!",
        }),
        title: `${t({
          id: "get-started-dialog-component.set-profile-pic",
          defaultMessage: "Set up your Profile pic!",
        })} 🔥`,
        description: `${t({
          id: "get-started-dialog-component.profile-picture.recognition",
          defaultMessage:
            "It's always easier to recognize someone by Profile Picture!",
        })} 😍`,
        image: (
          <img
            src={profilePicImage}
            alt="Set up your Profile pic!"
            className="w-full"
          />
        ),
        onStart: () =>
          navigateToPage("settingsEditProfile", { section: "personal-info" }),
      },
      {
        id: "task",
        type: "task",
        isCompleted: !!onboardingStatus?.task,
        label: t({
          id: "get-started-dialog-component.create-first-task",
          defaultMessage: "Create your first Task!",
        }),
        title: t({
          id: "get-started-dialog-component.create-first-task",
          defaultMessage: "Create your first Task!",
        }),
        description: `${t({
          id: "get-started-dialog-component.easy-task-management",
          defaultMessage: "Task management never was this easy!",
        })} 🚀`,
        image: (
          <img
            src={firstTaskImage}
            alt="Create your first Task!"
            className="w-full"
          />
        ),
        onStart: () => navigateToPage("tasksTasks"),
      },
      {
        id: "drive",
        type: "drive",
        isCompleted: !!onboardingStatus?.drive,
        label: t({
          id: "get-started-dialog-component.discover-drive-possibilities",
          defaultMessage: "Discover Drive possibilities!",
        }),
        title: t({
          id: "get-started-dialog-component.discover-drive-possibilities",
          defaultMessage: "Discover Drive possibilities!",
        }),
        description: `${t({
          id: "get-started-dialog-component.create-new-folder-or-upload-files",
          defaultMessage:
            "Let's create a new folder or upload some files to the Drive!",
        })} 📂`,
        image: (
          <img
            src={drivePossibilitiesImage}
            alt="Discover Drive possibilities!"
            className="w-full"
          />
        ),
        onStart: () => navigateToPage("driveDrive"),
      },
      {
        id: "chat",
        type: "chat",
        isCompleted: !!onboardingStatus?.chat,
        label: t({
          id: "get-started-dialog-component.write-first-message",
          defaultMessage: "Write your first message!",
        }),
        title: t({
          id: "get-started-dialog-component.communication-key",
          defaultMessage: "Communication is a Key!",
        }),
        description: `${t({
          id: "get-started-dialog-component.write-first-message-in-chat",
          defaultMessage: "Write your first message in the Chat!",
        })} 🤗`,
        image: (
          <img
            src={firstMessageImage}
            alt="Write your first message!"
            className="w-full"
          />
        ),
        onStart: () => navigateToPage("chatsChats"),
      },
    ],
    [onboardingStatus, navigateToPage, t, me]
  );

  const completedSteps = useMemo(
    () => steps.filter((step) => step.isCompleted).length,
    [steps]
  );

  return { steps, completedSteps };
};
