import { Breadcrumb } from "@jugl-web/ui-components/web/DrawerHeader";
import {
  Screen,
  ScreenTransitionManagerProvider,
  useScreenTransitionManager,
} from "@jugl-web/utils/utils/ScreenTransitionManager";
import { FC, useMemo } from "react";
import { useTranslations } from "@jugl-web/utils";
import { TaskFormDialog } from "../TaskFormDialog";
import {
  TemplatePreviewDialogProps,
  TemplatePreviewDialogScreenToParametersMap,
} from "./types";
import { TemplatePreviewDialogContent } from "./components/TemplatePreviewDialogContent";

const INITIAL_SCREEN: Screen<TemplatePreviewDialogScreenToParametersMap> = {
  name: "templatePreview",
};

const InnerTemplatePreviewDialog: FC<TemplatePreviewDialogProps> = ({
  isOpen,
  templateId,
  onClose,
  ...props
}) => {
  const { screen, renderContent, transitionTo, transitionRequest$ } =
    useScreenTransitionManager<TemplatePreviewDialogScreenToParametersMap>();

  const { t } = useTranslations();

  const breadcrumbs = useMemo(() => {
    const rootBreadcrumb: Breadcrumb = {
      id: "templatePreview",
      title: t({
        id: "tasks-page.task-template",
        defaultMessage: "Task template",
      }),
      onClick:
        screen.name !== "templatePreview"
          ? () => transitionTo({ name: "templatePreview" })
          : undefined,
    };

    return renderContent<Breadcrumb[]>({
      templatePreview: [rootBreadcrumb],
      taskCreation: [
        rootBreadcrumb,
        {
          id: "taskCreation",
          title: t({
            id: "tasks-page.create-task",
            defaultMessage: "Create task",
          }),
        },
      ],
    });
  }, [renderContent, screen.name, transitionTo, t]);

  const handleSafeClose = () => {
    if (transitionRequest$.observed) {
      transitionRequest$.next({ resolve: onClose });
    } else {
      onClose();
    }
  };

  const handleResetState = () => {
    if (!isOpen) {
      transitionTo(INITIAL_SCREEN, { force: true });
    }
  };

  return (
    <TaskFormDialog
      isOpen={isOpen}
      header={{
        type: "breadcrumbs",
        breadcrumbs,
      }}
      onClose={handleSafeClose}
      onTransitionEnd={handleResetState}
    >
      <TemplatePreviewDialogContent
        key={templateId}
        isOpen={isOpen}
        templateId={templateId}
        onClose={onClose}
        {...props}
      />
    </TaskFormDialog>
  );
};

export const TemplatePreviewDialog: FC<TemplatePreviewDialogProps> = (
  props
) => {
  const initialScreen = useMemo<
    Screen<TemplatePreviewDialogScreenToParametersMap>
  >(() => ({ name: "templatePreview" }), []);

  return (
    <ScreenTransitionManagerProvider initialScreen={initialScreen}>
      <InnerTemplatePreviewDialog {...props} />
    </ScreenTransitionManagerProvider>
  );
};
