import {
  Button,
  InteractiveContainer,
} from "@jugl-web/ui-components/cross-platform";
import { Radio } from "@jugl-web/ui-components/cross-platform/Radio";
import { SidebarDrawer } from "@jugl-web/ui-components/web";
import { cx, useTranslations } from "@jugl-web/utils";
import React, { useState } from "react";
import { getSoundName } from "../../utils/getSoundName";

export const NotificationsSoundSidebar: React.FC<{
  isOpen: boolean;
  notificationSoundId: number;
  onNotificationSoundIdChange: (notificationSoundId: number) => void;
  onRequestClose: () => void;
}> = ({
  isOpen,
  notificationSoundId,
  onNotificationSoundIdChange,
  onRequestClose,
}) => {
  const { t } = useTranslations();
  const [selectedSoundId, setSelectedSoundId] = useState(notificationSoundId);
  const onClose = () => {
    onRequestClose();
    setSelectedSoundId(notificationSoundId);
  };
  return (
    <SidebarDrawer
      title={t({
        id: "settings-page.sound",
        defaultMessage: "Sound",
      })}
      onClose={onClose}
      onGoBack={onClose}
      isOpen={isOpen}
      hasBackdrop={false}
    >
      <SidebarDrawer.Content className="p-6">
        <div className="mb-6 text-[#828282]">
          {t({
            id: "settings-page.system-sounds",
            defaultMessage: "System sounds",
          })}
        </div>
        <div className="flex flex-col gap-1">
          {Array(13)
            .fill("")
            .map((_, idx) => (
              <div key={+idx}>
                <InteractiveContainer
                  className={cx(
                    "flex items-center gap-2 rounded-lg p-4",
                    selectedSoundId === idx && "bg-grey-100"
                  )}
                  onClick={() => {
                    new Audio(
                      `/notification-sounds/notification${
                        idx !== 0 ? idx : ""
                      }.mp3`
                    ).play();
                    setSelectedSoundId(idx);
                  }}
                >
                  <Radio isChecked={selectedSoundId === idx} readOnly />
                  {t(getSoundName(idx), { notificationSoundId: idx })}
                </InteractiveContainer>
                {idx !== 12 && (
                  <div className="bg-grey-200 my-0.5 h-px w-full" />
                )}
              </div>
            ))}
        </div>
      </SidebarDrawer.Content>
      <SidebarDrawer.Actions>
        <Button
          fullWidth
          uppercase
          onClick={() => {
            onRequestClose();
            onNotificationSoundIdChange(selectedSoundId);
          }}
        >
          {t({
            id: "common.save",
            defaultMessage: "Save",
          })}
        </Button>
      </SidebarDrawer.Actions>
    </SidebarDrawer>
  );
};
