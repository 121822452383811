import { FC } from "react";
import { Alert, Text } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import Lottie from "react-lottie";
import notFoundAnimation from "./assets/not-found-animation.json";

export const ExpiredInvitationAlert: FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const { t } = useTranslations();

  return (
    <Alert
      isCloseButtonVisible
      isOpen={isOpen}
      onRequestClose={onClose}
      img={
        <Lottie
          options={{ animationData: notFoundAnimation }}
          height={262}
          width={300}
        />
      }
      header={t({
        id: "entity-select-page.expired-invitation",
        defaultMessage: "Expired Invitation",
      })}
      title={t({
        id: "entity-select-page.invitation-expired-title",
        defaultMessage: "Ooops! Invite was not Found",
      })}
      content={
        <Text variant="body2" className="text-dark-800">
          {t({
            id: "entity-select-page.expired-invitation-description",
            defaultMessage:
              "It looks like this invite has been removed by Admin or is no longer relevant",
          })}
        </Text>
      }
      buttons={[
        {
          text: t({ id: "common.okay", defaultMessage: "Okay" }),
          color: "primary",
          onClick: onClose,
        },
      ]}
    />
  );
};
