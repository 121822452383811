export const MENTIONS_REGEXP = /@<([^>]+?)>\[([^\]]+?)\]/g;

export const mentionifyHtml = (str: string) =>
  str.replaceAll(
    MENTIONS_REGEXP,
    (e) =>
      `<span class="text-primary-500 font-bold">${
        [...e.matchAll(MENTIONS_REGEXP)][0][2]
      }</span>`
  );
