import {
  Button,
  Dialog,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import React from "react";
import { useTranslations } from "@jugl-web/utils";
import { ReactComponent as InfoBlueIcon } from "./assets/info-blue.svg";
import { ReactComponent as CloseIcon } from "../../assets/close.svg";

export const InfoDialog: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const { t } = useTranslations();
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      className="rounded-lg py-10 px-24"
    >
      <PlainButton onClick={onClose} className="absolute right-4 top-4">
        <CloseIcon />
      </PlainButton>
      <div className="flex items-center justify-center gap-2">
        <InfoBlueIcon />
        <p className="text-dark text-lg font-medium">
          {t({
            id: "settings-page.location-tracker-time-zone",
            defaultMessage: "Location Tracker Time Zone",
          })}
        </p>
      </div>
      <ul className="marker:text-dark-700 mx-auto max-w-[500px] space-y-4 overflow-hidden">
        <li className="text-dark-700 text-center text-sm">
          {t({
            id: "settings-page.use-time-zone",
            defaultMessage:
              "Location Tracker Time Zone will be used for proper work of Location Tracking function",
          })}
        </li>
        <li className="text-dark-700 text-center text-sm">
          {t({
            id: "settings-page.default-time-zone",
            defaultMessage:
              "By default your Time Zone is set automatically based on system. You can change it any time",
          })}
        </li>
      </ul>
      <div className="mx-auto mt-10 w-[265px]">
        <Button uppercase fullWidth onClick={onClose}>
          {t({
            id: "common..got-it",
            defaultMessage: "Got it",
          })}
        </Button>
      </div>
    </Dialog>
  );
};
