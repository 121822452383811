import { PlainButton } from "@jugl-web/ui-components/cross-platform";
import { Tooltip } from "@jugl-web/ui-components/cross-platform/Tooltip";
import { cx } from "@jugl-web/utils";
import { FC, ReactNode } from "react";

interface TopPanelButtonProps {
  icon: ReactNode;
  hint?: string;
  isGhost?: boolean;
  onClick: () => void;
}

export const TopPanelButton: FC<TopPanelButtonProps> = ({
  icon,
  hint,
  isGhost = false,
  onClick,
}) => (
  <Tooltip
    isDisabled={!hint}
    className="z-[1400] mt-1 shrink-0 px-4 py-2"
    renderTrigger={({ ref, props }) => (
      <PlainButton
        ref={ref}
        className={cx(
          "flex h-10 w-10 items-center justify-center rounded-lg",
          !isGhost && "bg-white/5 transition-colors hover:bg-white/20"
        )}
        onClick={onClick}
        {...props}
      >
        {icon}
      </PlainButton>
    )}
  >
    {hint}
  </Tooltip>
);
