import { Pill, PillProps } from "@jugl-web/ui-components/cross-platform/Pill";
import { PlainButton } from "@jugl-web/ui-components/cross-platform/PlainButton";
import { cx } from "@jugl-web/utils";
import { FC } from "react";
import { ReactComponent as PillRemoveIcon } from "../../assets/pill-remove.svg";

type FilterPillProps = Pick<
  PillProps,
  "label" | "startIcon" | "isActive" | "className"
> & {
  onClick?: () => void;
  onRemove?: () => void;
};

export const LoadingFilterPill = () => (
  <Pill
    size="md"
    label={
      <div className="flex animate-pulse items-center gap-1.5">
        <div
          style={{
            background:
              "linear-gradient(90deg, #D8D8D8 0%, #EAEAEA 100%), #D9D9D9",
          }}
          className="h-4 w-4 rounded-full"
        />
        <div
          style={{
            background:
              "linear-gradient(90deg, #D8D8D8 0%, #EAEAEA 100%), linear-gradient(90deg, #E1E1E1 0%, #EBEBEB 100%)",
          }}
          className="h-2.5 w-[80px] rounded-lg"
        />
      </div>
    }
    className="h-8 py-0"
  />
);

export const FilterPill: FC<FilterPillProps> = ({
  label,
  startIcon,
  isActive,
  className,
  onClick,
  onRemove,
}) => {
  const isInteractive = !!onClick;

  return (
    <Pill
      size="md"
      label={label}
      startIcon={startIcon}
      isActive={isActive}
      className={cx(
        "h-8 py-0",
        isInteractive && "hover:bg-dark-100 cursor-pointer",
        className
      )}
      endIcon={
        onRemove ? (
          <PlainButton
            className="rounded-full transition-colors hover:bg-white"
            onClick={onRemove}
          >
            <PillRemoveIcon />
          </PlainButton>
        ) : undefined
      }
      onClick={onClick}
    />
  );
};
