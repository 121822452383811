import { DEFAULT_APP_SUMO_PLAN_NAME } from "@jugl-web/rest-api";
import { SubscriptionPlanModel } from "@jugl-web/rest-api/entities/models/SubscriptionPlan/SubscriptionPlanModel";
import { SubscriptionIntervalType } from "@jugl-web/rest-api/entities/models/common-types/SubscriptionIntervalType";
import {
  DataLoadingWrapper,
  MultiSectionLayout,
} from "@jugl-web/ui-components/cross-platform";
import { cx } from "@jugl-web/utils";
import { useIntercom } from "@web-src/components/IntercomProvider";
import { FC, useCallback, useMemo } from "react";
import {
  SelectSubscriptionFormProvider,
  useSelectSubscriptionFormProvider,
} from "./SelectSubscriptionFormProvider";
import { PlanItem } from "./components/PlanItem";
import { SummarySideBar } from "./components/SummarySideBar/SummarySideBar";

const SelectSubscriptionFormContent: FC = () => {
  const {
    updateAdditionalSeats,
    selectedPlan,
    selectPlan,
    selectSubscriptionInterval,
    selectedSubscriptionInterval,
    plans,
    plansIsError,
    plansIsLoading,
    plansRefetch,
    isAppSumoMode,
  } = useSelectSubscriptionFormProvider();
  const { toggle: toggleIntercomWidget } = useIntercom();

  const subscriptionIntervals: {
    label: string;
    value: SubscriptionIntervalType;
    annotation?: string;
  }[] = useMemo(
    () => [
      {
        label: "Monthly",
        value: "month",
      },
      { label: "Yearly", value: "year", annotation: "save 20%" },
    ],
    []
  );

  const visiblePlans = useMemo(
    () =>
      plans?.filter(
        (item) => item.intervalType === selectedSubscriptionInterval
      ) || [],
    [selectedSubscriptionInterval, plans]
  );

  const handleSelectPlan = useCallback(
    (plan: SubscriptionPlanModel) => {
      if (plan.planType === "enterprise") {
        toggleIntercomWidget();
        return;
      }
      selectPlan(plan);
    },
    [selectPlan, toggleIntercomWidget]
  );

  return (
    <DataLoadingWrapper
      className="h-full"
      isLoading={plansIsLoading}
      isError={plansIsError}
      onRetry={plansRefetch}
    >
      <MultiSectionLayout className="relative" rightSide={<SummarySideBar />}>
        <div className={cx("relative px-8 py-12")}>
          <div className="flex items-center justify-center">
            <div
              className={cx(
                "flex h-11 items-center rounded-[22px] bg-[#EDF2F4] px-1",
                { "opacity-50": isAppSumoMode }
              )}
            >
              {subscriptionIntervals.map((item) => {
                const isSelected = item.value === selectedSubscriptionInterval;
                return (
                  <div
                    key={item.value}
                    onClick={() =>
                      isAppSumoMode
                        ? null
                        : selectSubscriptionInterval(item.value)
                    }
                    className={cx(
                      "flex h-9 cursor-pointer items-center justify-center rounded-full px-8 transition-all",
                      {
                        "shadow-[0px 2px 16px 0px rgba(0, 0, 0, 0.06) bg-white":
                          isSelected,
                      }
                    )}
                  >
                    <span className={cx({ "font-medium": isSelected })}>
                      {item.label}
                    </span>
                    {item.annotation ? (
                      <div className="ml-2 rounded bg-white px-2 py-0.5 text-xs font-semibold text-red-500">
                        {item.annotation}
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mt-8 flex justify-center gap-2.5">
            {visiblePlans.map((plan) => (
              <div
                key={plan.id}
                className={cx({
                  "opacity-50": isAppSumoMode && selectedPlan?.id !== plan.id,
                })}
              >
                <PlanItem
                  plan={
                    isAppSumoMode && selectedPlan?.id === plan.id
                      ? { ...plan, name: DEFAULT_APP_SUMO_PLAN_NAME }
                      : plan
                  }
                  onSelect={isAppSumoMode ? () => null : handleSelectPlan}
                  onRequestAdditionalSeats={updateAdditionalSeats}
                  isSelected={selectedPlan?.id === plan.id}
                />
              </div>
            ))}
          </div>
        </div>
      </MultiSectionLayout>
    </DataLoadingWrapper>
  );
};

export const SelectSubscriptionForm: FC = () => (
  <SelectSubscriptionFormProvider>
    <SelectSubscriptionFormContent />
  </SelectSubscriptionFormProvider>
);
