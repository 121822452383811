import { TaskDefaultStatus } from "@jugl-web/rest-api/tasks";
import { useTranslations } from "@jugl-web/utils";
import { ReactNode, useMemo } from "react";

export const useTaskDefaultStatuses = () => {
  const { t } = useTranslations();

  return useMemo(
    (): {
      id: TaskDefaultStatus;
      label: string;
      tip?: ReactNode;
    }[] => [
      {
        id: TaskDefaultStatus.notStarted,
        label: t({
          id: "tasks-page.status-pending",
          defaultMessage: "Pending",
        }),
      },
      {
        id: TaskDefaultStatus.inProgress,
        label: t({
          id: "tasks-page.status-in-progress",
          defaultMessage: "In progress",
        }),
      },
      {
        id: TaskDefaultStatus.completed,
        label: t({
          id: "tasks-page.status-completed",
          defaultMessage: "Completed",
        }),
        tip: (
          <span>
            {t(
              {
                id: "tasks-page.default-statuses-order-hint",
                defaultMessage:
                  "All statuses, including both default and custom, will always appear before <b>Completed</b> one. Completed status represents the final stage of a task",
              },
              { b: (text: (string | JSX.Element)[]) => <b>{text}</b> }
            )}
          </span>
        ),
      },
    ],
    [t]
  );
};
