import {
  LiveUpdateEvent,
  TASK_COMMENT_MODULE,
  TASK_MODULE,
} from "../../../live-updates";
import {
  ATTACHMENT_ADDED_ACTION,
  ATTACHMENT_DELETED_ACTION,
  ATTACHMENT_RENAMED_ACTION,
  CREATED_ACTION,
  DELETED_ACTION,
  TaskCommentLiveUpdateCreatedEvent,
  TaskCommentLiveUpdateDeletedEvent,
  TaskCommentLiveUpdateEvent,
  TaskCommentLiveUpdateUpdatedEvent,
  TaskLiveUpdateAttachmentAddedEvent,
  TaskLiveUpdateAttachmentDeletedEvent,
  TaskLiveUpdateAttachmentRenamedEvent,
  TaskLiveUpdateCreatedEvent,
  TaskLiveUpdateDeletedEvent,
  TaskLiveUpdateEvent,
  TaskLiveUpdateUpdatedEvent,
  UPDATED_ACTION,
} from "./types";

// #region Task module type guards
export const isTaskLiveUpdateEvent = (
  event: LiveUpdateEvent
): event is TaskLiveUpdateEvent => event.module === TASK_MODULE;

export const isTaskCreatedEvent = (
  event: TaskLiveUpdateEvent
): event is TaskLiveUpdateCreatedEvent => event.action === CREATED_ACTION;

export const isTaskUpdatedEvent = (
  event: TaskLiveUpdateEvent
): event is TaskLiveUpdateUpdatedEvent => event.action === UPDATED_ACTION;

export const isTaskDeletedEvent = (
  event: TaskLiveUpdateEvent
): event is TaskLiveUpdateDeletedEvent => event.action === DELETED_ACTION;

export const isTaskAttachmentAddedEvent = (
  event: TaskLiveUpdateEvent
): event is TaskLiveUpdateAttachmentAddedEvent =>
  event.action === ATTACHMENT_ADDED_ACTION;

export const isTaskAttachmentRenamedEvent = (
  event: TaskLiveUpdateEvent
): event is TaskLiveUpdateAttachmentRenamedEvent =>
  event.action === ATTACHMENT_RENAMED_ACTION;

export const isTaskAttachmentDeletedEvent = (
  event: TaskLiveUpdateEvent
): event is TaskLiveUpdateAttachmentDeletedEvent =>
  event.action === ATTACHMENT_DELETED_ACTION;
// #endregion

// #region Task comment module type guards
export const isTaskCommentLiveUpdateEvent = (
  event: LiveUpdateEvent
): event is TaskCommentLiveUpdateEvent => event.module === TASK_COMMENT_MODULE;

export const isTaskCommentCreatedEvent = (
  event: TaskCommentLiveUpdateEvent
): event is TaskCommentLiveUpdateCreatedEvent =>
  event.action === CREATED_ACTION;

export const isTaskCommentUpdatedEvent = (
  event: TaskCommentLiveUpdateEvent
): event is TaskCommentLiveUpdateUpdatedEvent =>
  event.action === UPDATED_ACTION;

export const isTaskCommentDeletedEvent = (
  event: TaskCommentLiveUpdateEvent
): event is TaskCommentLiveUpdateDeletedEvent =>
  event.action === DELETED_ACTION;
// #endregion
