import { cx } from "@jugl-web/utils";
import { FC, Fragment, ReactNode } from "react";
import { PlainButton } from "../../cross-platform/PlainButton";
import { ReactComponent as ArrowLeftIcon } from "./assets/arrow-left.svg";
import { ReactComponent as ChevronRightIcon } from "./assets/chevron-right.svg";
import { ReactComponent as CloseIcon } from "./assets/close.svg";
import { DrawerHeaderNavButton } from "./components/DrawerHeaderNavButton";

export interface TitleDrawerHeaderProps {
  type: "title";
  title: ReactNode;
  subtitle?: ReactNode;
  onGoBack?: () => void;
}

export interface Breadcrumb {
  id: string;
  title: string;
  subtitle?: ReactNode;
  onClick?: () => void;
}

export interface BreadcrumbsDrawerHeaderProps {
  type: "breadcrumbs";
  breadcrumbs: Breadcrumb[];
}

interface BaseDrawerHeaderProps {
  navigationSlots?: ReactNode[];
  onClose: () => void;
}

export type DrawerHeaderProps = BaseDrawerHeaderProps &
  (TitleDrawerHeaderProps | BreadcrumbsDrawerHeaderProps);

export const DRAWER_HEADER_HEIGHT_PX = 60;

export const DrawerHeader: FC<DrawerHeaderProps> = ({
  navigationSlots = [],
  onClose,
  ...props
}) => (
  <header
    className="relative z-10 flex shrink-0 items-center justify-between px-8"
    style={{
      height: DRAWER_HEADER_HEIGHT_PX,
      boxShadow: "2px -6px 24px rgba(18, 22, 34, 0.16)",
    }}
  >
    {props.type === "title" && (
      <div className="flex items-center gap-2.5">
        {props.onGoBack && (
          <PlainButton onClick={props.onGoBack}>
            <ArrowLeftIcon />
          </PlainButton>
        )}
        <div className="flex items-center gap-2">
          <span className="text-dark max-w-[250px] truncate text-base font-semibold leading-4 tracking-[0.16px]">
            {props.title}
          </span>
          {props.subtitle && (
            <span className="text-grey-700 text-sm font-normal tracking-[0.14px]">
              {props.subtitle}
            </span>
          )}
        </div>
      </div>
    )}
    {props.type === "breadcrumbs" && (
      <div className="flex items-center gap-4">
        {props.breadcrumbs.map((breadcrumb, index, array) => {
          const isLast = index === array.length - 1;
          const isInteractive = !!breadcrumb.onClick;

          return (
            <Fragment key={breadcrumb.id}>
              <div className="flex items-center gap-2">
                <span
                  className={cx(
                    "text-dark max-w-[250px] truncate text-base leading-4 tracking-[0.16px]",
                    isLast && "font-semibold",
                    isInteractive && "cursor-pointer"
                  )}
                  {...(isInteractive
                    ? { onClick: breadcrumb.onClick, role: "button" }
                    : undefined)}
                >
                  {breadcrumb.title}
                </span>
                {breadcrumb.subtitle && (
                  <span className="text-grey-700 text-sm font-normal tracking-[0.14px]">
                    {breadcrumb.subtitle}
                  </span>
                )}
              </div>
              {!isLast && <ChevronRightIcon />}
            </Fragment>
          );
        })}
      </div>
    )}
    <div className="flex items-center gap-6">
      {navigationSlots.length > 0 &&
        navigationSlots.map((slot, index) => (
          <Fragment key={+index}>{slot}</Fragment>
        ))}
      <DrawerHeaderNavButton onClick={onClose}>
        <CloseIcon />
      </DrawerHeaderNavButton>
    </div>
  </header>
);
