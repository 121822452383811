import React from "react";
import { ReactComponent as CirclesIcon } from "./assets/circles.svg";
import { ReactComponent as LeftGradientIcon } from "./assets/left-gradient.svg";
import { ReactComponent as RightGradientIcon } from "./assets/right-gradient.svg";

export const PromoBackground: React.FC = () => (
  <div className="relative h-full w-full overflow-hidden bg-[#05162F]">
    <CirclesIcon className="absolute left-[5%] top-1/2 w-[90%] -translate-y-1/2" />
    <LeftGradientIcon className="absolute -bottom-1/3 -left-[10%] -rotate-45" />
    <RightGradientIcon className="absolute -top-1/3 right-0" />
  </div>
);
