import LinkifyReact from "linkify-react";
import { FC, ReactNode } from "react";

export interface LinkifyProps {
  children: ReactNode;
}

const TRUNCATED_LINK_LENGTH = 100;

export const Linkify: FC<LinkifyProps> = ({ children }) => (
  <LinkifyReact
    options={{
      className: "text-primary-500 no-underline hover:underline",
      target: "_blank",
      truncate: TRUNCATED_LINK_LENGTH,
      rel: "noreferrer",
    }}
  >
    {children}
  </LinkifyReact>
);
