import React, { memo, PropsWithChildren } from "react";
import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as Exit } from "@web-src/images/icons/close.svg";
import { ReactComponent as ArrowIcon } from "@web-src/images/icons/arrow-left.svg";
import { dark, grey, white } from "@web-src/themes/colors";

export type InlineSideBarProps = {
  onRequestClose?: () => void;
  footer?: React.ReactNode;
  title?: string;
  hasBackButton?: boolean;
  width?: number | string;
};

export const InlineSideBar: React.FC<PropsWithChildren<InlineSideBarProps>> = ({
  children,
  onRequestClose,
  footer,
  title,
  hasBackButton,
  width,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: "100%",
        overflowY: "auto",
        width: width || "40%",
        minWidth: width || 400,
        maxWidth: width || 400,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderLeft: `1px solid ${theme.palette.grey["200"]}`,
        background: theme.palette.grey[200],
      }}
    >
      <Box
        sx={{
          backgroundColor: white[500],
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          overflowX: "clip",
        }}
      >
        {title && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              height: "80px",
              px: "32px",
              backgroundColor: white[500],
              borderBottom: `1px solid ${theme.palette.grey[200]}`,
            }}
          >
            <Stack direction="row" alignItems="center">
              {hasBackButton && (
                <IconButton
                  disableRipple
                  onClick={onRequestClose}
                  sx={{ paddingRight: "16px" }}
                >
                  <ArrowIcon />
                </IconButton>
              )}
              <Typography color={dark.A100} variant="h4">
                {title}
              </Typography>
            </Stack>
            <IconButton onClick={onRequestClose} disableRipple>
              <SvgIcon
                viewBox="0 0 24 24"
                component={Exit}
                sx={{ color: grey.A100 }}
              />
            </IconButton>
          </Stack>
        )}
        <Box sx={{ flex: 1, overflowY: "auto", overflowX: "hidden" }}>
          {children}
        </Box>
        {footer && (
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing="10px"
            sx={{
              backgroundColor: "white",
              display: "flex",
              alignItems: "flex-end",
              padding: "21px 30px 21px 31px",
            }}
          >
            {footer}
          </Stack>
        )}
      </Box>
    </Box>
  );
};

const SideBar: React.FC<
  PropsWithChildren<{
    isOpen: boolean;
    onRequestClose?: () => void;
    footer?: React.ReactNode;
    title?: string;
    hasBackButton?: boolean;
    width?: number | string;
    leftContent?: React.ReactNode;
  }>
> = ({ children, isOpen, leftContent, ...inlineSideBarParams }) => (
  <Modal
    open={isOpen}
    onClose={inlineSideBarParams.onRequestClose}
    sx={{ zIndex: 40 }}
  >
    <Box
      sx={{
        position: "absolute",
        right: 0,
        height: "100%",
      }}
    >
      {leftContent ? (
        <Stack sx={{ height: "100%" }} direction="row">
          <Box sx={{ height: "100%" }}>{leftContent}</Box>
          <InlineSideBar {...inlineSideBarParams}>{children}</InlineSideBar>
        </Stack>
      ) : (
        <InlineSideBar {...inlineSideBarParams}>{children}</InlineSideBar>
      )}
    </Box>
  </Modal>
);

export default memo(SideBar);
