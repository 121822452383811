export const downloadBlobAsFile = (blob: Blob, filename: string) => {
  const fileUrl = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = fileUrl;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  URL.revokeObjectURL(fileUrl);
};
