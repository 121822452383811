import { PageNavParams } from "@web-src/modules/navigation/types";
import { ModulePagesConfig } from "@web-src/modules/navigation/types/ModulePagesConfigs";
import SettingsPage from "./SettingsPage";
import { EditProfilePage, EditProfileSection } from "./EditProfilePage";
import { MainSettings } from "./MainSettings";

export type SettingsPagesNavigationParams = {
  settingsMainSettings: PageNavParams;
  settingsSettings: PageNavParams;
  settingsEditProfile: PageNavParams<{
    section: EditProfileSection;
  }>;
};

export const settingsPagesNavigationConfig: ModulePagesConfig<SettingsPagesNavigationParams> =
  {
    settingsMainSettings: {
      element: <MainSettings />,
      path: "settings/main-settings",
      isPrivate: true,
    },
    settingsSettings: {
      element: <SettingsPage />,
      path: "settings",
      isPrivate: true,
    },
    settingsEditProfile: {
      element: <EditProfilePage />,
      path: "settings/edit-profile/:section",
      isPrivate: true,
    },
  };
