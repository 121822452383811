import {
  Button,
  Dialog,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import React from "react";
import { useTranslations } from "@jugl-web/utils";
import { ChatBotReceivedMessage } from "../../types";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import { downloadReport, getImageSrc } from "../../utils";
import { ReactComponent as DownloadIcon } from "../../icons/download.svg";

export const ReportDialog: React.FC<{
  isOpen: boolean;
  message?: ChatBotReceivedMessage;
  onClose: () => void;
}> = ({ isOpen, onClose, message }) => {
  const { t } = useTranslations();
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      className="px-28 pt-14 pb-10 text-center"
    >
      <PlainButton onClick={onClose} className="absolute right-4 top-4">
        <CloseIcon />
      </PlainButton>
      <h3 className="text-dark m-0 mb-4 text-lg font-medium leading-[22px]">
        {t({
          id: "tasks-page.report",
          defaultMessage: "Report",
        })}
      </h3>
      <img
        src={getImageSrc(message)}
        alt="report"
        className="max-h-[50vh] max-w-[50vw]"
      />
      <div className="mt-10 flex items-center justify-center gap-[30px]">
        <Button className="w-64" uppercase color="grey" onClick={onClose}>
          {t({
            id: "tasks-page.go-back",
            defaultMessage: "Go back",
          })}
        </Button>
        <Button
          className="w-64"
          uppercase
          onClick={() =>
            downloadReport(
              `data:${message?.filetype};base64, ${message?.base64}`,
              message?.file_name || ""
            )
          }
          iconStart={<DownloadIcon />}
        >
          {t({
            id: "tasks-page.download-report",
            defaultMessage: "Download Report",
          })}
        </Button>
      </div>
    </Dialog>
  );
};
