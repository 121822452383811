import React from "react";
import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";

const LoginMethodButton: React.FC<{
  title: string;
  icon: React.ReactNode;
  onClick: () => void;
}> = ({ title, icon, onClick }) => (
  <InteractiveContainer
    className="bg-grey-100 text-grey-800 flex w-full items-center justify-center gap-2 rounded-lg p-2 text-xs hover:brightness-95"
    onClick={onClick}
  >
    {icon}
    {title}
  </InteractiveContainer>
);

export default LoginMethodButton;
