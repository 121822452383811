import React, { useState, useMemo } from "react";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { Pill } from "@jugl-web/ui-components/cross-platform";
import { Text } from "@jugl-web/ui-components/cross-platform/Text";
import { SidebarDrawer } from "@jugl-web/ui-components/web/SidebarDrawer";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { cx, useToast, useTranslations } from "@jugl-web/utils";
import { useRestApiProvider } from "@jugl-web/rest-api";
import FieldChip from "./components/FieldChip";
import { ReactComponent as PlusIcon } from "./assets/plus.svg";
import { AddNewFieldDialog } from "./components/CustomerFormSideBar";
import { sortFormFields } from "../../utils/sortFormFields";

export const CustomizeFieldsDialog: React.FC<{
  isOpen: boolean;
  onGoBack?: () => void;
  onClose: () => void;
}> = ({ isOpen, onGoBack, onClose }) => {
  const { customersFormFieldApi } = useRestApiProvider();
  const { entity } = useEntity();
  const { data: formFields } = customersFormFieldApi.useFormFieldsQuery(
    entity?.id ? { entityId: entity.id } : skipToken
  );
  const { t } = useTranslations();

  const [deleteFormField] = customersFormFieldApi.useDeleteFormFieldMutation();
  const [fieldIdsToDelete, addFieldIdToDelete] = useState<string[]>([]);
  const [isAddNewFieldOpen, setAddNewFieldOpen] = useState(false);
  const [sectionToOpen, setSectionToOpen] = useState<"personal" | "company">(
    "personal"
  );
  const { toast } = useToast({ variant: "web" });
  const onAddToRemove = (fieldId: string) => {
    if (fieldIdsToDelete.includes(fieldId)) {
      addFieldIdToDelete([
        ...fieldIdsToDelete.filter((field) => field !== fieldId),
      ]);
    } else {
      addFieldIdToDelete((fields) => [...fields, fieldId]);
    }
  };

  const handleSave = async () => {
    if (!entity) {
      return;
    }
    const response = await Promise.all(
      fieldIdsToDelete.map((field) =>
        deleteFormField({ entityId: entity?.id, fieldId: field })
      )
    );
    if (response) {
      addFieldIdToDelete([]);
      toast(
        t({
          id: "feedback.customer-fields-updated",
          defaultMessage: "Customer fields have been updated",
        })
      );
      if (onGoBack) {
        onGoBack();
      } else {
        onClose();
      }
    }
  };
  const handleOpenAddField = (section: "personal" | "company") => {
    setSectionToOpen(section);
    setAddNewFieldOpen(true);
  };

  const sortedFields = useMemo(
    () => sortFormFields(formFields?.data || []),
    [formFields?.data]
  );

  return (
    <SidebarDrawer
      isOpen={isOpen}
      title={t({
        id: "customers-page.customize-fields",
        defaultMessage: "Customize Fields",
      })}
      onClose={onClose}
      onGoBack={onGoBack}
    >
      <SidebarDrawer.Content className="px-8 py-6">
        <AddNewFieldDialog
          isOpen={isAddNewFieldOpen}
          section={sectionToOpen}
          onClose={onClose}
          onGoBack={() => setAddNewFieldOpen(false)}
        />
        {["personal", "company"].map((section) => (
          <>
            <div
              className={cx("flex flex-row items-center justify-between", {
                "mt-10": section === "company",
              })}
            >
              <Text variant="body3" className="capitalize">
                {section === "personal"
                  ? t({
                      id: "customers-page.personal-info",
                      defaultMessage: "personal Info",
                    })
                  : t({
                      id: "customers-page.company-info",
                      defaultMessage: "Company Info",
                    })}
              </Text>
              <div className="bg-grey-200 rounded-lg">
                <div
                  onClick={() =>
                    handleOpenAddField(section as "personal" | "company")
                  }
                  className="cursor-pointer"
                >
                  <Pill
                    label={t({
                      id: "common.add",
                      defaultMessage: "Add",
                    })}
                    size="sm"
                    endIcon={<PlusIcon />}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 flex flex-col gap-3">
              {sortedFields.map(
                (field) =>
                  field.section === section && (
                    <FieldChip
                      key={field.id}
                      type={field.type}
                      name={field.name}
                      isAddedToDelete={fieldIdsToDelete.includes(field.id)}
                      onRemove={
                        field.is_cust_field
                          ? () => onAddToRemove(field.id)
                          : undefined
                      }
                    />
                  )
              )}
            </div>
          </>
        ))}
      </SidebarDrawer.Content>
      <SidebarDrawer.Actions className="flex w-full flex-col gap-4">
        <Button className="uppercase" onClick={handleSave}>
          {t({
            id: "common.save",
            defaultMessage: "Save",
          })}
        </Button>
        <Button
          variant="contained"
          className="bg-grey-600/10 text-dark-800 uppercase hover:bg-slate-600/20"
          onClick={onGoBack}
        >
          {t({
            id: "common.cancel",
            defaultMessage: "Cancel",
          })}
        </Button>
      </SidebarDrawer.Actions>
    </SidebarDrawer>
  );
};
