import React, { useCallback, useEffect, useMemo, useState } from "react";
import { locationApi } from "@web-src/features/api/createApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { format, utcToZonedTime } from "date-fns-tz";
import { parseISO, subDays, addDays } from "date-fns";
import { useParams } from "react-router-dom";
import { TabRoute } from "@web-src/features/app/types";
import { HeaderBreadcrumbs } from "@jugl-web/ui-components/web/HeaderBreadcrumbs";
import { Event } from "@jugl-web/rest-api/location/types";
import { useTranslations } from "@jugl-web/utils";
import useEntityParticipants from "@web-src/features/app/hooks/useEntityParticipants";
import { addZ } from "@web-src/utils/addZ";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { ReactComponent as CalendarIcon } from "./assets/calendar.svg";
import EventDetailsList from "./components/EventDetailsList";
import LocationMap from "./components/LocationMap";

type Context = {
  setLocationToFocus: (location: { lat: number; lon: number }) => void;
};
export const LocationContext = React.createContext<Context>({
  setLocationToFocus: () => {},
});

export const LocationPage: React.FC = () => {
  const [locationToFocus, setLocationToFocus] = useState<{
    lat: number;
    lon: number;
  }>();
  const { t } = useTranslations();
  const { entity } = useEntity();
  const { participantsMap } = useEntityParticipants(entity?.id);
  const [eventsList, setEventsList] = useState<Event[]>([]);
  const [isLocationDetailsOpen, setIsLocationDetailsOpen] =
    useState<boolean>(false);
  const [selectedLocationDate, setSelectedLocationDate] = useState<
    string | undefined
  >();
  const params = useParams();
  const userData = params.userId && participantsMap[params.userId];
  const { usersApi } = useRestApiProvider();
  const { data: profile } = usersApi.useGetUserGeneralProfileQuery(
    params.userId && params.entityId
      ? {
          entityId: params.entityId,
          params: { user_id: params.userId },
        }
      : skipToken
  );

  const scheduleLink = `/${entity?.id}/${TabRoute.schedule}/${params.date}`;

  const username = useMemo(
    () => `${profile?.firstName || ""} ${profile?.lastName || ""}`,
    [profile]
  );

  const getTimeForRequest = (
    selectedRequestDate: string,
    dayStart: boolean
  ) => {
    const startOfDayDate = `${format(
      subDays(parseISO(selectedRequestDate), 1),
      "yyyy-MM-dd"
    )}T00:00:00`;
    const endOfDayDate = `${format(
      addDays(parseISO(selectedRequestDate), 1),
      "yyyy-MM-dd"
    )}T23:59:59`;
    const newDate = dayStart ? startOfDayDate : endOfDayDate;
    return newDate;
  };

  const { data: summaryData } = locationApi.useUserSummaryQuery(
    entity && params.userId && selectedLocationDate
      ? {
          entity_id: entity.id,
          user_id: params.userId,
          from: getTimeForRequest(selectedLocationDate, true),
          to: getTimeForRequest(selectedLocationDate, false),
          page: 1,
          page_size: 100,
        }
      : skipToken
  );

  const [getEventsList] = locationApi.useLazyDetailedListQuery();
  const { data: detailsData, isFetching: isDetailsDataFetching } =
    locationApi.useDetailedListQuery(
      entity && params.userId && selectedLocationDate
        ? {
            entityId: entity.id,
            user_id: params.userId,
            from_time: getTimeForRequest(selectedLocationDate, true),
            to_time: getTimeForRequest(selectedLocationDate, false),
            page: 1,
            page_size: 20,
          }
        : skipToken
    );
  const filterEvents = useCallback(
    (events: Event[]) => {
      if (!selectedLocationDate) return events;
      const dayEvents = events.filter((element) =>
        format(
          utcToZonedTime(
            addZ(element.start_time),
            (userData && userData?.timezone) || ""
          ),
          "yyyy-MM-dd"
        ).includes(selectedLocationDate)
      );
      return dayEvents;
    },
    [selectedLocationDate, userData]
  );
  useEffect(() => {
    if (detailsData?.data && detailsData?.total_pages === 1) {
      setEventsList(filterEvents(detailsData.data));
    }
    if (detailsData?.total_pages && detailsData?.total_pages > 1) {
      (async () => {
        let list: Event[] = detailsData?.data || [];
        const reqParams = {
          entityId: entity?.id || "",
          user_id: params.userId || "",
          from_time: getTimeForRequest(selectedLocationDate || "", true),
          to_time: getTimeForRequest(selectedLocationDate || "", false),
          page_size: 20,
        };
        for (let i = 2; i <= detailsData.total_pages; i += 1) {
          // eslint-disable-next-line no-await-in-loop
          const additionalRequest = await getEventsList({
            ...reqParams,
            page: i,
          });
          if (additionalRequest?.data?.data) {
            list = list.concat(additionalRequest.data.data);
          }
        }
        setEventsList(filterEvents(list));
      })();
    }
  }, [
    filterEvents,
    detailsData,
    entity?.id,
    getEventsList,
    params.userId,
    selectedLocationDate,
  ]);

  const handleOpenLocationDetails = (date: string) => {
    setSelectedLocationDate(date);
    setIsLocationDetailsOpen(true);
  };

  const contextValue = useMemo(
    () => ({
      setLocationToFocus,
    }),
    [setLocationToFocus]
  );
  useEffect(() => {
    if (params.date) {
      handleOpenLocationDetails(params.date);
    }
  }, [params.date]);
  return (
    <LocationContext.Provider value={contextValue}>
      <div className="h-full overflow-hidden">
        <HeaderBreadcrumbs
          icon={<CalendarIcon />}
          items={[
            {
              title: t({
                id: "location-page.schedule",
                defaultMessage: "Schedule",
              }),
              href: scheduleLink,
            },
            {
              title: `${username}: ${t({
                id: "location-page.map-timeline",
                defaultMessage: "Map Timeline",
              })}`,
            },
          ]}
        />
        <div className="bg-grey-200 flex flex-row">
          <div className="bg-grey-200 flex h-[calc(100vh_-_60px)] min-w-[483px] flex-col items-center justify-center">
            {isLocationDetailsOpen && (
              <EventDetailsList
                list={eventsList}
                key={selectedLocationDate}
                summaryData={summaryData}
                isFetching={isDetailsDataFetching}
                timezone={(userData && userData?.timezone) || ""}
              />
            )}
          </div>
          <div>
            <LocationMap
              events={eventsList}
              locationToFocus={locationToFocus}
              timezone={(userData && userData?.timezone) || ""}
            />
          </div>
        </div>
      </div>
    </LocationContext.Provider>
  );
};
