import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { Text } from "@jugl-web/ui-components/cross-platform/Text";
import { SidebarDrawer } from "@jugl-web/ui-components/web/SidebarDrawer";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { useForm, FieldValues, FieldError } from "react-hook-form";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { AvatarSelect } from "@jugl-web/ui-components/web";
import { useToast, useTranslations } from "@jugl-web/utils";
import { ReactComponent as LogoPlaceholderIcon } from "./assets/logo-placeholder.svg";
import CustomerFormField from "../CustomerFormField/CustomerFormField";
import { CustomizeFieldsDialog } from "../CustomizeFieldsDialog/CustomizeFieldsDialog";
import { sortFormFields } from "../../utils/sortFormFields";

export const ManageCustomerDialog: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  customerId?: string;
}> = ({ isOpen, onClose, customerId }) => {
  const { customersApi, customersFormFieldApi } = useRestApiProvider();
  const { t } = useTranslations();
  const { toast } = useToast({ variant: "web" });
  const { entity } = useEntity();
  const { data: customer } = customersApi.useCustomerQuery(
    entity?.id && customerId ? { entityId: entity.id, customerId } : skipToken
  );
  const [addNewCustomer] = customersApi.useAddCustomerMutation();
  const [updateCustomer] = customersApi.useUpdateCustomerMutation();
  const [logoFile, setLogoFile] = useState<File>();
  const [isCustomizeFieldsOpen, setCustomizeFieldsOpen] = useState(false);

  const { data: formFields } = customersFormFieldApi.useFormFieldsQuery(
    entity?.id ? { entityId: entity.id } : skipToken
  );

  const {
    reset,
    resetField,
    control,
    watch,
    handleSubmit,
    register,
    formState: { errors, isValid, dirtyFields },
  } = useForm({ mode: "onChange" });
  const formParams = watch();
  useEffect(() => {
    if (customerId) {
      const values: { [key: string]: string | object } = {};
      customer?.data.fields?.forEach((item) => {
        if (item.value) {
          values[item.field.id] = item.value;
        }
      });
      reset(values);
    } else {
      reset({});
      setLogoFile(undefined);
    }
  }, [customer, reset, isOpen, customerId]);

  const processCustomerData = (data: FieldValues) => {
    const dataKeys = Object.keys(data);
    return dataKeys
      .filter((key: string) => {
        const fieldData = formFields?.data.find(
          (formField) => formField.id === key
        );
        return fieldData;
      })
      .map((key: string) => {
        const fieldData = formFields?.data.find(
          (formField) => formField.id === key
        );
        if (fieldData?.type === "mobile" && data[key]) {
          const [, , number] = data[key].split(",");
          if (!number) {
            return { field_id: key, value: null };
          }
        }
        return {
          field_id: key,
          value: data[key]?.trim() || null,
        };
      });
  };
  const onSubmit = async (data: FieldValues) => {
    if (entity && data) {
      const newCustomer = processCustomerData(data);
      const response = await addNewCustomer({
        entityId: entity.id,
        fields: { fields: newCustomer },
        logoFile,
      });

      if (response && "data" in response) {
        toast(
          t({
            id: "feedback.customer-added",
            defaultMessage: "Customer has been added",
          })
        );
        onClose();
        reset({});
      }
    }
  };
  const onUpdate = async (data: FieldValues) => {
    const dirtyData = Object.fromEntries(
      Object.entries(data).filter(([key]) => dirtyFields[key])
    );

    const hasAnyDirtyField = Object.keys(dirtyData).length > 0;
    const hasLogoChanged = !!logoFile;

    if (!hasAnyDirtyField && !hasLogoChanged) {
      onClose();
      return;
    }

    if (entity && data && customerId) {
      const newCustomer = processCustomerData(dirtyData);
      const response = await updateCustomer({
        customerId,
        entityId: entity.id,
        fields: { fields: newCustomer },
        logoFile,
      });

      if (response && "data" in response) {
        toast(
          t({
            id: "feedback.customer-updated",
            defaultMessage: "Customer has been updated",
          })
        );
        onClose();
        reset({});
      }
    }
  };
  const onLogoSave = (value?: File) => {
    if (value) {
      setLogoFile(value);
    }
  };

  const isFieldInvalid = useCallback(
    (fieldId: string) => fieldId in errors,
    [errors]
  );
  const sortedFields = useMemo(
    () => sortFormFields(formFields?.data || []),
    [formFields]
  );

  return (
    <SidebarDrawer
      isOpen={isOpen}
      title={
        customerId
          ? t({
              id: "customers-page.edit-client",
              defaultMessage: "Edit Client",
            })
          : t({
              id: "customers-page.add-new-client",
              defaultMessage: "Add New Client",
            })
      }
      onClose={() => {
        onClose();
        reset({});
      }}
    >
      <SidebarDrawer.Content className="relative">
        <div className="border-bottom border-grey-100 border-b-1 flex flex-col items-center gap-3 border-t-0 border-l-0 border-r-0 border-solid py-6">
          <div className="border-grey-200 rounded-full border-solid">
            <AvatarSelect
              value={logoFile}
              onSave={onLogoSave}
              defaultSrc={(customerId && customer?.data?.logo) || undefined}
              placeholder={<LogoPlaceholderIcon />}
            />
          </div>
          <Text variant="body3">
            {t({
              id: "customers-page.upload-logo",
              defaultMessage: "Upload Company/User Logo",
            })}
          </Text>
        </div>
        <div className="px-8 py-6">
          <div className="mb-3 flex flex-row items-center justify-between">
            <Text variant="body2" className="font-medium">
              {t({
                id: "customers-page.contact-info",
                defaultMessage: "Contact Info",
              })}
            </Text>
          </div>
          <div className="flex flex-col gap-6">
            {sortedFields?.map((field) => (
              <CustomerFormField
                key={field.id}
                field={field}
                section="personal"
                register={register}
                control={control}
                value={formParams[field.id]}
                invalid={isFieldInvalid(field.id)}
                error={errors[field.id] as FieldError}
                resetField={resetField}
              />
            ))}
          </div>
          <div className="mt-3 mb-3 flex flex-row items-center justify-between">
            <Text variant="body2" className="font-medium">
              {t({
                id: "customers-page.company-info",
                defaultMessage: "Company Info",
              })}
            </Text>
          </div>
          <div className="flex flex-col gap-6">
            {sortedFields?.map((field) => (
              <CustomerFormField
                key={field.id}
                field={field}
                section="company"
                register={register}
                control={control}
                value={formParams[field.id]}
                invalid={isFieldInvalid(field.id)}
                error={errors[field.id] as FieldError}
                resetField={resetField}
              />
            ))}
          </div>
        </div>
      </SidebarDrawer.Content>
      <SidebarDrawer.Actions className="flex w-full flex-col gap-4">
        <Button
          className="uppercase"
          onClick={customerId ? handleSubmit(onUpdate) : handleSubmit(onSubmit)}
          isDisabled={!isValid}
        >
          {customerId
            ? t({
                id: "customers-page.update-client",
                defaultMessage: "Update client",
              })
            : t({
                id: "customers-page.add-client",
                defaultMessage: "Add client",
              })}
        </Button>
        <Button
          variant="contained"
          className="bg-grey-800/10 text-dark-800 uppercase hover:bg-slate-800/20"
          onClick={() => setCustomizeFieldsOpen(true)}
        >
          {t({
            id: "customers-page.customize-fields",
            defaultMessage: "Customize Fields",
          })}
        </Button>
      </SidebarDrawer.Actions>
      <CustomizeFieldsDialog
        isOpen={isCustomizeFieldsOpen}
        onGoBack={() => setCustomizeFieldsOpen(false)}
        onClose={() => {
          onClose();
          reset({});
          setCustomizeFieldsOpen(false);
        }}
      />
    </SidebarDrawer>
  );
};
