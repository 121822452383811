import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { HookOutOfContextError } from "@jugl-web/utils/errors/HookOutOfContext";
import { useDownloadAttachment } from "@jugl-web/domain-resources/drive";
import { getFileSizeLabel } from "@jugl-web/utils/utils/files";
import { useDownloadManagerProvider } from "@jugl-web/domain-resources/files/providers/DownloadManagerProvider";
import { logger } from "@web-src/utils/logger";
import { useTranslations } from "@jugl-web/utils";
import { ChatMessage } from "../types";

export interface ChatMessageContextProps {
  message: ChatMessage;
  downloadAttachmentInProgress: boolean;
  downloadAttachment: () => void;
  attachmentDownloadProgress?: number;
  cancelAttachmentDownload: () => void;
  attachmentString: { text: string; className?: string } | null;
}

export const ChatMessageContext = createContext<ChatMessageContextProps>(
  null as unknown as ChatMessageContextProps
);

export const ChatMessageProvider: React.FC<
  PropsWithChildren<{ message: ChatMessage }>
> = ({ children, message }) => {
  const attachment = message?.payload?.attachments?.[0];
  const isDeleted = message?.deleted;
  const { download, downloadInProgress, cancelDownload } =
    useDownloadAttachment();
  const [attachmentDownloadProgress, setAttachmentDownloadProgress] =
    useState<number>();
  const { t } = useTranslations();

  const { files } = useDownloadManagerProvider();
  const downloadManagerFile = useMemo(
    () =>
      message.payload?.attachments?.[0]?.uid
        ? files[message.payload?.attachments?.[0].uid]
        : null,
    [files, message.payload?.attachments]
  );

  const attachmentString: { text: string; className?: string } | null =
    useMemo(() => {
      if (attachment && downloadManagerFile?.status === "in-progress") {
        return {
          text: t(
            {
              id: "chats-page.downloading-attachment",
              defaultMessage: "Downloading {progress}/{size}",
            },
            {
              progress: getFileSizeLabel(
                downloadManagerFile.progress
                  ? attachment.size * (downloadManagerFile.progress / 100)
                  : 0
              ),
              size: getFileSizeLabel(attachment.size),
            }
          ),
          className: "font-bold",
        };
      }
      if (attachment?._progress !== undefined) {
        return {
          text: t(
            {
              id: "chats-page.uploading-attachment",
              defaultMessage: "Uploading {progress}/{size}",
            },
            {
              progress: getFileSizeLabel(
                attachment?._progress
                  ? attachment.size * (attachment._progress / 100)
                  : 0
              ),
              size: getFileSizeLabel(attachment.size),
            }
          ),
          className: "font-bold",
        };
      }
      if (attachment && !isDeleted) {
        return { text: getFileSizeLabel(attachment.size) };
      }
      return null;
    }, [
      t,
      isDeleted,
      attachment,
      downloadManagerFile?.progress,
      downloadManagerFile?.status,
    ]);

  const downloadAttachment = useCallback(async () => {
    if (!attachment) {
      throw new Error("Message don't have attachment");
    }
    const path = attachment.uid;
    if (!path) {
      throw new Error("Path is required in URL");
    }
    // TODO: error handling
    try {
      const file = await download({
        path,
        fileSize: attachment.size,
        mimeType: attachment.mimetype,
        fileName: attachment.name || "",
        onProgress: (bytesSent, bytesTotal) => {
          setAttachmentDownloadProgress(
            Math.round((bytesSent / bytesTotal) * 100)
          );
        },
      });
      window.open(URL.createObjectURL(file), "_blank");
      setAttachmentDownloadProgress(undefined);
    } catch (error) {
      // TODO: error handling
      logger.error("ERROR >", error);
    } finally {
      setAttachmentDownloadProgress(undefined);
    }
  }, [attachment, download]);

  const value: ChatMessageContextProps = useMemo(
    () => ({
      message,
      downloadAttachment,
      downloadAttachmentInProgress: downloadInProgress,
      attachmentDownloadProgress,
      cancelAttachmentDownload: cancelDownload,
      attachmentString,
    }),
    [
      message,
      downloadAttachment,
      downloadInProgress,
      attachmentDownloadProgress,
      cancelDownload,
      attachmentString,
    ]
  );

  return (
    <ChatMessageContext.Provider value={value}>
      {children}
    </ChatMessageContext.Provider>
  );
};

export const useChatMessageProvider = () => {
  const context = useContext(ChatMessageContext);

  if (!context) {
    throw new HookOutOfContextError(
      "useChatMessageProvider",
      "ChatMessageContext"
    );
  }

  return context;
};
