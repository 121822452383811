import { Radio } from "@jugl-web/ui-components/cross-platform";
import { cx } from "@jugl-web/utils";
import React from "react";

const PhoneMethodRadio: React.FC<{
  checked: boolean;
  title: string;
  onChange: () => void;
}> = ({ checked, title, onChange }) => (
  <label className="flex cursor-pointer items-center gap-1">
    <Radio isChecked={checked} onChange={onChange} />
    <span
      className={cx("text-grey-700 text-sm", checked && "text-primary-800")}
    >
      {title}
    </span>
  </label>
);

export default PhoneMethodRadio;
