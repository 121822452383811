import { useEffect, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@web-src/store";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useToast, useTranslations } from "@jugl-web/utils";
import { setVisited } from "../driveSlice";

export const useDrive = () => {
  const dispatch = useDispatch();
  const visited = useSelector((state: RootState) => state.drive.visited);
  const params = useParams();
  const { t } = useTranslations();
  const { toast } = useToast({ variant: "web" });
  const [searchParams, setSearchParams] = useSearchParams();
  const { driveApi } = useRestApiProvider();

  const {
    data: rootDirectory,
    isLoading: isRootDirectoryLoading,
    isError: isRootDirectoryError,
    refetch: rootRefetch,
  } = driveApi.useGetDirectoryContentsQuery(
    {
      entityId: params.entityId || "",
      parent_id: undefined,
    },
    { skip: !params.entityId }
  );

  const { refetch, data, error, isLoading, isFetching } =
    driveApi.useGetDirectoryContentsQuery(
      {
        entityId: params.entityId || "",
        parent_id:
          visited.length > 1 && visited.at(-1)?.id
            ? visited.at(-1)?.id
            : searchParams.get("directoryId") || undefined,
      },
      { skip: !rootDirectory || !params.entityId }
    );

  const sortedData = useMemo(() => {
    if (!data) return undefined;

    const sortedDataCopy = { ...data, data: [...data.data] };

    sortedDataCopy.data = sortedDataCopy.data.sort((a, b) => {
      if (a.meta?.sys_dir && !b.meta?.sys_dir) return -1;
      if (!a.meta?.sys_dir && b.meta?.sys_dir) return 1;
      return 0;
    });

    return sortedDataCopy;
  }, [data]);

  const currentDirectory = useMemo(
    () => data?.data.find((el) => el.id === searchParams.get("directoryId")),
    [data?.data, searchParams]
  );
  useEffect(() => {
    // eslint-disable-next-line no-useless-return
    if (!data || visited.length !== 0) return;
    dispatch(
      setVisited([
        {
          id: searchParams.get("directoryId") || "",
          name: currentDirectory?.name || "",
        },
      ])
    );
  }, [data, visited, dispatch, searchParams, currentDirectory?.name]);

  useEffect(() => {
    if (visited.length > 1) {
      const openedFile = searchParams.get("openedFile");
      setSearchParams(
        new URLSearchParams({
          directoryId: visited[visited.length - 1]?.id,
          directoryName: visited[visited.length - 1]?.name,
          ...(openedFile ? { openedFile } : {}),
          ...(visited[visited.length - 2]?.id &&
          visited[visited.length - 2]?.id !== "0"
            ? { parentDirectoryId: visited[visited.length - 2]?.id }
            : {}),
        })
      );
    } else {
      setSearchParams(new URLSearchParams({}));
    }

    // eslint-disable-next-line
  }, [visited, searchParams]);

  useEffect(() => {
    if (!data) return;
    const openedFileId = searchParams.get("openedFile");
    const openedFile = data.data.find((file) => file.id === openedFileId);
    if (openedFileId && !openedFile) {
      searchParams.delete("openedFile");
      setSearchParams(searchParams);
      toast(
        t({
          id: "drive-page.file-not-exist",
          defaultMessage: "File not exist",
        }),
        { preventDuplicate: true, variant: "error" }
      );
    }
  }, [data, searchParams, setSearchParams, t, toast]);

  return {
    data: sortedData,
    rootDirectory,
    refetch,
    error,
    isLoading,
    isFetching,
    isRootDirectoryError,
    isRootDirectoryLoading,
    rootRefetch,
  };
};
