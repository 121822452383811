import { createSlice } from "@reduxjs/toolkit";
import { entityIdStorageKey } from "./utils";

const getInitialState = () => ({
  sideBarVisible: true,
  entity: null,
});

const appSlice = createSlice({
  name: "app",
  initialState: getInitialState(),
  reducers: {
    setEntity: (state, { payload }) => {
      localStorage.setItem(entityIdStorageKey, payload.id);
      state.entity = payload;
    },
    showSideBar: (state) => {
      state.sideBarVisible = true;
    },
    hideSideBar: (state) => {
      state.sideBarVisible = false;
    },
  },
});

export const selectIsSideBarVisible = ({ app: { sideBarVisible } }) =>
  sideBarVisible;

export const selectEntity = ({ app: { entity } }) => entity;

export const selectEntityId = ({ app: { entity } }) => entity?.id;

export const selectEntityRole = ({ app: { entity } }) => entity?.role;

export const { showSideBar, hideSideBar, setEntity } = appSlice.actions;

export default appSlice;
