import {
  PaginatedResponse,
  RtkEmptySplitApi,
  PaginatedRequestParams,
} from "../../types";
import { TasksApiTag } from "../tasks/tags";
import { TaskActivity } from "../tasks/types";
import { TasksCommentsApiTag } from "./tags";
import { TaskComment, TaskCommentAttachment } from "./types";

export const TASK_COMMENTS_TAGS_CREATORS = {
  TASK_ID: (taskId: string) => ({
    type: TasksCommentsApiTag.taskComment,
    id: `TASK_ID-${taskId}`,
  }),
};

export const addTasksCommentsApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [TasksCommentsApiTag.taskComment, TasksApiTag.taskActivity],
  });

  const tasksCommentsApi = apiWithTags.injectEndpoints({
    endpoints: (builder) => ({
      getTaskComments: builder.query<
        PaginatedResponse<TaskComment>,
        {
          entityId: string;
          taskId: string;
          repliesCommentId?: string;
          params?: PaginatedRequestParams<{ from?: string }>;
        }
      >({
        query: ({ entityId, taskId, params, repliesCommentId }) => ({
          url: `/api/auth/entity/${entityId}/task/${taskId}/comment${
            repliesCommentId ? `/${repliesCommentId}/reply` : ""
          }`,
          params,
          silentError: true,
        }),
        providesTags: (_result, _error, arg) => [
          TASK_COMMENTS_TAGS_CREATORS.TASK_ID(arg.taskId),
        ],
      }),

      getTaskComment: builder.query<
        { data: [TaskComment] },
        {
          entityId: string;
          taskId: string;
          commentId: string;
          replyId?: string;
        }
      >({
        query: ({ entityId, taskId, commentId, replyId }) => ({
          url: `/api/auth/entity/${entityId}/task/${taskId}/comments/${commentId}${
            replyId ? `/reply/${replyId}` : ""
          }`,
        }),
      }),

      createTaskComment: builder.mutation<
        TaskActivity,
        {
          taskId: string;
          entityId: string;
          replyToCommentId?: string;
          data: {
            comment_attachments?: TaskCommentAttachment[];
            content?: string;
          };
        }
      >({
        query: ({ taskId, entityId, data, replyToCommentId }) => ({
          url: `/api/auth/entity/${entityId}/task/${taskId}/comments${
            replyToCommentId ? `/${replyToCommentId}/reply` : ""
          }`,
          method: "POST",
          data,
        }),
      }),

      editComment: builder.mutation<
        TaskActivity,
        {
          taskId: string;
          entityId: string;
          commentId: string;
          parentCommentId?: string;
          data: {
            comment_attachments?: TaskCommentAttachment[];
            content?: string;
          };
        }
      >({
        query: ({ taskId, entityId, commentId, data }) => ({
          url: `/api/auth/entity/${entityId}/task/${taskId}/comments/${commentId}`,
          method: "PUT",
          data,
        }),
      }),

      deleteTaskComment: builder.mutation<
        void,
        {
          entityId: string;
          taskId: string;
          commentId: string;
          parentCommentId?: string;
        }
      >({
        query: ({ entityId, taskId, commentId }) => ({
          url: `/api/auth/entity/${entityId}/task/${taskId}/comments/${commentId}`,
          method: "DELETE",
        }),
      }),
    }),
  });

  return tasksCommentsApi;
};

export type TasksCommentsApi = ReturnType<typeof addTasksCommentsApi>;
