import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import React from "react";
import { useTranslations } from "@jugl-web/utils";
import { ReactComponent as RefreshIcon } from "./assets/refresh.svg";

export const ChatInfoError: React.FC<{
  isMembersSection?: boolean;
  onRefresh: () => void;
}> = ({ onRefresh, isMembersSection }) => {
  const { t } = useTranslations();
  return (
    <div className="sticky bottom-10 -mt-10">
      <div className="mx-auto flex h-10 w-max items-center justify-center gap-3 rounded-lg bg-[rgba(234,83,83,0.8)] py-3 px-4 text-white">
        <span>
          {isMembersSection
            ? t({
                id: "chats-page.cant-display-members",
                defaultMessage: "Cant display members",
              })
            : t({
                id: "chats-page.cant-display-attachments",
                defaultMessage: "Cant display attachments",
              })}
        </span>
        <span>|</span>
        <InteractiveContainer
          className="flex items-center gap-2 font-bold"
          onClick={onRefresh}
        >
          {t({
            id: "common.retry",
            defaultMessage: "Retry",
          })}
          <RefreshIcon />
        </InteractiveContainer>
      </div>
    </div>
  );
};
