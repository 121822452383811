import { EntityModel } from "@jugl-web/rest-api";
import { Alert, TextField } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { EntityAvatarWithDangerIndicator } from "../EntityAvatarWithDangerIndicator";

interface DangerEntityActionConfirmationDialogProps {
  isOpen: boolean;
  entity: EntityModel | null;
  title: string;
  message: ReactNode;
  requiredActionMessage: ReactNode;
  submitButton: { label: string; isDisabled: boolean; onClick: () => void };
  onClose: () => void;
}

export const DangerEntityActionConfirmationDialog: FC<
  DangerEntityActionConfirmationDialogProps
> = ({
  isOpen,
  entity,
  title,
  message,
  requiredActionMessage,
  submitButton,
  onClose,
}) => {
  const [repeatedEntityName, setRepeatedEntityName] = useState("");

  const inputRef = useRef<HTMLInputElement | null>(null);

  const { t } = useTranslations();

  useEffect(() => {
    if (isOpen) {
      setRepeatedEntityName("");
      window.setTimeout(() => inputRef.current?.focus());
    }
  }, [isOpen]);

  return (
    <Alert
      isOpen={isOpen}
      onRequestClose={onClose}
      isCloseButtonVisible
      header={t({
        id: "common.confirm-action",
        defaultMessage: "Confirm action",
      })}
      title={title}
      img={<EntityAvatarWithDangerIndicator entity={entity} />}
      content={
        <div className="flex flex-col gap-6">
          <span>{message}</span>
          <div className="flex flex-col gap-2">
            <TextField
              ref={inputRef}
              value={repeatedEntityName}
              placeholder={t({
                id: "form-controls.repeat-workspace-name.placeholder",
                defaultMessage: "Enter workspace name",
              })}
              isFullWidth
              onChange={(event) => setRepeatedEntityName(event.target.value)}
            />
            <span className="text-gradients-danger text-sm leading-3">
              {requiredActionMessage}
            </span>
          </div>
        </div>
      }
      buttons={[
        {
          text: submitButton.label,
          color: "tertiary",
          isDisabled:
            repeatedEntityName !== entity?.name || submitButton.isDisabled,
          onClick: submitButton.onClick,
        },
      ]}
    />
  );
};
