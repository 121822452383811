import { WorkspaceParticipant } from "@jugl-web/rest-api";

export const getWorkspaceParticipantDisplayName = (
  participant: WorkspaceParticipant
) => {
  const profile = participant.profile_info;
  if (profile && (profile.first_name || profile.last_name)) {
    const strings: string[] = [];
    if (profile.first_name) {
      strings.push(profile.first_name);
    }
    if (profile.last_name) {
      strings.push(profile.last_name);
    }
    return strings.join(" ");
  }
  // TODO: re-check
  return participant.username || "unknown participant";
};

export const getWorkspaceParticipantDisplayImg = (
  participant: WorkspaceParticipant
) => participant.profile_info?.img || undefined;
