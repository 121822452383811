import { useRestApiProvider } from "@jugl-web/rest-api";
import { SelfProducedTaskActivity } from "@jugl-web/rest-api/tasks";
import { omit } from "@jugl-web/utils";
import { useCallback } from "react";
import {
  TaskChecklistHandlers,
  taskChecklistItemAdapters,
} from "../components/TaskChecklist";
import { useUpdateTask } from "./useUpdateTask";

interface UseRemoteTaskChecklistHandlersOptions {
  entityId: string;
  taskId: string;
  onProducedActivity: (
    activity: SelfProducedTaskActivity | Record<string, never>
  ) => void;
}

export const useRemoteTaskChecklistHandlers = ({
  entityId,
  taskId,
  onProducedActivity,
}: UseRemoteTaskChecklistHandlersOptions): TaskChecklistHandlers => {
  const { tasksApi } = useRestApiProvider();

  const [addChecklistItemMutation] = tasksApi.useAddChecklistItemMutation();

  const [updateChecklistItemMutation] =
    tasksApi.useUpdateChecklistItemMutation();

  const [deleteChecklistItemMutation] =
    tasksApi.useDeleteChecklistItemMutation();

  const updateTask = useUpdateTask({ entityId, taskId });

  const handleAddItem = useCallback<TaskChecklistHandlers["onAddItem"]>(
    async (item, index) => {
      const itemAsBackendModel = taskChecklistItemAdapters.toBackendModel(
        item,
        index
      );

      const response = await addChecklistItemMutation({
        entityId,
        taskId,
        item: omit(itemAsBackendModel, "id", "completed_at"),
      });

      if ("data" in response) {
        onProducedActivity(response.data.activity);
      }
    },
    [addChecklistItemMutation, entityId, onProducedActivity, taskId]
  );

  const handleUpdateItem = useCallback<TaskChecklistHandlers["onUpdateItem"]>(
    async (updatedItem, index) => {
      const itemAsBackendModel = taskChecklistItemAdapters.toBackendModel(
        updatedItem,
        index
      );

      const response = await updateChecklistItemMutation({
        entityId,
        taskId,
        itemId: updatedItem.id,
        attributes: omit(itemAsBackendModel, "id", "completed_at"),
      });

      if ("data" in response) {
        onProducedActivity(response.data.activity);
      }
    },
    [entityId, onProducedActivity, taskId, updateChecklistItemMutation]
  );

  const handleDeleteItem = useCallback<TaskChecklistHandlers["onDeleteItem"]>(
    async (itemId) => {
      const response = await deleteChecklistItemMutation({
        entityId,
        taskId,
        itemId,
      });

      if ("data" in response) {
        onProducedActivity(response.data.activity);
      }
    },
    [deleteChecklistItemMutation, entityId, onProducedActivity, taskId]
  );

  const handleReorderItems = useCallback<
    TaskChecklistHandlers["onReorderItems"]
  >(
    async (newItems) => {
      const itemsAsBackendModel = newItems.map(
        taskChecklistItemAdapters.toBackendModel
      );

      try {
        const response = await updateTask({ checklist: itemsAsBackendModel });

        onProducedActivity(response.activity);
      } catch {
        // Do nothing
      }
    },
    [onProducedActivity, updateTask]
  );

  return {
    onAddItem: handleAddItem,
    onUpdateItem: handleUpdateItem,
    onDeleteItem: handleDeleteItem,
    onReorderItems: handleReorderItems,
  };
};
