import React, { useContext } from "react";
import { Text, Button } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { CallsContext } from "../../../../../../../../providers";
import scheludedConferenceImage from "./assets/scheluded-conference.png";

export const ScheludedConference: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  const { t } = useTranslations();
  const { activeCallProps } = useContext(CallsContext);

  return (
    <div className="mt-[60px] flex h-full w-full flex-col items-center">
      <img src={scheludedConferenceImage} alt="Conference" />
      <Text
        variant="h3"
        className="text-dark w-[400px] overflow-hidden text-center"
      >
        {activeCallProps?.conferenceMessage?.name}
      </Text>
      <Text variant="body2" className="text-dark-700 w-[400px] text-center">
        {t(
          {
            id: "call-conference.scheluded-description-info",
            defaultMessage:
              "This meeting will take place on {date}, at {time}. Before the meeting, you will receive a notification about it.",
          },
          {
            date: activeCallProps?.conferenceMessage?.date,
            time: activeCallProps?.conferenceMessage?.time,
          }
        )}
      </Text>
      <Button
        color="primary"
        uppercase
        className="mt-[72px] w-[343px]"
        onClick={onClose}
      >
        {t({
          id: "call-conference-scheluded-confirmation-button",
          defaultMessage: "Got it",
        })}
      </Button>
    </div>
  );
};
