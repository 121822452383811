import { useCallback, useEffect, useRef, useState } from "react";
import { useMemoCompare } from "@web-src/utils/helper";
import WaveSurfer from "wavesurfer.js";
import { WaveSurferParams } from "wavesurfer.js/types/params";
import isEqual from "lodash/isEqual";
import { secondsToTime } from "@web-src/utils/datetime";

const useWaveAudio = (url: string, params: WaveSurferParams) => {
  const [waveSurfer, setWaveSurfer] = useState<WaveSurfer>();
  const [duration, setDuration] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [playing, setPlaying] = useState<boolean>(false);
  const waveSurferRef = useRef<WaveSurfer | null>(null);
  const memorizedParams = useMemoCompare(params, isEqual);
  const memorizedUrl = useMemoCompare(url, isEqual);

  useEffect(() => {
    if (waveSurferRef.current) {
      waveSurferRef.current?.destroy();
    }
    const waveSurferObj = WaveSurfer.create({
      ...memorizedParams,
      xhr: {
        credentials: "include",
      },
    });
    waveSurferRef.current = waveSurferObj;
    waveSurferObj.load(memorizedUrl);
    setWaveSurfer(waveSurferObj);

    const readyCallback = () => {
      setDuration(waveSurferObj.getDuration());
    };
    waveSurferObj.on("ready", readyCallback);

    const interactionCallback = () => {
      setTimeout(() => {
        setCurrentTime(waveSurferObj.getCurrentTime());
      }, 100);
    };
    waveSurferObj.on("interaction", interactionCallback);

    const playCallback = () => {
      setPlaying(true);
    };
    waveSurferObj.on("play", playCallback);

    const pauseCallback = () => {
      setPlaying(false);
    };
    waveSurferObj.on("pause", pauseCallback);

    const audioProcessCallback = () => {
      setCurrentTime(waveSurferObj.getCurrentTime());
    };
    waveSurferObj.on("audioprocess", audioProcessCallback);

    const finishCallback = () => {
      waveSurferObj.setCurrentTime(0);
      setCurrentTime(0);
    };
    waveSurferObj.on("finish", finishCallback);

    return () => {
      waveSurferObj.un("pause", pauseCallback);
      waveSurferObj.un("play", playCallback);
      waveSurferObj.un("ready", readyCallback);
      waveSurferObj.un("interaction", interactionCallback);
      waveSurferObj.un("audioprocess", audioProcessCallback);
    };
  }, [memorizedParams, memorizedUrl]);

  const play = useCallback(() => {
    waveSurfer?.play();
  }, [waveSurfer]);

  const pause = useCallback(() => {
    waveSurfer?.pause();
  }, [waveSurfer]);

  return {
    waveSurfer,
    duration,
    durationStr: secondsToTime(duration),
    currentTime,
    currentTimeStr: secondsToTime(currentTime),
    playing,
    play,
    pause,
  };
};

export default useWaveAudio;
