import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import Search from "@mui/icons-material/Search";
import useDebounce from "@web-src/hooks/useDebounce";
import { useTheme } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { useTranslations } from "@jugl-web/utils";
import ClearIcon from "@mui/icons-material/Clear";

const SearchInputUnderline: React.FC<{
  onChange: (value: string) => void;
  onClear?: () => void;
  placeholder?: string;
  debounce?: number;
}> = ({ debounce = 300, onChange, onClear, placeholder }) => {
  const { t } = useTranslations();
  const theme = useTheme();
  const [value, setValue] = useState<string>("");
  const debounceValue = useDebounce(value, debounce);
  useEffect(() => {
    onChange(debounceValue);
  }, [debounceValue, onChange]);
  const handleChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e) => {
    setValue(e.target.value);
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        py: "5px",
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
      }}
    >
      <Search sx={{ color: "grey.500", mr: "10px" }} />
      <InputBase
        value={value}
        placeholder={
          placeholder ||
          t({
            id: "common.search",
            defaultMessage: "Search",
          })
        }
        sx={{ width: "100%", py: "5px", border: "none" }}
        onChange={handleChange}
        endAdornment={
          <InputAdornment
            sx={{ visibility: value && onClear ? "visible" : "hidden" }}
            position="end"
          >
            <IconButton
              onClick={() => {
                if (onClear) {
                  onClear();
                }
                setValue("");
              }}
              edge="end"
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </Box>
  );
};

export default SearchInputUnderline;
