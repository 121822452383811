import { AxiosError, AxiosInstance } from "axios";

// TODO: type
export const baseQuery = (axios: AxiosInstance) => async (params: any) => {
  try {
    const response = await axios(params);

    return {
      data: params?.rawResponse
        ? {
            data: response.data,
            headers: response.headers,
            status: response.status,
            statusText: response.statusText,
          }
        : response.data,
    };
  } catch (error: unknown) {
    return {
      error: (error as AxiosError).response || { __NETWORK_ERROR: true },
    };
  }
};
