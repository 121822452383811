import { PlainButton } from "@jugl-web/ui-components/cross-platform/PlainButton";
import React, { ButtonHTMLAttributes, useMemo } from "react";
import { cx } from "@jugl-web/utils";
import { Text } from "@jugl-web/ui-components/cross-platform";

export type ToggleButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  isOn: boolean;
  onIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  offIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  noFill?: boolean;
  hide?: boolean;
  noBgChange?: boolean;
  text?: React.ReactNode;
  textVariant?: "dark";
};

export const ToggleButton: React.FC<ToggleButtonProps> = (props) => {
  const {
    onIcon: OnIcon,
    offIcon: OffIcon,
    isOn,
    noFill,
    hide,
    noBgChange,
    className,
    text,
    textVariant,
    ...rest
  } = props;
  const nativeProps = useMemo(() => {
    const out: Partial<ToggleButtonProps> = { ...rest };
    delete out.offIcon;
    delete out.onIcon;
    delete out.isOn;
    delete out.children;
    delete out.noFill;
    delete out.hide;
    delete out.noBgChange;
    delete out.text;
    delete out.textVariant;
    return out;
  }, [rest]);

  const Icon = isOn ? OffIcon : OnIcon;
  if (hide) {
    return null;
  }
  return (
    <div className="flex flex-col gap-1">
      <PlainButton
        className={cx(
          "h-[56px] w-[56px] rounded-full",
          {
            "bg-dark/50 hover:brightness-90 active:brightness-110":
              isOn || noBgChange,
            "bg-white hover:brightness-90 active:brightness-110":
              !isOn && !noBgChange,
          },
          className
        )}
        {...nativeProps}
      >
        <Icon
          className={cx(
            textVariant === "dark" && {
              "fill-none stroke-white": noFill,
              "fill-white": (!isOn && !noFill) || (isOn && !noFill),
            },
            textVariant !== "dark" && {
              "stroke-grey-500 fill-none": noFill && !isOn,
              "stroke-dark fill-none": noFill && isOn,
              "fill-dark-300": !isOn && !noFill,
              "fill-dark": isOn && !noFill,
            }
          )}
        />
      </PlainButton>
      <Text
        variant="timestamp"
        className={cx("whitespace-nowrap text-center font-[500]", {
          "text-white": textVariant !== "dark",
          "text-dark-700": textVariant === "dark" && isOn,
          "text-grey-800": textVariant === "dark" && !isOn,
        })}
      >
        {text}
      </Text>
    </div>
  );
};
