import { CountryCode, parsePhoneNumber } from "libphonenumber-js";
import { PhoneInputValue } from "../PhoneInput";
import { Country } from "../types";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const countries: Country[] = require("countries-phone-masks");

export const phoneStringToInputValue = (phoneStr: string): PhoneInputValue => {
  let country: Country | undefined;
  if (phoneStr?.indexOf("+91") === 0) {
    country = countries.find((item) => item.iso === "IN");
  }
  if (phoneStr?.indexOf("+1") === 0) {
    country = countries.find((item) => item.iso === "US");
  }
  country = countries.find((item) => phoneStr?.indexOf(item.code) === 0);

  const phone = phoneStr && country ? phoneStr.slice(country.code.length) : "";
  const code = country?.code || "+91";
  const iso = country?.iso || "IN";
  let phoneNumber;
  try {
    phoneNumber = parsePhoneNumber(`${code}${phone}`, iso as CountryCode);
  } catch (error) {
    //
  }

  return {
    iso: country?.iso || "IN",
    code,
    phone,
    isValid: /000\d\d\d\d000/g.test(phone) || phoneNumber?.isValid() || false,
  };
};
