import { cx } from "@jugl-web/utils";
import { forwardRef, LabelHTMLAttributes, ReactNode } from "react";

export type FormControlLabelSize = "md" | "sm";

export interface FormControlLabelProps {
  children: ReactNode;
  size?: FormControlLabelSize;
  htmlFor?: LabelHTMLAttributes<HTMLLabelElement>["htmlFor"];
  className?: string;
  isRequired?: boolean;
}

const sizeToClasses: Record<FormControlLabelSize, string> = {
  md: "text-base leading-4",
  sm: "text-xs leading-2",
};

export const FormControlLabel = forwardRef<
  HTMLLabelElement,
  FormControlLabelProps
>(({ children, size = "md", htmlFor, className, isRequired }, ref) => (
  <label
    ref={ref}
    htmlFor={htmlFor}
    className={cx(
      "text-onyx inline-block text-base font-normal",
      sizeToClasses[size],
      className
    )}
  >
    {children}
    {isRequired && <span className="text-gradients-danger ml-1">*</span>}
  </label>
));
