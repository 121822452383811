import { TaskActivity } from "@jugl-web/rest-api/tasks";
import { TasksCommentsApi } from "@jugl-web/rest-api/tasks-comments";
import { useEffect } from "react";
import { ManageCommentsSubject } from "../providers/TaskCommentsProvider";

export const useManageCommentsListener = ({
  taskId,
  commentId,
  entityId,
  tasksCommentsApi,
  manageComments$,
  addActivity,
  addGuestActivity,
  modifyActivity,
  modifyGuestActivity,
}: {
  taskId: string;
  entityId: string;
  commentId?: string;
  tasksCommentsApi: TasksCommentsApi;
  manageComments$: ManageCommentsSubject;
  addActivity: (activity: TaskActivity) => void;
  addGuestActivity?: (activity: TaskActivity) => void;
  modifyActivity: (
    activity: TaskActivity,
    actionType: "delete" | "update"
  ) => void;
  modifyGuestActivity?: (
    activity: TaskActivity,
    actionType: "delete" | "update"
  ) => void;
}) => {
  const [deleteComment] = tasksCommentsApi.useDeleteTaskCommentMutation();
  const [createComment] = tasksCommentsApi.useCreateTaskCommentMutation();
  const [editComment] = tasksCommentsApi.useEditCommentMutation();
  useEffect(() => {
    const subscription = manageComments$.subscribe(
      async ({ taskId: manageTaskId, comment, data, action }) => {
        if (manageTaskId !== taskId) {
          return;
        }
        switch (action) {
          case "create":
            if (data) {
              const response = await createComment({
                taskId,
                entityId,
                data,
              });

              if ("data" in response) {
                const isActionGuest =
                  response.data.action_details.action.includes("guest");
                if (isActionGuest) {
                  addGuestActivity?.(response.data);
                } else {
                  addActivity(response.data);
                }
              }
            }
            break;
          case "delete":
            if (comment && !!comment.id) {
              const commentIdToDelete = comment.id;
              if (commentIdToDelete) {
                deleteComment({
                  entityId,
                  taskId,
                  commentId: commentIdToDelete,
                });
                const isActionGuest =
                  comment.action_details.action.includes("guest");
                if (isActionGuest) {
                  modifyGuestActivity?.(comment, "delete");
                } else {
                  modifyActivity(comment, "delete");
                }
              }
            }
            break;
          case "update":
            if (comment && data) {
              const commentIdToEdit = comment.id;
              if (commentIdToEdit) {
                const response = await editComment({
                  taskId,
                  entityId,
                  commentId: comment.id,
                  data,
                });
                if ("data" in response) {
                  const isActionGuest =
                    response.data.action_details.action.includes("guest");
                  if (isActionGuest) {
                    modifyGuestActivity?.(response.data, "update");
                  } else {
                    modifyActivity(response.data, "update");
                  }
                }
              }
            }
            break;
          default:
        }
      }
    );
    return () => subscription.unsubscribe();
  }, [
    addActivity,
    addGuestActivity,
    commentId,
    createComment,
    deleteComment,
    editComment,
    entityId,
    manageComments$,
    modifyActivity,
    modifyGuestActivity,
    taskId,
  ]);
};
