import React, { useEffect, useRef, useState } from "react";
import { useTranslations } from "@jugl-web/utils";
import {
  Button,
  Dialog,
  InteractiveContainer,
} from "@jugl-web/ui-components/cross-platform";
import { CropImage, CropImageHandle } from "./components/CropImage/CropImage";
import { ReactComponent as CloseIcon } from "./assets/close.svg";
import { ReactComponent as RotateIcon } from "./assets/rotate.svg";
import { ReactComponent as PhotoIcon } from "./assets/photo.svg";

export const CropImageModal: React.FC<{
  onClose: () => void;
  isOpen: boolean;
  onSave: (image: File) => void;
  onChangeImage: () => void;
  image?: File;
}> = ({ onClose, onSave, onChangeImage, image, isOpen }) => {
  const [scale, setScale] = useState<number>(100);
  const $cropImage = useRef<CropImageHandle>(null);
  const { t } = useTranslations();

  const handleSave = async () => {
    const file = await $cropImage.current?.getImageFile();
    if (file) {
      onSave(file);
    }
  };

  const handleScale = (e: React.ChangeEvent<HTMLInputElement>) =>
    setScale(Number(e.target.value));

  const handleRotateImage = () => $cropImage.current?.rotate();

  useEffect(() => {
    setScale(100);
  }, [image]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      className="w-[90%] max-w-[780px] rounded-2xl"
    >
      <div className="border-dark-100 flex items-center justify-between border-0 border-b border-solid py-[14px] px-6">
        <span className="text-sm font-semibold text-[#383838]">
          {t({
            id: "avatar-select-component.adjust-image",
            defaultMessage: "Adjust Image",
          })}
        </span>
        <InteractiveContainer onClick={onClose} className="block">
          <CloseIcon />
        </InteractiveContainer>
      </div>
      <div className="grid grid-cols-2 items-center gap-10 py-12 px-14">
        <div className="flex items-center justify-center">
          <CropImage image={image} scale={scale / 100} ref={$cropImage} />
        </div>
        <div className="flex w-full flex-col gap-5">
          <div className="flex flex-col gap-1">
            <div className="flex items-center justify-between">
              <span className="text-grey-700 text-sm font-medium">
                {t({
                  id: "avatar-select-component.zoom-in-out",
                  defaultMessage: "Zoom in/out",
                })}
              </span>
              <span className="text-grey-700 font-medium">{scale}%</span>
            </div>
            <input
              type="range"
              min="100"
              max="200"
              step="5"
              value={scale}
              className="jugl__range-slider w-full"
              onChange={handleScale}
            />
          </div>
          <InteractiveContainer
            onClick={handleRotateImage}
            className="bg-grey-200 text-dark hover:bg-grey-300 flex items-center gap-2 rounded-lg p-4 font-[Poppins] text-[15px] font-medium transition-colors"
          >
            <RotateIcon />
            {t({
              id: "avatar-select-component.rotate-photo",
              defaultMessage: "Rotate Photo",
            })}
          </InteractiveContainer>
          <InteractiveContainer
            onClick={onChangeImage}
            className="bg-grey-200 text-dark hover:bg-grey-300 flex items-center gap-2 rounded-lg p-4 font-[Poppins] text-[15px] font-medium transition-colors"
          >
            <PhotoIcon />
            {t({
              id: "avatar-select-component.change-photo",
              defaultMessage: "Change Photo",
            })}
          </InteractiveContainer>
        </div>
      </div>
      <div className="mb-12 flex justify-center">
        <Button className="mx-20 w-full max-w-[300px]" onClick={handleSave}>
          {t({
            id: "common.save",
            defaultMessage: "Save",
          })}
        </Button>
      </div>
    </Dialog>
  );
};
