import React, { useState, useEffect } from "react";
import { Dialog, Box, Typography, SvgIcon } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { ReactComponent as Close } from "@web-src/images/icons/close.svg";
import { grey, primary, white } from "@web-src/themes/colors";
import { useTranslations } from "@jugl-web/utils";

const ImageViewer = function ImageViewer({
  isOpen,
  onClose,
  images,
  currentImageId,
}) {
  const [imageIndex, setImageIndex] = useState(null);
  const { t } = useTranslations();

  const increaseIndex = () => setImageIndex((old) => (old + 1) % images.length);

  useEffect(() => {
    setImageIndex(images.findIndex((element) => element.id === currentImageId));
  }, [currentImageId, images]);

  const decreaseIndex = () =>
    setImageIndex((old) => {
      if (old === 0) return images.length - 1;
      return old - 1;
    });

  if (imageIndex === null) {
    return null;
  }

  const currentImage = images[imageIndex];

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0,0,0,0.9)",
        },
      }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <Carousel
        next={increaseIndex}
        prev={decreaseIndex}
        sx={{ maxHeight: "70vh", textAlign: "center", objectFit: "contain" }}
        autoPlay={false}
        indicators={false}
        animation="slide"
        duration="650"
        index={imageIndex}
        navButtonsAlwaysVisible="true"
        navButtonsWrapperProps={{
          style: {
            position: "fixed",
          },
        }}
      >
        {images.map((element) => (
          <Box
            component="img"
            src={element.url}
            key={element.id}
            sx={{ maxWidth: "100%", maxHeight: "70vh" }}
          />
        ))}
      </Carousel>
      <Box
        sx={{
          position: "fixed",
          bottom: "5%",
          left: "60px",
        }}
      >
        <Typography sx={{ color: white[500], cursor: "pointer" }}>
          {currentImage?.name}
        </Typography>
        {currentImage?.date && (
          <Typography sx={{ color: grey[700], fontSize: "12px" }}>
            {t(
              {
                id: "image-viewer.image-upload-date",
                defaultMessage: "You have uploaded {date}",
              },
              {
                date: currentImage.date,
              }
            )}
          </Typography>
        )}
      </Box>
      <SvgIcon
        component={Close}
        onClick={onClose}
        sx={{
          position: "fixed",
          top: "3.5%",
          right: "5%",
          cursor: "pointer",
          color: grey[800],
          "&:hover": {
            color: grey[300],
          },
        }}
      />
      <Box
        sx={{
          width: "70%",
          height: "2px",
          backgroundColor: grey[900],
          position: "fixed",
          top: "5%",
          left: "15%",
        }}
      >
        <Box
          sx={{
            width: `${(1 / images.length) * 100}%`,
            left: `${(imageIndex / images.length) * 100}%`,
            position: "absolute",
            height: "100%",
            backgroundColor: primary[500],
          }}
        />
      </Box>
    </Dialog>
  );
};

export default ImageViewer;
