import React, { useEffect, useState } from "react";
import { cx } from "@jugl-web/utils";
import { ReactComponent as JuglLogoIcon } from "./assets/jugl-logo.svg";

const animationContent = [
  {
    id: 1,
    firstLine: "Transform Your",
    secondLine: "Workflow with",
  },
  {
    id: 2,
    firstLine: "Streamline Your",
    secondLine: "Operations with",
  },
  {
    id: 3,
    firstLine: "Navigate Your",
    secondLine: "Business with",
  },
  {
    id: 4,
    firstLine: "Task Clarity for Peak",
    secondLine: "Performance with",
  },
  {
    id: 5,
    firstLine: "Simplify Task",
    secondLine: "Tracking with",
  },
];

type PromoTextSize = "md" | "lg";

export type PromoTextProps = { size?: PromoTextSize };

const sizeToTextClasses: Record<PromoTextSize, string> = {
  md: "text-2xl",
  lg: "text-[40px]",
};

const sizeToLogoClasses: Record<PromoTextSize, string> = {
  md: "w-[58px] h-[26px]",
  lg: "w-[101px] h-[48px]",
};

export const PromoText: React.FC<PromoTextProps> = ({ size = "lg" }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % animationContent.length);
    }, 6000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="relative flex h-[60px] w-[450px] items-center justify-center">
      {animationContent.map((text, index) => (
        <div
          className={cx(
            "font-secondary absolute flex flex-col items-center justify-center transition-opacity duration-[2000ms]",
            index === activeIndex ? "z-[1] opacity-100" : "opacity-0"
          )}
          key={text.id}
        >
          <span
            className={cx(
              "shrink-0 bg-gradient-to-r from-[#22DBFC] via-[#FFFFFF] to-[#FF7AFF] bg-clip-text text-center font-semibold leading-[115%] text-transparent",
              sizeToTextClasses[size]
            )}
          >
            {text.firstLine}
          </span>
          <div className="flex items-center gap-3">
            <span
              className={cx(
                "shrink-0 bg-gradient-to-r from-[#FF7AFF] via-[#FFFFFF] to-[#22DBFC] bg-clip-text text-center font-semibold leading-[115%] text-transparent",
                sizeToTextClasses[size]
              )}
            >
              {text.secondLine}
            </span>
            <JuglLogoIcon className={cx("shrink-0", sizeToLogoClasses[size])} />
          </div>
        </div>
      ))}
    </div>
  );
};
