import { TaskCustomStatus } from "@jugl-web/rest-api/tasks";
import { Button } from "@jugl-web/ui-components/cross-platform";
import { Tooltip } from "@jugl-web/ui-components/cross-platform/Tooltip";
import { BottomCenteredDrawer } from "@jugl-web/ui-components/web/BottomCenteredDrawer";
import { cx, useTranslations } from "@jugl-web/utils";
import { FC, useMemo, useRef, useState } from "react";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import Highlighter from "react-highlight-words";
import { useTaskFields } from "../../../hooks/useTaskFields";
import { taskCustomStatusValidator } from "../../../validators";
import { DraggableContainer } from "../DraggableContainer";
import { TaskStatusListItem } from "../TaskStatusListItem";
import { ReactComponent as AddIcon } from "../icons/add.svg";
import { ReactComponent as InfoBlankIcon } from "../icons/info-blank.svg";
import { EmptyStateScreen } from "./EmptyStateScreen";
import { useTaskDefaultStatuses } from "../useTaskDefaultStatuses";

export interface StatusesScreenProps {
  entityId: string;
  searchQuery: string;
}

const TEMPORARY_TASK_CUSTOM_STATUS: TaskCustomStatus = {
  id: "__temporary",
  text: "",
  order: 0,
};

export const StatusesScreen: FC<StatusesScreenProps> = ({
  entityId,
  searchQuery,
}) => {
  const {
    customStatuses,
    createCustomStatus,
    updateCustomStatus,
    deleteCustomStatus,
    reorderCustomStatus,
    isCreatingCustomStatus,
  } = useTaskFields({ entityId });
  const defaultStatuses = useTaskDefaultStatuses();
  const { t } = useTranslations();

  const [editingStatusId, setEditingStatusId] = useState<string | null>(null);
  const [isNewStatusShown, setIsNewStatusShown] = useState(false);

  const endListAnchorRef = useRef<HTMLDivElement | null>(null);

  const statusOrderById = useMemo<Map<string, number>>(() => {
    const allStatusesOrdered = [
      ...defaultStatuses.slice(0, -1).map((status) => status.id),
      ...customStatuses.map((status) => status.id),
      defaultStatuses[defaultStatuses.length - 1].id,
    ];

    return new Map(
      allStatusesOrdered.map((status, index) => [status, index + 1])
    );
  }, [customStatuses, defaultStatuses]);

  const visibleDefaultStatuses = useMemo(() => {
    if (!searchQuery) {
      return defaultStatuses;
    }

    return defaultStatuses.filter((status) =>
      status.label.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [searchQuery, defaultStatuses]);

  const visibleCustomStatuses = useMemo(() => {
    if (!searchQuery) {
      return customStatuses;
    }

    return customStatuses.filter((status) =>
      status.text.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [searchQuery, customStatuses]);

  const isInSearchingMode = !!searchQuery;
  const hasNoCustomStatus = customStatuses.length === 0;
  const isEmpty =
    visibleDefaultStatuses.length === 0 && visibleCustomStatuses.length === 0;

  const scrollToBottom = () => {
    endListAnchorRef.current?.scrollIntoView({ block: "center" });
  };

  const handleNewCustomStatusClick = () => {
    setIsNewStatusShown(true);
    // Make sure that scroll happens after the new item is rendered
    window.setTimeout(scrollToBottom, 0);
  };

  const handleCreateCustomStatus = (text: string) => {
    const isValid = taskCustomStatusValidator(text);

    if (!isValid) {
      setIsNewStatusShown(false);
      return;
    }

    createCustomStatus({ text }).then(() => {
      setIsNewStatusShown(false);
    });
  };

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination || source.index === destination.index) {
      return;
    }

    reorderCustomStatus(source.index, destination.index);
  };

  if (isInSearchingMode && isEmpty) {
    return <EmptyStateScreen />;
  }

  return (
    <BottomCenteredDrawer.Content className="py-8 px-12">
      {visibleDefaultStatuses.length > 0 && (
        <>
          <div className="mb-2.5 px-2">
            <span className="text-dark-800 text-sm font-medium leading-[21px]">
              {t({
                id: "tasks-page.task-default-statuses",
                defaultMessage: "Default statuses",
              })}
            </span>
          </div>
          <div className="mb-10 flex flex-col gap-4">
            {visibleDefaultStatuses.map((status) => (
              <div
                key={status.id}
                className="bg-grey-100 flex h-14 items-center justify-between gap-4 rounded-lg px-6"
              >
                <div className="flex items-center gap-3">
                  <div className="text-dark-700 bg-grey-200 flex h-8 w-8 items-center justify-center rounded-md border-4 border-solid border-white text-[10px] font-medium">
                    #{statusOrderById.get(status.id) ?? 0}
                  </div>
                  <span className="text-dark">
                    {searchQuery ? (
                      <Highlighter
                        autoEscape
                        highlightClassName="text-primary font-semibold"
                        highlightTag="span"
                        textToHighlight={status.label}
                        searchWords={[searchQuery]}
                      />
                    ) : (
                      status.label
                    )}
                  </span>
                </div>
                {status.tip && (
                  <Tooltip
                    placement="left"
                    renderTrigger={({ ref, props }) => (
                      <InfoBlankIcon
                        ref={ref}
                        className="text-grey-300 hover:text-grey-600 transition-colors"
                        {...props}
                      />
                    )}
                  >
                    {status.tip}
                  </Tooltip>
                )}
              </div>
            ))}
          </div>
        </>
      )}
      {(visibleCustomStatuses.length > 0 || !isInSearchingMode) && (
        <>
          <div className="mb-[18px] flex h-10 items-center justify-between p-2">
            <div className="flex flex-col gap-1">
              <span className="text-dark-800 text-sm font-medium leading-[21px]">
                {t({
                  id: "tasks-page.task-custom-statuses",
                  defaultMessage: "Custom statuses",
                })}
              </span>
              {hasNoCustomStatus && !isNewStatusShown && (
                <span className="text-grey-900 leading-2 text-xs">
                  {t({
                    id: "tasks-page.no-task-custom-statuses-message",
                    defaultMessage: "You haven't created any custom status yet",
                  })}
                </span>
              )}
            </div>
            <Button
              color="grey"
              className="p-3 font-normal"
              iconEnd={<AddIcon />}
              onClick={handleNewCustomStatusClick}
            >
              {t({ id: "common.add", defaultMessage: "Add" })}
            </Button>
          </div>
          <div>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="statusList">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    className="flex flex-col"
                    {...provided.droppableProps}
                  >
                    {visibleCustomStatuses.map((status, index) => (
                      <DraggableContainer
                        key={status.id}
                        id={status.id}
                        index={index}
                        className="mb-4"
                        isDragDisabled={isInSearchingMode}
                      >
                        <TaskStatusListItem
                          customStatus={status}
                          order={statusOrderById.get(status.id) ?? 0}
                          isEditing={editingStatusId === status.id}
                          highlightedText={searchQuery}
                          onStartEditing={() => setEditingStatusId(status.id)}
                          onFinishEditing={() => setEditingStatusId(null)}
                          onUpdate={(item) =>
                            updateCustomStatus(status.id, item.text)
                          }
                          onRemove={() => deleteCustomStatus(status.id)}
                        />
                      </DraggableContainer>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {isNewStatusShown && (
              <div
                className={cx(
                  "transition-opacity",
                  isCreatingCustomStatus && "cursor-not-allowed opacity-50"
                )}
              >
                <TaskStatusListItem
                  customStatus={TEMPORARY_TASK_CUSTOM_STATUS}
                  order={statusOrderById.size}
                  isEditing
                  onStartEditing={() => {}}
                  onFinishEditing={(item) =>
                    handleCreateCustomStatus(item.text)
                  }
                  onUpdate={() => {}}
                  onRemove={() => setIsNewStatusShown(false)}
                  className={cx(
                    "mb-4",
                    isCreatingCustomStatus && "pointer-events-none"
                  )}
                />
              </div>
            )}
            <div ref={endListAnchorRef} />
          </div>
        </>
      )}
    </BottomCenteredDrawer.Content>
  );
};
