export const getImagePathForSharedLibrary = (image: unknown) => {
  // when image was imported in React App based on Webpack
  if (typeof image === "string") {
    return image;
  }

  // when image was imported in React App based on Next.js
  if (
    image &&
    typeof image === "object" &&
    "src" in image &&
    typeof image.src === "string"
  ) {
    return image.src;
  }

  throw new Error("Invalid image object");
};
