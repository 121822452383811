import { cx } from "@jugl-web/utils/cx";
import { FC, HTMLAttributes, ReactNode } from "react";

interface TaskLabelContainerProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

export const TaskLabelContainer = ({
  children,
  className,
  ...props
}: TaskLabelContainerProps) => (
  <div
    className={cx(
      "relative inline-flex h-5 flex-shrink-0 items-center text-xs font-semibold leading-[14px] tracking-[-0.01em] text-white outline outline-2 outline-white",
      className
    )}
    {...props}
  >
    {children}
  </div>
);

export interface TaskLabelGroupItem {
  color: string;
  content: ReactNode;
}

export interface TaskLabelGroupProps {
  items: TaskLabelGroupItem[];
  isInverted?: boolean;
  className?: string;
}

export const TaskLabelGroup: FC<TaskLabelGroupProps> = ({
  items,
  isInverted,
  className,
}: TaskLabelGroupProps) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <div className={cx(className, "inline-flex items-center overflow-hidden")}>
      {items.map((item, index) => {
        const isFirst = index === 0;
        const isLast = index === items.length - 1;

        return (
          <TaskLabelContainer
            key={+index}
            style={{ backgroundColor: item.color }}
            className={cx(
              !isFirst && "-ml-3",
              isLast
                ? isInverted
                  ? "rounded-tl-xl rounded-br-xl px-2"
                  : "rounded-tr-xl rounded-bl-xl px-2"
                : isInverted
                ? "rounded-tl-xl pl-2 pr-[18px]"
                : "rounded-bl-xl pl-2 pr-[18px]"
            )}
          >
            {item.content}
          </TaskLabelContainer>
        );
      })}
    </div>
  );
};
