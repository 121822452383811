/* eslint-disable @typescript-eslint/lines-between-class-members, class-methods-use-this */
import {
  $applyNodeReplacement,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalNode,
  NodeKey,
  SerializedTextNode,
  Spread,
  TextNode,
} from "lexical";

export type SerializedMentionNode = Spread<
  { userId: string; mentionName: string },
  SerializedTextNode
>;

const convertMentionElement = (
  element: HTMLElement
): DOMConversionOutput | null => {
  const { textContent } = element;
  const { userId } = element.dataset;

  if (!textContent || !userId) {
    return null;
  }

  return {
    node: $createMentionNode(userId, textContent),
  };
};

export class MentionNode extends TextNode {
  __userId: string;
  __mention: string;

  static getType(): string {
    return "mention";
  }

  static clone(node: MentionNode): MentionNode {
    return new MentionNode(
      node.__userId,
      node.__mention,
      node.__text,
      node.__key
    );
  }

  static importJSON(serializedNode: SerializedMentionNode): MentionNode {
    const node = $createMentionNode(
      serializedNode.userId,
      serializedNode.mentionName
    );

    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);

    return node;
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (node) => {
        if (!node.dataset.lexicalMentionNode) {
          return null;
        }

        return {
          conversion: convertMentionElement,
          priority: 1,
        };
      },
    };
  }

  constructor(
    userId: string,
    mentionName: string,
    text?: string,
    key?: NodeKey
  ) {
    super(text ?? mentionName, key);

    this.__userId = userId;
    this.__mention = mentionName;
  }

  createDOM(config: EditorConfig): HTMLElement {
    const element = super.createDOM(config);
    element.className = config.theme.mention;
    return element;
  }

  exportJSON(): SerializedMentionNode {
    return {
      ...super.exportJSON(),
      type: this.getType(),
      userId: this.__userId,
      mentionName: this.__mention,
      version: 1,
    };
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("span");
    element.dataset.lexicalMentionNode = "true";
    element.dataset.userId = this.__userId;
    element.textContent = this.__text;
    element.className = "font-bold text-primary";
    return { element };
  }

  isTextEntity(): boolean {
    return true;
  }

  canInsertTextBefore(): boolean {
    return false;
  }

  canInsertTextAfter(): boolean {
    return false;
  }
}

export const $createMentionNode = (
  userId: string,
  mentionName: string
): MentionNode => {
  const node = new MentionNode(userId, mentionName);
  node.setMode("segmented").toggleDirectionless();
  return $applyNodeReplacement(node);
};

export const $isMentionNode = (
  node: LexicalNode | null | undefined
): node is MentionNode => node instanceof MentionNode;
