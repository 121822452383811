import { TaskChecklistSummaryItem } from "@jugl-web/rest-api/tasks";
import { Checkbox, Text } from "@jugl-web/ui-components/cross-platform";
import React, { useCallback } from "react";
import { ReactComponent as ChecklistTaskIcon } from "./assets/checklist-task.svg";

export const CheckListCard: React.FC<{
  list: TaskChecklistSummaryItem[];
}> = ({ list }) => {
  const checklistItem = useCallback(
    (item: TaskChecklistSummaryItem) => (
      <div className="bg-grey-100 flex flex-row items-center justify-between gap-3 rounded-md py-3 px-4">
        <Text
          variant="body2"
          className="text-dark overflow-hidden text-ellipsis font-medium"
        >
          {item.name}
        </Text>
        <Checkbox isChecked className="cursor-not-allowed" />
      </div>
    ),
    []
  );
  if (!list) {
    return null;
  }

  return (
    <div className="flex flex-col gap-3 rounded-md bg-white p-4">
      <div className="flex flex-row items-center justify-start gap-3">
        <ChecklistTaskIcon />
        <Text variant="body3" className="text-grey-900 overflow-hidden">
          {list[0].task_name}
        </Text>
      </div>
      {list.map((item) => checklistItem(item))}
    </div>
  );
};
