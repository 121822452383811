import React, { memo } from "react";
import { ChatMessage } from "@web-src/features/chats/types";

const ChatMessageDebugInfo: React.FC<{
  onReadClick?: (message: ChatMessage) => void;
  message: ChatMessage;
}> = ({ onReadClick, message }) => (
  <div className="break-words bg-gray-100 text-black">
    <ul className="m-0 p-5">
      <li>
        Read by self: <b>{String(message.is_read_by_self) || "not-defined"}</b>
      </li>
      <li>
        Delivered: <b>{String(message.delivered) || "not-defined"}</b>
      </li>
      <li>
        Read: <b>{String(message.read) || "not-defined"}</b>
      </li>
      <li>
        id: <small>{message.msg_id}</small>
      </li>
      <li>
        rid: <small>{message.msg_receipt_id}</small>
      </li>
    </ul>
    <pre className="whitespace-pre-wrap">
      <small>{JSON.stringify(message)}</small>
    </pre>
    <button
      onClick={onReadClick?.bind(null, message)}
      type="button"
      // disabled={message.is_read_by_self}
    >
      {message.is_read_by_self ? "Already read" : "Mark as read"}
    </button>
  </div>
);

export default memo(ChatMessageDebugInfo);
