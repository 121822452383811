import { ConfirmationPopup } from "@jugl-web/ui-components/cross-platform";
import { HookOutOfContextError } from "@jugl-web/utils";
import { isImageUrl, isVideoUrl } from "@web-src/utils/helper";
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import ImageViewer from "react-simple-image-viewer";

type FilePreviewContextValue = {
  openFile: (url: string, name?: string) => void;
  closeFile: () => void;
};

const FilePreviewContext = createContext<FilePreviewContextValue | null>(null);

/**
 * @deprecated use FilePreviewProvider from domain-resources instead
 */
export const DeprecatedFilePreviewProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [openedFile, setOpenedFile] = useState<{
    url: string;
    type: "image" | "video" | "confirmation";
    name?: string;
  } | null>(null);
  const openFile = useCallback((url: string, name?: string) => {
    // TODO: other types will be handled once UI is ready
    if (isImageUrl(url)) {
      setOpenedFile({ url, type: "image", name });
    } else if (isVideoUrl(url)) {
      setOpenedFile({ url, type: "video", name });
    } else {
      setOpenedFile({ url, type: "confirmation", name });
    }
  }, []);

  const closeFile = useCallback(() => setOpenedFile(null), []);

  const value = useMemo(
    () => ({
      openFile,
      setOpenedFile,
      closeFile,
    }),
    [openFile, closeFile]
  );

  return (
    <FilePreviewContext.Provider value={value}>
      {children}
      {openedFile && openedFile.type === "image" && (
        <ImageViewer
          src={[openedFile.url]}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside
          onClose={closeFile}
          backgroundStyle={{
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            zIndex: 99999,
          }}
        />
      )}
      {openedFile && openedFile.type === "video" && (
        <div
          className="fixed top-0 bottom-0 left-0 right-0 z-[999999]"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
          onClick={closeFile}
        >
          <div
            className="relative top-[50px] left-[50px] flex items-center justify-center"
            style={{
              width: "calc(100% - 100px)",
              height: "calc(100% - 100px)",
            }}
          >
            <video
              className="max-h-[100%] min-h-[200px] min-w-[200px] max-w-[100%] rounded shadow-2xl"
              src={openedFile.url}
              controls
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        </div>
      )}
      <ConfirmationPopup
        variant="web"
        title={`Do you want to download ${
          openedFile?.name ? `"${openedFile.name}"` : ""
        } file?`}
        message=""
        hasCancelButton
        onRequestClose={closeFile}
        isOpen={openedFile?.type === "confirmation"}
        buttons={[
          {
            label: "Download",
            onClick: () => {
              closeFile();
              window.open(openedFile?.url, "_blank");
            },
          },
        ]}
      />
    </FilePreviewContext.Provider>
  );
};

/**
 * @deprecated use useFilePreview from domain-resources instead
 */
export const useDeprecatedFilePreview = () => {
  const context = useContext(FilePreviewContext);

  if (!context) {
    throw new HookOutOfContextError(
      "useDeprecatedFilePreview",
      "FilePreviewContext"
    );
  }

  return context;
};
