import { stripTaskDescriptionPrefix } from "../../utils";
import { GuestTaskDataDto } from "./GuestTaskDataDto";
import { GuestTaskDataModel } from "./GuestTaskDataModel";

export const transformGuestDataDtoToModel = (
  dto: GuestTaskDataDto
): GuestTaskDataModel => ({
  task: {
    ...dto.module_res,
    desc: dto.module_res.desc
      ? stripTaskDescriptionPrefix(dto.module_res.desc)
      : null,
  },
  activity: dto.guest_activity,
  entity: dto.entity,
  hasGuestFeedback: !!dto?.guest_activity.find(
    (item) => item.action_details?.action === "guest_feedback"
  ),
  customStatuses: dto.custom_status,
});
