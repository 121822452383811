import { EntityState } from "@reduxjs/toolkit";
import { RtkEmptySplitApi } from "../../types";
import { TaskDefaultStatus, TaskType } from "../tasks/types";
import { filterTasksWithoutName } from "../tasks/utils";
import { TasksTemplatesApiTag } from "./tags";
import {
  CreateOrUpdateTaskTemplateFolderPayload,
  CreateOrUpdateTaskTemplatePayload,
  DetailedTaskTemplate,
  PreviewTaskTemplate,
  TaskTemplateFolder,
} from "./types";
import { previewTasksTemplatesAdapter } from "./utils";

export const adaptDetailedTemplateToPreviewTemplate = (
  detailedTemplate: DetailedTaskTemplate
): PreviewTaskTemplate => ({
  ...detailedTemplate,
  chklist_total: detailedTemplate.checklist.length,
});

export const addTasksTemplatesApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [TasksTemplatesApiTag.folder, TasksTemplatesApiTag.template],
  });

  const tasksTemplatesApi = apiWithTags.injectEndpoints({
    endpoints: (builder) => ({
      // #region Template folders
      getTemplateFolders: builder.query<
        TaskTemplateFolder[],
        { entityId: string }
      >({
        query: ({ entityId }) => ({
          url: `/api/auth/entity/${entityId}/template_folders`,
        }),
        providesTags: [TasksTemplatesApiTag.folder],
      }),

      createTemplateFolder: builder.mutation<
        TaskTemplateFolder,
        {
          entityId: string;
          folder: CreateOrUpdateTaskTemplateFolderPayload;
        }
      >({
        query: ({ entityId, folder }) => ({
          url: `/api/auth/entity/${entityId}/template_folders`,
          method: "POST",
          data: folder,
        }),
        onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
          try {
            const { data: newFolder } = await queryFulfilled;

            dispatch(
              tasksTemplatesApi.util.updateQueryData(
                "getTemplateFolders",
                { entityId: args.entityId },
                (draft) => {
                  draft.push(newFolder);
                }
              )
            );
          } catch {
            // Do nothing
          }
        },
      }),

      updateTemplateFolder: builder.mutation<
        TaskTemplateFolder,
        {
          entityId: string;
          folderId: string;
          folder: CreateOrUpdateTaskTemplateFolderPayload;
        }
      >({
        query: ({ entityId, folderId, folder }) => ({
          url: `/api/auth/entity/${entityId}/template_folders/${folderId}`,
          method: "PUT",
          data: folder,
        }),
        onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
          try {
            const { data: updatedFolder } = await queryFulfilled;

            dispatch(
              tasksTemplatesApi.util.updateQueryData(
                "getTemplateFolders",
                { entityId: args.entityId },
                (draft) => {
                  const folderIndex = draft.findIndex(
                    (f) => f.id === args.folderId
                  );

                  if (folderIndex > -1) {
                    draft[folderIndex] = updatedFolder;
                  }
                }
              )
            );
          } catch {
            // Do nothing
          }
        },
      }),

      deleteTemplateFolder: builder.mutation<
        void,
        { entityId: string; folderId: string; replacementFolderId?: string }
      >({
        query: ({ entityId, folderId, replacementFolderId }) => ({
          url: `/api/auth/entity/${entityId}/template_folders/${folderId}${
            replacementFolderId ? `?move_to=${replacementFolderId}` : ""
          }`,
          method: "DELETE",
        }),
        onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
          try {
            await queryFulfilled;

            dispatch(
              tasksTemplatesApi.util.updateQueryData(
                "getTemplateFolders",
                { entityId: args.entityId },
                (draft) => {
                  const folderIndex = draft.findIndex(
                    (f) => f.id === args.folderId
                  );

                  if (folderIndex > -1) {
                    draft.splice(folderIndex, 1);
                  }
                }
              )
            );
          } catch {
            // Do nothing
          }
        },
      }),
      // #endregion

      // #region Templates
      getTemplates: builder.query<
        EntityState<PreviewTaskTemplate>,
        { entityId: string }
      >({
        query: ({ entityId }) => ({
          url: `/api/auth/entity/${entityId}/v2/task`,
          params: { type: TaskType.template },
        }),
        transformResponse: (templates: PreviewTaskTemplate[]) =>
          previewTasksTemplatesAdapter.addMany(
            previewTasksTemplatesAdapter.getInitialState(),
            filterTasksWithoutName(templates)
          ),
        providesTags: [TasksTemplatesApiTag.template],
      }),

      getTemplate: builder.query<
        DetailedTaskTemplate,
        { entityId: string; templateId: string }
      >({
        query: ({ entityId, templateId }) => ({
          url: `/api/auth/entity/${entityId}/task/${templateId}`,
        }),
      }),

      createTemplate: builder.mutation<
        DetailedTaskTemplate,
        { entityId: string; template: CreateOrUpdateTaskTemplatePayload }
      >({
        query: ({ entityId, template }) => {
          const transformedTemplate = {
            ...template,
            status: TaskDefaultStatus.notStarted,
            type: TaskType.template,
          };

          return {
            url: `/api/auth/entity/${entityId}/task`,
            method: "POST",
            data: transformedTemplate,
          };
        },
      }),

      updateTemplate: builder.mutation<
        { task: DetailedTaskTemplate },
        {
          entityId: string;
          templateId: string;
          template: CreateOrUpdateTaskTemplatePayload;
        }
      >({
        query: ({ entityId, templateId, template }) => ({
          url: `/api/auth/entity/${entityId}/task/${templateId}`,
          method: "PUT",
          data: template,
        }),
      }),

      deleteTemplate: builder.mutation<
        void,
        { entityId: string; templateId: string }
      >({
        query: ({ entityId, templateId }) => ({
          url: `/api/auth/entity/${entityId}/task/${templateId}`,
          method: "DELETE",
        }),
      }),
      // #endregion
    }),
    overrideExisting: false,
  });

  return tasksTemplatesApi;
};

export type TasksTemplatesApi = ReturnType<typeof addTasksTemplatesApi>;
