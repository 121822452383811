import { TaskAttachment } from "@jugl-web/rest-api/tasks";
import { Alert } from "@jugl-web/ui-components/cross-platform/Alert";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { MobileDrawer } from "@jugl-web/ui-components/mobile/MobileDrawer";
import { useAppVariant, useTranslations } from "@jugl-web/utils";
import { FC } from "react";

interface RemoveAttachmentDialogProps {
  isOpen: boolean;
  attachment: TaskAttachment;
  onRemove: () => void;
  onClose: () => void;
}

export const RemoveAttachmentDialog: FC<RemoveAttachmentDialogProps> = ({
  isOpen,
  attachment,
  onRemove,
  onClose,
}) => {
  const { t } = useTranslations();
  const { isMobile } = useAppVariant();

  const labels = {
    title: t({
      id: "common.delete-file",
      defaultMessage: "Delete file",
    }),
    message: t(
      {
        id: "tasks-page.confirm-delete-attachment",
        defaultMessage:
          "Are you sure you want to delete task attachment {attachmentName}?",
      },
      {
        attachmentName: (
          <span className="text-primary-800 font-semibold">
            {attachment.name}
          </span>
        ),
      }
    ),
    delete: t({
      id: "common.delete",
      defaultMessage: "Delete",
    }),
    cancel: t({
      id: "common.cancel",
      defaultMessage: "Cancel",
    }),
  };

  if (isMobile) {
    return (
      <MobileDrawer
        isOpen={isOpen}
        header={{ title: labels.title }}
        onClose={onClose}
      >
        <MobileDrawer.Content>
          <p className="text-onyx mt-2 mb-10 text-sm leading-[21px]">
            {labels.message}
          </p>
          <div className="flex flex-col gap-2.5">
            <Button
              color="primary"
              variant="contained"
              onClick={onRemove}
              uppercase
            >
              {labels.delete}
            </Button>
            <Button color="primary" variant="text" onClick={onClose} uppercase>
              {labels.cancel}
            </Button>
          </div>
        </MobileDrawer.Content>
      </MobileDrawer>
    );
  }

  return (
    <Alert
      isOpen={isOpen}
      title={labels.title}
      content={labels.message}
      buttons={[
        {
          text: labels.cancel,
          role: "close",
        },
        {
          text: labels.delete,
          color: "tertiary",
          onClick: onRemove,
        },
      ]}
      onRequestClose={onClose}
    />
  );
};
