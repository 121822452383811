import { FC, useMemo, useRef, useState } from "react";
import { Alert } from "@jugl-web/ui-components/cross-platform";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useToast } from "@jugl-web/utils";
import { ReactComponent as BillingInfoIcon } from "./assets/billing-info-icon.svg";
import {
  BillingInfoForm,
  BillingInfoFormHandle,
  BillingInfoFormType,
} from "./components/BillingInfoForm";
import { InfoItem } from "../InfoItem";

export const BillingInfoItem: FC = () => {
  const { entitiesApi } = useRestApiProvider();
  const { entityId, refetchSubscriptionInfo } = useEntitySelectedProvider();
  const [updateBillingInfo, { isLoading }] =
    entitiesApi.useUpdateBillingInfoMutation();

  const { toast } = useToast({ variant: "web" });

  const [isBillingInfoUpdateOpen, setIsBillingInfoUpdateOpen] = useState(false);

  const { subscriptionInfo } = useEntitySelectedProvider();

  const formRef = useRef<BillingInfoFormHandle | null>(null);

  const handleFormSubmit = async (
    values: BillingInfoFormType,
    closeFormAlert: () => void
  ) => {
    const response = await updateBillingInfo({
      entityId,
      data: { address: values.address, name: values.name, notes: values.notes },
    });
    if (!("data" in response)) {
      return;
    }
    refetchSubscriptionInfo();
    toast("Billing information was updated");
    closeFormAlert();
  };

  const { title, subtitle } = useMemo<{
    title: string;
    subtitle: string;
  }>(() => {
    if (subscriptionInfo?.customerAddress || subscriptionInfo.customerName) {
      return {
        title: `${subscriptionInfo.customerName}${
          subscriptionInfo.customerAddress?.country
            ? `, ${subscriptionInfo.customerAddress?.country}`
            : ""
        }`,
        subtitle: "Billing Information",
      };
    }
    return {
      title: "No info added",
      subtitle: "Billing information",
    };
  }, [subscriptionInfo.customerAddress, subscriptionInfo.customerName]);

  return (
    <>
      <InfoItem
        title={title}
        description={subtitle}
        icon={<BillingInfoIcon />}
        action={{
          onClick: () => setIsBillingInfoUpdateOpen(true),
          text: "Manage",
          color: "gray",
        }}
      />
      {!!subscriptionInfo && (
        <Alert
          isOpen={isBillingInfoUpdateOpen}
          header="Edit billing information"
          content={
            <BillingInfoForm
              ref={formRef}
              subscriptionInfo={subscriptionInfo}
            />
          }
          onRequestClose={() => setIsBillingInfoUpdateOpen(false)}
          buttons={[
            {
              text: "Save",
              onClick: (e, { closeAlert }) => {
                formRef.current?.handleSubmit((values) => {
                  if (!values) {
                    return;
                  }
                  handleFormSubmit(values, closeAlert);
                })();
              },
              isDisabled: isLoading,
            },
          ]}
          isCloseButtonVisible
        />
      )}
    </>
  );
};
