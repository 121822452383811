import React, { memo } from "react";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as SendIcon } from "@web-src/images/icons/send.svg";

const ChatInputButton: React.FC<{
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  color?: "primary" | "success";
}> = memo(({ disabled, onClick, loading, color = "primary" }) => {
  const backgroundColor =
    color === "primary" ? "primary.500" : "juglSecondary.500";
  return (
    <IconButton
      color={color}
      disabled={disabled}
      onClick={onClick}
      sx={{
        width: "44px",
        height: "44px",
        borderRadius: "22px",
        color: "white",
        backgroundColor,
        [`&:disabled`]: { color: "white", backgroundColor: "grey.300" },
        [`&:hover`]: {
          backgroundColor,
        },
      }}
    >
      {!loading ? (
        <SvgIcon component={SendIcon} viewBox="0 0 24 24" />
      ) : (
        <CircularProgress size={20} color="info" />
      )}
    </IconButton>
  );
});

export default ChatInputButton;
