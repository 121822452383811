import React, { memo, useMemo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { selectUserId } from "@web-src/features/auth/authSlice";
import ReplyIcon from "@mui/icons-material/Reply";
import UserProfileName from "@web-src/features/users/components/UserProfileName";
import ChatMessageAttachment from "./ChatMessageAttachment";
import { ChatMessage } from "../../types";
import { clearMessageBody } from "../../utils";
import ChatMessageCall from "./ChatMessageCall";

const ChatMessageReplyMessageBubble: React.FC<{
  message: ChatMessage;
}> = memo(({ message }) => {
  const meId = useSelector(selectUserId);
  const isParentOutgoing = useMemo(
    () => message?.from === meId,
    [message, meId]
  );
  const body = useMemo(() => {
    const messageBody =
      message?.payload?.reply_attributes_map?.reply_msg_body ||
      message?.payload?.reply_attributes_map?.reply_attachments?.[0]?.caption ||
      "";
    return clearMessageBody(messageBody);
  }, [message]);
  if (
    !message?.payload?.reply_attributes_map ||
    (!body &&
      !message?.payload?.reply_attributes_map?.reply_attachments &&
      !message?.payload?.reply_attributes_map?.reply_conference?.channel)
  ) {
    return null;
  }
  return (
    <Box
      sx={{
        bgcolor: isParentOutgoing ? "white" : "grey.200",
        borderRadius: "4px",
        padding: "10px",
        filter: isParentOutgoing
          ? // TODO: hardcoded color
            "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.17))"
          : undefined,
      }}
    >
      <Typography
        variant="bodyBold"
        fontSize="12px"
        // TODO: hardcoded color
        color="#B021F3"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <UserProfileName
          userId={message.payload.reply_attributes_map.reply_msg_user}
        />
        <ReplyIcon
          sx={{
            fontSize: "20px",
            color: isParentOutgoing ? undefined : "grey.A100",
          }}
        />
      </Typography>
      {message.payload.reply_attributes_map?.reply_attachments?.length ? (
        <div className="mb-[10px]">
          <ChatMessageAttachment reply />
        </div>
      ) : null}
      {message?.payload?.reply_attributes_map?.reply_conference?.channel && (
        <ChatMessageCall
          conference={message?.payload?.reply_attributes_map?.reply_conference}
          isOutgoing={isParentOutgoing}
          reply
        />
      )}
      {body && (
        <Typography
          variant="body"
          fontSize="15px"
          lineHeight="18px"
          color="dark.A100"
          whiteSpace="pre-wrap"
          sx={{
            wordWrap: "break-word",
          }}
        >
          {body}
        </Typography>
      )}
    </Box>
  );
});

export default memo(ChatMessageReplyMessageBubble);
