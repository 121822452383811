import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { BroadcastChannel } from "broadcast-channel";
import { BroadcastChannelName } from "@web-src/features/app/types";
import { getAppId } from "@web-src/features/app/utils";
import { logout as logoutDispatch } from "@web-src/features/auth/authSlice";
import { useAppDispatch } from "@web-src/store";
import { getSessionId } from "@web-src/utils/session";
import { HookOutOfContextError, useTranslations } from "@jugl-web/utils";
import { useUserLogoutMutation } from "@web-src/features/auth/authApi";
import { DeviceType } from "@web-src/features/auth/types";
import { Alert, PageLoaderFull } from "@jugl-web/ui-components/cross-platform";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { TIMEZONE_MISMATCH_ALERT_SHOWN } from "@jugl-web/utils/storage";

interface AuthBroadcastChannelMessage {
  emitterId: string;
}

interface UserAuthContextValue {
  logout: (showAlert: boolean) => void;
}

const UserAuthContext = createContext<UserAuthContextValue | null>(null);

const channel: BroadcastChannel<AuthBroadcastChannelMessage> =
  new BroadcastChannel(BroadcastChannelName.auth);

export const UserAuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const dispatch = useAppDispatch();
  const [userLogout, { isLoading }] = useUserLogoutMutation();
  const { navigateToPage } = useNavigation();
  const { t } = useTranslations();
  const emitterId = getSessionId();

  const [isAlertVisible, setIsAlertVisible] = useState(false);

  useEffect(() => {
    const messageHandler = (e: AuthBroadcastChannelMessage) => {
      if (emitterId !== e.emitterId) {
        window.location.reload();
      }
    };
    channel.addEventListener("message", messageHandler);
    return () => {
      channel.removeEventListener("message", messageHandler);
    };
  }, [emitterId]);

  const handleLogout = useCallback(async () => {
    await userLogout({
      app_id: getAppId(),
      device_type: DeviceType.web,
    });
    await dispatch(logoutDispatch());
    setIsAlertVisible(false);
    navigateToPage("authLogin", undefined, { replace: true });
    channel.postMessage({
      emitterId,
    });
    localStorage.removeItem(TIMEZONE_MISMATCH_ALERT_SHOWN);
  }, [emitterId, dispatch, navigateToPage, userLogout]);

  const logout = useCallback(
    (showAlert: boolean) =>
      showAlert ? setIsAlertVisible(true) : handleLogout(),
    [handleLogout]
  );

  const contextValue = useMemo<UserAuthContextValue>(
    () => ({ logout }),
    [logout]
  );

  return (
    <UserAuthContext.Provider value={contextValue}>
      {children}
      <Alert
        title={t({
          id: "common.logout",
          defaultMessage: "Logout",
        })}
        isOpen={isAlertVisible}
        onRequestClose={() => setIsAlertVisible(false)}
        content={t({
          id: "logout-provider.logout-confirmation-message",
          defaultMessage: "Are you sure you want to log out?",
        })}
        buttons={[
          {
            text: t({
              id: "common.cancel",
              defaultMessage: "Cancel",
            }),
            role: "close",
          },
          {
            text: t({
              id: "common.logout",
              defaultMessage: "Logout",
            }),
            isDisabled: isLoading,
            onClick: handleLogout,
          },
        ]}
      />
      <PageLoaderFull isActive={isLoading} isTransparent />
    </UserAuthContext.Provider>
  );
};

export const useUserAuth = () => {
  const context = useContext(UserAuthContext);

  if (!context) {
    throw new HookOutOfContextError("useUserAuth", "UserAuthContext");
  }

  return context;
};
