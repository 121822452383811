import { onEnter, useTranslations } from "@jugl-web/utils";
import { Text } from "@jugl-web/ui-components/cross-platform/Text";
import {
  Input,
  Button,
  PhoneInput,
  phoneStringToInputValue,
} from "@jugl-web/ui-components/cross-platform";
import { validateEmail } from "@web-src/utils/helper";
import { useState } from "react";
import newMethodAnimation from "./assets/newlogin.gif";
import { LoginMethodType } from "../../../../types";

export const InputStep: React.FC<{
  type: LoginMethodType;
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
}> = ({ type, value, onChange, onSubmit }) => {
  const [isMobileValid, setIsMobileValid] = useState(false);
  const { t } = useTranslations();
  const isEmailValid = type === "email" && validateEmail(value);
  return (
    <div className="flex w-full flex-col items-center justify-center pt-[112px]">
      <div className="h-[156px] w-[184px]">
        <img
          src={newMethodAnimation}
          alt="animation"
          className="h-full w-full"
        />
      </div>
      <Text
        variant="h2"
        className="font-secondary text-dark text-[32px] leading-[16px]"
      >
        {t({
          id: "settings-page.new-login-method",
          defaultMessage: "New Login Method",
        })}
      </Text>
      <Text variant="body2" className="text-dark-600 w-[450px] text-center">
        {type === "email" &&
          t({
            id: "settings-page.new-login-method-step-input-description",
            defaultMessage:
              "Please enter your new Email, we will send an OTP code for confirmation",
          })}
        {type === "mobile" &&
          t({
            id: "settings-page.new-login-method-step-input-mobile-description",
            defaultMessage:
              "Please enter your new Phone number, we will send an OTP code for confirmation",
          })}
      </Text>
      {type === "email" && (
        <div className="flex-gap-1 mt-14 flex w-[400px] flex-col text-xs text-gray-500">
          <div>
            {t({
              id: "common.email",
              defaultMessage: "Email",
            })}
          </div>
          <Input
            type="email"
            onChange={(e) => onChange(e.target.value)}
            onKeyDown={isEmailValid && onEnter(onSubmit)}
            value={value}
            placeholder={t({
              id: "common.email",
              defaultMessage: "Email",
            })}
            isError={(value && !isEmailValid) || false}
          />
        </div>
      )}
      {type === "mobile" && (
        <div className="mt-14 w-[400px]">
          <PhoneInput
            value={phoneStringToInputValue(value)}
            onChange={({ phone, code, isValid }) => {
              onChange(`${code}${phone}`);
              setIsMobileValid(!!isValid);
            }}
            placeholder={t({
              id: "form-controls.mobile-number.placeholder",
              defaultMessage: "Mobile Number",
            })}
          />
        </div>
      )}
      <Button
        className="mt-14 w-[300px]"
        isDisabled={type === "email" ? !isEmailValid : !isMobileValid}
        onClick={onSubmit}
      >
        {t({
          id: "common.continue",
          defaultMessage: "Continue",
        })}
      </Button>
    </div>
  );
};
