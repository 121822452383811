import { TemplateFilter } from "@jugl-web/domain-resources/tasks/hooks/useTasksTemplates";
import { HookOutOfContextError } from "@jugl-web/utils";
import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";

interface TaskTemplatesPageContextValue {
  searchQuery: string;
  filter: TemplateFilter;
  isNewTemplateDialogOpen: boolean;
  setSearchQuery: Dispatch<SetStateAction<string>>;
  setFilter: Dispatch<SetStateAction<TemplateFilter>>;
  setIsNewTemplateDialogOpen: Dispatch<SetStateAction<boolean>>;
}

const TaskTemplatesPageContext =
  createContext<TaskTemplatesPageContextValue | null>(null);

interface TaskTemplatesPageContextProviderProps {
  children: ReactNode;
}

export const TaskTemplatesPageContextProvider: FC<
  TaskTemplatesPageContextProviderProps
> = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const [filter, setFilter] = useState<TemplateFilter>(
    TemplateFilter.AllTemplates
  );

  const [isNewTemplateDialogOpen, setIsNewTemplateDialogOpen] = useState(false);

  const contextValue = useMemo<TaskTemplatesPageContextValue>(
    () => ({
      searchQuery,
      filter,
      isNewTemplateDialogOpen,
      setSearchQuery,
      setFilter,
      setIsNewTemplateDialogOpen,
    }),
    [filter, isNewTemplateDialogOpen, searchQuery]
  );

  return (
    <TaskTemplatesPageContext.Provider value={contextValue}>
      {children}
    </TaskTemplatesPageContext.Provider>
  );
};

export const useTaskTemplatesPageContext = () => {
  const context = useContext(TaskTemplatesPageContext);

  if (!context) {
    throw new HookOutOfContextError(
      "useTaskTemplatesPageContext",
      "TaskTemplatesPageContext"
    );
  }

  return context;
};
