import { FC, ReactNode } from "react";
import { PlainButton } from "../../cross-platform/PlainButton";
import { ReactComponent as CloseIcon } from "./assets/close.svg";

export interface ResourcePickerPopoverHeaderProps {
  title: ReactNode;
  subtitle?: ReactNode;
  onClose: () => void;
}

export const ResourcePickerPopoverHeader: FC<
  ResourcePickerPopoverHeaderProps
> = ({ title, subtitle, onClose }) => (
  <div>
    <div className="flex items-center justify-between p-4">
      <div className="flex flex-col gap-1">
        <span className="text-dark text-base font-medium">{title}</span>
        {subtitle && <span className="text-xs text-[#828282]">{subtitle}</span>}
      </div>
      <PlainButton onClick={onClose} className="relative">
        <CloseIcon />
      </PlainButton>
    </div>
    <div className="px-4">
      <div className="bg-dark-100 h-[1px] w-full" />
    </div>
  </div>
);
