import { Alert } from "@jugl-web/ui-components/cross-platform";
import React from "react";
import Lottie from "react-lottie";
import { useTranslations } from "@jugl-web/utils";
import welcomeAnimation from "./assets/welcome-animation.json";

export const OnboardingBeginningDialog: React.FC<{
  isOpen: boolean;
  onRequestClose: () => void;
}> = ({ isOpen, onRequestClose }) => {
  const { t } = useTranslations();

  return (
    <Alert
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      disableBackdropClose
      img={
        <Lottie
          options={{ animationData: welcomeAnimation }}
          height={225}
          width={300}
        />
      }
      title={t({
        id: "onboarding-beginning-dialog-component.welcome-to-jugl",
        defaultMessage: "Welcome to the Jugl!",
      })}
      content={t(
        {
          id: "onboarding-beginning-dialog-component.onboarding-basic-steps",
          defaultMessage:
            "Lets start with some <b>basic steps</b> that will help you to get to know the Jugl environment 🚀 Don't worry, it will take you only up to <b>5 minutes</b>, as we value your time 🤗",
        },
        {
          b: (text: (string | JSX.Element)[]) => (
            <span className="text-primary-800 font-semibold">{text}</span>
          ),
        }
      )}
      buttons={[
        {
          text: t({
            id: "onboarding-beginning-dialog-component.lets-start",
            defaultMessage: "Let's Start",
          }),
          onClick: onRequestClose,
        },
      ]}
    />
  );
};
