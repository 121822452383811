import { cx } from "@jugl-web/utils";
import React, { useMemo } from "react";
import { Tooltip } from "@jugl-web/ui-components/cross-platform/Tooltip";
import { PlainButton } from "@jugl-web/ui-components/cross-platform";
import { ReactComponent as CheckIcon } from "./assets/check.svg";

export interface Step {
  id: string;
  label: string;
  isCompleted: boolean;
}

export interface StepperProps {
  steps: Step[];
  activeStepIndex: number;
  className?: string;
  onChange: (index: number) => void;
}

export const Stepper: React.FC<StepperProps> = ({
  steps,
  activeStepIndex,
  className,
  onChange,
}) => {
  const currentStepIndex = useMemo(
    () =>
      steps.reduce(
        (acc, step, index) => (step.isCompleted ? index + 1 : acc),
        0
      ),
    [steps]
  );

  return (
    <div
      className={cx("flex w-full items-center justify-between p-4", className)}
    >
      {steps.map((step, idx) => {
        const isFirstStep = idx === 0;
        const isActiveStep = idx === activeStepIndex;
        const isBeforeCurrentStep = idx <= currentStepIndex;
        return (
          <div
            className={cx("flex items-center", !isFirstStep && "grow")}
            key={step.id}
          >
            <div
              className={cx(
                "h-1 w-full",
                isFirstStep && "hidden",
                isBeforeCurrentStep ? "bg-primary-400" : "bg-dark-100"
              )}
            />
            <Tooltip
              delay="none"
              className="z-[2000]"
              renderTrigger={({ ref, props }) => (
                <PlainButton
                  onClick={() => onChange(idx)}
                  ref={ref}
                  {...props}
                  className="group relative"
                >
                  <div
                    className={cx(
                      "group-hover:bg-primary-50 absolute top-1/2 left-1/2 h-9 w-9 -translate-x-1/2 -translate-y-1/2 rounded-full transition-colors",
                      isActiveStep && step.isCompleted && "bg-primary-50"
                    )}
                  />
                  <div
                    className={cx(
                      "relative flex h-4 w-4 shrink-0 items-center justify-center rounded-full border-2 border-solid border-white transition-colors",
                      isActiveStep ? "bg-primary" : "bg-dark-100",
                      step.isCompleted && "bg-gradients-success"
                    )}
                  >
                    {step.isCompleted && <CheckIcon />}
                  </div>
                </PlainButton>
              )}
            >
              {step.label}
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
};
