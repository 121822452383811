import { UserProfile } from "@jugl-web/rest-api";

export const getUserProfileDisplayName = (profile: UserProfile): string => {
  if (profile && (profile.general?.first_name || profile.general?.last_name)) {
    const strings: string[] = [];
    if (profile.general?.first_name) {
      strings.push(profile.general?.first_name);
    }
    if (profile.general?.last_name) {
      strings.push(profile.general?.last_name);
    }
    return strings.join(" ");
  }
  return profile.general?.first_name;
};
