import React from "react";

interface DriveButtonProps {
  Icon: React.FC;
  onClick?: () => void;
}

const DriveButton: React.FC<DriveButtonProps> = ({ Icon, onClick }) => (
  <button
    type="button"
    className="relative flex h-10 w-10 cursor-pointer items-center justify-center rounded-xl border-none bg-white"
    onClick={onClick}
    onKeyDown={onClick}
  >
    <Icon />
  </button>
);

export default DriveButton;
