import { LiveUpdateEvent } from "@jugl-web/domain-resources/live-updates";
import {
  PhoenixSocketContext,
  PhxResponse,
} from "@web-src/features/chats/providers/PheonixSocket";
import { logger } from "@web-src/utils/logger";
import { FC, useContext, useEffect } from "react";
import { Subject } from "rxjs";

const LIVE_UPDATES_CHANNEL = "update:task";
const LIVE_UPDATE_MESSAGE = "phx_task_updates";

export interface LiveUpdatesListenerProps {
  events$: Subject<LiveUpdateEvent>;
}

export const LiveUpdatesListener: FC<LiveUpdatesListenerProps> = ({
  events$,
}) => {
  const { socket } = useContext(PhoenixSocketContext);

  useEffect(() => {
    if (!socket) {
      return undefined;
    }

    const channel = socket.channel(LIVE_UPDATES_CHANNEL);

    channel
      .join()
      .receive("error", (e) => {
        logger.error(
          `[${LIVE_UPDATES_CHANNEL} channel] Read access denied.`,
          e
        );
      })
      .receive("ok", () => {
        logger.info(`[${LIVE_UPDATES_CHANNEL} channel] Read access granted.`);
      });

    channel.on(
      LIVE_UPDATE_MESSAGE,
      ({ response }: PhxResponse<LiveUpdateEvent>) => {
        events$.next(response);
      }
    );

    return () => {
      channel.leave();
    };
  }, [events$, socket]);

  return null;
};
