import { UserCPanelModel, UserRole } from "@jugl-web/rest-api";
import React, { useCallback } from "react";
import { Alert, Avatar } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { usersApi } from "@web-src/features/api/createApi";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { ReactComponent as AlertIcon } from "./assets/alert.svg";
import { useCPanelPageContext } from "../../CPanelPageProvider";

export const UserRoleChangeAlert: React.FC<{
  isOpen: boolean;
  user?: UserCPanelModel;
  onRequestClose: () => void;
}> = ({ isOpen, user, onRequestClose }) => {
  const { t } = useTranslations();
  const isAdmin = user?.role === "admin";
  const { entityId } = useEntitySelectedProvider();
  const { updateUser$ } = useCPanelPageContext();

  const [updateUsersApi, { isLoading }] = usersApi.useUpdateUserMutation();

  const updateUserRole = useCallback(async () => {
    if (!user?.entity_rel_id) return;
    const response = await updateUsersApi({
      entityId,
      entityRelId: user.entity_rel_id,
      data: {
        role: isAdmin ? UserRole.member : UserRole.admin,
      },
    });
    if ("data" in response) {
      onRequestClose();
      updateUser$.next({
        id: user.entity_rel_id,
        data: { role: isAdmin ? UserRole.member : UserRole.admin },
      });
    }
  }, [user, updateUsersApi, entityId, isAdmin, onRequestClose, updateUser$]);

  return (
    <Alert
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      isCloseButtonVisible
      title={t({
        id: "cpanel-page.admin-role",
        defaultMessage: "Admin Role",
      })}
      img={
        <div className="relative grid h-[150px] w-[150px]">
          <Avatar
            imageUrl={user?.profile?.img || ""}
            username={user?.display_name || ""}
            className="h-full w-full"
          />
          <div className="absolute bottom-0 right-0 flex h-[60px] w-[60px] items-center justify-center rounded-full bg-white">
            <AlertIcon />
          </div>
        </div>
      }
      content={
        isAdmin
          ? t(
              {
                id: "cpanel-page.remove-admin-role-confirmation-message",
                defaultMessage:
                  "Do you really want to <highlightedText>remove Admin</highlightedText> role for <highlightedText>{username}</highlightedText>? 🔐",
              },
              {
                highlightedText: (text: (string | JSX.Element)[]) => (
                  <span className="text-primary-800 font-semibold">{text}</span>
                ),
                username: user.display_name,
              }
            )
          : t(
              {
                id: "cpanel-page.assigning-admin-role-confirmation-message",
                defaultMessage:
                  "Do you really want to change role for <highlightedText>{username}</highlightedText> to <highlightedText>Admin</highlightedText>? 🔐 User with Admin role can edit all Modules, and even Delete the Workspace",
              },
              {
                highlightedText: (text: (string | JSX.Element)[]) => (
                  <span className="text-primary-800 font-semibold">{text}</span>
                ),
                username: user?.display_name || "",
              }
            )
      }
      buttons={[
        {
          text: isAdmin
            ? t({
                id: "cpanel-page.remove-role",
                defaultMessage: "Remove role",
              })
            : t({
                id: "common.confirm",
                defaultMessage: "Confirm",
              }),
          color: isAdmin ? "tertiary" : "primary",
          isDisabled: isLoading,
          onClick: updateUserRole,
        },
      ]}
    />
  );
};
