import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import React, { useCallback, useMemo, useState } from "react";
import { useUserAuth } from "@web-src/modules/auth/providers/UserAuthProvider";
import { useTranslations } from "@jugl-web/utils";
import {
  getLanguagesByNames,
  useLanguage,
} from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { MultiSectionLayout } from "@jugl-web/ui-components/cross-platform";
import { HeaderBreadcrumbs } from "@jugl-web/ui-components/web";
import { ReactComponent as LanguageIcon } from "./assets/language.svg";
import { ReactComponent as TimeZoneIcon } from "./assets/time-zone.svg";
import { ReactComponent as BellIcon } from "./assets/bell.svg";
import { ReactComponent as BuildingIcon } from "./assets/building-line.svg";
import { ReactComponent as LogOutIcon } from "./assets/log-out.svg";
import { ReactComponent as PrivacyIcon } from "./assets/privacy.svg";
import Tile from "./components/Tile";
import { NotificationsSettingsSidebar } from "./components/NotificationsSettingsSidebar";
import { LanguagePickerDrawer } from "./components/LanguagePickerDrawer";
import { TimeZoneSettingsDialog } from "./components/TimeZoneSettingsDialog";
import { WelcomeToEntityAlert } from "./components/WelcomeToEntityAlert";

const SettingsPage: React.FC = () => {
  const [isLanguagePickerDrawerOpen, setIsLanguagePickerDrawerOpen] =
    useState(false);
  const [isTimeZonePickerOpen, setIsTimeZonePickerOpen] = useState(false);
  const [isNotificationsSidebarOpen, setIsNotificationsSidebarOpen] =
    useState(false);
  const { language } = useLanguage();
  const { navigateToPage } = useNavigation();
  const { logout } = useUserAuth();
  const { t } = useTranslations();
  const languageToTranslatedName = useMemo(() => getLanguagesByNames(t), [t]);
  const tileList: {
    id: string;
    title: string;
    subTitle?: string;
    icon: React.ReactNode;
    onClick: () => void;
  }[] = [
    {
      id: "account-settings",
      title: t({
        id: "settings-page.account-settings",
        defaultMessage: "Account Settings",
      }),
      subTitle: undefined,
      icon: <BuildingIcon />,
      onClick: useCallback(() => {
        navigateToPage("settingsEditProfile", { section: "personal-info" });
      }, [navigateToPage]),
    },
    {
      id: "language",
      title: t({
        id: "common.language",
        defaultMessage: "Language",
      }),
      subTitle: languageToTranslatedName[language],
      icon: <LanguageIcon />,
      onClick: () => {
        setIsLanguagePickerDrawerOpen(true);
      },
    },
    {
      id: "privacy-policy",
      title: t({
        id: "settings-page.privacy-policy",
        defaultMessage: "Privacy Policy",
      }),
      subTitle: undefined,
      icon: <PrivacyIcon />,
      onClick: useCallback(() => {
        window.open(`https://jugl.com/privacy-policy-app/`, "_blank");
      }, []),
    },
    {
      id: "notifications",
      title: t({
        id: "settings-page.notifications-and-sounds",
        defaultMessage: "Notifications and Sounds",
      }),
      subTitle: undefined,
      icon: <BellIcon />,
      onClick: useCallback(() => {
        setIsNotificationsSidebarOpen(true);
      }, []),
    },
    {
      id: "log-out",
      title: t({
        id: "common.log-out",
        defaultMessage: "Log out",
      }),
      subTitle: undefined,
      icon: <LogOutIcon />,
      onClick: () => logout(true),
    },
    {
      id: "time-zone",
      title: t({
        id: "settings-page.time-zone",
        defaultMessage: "Time Zone",
      }),
      subTitle: undefined,
      icon: <TimeZoneIcon />,
      onClick: useCallback(() => {
        setIsTimeZonePickerOpen(true);
      }, []),
    },
  ];

  return (
    <>
      <MultiSectionLayout
        className="bg-gray-200"
        header={
          <HeaderBreadcrumbs
            items={[
              {
                title: t({
                  id: "settings-page.settings",
                  defaultMessage: "Settings",
                }),
              },
            ]}
          />
        }
      >
        <div className="grid w-max grid-cols-2 gap-x-[60px] gap-y-10 px-[60px] py-10">
          {tileList.map((el) => (
            <Tile
              key={el.id}
              title={el.title}
              subTitle={el.subTitle}
              icon={el.icon}
              onClick={el.onClick}
            />
          ))}
        </div>
      </MultiSectionLayout>
      <LanguagePickerDrawer
        isOpen={isLanguagePickerDrawerOpen}
        title={t({
          id: "common.language",
          defaultMessage: "Language",
        })}
        onClose={() => setIsLanguagePickerDrawerOpen(false)}
      />
      <TimeZoneSettingsDialog
        isOpen={isTimeZonePickerOpen}
        onClose={() => setIsTimeZonePickerOpen(false)}
      />
      <NotificationsSettingsSidebar
        isOpen={isNotificationsSidebarOpen}
        onClose={() => setIsNotificationsSidebarOpen(false)}
      />
      <WelcomeToEntityAlert />
    </>
  );
};

export default SettingsPage;
