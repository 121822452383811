import {
  SupportedNotificationsModule,
  ModuleUnreadIndicators,
  UnreadIndicatorSupportedModule,
  useRestApiProvider,
} from "@jugl-web/rest-api";
import { assert, HookOutOfContextError } from "@jugl-web/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { selectUserId } from "@web-src/features/auth/authSlice";
import {
  PhoenixSocketContext,
  PhxResponse,
} from "@web-src/features/chats/providers/PheonixSocket";
import {
  BulkReceiptSentResponse,
  ChatMessage,
  ChatMessagePayloadCallAction,
} from "@web-src/features/chats/types";
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import { Observable, Subject } from "rxjs";

interface IndicatorEventResponse {
  data: number;
  entity_id: string;
  type: number;
}

interface UnreadIndicatorsProviderProps {
  children: JSX.Element;
}

interface UnreadIndicatorsContextValue {
  moduleUnreadIndicators: ModuleUnreadIndicators | undefined;
  anotherEntityUpdateEvent$: Observable<{ updatedEntityId: string }>;
}

const CHAT_MESSAGE_EVENT = "phx_message";
const CALL_EVENT = "phx_call";
const CALL_SENT_EVENT = "phx_call_sent";
const CHAT_GROUP_INFO_EVENT = "phx_group_info";
const TASK_INDICATOR_EVENT = "phx_event::indicators::task";
const PEOPLE_INDICATOR_EVENT = "phx_event::indicators::people";
const DRIVE_INDICATOR_EVENT = "phx_event::indicators::drive";
const CUSTOMER_INDICATOR_EVENT = "phx_event::indicators::customer";

const UnreadIndicatorsContext =
  createContext<UnreadIndicatorsContextValue | null>(null);

export const UnreadIndicatorsProvider: FC<UnreadIndicatorsProviderProps> = ({
  children,
}) => {
  const { entity } = useEntity();
  const { entitiesApi } = useRestApiProvider();
  const meId = useSelector(selectUserId);

  const anotherEntityUpdateEvent$ = useMemo(
    () => new Subject<{ updatedEntityId: string }>(),
    []
  );

  const { moduleNotificationsApi } = useRestApiProvider();

  const { data: moduleUnreadIndicators } =
    entitiesApi.useGetModuleUnreadIndicatorsQuery(
      entity ? { entityId: entity.id } : skipToken,
      { refetchOnMountOrArgChange: true }
    );

  const { channel, incomingMessages$, receiptSent$ } =
    useContext(PhoenixSocketContext);

  const [updateModuleUnreadIndicators] =
    entitiesApi.useUpdateModuleUnreadIndicatorsMutation();

  const [markModuleNotificationsUnreadIndicatorAsUnread] =
    moduleNotificationsApi.useMarkModuleNotificationsUnreadIndicatorAsUnreadMutation();

  const [markEntityAsUnread] = entitiesApi.useMarkEntityAsUnreadMutation();

  const markModuleNotificationsAsUnreadIfViable = useCallback(
    (entityId: string, module: UnreadIndicatorSupportedModule) => {
      const notificationsSupportedModules: Record<
        SupportedNotificationsModule,
        true
      > = {
        task: true,
        people: true,
        customer: true,
        drive: true,
        call: true,
      };

      if (module in notificationsSupportedModules) {
        markModuleNotificationsUnreadIndicatorAsUnread({
          entityId,
          module: module as SupportedNotificationsModule,
        });
      }
    },
    [markModuleNotificationsUnreadIndicatorAsUnread]
  );

  const handleModuleIndicatorEvent = useCallback(
    (module: UnreadIndicatorSupportedModule) =>
      (event: PhxResponse<IndicatorEventResponse>) => {
        assert(!!entity && !!moduleUnreadIndicators);
        const wasIndicatorReset = event.response.data === 0;

        // If the event is for the current entity, update the sidebar indicators
        // regardless of whether the indicator was reset or not
        if (event.response.entity_id === entity.id) {
          updateModuleUnreadIndicators({
            entityId: entity.id,
            state: { ...moduleUnreadIndicators, [module]: event.response.data },
          });
          // If the event is for another entity and the indicator was not reset,
          // publish an event to trigger the other entities updates popup
        } else if (!wasIndicatorReset) {
          anotherEntityUpdateEvent$.next({
            updatedEntityId: event.response.entity_id,
          });
        }

        if (!wasIndicatorReset) {
          // Always mark the entity as unread if the indicator was not reset
          markEntityAsUnread({ entityId: event.response.entity_id });

          // Mark the module notifications indicator as unread if it supports the given module
          markModuleNotificationsAsUnreadIfViable(
            event.response.entity_id,
            module
          );
        }
      },
    [
      entity,
      moduleUnreadIndicators,
      updateModuleUnreadIndicators,
      anotherEntityUpdateEvent$,
      markEntityAsUnread,
      markModuleNotificationsAsUnreadIfViable,
    ]
  );

  const handleIncomingMessageEvent = useCallback(
    (event: PhxResponse<ChatMessage>) => {
      assert(!!entity && !!moduleUnreadIndicators);

      if (event.status !== "ok") {
        return;
      }

      const message = event.response;

      if (
        message.from === meId ||
        (message.payload?.action === "action" &&
          message.payload?.type === "typing_indicator") ||
        message.payload?.type === "group_info"
      ) {
        return;
      }

      markEntityAsUnread({ entityId: event.response.entity_id });

      if (message.entity_id === entity.id) {
        updateModuleUnreadIndicators({
          entityId: entity.id,
          state: {
            ...moduleUnreadIndicators,
            chat: moduleUnreadIndicators.chat + 1,
          },
        });
      } else {
        anotherEntityUpdateEvent$.next({
          updatedEntityId: event.response.entity_id,
        });
      }
    },
    [
      entity,
      moduleUnreadIndicators,
      meId,
      markEntityAsUnread,
      updateModuleUnreadIndicators,
      anotherEntityUpdateEvent$,
    ]
  );

  const handleMessageReadEvent = useCallback(
    (event: BulkReceiptSentResponse) => {
      assert(!!entity && !!moduleUnreadIndicators);

      updateModuleUnreadIndicators({
        entityId: entity.id,
        state: {
          ...moduleUnreadIndicators,
          chat: Math.max(moduleUnreadIndicators.chat - event.count, 0),
        },
      });
    },
    [entity, moduleUnreadIndicators, updateModuleUnreadIndicators]
  );

  const handleCallsEvent = useCallback(
    (event: PhxResponse<ChatMessage>) => {
      assert(!!entity && !!moduleUnreadIndicators);
      const isFromMe = event.response.from === meId;
      if (
        (event.response.payload.call_action ===
          ChatMessagePayloadCallAction.call_declined &&
          !isFromMe) ||
        event.response.payload.call_action ===
          ChatMessagePayloadCallAction.call_duration
      ) {
        updateModuleUnreadIndicators({
          entityId: entity.id,
          state: {
            ...moduleUnreadIndicators,
            call: moduleUnreadIndicators.call + 1,
          },
        });
      }
    },
    [entity, meId, moduleUnreadIndicators, updateModuleUnreadIndicators]
  );

  useEffect(() => {
    if (
      !channel ||
      !incomingMessages$ ||
      !receiptSent$ ||
      !entity ||
      !moduleUnreadIndicators
    ) {
      return undefined;
    }

    const sentReceiptsSubscription = receiptSent$?.subscribe(
      handleMessageReadEvent
    );

    const incomingMessagesListenerId = channel.on(
      CHAT_MESSAGE_EVENT,
      handleIncomingMessageEvent
    );

    const groupInfoUpdatesListenerId = channel.on(
      CHAT_GROUP_INFO_EVENT,
      handleIncomingMessageEvent
    );

    const incomingCallsListenerId = channel.on(CALL_EVENT, handleCallsEvent);

    const callSentListenerId = channel.on(CALL_SENT_EVENT, handleCallsEvent);

    const taskIndicatorEventListenerId = channel.on(
      TASK_INDICATOR_EVENT,
      handleModuleIndicatorEvent("task")
    );

    const peopleIndicatorEventListenerId = channel.on(
      PEOPLE_INDICATOR_EVENT,
      handleModuleIndicatorEvent("people")
    );

    const driveIndicatorEventListenerId = channel.on(
      DRIVE_INDICATOR_EVENT,
      handleModuleIndicatorEvent("drive")
    );

    const customerIndicatorEventListenerId = channel.on(
      CUSTOMER_INDICATOR_EVENT,
      handleModuleIndicatorEvent("customer")
    );

    return () => {
      sentReceiptsSubscription?.unsubscribe();
      channel.off(CHAT_MESSAGE_EVENT, incomingMessagesListenerId);
      channel.off(CALL_EVENT, incomingCallsListenerId);
      channel.off(CALL_SENT_EVENT, callSentListenerId);
      channel.off(CHAT_GROUP_INFO_EVENT, groupInfoUpdatesListenerId);
      channel.off(TASK_INDICATOR_EVENT, taskIndicatorEventListenerId);
      channel.off(PEOPLE_INDICATOR_EVENT, peopleIndicatorEventListenerId);
      channel.off(DRIVE_INDICATOR_EVENT, driveIndicatorEventListenerId);
      channel.off(CUSTOMER_INDICATOR_EVENT, customerIndicatorEventListenerId);
    };
  }, [
    channel,
    entity,
    handleCallsEvent,
    handleIncomingMessageEvent,
    handleMessageReadEvent,
    handleModuleIndicatorEvent,
    incomingMessages$,
    moduleUnreadIndicators,
    receiptSent$,
  ]);

  const contextValue = useMemo(
    () => ({ moduleUnreadIndicators, anotherEntityUpdateEvent$ }),
    [anotherEntityUpdateEvent$, moduleUnreadIndicators]
  );

  return (
    <UnreadIndicatorsContext.Provider value={contextValue}>
      {children}
    </UnreadIndicatorsContext.Provider>
  );
};

export const useUnreadIndicators = () => {
  const context = useContext(UnreadIndicatorsContext);

  if (!context) {
    throw new HookOutOfContextError(
      "useUnreadIndicators",
      "UnreadIndicatorsContext"
    );
  }

  return context;
};
