import React, { useState, memo } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  UserProfile,
  UserProfileCareers,
  useRestApiProvider,
} from "@jugl-web/rest-api";
import { ReactComponent as BinIcon } from "@web-src/images/icons/delete.svg";
import format from "date-fns/format";
import { isoToLocalDate } from "@web-src/utils/helper";
import { useTranslations } from "@jugl-web/utils";
import { DatePicker } from "@jugl-web/ui-components/cross-platform/DatePicker";
import {
  InteractiveContainer,
  Popover,
  Button,
  Divider,
  LoadingAnimation,
  TextField,
  FormControlLabel,
  Checkbox,
  ConfirmationPopup,
} from "@jugl-web/ui-components/cross-platform";
import { Accordion } from "@jugl-web/ui-components/cross-platform/Accordion";
import { ReactComponent as WorkExperienceIcon } from "./assets/work-experience.svg";
import { useProfileUpdateToast } from "../../hooks/useProfileUpdateToast";
import { DateButton } from "../DateButton/DateButton";

type Inputs = {
  job_title: string;
  organization: string;
  location: string;
  start_date: Date | undefined;
  end_date: Date | undefined;
  current: boolean;
};

const schema = yup.object().shape({
  job_title: yup.string().required(),
  organization: yup.string().required(),
  location: yup.string().required(),
  start_date: yup.date().required(),
  current: yup.boolean(),
  end_date: yup.date().when("current", {
    is: false,
    then: (schemat) => schemat.required(),
  }),
});

const EditExperienceFormContainer = memo(() => {
  const { usersApi } = useRestApiProvider();
  const { data } = usersApi.useGetUserProfileQuery({});
  return <EditExperienceFormComponent {...{ data }} />;
});

const EditExperienceFormComponent: React.FC<{
  data: UserProfile | undefined;
}> = ({ data }) => {
  const { t } = useTranslations();
  return (
    <div className="w-1/2">
      {!data ? (
        <LoadingAnimation />
      ) : data.careers.length === 0 ? (
        <div className="pt-10">
          <span className="pl-5 font-medium">
            {t({
              id: "experience-form-page.add-work-experience",
              defaultMessage: "Add Work Experience",
            })}
          </span>
          <EditExperienceForm type="add" />
        </div>
      ) : (
        <EditExperienceList {...{ data }} />
      )}
    </div>
  );
};

const EditExperienceList: React.FC<{ data: UserProfile }> = ({ data }) => {
  const [displayForm, setDisplayForm] = useState(false);
  const { t } = useTranslations();
  return (
    <div className="pt-5">
      <span className="pl-5 font-medium">
        {t({
          id: "experience-form-page.your-work-experience",
          defaultMessage: "Your Work Experience",
        })}
      </span>
      {data.careers.map((el, idx) => (
        <>
          <EditExperienceListItem key={el.id} data={el} />
          {data.careers.length - 1 > idx && <Divider />}
        </>
      ))}
      {!displayForm && (
        <Button
          className="ml-5 mt-5 mb-10 w-1/2"
          variant="outlined"
          onClick={() => setDisplayForm(true)}
        >
          {t({
            id: "form-controls.add-new-experience.button",
            defaultMessage: "Add New Experience",
          })}
        </Button>
      )}
      {displayForm && (
        <>
          <span className="ml-5 font-medium">
            {t({
              id: "experience-form-page.add-work-experience",
              defaultMessage: "Add Work Experience",
            })}
          </span>
          <EditExperienceForm
            type="add"
            onSuccess={() => setDisplayForm(false)}
          />
        </>
      )}
    </div>
  );
};

const EditExperienceListItem: React.FC<{ data: UserProfileCareers }> = ({
  data,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const { usersApi } = useRestApiProvider();
  const [deleteExperience, { isLoading }] =
    usersApi.useDeleteUserProfileSectionMutation();
  const { showProfileUpdatedSnackbar } = useProfileUpdateToast();
  const { t } = useTranslations();

  const handleDeleteExperience = async () => {
    const res = await deleteExperience({
      type: "career",
      item_id: data.id,
    });
    if ("data" in res) {
      showProfileUpdatedSnackbar();
      setOpenModal(false);
    }
  };

  return (
    <>
      <Accordion
        variant="web"
        title={
          <div className="flex w-full items-center gap-5 px-5 py-3">
            <div className="flex h-9 w-9 items-center justify-center rounded-full bg-[rgba(171,89,228,0.1)]">
              <WorkExperienceIcon />
            </div>
            <div className="flex grow flex-col gap-1">
              <span>{data.title}</span>
              <span className="text-grey text-sm">
                {`${format(isoToLocalDate(data.start), "dd/MM/yyyy")} - ${
                  data.end !== null
                    ? format(isoToLocalDate(data.end), "dd/MM/yyyy")
                    : "Present"
                }`}
              </span>
            </div>
            <Button
              color="tertiary"
              className="text-sm font-medium uppercase"
              isDisabled={isLoading}
              onClick={(e) => {
                e.stopPropagation();
                setOpenModal(true);
              }}
              variant="text"
              iconStart={<BinIcon />}
            >
              {t({
                id: "common.delete",
                defaultMessage: "Delete",
              })}
            </Button>
          </div>
        }
        className="flex-row-reverse px-4"
      >
        {({ onClose }) => (
          <EditExperienceForm {...{ data }} type="update" onSuccess={onClose} />
        )}
      </Accordion>
      {openModal && (
        <ConfirmationPopup
          isOpen={openModal}
          onRequestClose={() => setOpenModal(false)}
          hasCancelButton
          message={t({
            id: "experience-form-page.experience-information-removal-confirmation",
            defaultMessage:
              "Do you really want to remove Work Experience information?",
          })}
          variant="web"
          title={t({
            id: "delete-modal.delete-confirmation-title",
            defaultMessage: "Delete Confirmation",
          })}
          buttons={[
            {
              label: t({
                id: "common.delete",
                defaultMessage: "Delete",
              }),
              color: "tertiary",
              onClick: handleDeleteExperience,
              isDisabled: isLoading,
            },
          ]}
        />
      )}
    </>
  );
};

const EditExperienceForm: React.FC<{
  data?: UserProfileCareers;
  type: "add" | "update";
  onSuccess?: () => void;
}> = ({ data, type, onSuccess }) => {
  const { usersApi } = useRestApiProvider();
  const [addExperience, { isLoading }] =
    usersApi.useAddUserProfileSectionMutation();
  const [updateExperience, { isLoading: isLoadingUpdate }] =
    usersApi.useUpdateUserProfileSectionMutation();
  const { showProfileUpdatedSnackbar } = useProfileUpdateToast();
  const { t } = useTranslations();
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isValid, isDirty },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    values: {
      job_title: data?.title || "",
      organization: data?.org || "",
      location: data?.location || "",
      start_date: data?.start ? new Date(data.start) : undefined,
      end_date: data?.end ? new Date(data.end) : undefined,
      current: data?.end === null,
    },
  });

  const startDateValue = watch("start_date");
  const endDateValue = watch("end_date");
  const currentValue = watch("current");

  const onSubmit: SubmitHandler<Inputs> = async (params) => {
    const {
      job_title: JobTitle,
      organization,
      location,
      start_date: StartDate,
      end_date: EndDate,
      current,
    } = { ...params };
    const dataForm = new FormData();
    dataForm.set("type", "career");
    dataForm.set("title", JobTitle);
    dataForm.set("org", organization);
    dataForm.set("location", location);
    dataForm.set("start", StartDate?.toISOString() || "");
    let res;
    if (!current) dataForm.set("end", EndDate?.toISOString() || "");
    if (type === "add") {
      res = await addExperience({ data: dataForm });
    } else {
      res = await updateExperience({
        item_id: data?.id,
        data: dataForm,
      });
    }
    if ("data" in res) {
      showProfileUpdatedSnackbar();
      onSuccess?.();
    }
  };

  return (
    <div className="px-10 pb-10 pt-5">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex w-full gap-[70px]">
          <div className="flex w-1/2 flex-col gap-12">
            <TextField
              isRequired
              label={t({
                id: "form-controls.job-title.label",
                defaultMessage: "Job Title",
              })}
              isFullWidth
              placeholder={t({
                id: "form-controls.job-title.placeholder",
                defaultMessage: "Job Title",
              })}
              {...register("job_title")}
            />
            <TextField
              isRequired
              label={t({
                id: "form-controls.location.label",
                defaultMessage: "Location / Branch",
              })}
              isFullWidth
              placeholder={t({
                id: "form-controls.location.placeholder",
                defaultMessage: "Location / Branch",
              })}
              {...register("location")}
            />
            <div className="flex flex-col gap-[35px]">
              <Controller
                name="end_date"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Popover
                    className="w-[340px]"
                    placement="bottom-start"
                    renderTrigger={({ Trigger, triggerRef }) => (
                      <Trigger
                        ref={triggerRef}
                        as={InteractiveContainer}
                        isDisabled={currentValue}
                      >
                        <FormControlLabel isRequired className="ml-2.5">
                          {t({
                            id: "form-controls.end-date.label",
                            defaultMessage: "End Date",
                          })}
                        </FormControlLabel>
                        <DateButton date={value} />
                      </Trigger>
                    )}
                  >
                    {({ onClose }) => (
                      <DatePicker
                        minDate={startDateValue}
                        onSubmit={onChange}
                        initialDate={value}
                        onClose={onClose}
                        className="p-4"
                        dateTransformation="endOfDay"
                      />
                    )}
                  </Popover>
                )}
              />
              <Controller
                name="current"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    isChecked={value || false}
                    onChange={(e) => onChange(e.target.checked)}
                    label={t({
                      id: "form-controls.currently-working-in-role.label",
                      defaultMessage: "I am currently working in this role",
                    })}
                    labelClassName="text-xs font-medium"
                  />
                )}
              />
              <Button
                isDisabled={
                  !isValid || isLoading || isLoadingUpdate || !isDirty
                }
                fullWidth
                variant="contained"
                type="submit"
              >
                {t({
                  id: "common.save",
                  defaultMessage: "Save",
                })}
              </Button>
            </div>
          </div>
          <div className="flex w-1/2 flex-col gap-12">
            <TextField
              isRequired
              label={t({
                id: "form-controls.organisation.label",
                defaultMessage: "Organisation",
              })}
              isFullWidth
              placeholder={t({
                id: "form-controls.organisation.placeholder",
                defaultMessage: "Organisation",
              })}
              {...register("organization")}
            />
            <Controller
              name="start_date"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Popover
                  className="w-[340px]"
                  placement="bottom-start"
                  renderTrigger={({ Trigger, triggerRef }) => (
                    <Trigger ref={triggerRef} as={InteractiveContainer}>
                      <FormControlLabel isRequired className="ml-2.5">
                        {t({
                          id: "form-controls.start-date.label",
                          defaultMessage: "Start Date",
                        })}
                      </FormControlLabel>
                      <DateButton date={value} />
                    </Trigger>
                  )}
                >
                  {({ onClose }) => (
                    <DatePicker
                      onSubmit={(date) => {
                        onChange(date);
                        if (endDateValue && date && date > endDateValue) {
                          setValue("end_date", date, {
                            shouldValidate: true,
                          });
                        }
                      }}
                      initialDate={value}
                      onClose={onClose}
                      className="p-4"
                      dateTransformation="endOfDay"
                    />
                  )}
                </Popover>
              )}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditExperienceFormContainer;
