import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import classNames from "classnames";

import ReactCrop, { Crop, PixelCrop } from "react-image-crop";
import { useToast, useTranslations } from "@jugl-web/utils";
import { canvasPreview } from "./utils/canvasPreview";
import { CROP_SIZE } from "./consts";
import { rotateImage } from "./utils/rotateImage";
import "react-image-crop/dist/ReactCrop.css";
import { centerAspectCrop } from "./utils/centerAspectCrop";
import { saveImageFromCanvas } from "./utils/saveImageFromCanvas";

export interface CropImageHandle {
  rotate: () => void;
  getImageFile: () => Promise<File | undefined>;
}

type CropImageProps = {
  image?: File;
  scale: number;
};

export const CropImage = forwardRef<CropImageHandle, CropImageProps>(
  ({ image, scale }, ref) => {
    const [imgSrc, setImgSrc] = useState("");
    const previewImgRef = useRef<HTMLCanvasElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const [renderImageSize, setRenderImageSize] = useState<{
      width: number;
      height: number;
    }>();
    const { toast } = useToast({ variant: "web" });
    const { t } = useTranslations();
    const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
      const { width, height } = e.currentTarget;

      setCrop(centerAspectCrop(width, height, 1));

      const mainDimension = Math.max(
        imgRef.current?.width || 0,
        imgRef.current?.height || 0
      );

      setRenderImageSize({
        width: (CROP_SIZE / mainDimension) * width,
        height: (CROP_SIZE / mainDimension) * height,
      });
    };

    useImperativeHandle(ref, () => ({
      rotate: async () => {
        if (imgRef.current) {
          try {
            const url = await rotateImage(imgRef.current);
            setImgSrc(url);
            setCrop(undefined);
          } catch {
            toast(
              t({
                id: "settings-page.failed-to-process-image",
                defaultMessage: "Failed to process image",
              }),
              { variant: "error" }
            );
          }
        }
      },
      getImageFile: async () => {
        if (!previewImgRef.current) return undefined;
        try {
          const file = await saveImageFromCanvas(previewImgRef.current);
          return file;
        } catch {
          toast(
            t({
              id: "settings-page.failed-to-process-image",
              defaultMessage: "Failed to process image",
            }),
            { variant: "error" }
          );
          return undefined;
        }
      },
    }));

    useEffect(() => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewImgRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewImgRef.current,
          completedCrop,
          scale
        );
      }
    }, [completedCrop, previewImgRef, scale]);

    useEffect(() => {
      // On image change, the function loads a new photo, converts it, and sets it as the source
      if (image) {
        setCrop(undefined);
        const reader = new FileReader();
        reader.addEventListener("load", () =>
          setImgSrc(reader.result?.toString() || "")
        );
        reader.readAsDataURL(image);
      }
    }, [image]);

    return (
      <div
        className="flex items-center justify-center"
        style={{
          height: renderImageSize?.height && `${renderImageSize.height}px`,
          width: renderImageSize?.width && `${renderImageSize.width}px`,
        }}
      >
        {!!imgSrc && (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={1}
            circularCrop
            className="custom-crop rounded-md bg-black"
          >
            <img
              className={classNames("rounded object-contain")}
              ref={imgRef}
              alt="Crop me"
              src={imgSrc}
              style={{ scale: `${scale}` }}
              onLoad={onImageLoad}
            />
            <style>
              {`
          .custom-crop .ReactCrop__crop-selection {
            --webkit-animation: none;
            animation: none !important;
            background-image: none !important;
          }
        `}
            </style>
          </ReactCrop>
        )}
        {!!completedCrop && (
          <div className="hidden">
            <canvas
              ref={previewImgRef}
              style={{
                border: "1px solid black",
                objectFit: "contain",
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          </div>
        )}
      </div>
    );
  }
);
