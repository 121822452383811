import React from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import {
  Text,
  TypographyVariant,
} from "@jugl-web/ui-components/cross-platform/Text";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { useTranslations } from "@jugl-web/utils";
import { ReactComponent as CloseIcon } from "./assets/close.svg";

const DriveDeleteModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onClick: () => void;
  type: "file" | "directory";
  name: string;
}> = ({ isOpen, onClose, onClick, type, name }) => {
  const { t } = useTranslations();
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div
        className="z-1 fixed inset-0"
        style={{
          backgroundColor: "rgba(14, 14, 14, 0.66)",
        }}
      />
      <DialogPanel className="fixed top-1/2 left-1/2 flex h-[300px] w-[700px] -translate-x-1/2 -translate-y-1/2 flex-col items-center rounded-xl bg-white">
        <div className="mt-5 mr-5 flex w-full justify-end">
          <CloseIcon onClick={onClose} className="cursor-pointer" />
        </div>
        <Text variant={TypographyVariant.h4}>
          {type === "file"
            ? t({
                id: "drive-page.delete-file",
                defaultMessage: "Delete File",
              })
            : t({
                id: "drive-page.delete-folder",
                defaultMessage: "Delete Folder",
              })}
        </Text>
        <div className="mx-auto inline-flex max-w-[90%]">
          <Text variant={TypographyVariant.body2} className="shrink-0">
            {t({
              id: "drive-page.removal-confimation",
              defaultMessage: "Are you sure you want to delete",
            })}
          </Text>
          <Text variant={TypographyVariant.body2} className="truncate">
            <span className="text-primary-500 ml-1">
              {type === "file"
                ? t(
                    {
                      id: "drive-page.file-name",
                      defaultMessage: "File {fileName}",
                    },
                    {
                      fileName: name,
                    }
                  )
                : t(
                    {
                      id: "drive-page.folder-with-name",
                      defaultMessage: "Folder {fileName}",
                    },
                    {
                      fileName: name,
                    }
                  )}
            </span>
          </Text>
        </div>
        <div className="mt-20 flex w-3/4 justify-between gap-5">
          <Button onClick={onClose} variant="outlined" fullWidth>
            <span className="font-semibold uppercase">
              {t({
                id: "common.cancel",
                defaultMessage: "Cancel",
              })}
            </span>
          </Button>
          <Button
            onClick={() => {
              onClick();
              onClose();
            }}
            color="tertiary"
            fullWidth
          >
            <span className="font-semibold uppercase">
              {t({
                id: "common.delete",
                defaultMessage: "Delete",
              })}
            </span>
          </Button>
        </div>
      </DialogPanel>
    </Dialog>
  );
};

export default DriveDeleteModal;
