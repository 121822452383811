import {
  AnyModuleLog,
  CustomerModuleLog,
  DriveModuleLog,
  PeopleModuleLog,
  TaskModuleLog,
} from "@jugl-web/rest-api";

export const isTaskLog = (log: AnyModuleLog): log is TaskModuleLog =>
  "task" in log;

export const isPeopleLog = (log: AnyModuleLog): log is PeopleModuleLog =>
  "people" in log;

export const isCustomerLog = (log: AnyModuleLog): log is CustomerModuleLog =>
  "customer" in log;

export const isDriveLog = (log: AnyModuleLog): log is DriveModuleLog =>
  "drive" in log;
