import React, { memo, useMemo } from "react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import {
  InteractiveContainer,
  PlainButton,
  Popover,
} from "@jugl-web/ui-components/cross-platform";
import { cx, useTranslations } from "@jugl-web/utils";
import { useEntityProvider } from "@web-src/modules/entities/providers/EntityProvider";
import SvgIcon from "@mui/material/SvgIcon";
import ReplyIcon from "@mui/icons-material/Reply";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ReactComponent as CopyIcon } from "@web-src/images/icons/copy.svg";
import { ReactComponent as EditIcon } from "@web-src/images/icons/edit-alt.svg";
import { ReactComponent as CircleArrowIcon } from "@web-src/images/icons/circle-arrow-right.svg";
import { ReactComponent as CircleInformationIcon } from "@web-src/images/icons/circle-information.svg";
import { ReactComponent as DeleteIcon } from "@web-src/images/icons/delete.svg";
import { ReactComponent as AddIcon } from "./images/add.svg";

export enum ChatBubbleMenuAction {
  reply = "reply",
  forward = "forward",
  edit = "edit",
  copy = "copy",
  messageInfo = "messageInfo",
  delete = "delete",
  reaction = "reaction",
}

const MessageBubbleContextMenu: React.FC<{
  onClick: (action: ChatBubbleMenuAction, value?: string) => void;
  isOutgoing?: boolean;
  isHaveBodyContent: boolean;
  myReactions: string[];
  downloadAttachmentInProgress: boolean;
}> = ({
  onClick,
  isOutgoing,
  isHaveBodyContent,
  myReactions,
  downloadAttachmentInProgress,
}) => {
  const { t } = useTranslations();
  const { subscriptionRequiredAction } = useEntityProvider();

  const reactionSet = useMemo(() => ["😄", "👍", "👎", "😍", "✅"], []);
  const items: {
    icon: React.FunctionComponent;
    action: ChatBubbleMenuAction;
    text: string;
  }[] = useMemo(
    () => [
      {
        icon: ReplyIcon,
        action: ChatBubbleMenuAction.reply,
        text: t({
          id: "common.reply",
          defaultMessage: "Reply",
        }),
      },
      ...(isHaveBodyContent
        ? [
            {
              icon: CopyIcon,
              action: ChatBubbleMenuAction.copy,
              text: t({
                id: "common.copy",
                defaultMessage: "Copy",
              }),
            },
          ]
        : []),
      {
        icon: CircleArrowIcon,
        action: ChatBubbleMenuAction.forward,
        text: t({
          id: "common.forward",
          defaultMessage: "Forward",
        }),
      },
      ...(isOutgoing
        ? [
            {
              icon: EditIcon,
              action: ChatBubbleMenuAction.edit,
              text: t({
                id: "chats-page.edit-message",
                defaultMessage: "Edit Message",
              }),
            },
            {
              icon: CircleInformationIcon,
              action: ChatBubbleMenuAction.messageInfo,
              text: t({
                id: "chats-page.message-info",
                defaultMessage: "Message Info",
              }),
            },
            ...(!downloadAttachmentInProgress
              ? [
                  {
                    icon: DeleteIcon,
                    action: ChatBubbleMenuAction.delete,
                    text: t({
                      id: "common.delete",
                      defaultMessage: "Delete",
                    }),
                  },
                ]
              : []),
          ]
        : []),
    ],
    [isOutgoing, isHaveBodyContent, downloadAttachmentInProgress, t]
  );
  return (
    <Popover
      placement="right-start"
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          as={PlainButton}
          ref={triggerRef}
          className={cx(
            "bubbleMenuButton absolute top-[-10px] h-[24px] w-[24px] cursor-pointer rounded-[12px] bg-white text-gray-500 shadow",
            isOutgoing ? `left-[-10px]` : "right-[-10px]"
          )}
        >
          <MoreVertIcon />
        </Trigger>
      )}
      className="py-2"
    >
      {({ onClose }) => (
        <>
          {items.map((item) => (
            <InteractiveContainer
              key={item.action}
              onClick={() => {
                subscriptionRequiredAction(
                  onClick?.bind(null, item.action, undefined)
                )();
                onClose();
              }}
              className="hover:bg-grey-100 flex h-full w-full items-center gap-2.5 px-4 py-2"
            >
              <span className="shrink-0">
                <SvgIcon
                  color="primary"
                  component={item.icon}
                  fontSize="small"
                  className="block"
                />
              </span>
              <span className="text-dark grow text-left text-sm leading-[16px]">
                {item.text}
              </span>
            </InteractiveContainer>
          ))}
          <div className="mx-2 mt-2 flex justify-center gap-1 border-0 border-t border-solid border-gray-100 pt-2 text-[20px]">
            {reactionSet.map((item) => (
              <PlainButton
                key={item}
                onClick={() => {
                  onClick?.(ChatBubbleMenuAction.reaction, item);
                  onClose();
                }}
                className={cx(
                  "h-8 w-8 cursor-pointer rounded border-none bg-transparent text-[20px] hover:bg-gray-100",
                  myReactions.includes(item) && "bg-gray-100"
                )}
              >
                {item}
              </PlainButton>
            ))}
            <Popover
              placement="top"
              renderTrigger={({ Trigger, triggerRef }) => (
                <Trigger
                  as={PlainButton}
                  ref={triggerRef}
                  className="h-8 w-8 cursor-pointer rounded border-none bg-transparent text-[20px] hover:bg-gray-100"
                >
                  <AddIcon />
                </Trigger>
              )}
            >
              <Picker
                theme="light"
                data={data}
                skinTonePosition="none"
                previewPosition="none"
                onEmojiSelect={(emoji: { [key: string]: string }) => {
                  onClick?.(ChatBubbleMenuAction.reaction, emoji.native);
                  onClose();
                }}
              />
            </Popover>
          </div>
        </>
      )}
    </Popover>
  );
};
export default memo(MessageBubbleContextMenu);
