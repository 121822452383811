import React, { useMemo } from "react";
import { ChatItem } from "@web-src/features/chats/types";
import { Checkbox, Avatar } from "@jugl-web/ui-components/cross-platform";

export const ChatListPickerItem: React.FC<{
  chat: ChatItem;
  isSelected: boolean;
  onSelect: (chat: ChatItem) => void;
}> = ({ chat, isSelected, onSelect }) => {
  const unknownName = useMemo(() => {
    if (chat.type === "muc") {
      return "Unknown Group";
    }
    return "Unknown User";
  }, [chat]);

  return (
    <div
      className="flex cursor-pointer items-center gap-2 px-8 py-1"
      onClick={onSelect.bind(null, chat)}
    >
      <Avatar
        username={chat.title || unknownName}
        imageUrl={chat.img}
        className="h-10 w-10"
      />
      <p className="grow truncate">{chat.title || unknownName}</p>
      <Checkbox readOnly isChecked={isSelected} />
    </div>
  );
};
