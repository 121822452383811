export enum AuthStep {
  login = "login",
  otp = "otp",
}

export enum LoginMethod {
  email = "email",
  phone = "phone",
  google = "google",
  linkedin = "linkedin",
}

export enum PhoneMethod {
  sms = "sms",
  whatsapp = "wa",
}
