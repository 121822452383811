import { BottomCenteredDrawer } from "@jugl-web/ui-components/web/BottomCenteredDrawer";
import Lottie from "react-lottie";
import { useTranslations } from "@jugl-web/utils";
import animationData from "../animations/empty-state-animation.json";

export const EmptyStateScreen = () => {
  const { t } = useTranslations();
  return (
    <BottomCenteredDrawer.Content className="flex flex-col items-center justify-center">
      <Lottie options={{ animationData }} height={160} width={160} />
      <span className="text-dark mb-3 text-xl font-medium leading-4">
        {t({
          id: "common.no-results",
          defaultMessage: "No results",
        })}
      </span>
      <span className="text-dark text-sm leading-3">
        {t({
          id: "feedback.try-something-else",
          defaultMessage: "Please try something else",
        })}
      </span>
    </BottomCenteredDrawer.Content>
  );
};
