import React, { useMemo } from "react";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { Text } from "@jugl-web/ui-components/cross-platform/Text";

import { SidebarDrawer } from "@jugl-web/ui-components/web";
import { useForm } from "react-hook-form";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { useTranslations } from "@jugl-web/utils";
import { EntitySpaceType, useRestApiProvider } from "@jugl-web/rest-api";

const FilterSidebar: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (params: { [key: string]: boolean }) => void;
}> = ({ isOpen, onClose, onSubmit }) => {
  const { t } = useTranslations();
  const { entity } = useEntity();
  const { entitySpacesApi } = useRestApiProvider();
  const { data: departmentList } =
    entitySpacesApi.use_DEPRECATED_allEntitySpacesListQuery(
      entity?.id
        ? {
            entityId: entity?.id,
            params: {
              type: EntitySpaceType.dept,
            },
          }
        : skipToken
    );

  const defaultValues = useMemo(() => {
    const values: { [x: string]: boolean } = {
      "sort-asc": true,
      "sort-dsc": false,
    };
    departmentList?.forEach((deparatment) => {
      const key = `dept-${deparatment.id}`;
      values[key] = false;
    });
    return values;
  }, [departmentList]);

  const { register, handleSubmit, watch, setValue, reset } = useForm({
    defaultValues,
  });
  const handleApplyFilters = handleSubmit((params) => onSubmit(params));
  const handleClear = () => {
    reset(defaultValues);
    handleApplyFilters();
  };
  const params = watch();
  const isDifferent = JSON.stringify(params) !== JSON.stringify(defaultValues);
  const shouldShowClear = useMemo(() => isDifferent, [isDifferent]);
  return (
    <SidebarDrawer
      isOpen={isOpen}
      title={t({ id: "common.filter", defaultMessage: "Filter" })}
      onClose={onClose}
    >
      <SidebarDrawer.Content>
        <div className="flex flex-col justify-start">
          <div className="flex flex-col gap-4 p-8">
            <span style={{ fontVariant: "all-small-caps" }}>
              <Text variant="text2Regular" className="text-dark">
                {t({ id: "common.sort-by", defaultMessage: "Sort by" })}
              </Text>
            </span>
            <div className="flex flex-row items-center justify-start gap-3">
              <input
                type="checkbox"
                className="m-0"
                checked={watch("sort-asc")}
                {...register("sort-asc", { value: true })}
                onChange={() => {
                  setValue("sort-asc", !watch("sort-asc"));
                  setValue("sort-dsc", !watch("sort-dsc"));
                }}
              />
              <Text variant="body3">
                {t({
                  id: "location-page.ascending",
                  defaultMessage: "Ascending A - Z",
                })}
              </Text>
            </div>
            <div className="flex flex-row items-center justify-start gap-3">
              <input
                type="checkbox"
                className="m-0"
                checked={watch("sort-dsc")}
                {...register("sort-dsc")}
                onChange={() => {
                  setValue("sort-dsc", !watch("sort-dsc"));
                  setValue("sort-asc", !watch("sort-asc"));
                }}
              />
              <Text variant="body3">
                {t({
                  id: "location-page.descending",
                  defaultMessage: "Descending Z - A",
                })}
              </Text>
            </div>
          </div>
          <hr className="border-grey-200/10 border-1 m-0 w-full" />
          <div className="flex flex-col gap-4 p-8">
            <span style={{ fontVariant: "all-small-caps" }}>
              <Text variant="text2Regular" className="text-dark">
                {t({
                  id: "common.department",
                  defaultMessage: "Department",
                })}
              </Text>
            </span>
            {departmentList?.map((department) => (
              <div
                className="flex flex-row items-center justify-start gap-3"
                key={department.id}
              >
                <input
                  type="checkbox"
                  className="m-0"
                  checked={params[`dept-${department.id}`]}
                  {...register(`dept-${department.id}` || "")}
                />
                <Text variant="body3">{department?.info?.title}</Text>
              </div>
            ))}
          </div>
        </div>
      </SidebarDrawer.Content>
      <SidebarDrawer.Actions className="flex gap-2">
        {shouldShowClear && (
          <Button
            className="bg-grey-200 text-dark hover:bg-grey-300 w-full uppercase"
            onClick={handleClear}
            variant="contained"
          >
            Clear
          </Button>
        )}
        <Button
          className="w-full uppercase"
          onClick={handleApplyFilters}
          isDisabled={!isDifferent}
        >
          {t({ id: "common.apply", defaultMessage: "Apply" })}
        </Button>
      </SidebarDrawer.Actions>
    </SidebarDrawer>
  );
};

export default FilterSidebar;
