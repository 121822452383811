import { apiUTCToLocalDateTime, cx } from "@jugl-web/utils";
import React from "react";
import format from "date-fns/format";
import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import { UserLastActiveOn } from "@jugl-web/rest-api";
import { AuthModeIcon } from "../../../AuthModeIcon";

export const UserLastActivityInfo: React.FC<{
  lastActivity: UserLastActiveOn;
  onClick: () => void;
}> = ({ lastActivity, onClick }) => (
  <InteractiveContainer
    className={cx(
      "flex w-full items-center gap-2 border-none bg-transparent py-4 outline-none",
      lastActivity && "hover:bg-grey-100 cursor-pointer"
    )}
    onClick={onClick}
  >
    <div className="flex items-center gap-1">
      <AuthModeIcon authMode={lastActivity.auth_mode} />
      <span className="text-sm text-[#4F4F4F]">
        Login -
        {format(apiUTCToLocalDateTime(lastActivity.time), " MMM dd, hh:mm a")}
      </span>
    </div>
  </InteractiveContainer>
);
