import React, { useMemo } from "react";
import { getTimezone } from "countries-and-timezones";
import { NoInfoCell } from "../NoInfoCell";

export const UserTimeZoneInfo: React.FC<{
  timeZone?: string;
}> = ({ timeZone }) => {
  const utcForTimeZone = useMemo(() => {
    if (timeZone) {
      return getTimezone(timeZone)?.utcOffsetStr;
    }
    return undefined;
  }, [timeZone]);
  if (!timeZone) {
    return <NoInfoCell />;
  }
  return (
    <div className="flex flex-col gap-2">
      <span className="text-sm text-[#4F4F4F]">{timeZone ?? "-"}</span>
      <span className="text-xs text-[#828282]">
        {utcForTimeZone ? `(UTC ${utcForTimeZone})` : "-"}
      </span>
    </div>
  );
};
