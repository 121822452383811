import { TouchEvent, useState } from "react";

export interface UseSwipeOptions {
  threshold?: number;
  onSwipeDown?: () => void;
  onSwipeUp?: () => void;
  onSwipeRight?: () => void;
  onSwipeLeft?: () => void;
}

type TouchPosition = { x: number; y: number } | null;

const DEFAULT_THRESHOLD = 50;

export const useSwipe = <TElement extends HTMLElement>({
  onSwipeDown,
  onSwipeUp,
  onSwipeRight,
  onSwipeLeft,
  threshold = DEFAULT_THRESHOLD,
}: UseSwipeOptions) => {
  const [startPosition, setStartPosition] = useState<TouchPosition>(null);

  const onTouchStart = (event: TouchEvent<TElement>) => {
    const touch = event.touches[0];
    setStartPosition({ x: touch.clientX, y: touch.clientY });
  };

  const onTouchMove = (event: TouchEvent<TElement>) => {
    if (!startPosition) {
      return;
    }

    const touch = event.touches[0];
    const deltaX = touch.clientX - startPosition.x;
    const deltaY = touch.clientY - startPosition.y;
    const absDeltaX = Math.abs(deltaX);
    const absDeltaY = Math.abs(deltaY);
    if (absDeltaY > absDeltaX && absDeltaY > threshold) {
      if (deltaY > 0) {
        onSwipeDown?.();
      } else {
        onSwipeUp?.();
      }
    } else if (absDeltaY < absDeltaX && absDeltaX > threshold) {
      if (deltaX > 0) {
        onSwipeRight?.();
      } else {
        onSwipeLeft?.();
      }
    }
  };

  const onTouchEnd = () => {
    setStartPosition(null);
  };

  return {
    touchProps: {
      onTouchStart,
      onTouchMove,
      onTouchEnd,
    },
  };
};
