import { useMultipleUserProfiles } from "@jugl-web/domain-resources/users/hooks/useMultipleUserProfiles";
import { useUserListBox } from "@jugl-web/domain-resources/users/hooks/useUserListBox";
import {
  AvatarMember,
  AvatarStack,
} from "@jugl-web/ui-components/cross-platform";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { FC, useCallback, useMemo } from "react";
import { TaskTableCellComponentProps } from "../../types";
import { TaskTableLayout } from "../TaskTableLayout";

export const AssigneeCell: FC<TaskTableCellComponentProps> = ({
  task,
  entityId,
  permissions,
  cellProps,
  onUpdate,
}) => {
  const { profiles, isLoading } = useMultipleUserProfiles({
    entityId,
    userIds: task.assignees,
  });

  const { getListBoxProps, clearSearchQuery } = useUserListBox({ entityId });

  const profilesAsAvatarMembers = useMemo<AvatarMember[]>(
    () =>
      profiles.map((profile) => ({
        firstName: profile.firstName || "",
        lastName: profile.lastName || "",
        imageUrl: profile.avatar || null,
      })),
    [profiles]
  );

  const isEditable = permissions.canEditAssignees && !task.is_self;

  const getCellContent = useCallback(
    (isPopoverOpen: boolean) => {
      if (isLoading) {
        return <TaskTableLayout.CellContentSkeleton type="avatars" />;
      }

      const hasNoAssignee = task.assignees.length === 0;

      if (hasNoAssignee) {
        return (
          <TaskTableLayout.AppearingAddValueCellText
            isStatic={isPopoverOpen}
            isHidden={!isEditable}
          />
        );
      }

      return (
        <AvatarStack
          maxCount={6}
          size="sm"
          members={profilesAsAvatarMembers}
          shouldShowNameForSingleMember
        />
      );
    },
    [isLoading, task.assignees.length, profilesAsAvatarMembers, isEditable]
  );

  return (
    <ResourcePickerPopover
      placement="bottom"
      className="w-[375px]"
      renderTrigger={({ Trigger, triggerRef, isOpen }) => (
        <Trigger
          as={TaskTableLayout.Cell}
          ref={triggerRef}
          isFocused={isOpen}
          isHoverable
          isDisabled={!isEditable}
          {...cellProps}
        >
          {getCellContent(isOpen)}
        </Trigger>
      )}
      {...getListBoxProps({
        selectionBehavior: { mode: "multiple" },
        defaultSelectedIds: task.assignees,
        itemSize: "md",
        spaceBetweenItems: "normal",
        maxVisibleItems: 5,
      })}
      onSubmit={(ids) => onUpdate({ assignees: ids })}
      onUnmount={clearSearchQuery}
    />
  );
};
