import { useTranslations } from "@jugl-web/utils";
import { Alert } from "@jugl-web/ui-components/cross-platform";
import { LoginMethod } from "@jugl-web/rest-api/auth/types";
import Lottie from "react-lottie";
import alertAnimation from "./assets/alert-animation.json";

export const RemoveLoginMethodConfirmationDialog: React.FC<{
  isOpen: boolean;
  loginMethod: LoginMethod | null;
  onRemove: () => void;
  onClose: () => void;
}> = ({ isOpen, loginMethod, onRemove, onClose }) => {
  const { t } = useTranslations();

  return (
    <Alert
      isOpen={isOpen}
      onRequestClose={onClose}
      isCloseButtonVisible
      content={
        loginMethod && (
          <div className="flex flex-col items-center justify-center">
            <Lottie
              options={{ animationData: alertAnimation }}
              height={70}
              width={70}
            />
            <div className="font-secondary mt-8 text-lg font-medium">
              {t({
                id: "settings-page.delete-confirmation",
                defaultMessage: "Delete Confirmation",
              })}
            </div>
            <div className="mt-[18px]">
              {loginMethod?.type === "email" &&
                t(
                  {
                    id: "settings-page.delete-confirmation-description",
                    defaultMessage:
                      "Are you sure you want to delete this Login Method <b>{mail}</b>? You will no longer be able to login to your Account using this Email",
                  },
                  {
                    mail: loginMethod.value,
                    b: (text: (string | JSX.Element)[]) => (
                      <span className="text-primary font-semibold">{text}</span>
                    ),
                  }
                )}
              {loginMethod.type === "mobile" &&
                t(
                  {
                    id: "settings-page.delete-confirmation-description-mobile",
                    defaultMessage:
                      "Are you sure you want to delete this Login Method <b>{phoneNumber}</b>? You will no longer be able to login to your Account using this Phone Number",
                  },
                  {
                    phoneNumber: loginMethod.value,
                    b: (text: (string | JSX.Element)[]) => (
                      <span className="text-primary font-semibold">{text}</span>
                    ),
                  }
                )}
            </div>
          </div>
        )
      }
      buttons={[
        {
          text: t({
            id: "common.delete",
            defaultMessage: "Delete",
          }),
          color: "tertiary",
          onClick: (_, actions) => {
            onRemove();
            actions.closeAlert();
          },
        },
      ]}
    />
  );
};
