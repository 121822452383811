import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Text,
  LoadingSpinner,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import useDebounce from "@web-src/hooks/useDebounce";
import { useParams } from "react-router-dom";
import useActiveChat from "@web-src/features/chats/hooks/useActiveChat";

import { useDispatch, useSelector } from "react-redux";
import {
  useTranslations,
  PaginationComponent,
  PaginationItem,
  cx,
} from "@jugl-web/utils";
import { useLazyMessageSearchQuery } from "../../chatsApi";
import { SearchChatMessage } from "../../types";
import {
  setSelectedSearchMessage,
  setSearchSidebarIsOpen,
  selectSelectedSearchMessage,
} from "../../chatsSlice";
import { ReactComponent as CloseIcon } from "./icons/close.svg";
import { MessageCard } from "./MessageCard";

export const ChatMessageSearchSidebar: React.FC = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [messages, setMessages] =
    useState<PaginationItem<SearchChatMessage>[]>();
  const [switchedChats, setSwitchedChats] = useState(false);
  const selectedMessage = useSelector(selectSelectedSearchMessage);
  const { t } = useTranslations();
  const { chat } = useActiveChat();
  const debouncedSearchQuery = useDebounce(searchQuery);
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setSwitchedChats(false);
  };
  const isQueryValid = useMemo(
    () =>
      debouncedSearchQuery.trim() !== "" && debouncedSearchQuery.length >= 3,
    [debouncedSearchQuery]
  );
  const [
    loadMessages,
    { isLoading, isFetching: isSearchFetching, data: searchResults },
  ] = useLazyMessageSearchQuery();
  const parseResponseData = (data: SearchChatMessage[]) =>
    data.map((item) => ({
      data: item,
      id: item.msg_id,
    }));
  useEffect(() => {
    if (debouncedSearchQuery && isQueryValid) {
      const handleMessages = async () => {
        const response = await loadMessages({
          params: {
            term: debouncedSearchQuery,
            chat_id: chat?.id || "",
            entity_id: params.entityId || "",
          },
        });
        if ("data" in response) {
          const parsedMessages = parseResponseData(response.data?.data || []);
          setMessages(parsedMessages);
        }
      };
      handleMessages();
    }
    dispatch(setSelectedSearchMessage(undefined));
  }, [
    chat?.id,
    debouncedSearchQuery,
    dispatch,
    isQueryValid,
    loadMessages,
    params.entityId,
  ]);

  const handleClose = () => {
    dispatch(setSearchSidebarIsOpen(false));
    dispatch(setSelectedSearchMessage(undefined));
    setSearchQuery("");
    setMessages(undefined);
  };
  useEffect(() => {
    setSearchQuery("");
    setMessages(undefined);
    setSwitchedChats(true);
  }, [chat?.id]);
  const handleMessageSelect = useCallback(
    (id: string, createdAt: string) => {
      dispatch(
        setSelectedSearchMessage({
          id,
          created_at: createdAt,
        })
      );
    },
    [dispatch]
  );
  const handleOnReachEnd = async () => {
    if (
      !isQueryValid ||
      !messages ||
      searchResults?.total_entries === searchResults?.current_entries ||
      isSearchFetching
    ) {
      return;
    }
    const response = await loadMessages({
      params: {
        term: debouncedSearchQuery,
        chat_id: chat?.id || "",
        entity_id: params.entityId || "",
        start_from: searchResults?.current_entries,
      },
    });
    if (
      "data" in response &&
      messages &&
      messages?.at(-1)?.data.msg_id !== response.data?.data[0]?.msg_id
    ) {
      const parsedResponse = parseResponseData(response.data?.data || []);
      setMessages([...messages, ...parsedResponse]);
    }
  };
  return (
    <div className="jugl__border-box-component z-30 max-h-[100vh] w-[360px] overflow-hidden shadow-[-2px_0px_3px_rgba(0,0,0,0.05)]">
      <header
        className="relative z-10 flex items-center justify-between px-8 py-[18px]"
        style={{
          boxShadow: "2px -6px 24px rgba(18, 22, 34, 0.16)",
        }}
      >
        <div className="flex items-center gap-2.5">
          <h3 className="text-dark m-0 text-lg font-semibold leading-4">
            {t({
              id: "chats-page.message-search-title",
              defaultMessage: "Search In Chat",
            })}
          </h3>
        </div>
        <PlainButton onClick={handleClose}>
          <CloseIcon />
        </PlainButton>
      </header>
      <div className="px-8 py-8">
        <SearchInput
          variant="filled"
          color="primary"
          onChange={handleSearchChange}
          value={searchQuery}
          onClear={() => {
            setSearchQuery("");
            setMessages(undefined);
          }}
        />
      </div>

      {!switchedChats && isQueryValid && (
        <>
          <div
            className={cx("mb-3 px-8", {
              hidden: isSearchFetching || isLoading || searchQuery === "",
            })}
          >
            <Text variant="body2" className="text-dark-800 font-medium">
              {t({
                id: "chats-page.message-search-results",
                defaultMessage: "Search results",
              })}
              ({searchResults?.total_entries || 0})
            </Text>
          </div>
          <div
            className={cx("h-full pb-[240px]", {
              "pb-[240px]": messages?.length,
            })}
          >
            <PaginationComponent
              items={messages || []}
              isLoading={isLoading || isSearchFetching}
              endReached={handleOnReachEnd}
              renderer={(idx, item: PaginationItem<SearchChatMessage>) => (
                <MessageCard
                  key={item.data.msg_id}
                  onClick={handleMessageSelect}
                  searchQuery={debouncedSearchQuery}
                  isSelected={selectedMessage?.id === item.data.msg_id}
                  {...item.data}
                />
              )}
            />
          </div>
        </>
      )}

      {isSearchFetching && (
        <div className="flex w-full items-center justify-center">
          <LoadingSpinner size="md" color="primary" />
        </div>
      )}
    </div>
  );
};
