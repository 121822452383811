import { cx, useTranslations } from "@jugl-web/utils";
import format from "date-fns/format";
import isYesterday from "date-fns/isYesterday";
import isToday from "date-fns/isToday";
import { FC, ReactNode } from "react";
import { Avatar } from "../Avatar";
import { InteractiveContainer } from "../InteractiveContainer";
import { ReactComponent as FlagIcon } from "./assets/flag.svg";

interface NotificationItemContainerProps {
  children: ReactNode;
  isRead: boolean;
  className?: string;
  onClick?: () => void;
}

const NotificationItemContainer: FC<NotificationItemContainerProps> = ({
  children,
  isRead,
  className,
  onClick,
}) => (
  <InteractiveContainer
    onClick={onClick}
    isDisabled={!onClick}
    className={cx("relative rounded-xl bg-white py-6 px-4", className)}
    style={{ boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.05)" }}
  >
    {children}
    {!isRead && <FlagIcon className="absolute right-0 top-0" />}
  </InteractiveContainer>
);

export interface NotificationItemEntry {
  id: string;
  image:
    | { type: "avatar"; username: string; imageUrl: string | null }
    | { type: "icon"; icon: ReactNode };
  message: ReactNode;
  date: Date;
}

export interface NotificationItemProps {
  entries: NotificationItemEntry[];
  isRead: boolean;
  contextualDetails?: {
    icon: ReactNode;
    title: string;
  };
  className?: string;
  onClick?: () => void;
}

export const NotificationItem: FC<NotificationItemProps> = ({
  entries,
  isRead,
  contextualDetails,
  className,
  onClick,
}) => {
  const { t } = useTranslations();

  const getDateLabel = (date: Date) => {
    if (isToday(date)) {
      return `${t({
        id: "common.today",
        defaultMessage: "Today",
      })}, ${format(date, "h:mm a")}`;
    }

    if (isYesterday(date)) {
      return `${t({
        id: "common.yesterday",
        defaultMessage: "Yesterday",
      })}, ${format(date, "h:mm a")}`;
    }

    return format(date, "MMM d, h:mm a");
  };

  return (
    <NotificationItemContainer
      isRead={isRead}
      className={className}
      onClick={onClick}
    >
      {contextualDetails && (
        <div className="mb-3 flex items-center gap-2.5">
          {contextualDetails.icon}
          <span className="text-dark-700 text-xs leading-[17px]">
            {contextualDetails.title}
          </span>
        </div>
      )}
      <div className="flex flex-col">
        {entries.map((entry) => (
          <div
            key={entry.id}
            className="border-grey-200 flex items-start gap-2.5 border border-l-0 border-t-0 border-r-0 border-solid py-3 first:pt-0 last:border-b-0 last:pb-0"
          >
            {entry.image.type === "avatar" ? (
              <Avatar size="sm" {...entry.image} />
            ) : (
              entry.image.icon
            )}
            <div className="flex min-w-[40%] flex-col gap-2">
              <div className="leading-2 whitespace-pre-wrap break-words text-sm text-black">
                {entry.message}
              </div>
              <div className="text-grey-900 text-xs leading-[14px]">
                {getDateLabel(entry.date)}
              </div>
            </div>
          </div>
        ))}
      </div>
    </NotificationItemContainer>
  );
};
