import {
  Button,
  Dialog,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { Stepper } from "@jugl-web/ui-components/web";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import Lottie from "react-lottie";
import { useTranslations } from "@jugl-web/utils";
import { ReactComponent as CloseIcon } from "./assets/close.svg";
import finishedAnimation from "./assets/finished.json";
import confettiAnimation from "./assets/confetti.json";
import { useOnboardingSteps } from "../../hooks/useOnboardingSteps";
import { GetStartedDialogStep } from "../../types";

export interface GetStartedDialogHandle {
  markStepAsCompleted: (index: number) => void;
}

type GetStartedDialogProps = {
  isOpen: boolean;
  activeStepIndex: number;
  isOnboardingFinished: boolean;
  openSpotlight: (type?: GetStartedDialogStep) => void;
  onFinishOnboarding: () => void;
  setStepIndex: (stepIndex: number) => void;
  onRequestClose: () => void;
};

export const GetStartedDialog = forwardRef<
  GetStartedDialogHandle,
  GetStartedDialogProps
>(
  (
    {
      isOpen,
      activeStepIndex,
      isOnboardingFinished,
      openSpotlight,
      onFinishOnboarding,
      setStepIndex,
      onRequestClose,
    },
    ref
  ) => {
    const { t } = useTranslations();
    const [isConfettiPlaying, setIsConfettiPlaying] = useState(false);
    const { steps } = useOnboardingSteps();

    useImperativeHandle(ref, () => ({
      markStepAsCompleted: (index: number) => {
        setIsConfettiPlaying(true);
        setStepIndex(index + 1 > steps.length - 1 ? index : index + 1);
      },
    }));

    const isOnboardingStepsFinished = useMemo(
      () => steps.every((step) => step.isCompleted),
      [steps]
    );

    const onClose = () => {
      onRequestClose();
      setIsConfettiPlaying(false);
    };

    const activeStep = steps[activeStepIndex];

    const buttonText =
      activeStep.isCompleted && !isOnboardingStepsFinished
        ? `${t({
            id: "common.done",
            defaultMessage: "Done",
          })} 🎉`
        : isOnboardingStepsFinished
        ? t({
            id: "common.thanks",
            defaultMessage: "Thanks",
          })
        : t({
            id: "common.start",
            defaultMessage: "Start",
          });

    useEffect(() => {
      if (isOnboardingStepsFinished && !isOnboardingFinished) {
        onFinishOnboarding();
      }
    }, [isOnboardingStepsFinished, isOnboardingFinished, onFinishOnboarding]);

    const handleButtonClick = () => {
      if (isOnboardingStepsFinished) {
        onRequestClose();
      } else {
        activeStep.onStart();
        openSpotlight(activeStep.type);
        onClose();
      }
    };

    return (
      <>
        <Lottie
          isClickToPauseDisabled
          options={{
            animationData: confettiAnimation,
            loop: false,
            autoplay: false,
          }}
          isStopped={!isConfettiPlaying}
          eventListeners={[
            {
              eventName: "complete",
              callback: () => setIsConfettiPlaying(false),
            },
          ]}
          style={{
            width: "100%",
            height: "100vh",
            position: "absolute",
            zIndex: 1,
            display: isOpen ? "block" : "none",
          }}
        />
        <Dialog
          isOpen={isOpen}
          onClose={onClose}
          className="mx-auto flex max-w-[788px] flex-col"
          size="full-width"
        >
          <div className="flex items-center justify-between border-0 border-b border-solid border-[#ECEFF0] py-[14px] px-6">
            <span>
              {t({
                id: "get-started-dialog-component.get-started",
                defaultMessage: "Get Started 💫",
              })}
            </span>
            <PlainButton onClick={onClose}>
              <CloseIcon />
            </PlainButton>
          </div>

          <div className="relative flex flex-col gap-14 overflow-y-auto p-16">
            <div className="flex items-center gap-2.5">
              <div className="h-[280px] max-w-[300px]">
                {isOnboardingStepsFinished ? (
                  <Lottie options={{ animationData: finishedAnimation }} />
                ) : (
                  activeStep.image
                )}
              </div>
              <div className="flex w-[350px] flex-col items-center gap-2 text-center">
                {!isOnboardingStepsFinished && (
                  <span className="text-grey-800 text-sm">
                    {t(
                      {
                        id: "get-started-dialog-component.step",
                        defaultMessage: "Step #{stepNumber}",
                      },
                      {
                        stepNumber: activeStepIndex + 1,
                      }
                    )}
                  </span>
                )}
                <span className="text-dark text-[22px] font-semibold leading-[33px]">
                  {isOnboardingStepsFinished
                    ? t({
                        id: "get-started-dialog-component.good-to-go",
                        defaultMessage: "You're good to go!",
                      })
                    : activeStep.title}
                </span>
                <span className="text-dark-800 leading-4">
                  {isOnboardingStepsFinished
                    ? `${t({
                        id: "get-started-dialog-component.onboarding-completion",
                        defaultMessage:
                          "You did a great job in Onboarding, now you're ready to use Jugl App",
                      })} 🚀`
                    : activeStep.description}
                </span>
                <Button
                  color={
                    activeStep.isCompleted && !isOnboardingStepsFinished
                      ? "secondary"
                      : "primary"
                  }
                  isDisabled={
                    activeStep.isCompleted && !isOnboardingStepsFinished
                  }
                  className="mt-8 w-3/4"
                  onClick={handleButtonClick}
                >
                  {buttonText}
                </Button>
              </div>
            </div>
            <Stepper
              activeStepIndex={activeStepIndex}
              steps={steps}
              onChange={setStepIndex}
            />
          </div>
        </Dialog>
      </>
    );
  }
);
