import React, { ImgHTMLAttributes, useState } from "react";
import previewImage from "./images/preview-image.png";

export interface ImageWithFallbackProps
  extends ImgHTMLAttributes<HTMLImageElement> {
  fallbackSrc?: string;
}

export const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({
  fallbackSrc,
  src,
  ...props
}) => {
  const [isFailed, setIsFailed] = useState<boolean>();
  const onError = () => setIsFailed(true);
  return (
    <img
      src={isFailed ? fallbackSrc || (previewImage as unknown as string) : src}
      onError={onError}
      {...{ ...props, alt: props.alt || "" }}
    />
  );
};
