import { useRestApiProvider } from "@jugl-web/rest-api";
import { SafeUserGeneralProfile } from "@jugl-web/rest-api/users";
import { getImagePathForSharedLibrary, useTranslations } from "@jugl-web/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMemo } from "react";
import juglLogo from "./assets/jugl-logo.webp";

export interface UseUserGeneralProfileOptions {
  userId?: string;
  entityId?: string;
  skip?: boolean;
}

export const useUserGeneralProfile = ({
  userId,
  entityId,
  skip,
}: UseUserGeneralProfileOptions) => {
  const { t } = useTranslations();
  const { usersApi } = useRestApiProvider();

  const {
    data: profile,
    status,
    refetch,
  } = usersApi.useGetUserGeneralProfileQuery(
    userId && entityId && !skip
      ? {
          entityId,
          params: { user_id: userId },
        }
      : skipToken
  );

  const safeProfile = useMemo<SafeUserGeneralProfile>(() => {
    if (profile) {
      return {
        displayName: profile.displayName,
        avatar: profile.avatar || null,
      };
    }

    return {
      displayName: t({ id: "common.jugl-user", defaultMessage: "Jugl User" }),
      avatar: getImagePathForSharedLibrary(juglLogo),
    };
  }, [profile, t]);

  return {
    profile,
    safeProfile,
    status,
    refetch,
  };
};
