import { useTusUpload } from "@jugl-web/domain-resources/files/hooks/useTusUpload";
import { FilesModule } from "@jugl-web/domain-resources/files/types";
import { DriveApiTags } from "@jugl-web/rest-api/drive/tags";
import { getUniqueId, useToast, useTranslations } from "@jugl-web/utils";
import { HookOutOfContextError } from "@jugl-web/utils/errors/HookOutOfContext";
import { driveApi } from "@web-src/features/api/createApi";
import useEntity from "@web-src/features/app/hooks/useEntity";
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useDrive } from "../hooks/useDrive";

interface UploadingFileItem {
  id: string;
  dirId: string;
  file: File;
  progress: number;
  isCompleted?: boolean;
  isFailed?: boolean;
}

interface DriveContextValue {
  uploadingFiles: UploadingFileItem[];
  uploadFiles: (parentDirId: string, files: File[]) => Promise<number>;
  driveHook: ReturnType<typeof useDrive>;
}

export const DriveContext = createContext<DriveContextValue | null>(null);

interface DriveProviderProps {
  children: ReactNode;
}

export const DriveProvider: FC<DriveProviderProps> = ({ children }) => {
  const { t } = useTranslations();
  const { toast } = useToast({ variant: "web" });
  const [uploadingFiles, setUploadingFiles] = useState<
    DriveContextValue["uploadingFiles"]
  >([]);
  const filesUploadProgress = useRef<{
    [key: string]: {
      isFailed?: boolean;
      isCompleted?: boolean;
      progress: number;
    };
  }>({});
  const { entity } = useEntity();
  const { uploadFile } = useTusUpload({
    module: FilesModule.drive,
    entityId: entity?.id,
  });
  const dispatch = useDispatch();

  const driveHook = useDrive();

  const uploadFiles: DriveContextValue["uploadFiles"] = useCallback(
    async (parentDirId, files) => {
      const newUploadingItems: UploadingFileItem[] = files.map((file) => ({
        id: getUniqueId(),
        dirId: parentDirId,
        file,
        progress: 0,
        isFailed: false,
      }));
      setUploadingFiles((prev) => [...prev, ...newUploadingItems]);
      await Promise.all(
        newUploadingItems.map((item) =>
          uploadFile({
            file: item.file,
            extraMetaData: { parent_dir_id: parentDirId },
            onProgress: (bytesSend, bytesTotal) => {
              const progressId = `${parentDirId}-${item.id}`;
              filesUploadProgress.current[progressId] = {
                ...filesUploadProgress.current[progressId],
                progress: Math.round((bytesSend / bytesTotal) * 100),
              };
            },
          })
            .then(() => {
              const progressId = `${parentDirId}-${item.id}`;
              filesUploadProgress.current[progressId] = {
                ...filesUploadProgress.current[progressId],
                isCompleted: true,
              };
            })
            .catch((error: Error) => {
              if (
                error.message.match(/response text: ([^,]+)/)?.[1].trim() ===
                "No space to upload this file"
              ) {
                toast(
                  t({
                    id: "drive-page.drive-is-full",
                    defaultMessage: "Drive is full",
                  }),
                  {
                    variant: "error",
                  }
                );
              }
              const progressId = `${parentDirId}-${item.id}`;
              filesUploadProgress.current[progressId] = {
                ...filesUploadProgress.current[progressId],
                isFailed: true,
              };
            })
        )
      );

      const successfullyUploaded = newUploadingItems.filter(
        (item) =>
          filesUploadProgress.current[`${parentDirId}-${item.id}`].isCompleted
      );
      setUploadingFiles((prev) =>
        prev.filter(
          (item) =>
            !successfullyUploaded.find((newItem) => item.id === newItem.id)
        )
      );

      dispatch(
        driveApi.util.invalidateTags([DriveApiTags.driveDirectoryContents])
      );

      return successfullyUploaded.length;
    },
    [dispatch, uploadFile, t, toast]
  );

  useEffect(() => {
    setInterval(() => {
      setUploadingFiles((prev) =>
        prev.map((item) => ({
          ...item,
          progress:
            filesUploadProgress.current[`${item.dirId}-${item.id}`]?.progress ||
            0,
          isCompleted:
            filesUploadProgress.current[`${item.dirId}-${item.id}`]
              ?.isCompleted,
          isFailed:
            filesUploadProgress.current[`${item.dirId}-${item.id}`]?.isFailed,
        }))
      );
    }, 1000);
  }, []);

  const value: DriveContextValue = useMemo(
    () => ({ uploadFiles, uploadingFiles, driveHook }),
    [uploadFiles, uploadingFiles, driveHook]
  );

  return (
    <DriveContext.Provider value={value}>{children}</DriveContext.Provider>
  );
};

export const useDriveProvider = () => {
  const context = useContext(DriveContext);

  if (!context) {
    throw new HookOutOfContextError("useDriveProvider", "DriveProvider");
  }

  return context;
};
