import {
  PageLoaderFull,
  InteractiveContainer,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { Tooltip } from "@jugl-web/ui-components/web";
import { onEscape, useToast, useTranslations } from "@jugl-web/utils";
import React, { useState } from "react";
import { entitiesApi } from "@web-src/features/api/createApi";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { ReactComponent as CheckIcon } from "./assets/check.svg";

export const WorkspaceEditableName: React.FC = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { entity } = useEntitySelectedProvider();
  const [workspaceName, setWorkspaceName] = useState("");
  const { toast } = useToast({ variant: "web" });
  const [updateEntity, { isLoading }] = entitiesApi.useEditEntityMutation();

  const { t } = useTranslations();

  const handleEditWorkspaceName = () => {
    setIsEditing(true);
    setWorkspaceName(entity.name);
  };

  const handleNameChange = async () => {
    const response = await updateEntity({
      data: { name: workspaceName },
      entityId: entity.id,
    });
    if (response && "data" in response) {
      toast(
        t({
          id: "workspace-settings-page.workspace-name-was-updated",
          defaultMessage: "Workspace name was updated",
        })
      );
      setIsEditing(false);
    }
  };

  return (
    <>
      <PageLoaderFull isActive={isLoading} isTransparent />
      {isEditing ? (
        <div className="relative flex items-start gap-4">
          <input
            className="focus:border-primary text-dark w-[400px] border-0 border-b border-solid border-black/20 bg-transparent py-[14px] pr-16 pt-2 font-[Roboto] text-[26px] font-medium leading-[39px] outline-none transition-colors"
            value={workspaceName}
            onChange={(event) => setWorkspaceName(event.target.value)}
            onKeyDown={onEscape(() => setIsEditing(false))}
            autoFocus
          />
          <PlainButton
            className="bg-primary absolute right-2 top-2 flex h-10 w-10 items-center justify-center rounded-xl"
            disabled={workspaceName.trim().length < 1}
            onClick={handleNameChange}
          >
            <CheckIcon />
          </PlainButton>
        </div>
      ) : (
        <Tooltip
          placement="right"
          delay="none"
          renderTrigger={({ ref }) => (
            <InteractiveContainer
              ref={ref}
              className="transition-color w-full rounded-[10px] py-2 px-1 duration-300 hover:bg-[#EEF2F5]"
              onClick={handleEditWorkspaceName}
            >
              <div className="text-dark whitespace-pre-wrap break-words text-[26px] font-medium">
                {entity.name}
              </div>
            </InteractiveContainer>
          )}
        >
          {t({
            id: "workspace-settings-page.tap-to-edit-workspace-name",
            defaultMessage: "Tap to edit workspace name",
          })}{" "}
          ✨
        </Tooltip>
      )}
    </>
  );
};
