import { Portal } from "@headlessui/react";
import { FC } from "react";

interface ConditionallyRenderedPortalProps {
  children: JSX.Element;
  isEnabled: boolean;
}

export const ConditionallyRenderedPortal: FC<
  ConditionallyRenderedPortalProps
> = ({ children, isEnabled }) => {
  if (isEnabled) {
    return <Portal>{children}</Portal>;
  }

  return children;
};
