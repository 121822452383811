import { useSearchInput, useTranslations } from "@jugl-web/utils";
import React, { Dispatch, SetStateAction } from "react";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { Button } from "@jugl-web/ui-components/cross-platform";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { ReactComponent as AddIcon } from "./assets/add.svg";

export const ControlBar: React.FC<{
  onSearchQueryChange: Dispatch<SetStateAction<string>>;
}> = ({ onSearchQueryChange }) => {
  const { inputProps, reset } = useSearchInput({
    onSearch: onSearchQueryChange,
  });
  const { t } = useTranslations();
  const { navigateToPage } = useNavigation();

  return (
    <>
      <div className="flex items-center justify-between bg-[#EEF2F5] py-4 px-8">
        <div className="max-w-[280px]">
          <SearchInput
            variant="filled"
            color="white"
            onReset={reset}
            {...inputProps}
          />
        </div>
        <Button
          iconEnd={<AddIcon />}
          onClick={() => navigateToPage("reportsScheduledForm")}
        >
          {t({
            id: "scheduled-reports-page.schedule-report",
            defaultMessage: "Schedule Report",
          })}
        </Button>
      </div>
    </>
  );
};
