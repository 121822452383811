import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "@web-src/store";
import {
  ChatMessage,
  ChatMessageAction,
  ChatMessageActionType,
} from "@web-src/features/chats/types";

type ChatsState = {
  openedChatMessage?: ChatMessage;
  messageActions: { [key: string]: ChatMessageAction };
  openedUserProfile?: string;
  activeChatId?: string;

  messagesReadSent: { [key: string]: boolean };
  selectedSearchMessage?: {
    id: string;
    created_at: string;
  };
  searchSidebarIsOpen: boolean;
};

const initialState: ChatsState = {
  messageActions: {},
  activeChatId: undefined,
  openedChatMessage: undefined,
  openedUserProfile: undefined,

  messagesReadSent: {},
  selectedSearchMessage: undefined,
  searchSidebarIsOpen: false,
};

const chatsSlice = createSlice({
  name: "chats",
  initialState,
  reducers: {
    setActiveChatId(state, { payload }: { payload: string }) {
      state.activeChatId = payload;
      state.selectedSearchMessage = undefined;
    },
    setOpenedChatMessage(
      state,
      { payload }: { payload: ChatMessage | undefined }
    ) {
      state.openedChatMessage = payload;
    },
    setMessageAction: (
      state,
      {
        payload: { action, chatId },
      }: {
        payload: {
          action: {
            type: ChatMessageActionType;
            message: ChatMessage;
          };
          chatId: string;
        };
      }
    ) => {
      state.messageActions = {
        ...state.messageActions,
        [chatId]: action,
      };
    },
    cancelMessageAction: (state, { payload: chatId }: { payload: string }) => {
      const updatedMessageActions = { ...state.messageActions };
      delete updatedMessageActions[chatId];
      state.messageActions = updatedMessageActions;
    },
    setMessageReadSent: (
      state,
      { payload: { id, read } }: { payload: { id: string; read: boolean } }
    ) => {
      state.messagesReadSent[id] = read;
    },
    setOpenedUserProfile: (
      state,
      { payload }: { payload: string | undefined }
    ) => {
      state.openedUserProfile = payload;
    },
    setSelectedSearchMessage: (
      state,
      { payload }: { payload: { id: string; created_at: string } | undefined }
    ) => {
      state.selectedSearchMessage = payload;
    },
    setSearchSidebarIsOpen: (state, { payload }: { payload: boolean }) => {
      state.searchSidebarIsOpen = payload;
    },
    reset: () => initialState,
  },
});

export const selectActiveChatId = createSelector(
  [(state: RootState) => state.chats],
  (chats) => chats.activeChatId
);

export const selectMessageAction = (chatId: string) =>
  createSelector(
    [(state: RootState) => state.chats],
    (chats) => chats.messageActions[chatId]
  );

export const selectOpenedChatMessage = createSelector(
  [(state: RootState) => state.chats],
  (chats) => chats.openedChatMessage
);

export const selectMessagesReadSent = createSelector(
  [(state: RootState) => state.chats],
  (chats) => chats.messagesReadSent
);

export const selectOpenedUserProfile = createSelector(
  [(state: RootState) => state.chats],
  (chats) => chats.openedUserProfile
);

export const selectSelectedSearchMessage = createSelector(
  [(state: RootState) => state.chats],
  (chats) => chats.selectedSearchMessage
);

export const selectSearchSidebarIsOpen = createSelector(
  [(state: RootState) => state.chats],
  (chats) => chats.searchSidebarIsOpen
);

export const {
  setActiveChatId,
  setMessageAction,
  cancelMessageAction,
  reset,
  setOpenedChatMessage,
  setMessageReadSent,
  setSelectedSearchMessage,
  setOpenedUserProfile,
  setSearchSidebarIsOpen,
} = chatsSlice.actions;

export default chatsSlice;
