import { useTaskFiltering } from "@jugl-web/domain-resources/tasks/components/TaskFilteringProvider";
import { useTaskFields } from "@jugl-web/domain-resources/tasks/hooks/useTaskFields";
import { useTaskFiltersStateContext } from "@jugl-web/domain-resources/tasks/hooks/useTaskFiltersState";
import { InternalTaskFilterSet } from "@jugl-web/rest-api/tasks";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { BottomCenteredDrawer } from "@jugl-web/ui-components/web/BottomCenteredDrawer";
import { useAppVariant, useToast, useTranslations } from "@jugl-web/utils";
import { useScreenTransitionManager } from "@jugl-web/utils/utils/ScreenTransitionManager";
import { TaskFilterSections } from "@web-src/features/tasks/TaskFilterSections";
import { FC } from "react";
import { ManageFilterSetDialogScreenToParametersMap } from "../../types";

interface SelectFiltersScreenProps {
  entityId: string;
  filterSetName: string;
  editingFilterSet: InternalTaskFilterSet | null;
  onDialogClose: () => void;
}

export const SelectFiltersScreen: FC<SelectFiltersScreenProps> = ({
  entityId,
  filterSetName,
  editingFilterSet,
  onDialogClose,
}) => {
  const { filtersState, activeFiltersCount, resetFiltersState } =
    useTaskFiltersStateContext();

  const { createFilterSet, isCreatingFilterSet } = useTaskFields({ entityId });
  const { changeFiltering } = useTaskFiltering();

  const { transitionTo } =
    useScreenTransitionManager<ManageFilterSetDialogScreenToParametersMap>();

  const { t } = useTranslations();
  const { variant } = useAppVariant();
  const { toast } = useToast({ variant });

  const isEditing = !!editingFilterSet;

  const submitButtonLabel = (() => {
    if (!isEditing) {
      return t({ id: "common.save", defaultMessage: "Save" });
    }

    if (activeFiltersCount > 0) {
      return t(
        {
          id: "common.apply-with-count",
          defaultMessage: "Apply {count}",
        },
        { count: activeFiltersCount }
      );
    }

    return t({ id: "common.apply", defaultMessage: "Apply" });
  })();

  const handleSubmit = async () => {
    if (isEditing) {
      transitionTo({ name: "entry" });
      return;
    }

    try {
      const createdFilterSet = await createFilterSet(
        filterSetName,
        filtersState
      );

      toast(
        <span>
          {t(
            {
              id: "feedback.filter-set-created",
              defaultMessage: "Filter set {name} has been created",
            },
            { name: <b>{createdFilterSet.name}</b> }
          )}
        </span>,
        { variant: "success" }
      );

      changeFiltering({ type: "filter-set", filterSetId: createdFilterSet.id });
      onDialogClose();
    } catch {
      // Do nothing
    }
  };

  return (
    <>
      <BottomCenteredDrawer.Content className="py-4">
        <TaskFilterSections entityId={entityId} />
      </BottomCenteredDrawer.Content>
      <BottomCenteredDrawer.Actions className="flex items-center justify-center gap-3.5">
        {!isEditing && (
          <Button
            variant="contained"
            color="grey"
            className="h-10 w-[200px]"
            onClick={() =>
              activeFiltersCount > 0
                ? resetFiltersState()
                : transitionTo({ name: "entry" })
            }
          >
            {activeFiltersCount > 0
              ? t({ id: "common.clear-all", defaultMessage: "Clear all" })
              : t({ id: "common.cancel", defaultMessage: "Cancel" })}
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          className="h-10 w-[200px]"
          isDisabled={activeFiltersCount === 0 || isCreatingFilterSet}
          onClick={handleSubmit}
        >
          {submitButtonLabel}
        </Button>
      </BottomCenteredDrawer.Actions>
    </>
  );
};
