import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { ChatMessage } from "@web-src/features/chats/types";
import { useToast, useTranslations } from "@jugl-web/utils";
import { Radio } from "@jugl-web/ui-components/cross-platform/Radio";
import { ChatSideBar } from "@web-src/modules/chats/components/ChatSideBar";
import { getMessageChatId } from "../../utils";
import useDeleteMessage from "../../hooks/useDeleteMessage";

enum DeleteType {
  everyone = "everyone",
  me = "me",
}

const DeleteSideBar: React.FC<{
  messages: ChatMessage[];
  onRequestClose: () => void;
}> = ({ messages, onRequestClose }) => {
  const deleteMessage = useDeleteMessage();
  const [deleteType, setDeleteType] = useState<DeleteType>(DeleteType.everyone);
  const { toast } = useToast({ variant: "web" });
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslations();

  const handleDeleteClick = async () => {
    try {
      const promises = messages.map((message) => {
        const chatId = getMessageChatId(message);
        if (!chatId) {
          return undefined;
        }
        return deleteMessage({ msgId: message.msg_id, chatId });
      });

      await Promise.all(promises);
      toast(
        t(
          {
            id: "chats-page.messages-deleted",
            defaultMessage:
              "{count} {count, plural, one {message} other {messages}} deleted",
          },
          {
            count: messages.length,
          }
        )
      );
      onRequestClose();
    } catch (error) {
      enqueueSnackbar(
        t({
          id: "chats-page.something-went-wrong",
          defaultMessage: "Something went wrong, please try again later",
        }),
        {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
          autoHideDuration: 2000,
        }
      );
    }
  };

  const handleChangeDeleteType = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDeleteType(event.target.value as DeleteType);
  };

  return (
    <ChatSideBar title="Delete Messages" onRequestClose={onRequestClose}>
      <div className="mt-4 flex w-[400px] flex-col gap-10 p-8">
        <span className="text-sm font-bold text-[#707577]">
          {t(
            {
              id: "chats-page.messages-selected",
              defaultMessage:
                "{count} {count, plural, one {message} other {messages}} selected",
            },
            {
              count: messages.length,
            }
          )}
        </span>
        <div
          className="flex flex-col gap-[10px]"
          onChange={handleChangeDeleteType}
        >
          <div className="flex gap-[10px]">
            <Radio
              isChecked={deleteType === DeleteType.everyone}
              value={DeleteType.everyone}
            />
            <span className="text-lg text-[#333333]">
              {t({
                id: "chats-page.delete-for-everyone",
                defaultMessage: "Delete for everyone",
              })}
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <Button
            fullWidth
            isDisabled={!messages.length}
            onClick={handleDeleteClick}
            className="uppercase"
          >
            {t({
              id: "chats-page.delete-messages",
              defaultMessage: "Delete Messages",
            })}
          </Button>
        </div>
      </div>
    </ChatSideBar>
  );
};

export default DeleteSideBar;
