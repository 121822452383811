export const ANDROID_DEVICE = "ANDROID";
export const IOS_DEVICE = "IOS";
export const BROWSER = "BROWSER";
export const PLATFORM_DEVICES = {
  [ANDROID_DEVICE]: ANDROID_DEVICE,
  [IOS_DEVICE]: IOS_DEVICE,
  [BROWSER]: BROWSER,
};

const nav = () => typeof navigator !== "undefined";

const ua = () =>
  nav() ? navigator.userAgent || navigator.vendor || window.opera : undefined;

export const isWindowPhone = () => /windows phone/i.test(ua());

export const isIOS = () => /iPhone|iPad|iPod/i.test(ua());

export const isAndroid = () => /Android/i.test(ua());

export const isMobile = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua());

export const getSupportedPlatForm = () => {
  if (isAndroid()) {
    return ANDROID_DEVICE;
  }
  if (isIOS()) {
    return IOS_DEVICE;
  }
  return BROWSER;
};

export const canConnectToBridge = (device) =>
  typeof device === "object" && typeof device.postMessage === "function";
