import { Text } from "@jugl-web/ui-components/cross-platform/Text";
import { cx } from "@jugl-web/utils";
import animationData from "@jugl-web/utils/animations/loader-light.json";
import { FC } from "react";
import Lottie from "react-lottie";

interface TaskViewLoadingProps {
  label: string;
  className?: string;
}

export const TaskViewLoading: FC<TaskViewLoadingProps> = ({
  label,
  className,
}) => (
  <div
    className={cx("flex flex-col items-center justify-center gap-6", className)}
  >
    <Lottie options={{ animationData }} height={100} width={100} />
    <Text variant="body1" className="text-dark font-medium">
      {label}
    </Text>
  </div>
);
