import { useMemo } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { User, useRestApiProvider } from "@jugl-web/rest-api";

const useEntityParticipants = (entityId?: string) => {
  const { usersApi } = useRestApiProvider();
  const { data: participants, isLoading } =
    usersApi.use_DEPRECATED_getAllUsersQuery(
      entityId ? { entityId } : skipToken
    );
  const participantsMap = useMemo(() => {
    const result: { [key: string]: User } = {};
    participants?.forEach((item) => {
      result[item.id] = item;
    });
    return result;
  }, [participants]);
  return {
    participants,
    participantsMap,
    isLoading,
  };
};

export default useEntityParticipants;
