import React, { useMemo } from "react";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { getUserProfileDisplayName } from "../../utils";

const UserProfileAvatar: React.FC<{
  userId?: string | null;
  className?: string;
  wrapperClassName?: string;
  lettersClassName?: string;
  onClick?: () => void;
}> = ({ userId, className, wrapperClassName, lettersClassName, onClick }) => {
  const { usersApi } = useRestApiProvider();
  const { data } = usersApi.useGetUserProfileQuery(
    userId ? { params: { user_id: userId } } : {}
  );
  const letters = useMemo(() => {
    if (!data) {
      return null;
    }
    return getUserProfileDisplayName(data)
      .split(" ")
      .map((n) => n[0])
      .join("")
      .slice(0, 2);
  }, [data]);
  return (
    <div className={wrapperClassName} onClick={onClick}>
      {data?.general?.img ? (
        <img
          className={className}
          src={data.general.img}
          alt={letters || "avatar"}
        />
      ) : (
        <div className={lettersClassName}>{letters}</div>
      )}
    </div>
  );
};

export default UserProfileAvatar;
