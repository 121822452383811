import React from "react";
import { Alert, Text } from "@jugl-web/ui-components/cross-platform";
import { useTranslations, assert, useToast } from "@jugl-web/utils";
import { entitiesApi } from "@web-src/features/api/createApi";
import Lottie from "react-lottie";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import invitationLinkAnimation from "./assets/invitation-link.animation.json";

export const InvitationLinkAlert: React.FC<{
  isOpen: boolean;
  onRequestClose: () => void;
}> = ({ isOpen, onRequestClose }) => {
  const { t } = useTranslations();
  const { entity } = useEntitySelectedProvider();
  const { toast } = useToast({ variant: "web" });
  const [createInvitationLink, { isLoading }] =
    entitiesApi.useCreateInvitationLinkMutation();

  const handleCopyLink = async () => {
    assert(!!entity);
    const response = await createInvitationLink({ entityId: entity.id });
    if ("data" in response) {
      try {
        await navigator.clipboard.writeText(response.data.short_url);
        onRequestClose();
        toast(
          t({
            id: "cpanel-page.invitation-link-copied-to-clipboard",
            defaultMessage: "Invitation Link was copied to clipboard",
          }),
          { variant: "success" }
        );
      } catch (error) {
        toast(
          t({
            id: "cpanel-page.invitation-link-copy-failed",
            defaultMessage: "Failed to copy the invitation link to clipboard",
          }),
          { variant: "error" }
        );
      }
    }
  };
  return (
    <Alert
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      isCloseButtonVisible
      title={t({
        id: "cpanel-page.invitation-link",
        defaultMessage: "Invitation Link",
      })}
      img={
        <Lottie
          height={150}
          width={150}
          options={{ animationData: invitationLinkAnimation }}
        />
      }
      content={
        <Text variant="body3" className="text-dark-800">
          {t(
            {
              id: "cpanel-page.invitation-link-description",
              defaultMessage:
                "Invitation Link is <highlightedText>valid for 2 hours</highlightedText> after you copy it. After sign up, Users will be able to see invitation to Your Workspace 🤗",
            },
            {
              highlightedText: (text: (string | JSX.Element)[]) => (
                <span className="text-primary-800 font-semibold">{text}</span>
              ),
            }
          )}
        </Text>
      }
      buttons={[
        {
          text: t({
            id: "cpanel-page.copy-invitation-link",
            defaultMessage: "Copy Invitation Link",
          }),
          color: "primary",
          isDisabled: isLoading,
          onClick: handleCopyLink,
        },
      ]}
    />
  );
};
