import { filterSetNameValidator } from "@jugl-web/domain-resources/tasks";
import { useTaskFields } from "@jugl-web/domain-resources/tasks/hooks/useTaskFields";
import { useTaskFiltersStateContext } from "@jugl-web/domain-resources/tasks/hooks/useTaskFiltersState";
import { InternalTaskFilterSet } from "@jugl-web/rest-api/tasks";
import { BottomCenteredDrawer } from "@jugl-web/ui-components/web/BottomCenteredDrawer";
import {
  assert,
  useAppVariant,
  useToast,
  useTranslations,
} from "@jugl-web/utils";
import { useScreenTransitionManager } from "@jugl-web/utils/utils/ScreenTransitionManager";
import { FilterSetForm } from "@web-src/features/tasks/FilterSetForm";
import { FC } from "react";
import { ManageFilterSetDialogScreenToParametersMap } from "../../types";

interface EntryScreenProps {
  entityId: string;
  filterSetName: string;
  editingFilterSet: InternalTaskFilterSet | null;
  onChangeFilterSetName: (name: string) => void;
  onDialogClose: () => void;
}

export const EntryScreen: FC<EntryScreenProps> = ({
  entityId,
  filterSetName,
  editingFilterSet,
  onChangeFilterSetName,
  onDialogClose,
}) => {
  const { filtersState, activeFiltersCount } = useTaskFiltersStateContext();

  const { updateFilterSet, isUpdatingFilterSet } = useTaskFields({ entityId });

  const { transitionTo } =
    useScreenTransitionManager<ManageFilterSetDialogScreenToParametersMap>();

  const { t } = useTranslations();
  const { variant } = useAppVariant();
  const { toast } = useToast({ variant });

  const isEditing = !!editingFilterSet;
  const isNameValid = filterSetNameValidator(filterSetName);

  const handleSubmit = async () => {
    assert(isEditing, "Cannot submit filter set in this screen in create mode");

    try {
      await updateFilterSet(editingFilterSet.id, filterSetName, filtersState);

      toast(
        t({
          id: "feedback.filter-set-updated",
          defaultMessage: "Filter set has been updated",
        }),
        { variant: "success" }
      );

      onDialogClose();
    } catch {
      // Do nothing
    }
  };

  const handleMoveToFilters = () => {
    transitionTo({ name: "selectFilters" });
  };

  return (
    <BottomCenteredDrawer.Content className="flex items-center justify-center py-6">
      <FilterSetForm className="gap-14">
        <FilterSetForm.Header type={isEditing ? "edit" : "create"} />
        <FilterSetForm.Input
          value={filterSetName}
          onChange={(event) => onChangeFilterSetName(event.target.value)}
        />
        {isEditing && (
          <FilterSetForm.MoveToFiltersButton
            className="-mt-7"
            activeFiltersCount={activeFiltersCount}
            onClick={handleMoveToFilters}
          />
        )}
        {isEditing ? (
          <FilterSetForm.SubmitButton
            isDisabled={
              !isNameValid || activeFiltersCount === 0 || isUpdatingFilterSet
            }
            onClick={handleSubmit}
          >
            {t({ id: "common.save", defaultMessage: "Save" })}
          </FilterSetForm.SubmitButton>
        ) : (
          <FilterSetForm.SubmitButton
            isDisabled={!isNameValid}
            onClick={handleMoveToFilters}
          >
            {t({ id: "common.continue", defaultMessage: "Continue" })}
          </FilterSetForm.SubmitButton>
        )}
      </FilterSetForm>
    </BottomCenteredDrawer.Content>
  );
};
