import { useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import { FieldComponentProps } from "../../types";
import { TaskPropertyToggleButton } from "../TaskPropertyToggleButton";

type CompleteChecklistInOrderFieldProps =
  FieldComponentProps<"completeChecklistInOrder">;

export const CompleteChecklistInOrderField: FC<
  CompleteChecklistInOrderFieldProps
> = ({ isChecked, isHidden, onChange }) => {
  const { t } = useTranslations();

  if (isHidden) {
    return null;
  }

  return (
    <TaskPropertyToggleButton
      label={t({
        id: "tasks-page.complete-checklist-in-order",
        defaultMessage: "Complete checklist in order",
      })}
      isChecked={isChecked}
      onChange={onChange}
    />
  );
};
