import { cx } from "@jugl-web/utils";
import { forwardRef, DetailedHTMLProps, InputHTMLAttributes } from "react";

type NativeRadioProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

type CheckboxSize = "sm" | "md" | "lg";

export interface RadioProps
  extends Omit<NativeRadioProps, "size" | "type" | "checked" | "disabled"> {
  size?: CheckboxSize;
  isChecked?: boolean;
  isDisabled?: boolean;
}

const sizeToClasses: Record<CheckboxSize, string> = {
  sm: "w-[14px] h-[14px]",
  md: "w-4 h-4",
  lg: "w-5 h-5",
};

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ size = "md", isChecked, isDisabled, className, ...props }, ref) => (
    <input
      ref={ref}
      type="radio"
      checked={isChecked}
      disabled={isDisabled}
      className={cx(
        "form-radio m-0 cursor-pointer",
        "focus-visible:outline-primary-300 focus:ring-0 focus:ring-transparent focus:ring-offset-transparent focus-visible:outline focus-visible:outline-2",
        // TODO: Style checked state according to design
        "checked:bg-primary checked:hover:bg-primary checked:focus:bg-primary",
        "disabled:checked:bg-grey-300 disabled:checked:hover:bg-grey-300 disabled:checked:border-grey-300 disabled:cursor-not-allowed disabled:border-[#F2F2F2]",
        "border-grey-500 border-1 border-solid",
        sizeToClasses[size],
        className
      )}
      {...props}
    />
  )
);
