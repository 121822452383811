import React, { useState } from "react";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { Button } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import useConversations from "@web-src/features/chats/hooks/useConversations";
import { SidebarDrawer } from "@jugl-web/ui-components/web";
import WorkspaceForm from "../WorkspaceForm";

export const UpdateWorkspaceSideBar: React.FC<{
  isOpen: boolean;
  onRequestClose: () => void;
  workspaceId: string;
  title?: string;
  img?: string;
}> = ({ isOpen, onRequestClose, workspaceId, title, img }) => {
  const { entity } = useEntity();

  const [formValue, setFormValue] = useState<{
    title: string;
    avatar?: File;
    savedAvatar?: string;
  }>({ title: title || "", savedAvatar: img });
  const { t } = useTranslations();

  const { updateGroupConversation } = useConversations();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (!entity?.id || !formValue?.title) {
      return;
    }
    try {
      setIsLoading(true);
      await updateGroupConversation({
        id: workspaceId,
        title: formValue?.title,
        img: formValue?.avatar,
      });
      onRequestClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <SidebarDrawer
      onClose={onRequestClose}
      isOpen={isOpen}
      title={t({
        id: "chats-page.update.group",
        defaultMessage: "Update group",
      })}
    >
      <SidebarDrawer.Content>
        <WorkspaceForm
          onChange={setFormValue}
          defaultImg={img}
          defaultTitle={title}
        />
      </SidebarDrawer.Content>
      <SidebarDrawer.Actions>
        <Button
          onClick={handleSubmit}
          variant="contained"
          fullWidth
          isDisabled={!formValue?.title || isLoading}
        >
          {t({
            id: "common.save",
            defaultMessage: "Save",
          })}
        </Button>
      </SidebarDrawer.Actions>
    </SidebarDrawer>
  );
};
