import { TaskLabel } from "@jugl-web/rest-api/tasks";
import { PlainButton, Popover } from "@jugl-web/ui-components/cross-platform";
import { cx, useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import { TASK_LABEL_COLORS } from "../../consts";
import { taskLabelValidator } from "../../validators";
import { CustomColorBox } from "./CustomColorBox";
import {
  GenericFieldListItem,
  GenericFieldListItemProps,
} from "./GenericFieldListItem";

export interface TaskLabelListItemProps
  extends Omit<
    GenericFieldListItemProps<TaskLabel>,
    "item" | "placeholder" | "validator" | "renderStartSlot"
  > {
  label: TaskLabel;
}

export const TaskLabelListItem: FC<TaskLabelListItemProps> = ({
  label,
  onUpdate,
  ...props
}) => {
  const { t } = useTranslations();

  return (
    <GenericFieldListItem
      item={label}
      placeholder={t({
        id: "form-controls.task-label.placeholder",
        defaultMessage: "Enter label name",
      })}
      validator={taskLabelValidator}
      onUpdate={onUpdate}
      renderStartSlot={() => (
        <Popover
          placement="bottom-start"
          className="flex gap-3 px-8 py-4"
          renderTrigger={({ Trigger, triggerRef }) => (
            <Trigger as={PlainButton} ref={triggerRef}>
              <CustomColorBox hexColor={label.color} className="mr-4" />
            </Trigger>
          )}
        >
          {({ onClose }) =>
            TASK_LABEL_COLORS.map((color) => (
              <PlainButton
                key={color}
                disabled={color === label.color}
                onClick={() => {
                  onUpdate({ ...label, color });
                  onClose();
                }}
              >
                <CustomColorBox
                  key={color}
                  size="lg"
                  variant="plain"
                  hexColor={color}
                  className={cx(
                    label.color === color
                      ? "border-2 border-solid border-white outline outline-2 outline-[#38393A]"
                      : "transition hover:scale-110 hover:contrast-200"
                  )}
                />
              </PlainButton>
            ))
          }
        </Popover>
      )}
      {...props}
    />
  );
};
