import React, { useState, useMemo } from "react";
import { SidebarDrawer } from "@jugl-web/ui-components/web";
import { cx, useTranslations } from "@jugl-web/utils";
import {
  Button,
  InteractiveContainer,
} from "@jugl-web/ui-components/cross-platform";
import { Radio } from "@jugl-web/ui-components/cross-platform/Radio";
import {
  getLanguagesByNames,
  languageToName,
  supportedLanguages,
  useLanguage,
} from "@jugl-web/utils/i18n/EnhancedIntlProvider";

export const LanguagePickerDrawer: React.FC<{
  isOpen: boolean;
  title: string;
  onClose: () => void;
}> = ({ isOpen, title, onClose }) => {
  const { language, changeLanguage } = useLanguage();
  const [choosenLanguage, setChoosenLanguage] = useState(language);
  const { t } = useTranslations();
  const languageToTranslatedName = useMemo(() => getLanguagesByNames(t), [t]);

  return (
    <SidebarDrawer
      isOpen={isOpen}
      hasBackdrop
      title={title}
      onClose={() => {
        setChoosenLanguage(language);
        onClose();
      }}
    >
      <SidebarDrawer.Content className="overflow-y-auto p-10">
        {supportedLanguages.map((languageItem, idx) => (
          <React.Fragment key={languageItem}>
            <InteractiveContainer
              onClick={() => setChoosenLanguage(languageItem)}
              className={cx(
                "my-2 flex h-[56px] w-full items-center gap-5 p-3",
                {
                  "bg-grey-100": languageItem === choosenLanguage,
                }
              )}
            >
              <Radio isChecked={languageItem === choosenLanguage} readOnly />
              <div className="flex flex-col gap-1">
                <span>{languageToName[languageItem]}</span>
                <span className="text-xs text-[#828282]">
                  {languageToTranslatedName[languageItem]}
                </span>
              </div>
            </InteractiveContainer>
            {idx !== supportedLanguages.length - 1 && (
              <div className="bg-grey-200 h-px w-full" />
            )}
          </React.Fragment>
        ))}
      </SidebarDrawer.Content>
      <SidebarDrawer.Actions>
        <Button
          fullWidth
          uppercase
          isDisabled={language === choosenLanguage}
          onClick={() => {
            changeLanguage(choosenLanguage);
            onClose();
          }}
        >
          {t({ id: "common.save", defaultMessage: "Save" })}
        </Button>
      </SidebarDrawer.Actions>
    </SidebarDrawer>
  );
};
