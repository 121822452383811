import {
  InteractiveContainer,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { cx, joinReactNodes } from "@jugl-web/utils";
import { ConditionallyRenderedPortal } from "@jugl-web/utils/utils/ConditionallyRenderedPortal";
import { FC, ReactNode, useMemo } from "react";
import { Draggable } from "react-beautiful-dnd";
import { ReactComponent as ChevronRightIcon } from "./icons/chevron-right.svg";
import { ReactComponent as ReorderIcon } from "./icons/reorder.svg";

export interface TaskFieldBoxProps {
  title: string;
  subtitle: {
    icon: ReactNode;
    label: string;
    valuesCount?: number;
    defaultValuesCount?: number;
  };
  isDefault?: boolean;
  customEndSlot?: ReactNode;
  onClick: () => void;
}

export const TaskFieldBox: FC<TaskFieldBoxProps> = ({
  title,
  subtitle,
  customEndSlot,
  isDefault = false,
  onClick,
}) => {
  const subtitleParts = useMemo<ReactNode[]>(() => {
    const parts = [
      <div className="flex items-center gap-1.5">
        {subtitle.icon}
        <span className={cx(isDefault && "text-primary-800 font-medium")}>
          {subtitle.label}
        </span>
      </div>,
    ];

    if (typeof subtitle.defaultValuesCount === "number") {
      parts.push(
        <span className="text-xs text-[#828282]">
          {subtitle.defaultValuesCount} default values
        </span>
      );
    }

    if (typeof subtitle.valuesCount === "number") {
      parts.push(
        <span className="text-xs text-[#828282]">
          {subtitle.valuesCount} {isDefault ? "custom values" : "values"}
        </span>
      );
    }

    return parts;
  }, [subtitle, isDefault]);

  return (
    <InteractiveContainer
      className="bg-grey-100 flex items-center justify-between rounded-xl px-8 py-6 transition-colors hover:bg-[#F6F6F7]"
      onClick={onClick}
    >
      <div className="flex flex-col gap-1.5">
        <span className="text-dark text-base font-medium">{title}</span>
        <div className="flex items-center gap-1.5 text-xs text-[#828282]">
          {joinReactNodes(
            subtitleParts,
            <div className="h-4 w-px bg-[#E0E0E0]" />
          )}
        </div>
      </div>
      {customEndSlot || (
        <PlainButton className="hover:bg-grey-200 flex h-8 w-8 items-center justify-center rounded-full transition-colors">
          <ChevronRightIcon />
        </PlainButton>
      )}
    </InteractiveContainer>
  );
};

export interface DraggableTaskFieldBoxProps extends TaskFieldBoxProps {
  id: string;
  index: number;
}

export const DraggableTaskFieldBox: FC<DraggableTaskFieldBoxProps> = ({
  id,
  index,
  ...props
}) => (
  <Draggable draggableId={id} index={index}>
    {(provided, snapshot) => (
      <ConditionallyRenderedPortal isEnabled={snapshot.isDragging}>
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="relative mb-6"
        >
          <PlainButton
            className="absolute -left-0.5 top-1/2 -translate-x-full -translate-y-1/2 cursor-grab"
            onClick={(event) => event.stopPropagation()}
          >
            <ReorderIcon />
          </PlainButton>
          <TaskFieldBox {...props} />
        </div>
      </ConditionallyRenderedPortal>
    )}
  </Draggable>
);
