import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import React, { useState } from "react";
import { SidebarDrawer } from "@jugl-web/ui-components/web";
import {
  getTimezonesForCountry,
  Timezone,
  Country,
} from "countries-and-timezones";
import { Radio } from "@jugl-web/ui-components/cross-platform/Radio";
import { ReactComponent as ChevronRightIcon } from "../../assets/chevron-right.svg";

export const TimeZoneDialogPicker: React.FC<{
  timeZone: Timezone;
  setTimeZone: (zone: Timezone) => void;
  region: Country;
}> = ({ region, timeZone, setTimeZone }) => {
  const { t } = useTranslations();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <InteractiveContainer
        className="bg-grey-200 flex items-center justify-between rounded-lg p-4"
        onClick={() => setIsOpen(true)}
      >
        <div className="flex flex-col gap-4">
          <p className="m-0 text-sm text-[#828282]">
            {t({
              id: "settings-page.time-zone",
              defaultMessage: "Time Zone",
            })}
          </p>
          <p className="text-dark m-0">{`${timeZone.name} (UTC ${timeZone.utcOffsetStr})`}</p>
        </div>
        <ChevronRightIcon />
      </InteractiveContainer>
      <SidebarDrawer
        isOpen={isOpen}
        hasBackdrop={false}
        onClose={() => setIsOpen(false)}
        title={t(
          {
            id: "settings-page.time-zone-with-region",
            defaultMessage: "Time Zone: {region}",
          },
          {
            region: region.name,
          }
        )}
        onGoBack={() => setIsOpen(false)}
      >
        <SidebarDrawer.Content className="overflow-y-auto p-10">
          <div>
            {getTimezonesForCountry(region.id)?.map((zoneObj, idx) => (
              <React.Fragment key={zoneObj.name}>
                <InteractiveContainer
                  className="flex items-center gap-3"
                  onClick={() => setTimeZone(zoneObj)}
                >
                  <Radio isChecked={timeZone.name === zoneObj.name} readOnly />
                  <div className="flex flex-col gap-1">
                    <p className="text-dark m-0">{zoneObj.name}</p>
                    <p className="m-0 text-xs text-[#828282]">{`(UTC ${zoneObj.utcOffsetStr})`}</p>
                  </div>
                </InteractiveContainer>
                {idx !== getTimezonesForCountry(region.id).length - 1 && (
                  <div className="bg-grey-200 my-3 h-px w-full" />
                )}
              </React.Fragment>
            ))}
          </div>
        </SidebarDrawer.Content>
      </SidebarDrawer>
    </>
  );
};
