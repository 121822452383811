import React from "react";
import { PromoBackground } from "@jugl-web/ui-components/web/PromoBackground";
import { PromoText } from "@jugl-web/ui-components/web/PromoText";
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from "@jugl-web/utils/consts";
import { AuthStep } from "./types";
import LoginForm from "./components/LoginForm";
import OtpVerification from "./components/OtpVerification";
import { LoginPageProvider, useLoginPage } from "./providers/LoginPageProvider";

const LoginPageContent: React.FC = () => {
  const { authStep } = useLoginPage();

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex overflow-y-auto">
      <div className="fixed h-full w-full">
        <PromoBackground />
      </div>
      <div className="m-auto py-5">
        <div className="flex flex-col items-center">
          <PromoText size="md" />
          <div className="relative mt-10 flex h-[625px] w-[512px] flex-col overflow-y-auto rounded-3xl bg-white p-14">
            {authStep === AuthStep.login ? <LoginForm /> : <OtpVerification />}
          </div>
          <div className="z-10 mt-5 w-[300px] text-center text-sm leading-[160%] text-white">
            You are agreeing to our{" "}
            <a
              className="text-primary-600"
              href={TERMS_OF_USE_URL}
              target="_blank"
              rel="noreferrer"
            >
              Terms and Conditions
            </a>{" "}
            and{" "}
            <a
              className="text-primary-600"
              href={PRIVACY_POLICY_URL}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const LoginPage: React.FC = () => (
  <LoginPageProvider>
    <LoginPageContent />
  </LoginPageProvider>
);

export default LoginPage;
