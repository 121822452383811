import { useContext } from "react";
import { HookOutOfContextError } from "../errors";
import { AppVariantContext } from "../providers/AppVariantProvider";

export const useAppVariant = () => {
  const variant = useContext(AppVariantContext);

  if (!variant) {
    throw new HookOutOfContextError("useAppVariant", "AppVariantContext");
  }

  return {
    variant,
    isMobile: variant === "mobile",
    isWeb: variant === "web",
  };
};
