import React, { memo, useCallback, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { ChatMessagePayloadAttachmentType } from "@web-src/features/chats/types";
import AudioPlayer from "@web-src/components/AudioPlayer";
import { useDownloadManagerProvider } from "@jugl-web/domain-resources/files/providers/DownloadManagerProvider";
import { MessageFileAttachment } from "@jugl-web/ui-components/cross-platform/attachments/MessageFileAttachment";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { useDeprecatedFilePreview } from "@web-src/features/app/providers/DeprecatedFilePreviewProvider";
import { ImageWithFallback } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import previewVideo from "./images/preview-video.png";
import { useChatMessageProvider } from "../../providers/ChatMessageProvider";

const MAX_SIZE = 300;
const getDimensions = (
  width?: number,
  height?: number
): { width: number; height: number } | null => {
  if (!width || !height) {
    return null;
  }
  let reduceBy = 1;
  if (width >= height && width > MAX_SIZE) {
    reduceBy = MAX_SIZE / width;
  } else if (width <= height && height > MAX_SIZE) {
    reduceBy = MAX_SIZE / height;
  }
  return {
    width: width * reduceBy,
    height: height * reduceBy,
  };
};

const ChatMessageAttachment: React.FC<{
  reply?: boolean;
}> = memo(({ reply }) => {
  const { message, downloadAttachmentInProgress, cancelAttachmentDownload } =
    useChatMessageProvider();
  const attachment = reply
    ? message?.payload?.reply_attributes_map?.reply_attachments?.[0]
    : message?.payload?.attachments?.[0];
  const { t } = useTranslations();

  const { entity } = useEntity();
  const { files, download, open } = useDownloadManagerProvider();
  const downloadManagerFile = useMemo(
    () => (attachment ? files[attachment?.uid] : null),
    [attachment, files]
  );
  const handleDownloadClick = useCallback(() => {
    if (!entity || !attachment) {
      return;
    }
    download(attachment.uid, {
      entityId: entity.id,
      fileSize: attachment.size,
      mimeType: attachment.mimetype,
      module: "attachments",
      fileName: attachment.name || "jugl-file",
      // TODO: hack
      path: attachment.url?.includes("amazonaws.com")
        ? attachment.uid
        : `${entity.id}/${attachment.uid}`,
    });
  }, [attachment, download, entity]);

  const dimensions = useMemo(
    () =>
      getDimensions(
        typeof attachment?.width === "string"
          ? parseInt(attachment?.width, 10)
          : attachment?.width,
        typeof attachment?.height === "string"
          ? parseInt(attachment?.height, 10)
          : attachment?.height
      ),
    [attachment]
  );
  const meId = useSelector(selectUserId);
  const isOutgoing = useMemo(() => message?.from === meId, [message, meId]);

  const imgSrc = useMemo(() => {
    if (attachment?.type === ChatMessagePayloadAttachmentType.gifDirectUrl) {
      return attachment.url || "";
    }
    if (attachment?.type === ChatMessagePayloadAttachmentType.gif) {
      return attachment?._local_url || attachment?._stream_url;
    }
    return (
      attachment?._local_url ||
      attachment?._preview_url ||
      attachment?._stream_url
    );
  }, [attachment]);

  const { openFile } = useDeprecatedFilePreview();

  useEffect(() => {
    if (
      (attachment?.type === ChatMessagePayloadAttachmentType.audio ||
        attachment?.type === ChatMessagePayloadAttachmentType.voice) &&
      attachment?._progress === undefined &&
      !files[attachment?.uid]
    ) {
      handleDownloadClick();
    }
  }, [
    attachment?.type,
    attachment?.uid,
    attachment?._progress,
    handleDownloadClick,
    downloadManagerFile,
    files,
  ]);

  const $content = useMemo(() => {
    const attachmentName = attachment?.name;
    if (
      attachment?.type === ChatMessagePayloadAttachmentType.image ||
      attachment?.type === ChatMessagePayloadAttachmentType.gif ||
      attachment?.type === ChatMessagePayloadAttachmentType.gifDirectUrl
    ) {
      return (
        <>
          <div
            className="relative flex cursor-pointer items-center justify-center overflow-hidden"
            onClick={() => {
              const fileURL =
                attachment?.type ===
                ChatMessagePayloadAttachmentType.gifDirectUrl
                  ? attachment.url || ""
                  : attachment._stream_url;
              if (fileURL) {
                openFile(fileURL);
              }
            }}
            style={{
              maxWidth: `${MAX_SIZE}px`,
              maxHeight: `${MAX_SIZE}px`,
              width: dimensions?.width ? `${dimensions.width}px` : undefined,
              height: dimensions?.height ? `${dimensions.height}px` : undefined,
            }}
          >
            <ImageWithFallback
              src={imgSrc}
              style={{
                width: dimensions?.width ? `${dimensions.width}px` : undefined,
                height: dimensions?.height
                  ? `${dimensions.height}px`
                  : undefined,
                maxHeight: "100%",
                maxWidth: "100%",
              }}
              alt={attachmentName}
              className="z-10"
            />
          </div>
        </>
      );
    }
    if (
      (attachment?.type === ChatMessagePayloadAttachmentType.audio ||
        attachment?.type === ChatMessagePayloadAttachmentType.voice) &&
      downloadManagerFile?.localUrl
    ) {
      return (
        <AudioPlayer
          bgColor={!isOutgoing ? "juglPrimary.50" : undefined}
          url={downloadManagerFile.localUrl}
        />
      );
    }
    if (attachment?.type === ChatMessagePayloadAttachmentType.video) {
      return (
        <div
          style={{
            width: dimensions?.width ? `${dimensions.width}px` : undefined,
            height: dimensions?.height ? `${dimensions.height}px` : undefined,
          }}
        >
          <video
            className="cursor-pointer"
            style={{
              maxWidth: `${MAX_SIZE}px`,
              maxHeight: `${MAX_SIZE}px`,
              width: dimensions?.width ? `${dimensions.width}px` : undefined,
              height: dimensions?.height ? `${dimensions.height}px` : undefined,
            }}
            poster={attachment._preview_url || previewVideo}
            controls
          >
            <source src={attachment._local_url || attachment._stream_url} />
          </video>
        </div>
      );
    }
    if (!attachment) {
      return null;
    }
    return (
      <MessageFileAttachment
        name={
          attachmentName ||
          t({
            id: "chats-page.unknown-file",
            defaultMessage: "Unknown file",
          })
        }
        mimeType={attachment.mimetype}
        progress={
          downloadManagerFile?.status === "completed"
            ? undefined
            : downloadManagerFile?.progress
        }
        onDownloadClick={
          attachment._progress !== undefined || downloadManagerFile
            ? undefined
            : handleDownloadClick
        }
        onCancelClick={
          downloadAttachmentInProgress ? cancelAttachmentDownload : undefined
        }
        previewImg={message._pending ? undefined : attachment._preview_url}
        onOpenClick={
          downloadManagerFile?.status === "completed"
            ? () => open(downloadManagerFile.id)
            : undefined
        }
      />
    );
  }, [
    t,
    attachment,
    downloadManagerFile,
    handleDownloadClick,
    downloadAttachmentInProgress,
    cancelAttachmentDownload,
    message._pending,
    dimensions?.width,
    dimensions?.height,
    imgSrc,
    openFile,
    isOutgoing,
    open,
  ]);
  return <Box>{$content}</Box>;
});

export default ChatMessageAttachment;
