import { useState } from "react";
import { useResizeObserver } from "./useResizeObserver";

export const useIsScrollable = () => {
  const [isScrollable, setIsScrollable] = useState(false);

  const { ref } = useResizeObserver({
    onResize: (entry) => {
      setIsScrollable(entry.target.scrollHeight > entry.target.clientHeight);
    },
  });

  return { ref, isScrollable };
};
