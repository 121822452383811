import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { COMMAND_PRIORITY_LOW, KEY_ENTER_COMMAND } from "lexical";
import { FC, useEffect } from "react";

export interface OnEnterPluginProps {
  onEnter?: (event: KeyboardEvent) => void;
}

export const OnEnterPlugin: FC<OnEnterPluginProps> = ({ onEnter }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!onEnter) {
      return undefined;
    }

    const unsubscribe = editor.registerCommand(
      KEY_ENTER_COMMAND,
      (event) => {
        if (event !== null) {
          // Hack: checks if mentions menu was not open while pressing Enter
          // as LexicalTypeaheadMenuPlugin uses the same command priority and
          // they conflict with each other
          const isMentionsMenuOpen = !!(event.target as HTMLElement)
            .getAttribute("aria-activedescendant")
            ?.startsWith("typeahead-item");
          if (isMentionsMenuOpen) {
            return false;
          }

          if (!event.shiftKey) {
            event.preventDefault();
            onEnter(event);
            return true;
          }
        }

        return false;
      },
      COMMAND_PRIORITY_LOW
    );

    return unsubscribe;
  }, [editor, onEnter]);

  return null;
};
