export interface TimeComponents {
  hours: number;
  minutes: number;
  seconds: number;
  milliseconds: number;
}

const MILLISECONDS_PER_HOUR = 60 * 60 * 1000;
const MILLISECONDS_PER_MINUTE = 60 * 1000;
const MILLISECONDS_PER_SECOND = 1000;

export const convertMillisecondsToTimeComponents = (
  duration: number
): TimeComponents => {
  const hours = Math.trunc(duration / MILLISECONDS_PER_HOUR);
  duration %= MILLISECONDS_PER_HOUR;

  const minutes = Math.trunc(duration / MILLISECONDS_PER_MINUTE);
  duration %= MILLISECONDS_PER_MINUTE;

  const seconds = Math.trunc(duration / MILLISECONDS_PER_SECOND);
  duration %= MILLISECONDS_PER_SECOND;

  const milliseconds = duration;

  return {
    hours,
    minutes,
    seconds,
    milliseconds,
  };
};

export const convertTimeComponentsToMilliseconds = (
  timeComponents: TimeComponents
): number =>
  timeComponents.hours * MILLISECONDS_PER_HOUR +
  timeComponents.minutes * MILLISECONDS_PER_MINUTE +
  timeComponents.seconds * MILLISECONDS_PER_SECOND +
  timeComponents.milliseconds;
