import {
  createContext,
  useState,
  useContext,
  useMemo,
  useCallback,
} from "react";
import es from "date-fns/locale/es";
import { IntlProvider, IntlFormatters } from "react-intl";
import { getStorageItemWithFallback, saveItemToStorage } from "../storage";
import { HookOutOfContextError } from "..";
import { LANG_KEY } from "../consts";
import englishMessages from "./langs/en.json";
import spanishMessages from "./langs/es.json";

export type SupportedLanguage = "en" | "es";

interface EnhancedIntlProviderProps {
  children: React.ReactNode;
  saveLangToLocalStorage?: boolean;
}

type Messages = Record<string, string>;

const languageToMessages: Record<SupportedLanguage, Messages> = {
  en: englishMessages,
  es: spanishMessages,
};

const languageToDateLocale: Partial<Record<SupportedLanguage, Locale>> = {
  es,
};

const fallbackLanguage = "en";

export const LanguageContext = createContext<{
  changeLanguage: (newLanguage: SupportedLanguage) => void;
  language: SupportedLanguage;
  dateLocale?: Locale;
} | null>(null);

const extractLanguageFromLocale = (locale: string) => locale.split(/[-_]/)[0];
const getNavigatorLanguage = () =>
  extractLanguageFromLocale(navigator.language);
export const isSupportedLanguage = (
  language: string
): language is SupportedLanguage =>
  Object.keys(languageToMessages).includes(language);
const getInitialLanguage = (loadStorage: boolean): SupportedLanguage => {
  if (!loadStorage) {
    return fallbackLanguage;
  }
  const cachedLanguage = getStorageItemWithFallback<
    SupportedLanguage | undefined
  >(LANG_KEY, undefined);

  if (cachedLanguage && isSupportedLanguage(cachedLanguage)) {
    return cachedLanguage;
  }

  const browserLanguage = getNavigatorLanguage();

  if (isSupportedLanguage(browserLanguage)) {
    return browserLanguage;
  }

  return fallbackLanguage;
};

export const languageToName: Record<SupportedLanguage, string> = {
  en: "English",
  es: "Spanish",
};

export const getLanguagesByNames = (
  t: IntlFormatters["formatMessage"]
): Record<SupportedLanguage, string> => ({
  en: t({ id: "language.english", defaultMessage: "English" }),
  es: t({ id: "language.spanish", defaultMessage: "Spanish" }),
});

export const supportedLanguages = Object.keys(
  languageToMessages
) as SupportedLanguage[];

export const EnhancedIntlProvider = ({
  children,
  saveLangToLocalStorage,
}: EnhancedIntlProviderProps) => {
  const [language, setLanguage] = useState(
    getInitialLanguage(!!saveLangToLocalStorage)
  );

  const messages = useMemo(() => {
    if (isSupportedLanguage(language)) {
      return languageToMessages[language];
    }

    return englishMessages;
  }, [language]);

  const dateLocale = useMemo(
    () => languageToDateLocale[language as SupportedLanguage],
    [language]
  );

  const changeLanguage = useCallback(
    (newLanguage: SupportedLanguage) => {
      if (isSupportedLanguage(newLanguage)) {
        setLanguage(newLanguage);
        if (saveLangToLocalStorage) {
          saveItemToStorage(LANG_KEY, newLanguage);
        }
      }
    },
    [saveLangToLocalStorage]
  );

  const contextValue = useMemo(
    () => ({
      language,
      changeLanguage,
      dateLocale,
    }),
    [language, changeLanguage, dateLocale]
  );

  return (
    <LanguageContext.Provider value={contextValue}>
      <IntlProvider messages={messages} locale={language}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);

  if (!context) {
    throw new HookOutOfContextError("useLanguage", "LanguageContext");
  }

  return context;
};
