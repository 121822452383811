import { assert, cx, useTranslations } from "@jugl-web/utils";
import { DPYear, useDatePickerContext } from "@rehookify/datepicker";
import { FC, MouseEvent, useEffect, useMemo, useRef, useState } from "react";
import { Button } from "../../../Button";
import { PickerBaseProps } from "../../types";

interface YearPickerProps extends PickerBaseProps {
  hasNavigation?: boolean;
}

const MIN_YEAR = 1930;
const MAX_YEARS_FROM_CURRENT_YEAR = 28;

export const YearPicker: FC<YearPickerProps> = ({
  hasNavigation,
  onViewChange,
}) => {
  const {
    data: { years, calendars },
    propGetters: { yearButton },
  } = useDatePickerContext();

  const $selectedYearBtn = useRef<HTMLButtonElement>(null);

  const { t } = useTranslations();

  const { year } = calendars[0];

  const [internalYear, setInternalYear] = useState<DPYear | undefined>(() => {
    const matchingYear = years.find(
      (dpYear) => dpYear.year.toString() === year
    );

    if (matchingYear && !matchingYear.disabled) {
      return matchingYear;
    }

    const firstAvailableYear = years.find((dpYear) => !dpYear.disabled);

    return firstAvailableYear;
  });

  const currentYear = new Date().getFullYear();

  const filteredYears = useMemo(
    () =>
      years.filter(
        (dpYear) =>
          dpYear.year - MAX_YEARS_FROM_CURRENT_YEAR <= currentYear &&
          dpYear.year >= MIN_YEAR
      ),
    [currentYear, years]
  );

  const handleYearSelect = (
    event: MouseEvent<HTMLButtonElement>,
    dpYear: DPYear
  ) => {
    if (dpYear.disabled) {
      return;
    }

    if (!hasNavigation) {
      yearButton(dpYear).onClick?.(event);
      onViewChange("dayPicker");
    } else {
      setInternalYear(dpYear);
    }
  };

  const handleSubmit = (event: MouseEvent<HTMLButtonElement>) => {
    assert(!!internalYear, "Month must be selected");
    yearButton(internalYear).onClick?.(event);
    onViewChange("dayPicker");
  };

  useEffect(() => {
    if ($selectedYearBtn.current) {
      $selectedYearBtn.current.scrollIntoView({
        block: "center",
        behavior: "instant",
      });
    }
  }, []);

  return (
    <>
      <div className="bg-grey-100 flex h-[42px] items-center justify-between rounded-lg px-6">
        <span className="text-grey-800 text-[13px] font-medium">
          {t({
            id: "date-picker-component.select-year",
            defaultMessage: "Select Year",
          })}
        </span>
        <span className="text-dark-800 text-[18px] font-bold">
          {internalYear?.year}
        </span>
      </div>
      <div className="mt-6 grid max-h-[304px] grid-cols-4 gap-1 overflow-auto">
        {filteredYears.map((dpYear) => {
          const isSelected = internalYear?.year === dpYear.year;

          return (
            <button
              key={dpYear.year}
              ref={isSelected ? $selectedYearBtn : undefined}
              type="button"
              className={cx(
                "h-10 cursor-pointer rounded-[32px] border-none font-[Roboto] text-lg transition-colors",
                "hover:bg-grey-100 bg-transparent font-normal text-[#19181A]",
                dpYear.now &&
                  "bg-primary-50 text-primary hover:bg-primary-50 font-semibold",
                dpYear.disabled &&
                  "text-grey-500 cursor-not-allowed bg-transparent font-normal hover:bg-transparent",
                isSelected &&
                  "bg-primary hover:bg-primary font-semibold text-white"
              )}
              onClick={(event) => handleYearSelect(event, dpYear)}
            >
              {dpYear.year}
            </button>
          );
        })}
      </div>
      {hasNavigation && (
        <div className="mt-8 flex gap-4">
          <Button
            color="grey"
            fullWidth
            onClick={() => onViewChange("dayPicker")}
          >
            {t({ id: "common.cancel", defaultMessage: "Cancel" })}
          </Button>
          <Button
            fullWidth
            isDisabled={internalYear === undefined}
            onClick={handleSubmit}
          >
            {t({ id: "common.select", defaultMessage: "Select" })}
          </Button>
        </div>
      )}
    </>
  );
};
