import { AvatarSelect, AvatarSelectProps } from "@jugl-web/ui-components/web";
import {
  Menu,
  MenuItem,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { ReactNode } from "react";
import { ReactComponent as OptionsVerticalIcon } from "./assets/options-vertical.svg";

export type ResourceMainSettingsItemProps = {
  avatarSelectProps: AvatarSelectProps;
  title: ReactNode;
  subTitle: string;
  menuItems: MenuItem[];
};

export const ResourceMainSettingsItem: React.FC<
  ResourceMainSettingsItemProps
> = ({ avatarSelectProps, title, subTitle, menuItems }) => (
  <div className="flex items-center justify-between gap-5 rounded-xl bg-[#F7F8F9] p-6">
    <div className="flex items-center gap-14 truncate">
      <AvatarSelect {...avatarSelectProps} />
      <div className="flex flex-col gap-2 truncate">
        <span className="font-secondary text-[26px] font-medium leading-[39px]">
          {title}
        </span>
        <span className="text-dark-700 text-sm leading-[21px]">{subTitle}</span>
      </div>
    </div>
    <Menu
      placement="bottom-start"
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          as={PlainButton}
          ref={triggerRef}
          className="flex h-12 w-12 shrink-0 items-center justify-center rounded-[10px] transition-colors hover:bg-[#EEF2F5]"
        >
          <OptionsVerticalIcon />
        </Trigger>
      )}
      onSelect={(el, event, close) => close()}
      className="py-2"
      sections={[menuItems]}
    />
  </div>
);
