import { useRestApiProvider } from "@jugl-web/rest-api";
import { getUniqueId } from "@jugl-web/utils";
import mime from "mime";
import { useCallback } from "react";
import * as tus from "tus-js-client";
import { useAuthTokenProvider } from "../../auth/providers/AuthTokenProvider";
import { FilesModule } from "../types";

const DEFAULT_TYPE = "application/octet-stream";

export interface ExtraMetaData {
  parent_dir_id?: string;
  type?: "file";
  sub_module?: "task" | "cpanel";
  task_id?: string;
  user_id?: string;
}

const TUS_PARALLEL_UPLOADS = 1;
const TUS_CHUNK_SIZE_IN_BYTES = 5 * 1024 * 1024; // 5MB

export const useTusUpload = ({
  module,
  entityId,
}: {
  module: FilesModule;
  entityId?: string;
}) => {
  const { getToken } = useAuthTokenProvider();
  const { apiBaseUrl } = useRestApiProvider();

  const uploadFile = useCallback(
    async ({
      file,
      extraMetaData,
      addPath,
      onProgress,
    }: {
      file: File;
      extraMetaData?: ExtraMetaData;
      addPath?: boolean;
      onProgress?: (bytesSend: number, bytesTotal: number) => void;
    }) =>
      new Promise<{
        stream_url: string;
        url: string;
        preview_url: string;
        uid: string;
      }>((resolve, reject) => {
        if (!entityId) {
          throw new Error("Entity ID is required");
        }
        const fileContentType = mime.getType(file.name) || DEFAULT_TYPE;
        const fileExtension =
          file.name.indexOf(".") > -1
            ? file.name.split(".").pop()
            : mime.getExtension(fileContentType);

        const fileUid = `${getUniqueId()}`;
        const name = `${fileUid}.${fileExtension}`;
        const path = `${entityId}/${name}`;
        const upload = new tus.Upload(file, {
          endpoint: `${apiBaseUrl}/api/auth/files/${entityId}`,
          retryDelays: [0, 3000, 5000, 10000, 20000],
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
          parallelUploads: TUS_PARALLEL_UPLOADS,
          metadata: {
            name: file.name,
            content_type: mime.getType(file.name) || DEFAULT_TYPE,
            module,
            ...(addPath ? { path } : {}),
            ...extraMetaData,
          },
          onError(error) {
            reject(error);
          },
          onSuccess() {
            resolve({
              stream_url: `${apiBaseUrl}/api/auth/attachment/stream?file=${path}`,
              url: `${apiBaseUrl}/api/auth/attachment/download?path=${path}`,
              preview_url: `${apiBaseUrl}/api/auth/attachment/preview?file=${path}`,
              uid: `${fileUid}.${fileExtension}`,
            });
          },
          chunkSize: TUS_CHUNK_SIZE_IN_BYTES,
          onProgress,
          // NOTE: to be changed once we will add resume functionality
          storeFingerprintForResuming: false,
          removeFingerprintOnSuccess: true,
        });
        upload.start();
      }),
    [apiBaseUrl, entityId, getToken, module]
  );

  return {
    uploadFile,
  };
};
