import {
  InteractiveContainer,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { cx, onEnter, onEscape } from "@jugl-web/utils";
import { ReactNode, useCallback, useLayoutEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import { TASK_TEXT_FIELD_CHAR_LIMIT } from "../../consts";
import { ReactComponent as TrashBinIcon } from "./icons/trash-bin.svg";

interface GenericFieldListItemBase {
  id: string;
  text: string;
}

export interface GenericFieldListItemProps<
  TItem extends GenericFieldListItemBase = GenericFieldListItemBase
> {
  item: TItem;
  placeholder: string;
  isEditing: boolean;
  highlightedText?: string;
  className?: string;
  validator: (text: string) => boolean;
  renderStartSlot?: () => ReactNode;
  onStartEditing: () => void;
  onFinishEditing: (item: TItem) => void;
  onUpdate: (item: TItem) => void;
  onRemove: () => void;
}

export const GenericFieldListItem = <TItem extends GenericFieldListItemBase>({
  item,
  placeholder,
  isEditing,
  highlightedText,
  className,
  validator,
  renderStartSlot,
  onStartEditing,
  onFinishEditing,
  onUpdate,
  onRemove,
}: GenericFieldListItemProps<TItem>) => {
  const [internalText, setInternalText] = useState("");

  const isValid = validator(internalText);
  const normalizedInternalText = internalText.trim();

  const handleEditingFinished = useCallback(() => {
    if (!isValid) {
      return;
    }

    const hasTextChanged = normalizedInternalText !== item.text;

    if (hasTextChanged) {
      onUpdate({ ...item, text: normalizedInternalText });
    }
  }, [normalizedInternalText, isValid, item, onUpdate]);

  const handleRequestFinishEditing = () => {
    onFinishEditing({ ...item, text: normalizedInternalText });
  };

  useLayoutEffect(() => {
    if (!isEditing) {
      handleEditingFinished();
    }
  }, [handleEditingFinished, isEditing]);

  useLayoutEffect(() => {
    if (isEditing) {
      setInternalText(item.text);
    }
  }, [isEditing, item]);

  return (
    <InteractiveContainer
      className={cx(
        "flex h-14 !cursor-pointer items-center justify-between rounded-lg px-6 transition",
        isEditing ? "bg-[#F6F6F7]" : "bg-grey-100 hover:bg-[#F6F6F7]",
        className
      )}
      onClick={onStartEditing}
    >
      {renderStartSlot?.()}
      <div className="grow">
        {isEditing ? (
          <div className="relative mr-10">
            <input
              type="text"
              autoFocus
              maxLength={TASK_TEXT_FIELD_CHAR_LIMIT}
              placeholder={placeholder}
              className="placeholder:text-grey-600 text-dark w-full border-b border-l-0 border-r-0 border-t-0 border-b-[#E0E0E0] bg-transparent py-[6.5px] px-0 font-[Roboto] text-base outline-none"
              value={internalText}
              onChange={(event) => setInternalText(event.target.value)}
              onKeyUp={onEnter(() => isValid && handleRequestFinishEditing())}
              onKeyDown={onEscape((event) => {
                event.stopPropagation();
                handleRequestFinishEditing();
              })}
              onBlur={() => handleRequestFinishEditing()}
            />
            <span className="text-grey-600 pointer-events-none absolute right-0 top-1/2 -translate-y-1/2">
              {internalText.length}/{TASK_TEXT_FIELD_CHAR_LIMIT}
            </span>
          </div>
        ) : (
          <span className="text-dark text-base">
            {highlightedText ? (
              <Highlighter
                autoEscape
                highlightClassName="text-primary font-semibold"
                highlightTag="span"
                textToHighlight={item.text}
                searchWords={[highlightedText]}
              />
            ) : (
              item.text
            )}
          </span>
        )}
      </div>
      <PlainButton
        className="hover:bg-grey-200 flex h-8 w-8 shrink-0 items-center justify-center rounded-full transition-colors"
        onClick={(event) => {
          event.stopPropagation();
          onRemove();
        }}
      >
        <TrashBinIcon />
      </PlainButton>
    </InteractiveContainer>
  );
};
