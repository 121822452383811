import React, { ReactElement } from "react";
import { Text } from "@jugl-web/ui-components/cross-platform/Text";
import { cx, convertSnakeCaseToReadableString } from "@jugl-web/utils";
import { ReactComponent as TextIcon } from "./assets/text.svg";
import { ReactComponent as EmailIcon } from "./assets/email.svg";
import { ReactComponent as PhoneIcon } from "./assets/phone.svg";
import { ReactComponent as RemoveIcon } from "./assets/remove.svg";
import { ReactComponent as ImageIcon } from "./assets/image.svg";
import { ReactComponent as WebIcon } from "./assets/web.svg";
import { ReactComponent as DropdownIcon } from "./assets/dropdown.svg";

const fieldIcon: { [key: string]: ReactElement } = {
  text: <TextIcon />,
  email: <EmailIcon />,
  mobile: <PhoneIcon />,
  image: <ImageIcon />,
  url: <WebIcon />,
  dropdown: <DropdownIcon />,
};
const FieldChip: React.FC<{
  type: "text" | "number" | "email" | "image" | string;
  name: string;
  onRemove?: () => void;
  isAddedToDelete?: boolean;
}> = ({ type, name, onRemove, isAddedToDelete }) => (
  <div className="flex h-12 w-full flex-row items-center justify-between gap-2">
    <div className="bg-grey-100 flex w-full flex-row items-center gap-4 rounded-lg p-3">
      {fieldIcon[type]}
      <Text variant="body2" className="capitalize">
        {convertSnakeCaseToReadableString(name)}
      </Text>
    </div>
    {onRemove && (
      <div
        className={cx(
          "bg-grey-100 flex h-full cursor-pointer items-center justify-center rounded-lg px-4 hover:brightness-90",
          {
            "bg-tertiary": isAddedToDelete,
          }
        )}
        onClick={onRemove}
      >
        <RemoveIcon />
      </div>
    )}
  </div>
);

export default FieldChip;
