import React from "react";
import { Alert } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { ReactComponent as AlertIcon } from "./assets/alert.svg";

export const DiscardChangesDialog: React.FC<{
  isOpen: boolean;
  content: React.ReactNode;
  onSubmit: () => void;
  onClose: () => void;
}> = ({ isOpen, content, onSubmit, onClose }) => {
  const { t } = useTranslations();
  return (
    <Alert
      isOpen={isOpen}
      onRequestClose={onClose}
      img={<AlertIcon />}
      title={t({
        id: "profile-form-page.discard-changes",
        defaultMessage: "Discard changes",
      })}
      content={content}
      buttons={[
        {
          text: t({
            id: "common.cancel",
            defaultMessage: "Cancel",
          }),
          color: "grey",
          onClick: onClose,
        },
        {
          text: t({
            id: "common.discard",
            defaultMessage: "Discard",
          }),
          color: "tertiary",
          onClick: onSubmit,
        },
      ]}
    />
  );
};
