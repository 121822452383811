import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChatMessage, ChatMessageAction } from "../types";
import {
  selectOpenedChatMessage,
  setOpenedChatMessage,
  setMessageAction as setMessageActionAction,
  cancelMessageAction as cancelMessageActionAction,
  selectMessageAction,
} from "../chatsSlice";

const useMessageActions = (chatId = "") => {
  const dispatch = useDispatch();
  const openedChatMessage = useSelector(selectOpenedChatMessage);
  const messageAction = useSelector(selectMessageAction(chatId));

  const openMessageInfo = useCallback(
    (message: ChatMessage) => {
      dispatch(setOpenedChatMessage(message));
    },
    [dispatch]
  );

  const closeMessageInfo = useCallback(() => {
    dispatch(setOpenedChatMessage());
  }, [dispatch]);

  const setMessageAction = useCallback(
    (params: ChatMessageAction) => {
      dispatch(setMessageActionAction({ action: params, chatId }));
    },
    [chatId, dispatch]
  );

  const cancelMessageAction = useCallback(() => {
    dispatch(cancelMessageActionAction(chatId));
  }, [chatId, dispatch]);

  return {
    openMessageInfo,
    closeMessageInfo,
    openedChatMessage,
    setMessageAction,
    cancelMessageAction,
    messageAction,
  };
};

export default useMessageActions;
