import { addSearchParamsToURL } from "@jugl-web/utils";
import { RtkBaseQuery } from "./types";

export function serializeSearchParams(obj: {
  [key: string]: string | number | undefined;
}) {
  if (!obj) {
    return "";
  }
  const str: string[] = [];
  const keys = Object.keys(obj);
  keys.forEach((key) => {
    if (key && obj[key]) {
      const value =
        typeof obj[key] === "string"
          ? (obj[key] as string)
          : obj.key?.toString();
      if (value === undefined) {
        return;
      }
      str.push(
        `${encodeURIComponent(key).replace(/%2C/, ",")}=${encodeURIComponent(
          value
        ).replace(/%2C/, ",")}`
      );
    }
  });
  return str.join("&");
}

export const fetchAllPages = async <T>(
  url: string,
  params: { [key: string]: string | number },
  baseQuery: RtkBaseQuery
) => {
  let mergedItems: T[] = [];
  const resp = await baseQuery(addSearchParamsToURL(url, params || {}));
  if ("error" in resp) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw resp.error;
  }
  mergedItems = [...resp.data.data];
  const totalPages = resp.data.total_pages;
  let nextPage = 2;
  const extraQueries = [];

  while (nextPage <= totalPages) {
    extraQueries.push(
      addSearchParamsToURL(url, {
        ...(params || {}),
        page: nextPage,
      })
    );
    nextPage += 1;
  }
  const extraResponses = await Promise.all(
    extraQueries.map((query) => baseQuery(query))
  );
  extraResponses.forEach((extraResp) => {
    mergedItems = [...mergedItems, ...extraResp.data.data];
  });
  return mergedItems;
};
