import { useSessionStorage } from "@jugl-web/utils/hooks/useStorage";
import { LINK_TO_OPEN_AFTER_LOGIN_KEY } from "@jugl-web/utils/storage";

export const useLinkToOpenAfterLogin = () => {
  const [linkToOpenAfterLogin, setLinkToOpenAfterLogin] = useSessionStorage<
    string | null
  >(LINK_TO_OPEN_AFTER_LOGIN_KEY, null);

  return [linkToOpenAfterLogin, setLinkToOpenAfterLogin] as const;
};
