import { InteractiveContainerProps } from "@jugl-web/ui-components/cross-platform";
import { cx } from "@jugl-web/utils";
import { forwardRef, ReactNode } from "react";
import { TaskChecklistItemMode } from "./types";

export interface TaskChecklistItemButtonProps {
  children: ReactNode;
  itemMode: TaskChecklistItemMode;
  isDisabled?: boolean;
  className?: string;
  onClick?: InteractiveContainerProps["onClick"];
}

export const TaskChecklistItemButton = forwardRef<
  HTMLButtonElement,
  TaskChecklistItemButtonProps
>(({ children, itemMode, isDisabled, className, onClick }, ref) => (
  <button
    ref={ref}
    type="button"
    disabled={isDisabled}
    className={cx(
      "jugl__focusable-outline text-dark-600 flex items-center justify-center gap-1.5 rounded border-none px-2 font-[Roboto] text-xs",
      !isDisabled && "cursor-pointer",
      itemMode === "normal"
        ? "bg-grey-200 disabled:hover:bg-grey-200 hover:text-dark-800 disabled:hover:text-dark-600 hover h-6 transition-colors hover:bg-[#E9ECED]"
        : "bg-grey-100 h-8",
      className
    )}
    onClick={onClick}
  >
    {children}
  </button>
));
