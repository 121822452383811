import React, {
  useMemo,
  useState,
  createContext,
  PropsWithChildren,
} from "react";
import { ThemeProvider } from "@mui/material/styles";

// utils
import { getSupportedPlatForm } from "@web-src/utils/device";

// themes
import getTheme from "@web-src/themes/theme";

const CustomThemeContext = createContext<{
  darkMode: boolean;
  device: string | null;
  setDevice?: (e: string) => void;
}>({
  darkMode: false,
  device: null,
});

const CustomThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [device, setDevice] = useState(() => getSupportedPlatForm());
  const theme = getTheme();
  const setDeviceHandler = (type: string) => {
    setDevice(type);
  };
  const contextValue = useMemo(
    () => ({
      darkMode: false,
      device,
      setDevice: setDeviceHandler,
    }),
    [device]
  );
  return (
    <CustomThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CustomThemeContext.Provider>
  );
};

export { CustomThemeContext, CustomThemeProvider };
