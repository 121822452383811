import { FC, ReactNode } from "react";

interface CaretPinnedMentionsListProps {
  children: ReactNode;
}

export const CaretPinnedMentionsList: FC<CaretPinnedMentionsListProps> = ({
  children,
}) => (
  <ul
    className="jugl__border-box-component jugl__custom-scrollbar border-dark-100 relative -left-2 top-6 z-[1000] m-0 flex max-h-[200px] w-[314px] flex-col gap-1 overflow-y-auto rounded-lg border border-solid bg-white py-2 px-0"
    style={{ boxShadow: "0 9px 16px 0 rgba(18, 22, 34, 0.12)" }}
  >
    {children}
  </ul>
);
