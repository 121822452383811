import {
  Button,
  Dialog,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { MobileDrawer } from "@jugl-web/ui-components/mobile";
import { useAppVariant, useTranslations } from "@jugl-web/utils";
import Lottie from "react-lottie";
import React from "react";
import completionAnimation from "./animations/completionAnimation.json";
import { ReactComponent as CloseIcon } from "./icons/close.svg";

export const TaskCompletionDialog: React.FC<{
  isOpen: boolean;
  taskTitle: string;
  onClose: () => void;
}> = ({ isOpen, onClose, taskTitle }) => {
  const { variant } = useAppVariant();
  const { t } = useTranslations();
  const animatedTitle = (
    <>
      <div className="absolute top-1/2 left-1/2 h-[90px] w-[90px] -translate-x-1/2 -translate-y-1/2 rounded-full bg-[rgba(33,150,243,0.16)]">
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <Lottie
            options={{
              animationData: completionAnimation,
            }}
            height={200}
            width={200}
          />
        </div>
      </div>
      <p className="m-0 text-[22px] font-semibold">
        {t({
          id: "tasks-page.well-done",
          defaultMessage: "Well Done",
        })}
        !
      </p>
    </>
  );
  if (variant === "mobile") {
    return (
      <MobileDrawer isOpen={isOpen} onClose={onClose}>
        <MobileDrawer.Content>
          <div className="relative mt-8 flex items-center justify-center">
            {animatedTitle}
          </div>
          <div className="mt-14">
            <p className="text-primary text-sm">
              {t({
                id: "tasks-page.task-completed-successfully",
                defaultMessage: "Task completed successfully",
              })}
              !
            </p>
            <p className="text-dark line-clamp-2 max-w-full overflow-hidden text-ellipsis text-lg font-semibold">
              {taskTitle}
            </p>
            <Button fullWidth uppercase className="mt-10" onClick={onClose}>
              {t({
                id: "common.okay",
                defaultMessage: "Okay",
              })}
            </Button>
          </div>
        </MobileDrawer.Content>
      </MobileDrawer>
    );
  }
  return (
    <Dialog isOpen={isOpen} onClose={onClose} className="h-[450px] w-[800px]">
      <PlainButton onClick={onClose} className="absolute right-4 top-4">
        <CloseIcon />
      </PlainButton>
      <div className="relative mt-[74px] flex items-center justify-center">
        {animatedTitle}
      </div>
      <div className="mt-14 flex flex-col items-center justify-center">
        <p className="text-primary text-sm">
          {t({
            id: "tasks-page.task-completed-successfully",
            defaultMessage: "Task completed successfully",
          })}
          !
        </p>
        <p className="text-dark line-clamp-2 max-w-[480px] overflow-hidden text-ellipsis text-center text-lg font-semibold">
          {taskTitle}
        </p>
        <div className="mx-auto mt-10 w-[265px]">
          <Button fullWidth uppercase onClick={onClose}>
            {t({
              id: "common.okay",
              defaultMessage: "Okay",
            })}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
