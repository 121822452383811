import { CompleteTaskSuggestionDialog } from "@jugl-web/domain-resources/tasks/components/CompleteTaskSuggestionDialog";
import { useTaskFiltering } from "@jugl-web/domain-resources/tasks/components/TaskFilteringProvider";
import { useCompleteTaskSuggestionDialog } from "@jugl-web/domain-resources/tasks/hooks/useCompleteTaskSuggestionDialog";
import { useTaskPermissions } from "@jugl-web/domain-resources/tasks/hooks/useTaskPermissions";
import { useUpdateTask } from "@jugl-web/domain-resources/tasks/hooks/useUpdateTask";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { PreviewTask, TaskDefaultStatus } from "@jugl-web/rest-api/tasks";
import {
  Collapse,
  LoadingAnimation,
} from "@jugl-web/ui-components/cross-platform";
import { cx, useToast, useTranslations } from "@jugl-web/utils";
import { useTasksPageContext } from "@web-src/features/tasks/TasksPageContext";
import isEqual from "lodash/isEqual";
import { FC, Fragment, useState } from "react";
import { useInView } from "react-intersection-observer";
import { ReactComponent as ChevronRightSmallIcon } from "../../assets/chevron-right-small.svg";
import { TABLE_DIMENSIONS } from "../../consts";
import { useTaskTableColumns } from "../../hooks/useTaskTableColumns";
import { TaskDetailsRows } from "../TaskDetailsRows";
import { TaskTableLayout } from "../TaskTableLayout";

export interface TaskRowProps {
  task: PreviewTask;
  entityId: string;
  meId: string;
}

export const TaskRow: FC<TaskRowProps> = ({
  task: previewTask,
  entityId,
  meId,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { taskListMode } = useTasksPageContext();
  const { searchQuery } = useTaskFiltering();
  const { columns } = useTaskTableColumns();

  const [rowRef, isRowInView] = useInView({
    threshold: 0.1,
    rootMargin: "300px 0px",
  });

  const { t } = useTranslations();
  const { toast } = useToast();
  const { tasksApi } = useRestApiProvider();

  const { data: detailedTask, isLoading } = tasksApi.useGetTaskQuery(
    { entityId, taskId: previewTask.id },
    { skip: !isExpanded }
  );

  const permissions = useTaskPermissions({
    task: detailedTask || previewTask,
    meId,
  });

  const updateTask = useUpdateTask({
    entityId,
    taskId: previewTask.id,
    isInTeamTasksContext: taskListMode === "team",
  });

  const { dialogProps: completeTaskSuggestionDialogProps } =
    useCompleteTaskSuggestionDialog({
      task: detailedTask,
      meId,
      onComplete: () => {
        updateTask({ status: TaskDefaultStatus.completed });
        toast(
          t({
            id: "tasks-page.task-completed",
            defaultMessage: "Task has been completed",
          })
        );
      },
    });

  // Handler to update the preview task (so the main task row)
  const handleUpdatePreviewTask = (attributes: Partial<PreviewTask>) => {
    const hasTaskChanged = !isEqual(previewTask, {
      ...previewTask,
      ...attributes,
    });

    if (hasTaskChanged) {
      updateTask(attributes);
    }
  };

  const areTaskDetailsVisible = Boolean(isExpanded && detailedTask);

  return (
    <>
      <TaskTableLayout.Row
        ref={rowRef}
        style={{ minHeight: TABLE_DIMENSIONS.TABLE_TASK_ROW_HEIGHT }}
        className={cx(areTaskDetailsVisible && "[&>div]:bg-grey-100")}
      >
        {isRowInView ? (
          <>
            <TaskTableLayout.Cell
              width={TABLE_DIMENSIONS.TABLE_OUTERMOST_COLUMN_WIDTH}
              isHoverable
              isDisabled={isLoading}
              className="justify-center px-0"
              onClick={() => setIsExpanded((s) => !s)}
            >
              {isLoading ? (
                <LoadingAnimation size="sm" />
              ) : (
                <ChevronRightSmallIcon
                  className={cx("transition", isExpanded && "rotate-90")}
                />
              )}
            </TaskTableLayout.Cell>
            {columns.map((column, index) => {
              const isLastColumn = index === columns.length - 1;

              return (
                <Fragment key={column.id}>
                  {column.taskCellRenderer({
                    task: previewTask,
                    entityId,
                    permissions,
                    searchQuery,
                    cellProps: {
                      width: column.width,
                      shouldGrow: isLastColumn,
                    },
                    onUpdate: handleUpdatePreviewTask,
                  })}
                </Fragment>
              );
            })}
            <TaskTableLayout.OutermostEmptyCell />
          </>
        ) : (
          <TaskTableLayout.Cell shouldGrow />
        )}
      </TaskTableLayout.Row>
      <Collapse isOpen={areTaskDetailsVisible} className="flex flex-col">
        {detailedTask && (
          <TaskDetailsRows
            task={detailedTask}
            permissions={permissions}
            entityId={entityId}
          />
        )}
      </Collapse>
      <CompleteTaskSuggestionDialog {...completeTaskSuggestionDialogProps} />
    </>
  );
};
