import React, { useMemo, useState } from "react";
import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import { WEB_DOWNLOAD_MOBILE_CTA_CLOSED } from "@jugl-web/utils/storage";
import { ReactComponent as JuglLogo } from "./assets/jugl-logo.svg";
import { ReactComponent as AppStoreBadge } from "./assets/app-store-badge.svg";
import { ReactComponent as PlayStoreBadge } from "./assets/play-store-badge.svg";
import { isMobile } from "../../utils/isMobile";

export const DownloadMobileAppCTA: React.FC = () => {
  const isRunningOnMobile = useMemo(() => isMobile(), []);
  const [isClosed, setIsClosed] = useState<boolean>(
    !!sessionStorage.getItem(WEB_DOWNLOAD_MOBILE_CTA_CLOSED)
  );
  const handleClose = () => {
    setIsClosed(true);
    sessionStorage.setItem(WEB_DOWNLOAD_MOBILE_CTA_CLOSED, "true");
  };
  if (!isRunningOnMobile || isClosed) {
    return null;
  }
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 z-[99999] flex flex-col items-center justify-center bg-gray-100 px-6">
      <div className="bg-primary-500 flex h-[80px] w-[80px] items-center justify-center rounded-[20px]">
        <JuglLogo />
      </div>
      <div className="mt-10 max-w-[280px] text-center text-lg leading-[150%]">
        Download official Jugl Application for iOS and Android
      </div>
      <div className="mt-10 flex flex-wrap items-center justify-center gap-2">
        <a
          className="inline-block"
          href="https://apps.apple.com/id/app/jugl/id1571640105"
        >
          <AppStoreBadge />
        </a>
        <a
          className="inline-block"
          href="https://play.google.com/store/apps/details?id=com.jugl"
        >
          <PlayStoreBadge />
        </a>
      </div>
      <div className="mt-10">
        <InteractiveContainer
          onClick={handleClose}
          className="px-4 text-sm text-gray-500 underline"
        >
          Continue with Web version
        </InteractiveContainer>
      </div>
    </div>
  );
};
