import { cx, useUniqueDOMId } from "@jugl-web/utils";
import { forwardRef } from "react";
import { FormControlLabel } from "../FormControlLabel";
import { Select, SelectProps } from "../Select";

export interface SelectFieldProps {
  label: string;
  isRequired?: boolean;
  isFullWidth?: boolean;
  className?: string;
  placeholder?: SelectProps["placeholder"];
  value?: SelectProps["value"];
  onChange?: SelectProps["onChange"];
  children?: React.ReactNode;
}

export const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>(
  ({ label, isRequired, isFullWidth, className, ...props }, ref) => {
    const inputId = useUniqueDOMId();

    return (
      <div className={cx("flex flex-col items-start", className)}>
        <FormControlLabel
          htmlFor={inputId}
          isRequired={isRequired}
          className="ml-2.5"
        >
          {label}
        </FormControlLabel>
        <Select
          ref={ref}
          id={inputId}
          className={cx(isFullWidth && "w-full")}
          {...props}
        />
      </div>
    );
  }
);
