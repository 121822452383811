export const priceDisplay = (amountInCents: number, currencyCode: string) => {
  const formatter = new Intl.NumberFormat(
    Intl.DateTimeFormat().resolvedOptions().locale,
    {
      style: "currency",
      currency: currencyCode,
      minimumFractionDigits: 2,
    }
  );

  return formatter.format(
    amountInCents !== 0 ? amountInCents / 100 : amountInCents
  );
};
