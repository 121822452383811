import { useUniqueDOMId } from "@jugl-web/utils";
import { useCallback, useState } from "react";
import {
  DraggableChecklistAddButtonProps,
  DraggableChecklistController,
  DraggableChecklistItem,
} from "./types";
import { produceChecklistItem } from "./utils";

export const CHECKLIST_ID_DATA_ATTR = "data-checklist-id";
export const ADD_BUTTON_DATA_TTR = "data-add-button";

export const useDraggableChecklistController = (
  initialItems: DraggableChecklistItem[] = []
): DraggableChecklistController => {
  const checklistId = useUniqueDOMId();
  const [items, setItems] = useState(initialItems);
  const [editingItemId, setEditingItemId] = useState<string | null>(null);
  const [isDirty, setIsDirty] = useState(false);

  const isCreatingItem = items.length > 0 && items[0].text === "";

  const addButtonProps: DraggableChecklistAddButtonProps = {
    onClick: () => {
      if (isCreatingItem) {
        return;
      }

      const newItem = produceChecklistItem("", false);
      setItems((prevItems) => [newItem, ...prevItems]);
      setEditingItemId(newItem.id);
    },
    [ADD_BUTTON_DATA_TTR]: true,
    [CHECKLIST_ID_DATA_ATTR]: checklistId,
  };

  const reset = useCallback((newItems: DraggableChecklistItem[] = []) => {
    setItems(newItems);
    setEditingItemId(null);
    setIsDirty(false);
  }, []);

  const internals = {
    checklistId,
    items,
    editingItemId,
    isDirty,
    setItems,
    setEditingItemId,
    setIsDirty,
  };

  return { internals, items, isDirty, addButtonProps, reset };
};
