import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useTranslations } from "@jugl-web/utils";
import { DirectoryListItem } from "@jugl-web/rest-api/drive/types";
import { SidebarDrawer } from "@jugl-web/ui-components/web";
import MoveFileModal from "./components/MoveFileModal";
import CreateFolder from "./components/CreateFolderModal";
import { useDrive } from "../../../../hooks/useDrive";

enum ModalTypes {
  move = "move",
  create = "create",
}

const MoveFileSidebar: React.FC<{
  isOpen: { id: string; name: string };
  onRequestClose: () => void;
}> = ({ isOpen, onRequestClose }) => {
  const { t } = useTranslations();
  const [typeModal, setTypeModal] = useState(ModalTypes.move);
  const [folders, setFolders] = useState<DirectoryListItem[]>([]);
  const [knowRoot, setKnowRoot] = useState(false);
  const { driveApi } = useRestApiProvider();
  const { rootDirectory } = useDrive();
  const [visited, setVisited] = useState<{ id: string; name: string }[]>([
    { id: rootDirectory?.current_dir_id || "", name: "Drive" },
  ]);
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { data, isLoading, isFetching } = driveApi.useGetDirectoryContentsQuery(
    {
      parent_id: visited.at(-1)?.id || undefined,
      ...(params.activeChatId && { workspace_id: params.activeChatId }),
      entityId: params.entityId || "",
    }
  );

  useEffect(() => {
    setFolders([]);
  }, [visited.length]);

  useEffect(() => {
    if (!data || knowRoot) return;
    setKnowRoot(true);
  }, [data, knowRoot, searchParams]);

  useEffect(() => {
    if (!data) return;
    setFolders([
      ...new Map(data.data.map((element) => [element.id, element])).values(),
    ]);
  }, [data, setFolders]);

  return (
    <SidebarDrawer
      isOpen={!!isOpen}
      onClose={
        typeModal === ModalTypes.move
          ? onRequestClose
          : () => setTypeModal(ModalTypes.move)
      }
      title={
        typeModal === ModalTypes.move
          ? t({
              id: "drive-page.move-file-to",
              defaultMessage: "Move file to",
            })
          : t({
              id: "drive-page.create-new-folder",
              defaultMessage: "Create new folder",
            })
      }
    >
      <SidebarDrawer.Content>
        <div className="h-full">
          {typeModal === ModalTypes.move ? (
            <MoveFileModal
              onCreateFolder={() => setTypeModal(ModalTypes.create)}
              onClose={onRequestClose}
              folders={folders}
              visited={visited}
              setVisited={setVisited}
              isLoading={isLoading || isFetching}
              fileElement={isOpen}
            />
          ) : (
            <CreateFolder
              visited={visited}
              onRequestClose={() => setTypeModal(ModalTypes.move)}
            />
          )}
        </div>
      </SidebarDrawer.Content>
    </SidebarDrawer>
  );
};

export default MoveFileSidebar;
