import { FC } from "react";
import Lottie from "react-lottie";
import { useTranslations } from "@jugl-web/utils";
import emptyStateAnimationData from "../../assets/empty-state-animation.json";
import { ModuleToLogMap } from "../../types";

interface EmptyStateProps {
  module: keyof ModuleToLogMap;
}

export const EmptyState: FC<EmptyStateProps> = ({ module }) => {
  const { t } = useTranslations();
  const moduleToLabel: Record<keyof ModuleToLogMap, string> = {
    task: t({
      id: "empty-state-component.notification-task-module",
      defaultMessage: "Task",
    }),
    people: t({
      id: "empty-state-component.notification-people-module",
      defaultMessage: "People",
    }),
    customer: t({
      id: "empty-state-component.notification-client-module",
      defaultMessage: "Client",
    }),
    drive: t({
      id: "empty-state-component.notification-drive-module",
      defaultMessage: "Drive",
    }),
  };

  const moduleLabel = moduleToLabel[module];

  return (
    <div
      key="empty"
      className="animate-fade-in mx-auto mt-[200px] flex w-[343px] flex-col text-center"
    >
      <Lottie
        options={{ animationData: emptyStateAnimationData }}
        height={156}
        width={184}
      />
      <h3 className="text-dark mx-0 mt-8 mb-6 text-lg font-medium">
        {t(
          {
            id: "empty-state-component.welcome-notifications-title",
            defaultMessage: "Welcome to the {module} notifications",
          },
          {
            module: moduleLabel,
          }
        )}
      </h3>
      <p className="text-dark m-0 text-sm leading-[21px]">
        {t(
          {
            id: "empty-state-component.notifications-updates-info",
            defaultMessage:
              "Here you will see all the latest {module} updates.{br}We will notify you when there is something new 🚀",
          },
          {
            module: moduleLabel,
            br: <br />,
          }
        )}
      </p>
    </div>
  );
};
