import { FC } from "react";
import { Alert, Avatar, Text } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";

export const EntityAlreadyAddedAlert: FC<{
  isOpen: boolean;
  entityAvatar: string | null;
  entityName: string;
  onClose: () => void;
}> = ({ isOpen, entityAvatar, entityName, onClose }) => {
  const { t } = useTranslations();

  return (
    <Alert
      isCloseButtonVisible
      isOpen={isOpen}
      onRequestClose={onClose}
      img={
        <div className="relative grid h-[150px] w-[150px]">
          <Avatar imageUrl={entityAvatar} username={entityName} size="5xl" />
        </div>
      }
      header={t({
        id: "entity-select-page.invitation",
        defaultMessage: "Invitation",
      })}
      title={t({
        id: "entity-select-page.entity-already-added",
        defaultMessage: "Workspace already added",
      })}
      content={
        <Text variant="body2" className="text-dark-800">
          {t(
            {
              id: "entity-select-page.entity-already-added-description",
              defaultMessage:
                "You are already a part of <highlightedText>{entityName}</highlightedText> Workspace",
            },
            {
              highlightedText: (text: (string | JSX.Element)[]) => (
                <span className="text-primary-800 font-semibold">{text}</span>
              ),
              entityName,
            }
          )}
        </Text>
      }
      buttons={[
        {
          text: t({ id: "common.okay", defaultMessage: "Okay" }),
          color: "primary",
          onClick: onClose,
        },
      ]}
    />
  );
};
