import { usePaginationUpdate } from "@jugl-web/utils";
import { useCallback } from "react";
import {
  ChatMessage,
  ChatMessagePayloadAttachment,
  ChatMessagePayloadAttachmentType,
} from "@web-src/features/chats/types";
import { getChatPaginationId } from "../../utils/getChatPaginationId";

export const useUpdateChatMessages = () => {
  const { addItems, changeItemId, updateItem, addOrUpdateItem } =
    usePaginationUpdate<Partial<ChatMessage>>();

  const addMessages = useCallback(
    (
      params: Omit<Parameters<typeof addItems>[0], "paginationId"> & {
        chatId: string;
      }
    ) => {
      addItems({ ...params, paginationId: getChatPaginationId(params.chatId) });

      params.itemsToAdd.forEach((item) =>
        item.data.payload?.attachments?.map((attachment) =>
          addItems({
            ...params,
            paginationId: getChatPaginationId(params.chatId, {
              attachment_type: attachment.type,
            }),
          })
        )
      );
    },
    [addItems]
  );

  const addOrUpdateMessage = useCallback(
    (
      params: Omit<Parameters<typeof addOrUpdateItem>[0], "paginationId"> & {
        chatId: string;
      }
    ) => {
      addOrUpdateItem({
        ...params,
        paginationId: getChatPaginationId(params.chatId),
      });

      params.item.data.payload?.attachments?.map(
        (attachment: ChatMessagePayloadAttachment) =>
          addOrUpdateItem({
            ...params,
            paginationId: getChatPaginationId(params.chatId, {
              attachment_type: attachment.type,
            }),
          })
      );
    },
    [addOrUpdateItem]
  );

  const updateMessage = useCallback(
    (
      params: Omit<Parameters<typeof updateItem>[0], "paginationId"> & {
        chatId: string;
      }
    ) => {
      updateItem({
        ...params,
        paginationId: getChatPaginationId(params.chatId),
      });
      params.item.data.payload?.attachments?.map((attachment) =>
        updateItem({
          ...params,
          paginationId: getChatPaginationId(params.chatId, {
            attachment_type: attachment.type,
          }),
        })
      );
    },
    [updateItem]
  );

  const changeMessageId = useCallback(
    (
      params: Omit<Parameters<typeof changeItemId>[0], "paginationId"> & {
        chatId: string;
      }
    ) => {
      changeItemId({
        ...params,
        paginationId: getChatPaginationId(params.chatId),
      });

      Object.values(ChatMessagePayloadAttachmentType).forEach(
        (attachmentType) => {
          changeItemId({
            ...params,
            paginationId: getChatPaginationId(params.chatId, {
              attachment_type:
                attachmentType as unknown as ChatMessagePayloadAttachmentType,
            }),
          });
        }
      );
    },
    [changeItemId]
  );

  return { addMessages, changeMessageId, updateMessage, addOrUpdateMessage };
};
