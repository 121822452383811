import { EditCustomTextOrNumberFieldDialog } from "@jugl-web/domain-resources/tasks/components/EditCustomTextOrNumberFieldDialog";
import { assert } from "@jugl-web/utils";
import { FC, useState } from "react";
import { CustomSpecificFieldCellProps } from "../../types";
import { CustomFieldCellContent } from "../CustomFieldCellContent";
import { TaskTableLayout } from "../TaskTableLayout";

export const CustomNumberFieldCell: FC<CustomSpecificFieldCellProps> = ({
  field,
  rawValue,
  isEditable,
  cellProps,
  onFieldUpdate,
}) => {
  assert(field.type === "number", "Field should be of type number");

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <TaskTableLayout.Cell
        isFocused={isDialogOpen}
        isHoverable
        isDisabled={!isEditable}
        onClick={() => setIsDialogOpen(true)}
        {...cellProps}
      >
        <CustomFieldCellContent
          field={field}
          rawValue={rawValue}
          isEditable={isEditable}
          isCellFocused={isDialogOpen}
          onClearValue={() => onFieldUpdate("")}
        />
      </TaskTableLayout.Cell>
      <EditCustomTextOrNumberFieldDialog
        isOpen={isDialogOpen}
        field={field}
        rawValue={rawValue}
        onSubmit={onFieldUpdate}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};
