import { defineMessage } from "react-intl";

export const getSoundName = (notificationSoundId: number) =>
  notificationSoundId === 0
    ? defineMessage({
        id: "settings-page.jugl-default",
        defaultMessage: "Jugl Default",
      })
    : defineMessage({
        id: "settings-page.option",
        defaultMessage: "Option {notificationSoundId}",
      });
