import { FC, ReactNode } from "react";

const CONTAINER_SIZE_PX = 188;

interface ContainerPinnedMentionsListProps {
  children: ReactNode;
}

export const ContainerPinnedMentionsList: FC<
  ContainerPinnedMentionsListProps
> = ({ children }) => (
  <div
    className="jugl__border-box-component border-grey-200 absolute inset-x-0 z-[1000] flex flex-col justify-end border-b border-l-0 border-r-0 border-t-0 border-solid"
    style={{ top: -CONTAINER_SIZE_PX, height: CONTAINER_SIZE_PX }}
  >
    <ul
      className="m-0 flex flex-col gap-1 overflow-y-auto rounded-tl-lg rounded-tr-lg bg-white py-2 px-0"
      style={{ boxShadow: "2px -6px 10px 0px rgba(18, 22, 34, 0.16)" }}
    >
      {children}
    </ul>
  </div>
);
