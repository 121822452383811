import {
  InternalTaskCustomField,
  TaskCustomFieldType,
} from "@jugl-web/rest-api/tasks";
import {
  Button,
  Menu,
  MenuSections,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { useScreenTransitionManager } from "@jugl-web/utils/utils/ScreenTransitionManager";
import { FC, useCallback, useState } from "react";
import { useTranslations } from "@jugl-web/utils";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { taskCustomFieldTypeLabelById } from "../../consts";
import { useTaskFields } from "../../hooks/useTaskFields";
import { DeleteCustomFieldConfirmationDialog } from "./DeleteCustomFieldConfirmationDialog";
import { DraggableTaskFieldBox } from "./TaskFieldBox";
import { ReactComponent as AddIcon } from "./icons/add.svg";
import { ReactComponent as CustomDateFieldIcon } from "./icons/custom-date-field.svg";
import { ReactComponent as CustomDropdownFieldIcon } from "./icons/custom-dropdown-field.svg";
import { ReactComponent as CustomNumberFieldIcon } from "./icons/custom-number-field.svg";
import { ReactComponent as CustomTextFieldIcon } from "./icons/custom-text-field.svg";
import { ReactComponent as VerticalMenuBlankIcon } from "./icons/vertical-menu-blank.svg";
import { ManageTaskFieldsDialogScreenToParametersMap } from "./types";

const customFieldTypeToIcon: Record<TaskCustomFieldType, JSX.Element> = {
  text: <CustomTextFieldIcon />,
  dropdown: <CustomDropdownFieldIcon />,
  date: <CustomDateFieldIcon />,
  number: <CustomNumberFieldIcon />,
};

export interface CustomFieldsSectionProps {
  entityId: string;
}

export const CustomFieldsSection: FC<CustomFieldsSectionProps> = ({
  entityId,
}) => {
  const [deleteConfirmationDialogState, setDeleteConfirmationDialogState] =
    useState<{ isOpen: boolean; field: InternalTaskCustomField | null }>({
      isOpen: false,
      field: null,
    });
  const { t } = useTranslations();

  const { customFields, updateCustomField, reorderCustomField } = useTaskFields(
    { entityId }
  );

  const { transitionTo } =
    useScreenTransitionManager<ManageTaskFieldsDialogScreenToParametersMap>();

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination || source.index === destination.index) {
      return;
    }

    reorderCustomField(source.index, destination.index);
  };

  const getMenuSections = useCallback(
    (field: InternalTaskCustomField): MenuSections => [
      [
        {
          id: "showInTaskCard",
          label: t({
            id: "tasks-page.show-field-in-task-card",
            defaultMessage: "Show field in a task card",
          }),
          toggle: "switch",
          isSelected: field.isShownInCard,
          onSelect: (event) => {
            event.stopPropagation();

            updateCustomField({
              ...field,
              isShownInCard: !field.isShownInCard,
            }).catch(() => {});
          },
        },
        {
          id: "edit",
          label: t({
            id: "tasks-page.edit-field",
            defaultMessage: "Edit field",
          }),
          onSelect: (event, close) => {
            event.stopPropagation();
            close();

            transitionTo({
              name: "editCustomField",
              field,
            });
          },
        },
        {
          id: "delete",
          label: t({
            id: "tasks-page.delete-field",
            defaultMessage: "Delete field",
          }),
          onSelect: (event, close) => {
            event.stopPropagation();
            close();

            setDeleteConfirmationDialogState({ isOpen: true, field });
          },
        },
      ],
    ],
    [transitionTo, updateCustomField, t]
  );

  const isEmpty = customFields.length === 0;

  return (
    <div>
      <div className="mb-2.5 flex h-10 items-center justify-between p-2">
        <div className="flex flex-col gap-1">
          <span className="text-dark-800 text-sm font-medium leading-[21px]">
            {t({
              id: "tasks-page.custom-fields",
              defaultMessage: "Custom fields",
            })}
          </span>
          {isEmpty && (
            <span className="text-grey-900 leading-2 text-xs">{`You haven't created any custom field yet`}</span>
          )}
        </div>
        <Button
          color="grey"
          className="p-3 font-normal"
          iconEnd={<AddIcon />}
          onClick={() => transitionTo({ name: "newCustomField" })}
        >
          {t({
            id: "common.add",
            defaultMessage: "Add",
          })}
        </Button>
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="customFieldList">
          {(provided) => (
            <div
              ref={provided.innerRef}
              className="flex flex-col"
              {...provided.droppableProps}
            >
              {customFields.map((field, index) => (
                <DraggableTaskFieldBox
                  key={field.id}
                  id={field.id}
                  index={index}
                  title={field.name}
                  subtitle={{
                    icon: customFieldTypeToIcon[field.type],
                    label: t(
                      {
                        id: "tasks-page.custom-type-field",
                        defaultMessage: "Custom {type} field",
                      },
                      {
                        type: taskCustomFieldTypeLabelById[
                          field.type
                        ].toLowerCase(),
                      }
                    ),
                    valuesCount: field.values?.length,
                  }}
                  customEndSlot={
                    <Menu
                      placement="bottom-end"
                      className="w-[266px]"
                      sections={getMenuSections(field)}
                      renderTrigger={({ Trigger, triggerRef }) => (
                        <Trigger
                          as={PlainButton}
                          ref={triggerRef}
                          className="hover:bg-grey-200 flex h-8 w-8 items-center justify-center rounded-full transition-colors"
                          onClick={(event) => event.stopPropagation()}
                        >
                          <VerticalMenuBlankIcon className="text-grey-600" />
                        </Trigger>
                      )}
                    />
                  }
                  onClick={() =>
                    transitionTo({ name: "editCustomField", field })
                  }
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <DeleteCustomFieldConfirmationDialog
        entityId={entityId}
        onClose={() =>
          setDeleteConfirmationDialogState((prevState) => ({
            ...prevState,
            isOpen: false,
          }))
        }
        {...deleteConfirmationDialogState}
      />
    </div>
  );
};
