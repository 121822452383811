import {
  HeadlessUsersList,
  HeadlessUsersListHandle,
} from "@jugl-web/domain-resources/users/components/HeadlessUsersList";
import { UserListItem } from "@jugl-web/domain-resources/users/components/UserListItem";
import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { ListHeading } from "@jugl-web/ui-components/cross-platform/list/ListHeading";
import { useSearchInput, useTranslations } from "@jugl-web/utils";
import useEntity from "@web-src/features/app/hooks/useEntity";
import React, { useCallback, useRef, useState } from "react";
import AddWorkspaceMemberSideBar from "@web-src/features/workspaces/components/AddWorkspaceMemberSideBar";
import { UserGeneralProfile, useRestApiProvider } from "@jugl-web/rest-api";
import { setOpenedUserProfile } from "@web-src/features/chats/chatsSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectUserId } from "@web-src/features/auth/authSlice";
import useActiveChat from "@web-src/features/chats/hooks/useActiveChat";
import { ReactComponent as UserIcon } from "./assets/user.svg";
import { ChatInfoLoading } from "../ChatInfoLoading";
import { ChatInfoError } from "../ChatInfoError";
import { ChatInfoEmptyContent } from "../ChatInfoEmptyContent";
import { InfoTabId } from "../../types";

export const ChatInfoMembers: React.FC<{
  chatId: string;
  isActive: boolean;
}> = ({ chatId, isActive }) => {
  const { chat } = useActiveChat();
  const { entity } = useEntity();
  const { inputProps, searchQuery } = useSearchInput();
  const [addParticipantsOpened, setAddParticipantsOpened] = useState(false);
  const $headlessUsersList = useRef<HeadlessUsersListHandle | null>(null);
  const { t } = useTranslations();
  const { workspacesApi } = useRestApiProvider();
  const [deleteWorkspaceParticipant] =
    workspacesApi.useDeleteWorkspaceParticipantMutation();
  const dispatch = useDispatch();
  const meId = useSelector(selectUserId);

  const handleRemoveFromGroup = useCallback(
    (user: UserGeneralProfile) => {
      if (!chatId || !entity?.id) {
        return;
      }
      deleteWorkspaceParticipant({
        id: chatId,
        entityId: entity.id,
        userId: user.id,
      }).then(() => {
        setTimeout(() => {
          $headlessUsersList.current?.refetch();
        }, 1000);
      });
    },
    [entity?.id, deleteWorkspaceParticipant, chatId]
  );

  const handleOpenUserProfile = useCallback(
    (user: UserGeneralProfile) => {
      dispatch(setOpenedUserProfile(user.id));
    },
    [dispatch]
  );

  const menuItems = useCallback(
    (userId: string) => [
      {
        text: t({
          id: "chats-page.open-profile",
          defaultMessage: "Open profile",
        }),
        onClick: handleOpenUserProfile,
      },
      ...(userId !== meId
        ? [
            {
              text: t({
                id: "chats-page.remove-from-group",
                defaultMessage: "Remove from group",
              }),
              onClick: handleRemoveFromGroup,
            },
          ]
        : []),
    ],
    [handleRemoveFromGroup, meId, t, handleOpenUserProfile]
  );

  if (!isActive) return null;
  return (
    <>
      <div className="flex grow flex-col py-6">
        <div className="flex flex-col gap-[22px] px-8 pb-[11px]">
          <SearchInput {...inputProps} variant="filled" color="grey" />
          {!chat?.deleted && (
            <InteractiveContainer
              className="flex items-center gap-3"
              onClick={() => setAddParticipantsOpened(true)}
            >
              <div className="bg-primary flex h-12 w-12 items-center justify-center rounded-full">
                <UserIcon />
              </div>
              <span className="text-primary-700 font-medium">
                {t({
                  id: "chats-page.add-member",
                  defaultMessage: "Add member",
                })}
              </span>
            </InteractiveContainer>
          )}
        </div>
        <HeadlessUsersList
          ref={$headlessUsersList}
          fetchParams={{
            entityId: entity?.id || "",
            workspaceId: chatId,
            searchQuery,
          }}
          userRenderer={(user) => (
            <UserListItem
              options={chat?.deleted ? [] : menuItems(user.id)}
              entityId={entity?.id || ""}
              userId={user.id}
              variant="web"
              highlightText={searchQuery}
            />
          )}
          moreLoadingErrorContent={(retry) => (
            <ChatInfoError onRefresh={retry} isMembersSection />
          )}
          emptyContent={
            <ChatInfoEmptyContent tab={InfoTabId.workspaceMembers} />
          }
          errorContent={(retry) => (
            <div className="relative flex grow flex-col">
              <div className="grow" />
              <ChatInfoError onRefresh={retry} isMembersSection />
            </div>
          )}
          loadingContent={<ChatInfoLoading isInitialLoading />}
          moreLoadingContent={<ChatInfoLoading />}
          segregate={{
            type: "department",
            groupHeaderRenderer: ({ title }) => (
              <ListHeading>{title}</ListHeading>
            ),
          }}
        />
      </div>
      <AddWorkspaceMemberSideBar
        isOpen={addParticipantsOpened}
        workspaceId={chatId}
        onRequestClose={(added) => {
          if (added) {
            setTimeout(() => {
              $headlessUsersList.current?.refetch();
            }, 1000);
          }
          setAddParticipantsOpened(false);
        }}
      />
    </>
  );
};
