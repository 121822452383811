import React, { useEffect, useMemo, useRef, useState } from "react";
import { HeaderBreadcrumbs } from "@jugl-web/ui-components/web";
import { useParams } from "react-router-dom";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useFilePreview } from "@jugl-web/domain-resources/files/providers/FilePreviewProvider";
import {
  cx,
  useAutoResizedTextarea,
  useToast,
  useTranslations,
} from "@jugl-web/utils";
import {
  Button,
  InteractiveContainer,
  Radio,
  TextField,
  MultiSectionLayout,
  PageLoaderFull,
} from "@jugl-web/ui-components/cross-platform";
import { Controller, useForm } from "react-hook-form";
import { getAllTimezones, getTimezone } from "countries-and-timezones";
import { omit } from "lodash";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { environment } from "@web-src/environments/environment";
import PeopleImage from "./assets/people.png";
import TasksImage from "./assets/tasks.png";
import TravelLogImage from "./assets/travel-log.png";
import { CrontabPicker } from "./components/CrontabPicker";
import { ReactComponent as ReportIcon } from "./assets/report.svg";
import { ReactComponent as ArrowIcon } from "./assets/arrow.svg";
import { EmailsInput } from "./components/EmailsInput";
import { ScheduledReportsFormValues } from "./types";
import { getSupersetChartIdByType } from "../../utils/getSupersetChartIdByType";
import { getTemplateIdByChartId } from "../../utils/getTemplateIdByChartId";

export const ScheduledReportsFormPage: React.FC = () => {
  const { t } = useTranslations();
  const { navigateToPage } = useNavigation();
  const { entityId } = useEntitySelectedProvider();
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const { reportId } = useParams();
  const { textareaProps } = useAutoResizedTextarea(textareaRef);
  const { previewFile } = useFilePreview();
  const { reportsApi } = useRestApiProvider();
  const [reportFetched, setReportFetched] = useState(false);
  const [createReport, { isLoading }] = reportsApi.useCreateReportMutation();
  const { data: reports, isError } = reportsApi.useGetReportsQuery(
    { entityId },
    { skip: !reportId }
  );
  const data = useMemo(
    () =>
      reports?.filter((report) =>
        Object.keys(environment.supersetChartIdToType)
          .map((item) => parseInt(item, 10))
          .includes(report.chartId)
      ),
    [reports]
  );
  const [updateReport, { isLoading: isUpdateLoading }] =
    reportsApi.useUpdateReportMutation();
  const { toast } = useToast({ variant: "web" });

  const {
    register,
    handleSubmit,
    control,
    formState: { dirtyFields, isDirty, errors },
    reset,
  } = useForm<ScheduledReportsFormValues>({
    mode: "onTouched",
    defaultValues: {
      name: "",
      chartId: getSupersetChartIdByType("tasks"),
      recipients: [],
      description: "",
      crontab: "0 24 * * 0",
      timezone: "America/New_York",
    },
  });

  useEffect(() => {
    const editedReport = data?.find((report) => report.id === reportId);
    if (!editedReport) return;
    reset({
      ...editedReport,
      recipients: editedReport.recipients.map((email) => ({ value: email })),
    });
    setReportFetched(true);
  }, [reportId, data, reset]);

  useEffect(() => {
    if (isError) {
      navigateToPage("reportsScheduled");
    }
  }, [isError, entityId, navigateToPage]);

  const handleFormSubmit = handleSubmit(async (values) => {
    const response = await createReport({
      entityId,
      data: {
        ...values,
        recipients: values.recipients.map(({ value }) => value),
        template: getTemplateIdByChartId(values.chartId),
      },
    });
    if (response && "data" in response) {
      toast(
        t({
          id: "scheduled-reports-page.scheduled-report-created",
          defaultMessage: "Scheduled auto report was created",
        })
      );
      navigateToPage("reportsScheduled");
    }
  });

  const handleUpdate = handleSubmit(async (values) => {
    if (!reportId) return;
    const dirtyData = Object.fromEntries(
      Object.entries(values).filter(
        ([key]) => dirtyFields[key as keyof typeof dirtyFields]
      )
    );

    if (dirtyData.recipients) {
      dirtyData.recipients = dirtyData.recipients.map(
        ({ value }: { value: string }) => value
      );
    }

    const response = await updateReport({
      entityId,
      reportId,
      data: dirtyData,
    });
    if (response && "data" in response) {
      toast(
        t({
          id: "scheduled-reports-page.scheduled-report-updated",
          defaultMessage: "Scheduled auto report was updated",
        })
      );
      navigateToPage("reportsScheduled");
    }
  });

  const reportsList: {
    id: string;
    image: string;
    title: string;
    description: string;
    value: number;
  }[] = useMemo(
    () => [
      {
        id: "tasks",
        image: TasksImage,
        title: t({
          id: "scheduled-reports-page.tasks-reports",
          defaultMessage: "Tasks Report",
        }),
        description: t({
          id: "scheduled-reports-page.overall-task-progress",
          defaultMessage:
            "Overall task progress of each Employee during last time period",
        }),
        value: getSupersetChartIdByType("tasks"),
      },
      {
        id: "travelLog",
        image: TravelLogImage,
        title: t({
          id: "scheduled-reports-page.travel-log-report",
          defaultMessage: "Travel log Report",
        }),
        description: t({
          id: "scheduled-reports-page.employees-clocked-info",
          defaultMessage:
            "All info about when and where Employees clocked in during last time period",
        }),
        value: getSupersetChartIdByType("travelLog"),
      },
      {
        id: "people",
        image: PeopleImage,
        title: t({
          id: "scheduled-reports-page.people-report",
          defaultMessage: "People Report",
        }),
        description: t({
          id: "scheduled-reports-page.people-activity",
          defaultMessage:
            "People activity in Workspace, including status and Modules used",
        }),
        value: getSupersetChartIdByType("people"),
      },
    ],
    [t]
  );

  const timezoneItems = useMemo(
    () =>
      Object.values(getAllTimezones()).map((timezone) => ({
        id: timezone.name,
        value: timezone.name,
      })),
    []
  );

  const getTimezoneLabel = (timezone: string) => {
    const timezoneObj = getTimezone(timezone);
    if (timezoneObj) {
      return `(UTC${timezoneObj.utcOffsetStr}) ${timezone}`;
    }
    return timezone;
  };

  if (reportId && !reportFetched) {
    return <PageLoaderFull isActive />;
  }

  return (
    <MultiSectionLayout
      className="bg-gray-100"
      header={
        <HeaderBreadcrumbs
          items={[
            {
              title: t({
                id: "scheduled-reports-page.auto-reports",
                defaultMessage: "Auto Reports",
              }),
              onClick: () => navigateToPage("reportsScheduled"),
            },
            {
              title: reportId
                ? t({
                    id: "scheduled-reports-page.edit-schedule-report",
                    defaultMessage: "Edit Scheduled Report",
                  })
                : t({
                    id: "scheduled-reports-page.edit-scheduled-report",
                    defaultMessage: "Schedule Report",
                  }),
            },
          ]}
        />
      }
    >
      <PageLoaderFull isActive={isLoading || isUpdateLoading} isTransparent />
      <div className="flex min-h-full w-full items-center justify-center bg-white p-20">
        <div className="flex w-full max-w-[973px] flex-col gap-14">
          <div className="flex flex-col items-center">
            <ReportIcon />
            <span className="text-dark text-2xl font-medium leading-7">
              {t({
                id: "scheduled-reports-page.scheduled-auto-report",
                defaultMessage: "Scheduled Auto Report",
              })}
            </span>
            <span className="text-sm leading-3 text-[#828282]">
              {t({
                id: "scheduled-reports-page.fill-report-form-request",
                defaultMessage:
                  "Please, select the report type, fill in the email structure and select time",
              })}{" "}
              🚀
            </span>
          </div>
          <div className="flex w-full flex-col gap-[18px]">
            <div className="flex items-center gap-2.5">
              <span className="bg-primary flex h-[18px] w-[18px] items-center justify-center rounded-lg text-[10px] font-semibold text-white">
                1
              </span>
              <span className="text-sm font-medium text-[#1A2023]">
                {t({
                  id: "scheduled-reports-page.select-report-type",
                  defaultMessage: "Select Report Type",
                })}
              </span>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-grey text-xs">
                {t({
                  id: "scheduled-reports-page.report-name",
                  defaultMessage: "Report name",
                })}
              </span>
              <TextField
                isFullWidth
                inputClassName="bg-transparent"
                {...register("name", {
                  required: true,
                  validate: (value) => !!value.trim(),
                })}
                errorMessage={errors.name && " "}
              />
            </div>
            <div className="flex items-center gap-8 overflow-x-auto">
              {reportsList.map((el) => (
                <div
                  className="flex w-[303px] shrink-0 flex-col gap-4 overflow-hidden rounded-lg bg-white pb-6 drop-shadow-[rgba(0_1px_4px_rgba(0,0,0,0.12))]"
                  key={el.id}
                >
                  <InteractiveContainer
                    className="h-[90px] w-full overflow-hidden"
                    onClick={() =>
                      previewFile({
                        name: `${el.title} - ${t({
                          id: "common.example",
                          defaultMessage: "Example",
                        })}.png`,
                        url: el.image,
                        mimeType: "image/png",
                      })
                    }
                  >
                    <img
                      src={el.image}
                      className="h-full w-full object-cover"
                      alt="Report Example"
                    />
                  </InteractiveContainer>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="chartId"
                    render={({ field: { onChange, value } }) => (
                      <InteractiveContainer
                        className="flex flex-col gap-2 px-3"
                        onClick={() => onChange(el.value)}
                      >
                        <div className="flex items-center justify-between gap-2">
                          <span className="text-dark font-medium leading-6">
                            {el.title}
                          </span>
                          <Radio isChecked={el.value === value} readOnly />
                        </div>
                        <span className="text-sm leading-3 text-[#828282]">
                          {el.description}
                        </span>
                      </InteractiveContainer>
                    )}
                  />
                </div>
              ))}
            </div>
            <div className="flex items-center gap-2.5">
              <span className="bg-primary flex h-[18px] w-[18px] items-center justify-center rounded-lg text-[10px] font-semibold text-white">
                2
              </span>
              <span className="text-sm font-medium text-[#1A2023]">
                {t({
                  id: "scheduled-reports-page.set-email-details",
                  defaultMessage: "Set Email details",
                })}
              </span>
            </div>
            <div className="bg-grey-100 flex flex-col gap-10 rounded-lg p-6">
              <div
                className={cx(
                  "flex items-center gap-2.5 border-0 border-b border-solid pb-2",
                  errors.recipients ? "border-tertiary-300" : "border-dark-200"
                )}
              >
                <span className="text-sm text-[#828282]">
                  {t({
                    id: "scheduled-reports-page.recipients",
                    defaultMessage: "Recipients",
                  })}
                  :
                </span>
                <EmailsInput
                  control={control}
                  register={register}
                  errors={errors}
                />
              </div>
              <div className="flex flex-col gap-2">
                <span className="text-sm text-[#828282]">
                  {t({
                    id: "scheduled-reports-page.body-text",
                    defaultMessage: "Body Text",
                  })}
                  :
                </span>
                <textarea
                  {...textareaProps}
                  rows={6}
                  className={cx(
                    "text-dark resize-none rounded-2xl rounded-tl-none border border-solid p-4 font-[Roboto] text-base font-normal outline-none placeholder:text-[#828282]",
                    errors.description
                      ? "border-tertiary-300"
                      : "border-dark-200"
                  )}
                  placeholder={t({
                    id: "common.enter",
                    defaultMessage: "Enter",
                  })}
                  {...register("description", {
                    required: true,
                    validate: (value) => !!value.trim(),
                  })}
                />
              </div>
            </div>
            <div className="flex w-full flex-col gap-[30px]">
              <div className="flex items-center gap-2.5">
                <span className="bg-primary flex h-[18px] w-[18px] items-center justify-center rounded-lg text-[10px] font-semibold text-white">
                  3
                </span>
                <span className="text-sm font-medium text-[#1A2023]">
                  {t({
                    id: "scheduled-reports-page.set-timing",
                    defaultMessage: "Set Timing",
                  })}
                </span>
              </div>
              <Controller
                control={control}
                rules={{ required: true }}
                name="crontab"
                render={({ field }) => (
                  <CrontabPicker {...omit(field, ["ref"])} />
                )}
              />
              <div className="flex flex-col gap-2">
                <span className="text-sm text-[#828282]">
                  {t({
                    id: "scheduled-reports-page.timezone",
                    defaultMessage: "Timezone",
                  })}
                  :
                </span>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="timezone"
                  render={({ field: { value, onChange } }) => (
                    <ResourcePickerPopover
                      maxVisibleItems={5}
                      title=""
                      renderTrigger={({ Trigger, triggerRef, isOpen }) => (
                        <Trigger
                          ref={triggerRef}
                          as={InteractiveContainer}
                          className="bg-grey-100 flex w-max min-w-[180px] items-center justify-between gap-4 rounded-lg p-4 outline-none"
                        >
                          <span className="text-sm text-[#1A2023]">
                            {getTimezoneLabel(value)}
                          </span>
                          <ArrowIcon
                            className={cx(
                              isOpen && "rotate-180 transition-transform"
                            )}
                          />
                        </Trigger>
                      )}
                      selectionBehavior={{ canToggle: false, mode: "single" }}
                      placement="bottom"
                      items={timezoneItems}
                      renderLabel={({ value: timezone }) =>
                        getTimezoneLabel(timezone)
                      }
                      defaultSelectedIds={[value]}
                      onSelect={({ item, onClose: onRequestClose }) => {
                        onChange(item.value);
                        onRequestClose();
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="mx-auto mt-14 w-full max-w-[250px]">
            <Button
              fullWidth
              onClick={reportId ? handleUpdate : handleFormSubmit}
              isDisabled={reportId ? !isDirty : false}
            >
              {t({
                id: "common.save",
                defaultMessage: "Save",
              })}
            </Button>
          </div>
        </div>
      </div>
    </MultiSectionLayout>
  );
};
