import { getUniqueId } from "@jugl-web/utils";
import { SESSION_ID } from "@jugl-web/utils/storage";

export const getSessionId = () => {
  const storedSessionId = sessionStorage.getItem(SESSION_ID);
  if (storedSessionId) {
    return storedSessionId;
  }
  const sessionId = getUniqueId();
  sessionStorage.setItem(SESSION_ID, sessionId);
  return sessionId;
};
