import { EntityModel } from "@jugl-web/rest-api";
import { Avatar } from "@jugl-web/ui-components/cross-platform";
import { FC } from "react";
import Lottie from "react-lottie";
import dangerAnimation from "./assets/danger-animation.json";

export interface EntityAvatarWithDangerIndicatorProps {
  entity: EntityModel | null;
}

export const EntityAvatarWithDangerIndicator: FC<
  EntityAvatarWithDangerIndicatorProps
> = ({ entity }) => (
  <div className="relative">
    <Avatar
      className="h-[150px] w-[150px] border border-solid border-[#F2F2F2]"
      labelClassName="text-[48px]"
      username={entity?.name || ""}
      imageUrl={entity?.logoImg}
    />
    <div className="absolute right-0 bottom-0 flex h-[60px] w-[60px] items-center justify-center rounded-full bg-white">
      <Lottie
        options={{ animationData: dangerAnimation }}
        height={40}
        width={40}
      />
    </div>
  </div>
);
