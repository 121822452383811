/**
 * Checks if a given string is a valid UUID (Universally Unique Identifier).
 *
 * @param {string} str - The string to be checked for UUID validity.
 * @returns {boolean} Returns true if the input string is a valid UUID, otherwise false.
 */
export const isValidUUID = (str: string) => {
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return regexExp.test(str);
};
