import { cx } from "@jugl-web/utils";
import { CSSProperties, FC, ReactNode, useRef } from "react";
import {
  SwitchTransition,
  Transition,
  TransitionStatus,
} from "react-transition-group";

type TransitionType = "fade" | "fade-slide";
type TransitionTime = "fast" | "normal";

interface ScreenTransitionWrapperProps {
  children: ReactNode;
  screenName: string;
  transitionType?: TransitionType;
  transitionTime?: TransitionTime;
  className?: string;
  style?: CSSProperties;
}

const transitionTypeToTransitionStatusToClasses: Record<
  TransitionType,
  Record<TransitionStatus, string>
> = {
  fade: {
    entering: "opacity-0",
    entered: "opacity-100",
    exiting: "opacity-0",
    exited: "opacity-0",
    unmounted: "",
  },
  "fade-slide": {
    entering: "opacity-0 -translate-x-5",
    entered: "opacity-100 translate-x-0",
    exiting: "opacity-0 translate-x-5",
    exited: "opacity-0 translate-x-5",
    unmounted: "",
  },
};

const transitionTimeToDuration: Record<TransitionTime, number> = {
  fast: 100,
  normal: 150,
};

export const ScreenTransitionWrapper: FC<ScreenTransitionWrapperProps> = ({
  children,
  screenName,
  transitionType = "fade-slide",
  transitionTime = "normal",
  className,
  style,
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const transitionDuration = transitionTimeToDuration[transitionTime];

  const transitionStatusToClasses =
    transitionTypeToTransitionStatusToClasses[transitionType];

  return (
    <SwitchTransition>
      <Transition
        key={screenName}
        nodeRef={wrapperRef}
        timeout={transitionDuration}
      >
        {(state) => (
          <div
            ref={wrapperRef}
            className={cx(
              "flex flex-col transition",
              transitionStatusToClasses[state],
              className
            )}
            style={{
              transitionDuration: `${transitionDuration}}ms`,
              ...style,
            }}
          >
            {children}
          </div>
        )}
      </Transition>
    </SwitchTransition>
  );
};
