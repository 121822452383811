import { cx } from "@jugl-web/utils";
import { FC, PropsWithChildren } from "react";

export const SectionTitle: FC<PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => (
  <div
    className={cx(
      "font-secondary text-[26px] font-semibold leading-[150%]",
      className
    )}
  >
    {children}
  </div>
);
