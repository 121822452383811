import { Button } from "@jugl-web/ui-components/cross-platform";
import { Alert } from "@jugl-web/ui-components/cross-platform/Alert";
import { MobileDrawer } from "@jugl-web/ui-components/mobile";
import { useAppVariant, useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import Lottie from "react-lottie";
import hintAnimationData from "./assets/hint-animation.json";

export interface TaskTemplateFoldersHintDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const TaskTemplateFoldersHintDialog: FC<
  TaskTemplateFoldersHintDialogProps
> = ({ isOpen, onClose }) => {
  const { t } = useTranslations();
  const { isMobile } = useAppVariant();

  const title = t({
    id: "template-folders-page.hint-title",
    defaultMessage: "Templates are here to help",
  });

  const image = (
    <Lottie
      options={{ animationData: hintAnimationData }}
      height={200}
      width={200}
    />
  );

  const content = t(
    {
      id: "template-folders-page.hint-content",
      defaultMessage:
        "Use these <b>ready-made templates</b> to create a task for any type of work. You can also create <b>customized templates</b>, cool huh? 😍",
    },
    {
      b: (chunks: (string | JSX.Element)[]) => (
        <span className="text-primary-800 font-semibold">{chunks}</span>
      ),
    }
  );

  if (isMobile) {
    return (
      <MobileDrawer isOpen={isOpen} onClose={onClose}>
        <MobileDrawer.Content className="flex flex-col items-center">
          <div className="my-8">{image}</div>
          <span className="text-dark mb-4 text-xl font-medium leading-5 tracking-[0.2px]">
            {title}
          </span>
          <span className="text-dark-800 mb-12 text-center text-base font-normal leading-[22px] tracking-[0.16px]">
            {content}
          </span>
          <Button
            variant="contained"
            color="primary"
            className="h-10 w-[300px] shrink-0"
            onClick={onClose}
          >
            {t({
              id: "common.okay",
              defaultMessage: "Okay",
            })}
          </Button>
        </MobileDrawer.Content>
      </MobileDrawer>
    );
  }

  return (
    <Alert
      isOpen={isOpen}
      img={image}
      title={title}
      content={content}
      buttons={[
        {
          text: t({
            id: "common.okay",
            defaultMessage: "Okay",
          }),
          color: "primary",
          role: "close",
          className: "w-[200px] mx-auto",
        },
      ]}
      onRequestClose={onClose}
    />
  );
};
