import { cx, useUniqueDOMId } from "@jugl-web/utils";
import { AutoLinkPlugin } from "@lexical/react/LexicalAutoLinkPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { forwardRef, useMemo, useRef } from "react";
import { MaxLengthPlugin } from "./MaxLengthPlugin";
import { MentionsPlugin } from "./MentionsPlugin";
import { RichTextareaControllerPlugin } from "./RichTextareaControllerPlugin";
import { RichTextareaHandle, RichTextareaProps } from "./types";
import { getLexicalConfig, MATCHERS } from "./utils";

export const RichTextarea = forwardRef<RichTextareaHandle, RichTextareaProps>(
  (
    {
      mentions = [],
      mentionListType = "container-pinned",
      isDisabled = false,
      placeholder,
      classes,
      startSlot,
      endSlot,
      containerSlot,
      maxTextLength,
      onFocusStateChange,
      onRawTextChange,
    },
    ref
  ) => {
    const namespace = useUniqueDOMId();

    const containerRef = useRef<HTMLDivElement | null>(null);

    const contentEditableId = `${namespace}-content-editable`;

    const config = useMemo(
      () => getLexicalConfig(namespace, isDisabled),
      [namespace, isDisabled]
    );

    return (
      <LexicalComposer initialConfig={config}>
        <>{startSlot}</>
        <div className={cx("relative", classes?.container)}>
          <div ref={containerRef} />
          <RichTextPlugin
            contentEditable={
              <div className={cx("p-2", classes?.contentEditable)}>
                <ContentEditable
                  id={contentEditableId}
                  className="outline-none"
                />
              </div>
            }
            placeholder={
              placeholder ? (
                <div
                  className={cx(
                    "pointer-events-none absolute left-2 top-2 select-none overflow-hidden",
                    classes?.placeholder
                  )}
                >
                  {placeholder}
                </div>
              ) : null
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          {containerSlot}
        </div>
        <>{endSlot}</>
        <HistoryPlugin />
        <MentionsPlugin
          mentions={mentions}
          listType={mentionListType}
          containerRef={containerRef}
        />
        <RichTextareaControllerPlugin
          handle={ref}
          contentEditableId={contentEditableId}
          onFocusStateChange={onFocusStateChange}
          onRawTextChange={onRawTextChange}
        />
        {maxTextLength ? <MaxLengthPlugin maxTextLength={maxTextLength} /> : ""}
        <ListPlugin />
        <LinkPlugin />
        <AutoLinkPlugin matchers={MATCHERS} />
      </LexicalComposer>
    );
  }
);
