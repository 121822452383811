type CallsSettings = {
  camera_resolution?: "240p_1" | "480p_1" | "720p_1";
  isVideoOn?: boolean;
  isAudioOn?: boolean;
  microphone_id?: string;
  playback_id?: string;
  camera_id?: string;
};

const settingsKey = "calls_devices_settings";
export const useCallsSettings = () => {
  const getCallsSettings = () => {
    const data = localStorage.getItem(settingsKey);
    if (data) {
      try {
        return JSON.parse(data);
      } catch (e) {
        return {};
      }
    }
    return {};
  };

  const setCallsSettings = (settings: CallsSettings) => {
    const newState = { ...getCallsSettings(), ...settings };
    localStorage.setItem(settingsKey, JSON.stringify(newState));
  };

  return {
    getCallsSettings,
    setCallsSettings,
  };
};
