import {
  SelfProducedTaskActivity,
  TaskActivity,
} from "@jugl-web/rest-api/tasks";
import { User } from "@jugl-web/rest-api/users";
import { getUniqueId } from "@jugl-web/utils";
import { ValidTaskActivity } from "./types";

export const isValidActivity = (
  activity: ValidTaskActivity | Record<string, unknown>
): activity is ValidTaskActivity => Object.keys(activity).length > 0;

export const isSelfProducedTaskActivity = (
  validActivity: ValidTaskActivity
): validActivity is SelfProducedTaskActivity =>
  typeof validActivity.action_by === "string";

interface AdaptSelfProducedTaskActivityToTaskActivityOptions {
  selfProducedActivity: SelfProducedTaskActivity;
  taskId: string;
  meUser: User;
}

export const adaptSelfProducedTaskActivityToTaskActivity = ({
  selfProducedActivity,
  taskId,
  meUser,
}: AdaptSelfProducedTaskActivityToTaskActivityOptions): TaskActivity => {
  const nowAsISOString = new Date().toISOString();

  return {
    action_by: {
      user_id: meUser.id,
      first_name: meUser.profile?.first_name || "",
      last_name: meUser.profile?.last_name || "",
      img: meUser.profile?.img || null,
      updated_at: nowAsISOString,
    },
    action_details: selfProducedActivity.action_details,
    id: getUniqueId(),
    inserted_at: nowAsISOString,
    task_id: taskId,
    updated_at: nowAsISOString,
  };
};
