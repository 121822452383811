import React, { useState, useEffect, useMemo } from "react";
import InfiniteScroll from "@web-src/components/InfiniteScroll";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@web-src/store";
import {
  Text,
  TypographyVariant,
} from "@jugl-web/ui-components/cross-platform/Text";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import mime from "mime";
import {
  DriveItemDisplayType,
  DirectoryListItem,
} from "@jugl-web/rest-api/drive/types";
import { useSearchParams } from "react-router-dom";
import { Alert, LoadingSpinner } from "@jugl-web/ui-components/cross-platform";
import { cx, isAPIError, useToast, useTranslations } from "@jugl-web/utils";
import { DisplayImageViewer } from "./components/DisplayImageViewer";
import DriveFile from "./components/DriveFile";
import DriveFolder from "./components/DriveFolder";
import DriveEmptyPage from "./components/DriveEmptyPage";
import { matchesInsensitive } from "./utils";
import { FilePreview } from "./components/FilePreview";
import { useDriveProvider } from "../../providers/DriveProvider";
import restrictedImage from "./assets/restricted.png";
import { DriveError } from "./components/types";

export interface SerializedError {
  name?: string;
  message?: string;
  stack?: string;
  code?: string;
}

export const DisplayFolders: React.FC<{
  search: string;
  display: DriveItemDisplayType;
  onOpenFile?: (file: DirectoryListItem) => void;
  openedFile?: DirectoryListItem | null;
  readOnly?: boolean;
}> = ({ search, display, onOpenFile, openedFile, readOnly }) => {
  const { uploadingFiles, driveHook } = useDriveProvider();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentDirectoryId = searchParams.get("directoryId");
  const dispatch = useDispatch();
  const [noAccessAlertIsOpen, setNoAccessAlertIsOpen] = useState(false);
  const { t } = useTranslations();
  const { toast } = useToast({ variant: "web" });

  const {
    rootDirectory,
    data,
    error,
    isLoading,
    isFetching,
    refetch,
    isRootDirectoryError,
    isRootDirectoryLoading,
  } = driveHook;

  useEffect(() => {
    if (
      error &&
      isAPIError(error) &&
      error.data.errors === DriveError.noAccess
    ) {
      setNoAccessAlertIsOpen(true);
    }
  }, [error, dispatch, rootDirectory?.current_dir_id]);

  useEffect(() => {
    if (error && isAPIError(error) && error.data.errors === "Not Found") {
      toast(
        t({
          id: "drive-page.folder-not-exist",
          defaultMessage: "The folder does not exist",
        }),
        {
          preventDuplicate: true,
          variant: "error",
        }
      );
    }
  }, [error, t, toast, dispatch]);

  const [currentImageId, setCurrentImageId] = useState<string>();
  const visited = useSelector((state: RootState) => state.drive.visited);

  const folders = useMemo(
    () =>
      data
        ? [
            ...new Map(
              data.data.map((element) => [element.id, element])
            ).values(),
          ]
        : [],
    [data]
  );

  const filteredData = useMemo(
    () =>
      search
        ? folders.filter((element) => matchesInsensitive(element.name, search))
        : folders,
    [folders, search]
  );

  const isEmpty = useMemo(() => data?.data.length === 0, [data]);

  const currentDirectoryUploadingFiles = useMemo(
    () => uploadingFiles?.filter((item) => item.dirId === currentDirectoryId),
    [currentDirectoryId, uploadingFiles]
  );

  const $uploadingFiles = currentDirectoryUploadingFiles ? (
    <>
      {currentDirectoryUploadingFiles.map((item, idx) => (
        <div
          className="flex w-full items-center gap-4 rounded-lg bg-white px-2 py-2"
          key={+idx}
        >
          {item.isCompleted && (
            <div className="text-xs font-bold text-green-500">
              {" "}
              {t({
                id: "common.completed",
                defaultMessage: "Completed",
              })}
            </div>
          )}
          {item.isFailed && (
            <div className="text-xs font-bold  text-red-500">
              {" "}
              {t({
                id: "common.failed",
                defaultMessage: "Failed",
              })}
            </div>
          )}
          {!item.isCompleted && !item.isFailed && <LoadingSpinner />}

          <div className="flex items-center gap-5">
            <div className="h-[10px] w-[200px] overflow-hidden rounded bg-gray-200">
              <div
                style={{ width: `${item.progress}%` }}
                className="bg-primary-500 h-full"
              />
            </div>
            <div className="text-gray-500">{item.progress}%</div>
          </div>
          <div>{item.file.name}</div>
        </div>
      ))}
    </>
  ) : null;

  return (
    <>
      <Alert
        isOpen={noAccessAlertIsOpen}
        img={<img src={restrictedImage} alt="Restricted" />}
        title={t({
          id: "feedback.restricted-access",
          defaultMessage: "Restricted access",
        })}
        content={t({
          id: "feedback.private-folder-error",
          defaultMessage:
            "This Folder is private. Contact Admin to get an access to it",
        })}
        buttons={[
          {
            text: t({
              id: "common.okay",
              defaultMessage: "Okay",
            }),
            role: "close",
            color: "primary",
          },
        ]}
        onRequestClose={() => setNoAccessAlertIsOpen(false)}
      />
      {openedFile ? (
        <FilePreview file={openedFile} />
      ) : isLoading || isFetching || isRootDirectoryLoading ? (
        <div className="w-full py-7 text-center">
          <LoadingSpinner />
        </div>
      ) : error || isRootDirectoryError ? (
        <div className="flex w-full flex-col py-7 text-center">
          <Text variant={TypographyVariant.h4} className="text-grey-700 mb-5">
            {t({
              id: "drive-page.failed-to-retrieve-content",
              defaultMessage: "Failed to retrieve folders and files",
            })}
          </Text>
          <Button onClick={refetch} className="self-center">
            {t({
              id: "common.click-to-try-again",
              defaultMessage: "Click to try again",
            })}
          </Button>
        </div>
      ) : isEmpty && currentDirectoryUploadingFiles?.length ? (
        $uploadingFiles
      ) : isEmpty && visited.length === 1 ? (
        <DriveEmptyPage
          title={t({
            id: "drive-page.drive-is-empty",
            defaultMessage: "Your drive is empty",
          })}
          subtitle={t({
            id: "drive-page.start-uploading-files",
            defaultMessage:
              "Start uploading files for easy storage and sharing",
          })}
        />
      ) : isEmpty && visited.length !== 1 ? (
        <DriveEmptyPage
          title={t({
            id: "drive-page.folder-is-empty",
            defaultMessage: "Folder is empty",
          })}
          isMenuVisible={!readOnly}
        />
      ) : filteredData.length === 0 ? (
        <span className="text-grey-800 font-bold">
          {t({
            id: "drive-page.no-results",
            defaultMessage: "No results",
          })}
        </span>
      ) : data ? (
        <>
          <InfiniteScroll
            className={cx(
              "flex flex-row flex-wrap overflow-y-hidden",
              display === DriveItemDisplayType.tiles ? "gap-5" : "gap-2.5"
            )}
            disabled
          >
            {$uploadingFiles}
            {filteredData.map((element) =>
              element.type === "dir" ? (
                <DriveFolder
                  key={element.id}
                  id={element.id}
                  search={search}
                  display={display}
                  name={element.name}
                  documents={element.doc_count || 0}
                  allowedUsers={
                    element?.permission?.mode === "private"
                      ? element.permission.users
                      : []
                  }
                  accessType={element?.permission?.mode}
                  type={element.type}
                  size={element.size || 0}
                  meta={element.meta}
                  shortUrl={element.short_url}
                />
              ) : (
                <DriveFile
                  key={element.id}
                  id={element.id}
                  search={search}
                  display={display}
                  name={element.name}
                  link={element.preview_url as string}
                  ext={`.${mime.getExtension(element.mime_type)}` || ""}
                  date={element.updated_at}
                  size={element.size}
                  mime={element.mime_type}
                  previewImg={
                    element.preview_available ? element.preview_url : undefined
                  }
                  handleOpen={() => {
                    onOpenFile?.(element);
                    setSearchParams(
                      new URLSearchParams({
                        directoryId: visited[visited.length - 1]?.id,
                        directoryName: visited[visited.length - 1]?.name,
                        openedFile: element.id,
                      })
                    );
                  }}
                  type={element.type}
                  readOnly={readOnly}
                  shortUrl={element.short_url}
                />
              )
            )}
          </InfiniteScroll>
          {!!currentImageId && (
            <DisplayImageViewer
              data={filteredData || null}
              isOpen={!!currentImageId}
              onClose={() => setCurrentImageId(undefined)}
              currentImageId={currentImageId}
            />
          )}
        </>
      ) : null}
    </>
  );
};
