import { useFilePreview } from "@jugl-web/domain-resources/files/providers/FilePreviewProvider";
import {
  TaskChecklist,
  TaskChecklistHandle,
  getDueDateInDays,
  taskNameValidator,
  templateChecklistItemAdapters,
} from "@jugl-web/domain-resources/tasks";
import { DescriptionBox } from "@jugl-web/domain-resources/tasks/components/DescriptionBox";
import {
  TaskAttachments,
  TaskAttachmentsHandle,
} from "@jugl-web/domain-resources/tasks/components/TaskAttachments";
import { adaptFilesToTaskAttachmentsComponentItems } from "@jugl-web/domain-resources/tasks/components/TaskAttachments/adapters";
import { TaskAttachmentsUploadScreen } from "@jugl-web/domain-resources/tasks/components/TaskAttachmentsUploadScreen";
import { TaskExtraPropertiesPanel } from "@jugl-web/domain-resources/tasks/components/TaskExtraPropertiesPanel";
import { TaskPropertiesPanel } from "@jugl-web/domain-resources/tasks/components/TaskPropertiesPanel";
import { TitleBox } from "@jugl-web/domain-resources/tasks/components/TitleBox";
import { useCreateTask } from "@jugl-web/domain-resources/tasks/hooks/useCreateTask";
import { useLocalTaskChecklistHandlers } from "@jugl-web/domain-resources/tasks/hooks/useLocalTaskChecklistHandlers";
import { useTaskFormState } from "@jugl-web/domain-resources/tasks/hooks/useTaskFormState";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { TaskAttachment } from "@jugl-web/rest-api/tasks";
import { Alert, Dialog } from "@jugl-web/ui-components/cross-platform";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { getUniqueId, useTranslations } from "@jugl-web/utils";
import { useAppVariant, useToast } from "@jugl-web/utils/hooks";
import { TaskFormDialog } from "@web-src/features/tasks/TaskFormDialog";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useFCM } from "@web-src/modules/notifications/providers/FCMProvider";
import { useOnboarding } from "@web-src/modules/preferences/providers";
import { useTasksPageContext } from "../TasksPageContext/TasksPageContext";

export interface NewTaskDialogProps {
  entityId: string;
  meId: string;
}

export const NewTaskDialog: FC<NewTaskDialogProps> = ({ entityId, meId }) => {
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [isLeaveConfirmationDialogOpen, setIsLeaveConfirmationDialogOpen] =
    useState(false);

  const [createdTaskId, setCreatedTaskId] = useState<string>();

  const { taskListMode, newTaskDialogState, closeNewTaskDialog } =
    useTasksPageContext();

  const { isOpen, initialFormState, taskPropertiesPanelConfigOverrides } =
    newTaskDialogState;

  const { tasksTemplatesApi } = useRestApiProvider();
  const { variant } = useAppVariant();
  const { toast } = useToast({ variant });

  const { navigateToPage } = useNavigation();

  const { createTask, showTaskCreationToast, isLoading } = useCreateTask({
    entityId,
    isInTeamTasksContext: taskListMode === "team",
    onNavigate: (taskId) => navigateToPage("tasksDetails", { taskId }),
  });

  const [createTemplate] = tasksTemplatesApi.useCreateTemplateMutation();

  const $titleTextareaRef = useRef<HTMLTextAreaElement | null>(null);
  const $taskChecklistRef = useRef<TaskChecklistHandle | null>(null);
  const $taskAttachmentsRef = useRef<TaskAttachmentsHandle | null>(null);

  const {
    formState,
    updateFormState,
    updateChecklistItems,
    resetFormState,
    isDirty,
  } = useTaskFormState();

  const taskChecklistHandlers = useLocalTaskChecklistHandlers({
    itemsSetter: updateChecklistItems,
  });

  const { t } = useTranslations();
  const { logEvent } = useFCM();

  const { isOpen: isFilePreviewOpen, previewFile } = useFilePreview();
  const { completeOnboardingStep, isOnboardingActive } = useOnboarding();

  useEffect(() => {
    if (!initialFormState) {
      return;
    }

    resetFormState(initialFormState);
  }, [initialFormState, resetFormState]);

  const isValid = taskNameValidator(formState.title);
  const isDescriptionBoxVisible = formState.description || isEditingDescription;

  const adaptedAttachments = useMemo(
    () =>
      formState.attachments &&
      adaptFilesToTaskAttachmentsComponentItems(formState.attachments),
    [formState.attachments]
  );

  const handleSubmit = () => {
    const runTaskCreation = async () => {
      try {
        const createdTask = await createTask(formState);

        if (formState.attachments.length > 0) {
          setCreatedTaskId(createdTask.id);
          setIsUploadDialogOpen(true);
        } else {
          showTaskCreationToast(createdTask.id);
          closeNewTaskDialog();
          if (isOnboardingActive) {
            completeOnboardingStep("task");
          }
        }
        logEvent("action_task_create_send");
      } catch {
        // Do nothing
      }
    };

    const runTemplateCreation = async () => {
      const response = await createTemplate({
        entityId,
        template: {
          name: formState.title,
          due_in: formState.dueDate
            ? getDueDateInDays(formState.dueDate)
            : null,
          checklist: formState.checklistItems.map(
            templateChecklistItemAdapters.toBackendModel
          ),
          priority: formState.priority,
          desc: formState.description,
          label_id: formState.labelId || undefined,
          folder_id: formState.saveToTemplateFolder
            ? formState.saveToTemplateFolder.id
            : undefined,
          custom_fields: formState.customFields,
        },
      });

      if ("data" in response) {
        toast(
          t({
            id: "feedback.template-created",
            defaultMessage: "Template has been created",
          })
        );
      }
    };

    runTaskCreation();

    if (formState.saveToTemplateFolder) {
      runTemplateCreation();
    }
  };

  const handleSafeClose = () => {
    if (isFilePreviewOpen || isUploadDialogOpen) {
      return;
    }
    if (isDirty() || initialFormState) {
      setIsLeaveConfirmationDialogOpen(true);
      return;
    }
    closeNewTaskDialog();
  };

  const handlePreviewAttachment = (attachment: TaskAttachment) =>
    previewFile({
      url: attachment.stream_url,
      name: attachment.name,
      mimeType: attachment.content_type,
    });

  const handleFilesAdd = (files: File[]) => {
    if (!files) return;

    const filesWithId = files.map((file) => ({
      id: getUniqueId(),
      file,
    }));
    updateFormState("attachments", formState.attachments.concat(filesWithId));
  };

  const handleFileRename = (file: TaskAttachment, newName: string) => {
    const attachmentsNewState = formState.attachments.map((item) => {
      if (item.id === file.id) {
        return {
          ...item,
          file: new File([item.file], newName, { type: item.file.type }),
        };
      }
      return item;
    });
    updateFormState("attachments", attachmentsNewState);
  };

  const handleFileRemove = (id: string) => {
    const attachmentsNewState = formState.attachments.filter(
      (item) => item.id !== id
    );
    updateFormState("attachments", [...attachmentsNewState]);
  };

  return (
    <>
      <Dialog
        isOpen={isUploadDialogOpen}
        onClose={() => {}}
        className="w-[480px]"
      >
        <TaskAttachmentsUploadScreen
          taskId={createdTaskId || ""}
          entityId={entityId}
          files={formState.attachments}
          onClose={() => {
            setIsUploadDialogOpen(false);
            resetFormState();
            closeNewTaskDialog();
            if (isOnboardingActive) {
              completeOnboardingStep("task");
            }
          }}
        />
      </Dialog>
      <TaskFormDialog
        header={{
          type: "title",
          title: t({
            id: "tasks-page.create-task",
            defaultMessage: "Create task",
          }),
        }}
        isOpen={isOpen && !isUploadDialogOpen}
        initialFocus={$titleTextareaRef}
        onClose={handleSafeClose}
      >
        <TaskFormDialog.Content>
          <TaskFormDialog.TitleSection>
            <TitleBox
              title={formState.title}
              textareaRef={$titleTextareaRef}
              classes={{ titleText: "py-3" }}
              onInternalValueChange={(title) => updateFormState("title", title)}
            />
            {isDescriptionBoxVisible && (
              <DescriptionBox
                description={formState.description}
                isEditing={isEditingDescription}
                classes={{ editingBox: "mt-2", descriptionText: "mt-2 py-2" }}
                onChange={(value) => updateFormState("description", value)}
                onStartEditing={() => setIsEditingDescription(true)}
                onFinishEditing={() => {
                  setIsEditingDescription(false);
                }}
              />
            )}
            <TaskPropertiesPanel
              entityId={entityId}
              config={{
                description: {
                  isHidden: isEditingDescription || !!formState.description,
                  onClick: () => setIsEditingDescription(true),
                },
                status: {
                  statusId: formState.statusId,
                  onChange: (value) => updateFormState("statusId", value),
                  hideCompleted: true,
                },
                dueDate: {
                  date: formState.dueDate,
                  displayAs: "date",
                  onChange: (dueDate) => updateFormState("dueDate", dueDate),
                  ...taskPropertiesPanelConfigOverrides?.dueDate,
                },
                assignees: {
                  assigneeIds: formState.assigneeIds,
                  onlyReportees: taskListMode === "team",
                  onChange: (assigneeIds) =>
                    updateFormState("assigneeIds", assigneeIds),
                  isHidden: formState.isPrivate,
                  ...taskPropertiesPanelConfigOverrides?.assignees,
                },
                priority: {
                  priority: formState.priority,
                  onChange: (priority) => updateFormState("priority", priority),
                },
                label: {
                  labelId: formState.labelId,
                  onChange: (labelId) => updateFormState("labelId", labelId),
                },
                customer: {
                  customerId: formState.customer?.id || null,
                  onChange: (customer) => updateFormState("customer", customer),
                },
                attachments: {
                  count: formState.attachments.length,
                  onClick: () => $taskAttachmentsRef.current?.openFilePicker(),
                },
                customFields: {
                  onFieldChange: (fieldId, rawValue) =>
                    updateFormState("customFields", {
                      ...formState.customFields,
                      [fieldId]: rawValue,
                    }),
                  valuesById: formState.customFields,
                },
              }}
              className="mt-8"
            />
            <TaskExtraPropertiesPanel
              entityId={entityId}
              config={{
                privateTask: {
                  type: "interactive",
                  isChecked: formState.isPrivate,
                  isHidden: taskListMode === "team",
                  onChange: (isChecked) =>
                    updateFormState("isPrivate", isChecked),
                },
                saveTaskAsTemplate: {
                  templateFolder: formState.saveToTemplateFolder,
                  onTemplateFolderChange: (folder) =>
                    updateFormState("saveToTemplateFolder", folder),
                },
                allowAssigneesEdit: {
                  isChecked: formState.allowAssigneesEdit,
                  onChange: (isChecked) =>
                    updateFormState("allowAssigneesEdit", isChecked),
                  isHidden: formState.isPrivate,
                },
                completeChecklistInOrder: {
                  isChecked: formState.completeChecklistInOrder,
                  onChange: (isChecked) =>
                    updateFormState("completeChecklistInOrder", isChecked),
                },
              }}
              className="mt-8"
            />
          </TaskFormDialog.TitleSection>
          <TaskFormDialog.ChecklistSection
            className="grow-[unset]"
            onAddItem={() => $taskChecklistRef.current?.addItem()}
          >
            <TaskChecklist
              ref={$taskChecklistRef}
              entityId={entityId}
              meId={meId}
              items={formState.checklistItems}
              isCompletable={false}
              isAssignable={!formState.isPrivate}
              onlyReportees={taskListMode === "team"}
              {...taskChecklistHandlers}
            />
          </TaskFormDialog.ChecklistSection>
          <TaskFormDialog.AttachmentsSection
            onAddAttachment={() =>
              $taskAttachmentsRef.current?.openFilePicker()
            }
          >
            <TaskAttachments
              mode="controlled"
              ref={$taskAttachmentsRef}
              attachments={adaptedAttachments}
              entityId={entityId}
              onPreviewAttachment={handlePreviewAttachment}
              onFileAdded={handleFilesAdd}
              onAttachmentRename={handleFileRename}
              onAttachmentRemove={handleFileRemove}
            />
          </TaskFormDialog.AttachmentsSection>
        </TaskFormDialog.Content>
        <TaskFormDialog.Actions>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            isDisabled={!isValid || isLoading}
            className="w-[200px]"
            onClick={handleSubmit}
          >
            {t({ id: "tasks-page.create-task", defaultMessage: "Create task" })}
          </Button>
        </TaskFormDialog.Actions>
      </TaskFormDialog>
      <Alert
        isOpen={isLeaveConfirmationDialogOpen}
        title={t({
          id: "tasks-page.discard-changes-warning-title",
          defaultMessage: "Discard changes?",
        })}
        content={t({
          id: "tasks-page.discard-changes-description",
          defaultMessage: "If you discard, entered info will not be saved",
        })}
        buttons={[
          {
            text: t({ id: "common.cancel", defaultMessage: "Cancel" }),
            role: "close",
          },
          {
            text: t({ id: "common.discard", defaultMessage: "Discard" }),
            color: "primary",
            onClick: (_, actions) => {
              closeNewTaskDialog();
              resetFormState();
              actions.closeAlert();
            },
          },
        ]}
        onRequestClose={() => setIsLeaveConfirmationDialogOpen(false)}
      />
    </>
  );
};
