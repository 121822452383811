import React, { useState, useMemo } from "react";
import { useTranslations } from "@jugl-web/utils";
import {
  Checkbox,
  InteractiveContainer,
} from "@jugl-web/ui-components/cross-platform";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";

export type SearchableCheckboxListItem = {
  id: string;
  value: string;
  label: string;
  count?: number;
};

const SearchableCheckboxList: React.FC<{
  name: string;
  items: SearchableCheckboxListItem[];
  showSelectAll?: boolean;
  showSearch: boolean;
  isSelected: (item: string | "all") => boolean;
  onSelect: (checked: boolean, items: SearchableCheckboxListItem[]) => void;
}> = ({
  name,
  items,
  showSelectAll = true,
  isSelected,
  onSelect,
  showSearch,
}) => {
  const [query, setQuery] = useState("");
  const { t } = useTranslations();

  const filteredItems = useMemo(
    () =>
      query.length
        ? items.filter((item) =>
            item.label.toLowerCase().includes(query.toLowerCase())
          )
        : items,
    [query, items]
  );

  const handleSelection = (
    checked: boolean,
    item: SearchableCheckboxListItem | "all"
  ) => {
    if (item === "all") {
      onSelect(checked, [...items]);
    } else {
      onSelect(checked, [item]);
    }
  };

  return (
    <>
      {showSearch && (
        <SearchInput
          variant="filled"
          color="grey"
          value={query}
          onChange={(e) => setQuery(e.currentTarget.value)}
          placeholder={t(
            {
              id: "searchable-checkbox-list-component.search",
              defaultMessage: "Search {username}",
            },
            {
              username: name,
            }
          )}
        />
      )}
      <div className="mt-5 flex flex-col gap-2">
        {showSelectAll && (
          <InteractiveContainer
            className="text-primary flex w-max items-center gap-2 text-sm font-medium"
            onClick={() => handleSelection(!isSelected("all"), "all")}
          >
            <Checkbox isChecked={isSelected("all")} readOnly />
            {t({
              id: "common.select-all",
              defaultMessage: "Select All",
            })}
          </InteractiveContainer>
        )}
        {filteredItems.map((item) => {
          const isChecked = isSelected(item.value);
          return (
            <InteractiveContainer
              className="flex w-max items-center gap-2 text-sm font-medium"
              onClick={() => handleSelection(!isChecked, item)}
              key={item.id}
            >
              <Checkbox isChecked={isChecked} readOnly />
              {item.label}
              {item.count && <span className="text-grey"> ({item.count})</span>}
            </InteractiveContainer>
          );
        })}
      </div>
    </>
  );
};

export default SearchableCheckboxList;
