import { Button } from "@jugl-web/ui-components/cross-platform";
import { FC } from "react";
import Lottie from "react-lottie";
import { useTranslations } from "@jugl-web/utils";
import { MobileDrawer } from "@jugl-web/ui-components/mobile";
import lockAnimation from "./assets/lock-animation.json";

export interface PrivateTaskHintDialogProps {
  isOpen: boolean;
  isInTaskCreationContext: boolean;
  onRequestClose: () => void;
}

export const PrivateTaskHintDialog: FC<PrivateTaskHintDialogProps> = ({
  isOpen,
  isInTaskCreationContext,
  onRequestClose,
}) => {
  const { t } = useTranslations();

  return (
    <MobileDrawer
      isOpen={isOpen}
      onClose={onRequestClose}
      className="py-8 px-4"
    >
      <div className="flex flex-col items-center gap-4 text-center">
        <Lottie
          options={{ animationData: lockAnimation }}
          height={180}
          width={180}
        />
        <div className="text-dark font-secondary text-xl font-medium">
          {t({
            id: "tasks-page.private-task",
            defaultMessage: "Private Task",
          })}
        </div>
        <div className="text-dark text-base leading-4">
          {!isInTaskCreationContext
            ? t(
                {
                  id: "tasks-page.private-task-hint-presentational",
                  defaultMessage:
                    "You are the <b>One and Only Assignee</b> of this Task. No one, including your Manager, can't see Private Tasks 🙈",
                },
                {
                  b: (chunks: (string | JSX.Element)[]) => (
                    <span className="text-primary-800 font-semibold">
                      {chunks}
                    </span>
                  ),
                }
              )
            : t(
                {
                  id: "tasks-page.private-task-hint",
                  defaultMessage:
                    "You will become <b>One and Only Assignee</b> of this Task. No one, including your Manager, can't see Private Tasks 🙈",
                },
                {
                  b: (chunks: (string | JSX.Element)[]) => (
                    <span className="text-primary-800 font-semibold">
                      {chunks}
                    </span>
                  ),
                }
              )}
        </div>
        <div className="my-8 w-full px-8">
          <Button onClick={onRequestClose} fullWidth>
            {t({ id: "common.okay", defaultMessage: "Okay" })}
          </Button>
        </div>
      </div>
    </MobileDrawer>
  );
};
