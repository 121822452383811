import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot, $getSelection } from "lexical";
import { FC, ForwardedRef, useEffect, useImperativeHandle } from "react";
import { RichTextareaHandle } from "./types";

export interface RichTextareaControllerPluginProps {
  handle: ForwardedRef<RichTextareaHandle>;
  contentEditableId: string;
  onFocusStateChange?: (isFocused: boolean) => void;
  onRawTextChange?: (rawText: string) => void;
}

export const RichTextareaControllerPlugin: FC<
  RichTextareaControllerPluginProps
> = ({ handle, contentEditableId, onFocusStateChange, onRawTextChange }) => {
  const [editor] = useLexicalComposerContext();

  useImperativeHandle(handle, () => ({
    saveToHTML: () =>
      editor.getEditorState().read(() => $generateHtmlFromNodes(editor)),
    readFromHTML: (stringifiedHTML) => {
      editor.update(() => {
        const parser = new DOMParser();
        const dom = parser.parseFromString(stringifiedHTML, "text/html");
        const nodes = $generateNodesFromDOM(editor, dom);
        const root = $getRoot();
        root.clear();
        root.append(...nodes);
      });
    },
    focus: () => {
      document.getElementById(contentEditableId)?.focus();
    },
    setEditable: (isEditable) => {
      editor.setEditable(isEditable);
    },
    insertTextAtSelection: (text) => {
      editor.update(() => {
        const selection = $getSelection();
        selection?.insertText(text);
      });
    },
  }));

  useEffect(() => {
    const contentEditableEl = document.getElementById(contentEditableId);

    if (!contentEditableEl) {
      return undefined;
    }

    const onFocus = () => onFocusStateChange?.(true);
    const onBlur = () => onFocusStateChange?.(false);

    contentEditableEl.addEventListener("focus", onFocus);
    contentEditableEl.addEventListener("blur", onBlur);

    return () => {
      contentEditableEl.removeEventListener("focus", onFocus);
      contentEditableEl.removeEventListener("blur", onBlur);
    };
  }, [contentEditableId, onFocusStateChange]);

  useEffect(() => {
    if (!onRawTextChange) {
      return undefined;
    }

    const unsubscribe = editor.registerTextContentListener(onRawTextChange);

    return unsubscribe;
  }, [editor, onRawTextChange]);

  return null;
};
