import ZipIcon from "@web-src/images/icons/zip-icon.svg";
import TextIcon from "@web-src/images/icons/text-icon.svg";
import PdfIcon from "@web-src/images/icons/pdf-icon.svg";
import DocIcon from "@web-src/images/icons/doc-icon.svg";
import AudioIcon from "@web-src/images/icons/audio-icon.svg";
import Mp4Icon from "./icons/mp4.svg";
import FolderNormalIcon from "./icons/folder.svg";
import PublicFolder from "./icons/publicFolder.svg";
import PrivateFolder from "./icons/privateFolder.svg";
import ExcelIcon from "./icons/excel.svg";
import OtherFilesIcon from "./icons/otherFiles.svg";
import SysCpanelFolderIcon from "./icons/sys-cpanel-folder.svg";

export type FilePermissionType = "public" | "private" | "shared";

export const displayIcon = (
  icon: string,
  typeFolder?: FilePermissionType | false,
  params?: {
    isSystem?: boolean;
  }
) => {
  switch (icon) {
    case "folder":
      if (params?.isSystem) return SysCpanelFolderIcon;
      if (typeFolder === "shared") return FolderNormalIcon;
      if (typeFolder === "private") return PrivateFolder;
      return PublicFolder;
    case ".txt":
      return TextIcon;
    case ".zip":
    case ".rar":
      return ZipIcon;
    case ".pdf":
      return PdfIcon;
    case ".doc":
    case ".docx":
      return DocIcon;
    case ".xls":
    case ".xlsx":
      return ExcelIcon;
    case ".mp3":
    case ".wav":
    case ".flac":
    case ".mp4a":
      return AudioIcon;
    case ".mp4":
    case ".mov":
    case ".webm":
    case ".avi":
    case ".mkv":
    case ".wmv":
    case ".qt":
      return Mp4Icon;
    default:
      return OtherFilesIcon;
  }
};
