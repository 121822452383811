import {
  InteractiveContainer,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { assert, cx, useTranslations } from "@jugl-web/utils";
import { format, isFuture, isPast } from "date-fns";
import {
  FC,
  FocusEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ReactComponent as RemoveIcon } from "../../assets/remove.svg";

const isPM = (date: Date) => date.getHours() >= 12;

const convertAMTimeStringToDate = (AMTimeString: string, baseDate: Date) => {
  // eslint-disable-next-line prefer-const
  let [hours, minutes] = AMTimeString.split(":").map(Number);

  const isBaseDatePM = isPM(baseDate);

  if (isBaseDatePM && hours < 12) {
    hours += 12;
  } else if (!isBaseDatePM && hours === 12) {
    hours = 0;
  }

  const newDate = new Date(baseDate);
  newDate.setHours(hours, minutes, 0, 0);

  return newDate;
};

interface DueTimeInputProps {
  selectedDate: Date | undefined;
  onDateChange: (date: Date) => void;
  onClear: () => void;
}

export const DueTimeInput: FC<DueTimeInputProps> = ({
  selectedDate,
  onDateChange,
  onClear,
}) => {
  const [value, setValue] = useState("12:00");
  const [isEditing, setIsEditing] = useState(false);

  const { t } = useTranslations();

  const isSelectedDateInThePast = selectedDate ? isPast(selectedDate) : false;
  const isInputDisabled = !selectedDate || isSelectedDateInThePast;
  const isDatePM = selectedDate ? isPM(selectedDate) : false;

  const resetInputValue = useCallback(() => {
    if (selectedDate) {
      setValue(format(selectedDate, "hh:mm"));
    }
  }, [selectedDate]);

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    assert(!!selectedDate, "Selected date must be defined");

    try {
      if (event.target.validity.valid) {
        const newDate = convertAMTimeStringToDate(value, selectedDate);

        if (!isFuture(newDate)) {
          throw new Error("Date must be in the future");
        }

        onDateChange(newDate);
      } else {
        throw new Error("Invalid time format");
      }
    } catch {
      resetInputValue();
    } finally {
      setIsEditing(false);
    }
  };

  const getDateWithOppositeMeridiem = useCallback(() => {
    assert(!!selectedDate, "Selected date must be defined");

    const newDate = new Date(selectedDate);
    newDate.setHours(selectedDate.getHours() + (isDatePM ? -12 : 12));

    return newDate;
  }, [isDatePM, selectedDate]);

  const canToggleMeridiem = useMemo(
    () => !!selectedDate && isFuture(getDateWithOppositeMeridiem()),
    [getDateWithOppositeMeridiem, selectedDate]
  );

  const handleToggleMeridiem = () => {
    onDateChange(getDateWithOppositeMeridiem());
  };

  useEffect(() => {
    resetInputValue();
  }, [selectedDate, resetInputValue]);

  return (
    <InteractiveContainer
      isDisabled={isInputDisabled}
      className={cx(
        "flex h-10 w-full items-center justify-between gap-1 rounded-md px-4 transition-colors",
        isInputDisabled
          ? "bg-grey-200 text-dark-600 cursor-not-allowed"
          : "bg-primary-50 text-dark-800",
        isEditing && "outline-primary outline outline-1"
      )}
      onClick={() => setIsEditing(true)}
    >
      <div className="flex items-center gap-1">
        <span>{t({ id: "tasks-page.time", defaultMessage: "Time" })}:</span>
        {isEditing ? (
          <input
            autoFocus
            type="text"
            className="text-dark-800 font-primary placeholder:text-grey-700 m-0 w-[40px] border-none bg-transparent p-0 text-base outline-none"
            pattern="^(0?[1-9]|1[0-2]):[0-5]?[0-9]$"
            placeholder="09:00"
            required
            value={value}
            onChange={(event) => setValue(event.target.value)}
            onBlur={handleBlur}
          />
        ) : (
          <span className="w-[40px]">
            {selectedDate ? format(selectedDate, "hh:mm") : "--"}
          </span>
        )}
        {selectedDate && (
          <button
            type="button"
            disabled={isInputDisabled || !canToggleMeridiem}
            className="text-dark-800 font-primary disabled:text-dark-600 cursor-pointer border-none bg-transparent p-0 text-base outline-none disabled:cursor-not-allowed"
            onClick={(event) => {
              event.stopPropagation();
              handleToggleMeridiem();
            }}
          >
            {format(selectedDate, "a")}
          </button>
        )}
      </div>
      {selectedDate && (
        <PlainButton
          onClick={(event) => {
            event.stopPropagation();
            onClear();
          }}
        >
          <RemoveIcon />
        </PlainButton>
      )}
    </InteractiveContainer>
  );
};
