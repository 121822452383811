import { FC, ReactNode, Children, isValidElement, cloneElement } from "react";

const MENTIONS_REGEXP = /@<([^>]+?)>\[([^\]]+?)\]/g;

// userId might be useful in the future
const mentionRenderer = (_userId: string, username: string) => (
  <span className="text-primary-500 font-bold">{username}</span>
);

const mentionifyString = (text: string) => {
  const parts = text.split(MENTIONS_REGEXP);

  return parts.map((part, index) => {
    // check if index matches the first captured group (userId)
    if (index % 3 === 1) {
      const [userId, username] = [parts[index], parts[index + 1]];

      return mentionRenderer(userId, username);
    }

    // skip the second captured group (username) index as it was already
    // processed in the previous iteration
    if (index % 3 === 2) {
      return null;
    }

    return part;
  });
};

const mentionifyNode = (node: ReactNode) => {
  if (typeof node === "string") {
    return mentionifyString(node);
  }

  // traverse children of the node recursively
  if (isValidElement(node)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const nodeChildren: any = node.props.children
      ? Children.map(node.props.children, mentionifyNode)
      : node.props.children;

    return cloneElement(node, {}, nodeChildren);
  }

  if (node === null || node === undefined) {
    return node;
  }

  return node.toString();
};

export interface MentionifyProps {
  children: ReactNode;
}

export const Mentionify: FC<MentionifyProps> = ({ children }) => (
  <>{Children.map(children, mentionifyNode)}</>
);
