import React from "react";
import { cx } from "@jugl-web/utils";

export const UsersGrid: React.FC<{
  usersCount: number;
  isScreenSharing: boolean;
  isScreenSharingFullScreen: boolean;
  children: React.ReactNode;
}> = ({ usersCount, isScreenSharing, children, isScreenSharingFullScreen }) => (
  <div
    className={cx(
      !isScreenSharing && {
        "grid gap-x-8": usersCount > 1,
        "grid-cols-2": usersCount === 2,
        "grid-cols-3": usersCount === 3,
        "grid-cols-6 grid-rows-2 gap-y-8":
          usersCount === 4 || usersCount === 5 || usersCount === 6,
        "grid-cols-8 grid-rows-2 gap-y-8": usersCount === 7 || usersCount === 8,
        "gap-y-6 sm:grid-cols-8 sm:grid-rows-2 sm:content-center sm:items-stretch md:grid-cols-8 md:grid-rows-2 lg:grid-cols-8 lg:grid-rows-2 xl:grid-cols-8 xl:grid-rows-2 2xl:grid-cols-8 2xl:grid-rows-3":
          usersCount > 8,
      },
      isScreenSharing && {
        "hsm:gap-y-2 hxs:gap-y-2 relative grid max-h-[calc(100vh-220px)] grid-cols-1 grid-rows-3 items-start gap-y-6":
          !isScreenSharingFullScreen,
      },
      {
        "h-full w-full": !isScreenSharing,
      }
    )}
  >
    {children}
  </div>
);
