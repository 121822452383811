import {
  ImageWithFallback,
  Menu,
  MenuItem,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { isoToLocalDate, useTranslations } from "@jugl-web/utils";
import format from "date-fns/format";
import React, { useMemo } from "react";
import { UserFile } from "@jugl-web/rest-api";
import mime from "mime";
import { isImageType } from "@web-src/utils/helper";
import { displayIcon } from "@web-src/utils/displayIcon";
import { getFileSizeLabel } from "@jugl-web/utils/utils/files";
import { ReactComponent as DownloadIcon } from "./icons/download.svg";
import { ReactComponent as RenameIcon } from "./icons/rename.svg";
import { ReactComponent as VerticalDotsIcon } from "../../icons/vertical-dots.svg";
import { ReactComponent as DeleteIcon } from "../../icons/delete.svg";

export const FileItem: React.FC<{
  item: UserFile;
  onDelete: (file: UserFile) => void;
  onRename: (file: UserFile) => void;
  onDownload: (file: UserFile) => void;
}> = ({ item, onDelete, onRename, onDownload }) => {
  const { t } = useTranslations();
  const menuItems = [
    {
      id: "rename",
      icon: <RenameIcon />,
      label: t({
        id: "cpanel-page.rename",
        defaultMessage: "Rename",
      }),
      onSelect: (e, close) => {
        onRename(item);
        close();
      },
    },
    {
      id: "download",
      icon: <DownloadIcon />,
      label: t({
        id: "cpanel-page.download",
        defaultMessage: "Download",
      }),
      onSelect: (e, close) => {
        onDownload(item);
        close();
      },
    },
    {
      id: "delete",
      icon: <DeleteIcon />,
      label: t({
        id: "cpanel-page.delete",
        defaultMessage: "Delete",
      }),
      onSelect: (e, close) => {
        onDelete(item);
        close();
      },
    },
  ] as MenuItem[];

  const fileExtension = useMemo(
    () => mime.getExtension(item.content_type),
    [item.content_type]
  );

  return (
    <div className="bg-grey-100 my-2 flex items-center gap-4 rounded-xl py-4 px-6">
      <div className="flex h-10 w-10 shrink-0 items-center justify-center overflow-hidden rounded-md">
        {item.preview_available || isImageType(item.content_type) ? (
          <ImageWithFallback
            src={item.preview_url ?? ""}
            className="h-full w-full object-cover"
            alt=""
          />
        ) : (
          <img
            src={displayIcon(
              fileExtension ? `.${fileExtension.toLowerCase()}` : ""
            )}
            className="h-full w-full"
            alt=""
          />
        )}
      </div>
      <div className="flex grow flex-col overflow-hidden">
        <span className="text-dark truncate font-medium">{item.name}</span>
        <span className="text-grey text-sm font-medium">
          {format(isoToLocalDate(item.updated_at), "MMM dd, hh:mm a")} •{" "}
          {getFileSizeLabel(item.size)}
        </span>
      </div>
      <Menu
        sections={[menuItems]}
        placement="right"
        renderTrigger={({ Trigger, triggerRef }) => (
          <Trigger as={PlainButton} ref={triggerRef}>
            <VerticalDotsIcon />
          </Trigger>
        )}
      />
    </div>
  );
};
