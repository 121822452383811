import React, { forwardRef, useImperativeHandle, useState } from "react";
import Box from "@mui/material/Box";
import MicNoneRoundedIcon from "@mui/icons-material/MicNoneRounded";

interface RecordingVisualizerHandle {
  requestUpdate: (currentVolume: number) => void;
}

const RecordingVisualizer = forwardRef<RecordingVisualizerHandle>(
  (props, ref) => {
    const [volume, setVolume] = useState(0);
    useImperativeHandle(ref, () => ({
      requestUpdate: (currentVolume: number) => {
        setVolume(currentVolume);
      },
    }));
    return (
      <Box
        sx={{
          width: "40px",
          height: "40px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "30px",
            height: "30px",
            top: "5px",
            left: "5px",
            bgcolor: "juglPrimary.900",
            borderRadius: "15px",
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              transition: "transform .3s",
              position: "absolute",
              width: "100%",
              height: "100%",
              bgcolor: "juglPrimary.100",
              borderRadius: "15px",
              transform: `scale(${(1 + volume / 100).toFixed(1)})`,
              zIndex: -1,
            }}
          />
          <Box
            sx={{
              transition: "transform .3s",
              position: "absolute",
              width: "100%",
              height: "100%",
              bgcolor: "juglPrimary.300",
              borderRadius: "15px",
              transform: `scale(${(1 + volume / 100 / 2).toFixed(1)})`,
              zIndex: -1,
            }}
          />
          <Box
            sx={{
              transition: "transform .3s",
              position: "absolute",
              width: "100%",
              height: "100%",
              bgcolor: "juglPrimary.500",
              borderRadius: "15px",
              transform: `scale(${(1 + volume / 100 / 3).toFixed(1)})`,
              zIndex: -1,
            }}
          />
          <MicNoneRoundedIcon sx={{ color: "juglWhite", width: "20px" }} />
        </Box>
      </Box>
    );
  }
);

export default RecordingVisualizer;
