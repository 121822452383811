import { useRestApiProvider } from "@jugl-web/rest-api";
import { TaskAttachment } from "@jugl-web/rest-api/tasks";
import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform/InteractiveContainer";
import { cx, useAppVariant } from "@jugl-web/utils";
import { forwardRef, useMemo } from "react";
import {
  ControlledProps,
  TaskAttachmentsHandle,
  TaskAttachmentsUpload,
  UncontrolledProps,
} from "./TaskAttachmentsUpload";
import { ReactComponent as AttachmentIcon } from "./assets/attachment.svg";
import { TaskAttachmentItem } from "./components/TaskAttachmentItem";

type TaskAttachmentProps = {
  attachments: TaskAttachment[];
  entityId: string;
  isEditable?: boolean;
  searchQuery?: string;
  emptyContent?: React.ReactNode;
  className?: string;
  onPreviewAttachment: (attachment: TaskAttachment) => void;
  onAddAttachmentTileClick?: () => void;
} & (ControlledProps | UncontrolledProps);

export const TaskAttachments = forwardRef<
  TaskAttachmentsHandle,
  TaskAttachmentProps
>(
  (
    {
      attachments,
      entityId,
      isEditable = true,
      emptyContent,
      searchQuery,
      className,
      onPreviewAttachment,
      onAddAttachmentTileClick,
      ...modeSpecificProps
    },
    ref
  ) => {
    const { tasksApi } = useRestApiProvider();
    const { variant } = useAppVariant();

    const [deleteTaskAttachment] = tasksApi.useDeleteTaskAttachmentMutation();
    const [renameTaskAttachment] = tasksApi.useRenameTaskAttachmentMutation();

    const validAttachments = useMemo(
      () => attachments.filter((attachment) => attachment.status === "valid"),
      [attachments]
    );

    const shouldShowAddAttachmentTile =
      !!onAddAttachmentTileClick && isEditable;

    const attachmentTilesCount =
      validAttachments.length + (shouldShowAddAttachmentTile ? 1 : 0);

    const handleRenameAttachment = (
      attachment: TaskAttachment,
      name: string
    ) => {
      if (modeSpecificProps.mode === "uncontrolled") {
        renameTaskAttachment({
          entityId,
          taskId: modeSpecificProps.taskId,
          attachmentId: attachment.id,
          attachmentName: name,
        });
      }
      if (modeSpecificProps.mode === "controlled") {
        modeSpecificProps.onAttachmentRename?.(attachment, name);
      }
    };

    const handleAttachmentRemove = (attachmentId: string) => {
      if (modeSpecificProps.mode === "uncontrolled") {
        deleteTaskAttachment({
          entityId,
          taskId: modeSpecificProps.taskId,
          attachmentId,
        });
      }
      if (modeSpecificProps.mode === "controlled") {
        modeSpecificProps.onAttachmentRemove?.(attachmentId);
      }
    };

    return (
      <>
        {attachmentTilesCount > 0 ? (
          <div
            className={cx(
              "gap-4",
              variant === "mobile"
                ? "mx-auto grid grid-cols-2"
                : "flex flex-wrap",
              className
            )}
          >
            {shouldShowAddAttachmentTile && (
              <InteractiveContainer
                className="bg-grey-100 hover:bg-grey-200 flex h-full min-h-[142px] w-[168px] shrink-0 items-center justify-center rounded-xl transition-colors"
                onClick={onAddAttachmentTileClick}
              >
                <div className="flex flex-col items-center gap-2">
                  <AttachmentIcon />
                  <span className="font-secondary text-dark text-xs leading-[140%]">
                    Tap to add
                    <br />
                    Attachment
                  </span>
                </div>
              </InteractiveContainer>
            )}
            {validAttachments.map((attachment) => (
              <TaskAttachmentItem
                key={attachment.id}
                highlightedText={searchQuery}
                attachment={attachment}
                isEditable={isEditable}
                onRename={(name) => handleRenameAttachment(attachment, name)}
                onRemove={() => handleAttachmentRemove(attachment.id)}
                onPreview={() => onPreviewAttachment(attachment)}
              />
            ))}
          </div>
        ) : (
          emptyContent
        )}
        <TaskAttachmentsUpload
          ref={ref}
          entityId={entityId}
          {...modeSpecificProps}
        />
      </>
    );
  }
);
