import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslations, cx } from "@jugl-web/utils";
import {
  PhoenixSocketContext,
  PhxResponse,
} from "@web-src/features/chats/providers/PheonixSocket";
import {
  ChatMessagePayloadCallAction,
  ChatMessagePayloadPushType,
  ChatMessagePayloadType,
  ChatMessageType,
  ChatMessage,
  PheonixPushAction,
} from "@web-src/features/chats/types";
import { getEntityUserDisplayName } from "@web-src/features/entities/utils";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { Text } from "@jugl-web/ui-components/cross-platform";
import UserProfileName from "@web-src/features/users/components/UserProfileName";
import useEntity from "@web-src/features/app/hooks/useEntity";
import RemoteUserCallWindow from "../RemoteUserCallWindow/RemoteUserCallWindow";
import UserCallWindow from "../UserCallWindow";
import LocalUserCallWindow from "../LocalUserCallWindow";
import MinimizedCall from "../MinimizedCall";
import { CallsContext } from "../../../../providers";
import ControlPanel from "../ControlPanel";
import { Header } from "./components/Header/Header";
import { CallAvatar } from "../CallAvatar";
import { ReactComponent as DeclinedIcon } from "./assets/declined.svg";

export const IndividualCallScreen: React.FC = () => {
  const { me } = useMe();
  const { t } = useTranslations();
  const {
    activeCall,
    leaveCall,
    activeCallProps,
    callStage,
    setCallStage,
    incomingCall,
    callMsgId,
  } = useContext(CallsContext);
  const { incomingMessages$, channel } = useContext(PhoenixSocketContext);
  const [duration, setDuration] = useState(0);
  const [ringingDuration, setRingingDuration] = useState(0);
  const [userJoined, setUserJoined] = useState(false);
  const [isScreenMinimized, setIsScreenMinimized] = useState<boolean>(
    !activeCallProps?.video
  );
  const [isSwitchToVideoMsgSent, setIsSwitchToVideoMsgSent] = useState(false);
  const { participantsMap } = useEntity();
  const [participantId, setParticipantId] = useState<string | undefined>();

  const callString = activeCallProps?.isIncomingCall
    ? `Incoming ${activeCallProps?.video ? "Video" : "Voice"} Call..`
    : `Outgoing ${activeCallProps?.video ? "Video" : "Voice"} Call..`;

  useEffect(() => {
    if (!activeCallProps?.channel || !channel || !userJoined) {
      return undefined;
    }
    const callUpdateRefId = channel.on(
      "update_call",
      (message: ChatMessage) => {
        if (message.payload?.call_action === "call_hold") {
          setCallStage?.("onhold");
        }
        if (message.payload?.call_action === "call_un_hold") {
          setCallStage?.("call");
        }
      }
    );
    return () => {
      if (activeCallProps?.channel) {
        return;
      }
      channel.off("update_call", callUpdateRefId);
    };
  }, [
    activeCall,
    activeCallProps?.channel,
    channel,
    leaveCall,
    setCallStage,
    userJoined,
  ]);

  useEffect(() => {
    if (activeCall?.localVideoTrack) {
      activeCall?.localVideoTrack.play("localplayer", { fit: "cover" });
    }
    return () => activeCall?.localVideoTrack?.stop();
  }, [
    activeCall?.localVideoTrack,
    activeCall?.videoEnabled,
    isScreenMinimized,
  ]);

  useEffect(() => {
    let durationInterval: NodeJS.Timeout;
    let ringingInterval: NodeJS.Timeout;
    if (userJoined && callStage !== "onhold") {
      durationInterval = setInterval(() => {
        setDuration((prevDuration) => prevDuration + 1);
      }, 1000);
    }
    if (!userJoined && callStage === "ringing") {
      ringingInterval = setInterval(() => {
        setRingingDuration((prevDuration) => prevDuration + 1);
      }, 1000);
    }
    if (ringingDuration > 39 && !userJoined && callStage === "ringing") {
      setCallStage?.("missed");
      leaveCall?.();
      setRingingDuration(0);
    }
    return () => {
      clearInterval(durationInterval);
      clearInterval(ringingInterval);
    };
  }, [
    duration,
    userJoined,
    leaveCall,
    ringingDuration,
    callStage,
    setCallStage,
  ]);
  useEffect(() => {
    let ringingSoundInterval: NodeJS.Timeout;
    if (activeCallProps?.to) {
      setParticipantId(activeCallProps?.to);
    }
    if (!userJoined && callStage === "ringing") {
      ringingSoundInterval = setInterval(() => {
        new Audio("/call.mp3").play();
      }, 2500);
    }
    return () => {
      clearInterval(ringingSoundInterval);
    };
  }, [activeCallProps?.to, callStage, userJoined]);
  useEffect(() => {
    if (activeCall?.remoteUsers?.length && !userJoined) {
      setDuration(0);
      setUserJoined(true);
      setIsScreenMinimized(!activeCallProps?.video);
      setCallStage?.("call");
      channel?.push(PheonixPushAction.update_call, {
        to: activeCallProps?.to || "",
        msg_id: callMsgId,
        payload: {
          body: callString,
          call_type: callString,
          call_action: ChatMessagePayloadCallAction.call_answered,
          call_channel: activeCallProps?.channel,
          push_type: ChatMessagePayloadPushType.silent,
          sender_name: (me && getEntityUserDisplayName(me)) || undefined,
          title: "New message",
          type: ChatMessagePayloadType.call,
        },
        type: ChatMessageType.call,
      });
    }
    if (!activeCall?.remoteUsers?.length && userJoined) {
      leaveCall?.();
    }
  }, [
    activeCall?.remoteUsers,
    activeCallProps?.channel,
    activeCallProps?.to,
    activeCallProps?.video,
    callMsgId,
    callString,
    channel,
    leaveCall,
    me,
    setCallStage,
    userJoined,
  ]);
  useEffect(() => {
    const phxCallResponse = channel?.on(
      "phx_call",
      (message: PhxResponse<ChatMessage>) => {
        if (message.response.from !== activeCallProps?.to) {
          return;
        }
        if (
          message?.response.payload?.call_action ===
          ChatMessagePayloadCallAction.call_declined
        ) {
          setIsScreenMinimized(true);
          leaveCall?.();
          setCallStage?.("declined");
        }
        if (
          message?.response.payload?.call_action ===
          ChatMessagePayloadCallAction.call_duration
        ) {
          leaveCall?.();
          setCallStage?.("off");
        }
      }
    );
    const incomingMessagesSubscription = incomingMessages$?.subscribe(
      (message) => {
        const isFromMe = message.from === me?.id;
        if (
          isFromMe &&
          message?.payload?.call_action ===
            ChatMessagePayloadCallAction.call_invite
        ) {
          const isVideoCall = message?.payload?.call_type
            ?.toLowerCase()
            .includes("video");
          setIsScreenMinimized(!isVideoCall);
        }
      }
    );
    return () => {
      incomingMessagesSubscription?.unsubscribe();
      channel?.off("phx_call", phxCallResponse);
    };
  }, [
    activeCallProps?.to,
    incomingMessages$,
    leaveCall,
    setCallStage,
    me?.id,
    channel,
  ]);

  const formattedDuration =
    duration > 0
      ? `${Math.floor(duration / 60)
          .toString()
          .padStart(2, "0")}:${(duration % 60).toString().padStart(2, "0")}`
      : "00:00";

  const handleToggleVideo = () => {
    if (!activeCall?.videoEnabled && !isSwitchToVideoMsgSent) {
      channel?.push(PheonixPushAction.update_call, {
        to: activeCallProps?.to || "",
        msg_id: callMsgId,
        payload: {
          body: activeCallProps?.isIncomingCall
            ? "Incoming Video Call.."
            : "Outgoing Video Call..",
          call_type: activeCallProps?.isIncomingCall
            ? "Incoming Video Call.."
            : "Outgoing Video Call..",
          call_action: ChatMessagePayloadCallAction.call_switch_voice_to_video,
          call_channel: activeCallProps?.channel,
          push_type: ChatMessagePayloadPushType.silent,
          sender_name: (me && getEntityUserDisplayName(me)) || undefined,
          title: "New message",
          type: ChatMessagePayloadType.call,
        },
        type: ChatMessageType.call,
      });
      setIsSwitchToVideoMsgSent(true);
    }
    activeCall?.toggleVideo();
  };

  const formatDuration = useCallback((totalSeconds: number): string => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = (totalSeconds % 3600) % 60;

    const formattedHours = hours > 0 ? `${hours}h ` : "";
    const formattedMinutes =
      minutes > 0 ? `${minutes.toString().padStart(2, "0")}m ` : "";
    const formattedSeconds = `${seconds.toString().padStart(2, "0")}s`;

    return `${formattedHours}${formattedMinutes}${formattedSeconds}`.trim();
  }, []);

  const handleEndCall = () => {
    channel?.push(PheonixPushAction.update_call, {
      to: activeCallProps?.to || "",
      msg_id: callMsgId,
      payload: {
        body: userJoined ? "call ended" : "declined",
        call_type: callString,
        call_action: userJoined
          ? ChatMessagePayloadCallAction.call_duration
          : ChatMessagePayloadCallAction.call_declined,
        call_channel: activeCallProps?.channel,
        push_type: ChatMessagePayloadPushType.silent,
        sender_name: (me && getEntityUserDisplayName(me)) || undefined,
        duration: userJoined ? formatDuration(duration) : undefined,
        title: t({
          id: "common.new-message",
          defaultMessage: "New Message",
        }),
        type: ChatMessagePayloadType.call,
      },
      type: ChatMessageType.call,
    });
    leaveCall?.();
    setCallStage?.("off");
  };
  if (
    callStage === "off" ||
    (incomingCall && callStage !== "call" && callStage !== "onhold")
  ) {
    return null;
  }
  if (isScreenMinimized) {
    const userAvatar = participantsMap[participantId || ""]?.profile?.img;
    const bg = {
      backgroundImage: `url(${userAvatar})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top",
      filter: "brightness(30%) blur(10px)",
    };
    return (
      <MinimizedCall
        toggleMinimized={() => setIsScreenMinimized(!isScreenMinimized)}
        handleEndCall={handleEndCall}
        toggleVideo={handleToggleVideo}
        containerClassName={cx(
          `w-[467px] h-[281px] overflow-hidden bg-[#39393A]`,
          {
            "h-[228px]": callStage === "declined" || callStage === "missed",
          }
        )}
        hideControls={callStage === "declined" || callStage === "missed"}
        showCloseButton={callStage === "declined" || callStage === "missed"}
      >
        <div
          className="absolute inset-0 rounded-xl"
          style={userAvatar ? bg : {}}
        />
        <div className="absolute top-10 left-10 right-0 flex items-center gap-6 rounded-t-xl">
          <CallAvatar
            isSpeaking={false}
            size={80}
            userId={participantId || ""}
          />
          <div className="flex flex-col gap-1">
            <Text variant="body2" className="font-bold text-white">
              <UserProfileName userId={activeCallProps?.to || ""} />
            </Text>
            <Text variant="body3" className="text-primary-200">
              {callStage === "call" && formattedDuration}
              {callStage === "ringing" &&
                t({
                  id: "audio-call-component.ringing",
                  defaultMessage: "Ringing",
                })}
            </Text>
          </div>

          {(activeCall?.remoteUsers?.length && (
            <RemoteUserCallWindow
              user={activeCall.remoteUsers[0]}
              isCall
              duration={formattedDuration}
              containerClassName="rounded-xl"
              profileContainerClassName="top-0 left-0"
              minimized
              hide
            />
          )) ||
            ""}
        </div>
        <div
          className={cx(
            "absolute bottom-8 flex w-full flex-row items-center justify-center gap-2",
            {
              hidden: callStage !== "declined",
            }
          )}
        >
          <Text variant="h4" className="m-0 font-bold text-white">
            {t({
              id: "audio-call-component-call-declined",
              defaultMessage: "Call Declined",
            })}
          </Text>
          <DeclinedIcon />
        </div>
        <div
          className={cx(
            "absolute bottom-8 flex w-full flex-row items-center justify-center gap-2",
            {
              hidden: callStage !== "missed",
            }
          )}
        >
          <Text variant="h4" className="m-0 font-bold text-white">
            {t({
              id: "audio-call-component.no-answer",
              defaultMessage: "No Answer",
            })}
          </Text>
          <DeclinedIcon />
        </div>
      </MinimizedCall>
    );
  }
  return (
    <div className="fixed top-0 left-0 z-20 h-full w-full bg-[#39393A] ">
      <Header
        handleMinimize={() => setIsScreenMinimized(!isScreenMinimized)}
        userJoined={userJoined}
        callIsOnHold={callStage === "onhold"}
        isIncomingCall={activeCallProps?.isIncomingCall || false}
        formattedDuration={formattedDuration}
        callerId={activeCallProps?.to || ""}
      />
      <div className="h-[calc(100%-48px)] px-40 pt-12 pb-[192px]">
        {(activeCall && activeCall.remoteUsers?.length && (
          <RemoteUserCallWindow user={activeCall.remoteUsers[0]} isCall />
        )) || (
          <UserCallWindow
            isSpeaking={false}
            userId={activeCallProps?.to || ""}
            isAudioMuted
            isVideoMuted
            isCall
            isRinging
          />
        )}
      </div>

      <div
        className={cx(
          "max-hsm:scale-[0.6] max-hsm:top-[100px] max-hsm:right-[-32px] absolute top-[200px] right-[32px] z-20 h-[260px] w-[400px]",
          {
            hidden: !activeCall?.videoEnabled,
          }
        )}
      >
        <LocalUserCallWindow isCall duration={formattedDuration} />
      </div>
      <div className="absolute bottom-10 left-1/2 z-20 flex -translate-x-1/2 transform flex-row items-center gap-6">
        <ControlPanel
          isVideoOn={activeCall?.videoEnabled || false}
          isMicOn={activeCall?.audioEnabled || false}
          isScreenShared={activeCall?.screenSharingEnabled || false}
          toggleVideo={handleToggleVideo || undefined}
          toggleMic={activeCall?.toggleAudio || (() => {})}
          toggleScreenShare={activeCall?.toggleScreenShare || (() => {})}
          endCall={handleEndCall}
          optionsToHide={["toggleScreenShare", "toggleMinimize"]}
          toggleMinimize={() => setIsScreenMinimized(!isScreenMinimized)}
          isMinimized={isScreenMinimized}
        />
      </div>
    </div>
  );
};
