import React from "react";
import { Text } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { ReactComponent as EarthLocationIcon } from "./assets/earth-location.svg";

export const NoLocations: React.FC = () => {
  const { t } = useTranslations();
  return (
    <div className="mt-20 flex flex-col content-center items-center justify-center">
      <EarthLocationIcon className="mb-4" />
      <Text variant="body1" className="text-dark-a100 mb-3">
        {t({
          id: "location-page.no-location-info",
          defaultMessage: "No location info yet",
        })}
      </Text>
    </div>
  );
};
