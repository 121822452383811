import { FC } from "react";
import { UserLoginAuthMode } from "@jugl-web/rest-api";
import { ReactComponent as GoogleIcon } from "./assets/google-icon.svg";
import { ReactComponent as LinkedInIcon } from "./assets/linkedin-icon.svg";
import { ReactComponent as OtpIcon } from "./assets/otp-icon.svg";

export const AuthModeIcon: FC<{ authMode: UserLoginAuthMode }> = ({
  authMode,
}) => {
  switch (authMode) {
    case "google":
      return <GoogleIcon />;
    case "linkedin":
      return <LinkedInIcon />;
    case "MobileOTP":
    default:
      return <OtpIcon />;
  }
};
