import { useEffect, useState } from "react";

export const useTabIsActive = () => {
  const [isActive, setIsActive] = useState(!document.hidden);

  useEffect(() => {
    const callback = () => {
      setIsActive(!document.hidden);
    };

    document.addEventListener("visibilitychange", callback);

    return () => {
      document.removeEventListener("visibilitychange", callback);
    };
  }, []);

  return isActive;
};
