import {
  Alert,
  Avatar,
  InteractiveContainer,
} from "@jugl-web/ui-components/cross-platform";
import { cx } from "@jugl-web/utils";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { ReactComponent as ChatsIcon } from "./assets/chats-icon.svg";
import { ReactComponent as DriveIcon } from "./assets/drive-icon.svg";
import { ReactComponent as TasksIcon } from "./assets/tasks-icon.svg";
import { ReactComponent as StarIcon } from "./assets/star-icon.svg";

export type CancelSubscriptionAlertProps = {
  isOpen: boolean;
  onRequestClose: () => void;
};

export const CancelSubscriptionAlert: FC<CancelSubscriptionAlertProps> = ({
  isOpen,
  onRequestClose,
}) => {
  const { entity, refetchSubscriptionInfo } = useEntitySelectedProvider();
  const [reasonConfirmed, setReasonConfirmed] = useState(false);
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);

  const { entitiesApi } = useRestApiProvider();
  const [cancelSubscription, { isLoading: isSubscriptionCancelLoading }] =
    entitiesApi.useCancelSubscriptionMutation();

  const handleCancelSubscription = async () => {
    try {
      await cancelSubscription({
        entityId: entity.id,
        data: {
          entity_name: entity.name,
          reason: selectedReasons.join(", "),
        },
      }).unwrap();
      await refetchSubscriptionInfo();
      onRequestClose();
    } catch (error) {
      // ignore
    }
  };

  useEffect(() => {
    if (isOpen) {
      return;
    }
    if (!isOpen && reasonConfirmed) {
      setReasonConfirmed(false);
    }
    if (!isOpen && setSelectedReasons.length) {
      setSelectedReasons([]);
    }
  }, [isOpen, reasonConfirmed]);

  const toggleReason = useCallback(
    (reason: string) => {
      if (selectedReasons.includes(reason)) {
        setSelectedReasons((prev) => prev.filter((item) => item !== reason));
      } else {
        setSelectedReasons((prev) => [...prev, reason]);
      }
    },
    [selectedReasons]
  );

  const predefinedReasons = useMemo(
    () => [
      "Too Expensive",
      "Missing Features",
      "Don't like it",
      "I Don't Use It Daily",
      "Difficult To Use",
      "Other",
    ],
    []
  );

  const featuresToLose = useMemo(
    () => [
      { title: "Task Management environment", icon: <TasksIcon /> },
      { title: "Chats with your Mates", icon: <ChatsIcon /> },
      { title: "Team Workspace storage", icon: <DriveIcon /> },
      { title: "And other 10+ great features", icon: <StarIcon /> },
    ],
    []
  );

  const $content = useMemo(() => {
    if (!reasonConfirmed) {
      return (
        <div>
          <div className="mb-14 flex flex-col justify-center gap-6">
            <div className="text-center">
              You&apos;re about to cancel your subscription for:
            </div>
            <div>
              <div
                className="inline-flex items-center justify-center gap-2 rounded-xl bg-[#F2F2F4] py-2 px-4"
                style={{ flex: 0 }}
              >
                <Avatar
                  size="xl"
                  className="shrink-0"
                  username={entity?.name || ""}
                  imageUrl={entity?.logoImg}
                />
                <div>{entity.name}</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center gap-4">
            <div>
              Please share the reason you want to cancel the Subscription 🙏
            </div>
            <div className="flex flex-wrap justify-center gap-2">
              {predefinedReasons.map((item) => (
                <InteractiveContainer
                  onClick={() => toggleReason(item)}
                  key={item}
                  className={cx(
                    "rounded-xl px-4 py-2.5 text-center",
                    selectedReasons.includes(item)
                      ? "bg-[#DE5996] text-white"
                      : "bg-[#FFF2F8]"
                  )}
                >
                  {item}
                </InteractiveContainer>
              ))}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="flex flex-col items-center gap-7">
        <div className="font-secondary text-center text-[20px] font-medium leading-[28px] text-[#1A2023]">
          We are sad to say Goodbye to You 🥹
        </div>
        <div className="flex flex-1 flex-col gap-4 px-10">
          <div className="text-primary-900">
            If you continue, you will loose access to:
          </div>
          <div className="flex flex-col gap-3 px-2">
            {featuresToLose.map((item, idx) => (
              <div className="flex items-center gap-3" key={+idx}>
                {item.icon}
                <div>{item.title}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }, [
    entity?.logoImg,
    entity.name,
    featuresToLose,
    predefinedReasons,
    reasonConfirmed,
    selectedReasons,
    toggleReason,
  ]);

  return (
    <Alert
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      header="Cancel Subscription"
      content={$content}
      isCloseButtonVisible={!isSubscriptionCancelLoading}
      disableBackdropClose={isSubscriptionCancelLoading}
      buttons={[
        {
          text: "Go Back",
          color: "grey",
          isDisabled: isSubscriptionCancelLoading,
          onClick: reasonConfirmed
            ? () => setReasonConfirmed(false)
            : onRequestClose,
        },
        {
          text: reasonConfirmed ? "Confirm and Cancel" : "Continue",
          onClick: reasonConfirmed
            ? handleCancelSubscription
            : () => setReasonConfirmed(true),
          isDisabled: !selectedReasons.length || isSubscriptionCancelLoading,
        },
      ]}
    />
  );
};
