import { getTextWithoutSpacesAndNewLines } from "@jugl-web/utils";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { trimTextContentFromAnchor } from "@lexical/selection";
import { $restoreEditorState } from "@lexical/utils";
import {
  $getSelection,
  $isRangeSelection,
  EditorState,
  RootNode,
} from "lexical";
import { useEffect } from "react";

export const MaxLengthPlugin: React.FC<{ maxTextLength: number }> = ({
  maxTextLength,
}) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    let lastRestoredEditorState: EditorState | null = null;

    return editor.registerNodeTransform(RootNode, (rootNode: RootNode) => {
      const selection = $getSelection();
      if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
        return;
      }
      const prevEditorState = editor.getEditorState();
      const prevTextContentSize = prevEditorState.read(
        () => getTextWithoutSpacesAndNewLines(rootNode.getTextContent()).length
      );
      const textContentSize = getTextWithoutSpacesAndNewLines(
        rootNode.getTextContent()
      ).length;
      if (prevTextContentSize !== textContentSize) {
        const delCount = textContentSize - maxTextLength;
        const { anchor } = selection;
        if (delCount > 0) {
          if (
            prevTextContentSize === maxTextLength &&
            lastRestoredEditorState !== prevEditorState
          ) {
            lastRestoredEditorState = prevEditorState;
            $restoreEditorState(editor, prevEditorState);
          } else {
            trimTextContentFromAnchor(editor, anchor, delCount);
          }
        }
      }
    });
  }, [editor, maxTextLength]);

  return null;
};
