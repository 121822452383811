import {
  Avatar,
  ConfirmationPopup,
  EmptyListContent,
  Text,
} from "@jugl-web/ui-components/cross-platform";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { HeaderBreadcrumbs, Table } from "@jugl-web/ui-components/web";
import { useMarkModuleAsRead } from "@web-src/hooks/useMarkModuleAsRead";
import React, { useCallback, useMemo, useState } from "react";

import { ModuleNotificationsDialog } from "@jugl-web/domain-resources/module-notifications/components/ModuleNotificationsDialog";
import { useModuleNotificationsUnreadIndicator } from "@jugl-web/domain-resources/module-notifications/hooks/useModuleNotificationsUnreadIndicator";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { Customer } from "@jugl-web/rest-api/customer";
import { Menu } from "@jugl-web/ui-components/cross-platform/Menu";
import {
  convertSnakeCaseToReadableString,
  useToast,
  useTranslations,
} from "@jugl-web/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { NotificationsButton } from "@web-src/components/NotificationsButton";
import useEntity from "@web-src/features/app/hooks/useEntity";
import Highlighter from "react-highlight-words";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { customerFieldDescriptorsById } from "./components/CustomerFormField";
import { ManageCustomerDialog } from "./components/ManageCustomerDialog";
import { CustomizeFieldsDialog } from "./components/CustomizeFieldsDialog/CustomizeFieldsDialog";
import { ReactComponent as AddIcon } from "./assets/add.svg";
import { ReactComponent as CustomersIcon } from "./assets/customers.svg";
import { ReactComponent as EditIcon } from "./assets/edit.svg";
import noCustomersImage from "./assets/no-customers.png";
import { ReactComponent as RemoveIcon } from "./assets/remove-bin.svg";
import { ReactComponent as TableHeaderIcon } from "./assets/table-icon.svg";
import { ReactComponent as ThreeDotsIcon } from "./assets/three-dots.svg";

const CustomersPage: React.FC = () => {
  const { customersApi, customersFormFieldApi } = useRestApiProvider();
  const [isRemoveCustomerModalOpen, setRemoveCustomerModalOpen] =
    useState<boolean>(false);
  const [clientToRemove, setClientToRemove] = useState<string>();
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [isNotificationsDialogOpen, setIsNotificationsDialogOpen] =
    useState(false);
  const [clientToEdit, setClientToEdit] = useState<string>();
  const [page, setPage] = useState<number>(1);
  const [pageRows, setPageRows] = useState<number>(20);
  const [searchQuery, setSearchQuery] = useState<string>();
  const { entity } = useEntity();
  const [isCustomizeFieldsOpen, setCustomizeFieldsOpen] =
    useState<boolean>(false);
  const { t } = useTranslations();
  const [deleteCustomer] = customersApi.useDeleteCustomerMutation();
  const { data: formFields } = customersFormFieldApi.useFormFieldsQuery(
    entity?.id ? { entityId: entity.id } : skipToken
  );
  const { data: customersList } = customersApi.useCustomersListQuery(
    entity?.id
      ? {
          entityId: entity.id,
          params: {
            page,
            page_size: pageRows,
            search_term: searchQuery || undefined,
          },
        }
      : skipToken
  );
  const fixLink = (link: string) => {
    if (!link.startsWith("http://") && !link.startsWith("https://")) {
      return `https://${link}`;
    }
    return link;
  };
  const { toast } = useToast({ variant: "web" });

  const columns = useMemo(() => {
    const filteredColumns = formFields?.data?.filter((field) => {
      if (
        field.name === "first_name" ||
        field.name === "last_name" ||
        field.name === "position"
      ) {
        if (field.is_cust_field) {
          return field;
        }
        return null;
      }
      return field;
    });
    const data = filteredColumns
      ?.map((field) => ({
        id: field.id,
        title: customerFieldDescriptorsById[field.name]
          ? t(customerFieldDescriptorsById[field.name])
          : convertSnakeCaseToReadableString(field.name),
        section: field.section,
        pos: field.pos,
      }))
      .sort((a, b) => {
        const sectionComparison = b.section.localeCompare(a.section);

        if (sectionComparison === 0) {
          return a.pos - b.pos;
        }

        return sectionComparison;
      });
    return (
      (Array.isArray(data) &&
        [
          {
            id: "menu",
            title: t({
              id: "customers-page.menu",
              defaultMessage: "Menu",
            }),
          },
          {
            id: "name_position",
            title: t({
              id: "customers-page.name-and-position",
              defaultMessage: "Name & Positition",
            }),
          },
        ].concat(data)) ||
      []
    );
  }, [formFields, t]);
  const headerFormatter = (id: string, title: string, columnIdx: number) => {
    if (columnIdx === 0) {
      return (
        <TableHeaderIcon
          className="cursor-pointer"
          onClick={() => {
            setCustomizeFieldsOpen(true);
          }}
        />
      );
    }
    return title;
  };
  const handleDeleteCustomer = async (customerId: string) => {
    const entityId = entity?.id || "";
    const response = await deleteCustomer({ entityId, customerId });
    if ("data" in response) {
      toast(
        t({
          id: "feedback.deleted-customer",
          defaultMessage: "Client has been deleted",
        })
      );
    }
  };
  const getNameAndPosition = useCallback((row: Customer) => {
    const nonCustomFields = row.fields.filter(
      (item) => !item.field.is_cust_field
    );
    const firstName = nonCustomFields.find(
      (item) => item.field.name === "first_name"
    );
    const lastName = nonCustomFields.find(
      (item) => item.field.name === "last_name"
    );
    const position = nonCustomFields.find(
      (item) => item.field.name === "position"
    );

    const fullName = `${firstName?.value || ""} ${lastName?.value || ""}`;
    const positionStr = `${position?.value || "-"}`;
    return { fullName, positionStr };
  }, []);
  const rowFormatter = useCallback(
    (row: Customer) => (
      <>
        {columns.map((column, index) => {
          const columnField = row.fields?.find(
            (customerFields) => customerFields.field.id === column.id
          );
          const columnParams = formFields?.data?.find(
            (col) => col.id === column.id
          );
          const nameAndPosition = getNameAndPosition(row);
          if (index === 1) {
            return (
              <th>
                <div className="flex flex-row items-center justify-start gap-4">
                  <div className="w-[32px]">
                    <Avatar
                      size="lg"
                      username={nameAndPosition.fullName}
                      imageUrl={row.logo}
                      className="h-[32px] w-[32px]"
                    />
                  </div>
                  <div className="flex flex-col">
                    {" "}
                    <Text variant="body2">
                      <Highlighter
                        autoEscape
                        highlightClassName="bg-primary-200"
                        searchWords={[searchQuery || ""]}
                        textToHighlight={nameAndPosition?.fullName}
                      />
                    </Text>
                    <Text variant="body3" className="text-primary">
                      <Highlighter
                        autoEscape
                        highlightClassName="bg-primary-200"
                        searchWords={[searchQuery || ""]}
                        textToHighlight={nameAndPosition?.positionStr}
                      />
                    </Text>
                  </div>
                </div>
              </th>
            );
          }

          if (index === 0) {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <th key={index}>
                <Menu
                  placement="bottom"
                  autoClose
                  sections={[
                    [
                      {
                        id: "1",
                        label: t({
                          id: "common.edit",
                          defaultMessage: "Edit",
                        }),
                        icon: <EditIcon />,
                        onSelect: () => {
                          setClientToEdit(row.id);
                          setSidebarOpen(true);
                        },
                      },
                      {
                        id: "2",
                        label: t({
                          id: "common.delete",
                          defaultMessage: "Delete",
                        }),
                        icon: <RemoveIcon />,
                        onSelect: () => {
                          setClientToRemove(row.id);
                          setRemoveCustomerModalOpen(true);
                        },
                      },
                    ],
                  ]}
                  renderTrigger={({ Trigger, triggerRef }) => (
                    <Trigger
                      ref={triggerRef}
                      className="rounded-lg border-0 bg-transparent p-0"
                    >
                      <ThreeDotsIcon className="cursor-pointer" />
                    </Trigger>
                  )}
                />
              </th>
            );
          }
          if (columnField?.field.type === "url" && columnField?.value) {
            const value = columnField.value as string;
            return (
              <th>
                <a
                  href={fixLink(value)}
                  className="text-dark no-underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Highlighter
                    autoEscape
                    highlightClassName="bg-primary-200"
                    searchWords={[searchQuery || ""]}
                    textToHighlight={value}
                  />
                </a>
              </th>
            );
          }
          if (
            columnParams?.type === "mobile" &&
            typeof columnField?.value === "string"
          ) {
            const [, code, number] = columnField.value.split(",");
            const phoneNumber = `${code}${number}`;
            if (code && number) {
              return (
                <th>
                  <Highlighter
                    autoEscape
                    highlightClassName="bg-primary-200"
                    searchWords={[searchQuery || ""]}
                    textToHighlight={phoneNumber}
                  />
                </th>
              );
            }
            return <th>-</th>;
          }
          if (
            (columnParams?.type === "dropdown" ||
              columnParams?.type === "text" ||
              columnParams?.type === "email") &&
            columnField &&
            typeof columnField.value === "string"
          ) {
            return (
              <th key={columnField?.field.id}>
                <Highlighter
                  autoEscape
                  highlightClassName="bg-primary-200"
                  searchWords={[searchQuery || ""]}
                  textToHighlight={columnField.value}
                />
              </th>
            );
          }
          return <th>-</th>;
        })}
      </>
    ),
    [columns, formFields?.data, getNameAndPosition, searchQuery, t]
  );
  const isEmpty = customersList?.total_entries === 0 && !searchQuery;
  const getCustomerName = (customerId: string) => {
    const customerData = customersList?.data.find(
      (customer) => customer.id === customerId
    );
    const clientName = customerData?.fields.reduce((accumulator, item) => {
      const {
        field: { section, name },
      } = item;
      if (
        section === "personal" &&
        (name === "first_name" || name === "last_name")
      ) {
        const val = item.value as string;
        accumulator += ` ${val || ""}`;
      }
      return accumulator;
    }, "");
    return clientName;
  };

  const {
    isUnread: hasUnreadNotifications,
    markAsRead: markNotificationsAsRead,
  } = useModuleNotificationsUnreadIndicator({
    entityId: entity?.id,
    module: "customer",
  });

  useMarkModuleAsRead(entity?.id, "customer");

  return (
    <div>
      <ConfirmationPopup
        variant="web"
        title={t({
          id: "customers-page.delete-customer",
          defaultMessage: "Delete Customer",
        })}
        isOpen={isRemoveCustomerModalOpen}
        hasCancelButton
        message={
          <div className="flex flex-col items-center ">
            <Text variant="body3" className="text-grey">
              {t(
                {
                  id: "customers-page.customer-delete-confirmation",
                  defaultMessage: "Are you sure you want to delete {customer}?",
                },
                {
                  customer: getCustomerName(clientToRemove || ""),
                }
              )}
            </Text>
          </div>
        }
        buttons={[
          {
            label: t({
              id: "common.delete",
              defaultMessage: "Delete",
            }),
            onClick: () => {
              if (clientToRemove) {
                handleDeleteCustomer(clientToRemove);
              }
              setRemoveCustomerModalOpen(false);
            },
          },
        ]}
        onRequestClose={() => {
          setRemoveCustomerModalOpen(false);
          setClientToRemove(undefined);
        }}
      />
      <ManageCustomerDialog
        isOpen={isSidebarOpen}
        onClose={() => {
          setSidebarOpen(false);
          setClientToEdit(undefined);
        }}
        customerId={clientToEdit}
      />
      <CustomizeFieldsDialog
        isOpen={isCustomizeFieldsOpen}
        onClose={() => {
          setCustomizeFieldsOpen(false);
        }}
      />
      <HeaderBreadcrumbs
        icon={<CustomersIcon />}
        items={[
          {
            title: t({
              id: "customers-page.clients",
              defaultMessage: "Clients",
            }),
          },
        ]}
        rightContent={
          <>
            <NotificationsButton
              hasUnreadIndicator={hasUnreadNotifications}
              onClick={() => {
                setIsNotificationsDialogOpen(true);
                markNotificationsAsRead();
              }}
            />
            {entity && (
              <ModuleNotificationsDialog
                isOpen={isNotificationsDialogOpen}
                entityId={entity.id}
                module="customer"
                onNotificationClick={() => setIsNotificationsDialogOpen(false)}
                onClose={() => setIsNotificationsDialogOpen(false)}
              />
            )}
          </>
        }
      />
      <div className="bg-grey-200 flex h-full min-h-[calc(100vh-60px)] flex-col items-center justify-start">
        {!isEmpty && (
          <div className="mt-11 mb-8 flex w-full justify-between px-8">
            <div className="rounded-lg bg-white px-4">
              <SearchInput
                className="my-2"
                variant="blank"
                onChange={(e) => setSearchQuery(e.currentTarget.value)}
              />
            </div>
            <div className="flex flex-row gap-4">
              <Button
                className="uppercase"
                onClick={() => setSidebarOpen(true)}
              >
                {t({
                  id: "customers-page.add-new",
                  defaultMessage: "Add new",
                })}{" "}
                <AddIcon />
              </Button>
            </div>
          </div>
        )}
        {isEmpty ? (
          <div className="h-[calc(100vh-60px)]">
            <EmptyListContent
              type="custom"
              customButton={{
                text: t({
                  id: "customers-page.add-new-customer",
                  defaultMessage: "Add New Client",
                }),
                onClick: () => setSidebarOpen(true),
              }}
              customTitle={t({
                id: "customers-page.no-clients",
                defaultMessage: "No Clients",
              })}
              customSubtitle={t({
                id: "customers-page.no-clients-yet",
                defaultMessage: "Looks like you haven’t added any Client yet",
              })}
              customImg={<img src={noCustomersImage} alt="" />}
            />
          </div>
        ) : (
          <div className="w-full overflow-auto px-8">
            <Table<Customer>
              columns={columns}
              data={customersList?.data || []}
              rowFormatter={rowFormatter}
              headerFormatter={headerFormatter}
              currentPage={page}
              onPageChange={setPage}
              pageRows={pageRows}
              onPageRowsChange={setPageRows}
              totalPages={customersList?.total_pages || 0}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomersPage;
