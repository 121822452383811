import { HookOutOfContextError, useTranslations } from "@jugl-web/utils";
import { useLocalStorage } from "@jugl-web/utils/hooks/useStorage";
import { TASK_SORTING_KEY } from "@jugl-web/utils/storage";
import { FC, ReactNode, createContext, useContext, useMemo } from "react";

export enum TaskSorting {
  lastUpdated = "lastUpdated",
  dueDateAsc = "dueDateAsc",
  dueDateDesc = "dueDateDesc",
}

export interface TaskSortingOption {
  id: string;
  label: string;
  value: TaskSorting;
}

interface TaskSortingContextValue {
  sorting: TaskSorting;
  sortingOptions: TaskSortingOption[];
  sortingButtonLabel: string;
  changeSorting: (sorting: TaskSorting) => void;
}

const TaskSortingContext = createContext<TaskSortingContextValue | null>(null);

interface TaskSortingProviderProps {
  children: ReactNode;
}

export const TaskSortingProvider: FC<TaskSortingProviderProps> = ({
  children,
}) => {
  const { t } = useTranslations();

  const [sorting, setSorting] = useLocalStorage<TaskSorting>(
    TASK_SORTING_KEY,
    TaskSorting.lastUpdated
  );

  const sortingButtonLabel = useMemo(() => {
    switch (sorting) {
      case TaskSorting.dueDateAsc:
        return t({
          id: "tasks-page.sort-tasks-by-due-date-ascending",
          defaultMessage: "Sort tasks by due date (ASC)",
        });
      case TaskSorting.dueDateDesc:
        return t({
          id: "tasks-page.sort-tasks-by-due-date-descending",
          defaultMessage: "Sort tasks by due date (DESC)",
        });
      default:
        return t({
          id: "tasks-page.sort-tasks-by-last-updated",
          defaultMessage: "Sort tasks by last updated",
        });
    }
  }, [sorting, t]);

  const sortingOptions = useMemo(
    (): TaskSortingOption[] => [
      {
        id: "last-updated",
        label: t({
          id: "tasks-page.sort-by-last-updated",
          defaultMessage: "By last updated",
        }),
        value: TaskSorting.lastUpdated,
      },
      {
        id: "due-date-ascending",
        label: t({
          id: "tasks-page.sort-by-due-date-ascending",
          defaultMessage: "By due date - Ascending",
        }),
        value: TaskSorting.dueDateAsc,
      },
      {
        id: "due-date-descending",
        label: t({
          id: "tasks-page.sort-by-due-date-descending",
          defaultMessage: "By due date - Descending",
        }),
        value: TaskSorting.dueDateDesc,
      },
    ],
    [t]
  );

  const contextValue = useMemo<TaskSortingContextValue>(
    () => ({
      sorting,
      sortingOptions,
      sortingButtonLabel,
      changeSorting: setSorting,
    }),
    [sorting, sortingOptions, sortingButtonLabel, setSorting]
  );

  return (
    <TaskSortingContext.Provider value={contextValue}>
      {children}
    </TaskSortingContext.Provider>
  );
};

export const useTaskSorting = () => {
  const context = useContext(TaskSortingContext);

  if (!context) {
    throw new HookOutOfContextError(
      "useTaskSortingModifiers",
      "TaskSortingContext"
    );
  }

  return context;
};
