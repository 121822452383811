export const avatarFileSelectConfig = {
  multiple: false,
  maxFileSize: 5 * 1024 * 1024,
  acceptTypes: "image/jpg, image/jpeg, image/png",
  compressionConfig: {
    imageQuality: 0.8,
    imageMaxResolution: 1000,
    targetSizeInKb: 2000,
  },
};
