import { createContext, FC, ReactNode, useMemo } from "react";
import { AppVariant } from "../types";

export const AppVariantContext = createContext<AppVariant | null>(null);

type AppVariantProviderProps =
  | {
      children: ReactNode;
      variant: AppVariant;
    }
  | {
      children: ReactNode;
      auto: boolean;
    };

const isMobileDevice = () => {
  const userAgent =
    navigator.userAgent ||
    navigator.vendor ||
    (window as unknown as { opera: string }).opera;
  const mobileRegex =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return mobileRegex.test(userAgent);
};

export const AppVariantProvider: FC<AppVariantProviderProps> = ({
  children,
  ...props
}) => {
  const variant: AppVariant = useMemo(() => {
    if ("variant" in props) {
      return props.variant;
    }
    if (isMobileDevice()) {
      return "mobile";
    }
    return "web";
  }, [props]);

  return (
    <AppVariantContext.Provider value={variant}>
      {children}
    </AppVariantContext.Provider>
  );
};
