import { Transition } from "@headlessui/react";
import { Button, PlainButton } from "@jugl-web/ui-components/cross-platform";
import { useToast, useTranslations } from "@jugl-web/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { useSelectEntity } from "@web-src/features/app/hooks/useSelectEntity";
import { CSSProperties, forwardRef, Fragment, useMemo } from "react";
import { EntityModel, useRestApiProvider } from "@jugl-web/rest-api";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { EntityUpdateListItem } from "./EntityUpdateListItem";
import { ReactComponent as ArrowIcon } from "./icons/arrow.svg";
import { ReactComponent as CloseIcon } from "./icons/close.svg";
import { ReactComponent as JuglLogoSmallIcon } from "./icons/jugl-logo-small.svg";

export interface EntitiesUpdatesPopupProps {
  isOpen: boolean;
  entities?: EntityModel[];
  lastUpdatedEntityId?: string;
  style?: CSSProperties;
  onClose: (preventDismiss?: boolean) => void;
}

interface EntityUpdateEntry {
  entity: EntityModel;
  updatesCount: number;
}

interface AggregatedEntityUpdateEntries {
  lastUpdated: EntityUpdateEntry | undefined;
  remainingUpdated: EntityUpdateEntry[];
}

export const EntitiesUpdatesPopup = forwardRef<
  HTMLDivElement,
  EntitiesUpdatesPopupProps
>(({ isOpen, entities, lastUpdatedEntityId, style, onClose }, ref) => {
  const { entity } = useEntity();
  const { entitiesApi } = useRestApiProvider();
  const selectEntity = useSelectEntity();
  const { toast } = useToast({ variant: "web" });
  const { t } = useTranslations();
  const { navigateToPage } = useNavigation();
  const {
    data: response,
    currentData,
    isFetching,
  } = entitiesApi.useGetAllEntitiesModuleUpdateIndicatorsQuery(
    entity && isOpen ? { entityId: entity.id } : skipToken,
    { refetchOnMountOrArgChange: true }
  );

  const isVisible = isOpen && !!currentData && !isFetching;

  const aggregatedEntityUpdateEntries =
    useMemo<AggregatedEntityUpdateEntries>(() => {
      if (!response || !entity || !entities) {
        return { lastUpdated: undefined, remainingUpdated: [] };
      }

      const entitiesIds = Object.keys(response.data);

      return entitiesIds.reduce<AggregatedEntityUpdateEntries>(
        (acc, entityId) => {
          const foundEntity = entities.find(
            (_entity) => _entity.id === entityId
          );

          if (!foundEntity) {
            return acc;
          }

          const updatesCount = response.data[entityId].reduce(
            (sum, indicator) => sum + Number(indicator.entity_unread),
            0
          );

          if (updatesCount === 0) {
            return acc;
          }

          const entityUpdateEntry: EntityUpdateEntry = {
            entity: foundEntity,
            updatesCount,
          };

          if (lastUpdatedEntityId === foundEntity.id) {
            return {
              ...acc,
              lastUpdated: entityUpdateEntry,
            };
          }

          return {
            ...acc,
            remainingUpdated: [...acc.remainingUpdated, entityUpdateEntry],
          };
        },
        { lastUpdated: undefined, remainingUpdated: [] }
      );
    }, [entities, entity, lastUpdatedEntityId, response]);

  const handleEntitySwitch = (nextEntity: EntityModel) => {
    selectEntity(nextEntity);
    onClose(true);
    toast(
      <span>
        {t(
          {
            id: "feedback.switched-to-entity",
            defaultMessage: "Switched to {entityName}",
          },
          { entityName: <b>{nextEntity.name}</b> }
        )}
      </span>
    );
  };

  return (
    <Transition
      as="div"
      ref={ref}
      show={isVisible}
      enter="transition"
      enterFrom="opacity-0 -translate-x-4 scale-75"
      enterTo="opacity-100 translate-x-0 scale-100"
      leave="transition"
      leaveFrom="opacity-100 translate-x-0 scale-100"
      leaveTo="opacity-0 -translate-x-4 scale-75"
      className="z-[200] flex max-h-[100vh] w-[400px] origin-bottom-left flex-col rounded-2xl bg-white duration-300"
      style={{
        boxShadow: "18px 6px 32px 0px rgba(0, 0, 0, 0.14)",
        maxHeight: `calc(100vh - 60px)`,
        ...style,
      }}
    >
      <div className="bg-primary-700 mb-2 flex h-[60px] items-center rounded-tl-2xl rounded-tr-2xl px-6 py-3.5">
        <JuglLogoSmallIcon />
        <span className="flex-grow text-sm text-gray-100">
          {t({
            id: "entity-updates-popup-component.heading",
            defaultMessage: "Workspace updates",
          })}
        </span>
        <PlainButton className="shrink-0" onClick={() => onClose()}>
          <CloseIcon />
        </PlainButton>
      </div>
      <div
        className="flex flex-grow flex-col overflow-y-auto rounded-bl-2xl rounded-br-2xl"
        style={{ maxHeight: "calc(100% - 60px)" }}
      >
        <div className="px-6 py-5">
          <span className="text-dark-800 block text-base font-normal leading-3">
            {t({
              id: "entity-updates-popup-component.intro",
              defaultMessage:
                "You have some unread notifications from your Workspaces. Here is a quick update:",
            })}
          </span>
        </div>
        <div className="bg-grey-100 h-1 w-full" />
        <div className="overflow-y-auto">
          {aggregatedEntityUpdateEntries.lastUpdated && (
            <div className="py-5 px-6">
              <span className="mb-4 inline-block text-sm leading-3 text-[#828282]">
                {t({
                  id: "entity-updates-popup-component.updated-recently",
                  defaultMessage: "Updated recently",
                })}
              </span>
              <EntityUpdateListItem
                entity={aggregatedEntityUpdateEntries.lastUpdated.entity}
                updatesCount={
                  aggregatedEntityUpdateEntries.lastUpdated.updatesCount
                }
                onEntitySwitch={handleEntitySwitch}
              />
            </div>
          )}
          {aggregatedEntityUpdateEntries.lastUpdated &&
            aggregatedEntityUpdateEntries.remainingUpdated.length > 0 && (
              <div className="bg-grey-100 h-1 w-full" />
            )}
          {aggregatedEntityUpdateEntries.remainingUpdated.length > 0 && (
            <div className="py-5 px-6">
              <span className="mb-4 inline-block text-sm leading-3 text-[#828282]">
                {t({
                  id: "entity-updates-popup-component.remaining-updates",
                  defaultMessage: "Remaining updates",
                })}
              </span>
              {aggregatedEntityUpdateEntries.remainingUpdated.map(
                (entry, index, array) => {
                  const isLast = index === array.length - 1;

                  return (
                    <Fragment key={entry.entity.id}>
                      <EntityUpdateListItem
                        entity={entry.entity}
                        updatesCount={entry.updatesCount}
                        onEntitySwitch={handleEntitySwitch}
                      />
                      {!isLast && (
                        <div className="bg-grey-200 my-4 h-px w-full" />
                      )}
                    </Fragment>
                  );
                }
              )}
            </div>
          )}
        </div>
        <div className="flex-grow" />
        <Button
          variant="contained"
          color="primary"
          uppercase
          className="my-8 w-[290px] shrink-0 self-center"
          onClick={() => navigateToPage("entitySelect", undefined)}
        >
          {t({
            id: "entity-updates-popup-component.go-to-org-list-button",
            defaultMessage: "Go to workspaces list",
          })}
        </Button>
      </div>
      <ArrowIcon className="absolute left-0 bottom-[38px] -translate-x-full translate-y-1/2" />
    </Transition>
  );
});
