import { Checkbox } from "@jugl-web/ui-components/cross-platform/Checkbox";
import { useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import { FilterPill } from "../FilterPill";

interface OverdueFilterPillProps {
  isChecked: boolean;
  onClick?: () => void;
  onRemove?: () => void;
}

export const OverdueFilterPill: FC<OverdueFilterPillProps> = ({
  isChecked,
  onClick,
  onRemove,
}) => {
  const { t } = useTranslations();

  return (
    <FilterPill
      startIcon={
        <Checkbox
          className="h-[15px] w-[15px]"
          readOnly
          tabIndex={-1}
          isChecked={isChecked}
        />
      }
      label={t({ id: "tasks-page.overdue", defaultMessage: "Overdue" })}
      onClick={onClick}
      onRemove={onRemove}
    />
  );
};
