import { TaskDefaultStatus } from "@jugl-web/rest-api/tasks";
import React, { useMemo } from "react";
import { useTranslations } from "@jugl-web/utils";

export interface TaskStatusDetails {
  id: TaskDefaultStatus | string;
  label: string;
}

export const useDefaultTaskStatuses = () => {
  const { t } = useTranslations();

  const defaultStatusDetailsById = useMemo<
    Record<TaskDefaultStatus, TaskStatusDetails>
  >(
    () => ({
      [TaskDefaultStatus.notStarted]: {
        id: TaskDefaultStatus.notStarted,
        label: t({
          id: "tasks-page.pending-status",
          defaultMessage: "Pending",
        }),
      },
      [TaskDefaultStatus.inProgress]: {
        id: TaskDefaultStatus.inProgress,
        label: t({
          id: "tasks-page.in-progress-status",
          defaultMessage: "In progress",
        }),
      },
      [TaskDefaultStatus.completed]: {
        id: TaskDefaultStatus.completed,
        label: t({
          id: "tasks-page.completed-status",
          defaultMessage: "Completed",
        }),
      },
      [TaskDefaultStatus.reopened]: {
        id: TaskDefaultStatus.reopened,
        label: t({
          id: "tasks-page.reopened-status",
          defaultMessage: "Re-opened",
        }),
      },
    }),
    [t]
  );

  return { defaultStatusDetailsById };
};
