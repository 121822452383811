import { AnyModuleLog } from "@jugl-web/rest-api";
import { useCallback } from "react";
import { isCustomerLog, isDriveLog, isPeopleLog, isTaskLog } from "../utils";
import { useTaskLogParser } from "./useTaskLogParser";
import { usePeopleLogParser } from "./usePeopleLogParser";
import { useCustomerLogParser } from "./useCustomerLogParser";
import { useDriveLogParser } from "./useDriveLogParser";

interface UseModuleLogParserOptions {
  entityId: string | undefined;
}

export const useModuleLogParser = ({ entityId }: UseModuleLogParserOptions) => {
  const { parseTaskLog } = useTaskLogParser({ entityId });
  const { parsePeopleLog } = usePeopleLogParser();
  const { parseCustomerLog } = useCustomerLogParser();
  const { parseDriveLog } = useDriveLogParser();

  const parseModuleLog = useCallback(
    (log: AnyModuleLog) => {
      if (isTaskLog(log)) {
        return parseTaskLog(log);
      }

      if (isPeopleLog(log)) {
        return parsePeopleLog(log);
      }

      if (isCustomerLog(log)) {
        return parseCustomerLog(log);
      }

      if (isDriveLog(log)) {
        return parseDriveLog(log);
      }

      throw new Error("Unsupported module log");
    },
    [parseCustomerLog, parseDriveLog, parsePeopleLog, parseTaskLog]
  );

  return { parseModuleLog };
};
