import { useCallback, useContext } from "react";
import { PheonixPushAction } from "@web-src/features/chats/types";
import { PhoenixSocketContext } from "@web-src/features/chats/providers/PheonixSocket";

type MessageReactionParams = {
  msgId: string;
  reaction: string;
  action: "add" | "remove";
};

const useSendMessageReaction = () => {
  const { channel } = useContext(PhoenixSocketContext);
  const sendMessageReaction = useCallback(
    ({ msgId, reaction, action }: MessageReactionParams) =>
      // eslint-disable-next-line no-async-promise-executor
      new Promise(async (resolve, reject) => {
        if (!channel) {
          reject(new Error("No channel"));
          return;
        }
        const message = channel?.push(PheonixPushAction.update, {
          msg_id: msgId,
          payload: {
            reaction,
            action,
          },
        });
        message.receive("ok", resolve);
        message.receive("error", reject);
        message.receive("timeout", reject);
      }),
    [channel]
  );
  return sendMessageReaction;
};

export default useSendMessageReaction;
