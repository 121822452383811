import { useTranslations } from "@jugl-web/utils";
import { environment } from "@web-src/environments/environment";
import React, { useCallback } from "react";
import LoginMethodButton from "./components/LoginMethodButton";
import { ReactComponent as EmailIcon } from "./assets/email.svg";
import { ReactComponent as GoogleIcon } from "./assets/google.svg";
import { ReactComponent as AppleIcon } from "./assets/apple.svg";
import { ReactComponent as LinkedinIcon } from "./assets/linkedin.svg";
import { ReactComponent as PhoneIcon } from "./assets/phone.svg";
import { LoginMethod } from "../../../../types";
import { useLoginPage } from "../../../../providers/LoginPageProvider";

const LoginMethodPicker: React.FC = () => {
  const { setLoginMethod, loginMethod } = useLoginPage();
  const { t } = useTranslations();

  const loginWithSocial = useCallback(
    (provider: "linkedin" | "google" | "apple") => {
      const redirectUrl = `${window.location.origin}/jugl-callback`;
      window.open(
        `${environment.apiUrl}/jugl/uberauth/${provider}?final_callback_url=${redirectUrl}&agt=web`,
        "_self"
      );
    },
    []
  );

  return (
    <div className="mt-auto flex flex-col gap-5">
      <div className="flex items-center gap-5">
        <div className="bg-dark-100 h-px w-full" />
        <span className="text-grey text-xs-700 shrink-0 text-xs">
          {t({
            id: "login-page.login-with",
            defaultMessage: "or login with",
          })}
        </span>
        <div className="bg-dark-100 h-px w-full" />
      </div>
      <div className="flex items-center gap-5">
        {loginMethod === LoginMethod.email ? (
          <LoginMethodButton
            icon={<PhoneIcon />}
            title={t({
              id: "login-page.phone",
              defaultMessage: "Phone",
            })}
            onClick={() => setLoginMethod(LoginMethod.phone)}
          />
        ) : (
          <LoginMethodButton
            icon={<EmailIcon />}
            title={t({
              id: "login-page.email",
              defaultMessage: "Email",
            })}
            onClick={() => setLoginMethod(LoginMethod.email)}
          />
        )}
        <LoginMethodButton
          icon={<GoogleIcon />}
          title={t({
            id: "login-page.google",
            defaultMessage: "Google",
          })}
          onClick={() => loginWithSocial("google")}
        />
        <LoginMethodButton
          icon={<AppleIcon />}
          title={t({
            id: "login-page.apple",
            defaultMessage: "Apple",
          })}
          onClick={() => loginWithSocial("apple")}
        />
        <LoginMethodButton
          icon={<LinkedinIcon />}
          title={t({
            id: "login-page.linkedin",
            defaultMessage: "Linkedin",
          })}
          onClick={() => loginWithSocial("linkedin")}
        />
      </div>
    </div>
  );
};

export default LoginMethodPicker;
