import { HookOutOfContextError } from "@jugl-web/utils";
import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

const PREFERENCES_KEY = "jugl-web:preferences";

interface AppPreferences {
  inAppNotificationsEnabled: boolean;
}

interface PreferencesContextValue {
  preferences: AppPreferences;
  updatePreferences: (newPreferences: Partial<AppPreferences>) => void;
}

const defaultPreferences: AppPreferences = {
  inAppNotificationsEnabled: true,
};

const getSavedPreferences = () => {
  try {
    const savedData = localStorage.getItem(PREFERENCES_KEY);
    if (!savedData) {
      return null;
    }
    return JSON.parse(savedData);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return null;
  }
};

export const PreferencesContext = createContext<PreferencesContextValue>(
  null as unknown as PreferencesContextValue
);

export const PreferencesProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [currentPreferences, setCurrentPreferences] = useState<AppPreferences>(
    getSavedPreferences() || defaultPreferences
  );

  const updatePreferences = useCallback(
    (preferencesToUpdate: Partial<AppPreferences>) => {
      setCurrentPreferences((prev) => {
        const newPreferences = { ...prev, ...preferencesToUpdate };
        localStorage.setItem(PREFERENCES_KEY, JSON.stringify(newPreferences));
        return newPreferences;
      });
    },
    []
  );

  const value: PreferencesContextValue = useMemo(
    () => ({ preferences: currentPreferences, updatePreferences }),
    [currentPreferences, updatePreferences]
  );

  return (
    <PreferencesContext.Provider value={value}>
      {children}
    </PreferencesContext.Provider>
  );
};

export const usePreferencesProvider = () => {
  const context = useContext(PreferencesContext);

  if (!context) {
    throw new HookOutOfContextError(
      "usePreferencesProvider",
      "PreferencesContext"
    );
  }

  return context;
};
