import { cx } from "@jugl-web/utils";

export type DotIconSize = "sm" | "md";

const sizeToClasses: Record<DotIconSize, string> = {
  sm: "h-4 w-4 p-[3px]",
  md: "h-4 w-4 p-0.5",
};

export interface DotIconProps {
  color: string;
  size?: DotIconSize;
  className?: string;
}

export const DotIcon = ({ color, size = "md", className }: DotIconProps) => (
  <div className={cx("box-border", sizeToClasses[size], className)}>
    <div
      className="h-full w-full rounded-full"
      style={{ backgroundColor: color }}
    />
  </div>
);
