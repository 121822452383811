import {
  InteractiveContainer,
  MultiSectionLayout,
} from "@jugl-web/ui-components/cross-platform";
import React from "react";
import { cx } from "@jugl-web/utils";
import {
  HeaderBreadcrumbs,
  HeaderBreadcrumbsProps,
} from "@jugl-web/ui-components/web";

export type TabItem = {
  id: string;
  title: string;
  hidden?: boolean;
  href?: string;
  onClick?: () => void;
};

export type TabsLayoutProps = {
  tabs: TabItem[];
  selectedTab: string;
  breadcrumbProps?: HeaderBreadcrumbsProps;
  header?: React.ReactNode;
  rightContent?: React.ReactNode;
  children: React.ReactNode;
};

export const TabsLayout: React.FC<TabsLayoutProps> = ({
  children,
  tabs,
  breadcrumbProps,
  header,
  selectedTab,
  rightContent,
}) => (
  <MultiSectionLayout
    header={
      <>
        {header}
        {breadcrumbProps && (
          <HeaderBreadcrumbs disableShadow {...breadcrumbProps} />
        )}
        <div className="flex h-[72px] items-center justify-between bg-[#EEF2F5] px-8">
          <div className="flex h-full items-end gap-1">
            {tabs.map((option) => (
              <InteractiveContainer
                key={option.id}
                className={cx(
                  "group flex flex-col justify-between",
                  option.id === selectedTab
                    ? " text-primary-800 font-medium"
                    : " text-[#69757C]"
                )}
                onClick={option.onClick}
              >
                <span className="p-4">{option.title}</span>
                <div
                  className={cx(
                    "h-1 w-full rounded-lg",
                    option.id === selectedTab
                      ? "bg-primary"
                      : "group-hover:bg-grey-300 bg-transparent"
                  )}
                />
              </InteractiveContainer>
            ))}
          </div>
          {rightContent && <div>{rightContent}</div>}
        </div>
      </>
    }
  >
    {children}
  </MultiSectionLayout>
);
