import { useEffect } from "react";

import api from "./api";

export const useApi = ({
  onError,
}: {
  onError?: (e: {
    status?: number;
    data?: Record<string, unknown>;
    message?: string;
    preventDuplicate: boolean;
  }) => void;
}) => {
  const capitalize = (text: string) =>
    text.charAt(0).toUpperCase() + text.slice(1);

  useEffect(() => {
    const interceptor = api.interceptors.response.use(undefined, (error) => {
      let message = capitalize(error.message);
      let preventDuplicate = false;

      if (error.request.responseURL.includes("/web/verify-email")) {
        message = "Invalid email or password";
      }

      if (error.request.status === 401) {
        message = "Your session expired, please login again";
        preventDuplicate = true;
      }

      if (Array.isArray(error.response?.data?.errors)) {
        message = capitalize(error.response?.data?.errors[0].message);
      }

      if (typeof error.response?.data?.errors === "string") {
        message = capitalize(error.response?.data?.errors.replaceAll("_", " "));
      }
      if (!error.config.silentError) {
        onError?.({
          status: error.response?.status,
          data: error.response?.data || error.message,
          message,
          preventDuplicate,
        });
      }
      throw error;
    });

    return () => {
      api.interceptors.response.eject(interceptor);
    };
  }, [onError]);
};
