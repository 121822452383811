import { addSearchParamsToURL } from "@jugl-web/utils";
import { User } from "./models/User";
import { UserGeneralProfile } from "./types/UserGeneralProfile";

export const adaptUserGeneralProfileFromUser = (
  user: User
): UserGeneralProfile => {
  // TODO: check with backend haw to process process it
  let displayName = user.username || "Unknown user";
  if (user.profile?.first_name || user.profile?.last_name) {
    displayName = `${user.profile?.first_name || ""} ${
      user.profile?.last_name ? ` ${user.profile?.last_name}` : ""
    }`;
  }
  const avatar =
    user.profile?.img && user.profile?.updated_at
      ? // As per backed request, manually add "updated_at" to avatar to update cache
        addSearchParamsToURL(user.profile.img, { t: user.profile.updated_at })
      : user.profile?.img;
  return {
    id: user.id,
    entityRelId: user.entity_rel_id,
    hasProfile: !!user.profile,
    firstName: user.profile?.first_name,
    lastName: user.profile?.last_name,
    username: user.username,
    displayName,
    role: user.role,
    department: user?.spaces?.length
      ? {
          id: user.spaces[0].id,
          name: user.spaces[0].info.title,
          type: user.spaces[0].type,
        }
      : undefined,
    avatar,
    managers: user.managers,
    timezone: user.timezone,
    _user: {
      ...user,
      profile: user.profile
        ? { ...user.profile, img: avatar || null }
        : undefined,
    },
  };
};
