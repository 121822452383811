import { getUniqueId } from "@jugl-web/utils";
import { resizeImage } from "./resizeImage";
import {
  FILE_EXTENSION,
  FILE_FORMAT,
  FILE_QUALITY,
  MAX_RESOLUTION,
} from "../consts";

export const saveImageFromCanvas = async (canvas: HTMLCanvasElement) => {
  const blob = await new Promise<Blob | null>((resolve) => {
    canvas.toBlob(resolve, FILE_FORMAT, FILE_QUALITY);
  });

  if (!blob) {
    throw new Error();
  }

  const file = new File([blob], `${getUniqueId()}.${FILE_EXTENSION}`, {
    type: FILE_FORMAT,
  });

  if (canvas.width > MAX_RESOLUTION || canvas.height > MAX_RESOLUTION) {
    const resizedImageFile = await resizeImage(file, MAX_RESOLUTION);

    if (!resizedImageFile) {
      throw new Error();
    }

    return resizedImageFile as File;
  }
  return file;
};
