import { TaskActivity } from "@jugl-web/rest-api/tasks";
import {
  AppVariant,
  cx,
  humanizePastDate,
  useTranslations,
} from "@jugl-web/utils";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { FC, ReactNode } from "react";
import { Avatar } from "../../Avatar";
import { ReactComponent as MessageIcon } from "./icons/message.svg";
import { ReactComponent as StarIcon } from "./icons/star.svg";
import { ReactComponent as GuestAvatarIcon } from "./icons/guest-avatar.svg";

export const parseActivityType = (
  type: TaskActivity["action_details"]["action"]
) => {
  switch (type) {
    case "downloaded":
    case "viewed":
      return "action";
    case "updated":
    case "created":
    case "deleted":
      return "update";
    case "guest_comment":
    case "guest_feedback":
    case "guest_comment_reply":
    case "commented":
      return "comment";
    default:
      return "action";
  }
};

type TaskActivityItemType = "comment" | "action" | "update";

export interface TaskActivityItemProps {
  variant: AppVariant;
  type: TaskActivityItemType;
  username: string;
  userImageUrl: string | null;
  message: ReactNode;
  date: Date;
  contextMenuSlot?: ReactNode;
  className?: string;
  action?: string;
  isMyActivity?: boolean;
  isGuestApp?: boolean;
  ratingRate?: number;
  ratingTags?: string[];
}

export const TaskActivityItem: FC<TaskActivityItemProps> = ({
  variant,
  type,
  username,
  userImageUrl,
  message,
  date,
  contextMenuSlot,
  className,
  action,
  isGuestApp,
  ratingRate,
  ratingTags,
  isMyActivity,
}) => {
  const isMobile = variant === "mobile";
  const { t } = useTranslations();
  const { dateLocale } = useLanguage();

  return (
    <div
      className={cx("flex w-full", isMobile ? "p-4" : "px-8 py-6", className)}
    >
      <div className="flex-start flex w-full gap-3">
        {(action === "guest_comment" || action === "guest_feedback") &&
        !userImageUrl ? (
          <GuestAvatarIcon className="shrink-0" />
        ) : (
          <Avatar
            size="md"
            username={username}
            imageUrl={userImageUrl}
            className="h-8 w-8 shrink-0 border border-solid border-white"
          />
        )}
        <div className="flex w-full min-w-0 flex-col">
          {type === "comment" && (
            <div className="mb-2 flex w-full items-center justify-between">
              <div className="flex items-center gap-1.5">
                {!isGuestApp && <MessageIcon />}
                <span className="leading-2 text-sm font-semibold -tracking-[0.14px] text-[#363636]">
                  {username}{" "}
                  {!isGuestApp && action === "guest_comment" && (
                    <span className="text-grey-background text-sm font-normal">
                      {t({
                        id: "common.external",
                        defaultMessage: "external",
                      })}
                    </span>
                  )}
                  {isGuestApp && type === "comment" && isMyActivity && (
                    <span>
                      {`(${t({
                        id: "common.you",
                        defaultMessage: "You",
                      })})`}
                    </span>
                  )}
                </span>
              </div>
              {contextMenuSlot}
            </div>
          )}
          {ratingRate && (
            <div className="mb-1 flex gap-1">
              {Array.from({ length: 5 }, (_, idx) => (
                <StarIcon
                  key={+idx}
                  className={
                    idx < ratingRate
                      ? "text-gradients-warning"
                      : "text-grey-400"
                  }
                />
              ))}
            </div>
          )}

          <div
            className={cx(
              "whitespace-pre-wrap break-words text-sm leading-3 -tracking-[0.14px]",
              type === "comment"
                ? "text-dark"
                : "text-dark-600 [&>b:first-of-type]:text-dark"
            )}
          >
            {message}
          </div>
          {ratingTags && ratingTags.length > 0 && (
            <div className="flex min-w-0 flex-wrap gap-x-2">
              {ratingTags.map((hashtag) => (
                <span className="text-primary truncate text-sm" key={hashtag}>
                  {hashtag}
                </span>
              ))}
            </div>
          )}
          <time className="text-grey-900 mt-1.5 text-xs -tracking-[0.12px]">
            {humanizePastDate(date, t, dateLocale)}
          </time>
        </div>
      </div>
    </div>
  );
};
