import { InternalTaskCustomField } from "@jugl-web/rest-api/tasks";
import {
  DetailedTaskTemplate,
  PreviewTaskTemplate,
} from "@jugl-web/rest-api/tasks-templates";
import format from "date-fns/format";
import { useCallback } from "react";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { useTaskFields } from "../../hooks/useTaskFields";
import { TaskTemplateCardCustomField, TaskTemplateCardProps } from "./types";

export const adaptCustomFieldToTaskTemplateCardField = (
  customField: InternalTaskCustomField,
  value: string,
  dateLocale?: Locale
): TaskTemplateCardCustomField | null => {
  if (value === "") {
    return null;
  }

  if (customField.type === "date") {
    value = format(new Date(value), "dd MMM, yyyy", { locale: dateLocale });
  } else if (customField.type === "dropdown") {
    const dropdownValue = customField.values?.find(
      (v) => v.id === value
    )?.value;

    if (!dropdownValue) {
      return null;
    }

    value = dropdownValue;
  }

  return {
    id: customField.id,
    text: `${customField.name}: ${value}`,
    type: customField.type,
  };
};

const adaptCustomFieldsToTaskTemplateCardCustomFields = (
  customFields: DetailedTaskTemplate["custom_fields"],
  getCustomFieldById: (id: string) => InternalTaskCustomField | undefined,
  dateLocale?: Locale
): TaskTemplateCardCustomField[] => {
  if (!customFields) {
    return [];
  }

  const matchingCustomFields = Object.keys(customFields).reduce<
    InternalTaskCustomField[]
  >((acc, customFieldId) => {
    const customField = getCustomFieldById(customFieldId);

    if (!customField) {
      return acc;
    }

    return [...acc, customField];
  }, []);

  return matchingCustomFields
    .sort((a, b) => a.order - b.order)
    .reduce<TaskTemplateCardCustomField[]>((acc, customField) => {
      const taskCardField = adaptCustomFieldToTaskTemplateCardField(
        customField,
        customFields[customField.id],
        dateLocale
      );

      if (!taskCardField) {
        return acc;
      }

      return [...acc, taskCardField];
    }, []);
};

type AdaptedTaskTemplateProps = Omit<
  TaskTemplateCardProps,
  "avatar" | "highlightedText" | "className" | "onClick" | "menuConfig"
>;

interface UseTemplateCardOptions {
  entityId: string;
}

export const useTemplateCard = ({ entityId }: UseTemplateCardOptions) => {
  const { getCustomFieldById, getLabelById } = useTaskFields({ entityId });
  const { dateLocale } = useLanguage();

  const getTemplateCardProps = useCallback(
    (template: PreviewTaskTemplate): AdaptedTaskTemplateProps => {
      const customFields = [];

      if (template.custom_fields) {
        customFields.push(
          ...adaptCustomFieldsToTaskTemplateCardCustomFields(
            template.custom_fields,
            getCustomFieldById,
            dateLocale
          )
        );
      }

      const label = (() => {
        if (template.label_id) {
          const matchingLabel = getLabelById(template.label_id);

          if (matchingLabel) {
            return matchingLabel;
          }
        }

        return null;
      })();

      return {
        title: template.name || "",
        dueDateInDays: template.due_in,
        checklistItemsCount: template.chklist_total,
        attachmentsCount: 0,
        label,
        customFields,
      };
    },
    [getCustomFieldById, getLabelById, dateLocale]
  );

  return { getTemplateCardProps };
};
