import { reorder, useDebouncedCallback, useUniqueDOMId } from "@jugl-web/utils";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { DraggableChecklistItemComponent } from "./DraggableChecklistItem";
import { DraggableChecklistItem, DraggableChecklistProps } from "./types";

const EMIT_UPDATE_DEBOUNCE_TIME_MS = 300;

export const DraggableChecklist = ({
  controller,
  className,
  onChange = () => {},
  hideCheckbox,
}: DraggableChecklistProps) => {
  const droppableId = useUniqueDOMId();

  const debouncedOnChange = useDebouncedCallback(
    onChange,
    EMIT_UPDATE_DEBOUNCE_TIME_MS
  );

  const {
    checklistId,
    items,
    editingItemId,
    setItems,
    setEditingItemId,
    setIsDirty,
  } = controller.internals;

  const setItemsWithChangeNotification = (
    callback: (prevItems: DraggableChecklistItem[]) => DraggableChecklistItem[]
  ) => {
    setIsDirty(true);
    setItems((prevItems) => {
      const updatedItems = callback(prevItems);
      debouncedOnChange(updatedItems);
      return updatedItems;
    });
  };

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination || source.index === destination.index) {
      return;
    }

    setItemsWithChangeNotification((prevItems) =>
      reorder(prevItems, source.index, destination.index)
    );
  };

  const handleCompleteToggle = (itemId: string) => {
    setItemsWithChangeNotification((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, isCompleted: !item.isCompleted } : item
      )
    );
  };

  const handleRemove = (itemId: string, silent: boolean) => {
    const setter = silent ? setItems : setItemsWithChangeNotification;
    setter((prevItems) => prevItems.filter((item) => item.id !== itemId));
  };

  const handleFinishEditing = (itemId: string, updatedText: string) => {
    setItemsWithChangeNotification((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, text: updatedText.trim() } : item
      )
    );

    setEditingItemId(null);
  };

  if (items.length === 0) {
    return null;
  }

  return (
    <div className={className}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={droppableId}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {items.map((item, index) => (
                <DraggableChecklistItemComponent
                  key={item.id}
                  item={item}
                  index={index}
                  checklistId={checklistId}
                  isEditing={editingItemId === item.id}
                  onCompleteToggle={() => handleCompleteToggle(item.id)}
                  onStartEditing={() => setEditingItemId(item.id)}
                  onCancelEditing={() => setEditingItemId(null)}
                  onFinishEditing={(updatedText) =>
                    handleFinishEditing(item.id, updatedText)
                  }
                  onRemove={({ silent }) => handleRemove(item.id, silent)}
                  hideCheckbox={hideCheckbox}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
