import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { cx } from "@jugl-web/utils";
import { LoadingSpinner } from "../LoadingSpinner";
import { PlainButton } from "../PlainButton";
import {
  Mention,
  MentionTextarea,
  MentionTextareaHandle,
} from "../RichTextarea";
import { ReactComponent as SendIcon } from "./icons/send.svg";
import { ReactComponent as SendIcon2 } from "./icons/send2.svg";
import { ReactComponent as SmileIcon } from "./icons/smile.svg";
import { ReactComponent as SmileIconMobile } from "./icons/smile-mobile.svg";
import { Popover } from "../Popover";

export interface CommentsInputParams {
  variant: "mobile" | "web";
  isLoading?: boolean;
  onSubmit: (args: { body: string }) => void;
  mentions?: Mention[];
  placeholder?: string;
}

export interface CommentsInputHandle {
  clear: () => void;
  focus: () => void;
  setValue: (params: { body: string }) => void;
}

const validator = (text: string) => text.trim().length > 0;

export const CommentsInput = forwardRef<
  CommentsInputHandle,
  CommentsInputParams
>(
  (
    {
      variant,
      isLoading,
      onSubmit,
      mentions = [],
      placeholder = "Type your comment",
    },
    ref
  ) => {
    const $richTextareaRef = useRef<MentionTextareaHandle | null>(null);
    const [isValid, setIsValid] = useState(false);

    const handleRawTextChange = (rawText: string) => {
      setIsValid(validator(rawText));
    };

    const handleSubmit = () => {
      if ($richTextareaRef.current) {
        const richTextareaHandle = $richTextareaRef.current;

        onSubmit({ body: richTextareaHandle.getRichText() });
        setTimeout(() => {
          richTextareaHandle.focus();
        });
      }
    };

    const handleEnter = () => {
      if (isValid && !isLoading) {
        handleSubmit();
      }
    };

    useImperativeHandle(ref, () => ({
      clear: () => {
        $richTextareaRef.current?.setRichText("");
      },
      setValue: ({ body: bodyToSet }: { body: string }) => {
        $richTextareaRef.current?.setRichText(bodyToSet);
      },
      focus: () => {
        $richTextareaRef.current?.focus();
      },
    }));

    const handleEmojiSelect = (emoji: string) => {
      if (emoji) {
        $richTextareaRef?.current?.focus();
        $richTextareaRef.current?.insertTextAtSelection(emoji);
      }
    };
    const sendIcon = variant === "web" ? <SendIcon /> : <SendIcon2 />;
    return (
      <div className="flex w-full items-center gap-2">
        <div
          className={cx(
            "bg-grey-200 flex flex-1 items-center gap-2 rounded-[10px] px-4",
            {
              "min-h-10": variant === "mobile",
              "min-h-11 border border-solid border-[#DEDEE4]":
                variant === "web",
            }
          )}
        >
          {variant === "web" && (
            <Popover
              placement="top-start"
              renderTrigger={({ Trigger, triggerRef }) => (
                <Trigger as={PlainButton} ref={triggerRef}>
                  {variant === "web" ? <SmileIcon /> : <SmileIconMobile />}
                </Trigger>
              )}
            >
              {({ onClose }) => (
                <Picker
                  theme="light"
                  data={data}
                  skinTonePosition="none"
                  previewPosition="none"
                  onEmojiSelect={(emoji: { [key: string]: string }) => {
                    handleEmojiSelect(emoji.native);
                    onClose();
                  }}
                />
              )}
            </Popover>
          )}
          <MentionTextarea
            ref={$richTextareaRef}
            placeholder={placeholder}
            mentions={mentions}
            classes={{
              container: "w-full py-3",
              contentEditable:
                "outline-none text-[15px] text-dark] max-h-[150px] overflow-y-auto p-0",
              placeholder: "text-[15px] left-0 top-3 text-grey",
            }}
            onRawTextChange={handleRawTextChange}
            onEnter={handleEnter}
          />
        </div>
        <PlainButton
          color="primary"
          className={cx(
            "bg-primary flex shrink-0 items-center justify-center",
            {
              "h-11 w-11 rounded-full": variant === "web",
              "h-10 w-10 rounded-xl": variant === "mobile",
            }
          )}
          disabled={!isValid || isLoading}
          onClick={!isValid || isLoading ? undefined : handleSubmit}
        >
          {!isLoading ? sendIcon : <LoadingSpinner size="sm" />}
        </PlainButton>
      </div>
    );
  }
);
