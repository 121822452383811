import React, { useState, useMemo } from "react";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Portal,
} from "@headlessui/react";
import { useFloating, autoUpdate, flip } from "@floating-ui/react-dom";
import UserProfileName from "@web-src/features/users/components/UserProfileName";
import { PlainButton } from "@jugl-web/ui-components/cross-platform/PlainButton";
import { useTranslations } from "@jugl-web/utils";
import { MessageReactionSummary } from "./types";

const ChatMessageReactionPopover: React.FC<{
  reactionItem: MessageReactionSummary;
  moreReactions: boolean;
  onSidebarOpen?: () => void;
  reactionsLength: number;
}> = ({ reactionItem, moreReactions, reactionsLength, onSidebarOpen }) => {
  const { refs, floatingStyles } = useFloating({
    middleware: [flip()],
    whileElementsMounted: autoUpdate,
  });

  const [displayAllReactions, setDisplayAllReactions] = useState(false);

  const displayReactions = useMemo(
    () =>
      displayAllReactions ? reactionItem.users : reactionItem.users.slice(0, 5),
    [displayAllReactions, reactionItem.users]
  );
  const { t } = useTranslations();

  return (
    <Popover>
      <PopoverButton
        ref={refs.setReference}
        className="relative flex h-[18px] w-[18px] cursor-pointer items-center justify-center border-none bg-transparent outline-none hover:bg-gray-200"
      >
        {reactionItem.reaction}
        {moreReactions && (
          <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center rounded bg-black bg-opacity-30">
            <span className="text-[10px] font-medium text-white">
              +{reactionsLength - 5}
            </span>
          </div>
        )}
      </PopoverButton>
      <Portal>
        <PopoverPanel
          ref={refs.setFloating}
          style={floatingStyles}
          className="w-[250px] rounded-lg bg-white p-4 shadow-sm shadow-gray-300"
        >
          <div className="flex items-center gap-2">
            <span className="text-[16px]">{reactionItem.reaction}</span>
            <span className="font-gray-500 text-xs">{reactionItem.count}</span>
          </div>
          <div className="my-4 h-px w-full bg-gray-200" />
          <div className="flex max-h-[181px] flex-col gap-3 overflow-y-auto">
            {displayReactions.map((item) => (
              <div className="flex items-center justify-between">
                <div className="truncate text-[14px]">
                  <UserProfileName userId={item} />
                </div>
                <span className="ml-2 text-[20px]">
                  {reactionItem.reaction}
                </span>
              </div>
            ))}
          </div>
          <div className="my-4 h-px w-full bg-gray-200" />
          <div className="flex-end flex items-center justify-end p-2">
            {!displayAllReactions && reactionItem.users.length > 5 && (
              <PlainButton
                className="text-primary-800 mr-auto cursor-pointer text-[14px]"
                onClick={() => setDisplayAllReactions(true)}
              >
                +
                {t(
                  {
                    id: "chats-page.users-more-reactions-count",
                    defaultMessage: "{usersCount} more",
                  },
                  {
                    usersCount: reactionItem.users.length - 5,
                  }
                )}
              </PlainButton>
            )}
            <PlainButton
              className="text-primary-300 cursor-pointer text-[12px]"
              onClick={onSidebarOpen}
            >
              {t({
                id: "chats-page.see-all-reactions",
                defaultMessage: "Tap to see all",
              })}
            </PlainButton>
          </div>
        </PopoverPanel>
      </Portal>
    </Popover>
  );
};

export default ChatMessageReactionPopover;
