import { UserGeneralProfile } from "@jugl-web/domain-resources/users/components/UserGeneralProfile";
import { InternalTaskFilters } from "@jugl-web/rest-api/tasks";
import { Avatar } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import { ReactComponent as WithoutUserIcon } from "../../assets/without-user.svg";
import { FilterPill, LoadingFilterPill } from "../FilterPill";

interface AssigneeFilterPillProps {
  entityId: string;
  assigneeId: InternalTaskFilters["assignees"][number];
  onRemove: () => void;
}

export const AssigneeFilterPill: FC<AssigneeFilterPillProps> = ({
  entityId,
  assigneeId,
  onRemove,
}) => {
  const { t } = useTranslations();

  if (assigneeId === null) {
    return (
      <FilterPill
        label={t({
          id: "tasks-page.without-assignee",
          defaultMessage: "Without assignee",
        })}
        startIcon={<WithoutUserIcon className="h-5 w-5" />}
        onRemove={onRemove}
      />
    );
  }

  return (
    <UserGeneralProfile entityId={entityId} userId={assigneeId}>
      {({ safeProfile, status }) => {
        if (status === "pending") {
          return <LoadingFilterPill />;
        }

        return (
          <FilterPill
            label={safeProfile.displayName}
            startIcon={
              <Avatar
                size="xs"
                username={safeProfile.displayName}
                imageUrl={safeProfile.avatar}
              />
            }
            onRemove={onRemove}
          />
        );
      }}
    </UserGeneralProfile>
  );
};
