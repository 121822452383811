import { PageNavParams } from "@web-src/modules/navigation/types";
import { ModulePagesConfig } from "@web-src/modules/navigation/types/ModulePagesConfigs";
import SchedulePage from "./SchedulePage";

export type SchedulePagesNavigationParams = {
  scheduleSchedule: PageNavParams;
  scheduleDate: PageNavParams<{ date: string }, { search?: string }>;
};

export const schedulePagesNavigationConfig: ModulePagesConfig<SchedulePagesNavigationParams> =
  {
    scheduleSchedule: {
      element: <SchedulePage />,
      path: "schedule",
      isPrivate: true,
    },
    scheduleDate: {
      element: <SchedulePage />,
      path: "schedule/:date",
      isPrivate: true,
    },
  };
