import { PageNavParams } from "@web-src/modules/navigation/types";
import { ModulePagesConfig } from "@web-src/modules/navigation/types/ModulePagesConfigs";
import LoginPage from "./Login";
import ProfileForm from "./ProfileForm";

export type AuthPagesNavigationParams = {
  authLogin: PageNavParams;
  authProfileForm: PageNavParams;
};

export const authPagesNavigationConfig: ModulePagesConfig<AuthPagesNavigationParams> =
  {
    authLogin: {
      element: <LoginPage />,
      path: "login",
      isPrivate: false,
    },
    authProfileForm: {
      element: <ProfileForm />,
      path: "profile-form",
      isPrivate: true,
      isEntityOptional: true,
      isProfileOptional: true,
    },
  };
