import { ReportsType } from "@jugl-web/rest-api/reports/models/common-types";
import { environment } from "@web-src/environments/environment";

export const getSupersetChartIdByType = (type: ReportsType) =>
  parseInt(
    Object.keys(environment.supersetChartIdToType).find(
      (item) =>
        environment.supersetChartIdToType[item as unknown as number] === type
    ) as string,
    10
  );
