import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { Dialog } from "@jugl-web/ui-components/cross-platform/Dialog";
import { useTranslations } from "@jugl-web/utils";
import {
  getStorageItemWithFallback,
  MANAGE_TASK_FIELDS_HINT_DISMISSED_KEY,
  saveItemToStorage,
} from "@jugl-web/utils/storage";
import { FC, useEffect, useState } from "react";
import Lottie from "react-lottie";
import hintAnimationData from "./animations/hint-animation.json";

export interface ManageTaskFieldsHintDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ManageTaskFieldsHintDialog: FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [isDismissed, setIsDismissed] = useState(() =>
    getStorageItemWithFallback<boolean>(
      MANAGE_TASK_FIELDS_HINT_DISMISSED_KEY,
      false
    )
  );

  const { t } = useTranslations();

  const handleDismiss = () => {
    setIsOpen(false);
    setIsDismissed(true);
    saveItemToStorage<boolean>(MANAGE_TASK_FIELDS_HINT_DISMISSED_KEY, true);
  };

  useEffect(() => {
    if (isDismissed) {
      return undefined;
    }

    const timeoutId = window.setTimeout(() => {
      setIsOpen(true);
    }, 500);

    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [isDismissed]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleDismiss}
      className="box-border flex w-[448px] flex-col items-center rounded-2xl px-6 pb-8 text-center"
    >
      <Lottie
        options={{ animationData: hintAnimationData }}
        height={300}
        width={300}
      />
      <span className="text-dark mb-[18px] text-lg font-medium">
        {t({
          id: "manage-task-fields-dialog.hint-title",
          defaultMessage: "Customize your Task structure",
        })}
      </span>
      <span className="text-dark mb-8 text-base font-normal leading-[21px]">
        {t(
          {
            id: "manage-task-fields-dialog.hint-content",
            defaultMessage:
              "Here you can add values to default fields like <b>Task Label</b> and <b>Task Status</b>. Also you can create <b>Custom Fields</b> that can be added to your task information",
          },
          {
            b: (chunks: (string | JSX.Element)[]) => (
              <span className="text-primary-800 font-semibold">{chunks}</span>
            ),
          }
        )}
      </span>
      <Button className="w-[200px]" onClick={handleDismiss}>
        {t({ id: "common.okay", defaultMessage: "Okay" })}
      </Button>
    </Dialog>
  );
};
