import { Alert } from "@jugl-web/ui-components/cross-platform";
import { FC } from "react";
import Lottie from "react-lottie";
import limitAnimation from "./assets/limit-animation.json";

export type BasicPlanLimitAlertProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  seatsLimit: number;
  planName: string;
};

export const BasicPlanLimitAlert: FC<BasicPlanLimitAlertProps> = ({
  isOpen,
  onRequestClose,
  seatsLimit,
  planName,
}) => (
  <Alert
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    isCloseButtonVisible
    header="Workspace Seats"
    img={<Lottie options={{ animationData: limitAnimation }} width={280} />}
    title={`${planName} Limitation`}
    content={`${planName} plan can handle the Team of up to ${seatsLimit} members, but it seams like your team is already bigger. Please select other Plan to seat all of your Workspace members 🤗`}
    buttons={[{ role: "close", text: "Okay", color: "primary" }]}
  />
);
