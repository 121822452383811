import { assert, cx, onEnter, onEscape } from "@jugl-web/utils";
import { FocusEvent, useEffect, useRef } from "react";
import { Draggable } from "react-beautiful-dnd";
import { Checkbox } from "../Checkbox";
import { ReactComponent as ReorderIcon } from "./icons/reorder.svg";
import { DraggableChecklistItemProps } from "./types";
import {
  ADD_BUTTON_DATA_TTR,
  CHECKLIST_ID_DATA_ATTR,
} from "./useDraggableChecklistController";

export const DraggableChecklistItemComponent = ({
  item,
  index,
  checklistId,
  isEditing,
  onCompleteToggle,
  onStartEditing,
  onCancelEditing,
  onFinishEditing,
  onRemove,
  hideCheckbox,
}: DraggableChecklistItemProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const removeButtonRef = useRef<HTMLButtonElement | null>(null);
  const saveButtonRef = useRef<HTMLButtonElement | null>(null);

  const handleCancel = () => {
    assert(!!inputRef.current);

    // Cancel adding a new item
    if (item.text === "") {
      onRemove({ silent: true });
    } else {
      onCancelEditing();
      inputRef.current.value = item.text;
    }
  };

  const handleSave = () => {
    assert(!!inputRef.current);

    const { value } = inputRef.current;

    if (value === item.text) {
      handleCancel();
      return;
    }

    if (value.trim().length > 0) {
      onFinishEditing(value);
    }
  };

  const handleBlur = (
    event: FocusEvent<HTMLInputElement | HTMLButtonElement>
  ) => {
    const isFocusWithinRelatedElements = [
      inputRef.current,
      removeButtonRef.current,
      saveButtonRef.current,
    ].some((element) => element === event.relatedTarget);

    if (isFocusWithinRelatedElements) {
      return;
    }

    const isAddButtonFocused =
      event.relatedTarget?.getAttribute(ADD_BUTTON_DATA_TTR) &&
      event.relatedTarget.getAttribute(CHECKLIST_ID_DATA_ATTR) === checklistId;

    if (isAddButtonFocused) {
      inputRef.current?.focus();
      return;
    }

    handleCancel();
  };

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className="mb-3 flex gap-1"
        >
          <div className="mt-[13px]" {...provided.dragHandleProps}>
            <ReorderIcon />
          </div>
          <div className="flex w-full flex-col gap-1">
            <div className="bg-grey-100 flex w-full items-center gap-[10px] rounded-lg px-3">
              <Checkbox
                isChecked={item.isCompleted}
                onChange={onCompleteToggle}
                className="shrink-0"
              />
              {isEditing ? (
                <input
                  ref={inputRef}
                  onKeyDown={(event) => {
                    onEnter(handleSave)(event);
                    onEscape(handleCancel)(event);
                  }}
                  type="text"
                  placeholder="What is to be done?"
                  defaultValue={item.text}
                  onBlur={handleBlur}
                  className="text-dark w-full border-none bg-transparent px-0 py-3 font-[Roboto] text-base font-normal leading-[19px] outline-none"
                />
              ) : (
                <span
                  onClick={onStartEditing}
                  onKeyDown={onEnter(onStartEditing)}
                  role="button"
                  tabIndex={0}
                  className={cx(
                    "text-dark block w-full cursor-pointer px-0 py-3 font-[Roboto] text-base font-normal leading-[19px]",
                    item.isCompleted
                      ? "text-grey-700 line-through"
                      : "text-dark"
                  )}
                >
                  {item.text}
                </span>
              )}
            </div>
            {isEditing && (
              <div className="flex items-center gap-3">
                <button
                  ref={removeButtonRef}
                  type="button"
                  className="bg-tertiary-50 hover:bg-tertiary-100 w-full cursor-pointer rounded-[6px] border-none p-1.5 font-[Roboto] text-sm text-[#333333] transition-colors"
                  onClick={() => onRemove({ silent: false })}
                  onBlur={handleBlur}
                >
                  Delete
                </button>
                <button
                  ref={saveButtonRef}
                  type="button"
                  className="bg-primary-50 hover:bg-primary-100 w-full cursor-pointer rounded-[6px] border-none p-1.5 font-[Roboto] text-sm text-[#333333] transition-colors"
                  onClick={handleSave}
                  onBlur={handleBlur}
                >
                  Save
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
};
