import React, { useState, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { isImageType, isoToLocalDate } from "@web-src/utils/helper";
import { FileType, DriveItemDisplayType } from "@jugl-web/rest-api/drive/types";
import { getFileSizeLabel } from "@jugl-web/utils/utils/files";
import format from "date-fns-tz/format";
import { useToast, useTranslations } from "@jugl-web/utils";
import { useDeleteObject } from "../../../../hooks/useDeleteObject";
import DriveDeleteModal from "../../../DriveDeleteModal";
import DriveObject from "../DriveObject";
import { actionSidebar } from "../../../../driveSlice";
import MoveFileSidebar from "../MoveFileSidebar";
import ArrowLeftIcon from "./assets/arrowLeft.svg";
import DeleteIcon from "./assets/bin.svg";
import EditIcon from "./assets/edit.svg";
import CopyIcon from "./assets/copy.svg";

type FileDetails = {
  id: string;
  name: string;
};

const DriveFile: React.FC<{
  handleOpen?: () => void;
  id: string;
  name: string;
  search: string;
  type: FileType;
  ext: string;
  link: string;
  display: DriveItemDisplayType;
  date: string;
  size: number;
  mime: string;
  shortUrl: string;
  previewImg?: string;
  readOnly?: boolean;
}> = ({
  handleOpen,
  id,
  name,
  search,
  type,
  display,
  ext,
  link,
  date,
  size,
  mime,
  shortUrl,
  previewImg,
  readOnly,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslations();
  const { toast } = useToast({ variant: "web" });
  const { deleteObject } = useDeleteObject();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [fileToMove, setFileToMove] = useState<FileDetails | null>(null);

  const isMoveFileSidebarOpen = !!fileToMove;
  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(shortUrl);
    toast(
      t(
        {
          id: "drive-page.link-was-copied-to-clipboard",
          defaultMessage: "{type} link was copied to clipboard",
        },
        {
          type: "File",
        }
      )
    );
  }, [shortUrl, toast, t]);
  const displayFileMenuElements = useMemo(
    () =>
      readOnly
        ? []
        : [
            {
              id: "rename",
              icon: EditIcon,
              text: t({
                id: "common.rename",
                defaultMessage: "Rename",
              }),
              onClick: () =>
                dispatch(
                  actionSidebar({
                    id,
                    type,
                    name,
                    allowed_users: undefined,
                    action: "rename",
                  })
                ),
            },
            {
              id: "copy-link",
              icon: CopyIcon,
              text: t({
                id: "common.copy-link",
                defaultMessage: "Copy link",
              }),
              onClick: () => handleCopyLink(),
            },
            {
              id: "move",
              icon: ArrowLeftIcon,
              text: t({
                id: "common.move",
                defaultMessage: "Move",
              }),
              onClick: () => setFileToMove({ id, name: name.concat(ext) }),
            },
            {
              id: "delete",
              icon: DeleteIcon,
              text: t({
                id: "common.delete",
                defaultMessage: "Delete",
              }),
              onClick: () => setIsDeleteModalOpen(true),
            },
          ],
    [readOnly, dispatch, id, type, name, ext, t, handleCopyLink]
  );

  return (
    <>
      <DriveObject
        title={name}
        search={search}
        firstSubtitle={format(isoToLocalDate(date), "MMM dd, yyyy")}
        secondSubtitle={getFileSizeLabel(size)}
        displayFileMenuElements={displayFileMenuElements}
        image={previewImg || (isImageType(mime) ? link : undefined)}
        icon={ext.toLowerCase()}
        display={display}
        onClick={() => handleOpen?.()}
        fileType={false}
      />
      {isDeleteModalOpen && (
        <DriveDeleteModal
          name={name.concat(ext)}
          type="file"
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onClick={() => deleteObject("file", id)}
        />
      )}
      {isMoveFileSidebarOpen && (
        <MoveFileSidebar
          isOpen={fileToMove}
          onRequestClose={() => setFileToMove(null)}
        />
      )}
    </>
  );
};

export default DriveFile;
