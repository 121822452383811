import React from "react";
import { FormField } from "@jugl-web/rest-api";
import { TextField } from "@jugl-web/ui-components/cross-platform/TextField";
import {
  PhoneInput,
  validatePhoneNumber,
} from "@jugl-web/ui-components/cross-platform/PhoneInput";
import { FormControlLabel, Text } from "@jugl-web/ui-components/cross-platform";
import { SelectField } from "@jugl-web/ui-components/cross-platform/SelectField";
import { defineMessage, MessageDescriptor } from "react-intl";
import {
  Controller,
  UseFormRegister,
  FieldValues,
  Control,
  FieldError,
  UseFormResetField,
} from "react-hook-form";
import {
  convertSnakeCaseToReadableString,
  cx,
  useTranslations,
} from "@jugl-web/utils";

export const customerFieldDescriptorsById: Record<string, MessageDescriptor> = {
  first_name: defineMessage({
    id: "customers-page.first-name",
    defaultMessage: "First name",
  }),
  last_name: defineMessage({
    id: "customers-page.last-name",
    defaultMessage: "Last name",
  }),
  position: defineMessage({
    id: "customers-page.position",
    defaultMessage: "Position",
  }),
  phone_number: defineMessage({
    id: "customers-page.phone-number",
    defaultMessage: "Phone number",
  }),
  state: defineMessage({
    id: "customers-page.state",
    defaultMessage: "State",
  }),
  country: defineMessage({
    id: "customers-page.country",
    defaultMessage: "Country",
  }),
  city: defineMessage({
    id: "customers-page.city",
    defaultMessage: "City",
  }),
  zip_code: defineMessage({
    id: "customers-page.zip-code",
    defaultMessage: "ZIP code",
  }),
  street: defineMessage({
    id: "customers-page.street",
    defaultMessage: "Street",
  }),
  company_name: defineMessage({
    id: "customers-page.company-name",
    defaultMessage: "Company name",
  }),
  industry: defineMessage({
    id: "customers-page.industry",
    defaultMessage: "Industry",
  }),
  website: defineMessage({
    id: "customers-page.website",
    defaultMessage: "Website",
  }),
};

const CustomerFormField: React.FC<{
  section: "personal" | "company";
  field: FormField;
  control: Control<FieldValues>;
  register: UseFormRegister<FieldValues>;
  value: string;
  invalid?: boolean;
  error?: FieldError;
  resetField: UseFormResetField<FieldValues>;
}> = ({
  section,
  field,
  register,
  control,
  value,
  invalid,
  error,
  resetField,
}) => {
  const { t } = useTranslations();
  const convertPhoneStringToObject = (str: string) => {
    if (!str) {
      return { iso: "IN", code: "+91", phone: "" };
    }
    const [iso, code, phone] = str.split(",");
    return { iso, code, phone };
  };
  if (field.section === section) {
    const normalizedFieldName = convertSnakeCaseToReadableString(field.name);
    switch (field.type) {
      case "email":
      case "text":
      case "url":
        return (
          <div>
            <TextField
              variant="underline"
              placeholder={t({
                id: "common.enter",
                defaultMessage: "Enter",
              })}
              isRequired={field.required}
              label={
                customerFieldDescriptorsById[field.name]
                  ? t(customerFieldDescriptorsById[field.name])
                  : normalizedFieldName
              }
              className={cx("capitalize", {
                "border-1 border-tertiary border-solid": invalid,
              })}
              isFullWidth
              {...register(field.id, {
                required: field.required,
                minLength: 2,
                pattern: {
                  value: /.*[^ ].*/,
                  message: t({
                    id: "feedback.only-spaces-are-forbidden",
                    defaultMessage: "Field can't contain only spaces",
                  }),
                },
              })}
              errorMessage={error?.message}
            />
          </div>
        );
      case "dropdown":
        return (
          <SelectField
            label={
              customerFieldDescriptorsById[field.name]
                ? t(customerFieldDescriptorsById[field.name])
                : normalizedFieldName
            }
            isRequired={field.required}
            isFullWidth
            {...register(field.id, { required: field.required })}
          >
            <option />
            {field?.value?.split(",").map((option: string) => (
              <option>{option}</option>
            ))}
          </SelectField>
        );
      case "mobile":
        return (
          <div
            className={cx({
              "border-1 border-tertiary border-solid": invalid,
            })}
          >
            <FormControlLabel
              className="pl-2 capitalize"
              isRequired={field.required}
            >
              {customerFieldDescriptorsById[field.name]
                ? t(customerFieldDescriptorsById[field.name])
                : normalizedFieldName}
            </FormControlLabel>
            <Controller
              control={control}
              {...register(field.id, {
                required: field.required,
                validate: {
                  check: (inputValue) => {
                    if (convertPhoneStringToObject(inputValue || "").phone) {
                      return validatePhoneNumber(inputValue);
                    }
                    return true;
                  },
                },
              })}
              render={({ field: controlledField }) => (
                <PhoneInput
                  value={convertPhoneStringToObject(value || "")}
                  onChange={({
                    iso,
                    phone,
                    code,
                  }: {
                    iso: string;
                    phone: string;
                    code: string;
                  }) => {
                    const parsedPhone = phone.replace(/[^0-9]/g, "");
                    if (!parsedPhone) {
                      controlledField.onChange(null);
                      resetField(field.id);
                    }
                    controlledField.onChange(`${iso},${code},${phone}`);
                  }}
                />
              )}
            />
            {invalid && (
              <Text variant="body3" className="text-tertiary">
                {t({
                  id: "feedback.invalid-phone-number",
                  defaultMessage: "Invalid phone number",
                })}
              </Text>
            )}
          </div>
        );
      default:
        return <></>;
    }
  }
  return <></>;
};

export default CustomerFormField;
