import React, { memo } from "react";
import Lottie from "react-lottie";
import { cx } from "@jugl-web/utils";
import loaderAnimation from "./animations/loaderAnimation.json";

const Loader: React.FC<{ className?: string }> = ({ className }) => (
  <div
    className={cx("flex h-full w-full items-center justify-center", className)}
  >
    <Lottie
      options={{
        animationData: loaderAnimation,
      }}
      height={100}
      width={100}
    />
  </div>
);

export default memo(Loader);
