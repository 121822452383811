import { HookOutOfContextError } from "@jugl-web/utils";
import { useContext } from "react";
import { TaskTableColumnsContext } from "../components/TaskTableColumnsProvider/TaskTableColumnsProvider";

export const useTaskTableColumns = () => {
  const context = useContext(TaskTableColumnsContext);

  if (!context) {
    throw new HookOutOfContextError(
      "useTaskTableColumns",
      "TaskTableColumnsContext"
    );
  }

  return context;
};
