import React, { ButtonHTMLAttributes } from "react";
import { ReactComponent as CommentIcon } from "./icons/comment.svg";

const MessageButton: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  ...rest
}) => (
  <button
    type="button"
    className="align-center bg-dark/50 flex h-[56px] w-[56px] cursor-pointer items-center justify-center rounded-full border-transparent drop-shadow-xl hover:brightness-90 active:brightness-110"
    {...rest}
  >
    <CommentIcon />
  </button>
);

export default MessageButton;
