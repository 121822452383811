import React from "react";
import { PlainButton } from "@jugl-web/ui-components/cross-platform";
import ChatMessageCall from "../ChatMessageBubble/ChatMessageCall";
import { ChatMessagePayload } from "../../types";
import { ReactComponent as CloseIcon } from "./icons/close2.svg";

export const ChatInputConferencePreview: React.FC<{
  conference: ChatMessagePayload["conference"];
  removeConference: () => void;
}> = ({ conference, removeConference }) => (
  <div className="relative max-w-[300px]">
    <PlainButton onClick={removeConference}>
      <div className="bg-primary-400 absolute top-[65px] right-2 flex h-[24px] w-[24px] items-center justify-center rounded-full hover:brightness-90">
        <CloseIcon />
      </div>
    </PlainButton>
    <ChatMessageCall conference={conference} isOutgoing preview />
  </div>
);
