import React from "react";

import ButtonBase from "@mui/material/ButtonBase";
import CloseIcon from "@mui/icons-material/Close";
import Link from "@mui/material/Link";
import SvgIcon from "@mui/material/SvgIcon";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material";

import { getFileNameFromUrl } from "@web-src/utils/helper";
import { primary } from "@web-src/themes/colors";
import { ReactComponent as FileIcon } from "./assets/file-document.svg";
import { ReactComponent as LinkIcon } from "./assets/link-alt.svg";

export const sliceLongText = (text, limitCount = 21) =>
  text?.length > limitCount ? `${text.slice(0, limitCount)}...` : text;

function FileAttachment({ url, isLink, onRemoveFile, fileName }) {
  const name = fileName || getFileNameFromUrl(url);
  const handleRemoveClick = (e) => {
    e.stopPropagation();
    onRemoveFile?.();
  };
  return (
    <Stack
      direction="row"
      sx={{
        backgroundColor: isLink ? "none" : "primary.50",
        border: isLink ? "none" : `1px solid ${alpha(primary[500], 0.15)}`,
        borderRadius: "10px",
        padding: "10px",
        alignItems: "center",
        marginBottom: "12px",
        cursor: "pointer",
      }}
      onClick={() => window.open(url)}
    >
      <Stack
        sx={{
          backgroundColor: "white",
          borderRadius: "44px",
          padding: "8px",
          marginRight: "10px",
        }}
      >
        <SvgIcon
          viewBox="0 0 24 24"
          component={isLink ? LinkIcon : FileIcon}
          sx={{ color: "primary.500" }}
        />
      </Stack>
      <Typography
        variant="subHeadlineBold"
        color="dark.A100"
        sx={{
          display: "block",
          flex: 1,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {isLink ? (
          <Link href={url} target="_blank" underline="none">
            {/* TODO: need descriptions for slice title */}
            {sliceLongText(name, 40)}
          </Link>
        ) : (
          sliceLongText(name, 40)
        )}
      </Typography>
      {onRemoveFile && (
        <ButtonBase
          onClick={handleRemoveClick}
          sx={{
            width: "25px",
            height: "25px",
            borderRadius: "12.5px",
            position: "absolute",
            top: "5px",
            right: "5px",
            backgroundColor: "#000",
            color: "white",
            fontSize: "12px",
            padding: "5px",
            zIndex: 1000,
          }}
        >
          <CloseIcon />
        </ButtonBase>
      )}
    </Stack>
  );
}

export default FileAttachment;
