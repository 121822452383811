import { RtkEmptySplitApi } from "../../types";
import { ReportsDto, ReportsModel } from "./models/reportsModel";
import { ReportsApiTag } from "./tags";
import { transformReportsDtoToReportsModel } from "./utils/transformReportsDtoToReportsModel";

export const addReportsApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [ReportsApiTag.reports],
  });

  const reportsApi = apiWithTags.injectEndpoints({
    endpoints: (builder) => ({
      createReport: builder.mutation<
        {
          id: number;
          result: ReportsDto;
        },
        {
          entityId: string;
          data: Pick<
            ReportsModel,
            | "crontab"
            | "description"
            | "name"
            | "timezone"
            | "recipients"
            | "template"
            | "chartId"
          >;
        }
      >({
        query: ({ entityId, data }) => ({
          url: `/api/auth/${entityId}/report_config`,
          data: {
            active: true,
            chart: data.chartId,
            creation_method: "charts",
            crontab: data.crontab,
            name: data.name,
            description: data.description,
            recipients: [
              {
                recipient_config_json: { target: data.recipients.join(", ") },
                type: "Email",
              },
            ],
            report_format: "EXCEL",
            template: data.template,
            timezone: data.timezone,
            type: "Report",
          },
          method: "POST",
        }),
        invalidatesTags: [ReportsApiTag.reports],
      }),
      deleteReport: builder.mutation<
        void,
        { entityId: string; reportId: string }
      >({
        query: ({ entityId, reportId }) => ({
          url: `/api/auth/${entityId}/report_config/${reportId}`,
          method: "DELETE",
        }),
        onQueryStarted: (args, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            reportsApi.util.updateQueryData(
              "getReports",
              { entityId: args.entityId },
              (reportsDraft) =>
                reportsDraft.filter((report) => report.id !== args.reportId)
            )
          );

          queryFulfilled.catch(patchResult.undo);
        },
      }),
      updateReport: builder.mutation<
        {
          id: number;
          result: ReportsDto;
        },
        {
          entityId: string;
          reportId: string;
          data: Partial<
            Pick<
              ReportsModel,
              | "active"
              | "chartId"
              | "crontab"
              | "description"
              | "name"
              | "timezone"
              | "recipients"
              | "template"
            >
          >;
        }
      >({
        query: ({ entityId, reportId, data }) => ({
          url: `/api/auth/${entityId}/report_config/${reportId}`,
          method: "PUT",
          data: {
            ...data,
            chart: data.chartId,
            recipients: data.recipients
              ? [
                  {
                    recipient_config_json: {
                      target: data.recipients.join(", "),
                    },
                    type: "Email",
                  },
                ]
              : undefined,
            template: data.template,
            type: "Report",
          },
        }),
        invalidatesTags: [ReportsApiTag.reports],
      }),
      getReports: builder.query<ReportsModel[], { entityId: string }>({
        query: ({ entityId }) => ({
          url: `/api/auth/${entityId}/report_config/show`,
        }),
        transformResponse: (response: {
          ids: number[];
          result: ReportsDto[] | null;
        }) =>
          response.result?.map((report) =>
            transformReportsDtoToReportsModel(report)
          ) || [],
        providesTags: [ReportsApiTag.reports],
      }),
    }),

    overrideExisting: false,
  });

  return reportsApi;
};

export type ReportsApi = ReturnType<typeof addReportsApi>;
