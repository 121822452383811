import { environment } from "@web-src/environments/environment";

const PROD_MANUAL_DEBUG_KEY = "jugl:manual-debug";

export const isAppInDevMode = (urlBased = true) => {
  if (!environment.production || localStorage.getItem(PROD_MANUAL_DEBUG_KEY)) {
    return true;
  }
  if (!urlBased) {
    return false;
  }
  return !!(
    window.location.hostname.includes("netlify.app") ||
    window.location.hostname.includes("localhost")
  );
};
