import { FC } from "react";
import { DataLoadingWrapper } from "@jugl-web/ui-components/cross-platform";
import { ReactComponent as EmailIcon } from "./assets/email-icon.svg";
import { ReactComponent as UserIcon } from "./assets/user-icon.svg";
import { useEntitySelectPageProvider } from "../../EntitySelectPageProvider";
import { EntityInvitationCard } from "../EntityInvitationCard";

export const InvitationsTab: FC = () => {
  const {
    entityInvitesState: { isError, isFetching, isLoading, entityInvites },
    refetchEntityInvites,
  } = useEntitySelectPageProvider();

  return (
    <DataLoadingWrapper
      onRetry={refetchEntityInvites}
      isError={isError}
      isFetching={isFetching}
      isLoading={isLoading}
      className="w-full"
    >
      {!entityInvites?.length ? (
        <div className="text-dark-600 flex flex-col items-center gap-10 text-center">
          <div className="flex flex-col items-center gap-[10px]">
            <UserIcon />
            <div>
              If someone sent you an Invitation, but you cant see it here -
              please reach out to{" "}
              <b className="text-primary-500">Workspace Admin</b> to fix it.
            </div>
          </div>
          <div className="flex flex-col items-center gap-[10px]">
            <EmailIcon />
            <div>
              Make sure that invitation was sent to the same{" "}
              <b className="text-primary-500">Email/Phone number</b> that you
              used to Login into Jugl
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-6">
          {entityInvites.map((invitation) => (
            <EntityInvitationCard invitation={invitation} key={invitation.id} />
          ))}
        </div>
      )}
    </DataLoadingWrapper>
  );
};
