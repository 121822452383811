import { usePhoenixSocket } from "@web-src/features/chats/providers/PheonixSocket";
import { FC, PropsWithChildren } from "react";
import { PageLoaderFull } from "@jugl-web/ui-components";
import { useEntitySelectedProvider } from "../../providers/EntityProvider";

export const EntityGateway: FC<PropsWithChildren> = ({ children }) => {
  const { socketConnected } = usePhoenixSocket();
  const { subscriptionInfo } = useEntitySelectedProvider();
  const { socketAllowed } = subscriptionInfo;
  if (!socketConnected && socketAllowed) {
    return <PageLoaderFull />;
  }
  return <>{children}</>;
};
