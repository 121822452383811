import { useTaskFields } from "@jugl-web/domain-resources/tasks/hooks/useTaskFields";
import { useTaskFiltersStateContext } from "@jugl-web/domain-resources/tasks/hooks/useTaskFiltersState";
import { TaskLabel } from "@jugl-web/rest-api/tasks";
import { DotIcon } from "@jugl-web/ui-components/cross-platform/DotIcon";
import { ListBoxItem } from "@jugl-web/ui-components/cross-platform/ListBox";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { useTranslations } from "@jugl-web/utils";
import { FC, useMemo } from "react";
import { FilterSectionProps } from "../../types";
import { FilterSectionLayout } from "../FilterSectionLayout";
import { LabelFilterPill } from "../LabelFilterPill";

export const LabelFilterSection: FC<FilterSectionProps> = ({ entityId }) => {
  const { labels, noneLabel } = useTaskFields({ entityId });

  const { filtersState, updateFiltersState } = useTaskFiltersStateContext();

  const { t } = useTranslations();

  const labelsAsListItems = useMemo<ListBoxItem<TaskLabel>[]>(
    () => [
      { id: noneLabel.id, value: noneLabel },
      ...labels.map((l) => ({ id: l.id, value: l })),
    ],
    [labels, noneLabel]
  );

  return (
    <FilterSectionLayout>
      <FilterSectionLayout.Header
        title={t({
          id: "tasks-page.by-label-filter",
          defaultMessage: "By Label",
        })}
        endSlot={
          <ResourcePickerPopover
            placement="bottom-end"
            title={t({
              id: "tasks-page.select-label",
              defaultMessage: "Select label",
            })}
            items={labelsAsListItems}
            selectionBehavior={{ mode: "multiple" }}
            hasSearch
            defaultSelectedIds={filtersState.labels.map(
              (label) => label || noneLabel.id
            )}
            maxVisibleItems={5}
            itemSize="md"
            spaceBetweenItems="compact"
            renderLabel={(item) => item.value.text}
            renderStartIcon={(item) => <DotIcon color={item.value.color} />}
            className="w-[315px]"
            renderTrigger={({ Trigger, triggerRef }) => (
              <Trigger ref={triggerRef} as={FilterSectionLayout.AddButton} />
            )}
            onSubmit={(ids) =>
              updateFiltersState(
                "labels",
                ids.map((id) => (id === noneLabel.id ? null : id))
              )
            }
          />
        }
      />
      {filtersState.labels.length > 0 && (
        <FilterSectionLayout.PillsContainer>
          {filtersState.labels.map((labelId) => (
            <LabelFilterPill
              key={labelId}
              entityId={entityId}
              labelId={labelId}
              onRemove={() =>
                updateFiltersState("labels", (previousLabels) =>
                  previousLabels.filter((l) => l !== labelId)
                )
              }
            />
          ))}
        </FilterSectionLayout.PillsContainer>
      )}
    </FilterSectionLayout>
  );
};
