import { useAppVariant, useToast, useTranslations } from "@jugl-web/utils";
import { useScreenTransitionManager } from "@jugl-web/utils/utils/ScreenTransitionManager";
import { FC, useState } from "react";
import { useTaskFields } from "../../../hooks/useTaskFields";
import { DropdownValueListItem } from "../DropdownValueList";
import { ManageTaskFieldsDialogScreenToParametersMap } from "../types";
import { CustomDropdownFieldValuesScreen } from "./CustomDropdownFieldValuesScreen";

interface NewCustomDropdownFieldValuesScreenProps {
  entityId: string;
  fieldName: string;
  isShownInCard: boolean;
}

export const NewCustomDropdownFieldValuesScreen: FC<
  NewCustomDropdownFieldValuesScreenProps
> = ({ entityId, fieldName, isShownInCard }) => {
  const [internalFieldName, setInternalFieldName] = useState(fieldName);
  const [internalIsShownInCard, setInternalIsShownInCard] =
    useState(isShownInCard);
  const [items, setItems] = useState<DropdownValueListItem[]>([]);

  const { createCustomField, isCreatingCustomField } = useTaskFields({
    entityId,
  });

  const { toast } = useToast({ variant: useAppVariant().variant });
  const { t } = useTranslations();

  const { transitionTo } =
    useScreenTransitionManager<ManageTaskFieldsDialogScreenToParametersMap>();

  const handleSubmit = async () => {
    try {
      createCustomField({
        name: internalFieldName,
        type: "dropdown",
        isShownInCard: internalIsShownInCard,
        values: items.map((item, index) => ({
          id: item.id,
          value: item.text,
          order: index + 1,
        })),
      });

      transitionTo({ name: "entry" }, { force: true });
      toast(
        t({
          id: "feedback.custom-field-created",
          defaultMessage: "Custom field has been successfully created",
        }),
        {
          variant: "success",
        }
      );
    } catch {
      toast(
        t({
          id: "feedback.custom-field-creation-failed",
          defaultMessage: "Failed to create a custom field",
        }),
        { variant: "error" }
      );
    }
  };

  return (
    <CustomDropdownFieldValuesScreen
      fieldName={internalFieldName}
      isShownInCard={internalIsShownInCard}
      items={items}
      submitButton={{
        label: t({
          id: "common.create",
          defaultMessage: "Create",
        }),
        isDisabled: isCreatingCustomField,
        onClick: handleSubmit,
      }}
      onChangeFieldName={setInternalFieldName}
      onChangeIsShownInCard={setInternalIsShownInCard}
      onChangeItems={setItems}
      onCancel={() =>
        transitionTo({
          name: "newCustomField",
          initialFieldName: fieldName,
          initialFieldType: "dropdown",
          initialIsShownInCard: isShownInCard,
        })
      }
    />
  );
};
