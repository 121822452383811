import { RtkEmptySplitApi } from "../../types";
import { SupersetApiTag } from "./tags";

export const addSupersetApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [SupersetApiTag.token],
  });
  const customerFormFieldApi = apiWithTags.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
      getToken: build.query<
        { token: string },
        {
          domain: string;
          entityId: string;
          dashboardId: string;
        }
      >({
        query: ({ domain, entityId, dashboardId }) => ({
          url: `${domain}/api/v1/security/jugl_guest_token`,
          data: { entity_id: entityId, dashboard_id: dashboardId },
          method: "POST",
        }),
      }),
    }),
  });

  return customerFormFieldApi;
};

export type SupersetApi = ReturnType<typeof addSupersetApi>;
